import React from 'react'

import { Link } from 'react-router-dom'
import '../styles/SecNav.css'

export interface MenuListProps {
  label: string
  link: string
  isActive?: boolean
  isPath?: string
}
interface SecNavProps {
  getMenuList?: Array<MenuListProps>
  children?: any
}

export const SecNav: React.FC<SecNavProps> = ({ children, getMenuList }) => {
  return (
    <>
      <div className="sec-nav-wrap">
        <div className="sec-nav-blk">
          {getMenuList &&
            getMenuList.map((menu, index) => (
              <Link
                key={index}
                to={menu.link}
                className={` ${localStorage.getItem('role') !== '1' ? 'display-sync' : ''}${
                  window.location.href.includes(menu.link.substring(menu.link.lastIndexOf('/') + 1)) ||
                  (menu.isPath && window.location.href.includes(menu.isPath))
                    ? 'active'
                    : ''
                }`}
              >
                {menu.label}
              </Link>
            ))}
        </div>
        <hr />
      </div>
    </>
  )
}
