import React, { useCallback, useEffect, useState } from 'react'
import '../styles/pagination.css'

export interface PaginationProps {
  callback: (__param: number) => void
  length?: number
  inc: number
}

const Pagination: React.FC<PaginationProps> = ({ callback, length = 0, inc }) => {
  const pageconstraint: number = 5,
    maxList: number = 3

  const [arrlength, setarrlength] = useState<Array<number>>([])

  const [pageLength, setPageLength] = useState<number>(Math.ceil((length ? length : 0) / 5))

  const [currentPage, setCurrentPage] = useState(1)

  const [pageroutes, setPageRoutes] = useState<Array<number>>([])

  useEffect(() => {
    setPageLength(Math.ceil((length ? length : 0) / pageconstraint))
    const currentPage = inc / 5 + 1;

    let __a = []
    for (let i = 1; i <= (length ? length : 1); i++) {
      __a.push(i)
    }
    setarrlength(__a)
    let __pageroutes: React.SetStateAction<number[]> = []
    if (Math.ceil(__a.length / 5) !== 2) {
      if (currentPage >= 2 && pageLength >= currentPage) {
        __pageroutes = [];
        if (pageLength === currentPage) {
          __pageroutes.push(currentPage - 2, currentPage - 1, currentPage);
        } else {
          __pageroutes.push(currentPage - 1, currentPage, currentPage + 1);
        }
      }
      if (currentPage < 2) {
        __pageroutes = [];
        __pageroutes.push(1, 2, 3)
      }
    }
    if (Math.ceil(__a.length / 5) === 2) {
      __pageroutes.push(1, 2)
    }
    setPageRoutes(__pageroutes)
  }, [length, inc])

  const checkPageNo = useCallback(
    (__param: any, __type: string) => {
      let __a = []
      if (__type === 'forward') {
        if (__param[__param.length - 1] < pageLength) {
          for (
            let i = __param[__param.length - 1];
            i < (__param[__param.length - 1] + 3 > pageLength ? pageLength : __param[__param.length - 1] + 3);
            i++
          ) {
            __a.push(i + 1)
          }
          if (__a.length === 2) {
            __a.unshift(__param[__param.length - 1])
          }
          if (__a.length === 1) {
            __a.unshift(__param[__param.length - 1])
            __a.unshift(__param[__param.length - 2])
          }
          setPageRoutes(__a)
        }
      } else {
        if (__param[__param.length - 1] > 3) {
          let __val = Math.floor(__param[__param.length - 1] / 3)
          let __myval = (__val - 1) * 3
          for (let i = __myval === 0 ? 3 : __myval; i > (__myval === 0 ? 3 : __myval) - 3; i--) {
            __a.unshift(i)
          }
          setPageRoutes(__a)
        }
      }
    },
    [length, pageLength]
  )

  return (
    <>
      {pageLength > 1 && (
        <nav aria-label="Page navigation example">
          <ul className="pagination pos-pag">
            {inc !== 0 && (
              <li className="page-item">
                <a
                  className="page-link pre-nxt-color"
                  href="javascript:void(0)"
                  aria-label="Previous"
                  onClick={(e) => {
                    callback(inc / 5)
                  }}
                >
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </a>
              </li>
            )}
            <>
              {pageroutes.map((evt) => {
                return (
                  <li key={Math.random()} className={`${inc / 5 === evt - 1 && 'active'} page-item`}>
                    <a
                      className="page-link"
                      href="javascript:void(0)"
                      onClick={(e) => {
                        callback(evt)
                      }}
                    >
                      {evt}
                    </a>
                  </li>
                )
              })}
            </>

            {(inc / 5) + 1 < pageLength && (
              <li className="page-item">
                <a
                  className="page-link pre-nxt-color"
                  href="javascript:void(0)"
                  aria-label="Next"
                  onClick={(e) => {
                    callback(inc / 5 + 2)
                  }}
                >
                  <span aria-hidden="true">&raquo;</span>
                  <span className="sr-only">Next</span>
                </a>
              </li>
            )}
          </ul>
        </nav>
      )}
    </>
  )
}

export default Pagination
