import React, { useCallback, useState } from 'react'

import '../../styles/common.css'
import '../../styles/AdminStyle/adminSettings.css'
import Popup from '../../components/admin/popup'
import { AdminTextArea } from '../../components/admin/TextArea'
import { TriNav } from '../../components/admin/TriNav'
import Loader from '../../components/Loader/loader'
import apiFunctions from '../../services/api-service'
import { AdminLayout } from './AdminLayout'
import { authPriNavMenu, authFeedbackTriNavMenu } from './AdminOptions'

interface FeedbackProps {}

export const Feedback: React.FC<FeedbackProps> = ({}) => {
  const [showLoader, SetShowLoader] = useState(false)
  const [showPopup, setshowPopup] = useState(false)
  const [feedback, SetFeedback] = useState('')

  const submitFeedback = useCallback(() => {
    SetShowLoader(true)
    apiFunctions
      .put(
        process.env.REACT_APP_SERVICE_BACKEND_URL + '/admin/feedback?content=' + feedback.replace(/\s+/g, ' ').trim()
      )
      .then((e) => {
        setshowPopup(true)
        resetForm()
        SetShowLoader(false)
      })
      .catch((err) => {
        SetShowLoader(false)
      })
  }, [feedback])
  const closeModal = () => {
    setshowPopup(false);
    resetForm();
  }

  async function resetForm(){
    SetFeedback('');
    SetShowLoader(false)
  }
  const tabIndex=0;
  return (
    <AdminLayout getMenuList={authPriNavMenu} sideBar="Help">
      {showLoader && <Loader />}
      <div className="admin-login-style">
        <div className="login-style1" tabIndex={tabIndex}>Your comments are welcome</div>
        <hr />
        <p className="login-style2" tabIndex={tabIndex}>Help us shape the future of this tool</p>
        <div className="">
          <TriNav getMenuList={authFeedbackTriNavMenu} />
        </div>
        <div>
          <div className="col-12 col-sm-10 col-md-6">
            <div className="text-account" tabIndex={tabIndex}>
              {' '}
              Any and all feedback is welcome - it could be on usability, accessibility, or any suggestions for new
              features as we are constantly working to improve our tools.
            </div>
            <AdminTextArea txtcontent={SetFeedback} type='feedback' value={feedback} />
            <div className="sec-text-style" tabIndex={tabIndex}>
              The digital team will be notified and we may contact you for more information.
            </div>
            <button
              type="button"
              className={`btn btn-primary submit-style login-btn ${feedback.trim().length < 3 ? 'disable' : ''}`}
              onClick={submitFeedback}
            >
              Submit feedback
            </button>
          </div>
        </div>
      </div>
      <Popup report={showPopup} Successtype="feedback" close={closeModal} />
    </AdminLayout>
  )
}

export default Feedback
