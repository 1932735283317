import apiFunctions from "../../services/api-service"

export interface NotificationState {
    showPopup: Boolean,
    notificationData: any
}

export type NotificationAction =
    | {
        type: 'NOTIFICATION_DATA'
    }

export const initialState: NotificationState = {
    showPopup: false,
    notificationData: {}
}

export const notificationReducer = (
    state: NotificationState,
    action: NotificationAction
): NotificationState => {
    switch (action.type) {
        case 'NOTIFICATION_DATA':
            return {
                ...state,
                notificationData: apiFunctions.get(process.env.REACT_APP_SERVICE_BACKEND_URL + '/auth/get-notifications')
            }
        default:
            return state
    }
}