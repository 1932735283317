export const stiKitLoaderPercentage = {
  WELCOME: '2',
  POST_CODE: '4',
  POST_CODE_AGE: '6',
  POSTAGE_YES: '8',
  POSTAGE_NO: '10',
  POSTAGE_DO_NOT_KNOW: '12',
  SUCCESS_POST_CODE: '14',
  PRE_INFO: '16',
  TYPE_OF_SEX: '26',
  GENITALS: '28',
  BROOK_CLINIC: '30',
  ELIGIBILITY_PASS: '32',
  RISK_HEPATITIS: '34',
  HEPATITIS_VACCINATION: '36',
  TEST_KIT: '38',
  EDIT_YOUR_KIT: '40',
  CUSTOMISE_YOUR_KIT: '42',
}