import React, { useState , useContext,useEffect} from 'react'

import { PublicClientApplication } from '@azure/msal-browser';
import axios from 'axios'
import { useLocation } from 'react-router-dom';

import { AzureButton } from '../../components/admin/AzureButton';
import Loader from '../../components/Loader/loader';
import siteConfig from '../../config/config';
import useAuthContext from '../../context/provider/AuthContext'
import { ThemeContext } from '../../context/provider/ThemeContext'
import { setToken } from '../../services/api-service'
import { getUserInfo } from '../../services/api/AuthAPI'
import appUrls from '../../services/AppURLs'
import { history } from '../../utils/history'
import { AdminLayout } from './AdminLayout';
import { AuthMenu } from './AdminOptions';

interface AzureLoginProps {

}
const errorIntialState = {
  status: false,
  message: '',
}
export const AzureLogin: React.FC<AzureLoginProps> = ({ }) => {
  const tabIndex = 0;
  const [showLoader, SetShowLoader] = useState(false);
  const [secNavMenu, setSecNavMenu] = useState(AuthMenu);
  const { theme, themeDispatch } = useContext(ThemeContext);
  const { authDispatch } = useAuthContext();
  const [error, setError] = useState(errorIntialState);

  const location = useLocation();
  const { hash, pathname, search } = location;
    useEffect(() => {
      // Get query parameters from the URL
      const queryParams = new URLSearchParams(window.location.search);
      
      // Get a specific query parameter value
      const paramValue = queryParams.get('redirect');
      
      if(paramValue){
        localStorage.clear();
        window.location.href = `${appUrls.admin.auth.login}?refresh=true`
      }
    }, []);

  const publicClienApplication = new PublicClientApplication({
    auth:{
        clientId: siteConfig.sso.appId,
        redirectUri:siteConfig.sso.redirectUrl,
        authority:siteConfig.sso.auhthority
    },
});

const handleAzureLogin = async() => {
    try{
      SetShowLoader(true)
      const result = await publicClienApplication.loginPopup({
          scopes:siteConfig.sso.scopes,
          prompt:'select_account'
      });
      
      sessionStorage.clear()
      try {
        axios
          .get(process.env.REACT_APP_SERVICE_BACKEND_URL + `/admin/validate-user`,{ headers: {"Authorization" : `Bearer ${result.accessToken}`} } )
          .then(async (_result) => {
            setError({ status: false, message: '' })
            localStorage.setItem('role', _result.data.data.role)
            localStorage.setItem('location', 'All')
            setToken(
              _result && _result.data && _result.data.data && _result.data.data.token ? _result.data.data.token : null,
              null
            )
            authDispatch({ type: 'LOGIN' })
            const userInfo = await getUserInfo()
            if (userInfo) {
              authDispatch({ type: 'LOGIN' })
              const responseModified = { ...userInfo }
              authDispatch({ type: 'LOAD_USER', user: responseModified.data.data.user })
            } else {
              authDispatch({ type: 'LOGOUT' })
            }
            // history.push(`${appUrls.admin.orderKit}?filters=Flagged`)
            history.push(`${appUrls.admin.dashboard}`)
          })
          .catch((err) => {
            console.log(err.response, 'helloerrree');

            SetShowLoader(false)
            setError({ status: true, message: err && err.response && err.response.data && err.response.data.message })
            const timer = setTimeout(() => {
              setError({ status: false, message: '' })
            }, 8000);
          })
      } catch (error: any) {
        console.log(error.response, 'errorresposne')
      }
    }
    catch(err:any){
      SetShowLoader(false);
      console.log(err);
      
    }
   
   
  };

  return (
    <div>
    {showLoader && <Loader />}
    <AdminLayout getMenuList={secNavMenu} roleMode="Super admin mode" hideMenus>
      <div className="p-4 admin-login-style">

        <div className="">
          <h3 className="login-style1 ml-0" tabIndex={tabIndex}>Login using your Microsoft account </h3>
          <p className='login-subtitle'>We've upgraded our login process to Microsoft Single Sign-On, making it easier and more convenient for you to access the platform.</p>
        </div>
        <hr />
        <div className='login-blk'>
          {error && error.message ? <span className="errorMessage">{error.message}</span> : ''}
          <AzureButton
            id='azure-button'
            type='button'
            label='Continue with Microsoft Azure'
            handleClick={handleAzureLogin} />
          <span className='login-note'>
            To get started, simply click the <strong>‘Continue with Microsoft’</strong> button above and follow the prompts to login to Brook’s Staff Hub using your Microsoft account
          </span>
        </div>
      </div>
    </AdminLayout>
    </div>
  );
}