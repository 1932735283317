import React, { useRef, useEffect, useState } from 'react';

import { ReactComponent as Logo } from '../../assets/images/brook_logo.svg';
import { ReactComponent as PopupClose } from '../../assets/images/popup-close.svg';
import { useDialogPolyfill } from './useDialogPolyfill';

import './popup.css';

interface DialogPopupProps {
  closeOnOutsideClick?: any;
  onRequestClose: any;
  // status: boolean;
  open: any;
  subheading?: any;
  children?: any;
  userdata?: any;
  PopupSize?: 'sm' | 'md' | 'lg' | 'xlg';
  heading?: string;
  content?: string;
  addClass?: string;
  text?: string;
  showIcon?: boolean;
  showHeading?: boolean;
  showFooter?: boolean;
  isLogo?: boolean;
}

export const DialogPopup: React.FC<DialogPopupProps> = ({
  closeOnOutsideClick,
  // status,
  onRequestClose,
  subheading,
  open,
  children,
  addClass,
  PopupSize,
  showIcon = true,
  showHeading = false,
  heading,
  content,
  text,
  showFooter = true,
  isLogo = false,
}) => {
  const dialogRef = useRef<any>(null);
  const lastActiveElement = useRef<any>(null);
  const firstRender = useRef<boolean>(true);
  const [tabActive, settabActive] = useState<boolean>(true);

  useDialogPolyfill(dialogRef);

  // Trap Inner Popup focus using Tab
  function trapTabKey(
    e: any,
    firstTabStop: any,
    lastTabStop: any,
    dialogNode: any
  ) {
    // Check for TAB key press
    if (e.keyCode === 9) {
      // SHIFT + TAB
      if (e.shiftKey) {
        if (document.activeElement === firstTabStop) {
          e.preventDefault();
          lastTabStop.focus();
        }

        // TAB
      } else if (document.activeElement === lastTabStop) {
        e.preventDefault();
        firstTabStop.focus();
      }
    }

    // ESCAPE
    if (e.keyCode === 27) {
      dialogNode.close();
      settabActive(true);
      onRequestClose();
    }
  }

  useEffect(() => {
    // prevents calling imperative methods on mount since the polyfill will throw an error since we are not using the `open` attribute
    if (firstRender.current) {
      firstRender.current = false;
    }

    const dialogNode = dialogRef.current;
    if (open) {
      lastActiveElement.current = document.activeElement;
      dialogNode.showModal();

      if (typeof window !== 'undefined' && window.document) {
        // document.body.style.overflow = 'hidden';
      }

      // Find all focusable children
      const focusableElementsString =
        'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]),button:disabled, iframe, object, embed, [tabindex="0"], [contenteditable]';

      let focusableElements = dialogNode.querySelectorAll(
        focusableElementsString
      );
      focusableElements = Array.prototype.slice.call(focusableElements);
      const firstTabStop = focusableElements[0];
      const lastTabStop = focusableElements[focusableElements.length - 1];

      // Focus first child
      // firstTabStop.focus();
      // Traps focus popup
      dialogNode.addEventListener('keydown', (event: any) => {
        trapTabKey(event, firstTabStop, lastTabStop, dialogNode);
        if (event.keyCode === 9) {
          settabActive(false);
        }
      });
    } else {
      dialogNode.close();
      // document.body.style.overflow = 'unset';
      settabActive(true);
      if (
        lastActiveElement &&
        lastActiveElement.current &&
        lastActiveElement.current !== null
      ) {
        lastActiveElement.current.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    const dialogNode = dialogRef.current;
    const handleCancel = (event: any) => {
      event.preventDefault();
      onRequestClose();
    };
    if (dialogNode) {
      dialogNode.addEventListener('cancel', handleCancel);
    }
    return () => {
      if (dialogNode) {
        dialogNode.removeEventListener('cancel', handleCancel);
      }
    };
  }, [onRequestClose]);

  function handleOutsideClick(event: any) {
    const dialogNode = dialogRef.current;
    if (closeOnOutsideClick && event.target === dialogNode) {
      onRequestClose();
    }
  }

  return (
    <div>
      <dialog
        ref={dialogRef}
        onClick={handleOutsideClick}
        className={`new-popup-card ${PopupSize} ${addClass} ${
          open ? 'open-dialog' : 'close-dialog'
        }`}
      >
        {showIcon && (
          <div className="new-popup-card-close cursor-pointer">
            <button
              className={`icon-btn ${open && tabActive ? 'tab_inactive' : ''}${
                showHeading ? ' light' : ' dark'
              }`}
              aria-label="close"
              type="button"
              onClick={onRequestClose}
            >
              {/* <Iconsvg
                  // addClass={``}
                  name='closeSolid'
                /> */}
              <PopupClose />
            </button>
          </div>
        )}
        <div className="new-popup-card-content">
          {isLogo ? (
            <div className="modal-logo-blk">
              <Logo />
            </div>
          ) : null}
          {showHeading && (
            <div className="new-popup-header">
              {heading && (
                <h4 className="heading-text text-baseColor text-2xl" id="pop_title">
                  {heading}
                </h4>
              )}
              {subheading && <p className="text-sm" style={{    color: 'rgb(0 0 0 / 50%)'}} id="pop_desc">{subheading}</p>}
            </div>
          )}

          <div className="popup-card-content">
            {open && <div className="new-popup-body">{children}</div>}
          </div>
          <div onClick={onRequestClose} className="backdrop" />
        </div>
      </dialog>
    </div>
  );
};

export default DialogPopup;
