import React, { useCallback, useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import { Accordion } from '../components/accordian/Accordian'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import '../styles/vaginalsex.css'

interface VaginalsexProps {
  children: any
}

export const BrookClinic: React.FC<VaginalsexProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)
  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('brookClinic_id')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  const history = useHistory()

  useEffect(() => {
    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: '41' },
    })
  }, [])

  const proceed = useCallback(() => {
    TextToSpeech.getInstance().playVoice(null, 'stop')
    setisAccessibilityVoiceOver(false)
    window.open('https://legacy.brook.org.uk/find-a-service', '_blank')
  }, [])

  const child = (theme: any) => {
    return (
      <>
        <div className={`p3-style p2-style ${theme.theme}-txt-global`}>
          At the moment, throat swabs (if you only have oral sex) or an anal swabs (if you only receive anal sex) need
          to be done in a clinic.
          <br />
          If you've had (or are having) different types of sex with different people, you may need more than one type of
          test.
        </div>
      </>
    )
  }
  const handleClick = () => {
    const URL = 'https://www.brook.org.uk/find-a-service/'
    window.location.href = URL
    // window.open(URL);
  }
  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }
  return (
    <div className="bg-color brookclinic-style">
      <div className="brook-container pad-bot">
        <div className="h-div mt-4 text-to-speech">
          <h2 className="page-header-txt">ORDER A TEST KIT</h2>
          <div className="speaker-i">
            <button
              className="no-background-border"
              onClick={(e) => {
                TextToSpeech.getInstance().playVoice('text-to-speech')
                setisAccessibilityVoiceOver(true)
              }}
            >
              <img src={Icon} alt="speaker" />
            </button>
          </div>
        </div>
        <div>
          <div className="b1-style">We recommend you contact your local Brook or other sexual health service</div>
          <div className="body-content mb-3">
            Don't panic - it's just some types of test can't be done at home and we think it would be worth you speaking
            to a professional before you get tested to make sure you get the right one. <br />
            You'll need to contact your local service to book yourself in as soon as you can.
            <br />
            <Accordion
              themeName="postcodeBarrier"
              panels={[
                {
                  id: 'brookClinic_id',
                  label: "WHAT TESTS CAN'T BE DONE AT HOME ?",
                  content: child(theme),
                },
              ]}
            />
            <div className="d-flex jus-center brook-container p-color">
              <Button
                isDisabled={false}
                buttonClassName="btn btn-default btn-main"
                buttonText="Find your local Brook clinic"
                onClick={(e) => proceed()}
              />
            </div>
          </div>
          <div>
            <div className="ft-tab brook-container p-color">
              <button type="button" className="float-left no-background-border" onClick={(e) => GoBackPage()}>
                <img src={backIcon} alt="back" />
              </button>
              <Button
                isDisabled={false}
                buttonClassName="btn btn-default btn-main"
                buttonText="Finish"
                onClick={(e) => handleClick()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
