import React, { useCallback, useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import '../styles/monitoringqns.css'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'

interface MonitoringQnsProps {
  children: any
}

export const MonitoringQns: React.FC<MonitoringQnsProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)

  const history = useHistory()

  useEffect(() => {
    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: '74' },
    })
  }, [])

  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('monitoring_id')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  const proceed = useCallback(() => {
    TextToSpeech.getInstance().playVoice(null, 'stop')
    setisAccessibilityVoiceOver(false)
    Props.history.push('/ethnicity')
  }, [])

  return (
    <div className={`bg-color monitoringqns-style ${theme.theme}-skin-color`}>
      <div className="text-to-speech brook-container pad-bot">
        <div className="h-div mt-4">
          <h2 className={`page-header-txt ${theme.theme}-txt-global`}>ORDER A TEST KIT</h2>
          <div className="speaker-i">
            <button
              className="no-background-border"
              onClick={(e) => {
                TextToSpeech.getInstance().playVoice('text-to-speech')
                setisAccessibilityVoiceOver(true)
              }}
            >
              <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
            </button>
          </div>
        </div>
        <div className={`b1-style ${theme.theme}-txt-global`}>Monitoring questions for data purposes</div>
        <div className={`p2-style ${theme.theme}-txt-global`}>
          These are standard questions that we ask anyone using clinical services.
          <br />
        </div>
        <div className={`p3-style p2-style ${theme.theme}-txt-global`}>
          {' '}
          We ask them to understand how our services are being used, make them more accessible for everyone, and
          identify where we need to make improvements.
          <br /> <br />{' '}
        </div>
        <div className={`p3-style p2-style mb-3 ${theme.theme}-txt-global`}>
          {' '}
          This will be the last set of questions you'll have to complete!{' '}
        </div>
        <div>
          <div className={`ft-tab brook-container jus-end ${theme.theme}-skin-color`}>
            <Button
              id="monitoring_id"
              isDisabled={false}
              buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
              buttonText="Next"
              onClick={proceed}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
