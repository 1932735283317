import React, { useCallback, useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import '../styles/eligibilitypass.css'
import { getTFourPlusDisease } from '../utils/getTFourPlusDisease'

interface ElgibilitypassProps {
  children: any
}

export const Elgibilitypass: React.FC<ElgibilitypassProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)

  const [content, setcontent] = useState('')

  const history = useHistory()
  let genitals_text = theme.quesObj['What genitals do you have?'] === 'Penis' ? 'urine sample' : 'vaginal swab'

  const proceed = useCallback(() => {
    const isTFourPlusDisease = getTFourPlusDisease(theme)
    TextToSpeech.getInstance().playVoice(null, 'stop')
    let typeOfSex = theme.quesObj['What genitals do you have?']
    let type = JSON.parse(theme.quesObj['What type of sex have you had since your last test?'])
    if (
      typeOfSex === 'Vagina' &&
      type['Vaginal'] &&
      type['Vaginal']['type'] &&
      type['Vaginal']['type'] === 'received'
    ) {
      if (isTFourPlusDisease === true) {
        history.push('/customise-kit')
      } else {
        Props.history.push('/vaginalsex')
      }

      // history.push('/customise-kit')
    } else {
      if (isTFourPlusDisease === true) {
        history.push('/customise-kit')
      } 
      else {
        Props.history.push('/preContact')
      // history.push('/customise-kit')
        // Props.history.push('/vaginalsex')
      }
      // Props.history.push('/preContact')
      // history.push('/customise-kit')
    }
  }, [])

  const proceedTtwoDisease = useCallback(() => {
    TextToSpeech.getInstance().playVoice(null, 'stop')
    let typeOfSex = theme.quesObj['What genitals do you have?']
    let type = JSON.parse(theme.quesObj['What type of sex have you had since your last test?'])
    if (
      typeOfSex === 'Vagina' &&
      type['Vaginal'] &&
      type['Vaginal']['type'] &&
      type['Vaginal']['type'] === 'received'
    ) {
      Props.history.push('/vaginalsex')
    } else {
      Props.history.push('/preContact')
    }
  }, [])

  useEffect(() => {
    let typeOfSex = theme.quesObj['What genitals do you have?']

    let myobj = JSON.parse(theme.quesObj['What type of sex have you had since your last test?'])

    if (
      (myobj['Vaginal'] && myobj['Vaginal']['type'] === 'given' && typeOfSex === 'Penis') ||
      (myobj['Anal'] && myobj['Anal']['type'] && myobj['Anal']['type']['given'] && typeOfSex === 'Penis') ||
      (myobj['Oral'] && myobj['Oral']['type'] && myobj['Oral']['type']['received'] && typeOfSex === 'Penis') ||
      (myobj['Hand'] && myobj['Hand']['type'] && myobj['Hand']['type']['received'] && typeOfSex === 'Penis')
    ) {
      setcontent('Urine sample')
    } else if (
      (myobj['Vaginal'] && myobj['Vaginal']['type'] === 'received' && typeOfSex === 'Vagina') ||
      (myobj['Oral'] && myobj['Oral']['type'] && myobj['Oral']['type']['received'] && typeOfSex === 'Vagina') ||
      (myobj['Hand'] && myobj['Hand']['type'] && myobj['Hand']['type']['received'] && typeOfSex === 'Vagina')
    ) {
      setcontent('Vaginal swab')
    } else if (
      (myobj['Anal'] && myobj['Anal']['type'] && myobj['Anal']['type']['received'] && typeOfSex === 'Penis') ||
      (myobj['Anal'] && myobj['Anal']['type'] && myobj['Anal']['type']['received'] && typeOfSex === 'Vagina')
    ) {
      setcontent('Anal swab')
    } else if (
      (myobj['Oral'] && myobj['Oral']['type'] && myobj['Oral']['type']['given'] && typeOfSex === 'Penis') ||
      (myobj['Oral'] && myobj['Oral']['type'] && myobj['Oral']['type']['given'] && typeOfSex === 'Vagina')
    ) {
      setcontent('Mouth / throat swab')
    }

    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: '39' },
    })
  }, [])
  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }
  // const tabIndex = 0
  return (
    <div className="bg-color successpostcode-style">
      <div className="brook-container s-pad">
        <div className="text-to-speech">
          <div className="h-div mt-4">
            <h2 className="page-header-txt">ORDER A TEST KIT</h2>
            <div className="speaker-i">
              <button
                className="no-background-border"
                onClick={(e) => TextToSpeech.getInstance().playVoice('text-to-speech')}
              >
                <img src={Icon} alt="speaker" />
              </button>
            </div>
          </div>
          <div className='mt-5'>
            {getTFourPlusDisease(theme) ? (
              <>
                <div className="b1-style !p-0">Your test kit from Brook.</div>
                <div className="mar-pos p2-style mb-3">
                  Thanks for answering those questions.
                  <br />
                </div>
                <div className="p3-style p2-style mb-3">
                  We will send you a {genitals_text} test for chlamydia and gonorrhoea.
                </div>
                <div className="p3-style p2-style mb-3">You can customise your test kit on the next pages.</div>
              </>
            ) : (
              <div>
                <div className="b1-style">
                  You are eligible for an STI test kit from Brook.
                </div>
                <div className="mar-pos p2-style mb-3">
                  Now we just need to know where to send it.
                  <br />
                </div>
                <div className="p3-style p2-style mb-3">
                  {' '}
                  You will be sent a {content.toLowerCase()} test for chlamydia and gonorrhoea. <br />{' '}
                </div>
                <div className="p3-style p2-style mb-3">
                  {' '}
                  If you require a HIV or syphilis test, you’ll need to book an appointment in person with your local
                  Brook or sexual health clinic as these tests need to be done with a nurse.{' '}
                </div>
              </div>
            )}
          </div>
          <div className="ft-tab brook-container">
            <button className="float-left no-background-border" onClick={(e) => GoBackPage()}>
              <img src={backIcon} alt="back" />
            </button>
            <Button
              isDisabled={false}
              buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
              buttonText="Next"
              onClick={(e) => (getTFourPlusDisease(theme) ? proceed() : proceedTtwoDisease())}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
