import React, { useCallback, useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import helpIcon from '../assets/images/qn.svg'
import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
// import xIcon from '../assets/images/X_popup.svg'
import '../styles/typeofsex.css'
import { Accordion } from '../components/accordian/Accordian'
import { Button } from '../components/button/Button'
import TypeofSexPopup from '../components/ModalPopup/TypeofSexPopup'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import { stiKitLoaderPercentage } from '../utils/stiKitLoaderPercentage'

interface TypeofsexProps {
  Prop: any
}

export const Typeofsex: React.FC<TypeofsexProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)

  const [typeOfSex, setTypeOfSex] = useState<Array<string>>([])

  const [showInfo, setShowInfo] = useState(false)

  const [fullfilled, setFullfilled] = useState(false)

  const [vaginalState, SetVaginalState] = useState('')

  const [analState, SetAnalState] = useState({
    given: false,
    received: false,
  })

  const [oralState, SetOralState] = useState({
    given: false,
    received: false,
  })

  const [handState, SetHandState] = useState({
    given: false,
    received: false,
  })

  const [handOrganState, SetHandOrganState] = useState({
    penis: false,
    vagina: false,
  })

  const history = useHistory()

  const [infoContent, setInfoContent] = useState({ header: '', content: '', content1: '' })

  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('sex_id')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  useEffect(() => {
    const type =
      theme.quesObj && theme.quesObj['What type of sex have you had since your last test?']
        ? JSON.parse(theme.quesObj['What type of sex have you had since your last test?'])
        : {}
    let arr: string[] = []
    Object.keys(type).map((e) => {
      arr.push(e)
      if (e === 'Vaginal') {
        SetVaginalState(type['Vaginal']['type'])
      } else if (e === 'Anal') {
        SetAnalState(type['Anal']['type'])
      } else if (e === 'Hand') {
        SetHandState(type['Hand']['type'])
        SetHandOrganState(type['Hand']['partner_gender'])
      } else if (e === 'Oral') {
        SetOralState(type['Oral']['type'])
      }
    })
    if (arr.length) setTypeOfSex(arr)

    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: stiKitLoaderPercentage.TYPE_OF_SEX },
    })
  }, [])

  useEffect(() => { }, [typeOfSex, handOrganState, handState, vaginalState, analState, oralState])

  const proceed = useCallback(() => {
    setisAccessibilityVoiceOver(false)
    let bindValues: any = {}
    typeOfSex.map((val) => {
      if (val === 'Vaginal') {
        bindValues['Vaginal'] = { type: vaginalState }
      } else if (val === 'Anal') {
        bindValues['Anal'] = { type: analState }
      } else if (val === 'Oral') {
        bindValues['Oral'] = { type: oralState }
      } else if (val === 'Hand') {
        bindValues['Hand'] = { type: handState, partner_gender: handOrganState }
      } else {
        bindValues[val] = { type: null }
      }
      TextToSpeech.getInstance().playVoice(null, 'stop')
    })
    themeDispatch({
      type: 'SET_QUES',
      payload: {
        quesObj: {
          ...theme.quesObj,
          'What type of sex have you had since your last test?': JSON.stringify(bindValues),
        },
      },
    })
    Props.history.push('/genitals')
  }, [typeOfSex, analState, oralState, vaginalState, handState, handOrganState])

  const removeSpecificItem = (array: any, str: any) => {
    let status = false
    if (str === 'Vaginal') {
      SetVaginalState('')
      status = true
    }
    if (str === 'Anal') {
      SetAnalState({ given: false, received: false })
      status = true
    }
    if (str === 'Oral') {
      SetOralState({ given: false, received: false })
      status = true
    }
    if (str === 'Hand') {
      SetHandState({ given: false, received: false })
      status = true
    }
    if (status) {
      setFullfilled(false)
    }
    array = array.filter((e: any) => e !== str)
    return array
  }
  const selected = useCallback(
    (e, value = null) => {
      let __typeOfSex = typeOfSex.concat()
      let checked = false
      if (e && e.target && e.target.value && e.target.value !== '' && value === null) {
        if (e.target.checked) {
          checked = true
        }
        if (
          e.target.value === 'Vaginal' ||
          e.target.value === 'Anal' ||
          e.target.value === 'Oral' ||
          e.target.value === 'Hand' ||
          e.target.value === 'Toys'
        ) {
          if (checked) {
            __typeOfSex.push(e.target.value)
          }
          if (!checked) {
            __typeOfSex = removeSpecificItem(__typeOfSex, e.target.value)
          }
        }
      }
      setTypeOfSex(__typeOfSex)
    },
    [typeOfSex]
  )
  function NewlineText(props: any) {
    const text: any = props.text
    return text.split('\n').map((str: any) => <p>{str}</p>)
  }
  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }
  return (
    <div className={`bg-color sex-style ${theme.theme}-global`}>
      {/* {showInfo && (
        <div className="popup-info-section">
          <div
            className="info-popup"
            tabIndex={tabIndex}
            onClick={(e) => {
              setShowInfo(false)
            }}
          >
            <div className="closeBtn">
              <img src={xIcon} className="onkeyup" alt="back" tabIndex={tabIndex} />
            </div>
            <div className="p4-style-title">
              {infoContent.header}
              <br />
              <br />
              <div className="p5-style">
                <p>{infoContent.content}</p>
                <p>{infoContent.content1}</p>
              </div>
            </div>
          </div>
        </div>
      )} */}
      <div className="brook-container pad-bot">
        <div className="text-to-speech">
          <div className="pad-te">
            <div className="h-div mt-4">
              <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
              <div className="speaker-i">
                <button
                  className="no-background-border pos-re"
                  onClick={(e) => {
                    TextToSpeech.getInstance().playVoice('text-to-speech')
                    setisAccessibilityVoiceOver(true)
                  }}
                >
                  <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
                </button>
              </div>
            </div>
            <div className={`b1-style ${theme.theme}-txt-global`}>
              What type of sex have you had since your last test?
            </div>
            <div className={`b2-style ${theme.theme}-txt-global`}>
              <i>Tick all that apply</i>
            </div>
          </div>
          <Accordion
            themeName={theme.theme}
            panels={[{ id: 'sex_id', label: 'WHY ARE YOU   ASKING ME THIS?', content: child(theme) }]}
          />
          <div className="pad-te font-check">
            <div className="form-check ">
              <input
                className="form-check-input"
                type="checkbox"
                value="Vaginal"
                aria-label="Vaginal"
                id="flexCheckDefault"
                checked={typeOfSex.includes('Vaginal')}
                onChange={(e) => {
                  selected(e)
                }}
              />
              <div className="d-flex">
                <div className=" mar-st">
                  <label className={`form-check-label label-font ${theme.theme}-txt-global`}>Vaginal </label>
                </div>
                <div>
                  {/* <button
                    aria-label="vaginal info"
                    className="no-background-border pos-re"
                    onClick={(e) => {
                      setInfoContent({
                        header: 'What counts as vaginal sex in terms of getting tested?',
                        content: 'Vaginal sex is a type of sex where a penis is inserted into a vagina. ',
                        content1:
                          'It is also known as vaginal penetrative sex, vaginal intercourse and penis-in-vagina sex.',
                      })
                      setShowInfo(true)
                    }}
                  >
                    <img src={helpIcon} alt="help" className="ml-2" />
                  </button> */}
                  <TypeofSexPopup
                    header="What counts as vaginal sex in terms of getting tested?"
                    content="Vaginal sex is a type of sex where a penis is inserted into a vagina. "
                    content1="It is also known as vaginal penetrative sex, vaginal intercourse and penis-in-vagina sex."
                    ariaLabel="vaginal info"
                  >
                    <img src={helpIcon} alt="help" className="ml-2" />
                  </TypeofSexPopup>
                </div>
              </div>
            </div>
            <div>
              {typeOfSex.includes('Vaginal') && (
                <div className="mb-3">
                  <Button
                    buttonClassName={`btn sub-button mar-sub ${theme.theme}-btn-global after-active`}
                    buttonText="given"
                    isDisabled={false}
                    selected={vaginalState}
                    onClick={(e) => {
                      setFullfilled(true)
                      SetVaginalState('given')
                    }}
                  />
                  <Button
                    buttonClassName={`btn sub-button ml-4 ${theme.theme}-btn-global after-active`}
                    buttonText="received"
                    isDisabled={false}
                    selected={vaginalState}
                    onClick={(e) => {
                      setFullfilled(true)
                      SetVaginalState('received')
                    }}
                  />
                </div>
              )}
            </div>
            <div className="form-check mt-5 mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value="Anal"
                aria-label="Anal"
                checked={typeOfSex.includes('Anal')}
                onChange={(e) => {
                  selected(e)
                }}
              />
              <div className="d-flex">
                <div className=" mar-st">
                  <label className={`form-check-label label-font ${theme.theme}-txt-global`}>Anal </label>
                </div>
                <div>
                  {/* <button
                    aria-label="Anal info"
                    className="no-background-border pos-re"
                    onClick={(e) => {
                      setInfoContent({
                        header: 'What counts as anal sex in terms of getting tested?',
                        content:
                          "Anal sex is any type of sexual activity involving the anus (bottom). This could include inserting a penis or sex toy into someone's anus.",
                        content1: '',
                      })
                      setShowInfo(true)
                    }}
                  >
                    <img src={helpIcon} alt="help" className="ml-2" />
                  </button> */}
                  <TypeofSexPopup
                    header="What counts as anal sex in terms of getting tested?"
                    content="Anal sex is any type of sexual activity involving the anus (bottom). This could include inserting a penis or sex toy into someone's anus."
                    content1=""
                    ariaLabel="Anal info"
                  >
                    <img src={helpIcon} alt="help" className="ml-2" />
                  </TypeofSexPopup>
                </div>
              </div>
            </div>
            <div>
              {typeOfSex.includes('Anal') && (
                <div className="mb-3">
                  <Button
                    buttonClassName={`btn sub-button mar-sub ${theme.theme}-btn-global ${!analState.given ? 'after-active' : ''
                      }`}
                    buttonText="given"
                    isDisabled={false}
                    selected={analState.given ? 'given' : ''}
                    onClick={(e) => {
                      SetAnalState({
                        given: !analState.given,
                        received: analState.received,
                      })
                    }}
                  />
                  <Button
                    buttonClassName={`btn sub-button ml-4 ${theme.theme}-btn-global ${!analState.received ? 'after-active' : ''
                      }`}
                    buttonText="received"
                    isDisabled={false}
                    selected={analState.received ? 'received' : ''}
                    onClick={(e) => {
                      SetAnalState({
                        given: analState.given,
                        received: !analState.received,
                      })
                    }}
                  />
                </div>
              )}
            </div>
            <div className="form-check mt-5">
              <input
                className="form-check-input"
                type="checkbox"
                value="Oral"
                aria-label="Oral"
                checked={typeOfSex.includes('Oral')}
                onChange={(e) => {
                  selected(e)
                }}
              />
              <div className="d-flex">
                <div className=" mar-st">
                  <label className={`form-check-label label-font ${theme.theme}-txt-global`}>Oral </label>
                </div>
                <div>
                  {/* <button
                    aria-label="Oral info"
                    className="no-background-border pos-re"
                    onClick={(e) => {
                      setInfoContent({
                        header: 'What counts as oral sex in terms of getting tested?',
                        content:
                          "Oral sex is using your mouth to stimulate someone's penis, vulva (vagina) or anus, or having your genitals stimulated by someone using their mouth. This is often called 'going down', a 'blow-job', 'eating out' or 'rimming'.",
                        content1: '',
                      })
                      setShowInfo(true)
                    }}
                  >
                    <img src={helpIcon} alt="help" className="ml-2" />
                  </button> */}
                  <TypeofSexPopup
                    header="What counts as oral sex in terms of getting tested?"
                    content="Oral sex is using your mouth to stimulate someone's penis, vulva (vagina) or anus, or having your genitals stimulated by someone using their mouth. This is often called 'going down', a 'blow-job', 'eating out' or 'rimming'."
                    content1=""
                    ariaLabel="Oral info"
                  >
                    <img src={helpIcon} alt="help" className="ml-2" />
                  </TypeofSexPopup>
                </div>
              </div>
            </div>
            <div>
              {typeOfSex.includes('Oral') && (
                <div className="mb-3">
                  <Button
                    buttonClassName={`btn sub-button mar-sub ${theme.theme}-btn-global ${!oralState.given ? 'after-active' : ''
                      }`}
                    buttonText="given"
                    isDisabled={false}
                    selected={oralState.given ? 'given' : ''}
                    onClick={(e) => {
                      SetOralState({
                        given: !oralState.given,
                        received: oralState.received,
                      })
                    }}
                  />
                  <Button
                    buttonClassName={`btn sub-button ml-4 ${theme.theme}-btn-global ${!oralState.received ? 'after-active' : ''
                      }`}
                    buttonText="received"
                    isDisabled={false}
                    selected={oralState.received ? 'received' : ''}
                    onClick={(e) => {
                      SetOralState({
                        given: oralState.given,
                        received: !oralState.received,
                      })
                    }}
                  />
                </div>
              )}
            </div>
            <div className="form-check mt-5 mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value="Hand"
                aria-label="Hand (with a partner)"
                checked={typeOfSex.includes('Hand')}
                onChange={(e) => {
                  selected(e)
                }}
              />
              <div className="d-flex">
                <div className=" mar-st">
                  <label className={`form-check-label label-font ${theme.theme}-txt-global`}>
                    Hand (with a partner){' '}
                  </label>
                </div>
                <div>
                  {/* <button
                    className="no-background-border pos-re"
                    aria-label="Hand (with a partner) info"
                    onClick={(e) => {
                      setInfoContent({
                        header: 'What counts as hand (with a partner) in terms of getting tested?',
                        content:
                          "This means using your hands to stimulate someone's penis, vulva/vagina or anus, or having your genitals stimulated by someone using their hands. This is often called a 'hand-job' or 'fingering'.",
                        content1: '',
                      })
                      setShowInfo(true)
                    }}
                  >
                    <img src={helpIcon} alt="help" className="ml-2" />
                  </button> */}
                  <TypeofSexPopup
                    header="What counts as hand (with a partner) in terms of getting tested?"
                    content="This means using your hands to stimulate someone's penis, vulva/vagina or anus, or having your genitals stimulated by someone using their hands. This is often called a 'hand-job' or 'fingering'."
                    content1=""
                    ariaLabel="Hand (with a partner) info"
                  >
                    <img src={helpIcon} alt="help" className="ml-2" />
                  </TypeofSexPopup>
                </div>
              </div>
            </div>
            {typeOfSex.includes('Hand') && (
              <>
                <div className="mb-3">
                  <Button
                    buttonClassName={`btn sub-button mar-sub ${theme.theme}-btn-global ${!handState.given ? 'after-active' : ''
                      }`}
                    buttonText="given"
                    isDisabled={false}
                    selected={handState.given ? 'given' : ''}
                    onClick={(e) => {
                      SetHandState({
                        given: !handState.given,
                        received: handState.received,
                      })
                    }}
                  />
                  <Button
                    buttonClassName={`btn sub-button ml-4 ${theme.theme}-btn-global  ${!handState.received ? 'after-active' : ''
                      }`}
                    buttonText="received"
                    isDisabled={false}
                    selected={handState.received ? 'received' : ''}
                    onClick={(e) => {
                      SetHandState({
                        given: handState.given,
                        received: !handState.received,
                      })
                    }}
                  />
                </div>
                <div className={`mar-sub mb-3 ${theme.theme}-txt-global`}>with someone who has</div>
                <div className="mb-3">
                  <Button
                    buttonClassName={`btn sub-button mar-sub ${theme.theme}-btn-global ${!handOrganState.penis ? 'after-active' : ''
                      }`}
                    buttonText="penis"
                    isDisabled={false}
                    selected={handOrganState.penis ? 'penis' : ''}
                    onClick={(e) => {
                      SetHandOrganState({
                        penis: !handOrganState.penis,
                        vagina: handOrganState.vagina,
                      })
                    }}
                  />
                  <Button
                    buttonClassName={`btn sub-button ml-4 ${theme.theme}-btn-global ${!handOrganState.vagina ? 'after-active' : ''
                      }`}
                    buttonText="vagina"
                    isDisabled={false}
                    selected={handOrganState.vagina ? 'vagina' : ''}
                    onClick={(e) => {
                      SetHandOrganState({
                        penis: handOrganState.penis,
                        vagina: !handOrganState.vagina,
                      })
                    }}
                  />
                </div>{' '}
              </>
            )}
            <div className="form-check mt-5 mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value="Toys"
                aria-label="Toys (with a parent)"
                checked={typeOfSex.includes('Toys')}
                onChange={(e) => {
                  selected(e)
                }}
              />
              <div className="d-flex">
                <div className=" mar-st">
                  <label className={`form-check-label label-font ${theme.theme}-txt-global`}>
                    Toys (with a partner){' '}
                  </label>
                </div>
                {/* <button
                  className="no-background-border pos-re"
                  aria-label="Toys (with a parent) info"
                  onClick={(e) => {
                    setInfoContent({
                      header: 'What counts as toys with a partner in terms of getting tested?',
                      content:
                        "This means using a sex toy or aid to stimulate someone's penis, vulva/vagina or anus, or having your genitals stimulated by someone usnig a sex toy. This might include sharing a vibrator, dildo or Fleshlight with a partner.",
                      content1: '',
                    })
                    setShowInfo(true)
                  }}
                >
                  <img src={helpIcon} alt="help" className="ml-2" />
                </button> */}
                <TypeofSexPopup
                  header="What counts as toys with a partner in terms of getting tested?"
                  content="This means using a sex toy or aid to stimulate someone's penis, vulva/vagina or anus, or having your genitals stimulated by someone using a sex toy. This might include sharing a vibrator, dildo or Fleshlight with a partner."
                  content1=""
                  ariaLabel="Toys (with a parent) info"
                >
                  <img src={helpIcon} alt="help" className="ml-2" />
                </TypeofSexPopup>
              </div>
            </div>
            <div className={`ft-tab brook-container ${theme.theme}-skin-color`}>
              <button className="float-left no-background-border pos-re" onClick={(e) => GoBackPage()}>
                <img src={backIcon} alt="back" />
              </button>
              <Button
                isDisabled={
                  typeOfSex.length === 0 ||
                    (typeOfSex.includes('Anal') && !analState.given && !analState.received) ||
                    (typeOfSex.includes('Oral') && !oralState.given && !oralState.received) ||
                    (typeOfSex.includes('Hand') && !handState.given && !handState.received) ||
                    (typeOfSex.includes('Hand') && !handOrganState.penis && !handOrganState.vagina) ||
                    (typeOfSex.includes('Vaginal') && vaginalState === '')
                    ? true
                    : false
                }
                buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                buttonText="Next"
                onClick={(e) => proceed()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const child = (props: any) => {
  const tabIndex = 0
  return (
    <>
      <div className={`p3-style p2-style ${props.theme}-txt-global`}>
        Your response to this question and the next question will help us know which type of kit to send you (this will
        either be a swab or a urine sample), or if you may need a different type of test that needs to be done in a
        clinic.
      </div>
    </>
  )
}
