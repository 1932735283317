import React, { useCallback, useContext, useEffect, useState } from 'react'

import moment from 'moment'
import LoadingOverlay from 'react-loading-overlay'
import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import '../styles/symptombarrier.css'
import xIcon from '../assets/images/X_popup.svg'
import { Button } from '../components/button/Button'
import ErrorPopup from '../components/ModalPopup/ErrorPopup'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import apiFunctions from '../services/api-service'

interface SymptombarrierProps {
  children: any
}

export const CallYou: React.FC<SymptombarrierProps> = (Props: any) => {
  const history = useHistory()

  const [showInfo, setShowInfo] = useState(false)

  const [isEmail, setIsEmail] = useState(false)

  const [isPhone, setisPhone] = useState(false)

  const [showErrorMsg, setshowErrorMsg] = useState('')

  const [errormsg, seterrormsg] = useState('')

  const { theme, themeDispatch } = useContext<any>(ThemeContext)

  const [showFinish, setShowFinish] = useState(true)

  const [overlay, setOverlay] = useState<boolean>(false)

  const proceed = useCallback(async () => {
    TextToSpeech.getInstance().playVoice(null, 'stop')
    if (theme.quesObj.isemailContact) {
      setIsEmail(theme.quesObj.isemailContact)
    }
    setOverlay(true)
    let userData = {
      first_name: theme.first_name,
      last_name: theme.last_name,
      date_of_birth:
        theme.date_of_birth === '' || theme.date_of_birth === '--'
          ? null
          : moment(theme.date_of_birth).format('YYYY-MM-DD'),
      email: theme.email,
      age: theme.age,
      phone_no: theme.phone_no,
      alternate_phone_no: theme.phone_no,
      address_line_1: theme.address_line_1,
      address_line_2: theme.address_line_2,
      address_line_3: theme.address_line_3,
      town: theme.town,
      postcode: theme.postcode,
      email_notification: isEmail,
      isEmailConfirm: theme.isEmailConfirm,
      isemailContact: theme.quesObj.isemailContact,
      isPhoneContact: theme.quesObj.isPhoneContact,
      sti_type: theme.sti_type ? theme.sti_type : 'chlamydia',
    }
    let myObj: any = {}
    await apiFunctions
      .get(process.env.REACT_APP_SERVICE_BACKEND_URL + '/question/list')
      .then(async (result) => {
        result.data.data.map((e: { question_text: string | number; id: string }) => {
          if (e.question_text && e.id) {
            myObj[String(e.question_text)] = e.id
          }
        })
        await apiFunctions
          .post(process.env.REACT_APP_SERVICE_BACKEND_URL + '/user/add', userData)
          .then(async (_result) => {
            let myarr: any = []
            for (let i in theme.quesObj) {
              if (myObj[i] && theme.quesObj[i] && theme.quesObj[i] !== '') {
                let _obj = {
                  question_id: myObj[i],
                  user_id: _result.data.data.id,
                  answer: theme.quesObj[i],
                }
                myarr.push(_obj)
              }
            }

            let __data = {
              response: myarr,
            }

            if (myarr.length < 1) {
              Props.history.push('/contact-brook')
              // window.location.reload();
            } else {
              await apiFunctions
                .post(process.env.REACT_APP_SERVICE_BACKEND_URL + '/response/user-answer', __data)
                .then((_result) => {
                  Props.history.push('/contact-brook')
                })
                .catch((err) => {
                  setOverlay(false)
                  setshowErrorMsg(err.response.data.message)
                })
            }
          })
          .catch((err) => {
            setshowErrorMsg(err.response.data.message)
            setOverlay(false)
          })
      })
      .catch((err) => {
        setOverlay(false)
        setshowErrorMsg(err.response.data.message)
      })
  }, [isEmail])

  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }
  function CloseModel() {
    setshowErrorMsg('')
  }
  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('callYou_id')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  return (
    <LoadingOverlay active={overlay} spinner text="">
      {showInfo && (
        <div
          className="my_info_popup info-popup fo-title"
          onClick={(e) => {
            setShowInfo(false)
          }}
        >
          <img src={xIcon} className="x-icon" alt="back" />
          <div className="p4-style-title">
            <div className="p5-style fo-style">{errormsg}</div>
          </div>
        </div>
      )}

      <div className="bg-color callyou-style">
        {/* {showErrorMsg !== '' && (
          <div
            className="info-popup internal-error-popup"
            onClick={(e) => {
              CloseModel()
            }}
          >
            <img src={xIcon} className="x-icon" alt="back" />
            <div className="p4-style-title">
              <div className={`p5-style fo-style ${theme.theme}-txt-global`}>{showErrorMsg}</div>
            </div>
          </div>
        )} */}
        <ErrorPopup
          showErrorMsg={showErrorMsg}
          setshowErrorMsg={setshowErrorMsg}
          headerClass={`p5-style fo-style ${theme.theme}-txt-global`}
        />
        <div className="brook-container pad-bot">
          <div className="pad-te ">
            <div className="text-to-speech">
              <div className="h-div mt-4">
                <h2 className="page-header-txt ">ORDER A TEST KIT</h2>
                <div className="speaker-i">
                  <button
                    className="no-background-border"
                    onClick={(e) => {
                      TextToSpeech.getInstance().playVoice('text-to-speech')
                      setisAccessibilityVoiceOver(true)
                    }}
                  >
                    <img src={Icon} alt="speaker" />
                  </button>
                </div>
              </div>
              <div>
                <div className="b-style">
                  <br />
                  Thank you for providing your contact details.
                  <br />
                  <br />
                  When you hit the Finish button below, these will be sent to your local Brook service and someone will
                  contact you as soon as possible.
                  <br />
                  <br />
                  Please keep a lookout for our calls or messages.
                  <br />
                  {theme && theme.age >= 16 && (
                    <p>
                      <br />
                      You can find full details of your rights regarding the information you share with us and when
                      accessing sexual health services on the Brook website: <br />
                      <br />{' '}
                      <a
                        href="http://www.brook.org.uk/privacy-and-confidentiality"
                        className="text-a-tag"
                        target="_blank"
                      >
                        http://www.brook.org.uk/privacy-and-confidentiality
                      </a>
                    </p>
                  )}
                </div>
                <div className="single-row">
                  {/* <div>
                  <input
                    type="checkbox"
                    id="vehicle1"
                    onChange={e => {
                      setIsEmail(e.target.checked)
                    }}
                    className="form-check-input check-style"
                  />
                </div>
                <div className="style-subdrop ml-4 mt-2 mb-3">
                  I would prefer to be emailed rather than called on my mobile
                </div> */}
                </div>
              </div>
            </div>
            <div>
              <div className="ft-tab brook-container p-color">
                <button className="float-left no-background-border" id="callYou_id" onClick={(e) => GoBackPage()}>
                  <img src={backIcon} alt="back" />
                </button>
                {showFinish && (
                  <Button
                    id="u_16_completion"
                    isDisabled={false}
                    buttonClassName="btn btn-default btn-main"
                    buttonText="Finish"
                    onClick={(e) => proceed()}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  )
}
