import React, { useCallback, useContext, useEffect, useState } from 'react'

import moment from 'moment'
import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import xIcon from '../assets/images/X_popup.svg'
import '../styles/dateofbirth.css'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import { getTFourPlusDisease } from '../utils/getTFourPlusDisease'

interface SuccesspostcodeProps {
  Props: any
}

export const DateOfBirth: React.FC<SuccesspostcodeProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)
  const [dob, selectDOB] = useState({ day: '', month: '', year: '' })
  const [disableyear, setDisableYear] = useState(false)
  const [yearList, SetyearList] = useState({})
  const history = useHistory()
  const currentage = theme.age ? theme.age : 0
  const currentYear = new Date().getFullYear() - currentage
  const [yearOptions, setYearOptions] = useState<any>()
  const [error, setError] = useState(false)
  const isTFourPlusDisease = getTFourPlusDisease(theme)

  function getYears(startYear: number, currentYear: number) {
    const years = []
    startYear = startYear || 1980
    while (startYear <= currentYear) {
      years.push(`${startYear++}`)
    }
    return years
  }

  useEffect(() => {
    if (theme.age) {
      themeDispatch({
        type: 'SET_DOB',
        payload: { date_of_birth: dob.year + '-' + dob.month + '-' + dob.day },
      })
      if (theme.age) {
        //   const age = theme.age ? theme.age : 0
        //   const year = new Date().getFullYear() - age
        //   const years = Array.from(new Array(age), (val, index) => index + year)
        //   let select: HTMLSelectElement | null = document.getElementById('dob-year') as HTMLSelectElement
        //   if (select) select.options.length = 0

        //   let opt = document.createElement('option')
        //   opt.value = ''
        //   opt.innerHTML = 'Year'
        //   if (select) select.appendChild(opt)

        //   years.map((val) => {
        //     let opt = document.createElement('option')
        //     opt.value = val.toString()
        //     opt.innerHTML = val.toString()
        //     if (select) select.appendChild(opt)
        //   })

        //   setDisableYear(false)
        // } else {
        const age = theme.age ? theme.age : 25
        const year = new Date().getFullYear() - age

        let select: HTMLSelectElement | null = document.getElementById('dob-year') as HTMLSelectElement
        if (select) select.options.length = 0

        let opt = document.createElement('option')
        opt.value = year.toString()
        opt.innerHTML = year.toString()
        if (select) select.appendChild(opt)
        if (theme.age === 25) {
          setYearOptions(getYears(year - 35, year).reverse())
        } else if (theme.age === 12) {
          setYearOptions(getYears(year - 1, new Date().getFullYear()))
          // setYearOptions(getYears(year - 12, year).reverse())
        } else {
          setYearOptions(getYears(year - 1, year).reverse())
        }
        selectDOB({
          year: theme.date_of_birth.split('-')[0],
          month: theme.date_of_birth.split('-')[1] || '',
          day: theme.date_of_birth.split('-')[2] || '',
        })

        setDisableYear(true)
      }
    }

    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: '50' },
    })
  }, [])

  useEffect(() => {
    if (yearOptions && yearOptions.length > 0 && !yearOptions.includes(dob.year)) {
      selectDOB({
        year: '',
        month: dob.month,
        day: dob.day,
      })
    }
  }, [yearOptions])

  function isValidDate(dates: any) {
    let date = moment(dates)
    return date.isValid()
  }
  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('date_of_birth')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  const proceed = useCallback(() => {
    let validDate = isValidDate(dob.year + '-' + dob.month + '-' + dob.day)
    if (!validDate) {
      setError(true)
      return false
    }
    setError(false)
    themeDispatch({
      type: 'SET_DOB',
      payload: { date_of_birth: dob.year + '-' + dob.month + '-' + dob.day },
    })
    themeDispatch({
      type: 'SET_AGE',
      payload: { age: moment().diff(dob.year + '-' + dob.month + '-' + dob.day, 'years') },
    })
    if (theme?.local_authority.toLowerCase() === 'manchester') {
      if ((moment().diff(dob.year + '-' + dob.month + '-' + dob.day, 'years') > 19) || (moment().diff(dob.year + '-' + dob.month + '-' + dob.day, 'years') < 13)) {
        Props.history.push('/agebarrier')
      } else {
        Props.history.push('/mobileNumber')
      }
    }
    else if(isTFourPlusDisease){
      if (moment().diff(dob.year + '-' + dob.month + '-' + dob.day, 'years') < 13) {
        Props.history.push('/agebarrier')
      } else {
        Props.history.push('/mobileNumber')
      }
    }
     else {
      if (moment().diff(dob.year + '-' + dob.month + '-' + dob.day, 'years') > 24) {
        Props.history.push('/agebarrier')
      } else {
        Props.history.push('/mobileNumber')
      }
    }
  }, [dob])

  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }

  return (
    <div className={`bg-color dateofbirth-style ${theme.theme}-skin-color`}>
      {error && (
        <div className="invalid-date-t  info-popup fo-title">
          <img
            src={xIcon}
            className="invalid-date-x-icon"
            alt="back"
            onClick={(e) => {
              setError(false)
            }}
          />
          <div className="p4-style-title">
            <div className="p5-style fo-style">Invalid date !</div>
          </div>
        </div>
      )}
      <div className="brook-container pad-bot">
        <div className="text-to-speech">
          <div className="h-div mt-4">
            <h2 className={`page-header-txt ${theme.theme}-txt-global`}>ORDER A TEST KIT</h2>
            <div className="speaker-i">
              <button
                className="no-background-border"
                onClick={(e) => {
                  TextToSpeech.getInstance().playVoice('text-to-speech')
                  setisAccessibilityVoiceOver(true)
                }}
              >
                <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
              </button>
            </div>
          </div>

          <div className={`b1-style ${theme.theme}-txt-global`}>Date of birth</div>
          <div className="dateofbirth-head">
            <select
              id="date_of_birth"
              className="form-select select-style"
              aria-label="Date of birth"
              disabled={error}
              value={dob.day}
              onChange={(e) => {
                selectDOB({
                  day: e.target.value,
                  month: dob.month,
                  year: dob.year,
                })
              }}
            >
              <option value="">Date</option>
              <option value="01">1</option>
              <option value="02">2</option>
              <option value="03">3</option>
              <option value="04">4</option>
              <option value="05">5</option>
              <option value="06">6</option>
              <option value="07">7</option>
              <option value="08">8</option>
              <option value="09">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
              <option value="25">25</option>
              <option value="26">26</option>
              <option value="27">27</option>
              <option value="28">28</option>
              <option value="29">29</option>
              <option value="30">30</option>
              <option value="31">31</option>
            </select>
            <select
              className="form-select select-style ml-2"
              value={dob.month}
              onChange={(e) => {
                selectDOB({ day: dob.day, month: e.target.value, year: dob.year })
              }}
              disabled={error}
            >
              <option value="">Month</option>
              <option value="01">January</option>
              <option value="02">February</option>
              <option value="03">March</option>
              <option value="04">April</option>
              <option value="05">May</option>
              <option value="06">June</option>
              <option value="07">July</option>
              <option value="08">August</option>
              <option value="09">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
            {yearOptions && yearOptions.length > 0 && (
              <select
                disabled={error}
                className="form-select select-style ml-2"
                // id="dob-year"
                value={dob.year}
                onChange={(e) => {
                  selectDOB({ day: dob.day, month: dob.month, year: e.target.value })
                }}
              >
                <option value="">Year</option>
                {yearOptions.length > 0 &&
                  yearOptions.map((item: number) => <option value={`${item}`}>{`${item}`}</option>)}
              </select>
            )}
          </div>
          <div className={`p3-style p2-style mt-4 mb-3 ${theme.theme}-txt-global`}>
            Please enter your date of birth carefully and make sure you select the right year, otherwise it may cause a
            delay in sending out your kit.
          </div>
          <div>
            <div className={`ft-tab brook-container form-width ${theme.theme}-skin-color`}>
              <button className="float-left no-background-border" onClick={(e) => GoBackPage()}>
                <img src={backIcon} alt="back" />
              </button>
              <Button
                isDisabled={!(dob.day !== '' && dob.month !== '' && dob.year !== '')}
                buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                buttonText="Next"
                onClick={proceed}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
