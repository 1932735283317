import React, { useCallback, useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../../assets/images/Back_Button.svg'
import Icon from '../../assets/images/Speaker_Icon.svg'
import { Accordion } from '../../components/accordian/Accordian'
import { Button } from '../../components/button/Button'
import TextToSpeech from '../../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../../context/provider/ThemeContext'
import '../../styles/confirmemail.css'

interface WhatNextProps {
  children: any
}

export const Under16sti: React.FC<WhatNextProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)

  const history = useHistory()

  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('under16')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  const proceed = useCallback(() => {
    // Props.history.push('/finalScreen')
  }, [])
  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }
  return (
    <div className="bg-color under16-style">
      <div className="text-to-speech brook-container pad-bot">
        <div className="h-div mt-4">
          <h2 className="page-header-txt">ORDER A TEST KIT</h2>
          <div className="speaker-i">
            <button
              className="no-background-border"
              onClick={(e) => {
                TextToSpeech.getInstance().playVoice('text-to-speech')
                setisAccessibilityVoiceOver(true)
              }}
            >
              <img src={Icon} alt="speaker" />
            </button>
          </div>
        </div>
        <div className={`b1-style  ${theme.theme} mb-4`}>
          <p className="pt-0">
            We can't send STI test kits to people under 16, but we can still help you do an STI test and access any
            other support you need.
          </p>
          <p>If you carry on, we will ask you for your name and some contact details so we can give you a call.</p>
          <p>
            {' '}
            Someone from Brook will try contacting you by phone first. They may then try sending you a text or email if
            they are unable to reach you by phone.{' '}
          </p>
          <div className="accordian-under16">
            <Accordion
              themeName="postcodeBarrier"
              labelBgClass="personel_info"
              panels={[
                {
                  id: 'under16',
                  label: `I'D RATHER CONTACT BROOK`,
                  content: child(theme),
                },
              ]}
            />
          </div>
        </div>
        <div>
          <div className="ft-tab brook-container p-color">
            <button className="float-left no-background-border" onClick={(e) => GoBackPage()}>
              <img src={backIcon} alt="back" />
            </button>
            <Button
              id="undr_16_sti"
              isDisabled={false}
              buttonClassName="btn btn-default btn-main"
              buttonText="Proceed"
              onClick={(e) => {
                // history.push('/contactUserName');
                history.push('/preinfo')
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const child = (theme: any) => {
  const tabIndex = 0
  return (
    <>
      <div className="p3-style p2-style">
        <p>
          Brook Manchester: call 0161 237 3001 or visit <br />
          <a href="https://www.brook.org.uk/service/brook-manchester" className="text-a-tag " target="_blank">
            www.brook.org.uk/service/brook-manchester
          </a>
          <span className="ml-2">for more information.</span>
        </p>
        <p>
          Brook Dudley: call 01384 881830 or visit <br />
          <a href="https://www.brook.org.uk/dudley/" className="text-a-tag" target="_blank">
          https://www.brook.org.uk/dudley
          </a>
          <span className="ml-2">for more information.</span>
        </p>
        <p>
          Brook Thurrock: call 01375 800260 or visit <br />
          <a href="https://www.brook.org.uk/thurrock/" className="text-a-tag " target="_blank">
          https://www.brook.org.uk/thurrock
          </a>
          <span className="ml-2">for more information.</span>
        </p>
        <p>
          Not sure which one is your local service? Search at{' '}
          <a href="https://www.brook.org.uk/find-a-service" className="text-a-tag" target="_blank">
            www.brook.org.uk/find-a-service{' '}
          </a>{' '}
        </p>
      </div>
    </>
  )
}
