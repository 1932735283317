import { ILoginPayload } from '../../models/Types'
import apiFunctions from '../api-service'
import appUrls from '../AppURLs'

let siteUrl = process.env.REACT_APP_SERVICE_BACKEND_URL
export function logout() {
  const accessToken = localStorage.getItem('access-token')
  const tokendata = {
    auth_token: accessToken,
  }
  apiFunctions.post(siteUrl + '/auth/logout', tokendata).then((res) => {
    sessionStorage.clear()
    localStorage.clear()
    return (window.location.href = `${appUrls.admin.auth.login}?refresh=true`)
  })
}

export function getUserInfo() {
  return apiFunctions.get(siteUrl + '/auth/get-user-data').then((user) => {
    if (`${user.data.data.user.role}`) {
      localStorage.setItem('role', user.data.data.user.role)
      return user
    } else {
      const accessToken = localStorage.getItem('access-token')
      const tokendata = {
        auth_token: accessToken,
      }
      apiFunctions.post(siteUrl + '/auth/logout', tokendata).then(() => {
        sessionStorage.clear()
        localStorage.clear()
        window.location.href = `${appUrls.admin.auth.login}?refresh=true`
      })
    }
  })
}

export function login(payload: ILoginPayload) {
  return apiFunctions.post(siteUrl + '/auth/login', payload).then((user) => {
    return user as any
  })
}

export function validateLogin() {
  return apiFunctions.get(siteUrl + '/third-party/validate-token').then((res) => {
    return res as any
  })
}

export async function getExternalUserToken(region_id: string | null) {
  try {
    return await apiFunctions.get(siteUrl + '/third-party/get-external-user-token?region_id='+region_id)
  } catch (ex) {
    console.log(ex)
  }
}

export async function getActiveRegionData() {
  try {
    return await apiFunctions.get(siteUrl + '/third-party/get-active-region')
  } catch (ex) {
    console.log(ex)
  }
}

export async function createExternalUser(region_id:string | null) {
  return await apiFunctions.post(siteUrl + '/third-party/create-external-user', {trustId:region_id})
}
// export function signup(payload: IUserInfo) {
//   return apiFunctions.get(siteUrl+'/admin/get-user-data').then((user) => {
//     return user;
//   });
// }
