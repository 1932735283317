import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom';

// import Popup from '../../../../components/admin/popup';

import { SecNav } from '../../../../components/admin/SecNav';
import { TableView } from '../../../../components/admin/TableView';
// import { TriNav } from '../../../../components/admin/TriNav';
import { useLocationContext } from '../../../../context/provider/LocationContext';
import { getPostcodeRegion, getUserRegionLocations } from '../../../../services/api/PostcodesAPI';
import appUrls from '../../../../services/AppURLs';
import { AdminLayout } from '../../AdminLayout';
import { authPriNavMenu, authManageSettingsSecNav } from '../../AdminOptions';
import '../../../../styles/permManagement.css';
import FetchData from '../forms/FetchData';
import { ManagerList } from './ManagerList';

interface SetDefaultPermissionsProps {

}

export const SetDefaultPermissions: React.FC<SetDefaultPermissionsProps> = ({ }) => {
    const [showPopup, setshowPopup] = useState(false);
    const [UserData, setUserData] = useState({
        "user_id": "",
        "name": "",
        "email": "",
        "password_last_update": ""
    });
    const history = useHistory()
    const [regionListSelect, setRegionListSelect] = useState([])
    const [dropDown, setDropDown] = useState(false)
    const { state: { locationList }, dispatch } = useLocationContext();

    useEffect(() => {
        const userRole = localStorage.getItem('role');
        if (userRole === "2") {
            history.push(appUrls.admin.settings.userPermission);
        }
    }, [])

    // API call for region
    useEffect(() => {
        async function getRegion() {
            try {
                const regionList = await getUserRegionLocations();
                if (regionList) {
                    if (regionList.data.data.locations.length === 0 && localStorage.getItem('role') === '1') {
                        const allLocationList = await getPostcodeRegion();
                        if (allLocationList) {
                            const modSkeleton: any = [];
                            allLocationList.data.data.map((item: any) => modSkeleton.push({
                                name: item,
                                status: true
                            }));
                            dispatch({ type: 'SET_LOCATION', data: modSkeleton });
                            setRegionListSelect(modSkeleton)
                        }
                    } else {
                        dispatch({ type: 'SET_LOCATION', data: regionList.data.data.locations });
                        setRegionListSelect(regionList.data.data.locations)
                    }
                }
            } catch (error) {
                console.log('regionListError', error)
            }
        }
        getRegion()
    }, [])

    const closeModal = () => {
        setshowPopup(false)
    }

    const tableHead = { type: 'defaultPermissions', titles: ['Role', 'View client data', 'Manage flags', 'Contact clients'] };

    const App = FetchData(process.env.REACT_APP_SERVICE_BACKEND_URL + '/admin/get-default-permissions')(TableView)({ tableHead })
    const tabIndex = 0;
    return (
        <AdminLayout getMenuList={authPriNavMenu} roleMode="Super admin mode" sideBar="Settings">
            <SecNav getMenuList={authManageSettingsSecNav} />
            <ManagerList />
        </AdminLayout>
    );
    // return (
    //     <AdminLayout getMenuList={authPriNavMenu} roleMode="Super admin mode" sideBar="Settings">
    //         <SecNav getMenuList={authManageSettingsSecNav} />
    //         <h1 className="page-heading" tabIndex={tabIndex}>Change settings-s</h1>
    //         <TriNav getMenuList={authManageSettingTriNav}>
    //             {/* <button type="button" className="btn btn-primary" onClick={e => { setshowPopup(true) }}>Create new role</button> */}
    //         </TriNav>
    //         <App />
    //         <Popup showNewRoleRequest={showPopup} userdata={UserData} close={closeModal} />
    //     </AdminLayout>
    // );
}