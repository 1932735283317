import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom';

import { PermissionManagementModal } from '../../../../components/admin/PermissionManagementModal';
import Popup from '../../../../components/admin/popup';
import { SecNav } from '../../../../components/admin/SecNav';
import { DropdownSubmodule } from '../../../../components/dropdownCascade/DropdownSubmodule';
import Loader from '../../../../components/Loader/loader'
import { CustomDataTable } from '../../../../components/table/CustomDataTable';
import useAuthContext from '../../../../context/provider/AuthContext';
import apiFunctions from '../../../../services/api-service'
import appUrls from '../../../../services/AppURLs';
import { AdminLayout } from '../../AdminLayout';
import { authPriNavMenu, authManageSettingsSecNav } from '../../AdminOptions';
import { PermissionMgmtMenuList } from './PermissionMgmtMenuList';

interface UsersListProps {

}

export const UsersList: React.FC<UsersListProps> = ({ }) => {
  let url: any = process.env.REACT_APP_SERVICE_BACKEND_URL;
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const [userListData, setUserListData] = useState([]);
  const [existUser, serExistUser] = useState<any>({});
  const [selectedUsersId, setSelectedUsersId] = useState<any>([]);
  const [showPoup, setShowpoup] = useState(false);
  const [staffdata, setStaffdata] = useState();
  const [dotMenu, setdotMenu] = useState<any>([
    {
      value: 'changeRole',
      label: 'Change account type',
      children: [
        {
          value: 'manager',
          label: 'Manager'
        },
      ]
    },
    {
      value: 'archive',
      label: 'Archive'
    }
  ]);
  const [internalState, setInternalState] = useState<any>({
    actionType: '',
    pageNumber: 1,
    inc: 0,
    isModalVisible: false
  })
  const titleModal = `${internalState.actionType === 'archive' ?
    "Are you sure you want to archive selected team members ?" :
    internalState.actionType === 'manager' ?
      "Are you sure you want to change roles of selected user to managers ?" :
      ""}`;


  useEffect(() => {
    getManagersList();
  }, []);

  const {
    state: { user },
  } = useAuthContext()
  const dataval: any = user;

  let permissionAccess = dataval?.user ? dataval.user : dataval;

  let permissionAccessArchive = dataval?.user ? dataval.user : dataval;

  permissionAccessArchive = permissionAccessArchive?.permissions?.team_members?.filter((ele: any) => { return ele.name === 'archive_team_member' });

  permissionAccess = permissionAccess?.permissions?.team_members?.filter((ele: any) => { return ele.name === 'change_team_member_role' });


  const getManagersList = async () => {
    const URL = url + '/admin/get-all-active-users'
    const response = await apiFunctions.get(URL);
    serExistUser(response?.data?.data?.existUser)
    setUserListData(response?.data?.data?.result);
    setLoader(false)
  }
  const getactionType = (selectedValue: string) => {
    setInternalState({
      ...internalState,
      actionType: selectedValue,
      isModalVisible: true
    });
  };

  const renderVerticalDotMenu = () => {
    let dotMenuValue: any;
    if (permissionAccess && permissionAccess[0].value === true) {
      dotMenuValue = dotMenu.filter((item: any) => item.value !== 'archive');
    }
    if (permissionAccessArchive && permissionAccessArchive[0].value === true) {
      dotMenuValue = dotMenu.filter((item: any) => item.value !== 'changeRole');
    }
    if ((permissionAccessArchive && permissionAccessArchive[0].value === true) && (permissionAccess && permissionAccess[0].value === true)) {
      dotMenuValue = dotMenu;
    }

    return (
      <DropdownSubmodule
        menus={dotMenuValue}
        actionType={getactionType}
      />
    )
  };

  const columns: any = [
    {
      name: selectedUsersId.length > 0 ? renderVerticalDotMenu() : '',
      minWidth: '50px',
      maxWidth: '50px'
    },
    {
      name: 'Name',
      maxWidth: '400px',
      selector: (row: any) => (
        <div className='d-flex'>
          <span className='name-column' onClick={() => showStaffdetails(row)}>{row.name}</span>
        </div>
      )
    },
    {
      name: 'Permissions',
      maxWidth: '300px',
      cell: (row: any) => (
        <div className='d-flex'>
          <span className='count-label'>{row.permissionsCount}</span> <br />
          <span className='edit-label' onClick={() => handleEdit(row)}>Edit</span>
        </div>
      )
    },
    {
      name: 'Email',
      maxWidth: '500px',
      minWidth: '400px',
      selector: (row: any) => row.email,
    }
  ];

  const handleEdit = (selectedItem: any) => {
    history.push(appUrls.admin.settings.editUser + `?user_id=${selectedItem.id}`);
  };

  const handlePagination = (page: number) => {
    setInternalState({
      ...internalState,
      pageNumber: page,
      inc: page * 5 - 5
    });
  }
  const handleCloseModal = () => {
    setInternalState({
      ...internalState,
      isModalVisible: false
    });
  };
  const onRowSelected = (selectedRow: any) => {
    setSelectedUsersId(selectedRow.map((obj: any) => obj.id))
  };

  // User Archive
  const managersArchive = async () => {
    setLoader(true)
    const URI = url + '/admin/archive-unarchive-administrators';
    try {
      const response = await apiFunctions.put(URI, { type: 'archive', user_ids: selectedUsersId });
      setInternalState({
        ...internalState,
        isModalVisible: false
      });
      await getManagersList();
      setLoader(false)
    } catch (err) {
      setLoader(false)
      console.log(err);

    }
  };

  // User Change Role
  const userRoleChange = async () => {

    setLoader(true)
    const URI = url + '/admin/change-administrators-role';
    try {
      const response = await apiFunctions.put(URI, { type: internalState.actionType, user_ids: selectedUsersId });
      setInternalState({
        ...internalState,
        isModalVisible: false
      });
      await getManagersList();
      setLoader(false)
    } catch (err) {
      console.log(err);
      setLoader(false)
    }
  };

  const getAddTeamMemberPermission = (P_Type: string) => {
    const permission = existUser && existUser.permissions && existUser.permissions.team_members ? existUser.permissions.team_members.filter((res: any) => { return res.name === P_Type }) : [];
    return permission.length && permission[0].value;
  }

  // AddTeamMemberPermission
  const getSelectRowPermission = () => {
    const permission = existUser && existUser.permissions && existUser.permissions.team_members ? existUser.permissions.team_members.filter((res: any) => { return (res.name === 'archive_team_member' && res.value === true) || (res.name === 'change_team_member_role' && res.value === true) }) : [];
    return permission.length ? true : false;
  }

  const showStaffdetails = (uservalue: any) => {
    setStaffdata(uservalue)
    setShowpoup(true)
  }
  const closeModal = () => {
    setShowpoup(false)
  }

  return (
    <AdminLayout getMenuList={authPriNavMenu} roleMode="Super admin mode" sideBar="Settings">
      {loader && <Loader />}
      <SecNav getMenuList={authManageSettingsSecNav} />
      <div className='perm-mgmt-block'>
        <PermissionMgmtMenuList addMemebrPresmission={getAddTeamMemberPermission('create_team_member')} />
        <div className='perm-mgmt-content-blk users-blk'>
          <h5 className='role-title'>Users</h5>
          <CustomDataTable
            data={userListData}
            columns={columns}
            rowsPerPage={5}
            paginationTotalRows={userListData.length}
            onChangePagination={handlePagination}
            inc={internalState.inc}
            onRowSelected={onRowSelected}
            selectedRow={getSelectRowPermission()}

          />
        </div>
        {/* render popup */}
        {internalState.isModalVisible ?
          <PermissionManagementModal
            title={titleModal}
            SelectType={internalState.actionType}
            handleCloseModal={handleCloseModal}
            handlePositiveButton={managersArchive}
            userRoleChange={userRoleChange}
          /> : null}
        <Popup showAdminProfile={showPoup} close={closeModal} userdata={staffdata} />
      </div>
    </AdminLayout>
  );
}