import React, { useCallback, useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import '../styles/dateofbirth.css'
import '../styles/mobilenumber.css'
// import xIcon from '../assets/images/X_popup.svg'
import { Button } from '../components/button/Button'
// import ErrorPopup from '../components/ModalPopup/ErrorPopup'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import apiFunctions from '../services/api-service'

interface SuccesspostcodeProps {
  Props: any
}

export const MobileNumber: React.FC<SuccesspostcodeProps> = (Props: any) => {


  const { theme, themeDispatch } = useContext<any>(ThemeContext)
  const validEmail =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const validPhone = /^([0][7]|[0][9])\d{9}/
  const [isEmail, setIsEmail] = useState(false)
  const [isPhone, setisPhone] = useState(false)
  const [showErrorMsg, setshowErrorMsg] = useState('')
  const [cellNumerIssue, setCellNumerIssue] = useState(false)
  const [emailIssue, setEmailIssue] = useState(false)
  const [name, setName] = useState<{
    prefix: string
    phone_no: string
    email: string
  }>({ prefix: '+44', phone_no: '', email: '' })

  const history = useHistory()

  const [showdisclaim, setshowdisclaim] = useState(true)
  
  useEffect(() => {
    if (theme.quesObj.isemailContact) {
      setIsEmail(theme.quesObj.isemailContact)
    }

    if (theme.phone_no !== '')
      setName({
        prefix: '+44',
        phone_no: theme.phone_no,
        email: theme.email.trim(),
      })
    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: '55' },
    })
  }, [])

  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker
  const [blockedNumbers, setBlockedNumbers] = useState(['07817988105', '07720171538', '07466107448', '07817988105'])
  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('mobile_num_id')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //


  const proceed = useCallback(async () => {
    TextToSpeech.getInstance().playVoice(null, 'stop')
    setisAccessibilityVoiceOver(false)

    if (!Boolean(name.phone_no.length > 10 && validPhone.test(name.phone_no))) {
      setCellNumerIssue(true)
      return
    } else {
      setCellNumerIssue(false)
    }
    if (!Boolean(validEmail.test(name.email.trim()))) {
      setEmailIssue(true)
      const timer = setTimeout(() => {
        setEmailIssue(false)
      }, 6000)
      return
    } else {
      setEmailIssue(false)
    }

    await apiFunctions
      .post(process.env.REACT_APP_SERVICE_BACKEND_URL + '/user/checkSTI', {
        email: name.email.trim(),
        phone_no: name.phone_no,
      })
      .then((_result) => {
        if (!theme.age) {
          window.location.href = '/postcode-age'
          return
        }

        themeDispatch({
          type: 'SET_QUES',
          payload: {
            quesObj: {
              ...theme.quesObj,
              isemailContact: isEmail,
              isPhoneContact: !isEmail,
            },
          },
        })

        themeDispatch({
          type: 'SET_PHONE',
          payload: {
            phone_no: name.phone_no,
            email: name.email.trim(),
          },
        })
        if (theme.age && theme.age >= 16 && theme.age <= 17) {
          Props.history.push('/order-confirmation')
          // Props.history.push('/userunder18')
        } else if (theme.age && theme.age <= 15) {
          Props.history.push('/callyou')
        } else if (theme.age) {
          Props.history.push('/order-confirmation')
        }
      })
      .catch((err) => {
        setshowErrorMsg(err.response.data.message)
        if(err?.response?.data?.data){
          err?.response?.data?.data === 'check_order_complete'
            ? window.location.href =  process.env.REACT_APP_INCOMPLETE_ORDER || ''
            : window.location.href = process.env.REACT_APP_LIMIT_REACHED ||''
        }
        return false
      })
  }, [name, isEmail])

  function GoBackPage() {
    history.goBack()
    // CloseModel()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }

  // function CloseModel() {
  //   setshowErrorMsg('')
  // }

 

  const tabIndex = 0
  return (
    <div className={`bg-color mobilenumber-style ${theme.theme}-skin-color`}>
      <div className="text-to-speech brook-container pad-bot">
        <div className="h-div mt-4">
          <h2 className={`page-header-txt ${theme.theme}-txt-global`}>ORDER A TEST KIT</h2>
          <div className="speaker-i">
            <button
              className="no-background-border"
              onClick={(e) => {
                TextToSpeech.getInstance().playVoice('text-to-speech')
                setisAccessibilityVoiceOver(true)
              }}
            >
              <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
            </button>
          </div>
        </div>
        {showdisclaim && (
          <>
            <form autoComplete="off">
              <div className={`b1-style ${theme.theme}-txt-global`}>Mobile Number</div>
              <div className="mob-number">
                <input
                  id="mobile_num_id"
                  type="text"
                  aria-label="mobile code"
                  className="form-control input-style mob-number-prefix"
                  value={name.prefix}
                  onChange={(e) => {
                    setName({
                      prefix: e.target.value.length < 5 ? e.target.value : name.prefix,
                      phone_no: name.phone_no,
                      email: name.email.trim(),
                    })
                  }}
                  disabled={showErrorMsg !== '' ? true : false}
                />
                <input
                  type="text"
                  maxLength={15}
                  className="form-control input-style mobNumber"
                  value={name.phone_no}
                  placeholder="Enter your Mobile Number"
                  aria-label="Enter your Mobile Number"
                  onChange={(e) => {
                    setName({
                      prefix: name.prefix,
                      phone_no: String(e.target.value.replace(/\s+/g, '')),
                      email: name.email.trim(),
                    })
                  }}
                  disabled={showErrorMsg !== '' ? true : false}
                />
              </div>
              {cellNumerIssue && (
                <span className="fo-pos errorMessage" role="alert" aria-live="assertive">
                  Please enter valid mobile number.
                </span>
              )}
              {blockedNumbers.includes(name.phone_no) && (
                <span className="fo-pos errorMessage" role="alert" aria-live="assertive">
                  You request can't be processed.
                </span>
              )}
            </form>
            <div className={`p3-style p2-style mt-4 ${theme.theme}-txt-global`}>
              Please enter your full number (including the 0 at the start) and double check that it is correct.
            </div>
            <div className={`p3-style p2-style mt-4 ${theme.theme}-txt-global txt-highlight`}>
              We will use this number to contact you with your test results. We may contact you by text message or call.
            </div>
            <div className={`b1-style ${theme.theme}-txt-global`}>Email address</div>
            <p className={`p3-style p2-style mb-4 ${theme.theme}-txt-global`}>
              We will use this email address as a back-up method of contact if we can’t reach you by phone
              {theme && theme.age >= 16 && (
                <span>, and to send you confirmation of your order if you select ‘yes’ to the next question. </span>
              )}
              {theme && theme.age <= 15 && <span>.</span>}
            </p>
            <input
              type="text"
              className="form-control input-style"
              value={name.email.trim()}
              placeholder="Enter your Email Address"
              aria-label="Enter your Email Address"
              onChange={(e) => {
                setName({
                  prefix: name.prefix,
                  phone_no: name.phone_no,
                  email: String(e.target.value),
                })
              }}
              disabled={showErrorMsg !== '' ? true : false}
            />
            {emailIssue && (
              <span className="fo-pos errorMessage" role="alert" aria-live="assertive">
                Email address is not valid.
              </span>
            )}
            <br />
            {/* <div className="single-row mt-4">
              <div>
                <input
                  type="checkbox"
                  checked={isEmail}
                  onChange={e => {
                    setIsEmail(e.target.checked)
                  }}
                  className="form-check-input check-style"
                />
              </div>
              <div className={`ml-4 p2-style fo-pos mb-3 ${theme.theme}-txt-global`}>Fsexual health clinic
                I would prefer to be contacted via email than over the phone.
              </div>
            </div> */}
          </>
        )}
        {!showdisclaim && (
          <div className="b1-style">
            As you are under 18, we may be able to offer you extra help and support if appropriate. Please tick any of
            the following if they apply you.
          </div>
        )}
        <div>
          <div className={`ft-tab brook-container form-width ${theme.theme}-skin-color`}>
            <button className="float-left no-background-border" onClick={(e) => GoBackPage()}>
              <img src={backIcon} alt="back" />
            </button>
            <Button
              isDisabled={false || blockedNumbers.includes(name.phone_no)}
              buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
              buttonText="Next"
              onClick={proceed}
            />
          </div>
        </div>
      </div>
      {/* {showErrorMsg !== '' && (
        <div className="popup-info-section">
          <div
            className="info-popup internal-error-popup"
            onClick={(e) => {
              CloseModel()
            }}
          >
            <img src={xIcon} className="x-icon" alt="back" />
            <div className="p4-style-title">
              <div className={`p5-style fo-style ${theme.theme}-txt-global`}>{showErrorMsg}</div>
            </div>
          </div>
        </div>
      )} */}
      {/* <ErrorPopup
        showErrorMsg={showErrorMsg}
        setshowErrorMsg={setshowErrorMsg}
        headerClass={`p5-style fo-style ${theme.theme}-txt-global`}
      /> */}

    </div>
  )
}
