import React, { useCallback, useContext, useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import { Accordion } from '../components/accordian/Accordian'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import '../styles/preinfo.css'
import '../styles/common.css'
import { getTFourPlusDisease } from '../utils/getTFourPlusDisease'
import { stiKitLoaderPercentage } from '../utils/stiKitLoaderPercentage'

interface PostcodeProps {
  Props: any
}

export const PreInfo: React.FC<PostcodeProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)

  const [pageNo, setPageNo] = useState(1)

  const history = useHistory()

  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //

  const accessibilityId = 'perinfo' + pageNo

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById(accessibilityId)
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  useEffect(() => {
    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: stiKitLoaderPercentage.PRE_INFO },
    })
  }, [])

  const proceed = useCallback((__pageNo?: number) => {
    const isTFourPlusDisease = getTFourPlusDisease(theme)
    setisAccessibilityVoiceOver(false)
    if (__pageNo) {
      const preInfoPercentage = Number(stiKitLoaderPercentage.PRE_INFO)
      themeDispatch({
        type: 'SET_PERCENTAGE',
        payload: { percentage: String(preInfoPercentage + __pageNo) },
      })
      // TextToSpeech.getInstance().stopSpeech()
      TextToSpeech.getInstance().playVoice(null, 'stop')
      setPageNo(__pageNo)
    } else {
      if ((isTFourPlusDisease === true) && theme.age && theme.age >= 16) {
        history.push('/typeofsex')
      } else if ((isTFourPlusDisease === true) && theme.age && theme.age < 16) {
        history.push('/contactUserName')
      } else if (!(isTFourPlusDisease === true) && theme.age && theme.age < 16) {
        history.push('/contactUserName')
      } else if (!(isTFourPlusDisease === true) && theme.age && theme.age >= 16) {
        TextToSpeech.getInstance().playVoice(null, 'stop')
        // TextToSpeech.getInstance().stopSpeech()
        // Props.history.push('/postcode')
        history.push('/typeofsex')
      } else {
      }
    }
  }, [])
  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }
  const tabIndex = 0
  const isTFourPlusDisease = getTFourPlusDisease(theme)

  if (isTFourPlusDisease === true) {
    return (
      <>
        {pageNo === 1 && (
          <div className={`bg-color preinfo-style ${theme.theme}-global`}>
            <div className="brook-container pad-bot">
              <div className="text-to-speech">
                <div className="h-div mt-4">
                  <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
                  <div className="speaker-i">
                    <button
                      className="no-background-border"
                      onClick={(e) => {
                        TextToSpeech.getInstance().playVoice('text-to-speech')
                        setisAccessibilityVoiceOver(true)
                      }}
                    >
                      <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
                    </button>
                  </div>
                </div>
                <div>
                  <div className={`b1-style  ${theme.theme}-txt-global`}>
                    The kits that Brook can provide by post in your area can include a combination of a swab, urine
                    sample and blood sample for you to do at home.
                  </div>
                  <Accordion
                    themeName={theme.theme}
                    panels={[
                      {
                        id: accessibilityId,
                        label: 'Swab or urine sample for chlamydia and gonorrhoea',
                        content: urineSample(theme),
                      },
                      {
                        label: 'Blood sample for HIV and syphilis',
                        content: bloodSampleHivSyphilis(theme),
                      },
                      // {
                      //   label: 'Blood sample for Hepatitis (if recommended)',
                      //   content: bloodSampleHepatitis(theme),
                      // },
                      {
                        label: 'I want to test for something else',
                        content: testSomethingElse(theme),
                      },
                    ]}
                  />
                </div>
              </div>
              <div className={`ft-tab brook-container ${theme.theme}-skin-color`}>
                <button className="float-left no-background-border" onClick={(e) => GoBackPage()}>
                  <img src={backIcon} alt="back" />
                </button>
                <Button
                  isDisabled={false}
                  buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                  buttonText="Next"
                  onClick={(e) => proceed(2)}
                />
              </div>
            </div>
          </div>
        )}
        {pageNo === 2 && (
          <div className={`bg-color preinfo-style ${theme.theme}-global`}>
            <div className="brook-container">
              <div className="text-to-speech">
                <div className="h-div mt-4">
                  <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
                  <div className="speaker-i">
                    <button
                      className="no-background-border"
                      onClick={(e) => {
                        TextToSpeech.getInstance().playVoice('text-to-speech')
                        setisAccessibilityVoiceOver(true)
                      }}
                    >
                      <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
                    </button>
                  </div>
                </div>
                <div>
                  <br />
                  <br />
                  <br />
                  <div className={`b2-style ${theme.theme}-txt-global`}>
                    Important: It can take up to two weeks for chlamydia and gonorrhoea to show up in tests. Waiting two
                    weeks after unprotected sex to take a test will provide a more accurate result. It can take 7 weeks
                    to HIV to show accurately in tests, and up to 12 weeks for syphilis.
                  </div>
                  <Accordion
                    themeName={theme.theme}
                    panels={[
                      {
                        id: accessibilityId,
                        label: 'WHAT IS UNPROTECTED SEX ?',
                        content: unprotectedSex(theme),
                      },
                    ]}
                  />
                  <div className={`b2-style ${theme.theme}-txt-global pt-0 mt-0`}>
                    Alert: If you think you may have been exposed to HIV in the past 72 hours (3 days) you might be able
                    to get a treatment called PEP to stop the virus taking hold.
                    <br />
                    More information about PEP:{' '}
                    <a
                      className="text-a-tag pep-anchor-btn"
                      href="https://www.brook.org.uk/your-life/prep-and-pep/#what-is-pep"
                      target="_blank"
                    >
                      https://www.brook.org.uk/your-life/prep-and-pep/#what-is-pep
                    </a>
                  </div>
                </div>
              </div>
              <div className={`ft-tab brook-container ${theme.theme}-skin-color`}>
                <button className="float-left no-background-border" onClick={(e) => GoBackPage()}>
                  <img src={backIcon} alt="back" />
                </button>
                <Button
                  isDisabled={false}
                  buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                  buttonText="Next"
                  onClick={(e) => proceed(3)}
                />
              </div>
            </div>
          </div>
        )}
        {pageNo === 3 && (
          <div className={`bg-color confi-style ${theme.theme}-global`}>
            <div className="brook-container">
              <div className="text-to-speech">
                <div className="h-div mt-4">
                  <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
                  <div className="speaker-i">
                    <button
                      className="no-background-border"
                      onClick={(e) => {
                        TextToSpeech.getInstance().playVoice('text-to-speech')
                        setisAccessibilityVoiceOver(true)
                      }}
                    >
                      <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
                    </button>
                  </div>
                </div>
                <div>
                  <br />
                  <br />
                  <br />
                  <div>
                    <div className={`b2-style ${theme.theme}-txt-global`}>
                      We'll need to ask you some questions.
                      <div className={`b2-style ${theme.theme}-txt-global`}>
                        Some of them may feel quite personal, but they are necessary for us to make sure you are doing
                        the right type of test and identify if there is more support we can offer.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div className={`ft-tab brook-container ${theme.theme}-skin-color`}>
                    <button
                      className="float-left mb-3 no-background-border"
                      onClick={(e) => GoBackPage()}
                      id={accessibilityId}
                    >
                      <img src={backIcon} alt="back" />
                    </button>
                    <Button
                      isDisabled={false}
                      buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                      buttonText="Next"
                      onClick={(e) => proceed(4)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {pageNo === 4 && (
          <div className={`bg-color confi-style ${theme.theme}-global`}>
            <div className="brook-container">
              <div className="text-to-speech">
                <div className="h-div mt-4">
                  <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
                  <div className="speaker-i">
                    <button
                      className="no-background-border"
                      onClick={(e) => {
                        TextToSpeech.getInstance().playVoice(
                          null,
                          'ORDER A TEST KIT How it works. Before you fill in the test request form, would you like to find out a bit more about how the process works?'
                        )
                        setisAccessibilityVoiceOver(true)
                      }}
                    >
                      <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
                    </button>
                  </div>
                </div>
                <>
                  <div className={`b1-style ${theme.theme}-txt-global`}>How it works</div>
                  <div className={`b2-style ${theme.theme}-txt-global`}>
                    Before you fill in the test request form, would you like to find out a bit more about how the
                    process works?
                  </div>
                  <div className={`ft-tab brook-container ${theme.theme}-skin-color jus-end pre-info-footer-blk`}>
                    <div className="pad-te">
                      <div className="footer-class-info">
                        <Button
                          id={accessibilityId}
                          isDisabled={false}
                          buttonClassName={`btn btn-default btn-main btn-width ${theme.theme}-start-btn-global`}
                          buttonText="Yes, I'd like to know more"
                          onClick={(e) => proceed(5)}
                        />
                        <div className="font-a mt-4 ml-2">
                          <div className={`${theme.theme}-txt-global`}>
                            <button
                              className="underline"
                              type="button"
                              style={{ cursor: 'pointer' }}
                              onClick={(e) => {
                                proceed()
                              }}
                            >
                              I'm good, let's skip to the form
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        )}
        {pageNo === 5 && (
          <div className={`bg-color confi-style ${theme.theme}-global`}>
            <div className="text-to-speech brook-container">
              <div className="h-div mt-4">
                <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
                <div className="speaker-i">
                  <button
                    className="no-background-border"
                    onClick={(e) => {
                      TextToSpeech.getInstance().playVoice(
                        null,
                        'ORDER A TEST KIT. More information about how it works. Complete a quick online assessment  It only takes a few minutes and will determine what type of test you need. Everything you tell us is kept private and confidential.'
                      )
                      setisAccessibilityVoiceOver(true)
                    }}
                  >
                    <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
                  </button>
                </div>
              </div>
              <div className={`b1-style ${theme.theme}-txt-global`}>More information about how it works</div>
              <div className="no-style">
                <div className="row mx-0 w-100 jus-space flex-nowrap">
                  <div className="col-md-2 col-3 col-sm-3 px-0 po-style">
                    <p className={`p-style ${theme.theme}-txt-global`}>1</p>
                  </div>
                  <div className="col-md-10 col-9 col-sm-9">
                    <div className={`b4-style ${theme.theme}-txt-global`}>Complete a quick online assessment</div>
                    <div className={`b2-style ${theme.theme}-txt-global`}>
                      It only takes a few minutes and will determine what type of test you need. Everything you tell us
                      is kept private and confidential.
                    </div>
                  </div>
                </div>
              </div>
              {/* <div> */}
              <div className={`steps-footer ft-tab brook-container ${theme.theme}-skin-color jus-end b-bot`}>
                <Button
                  id={accessibilityId}
                  isDisabled={false}
                  buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                  buttonText="Next"
                  onClick={(e) => proceed(6)}
                />
                <button
                  type="button"
                  className={`font-a ${theme.theme}-txt-global underline`}
                  onClick={(e) => {
                    proceed()
                  }}
                >
                  Let's skip to the form
                </button>
              </div>
              {/* </div> */}
              {/* <div className={`a-pos ft-tab brook-container ${theme.theme}-skin-color jus-end`}>
                <button
                  type="button"
                  className={`font-a ${theme.theme}-txt-global underline`}
                  onClick={(e) => {
                    proceed()
                  }}
                >
                  Let's skip to the form
                </button>
              </div> */}
            </div>
          </div>
        )}
        {pageNo === 6 && (
          <div className={`bg-color confi-style ${theme.theme}-global`}>
            <div className="text-to-speech brook-container">
              <div className="h-div mt-4">
                <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
                <div className="speaker-i">
                  <button
                    className="no-background-border"
                    onClick={(e) => {
                      TextToSpeech.getInstance().playVoice(
                        null,
                        'ORDER A TEST KIT. More information about how it works. Your test will be despatched. Tests usually will arrive within 2 days and will be sent in a plain envelope, via first class post.'
                      )
                      setisAccessibilityVoiceOver(true)
                    }}
                  >
                    <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
                  </button>
                </div>
              </div>
              <div className={`b1-style ${theme.theme}-txt-global`}>More information about how it works</div>
              <div className="no-style">
                <div className="row mx-0 w-100 jus-space flex-nowrap">
                  <div className="col-md-2 col-3 col-sm-3 px-0 po-style">
                    <p className={`p-style ${theme.theme}-txt-global`}>2</p>
                  </div>
                  <div className="col-md-10 col-9 col-sm-9">
                    <div className={`b4-style ${theme.theme}-txt-global`}>Your test will be despatched</div>
                    <div className={`b2-style ${theme.theme}-txt-global`}>
                      Tests usually will arrive within 2 days and will be sent in a plain envelope, via first class
                      post.
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className={`steps-footer ft-tab brook-container ${theme.theme}-skin-color jus-end b-bot`}>
                  <Button
                    id={accessibilityId}
                    isDisabled={false}
                    buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                    buttonText="Next"
                    onClick={(e) => proceed(7)}
                  />
                  <button
                    type="button"
                    className={`font-a ${theme.theme}-txt-global underline`}
                    onClick={(e) => {
                      proceed()
                    }}
                  >
                    Let's skip to the form
                  </button>
                </div>
              </div>
              {/* <div className={`a-pos ft-tab brook-container ${theme.theme}-skin-color jus-end`}>
                <button
                  type="button"
                  className={`font-a ${theme.theme}-txt-global underline`}
                  onClick={(e) => {
                    proceed()
                  }}
                >
                  Let's skip to the form
                </button>
              </div> */}
            </div>
          </div>
        )}
        {pageNo === 7 && (
          <div className={`bg-color confi-style ${theme.theme}-global`}>
            <div className="text-to-speech brook-container">
              <div className="h-div mt-4">
                <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
                <div className="speaker-i">
                  <button
                    className="no-background-border"
                    onClick={(e) => {
                      TextToSpeech.getInstance().playVoice(
                        null,
                        'ORDER A TEST KIT. More information about how it works. Complete your test. Your kit will include clear instructions to help you take your own samples.'
                      )
                      setisAccessibilityVoiceOver(true)
                    }}
                  >
                    <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
                  </button>
                </div>
              </div>
              <div className={`b1-style ${theme.theme}-txt-global`}>More information about how it works</div>
              <div className="no-style">
                <div className="row mx-0 w-100 jus-space flex-nowrap">
                  <div className="col-md-2 col-3 col-sm-3 px-0 po-style">
                    <p className={`p-style ${theme.theme}-txt-global`}>3</p>
                  </div>
                  <div className="col-md-10 col-9 col-sm-9">
                    <div className={`b4-style ${theme.theme}-txt-global`}>Complete your test</div>
                    <div className={`b2-style ${theme.theme}-txt-global`}>
                      Your kit will include clear instructions to help you take your own samples.
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className={`steps-footer ft-tab brook-container ${theme.theme}-skin-color jus-end b-bot`}>
                  <Button
                    id={accessibilityId}
                    isDisabled={false}
                    buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                    buttonText="Next"
                    onClick={(e) => proceed(8)}
                  />
                  <button
                    type="button"
                    className={`font-a ${theme.theme}-txt-global underline`}
                    onClick={(e) => {
                      proceed()
                    }}
                    tabIndex={tabIndex}
                  >
                    Let's skip to the form
                  </button>
                </div>
              </div>
              {/* <div className={`a-pos ft-tab brook-container ${theme.theme}-skin-color jus-end`}>
                <button
                  type="button"
                  className={`font-a ${theme.theme}-txt-global underline`}
                  onClick={(e) => {
                    proceed()
                  }}
                  tabIndex={tabIndex}
                >
                  Let's skip to the form
                </button>
              </div> */}
            </div>
          </div>
        )}
        {pageNo === 8 && (
          <div className={`bg-color confi-style t-style ${theme.theme}-global`}>
            <div className="text-to-speech brook-container">
              <div className="h-div mt-4">
                <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
                <div className="speaker-i">
                  <button
                    className="no-background-border"
                    onClick={(e) => {
                      TextToSpeech.getInstance().playVoice(
                        null,
                        'ORDER A TEST KIT. More information about how it works. Send your completed test back. Use the freepost envelope provided and pop it in the nearest postbox.'
                      )
                      setisAccessibilityVoiceOver(true)
                    }}
                  >
                    <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
                  </button>
                </div>
              </div>
              <div className={`b1-style ${theme.theme}-txt-global`}>More information about how it works</div>
              <div className="row mx-0 w-100 jus-space flex-nowrap">
                <div className="col-md-2 col-3 col-sm-3 px-0 po-style">
                  <p className={`p-style ${theme.theme}-txt-global`}>4</p>
                </div>
                <div className="col-md-10 col-9 col-sm-9">
                  <div className={`b4-style ${theme.theme}-txt-global`}>Send your completed test back</div>
                  <div className={`b2-style ${theme.theme}-txt-global`}>
                    Use the freepost envelope provided and pop it in the nearest postbox.
                  </div>
                </div>
              </div>
              <div>
                <div className={`steps-footer ft-tab brook-container ${theme.theme}-skin-color jus-end b-bot`}>
                  <Button
                    id={accessibilityId}
                    isDisabled={false}
                    buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                    buttonText="Next"
                    onClick={(e) => proceed(9)}
                  />
                  <button
                    type="button"
                    className={`font-a ${theme.theme}-txt-global underline`}
                    onClick={(e) => {
                      proceed()
                    }}
                  >
                    Let's skip to the form
                  </button>
                </div>
              </div>
              {/* <div className={`a-pos ft-tab brook-container ${theme.theme}-skin-color jus-end`}>
                <button
                  type="button"
                  className={`font-a ${theme.theme}-txt-global underline`}
                  onClick={(e) => {
                    proceed()
                  }}
                >
                  Let's skip to the form
                </button>
              </div> */}
            </div>
          </div>
        )}
        {pageNo === 9 && (
          <div className={`bg-color confi-style t-style ${theme.theme}-global`}>
            <div className="text-to-speech brook-container">
              <div className="h-div mt-4">
                <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
                <div className="speaker-i">
                  <button
                    className="no-background-border"
                    onClick={(e) => {
                      TextToSpeech.getInstance().playVoice(
                        null,
                        'ORDER A TEST KIT. More information about how it works. You will be contacted with your results. This is usually within 3 days.'
                      )
                      setisAccessibilityVoiceOver(true)
                    }}
                  >
                    <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
                  </button>
                </div>
              </div>
              <div className={`b1-style ${theme.theme}-txt-global`}>More information about how it works</div>
              <div className="no-style">
                <div className="row mx-0 w-100 jus-space flex-nowrap">
                  <div className="col-md-2 col-3 col-sm-3 px-0 po-style">
                    <p className={`p-style ${theme.theme}-txt-global`}>5</p>
                  </div>
                  <div className="col-md-10 col-9 col-sm-9">
                    <div className={`b4-style ${theme.theme}-txt-global`}>You will be contacted </div>
                    <div className={`b2-style ${theme.theme}-txt-global`}>
                      You will be contacted with your results. This is usually within 3 days.
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className={`steps-footer ft-tab brook-container ${theme.theme}-skin-color jus-end b-bot`}>
                  <Button
                    id={accessibilityId}
                    isDisabled={false}
                    buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                    buttonText="Next"
                    onClick={(e) => proceed(10)}
                  />
                  <button
                    type="button"
                    className={`font-a ${theme.theme}-txt-global underline`}
                    onClick={(e) => {
                      proceed()
                    }}
                    tabIndex={tabIndex}
                  >
                    Let's skip to the form
                  </button>
                </div>
              </div>
              {/* <div className={`a-pos ft-tab brook-container ${theme.theme}-skin-color jus-end`}>
                <button
                  type="button"
                  className={`font-a ${theme.theme}-txt-global underline`}
                  onClick={(e) => {
                    proceed()
                  }}
                  tabIndex={tabIndex}
                >
                  Let's skip to the form
                </button>
              </div> */}
            </div>
          </div>
        )}
        {pageNo === 10 && (
          <div className={`bg-color confi-style t-style ${theme.theme}-global`}>
            <div className="text-to-speech brook-container">
              <div className="h-div mt-4">
                <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
                <div className="speaker-i">
                  <button
                    className="no-background-border"
                    onClick={(e) => {
                      TextToSpeech.getInstance().playVoice(
                        null,
                        "ORDER A TEST KIT. More information about how it works. You'll get help with accessing treatment if you need it. If your test comes back positive, we will help you access treatment and notify your partners."
                      )
                      setisAccessibilityVoiceOver(true)
                    }}
                  >
                    <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
                  </button>
                </div>
              </div>
              <div className={`b1-style ${theme.theme}-txt-global`}>More information about how it works</div>
              <div className="no-style">
                <div className="row mx-0 w-100 jus-space flex-nowrap">
                  <div className="col-md-2 col-3 col-sm-3 px-0 po-style">
                    <p className={`p-style ${theme.theme}-txt-global`}>6</p>
                  </div>
                  <div className="col-md-10 col-9 col-sm-9">
                    <div className={`b4-style ${theme.theme}-txt-global`}>
                      You'll get help with accessing treatment if you need it{' '}
                    </div>
                    <div className={`b2-style ${theme.theme}-txt-global`}>
                      If your test comes back positive, we will help you access treatment and notify your partner(s).
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className={`steps-footer ft-tab brook-container ${theme.theme}-skin-color jus-end b-bot`}>
                  <Button
                    id={accessibilityId}
                    isDisabled={false}
                    buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                    buttonText="Next"
                    onClick={(e) => proceed()}
                  />
                  <button
                    type="button"
                    className={`font-a ${theme.theme}-txt-global underline`}
                    onClick={(e) => {
                      proceed()
                    }}
                  >
                    Let's skip to the form
                  </button>
                </div>
              </div>
              {/* <div className={`a-pos ft-tab brook-container ${theme.theme}-skin-color jus-end`}>
                <button
                  type="button"
                  className={`font-a ${theme.theme}-txt-global underline`}
                  onClick={(e) => {
                    proceed()
                  }}
                >
                  Let's skip to the form
                </button>
              </div> */}
            </div>
          </div>
        )}
      </>
    )
  } else {
    return (
      <>
        {pageNo === 1 && (
          <div className={`bg-color preinfo-style ${theme.theme}-global`}>
            <div className="brook-container pad-bot">
              <div className="text-to-speech">
                <div className="h-div mt-4">
                  <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
                  <div className="speaker-i">
                    <button
                      className="no-background-border"
                      onClick={(e) => {
                        TextToSpeech.getInstance().playVoice('text-to-speech')
                        setisAccessibilityVoiceOver(true)
                      }}
                    >
                      <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
                    </button>
                  </div>
                </div>
                <div>
                  <div className={`b1-style  ${theme.theme}-txt-global`}>
                    The kits that Brook can provide by post will test for chlamydia and gonorrhoea using either a
                    vaginal swab or urine sample.
                  </div>
                  <div className={`b2-style ${theme.theme}-txt-global`}>
                    These are two of the most common STIs and it's possible to have one or both of them with no
                    symptoms.
                    <br />
                  </div>
                  <div className={`b2-style ${theme.theme}-txt-global`}>
                    If you are not sure what you should test for, we recommend that you continue with this request.
                  </div>
                  <Accordion
                    themeName={theme.theme}
                    panels={[
                      {
                        id: accessibilityId,
                        label: 'I WANT TO TEST FOR SOMETHING ELSE',
                        content: child(theme),
                      },
                      {
                        label: 'I NEED A DIFFERENT TYPE OF TEST',
                        content: childOne(theme),
                      },
                    ]}
                  />
                </div>
              </div>
              <div className={`ft-tab brook-container ${theme.theme}-skin-color`}>
                <button className="float-left no-background-border" onClick={(e) => GoBackPage()}>
                  <img src={backIcon} alt="back" />
                </button>
                <Button
                  isDisabled={false}
                  buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                  buttonText="Next"
                  onClick={(e) => proceed(2)}
                />
              </div>
            </div>
          </div>
        )}
        {pageNo === 2 && (
          <div className={`bg-color preinfo-style ${theme.theme}-global`}>
            <div className="brook-container">
              <div className="text-to-speech">
                <div className="h-div mt-4">
                  <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
                  <div className="speaker-i">
                    <button
                      className="no-background-border"
                      onClick={(e) => {
                        TextToSpeech.getInstance().playVoice('text-to-speech')
                        setisAccessibilityVoiceOver(true)
                      }}
                    >
                      <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
                    </button>
                  </div>
                </div>
                <div>
                  <br />
                  <br />
                  <br />
                  <div className={`b2-style ${theme.theme}-txt-global`}>
                    Important: It can take up to two weeks for chlamydia and gonorrhoea to show up in tests. Waiting two
                    weeks after unprotected sex to take a test will provide a more accurate result.
                  </div>
                  <Accordion
                    themeName={theme.theme}
                    panels={[
                      {
                        id: accessibilityId,
                        label: 'WHAT IS UNPROTECTED SEX ?',
                        content: unprotectedSex(theme),
                      },
                    ]}
                  />
                </div>
              </div>
              <div className={`ft-tab brook-container ${theme.theme}-skin-color`}>
                <button className="float-left no-background-border" onClick={(e) => GoBackPage()}>
                  <img src={backIcon} alt="back" />
                </button>
                <Button
                  isDisabled={false}
                  buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                  buttonText="Next"
                  onClick={(e) => proceed(3)}
                />
              </div>
            </div>
          </div>
        )}
        {pageNo === 3 && (
          <div className={`bg-color confi-style ${theme.theme}-global`}>
            <div className="brook-container">
              <div className="text-to-speech">
                <div className="h-div mt-4">
                  <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
                  <div className="speaker-i">
                    <button
                      className="no-background-border"
                      onClick={(e) => {
                        TextToSpeech.getInstance().playVoice('text-to-speech')
                        setisAccessibilityVoiceOver(true)
                      }}
                    >
                      <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
                    </button>
                  </div>
                </div>
                <div>
                  <br />
                  <br />
                  <br />
                  <div>
                    <div className={`b2-style ${theme.theme}-txt-global`}>
                      We'll need to ask you some questions to make sure you are eligible for a test kit and to make sure
                      we can provide the type you need.
                      <div className={`b2-style ${theme.theme}-txt-global`}>
                        Some of the questions may feel quite personal, but they are all necessary for us to help you get
                        the right test.
                      </div>
                      <div className={`b2-style ${theme.theme}-txt-global`}>
                        Everything you tell us will be kept private and confidential.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div className={`ft-tab brook-container ${theme.theme}-skin-color`}>
                    <button className="float-left mb-3 no-background-border" onClick={(e) => GoBackPage()}>
                      <img src={backIcon} alt="back" />
                    </button>
                    <Button
                      id={accessibilityId}
                      isDisabled={false}
                      buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                      buttonText="Next"
                      onClick={(e) => proceed(4)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {pageNo === 4 && (
          <div className={`bg-color confi-style ${theme.theme}-global`}>
            <div className="brook-container">
              <div className="text-to-speech">
                <div className="h-div mt-4">
                  <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
                  <div className="speaker-i">
                    <button
                      className="no-background-border"
                      onClick={(e) => {
                        TextToSpeech.getInstance().playVoice(
                          null,
                          'ORDER A TEST KIT How it works. Before you fill in the test request form, would you like to find out a bit more about how the process works?'
                        )
                        setisAccessibilityVoiceOver(true)
                      }}
                    >
                      <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
                    </button>
                  </div>
                </div>
                <>
                  <div className={`b1-style ${theme.theme}-txt-global`}>How it works</div>
                  <div className={`b2-style ${theme.theme}-txt-global`}>
                    Before you fill in the test request form, would you like to find out a bit more about how the
                    process works?
                  </div>
                  <div className={`ft-tab brook-container ${theme.theme}-skin-color jus-end pre-info-footer-blk`}>
                    <div className="pad-te">
                      <div className="footer-class-info">
                        <Button
                          id={accessibilityId}
                          isDisabled={false}
                          buttonClassName={`btn btn-default btn-main btn-width ${theme.theme}-start-btn-global`}
                          buttonText="Yes, I'd like to know more"
                          onClick={(e) => proceed(5)}
                        />
                        <div className="font-a mt-4 ml-2">
                          <div className={`${theme.theme}-txt-global`}>
                            <button
                              className="underline"
                              type="button"
                              style={{ cursor: 'pointer' }}
                              onClick={(e) => {
                                proceed()
                              }}
                            >
                              I'm good, let's skip to the form
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        )}
        {pageNo === 5 && (
          <div className={`bg-color confi-style ${theme.theme}-global`}>
            <div className="text-to-speech brook-container">
              <div className="h-div mt-4">
                <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
                <div className="speaker-i">
                  <button
                    className="no-background-border"
                    onClick={(e) => {
                      TextToSpeech.getInstance().playVoice(
                        null,
                        'ORDER A TEST KIT. More information about how it works. Complete a quick online assessment  It only takes a few minutes and will determine what type of test you need. Everything you tell us is kept private and confidential.'
                      )
                      setisAccessibilityVoiceOver(true)
                    }}
                  >
                    <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
                  </button>
                </div>
              </div>
              <div className={`b1-style ${theme.theme}-txt-global`}>More information about how it works</div>
              <div className="no-style">
                <div className="row mx-0 w-100 jus-space flex-nowrap">
                  <div className="col-md-2 col-3 col-sm-3 px-0 po-style">
                    <p className={`p-style ${theme.theme}-txt-global`}>1</p>
                  </div>
                  <div className="col-md-10 col-9 col-sm-9">
                    <div className={`b4-style ${theme.theme}-txt-global`}>Complete a quick online assessment</div>
                    <div className={`b2-style ${theme.theme}-txt-global`}>
                      It only takes a few minutes and will determine what type of test you need. Everything you tell us
                      is kept private and confidential.
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className={`steps-footer ft-tab brook-container ${theme.theme}-skin-color jus-end b-bot`}>
                  <Button
                    id={accessibilityId}
                    isDisabled={false}
                    buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                    buttonText="Next"
                    onClick={(e) => proceed(6)}
                  />
                  <button
                    type="button"
                    className={`font-a ${theme.theme}-txt-global underline`}
                    onClick={(e) => {
                      proceed()
                    }}
                  >
                    Let's skip to the form
                  </button>
                </div>
              </div>
              {/* <div className={`a-pos ft-tab brook-container ${theme.theme}-skin-color jus-end`}>
                <button
                  type="button"
                  className={`font-a ${theme.theme}-txt-global underline`}
                  onClick={(e) => {
                    proceed()
                  }}
                >
                  Let's skip to the form
                </button>
              </div> */}
            </div>
          </div>
        )}
        {pageNo === 6 && (
          <div className={`bg-color confi-style ${theme.theme}-global`}>
            <div className="text-to-speech brook-container">
              <div className="h-div mt-4">
                <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
                <div className="speaker-i">
                  <button
                    className="no-background-border"
                    onClick={(e) => {
                      TextToSpeech.getInstance().playVoice(
                        null,
                        'ORDER A TEST KIT. More information about how it works. Your test will be despatched. Tests usually will arrive within 2 days and will be sent in a plain envelope, via first class post.'
                      )
                      setisAccessibilityVoiceOver(true)
                    }}
                  >
                    <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
                  </button>
                </div>
              </div>
              <div className={`b1-style ${theme.theme}-txt-global`}>More information about how it works</div>
              <div className="no-style">
                <div className="row mx-0 w-100 jus-space flex-nowrap">
                  <div className="col-md-2 col-3 col-sm-3 px-0 po-style">
                    <p className={`p-style ${theme.theme}-txt-global`}>2</p>
                  </div>
                  <div className="col-md-10 col-9 col-sm-9">
                    <div className={`b4-style ${theme.theme}-txt-global`}>Your test will be despatched</div>
                    <div className={`b2-style ${theme.theme}-txt-global`}>
                      Tests usually will arrive within 2 days and will be sent in a plain envelope, via first class
                      post.
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className={`steps-footer ft-tab brook-container ${theme.theme}-skin-color jus-end b-bot`}>
                  <Button
                    id={accessibilityId}
                    isDisabled={false}
                    buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                    buttonText="Next"
                    onClick={(e) => proceed(7)}
                  />
                  <button
                    type="button"
                    className={`font-a ${theme.theme}-txt-global underline`}
                    onClick={(e) => {
                      proceed()
                    }}
                  >
                    Let's skip to the form
                  </button>
                </div>
              </div>
              {/* <div className={`a-pos ft-tab brook-container ${theme.theme}-skin-color jus-end`}>
                <button
                  type="button"
                  className={`font-a ${theme.theme}-txt-global underline`}
                  onClick={(e) => {
                    proceed()
                  }}
                >
                  Let's skip to the form
                </button>
              </div> */}
            </div>
          </div>
        )}
        {pageNo === 7 && (
          <div className={`bg-color confi-style ${theme.theme}-global`}>
            <div className="text-to-speech brook-container">
              <div className="h-div mt-4">
                <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
                <div className="speaker-i">
                  <button
                    className="no-background-border"
                    onClick={(e) => {
                      TextToSpeech.getInstance().playVoice(
                        null,
                        'ORDER A TEST KIT. More information about how it works. Complete your test. Your kit will include clear instructions to help you take your own samples.'
                      )
                      setisAccessibilityVoiceOver(true)
                    }}
                  >
                    <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
                  </button>
                </div>
              </div>
              <div className={`b1-style ${theme.theme}-txt-global`}>More information about how it works</div>
              <div className="no-style">
                <div className="row mx-0 w-100 jus-space flex-nowrap">
                  <div className="col-md-2 col-3 col-sm-3 px-0 po-style">
                    <p className={`p-style ${theme.theme}-txt-global`}>3</p>
                  </div>
                  <div className="col-md-10 col-9 col-sm-9">
                    <div className={`b4-style ${theme.theme}-txt-global`}>Complete your test</div>
                    <div className={`b2-style ${theme.theme}-txt-global`}>
                      Your kit will include clear instructions to help you take your own samples.
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className={`steps-footer ft-tab brook-container ${theme.theme}-skin-color jus-end b-bot`}>
                  <Button
                    id={accessibilityId}
                    isDisabled={false}
                    buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                    buttonText="Next"
                    onClick={(e) => proceed(8)}
                  />
                  <button
                    type="button"
                    className={`font-a ${theme.theme}-txt-global underline`}
                    onClick={(e) => {
                      proceed()
                    }}
                  >
                    Let's skip to the form
                  </button>
                </div>
              </div>
              {/* <div className={`a-pos ft-tab brook-container ${theme.theme}-skin-color jus-end`}>
                <button
                  type="button"
                  className={`font-a ${theme.theme}-txt-global underline`}
                  onClick={(e) => {
                    proceed()
                  }}
                >
                  Let's skip to the form
                </button>
              </div> */}
            </div>
          </div>
        )}
        {pageNo === 8 && (
          <div className={`bg-color confi-style t-style ${theme.theme}-global`}>
            <div className="text-to-speech brook-container">
              <div className="h-div mt-4">
                <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
                <div className="speaker-i">
                  <button
                    className="no-background-border"
                    onClick={(e) => {
                      TextToSpeech.getInstance().playVoice(
                        null,
                        'ORDER A TEST KIT. More information about how it works. Send your completed test back. Use the freepost envelope provided and pop it in the nearest postbox.'
                      )
                      setisAccessibilityVoiceOver(true)
                    }}
                  >
                    <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
                  </button>
                </div>
              </div>
              <div className={`b1-style ${theme.theme}-txt-global`}>More information about how it works</div>
              <div className="row mx-0 w-100 jus-space flex-nowrap">
                <div className="col-md-2 col-3 col-sm-3 px-0 po-style">
                  <p className={`p-style ${theme.theme}-txt-global`}>4</p>
                </div>
                <div className="col-md-10 col-9 col-sm-9">
                  <div className={`b4-style ${theme.theme}-txt-global`}>Send your completed test back</div>
                  <div className={`b2-style ${theme.theme}-txt-global`}>
                    Use the freepost envelope provided and pop it in the nearest postbox.
                  </div>
                </div>
              </div>
              <div>
                <div className={`steps-footer ft-tab brook-container ${theme.theme}-skin-color jus-end b-bot`}>
                  <Button
                    id={accessibilityId}
                    isDisabled={false}
                    buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                    buttonText="Next"
                    onClick={(e) => proceed(9)}
                  />
                  <button
                    type="button"
                    className={`font-a ${theme.theme}-txt-global underline`}
                    onClick={(e) => {
                      proceed()
                    }}
                  >
                    Let's skip to the form
                  </button>
                </div>
              </div>
              {/* <div className={`a-pos ft-tab brook-container ${theme.theme}-skin-color jus-end`}>
                <button
                  type="button"
                  className={`font-a ${theme.theme}-txt-global underline`}
                  onClick={(e) => {
                    proceed()
                  }}
                >
                  Let's skip to the form
                </button>
              </div> */}
            </div>
          </div>
        )}
        {pageNo === 9 && (
          <div className={`bg-color confi-style t-style ${theme.theme}-global`}>
            <div className="text-to-speech brook-container">
              <div className="h-div mt-4">
                <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
                <div className="speaker-i">
                  <button
                    className="no-background-border"
                    onClick={(e) => {
                      TextToSpeech.getInstance().playVoice(
                        null,
                        'ORDER A TEST KIT. More information about how it works. You will be contacted with your results. This is usually within 3 days.'
                      )
                      setisAccessibilityVoiceOver(true)
                    }}
                  >
                    <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
                  </button>
                </div>
              </div>
              <div className={`b1-style ${theme.theme}-txt-global`}>More information about how it works</div>
              <div className="no-style">
                <div className="row mx-0 w-100 jus-space flex-nowrap">
                  <div className="col-md-2 col-3 col-sm-3 px-0 po-style">
                    <p className={`p-style ${theme.theme}-txt-global`}>5</p>
                  </div>
                  <div className="col-md-10 col-9 col-sm-9">
                    <div className={`b4-style ${theme.theme}-txt-global`}>You will be contacted </div>
                    <div className={`b2-style ${theme.theme}-txt-global`}>
                      You will be contacted with your results. This is usually within 3 days.
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className={`steps-footer ft-tab brook-container ${theme.theme}-skin-color jus-end b-bot`}>
                  <Button
                    id={accessibilityId}
                    isDisabled={false}
                    buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                    buttonText="Next"
                    onClick={(e) => proceed(10)}
                  />
                  <button
                    type="button"
                    className={`font-a ${theme.theme}-txt-global underline`}
                    onClick={(e) => {
                      proceed()
                    }}
                  >
                    Let's skip to the form
                  </button>
                </div>
              </div>
              {/* <div className={`a-pos ft-tab brook-container ${theme.theme}-skin-color jus-end`}>
                <button
                  type="button"
                  className={`font-a ${theme.theme}-txt-global underline`}
                  onClick={(e) => {
                    proceed()
                  }}
                >
                  Let's skip to the form
                </button>
              </div> */}
            </div>
          </div>
        )}
        {pageNo === 10 && (
          <div className={`bg-color confi-style t-style ${theme.theme}-global`}>
            <div className="text-to-speech brook-container">
              <div className="h-div mt-4">
                <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
                <div className="speaker-i">
                  <button
                    className="no-background-border"
                    onClick={(e) => {
                      TextToSpeech.getInstance().playVoice(
                        null,
                        "ORDER A TEST KIT. More information about how it works. You'll get help with accessing treatment if you need it. If your test comes back positive, we will help you access treatment and notify your partners."
                      )
                      setisAccessibilityVoiceOver(true)
                    }}
                  >
                    <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
                  </button>
                </div>
              </div>
              <div className={`b1-style ${theme.theme}-txt-global`}>More information about how it works</div>
              <div className="no-style">
                <div className="row mx-0 w-100 jus-space flex-nowrap">
                  <div className="col-md-2 col-3 col-sm-3 px-0 po-style">
                    <p className={`p-style ${theme.theme}-txt-global`}>6</p>
                  </div>
                  <div className="col-md-10 col-9 col-sm-9">
                    <div className={`b4-style ${theme.theme}-txt-global`}>
                      You'll get help with accessing treatment if you need it{' '}
                    </div>
                    <div className={`b2-style ${theme.theme}-txt-global`}>
                      If your test comes back positive, we will help you access treatment and notify your partner(s).
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className={`steps-footer ft-tab brook-container ${theme.theme}-skin-color jus-end b-bot`}>
                  <Button
                    id={accessibilityId}
                    isDisabled={false}
                    buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                    buttonText="Next"
                    onClick={(e) => proceed()}
                  />
                  <button
                    type="button"
                    className={`font-a ${theme.theme}-txt-global underline`}
                    onClick={(e) => {
                      proceed()
                    }}
                  >
                    Let's skip to the form
                  </button>
                </div>
              </div>
              {/* <div className={`a-pos ft-tab brook-container ${theme.theme}-skin-color jus-end`}>
                <button
                  type="button"
                  className={`font-a ${theme.theme}-txt-global underline`}
                  onClick={(e) => {
                    proceed()
                  }}
                >
                  Let's skip to the form
                </button>
              </div> */}
            </div>
          </div>
        )}
      </>
    )
  }
}

const child = (theme: any) => {
  const tabIndex = 0
  return (
    <>
      <div className={`p3-style p2-style ${theme.theme}-txt-global`}>
        If you'd like to test for HIV, syphilis or another specific STI, please contact your local sexual health clinic{' '}
        <br />
        <div className="d-flex justify-center mb-8 mt-4">
          <a className="text-a-tag anchor-btn" href="https://www.brook.org.uk/find-a-service/" target="_blank">
            Search for a local sexual health service
          </a>
        </div>
      </div>
    </>
  )
}

const childOne = (theme: any) => {
  const tabIndex = 0
  return (
    <>
      <div className={`p3-style p2-style ${theme.theme}-txt-global`}>
        If you think you need a throat swab (if you only/mostly have oral sex) or an anal swab (if you only/mostly
        receive anal sex) please get in touch with your local sexual health service and they will be able to arrange a
        test for you. <br /> If you've had (or are having) different types of sex with different people, you may need
        more than one type of test. <br />
        <br />
        <div className="d-flex justify-center mb-4">
          <a className="text-a-tag anchor-btn" href="https://www.brook.org.uk/find-a-service/" target="_blank">
            Search for a local sexual health service
          </a>
        </div>
      </div>
    </>
  )
}

const unprotectedSex = (theme: any) => {
  const tabIndex = 0
  return (
    <>
      <div className={`p3-style p2-style ${theme.theme}-txt-global`}>
        When talking about STIs, unprotected sex is any type of sexual contact between two or more people where a
        barrier method, like a condom, was not used or failed. If you used a condom but it broke or came off, this would
        count as unprotected sex. If you're not sure then we recommend you test anyway - it's better to be on the safer
        side.
      </div>
    </>
  )
}

/* T4 Plus disease */
const urineSample = (theme: any) => {
  const tabIndex = 0
  return (
    <div className="list-blk">
      <ul className={`p3-style p2-style ${theme.theme}-txt-global`}>
        <li>
          Chlamydia and gonorrhoea are two of the most common STIs and it's possible to have one or both of them with no
          symptoms.
        </li>
        <li>
          If left untreated, they can cause problems with fertility (having babies) in the future as well as other
          health problems. However, they are both really easy to treat.
        </li>
        <li>
          Testing for both chlamydia and gonorrhoea requires either a urine sample, if you have a penis and testicles,
          or a vaginal swab, if you have a vagina and vulva.
        </li>
      </ul>
    </div>
  )
}

const bloodSampleHivSyphilis = (theme: any) => {
  const tabIndex = 0
  return (
    <div className="list-blk">
      <ul className={`p3-style p2-style ${theme.theme}-txt-global`}>
        <li>
          HIV is a virus that damages the body’s immune system so it cannot fight off infections. It is most commonly
          passed on through vaginal or anal sex without using a condom.
        </li>
        <li>
          Syphilis is a sexually transmitted infection (STI) caused by a bacteria. It is commonly passed on through
          oral, vaginal or anal sex.
        </li>
        <li>
          Testing for both HIV and syphilis only requires one blood sample, about 15 drops of blood, taken from your
          finger.
        </li>
        <li>
          If you don't want to test for HIV and syphilis, maybe because you'd prefer to test in clinic or want to wait a
          bit longer for accurate results, you will have the option to decline the blood sample later on in this order.
        </li>
      </ul>
    </div>
  )
}

// const bloodSampleHepatitis = (theme: any) => {
//   const tabIndex = 0
//   return (
//     <div className="list-blk">
//       <ul className={`p3-style p2-style ${theme.theme}-txt-global`}>
//         <li>
//           Hepatitis is the medical name for inflammation of the liver. There are different types of hepatitis that can
//           be transmitted in different ways.
//         </li>
//         <li>
//           The kits Brook offers can test for Hepatitis B and Hepatitis C, both of which can be transmitted through
//           sexual contact.
//         </li>
//         <li>
//           Testing for Hepatitis only requires one blood sample, about 15 drops of blood, taken from your finger. We can
//           use the same sample you provide for the HIV and syphilis test.
//         </li>
//         <li>
//           We won't offer a Hepatitis test to everyone - we will assess your risk and let you know if we'd recommended it
//           for you.
//         </li>
//       </ul>
//     </div>
//   )
// }

const testSomethingElse = (theme: any) => {
  const tabIndex = 0
  return (
    <>
      <div className={`p3-style p2-style ${theme.theme}-txt-global`}>
        If you'd like to test for another specific STI, please contact your local sexual health clinic. <br />
        <br />
        <div className="d-flex justify-center mb-4">
          <a className="text-a-tag anchor-btn" href="https://www.brook.org.uk/find-a-service/" target="_blank">
            Search for a local sexual health service
          </a>
        </div>
      </div>
    </>
  )
}
/* End of T4 Plus disease */
