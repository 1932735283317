import React from 'react'

import { TableView } from '../../../components/admin/TableView'
// import { TableView } from '../../../components/admin/TableView';
import { TriNav } from '../../../components/admin/TriNav'
import { AdminLayout } from '../AdminLayout'
import { authManageNotificationSecNav, authPriNavMenu } from '../AdminOptions'
import FetchData from '../super_admin/forms/FetchData'

interface ManageAdminProps { }

export const ManageAdmin: React.FC<ManageAdminProps> = ({ }) => {
  const tableHead = {
    type: 'manage-admins',
    titles: ['Name', 'Email', 'Request', 'Notes', 'Date requested', 'Approval'],
  }

  const App = FetchData(process.env.REACT_APP_SERVICE_BACKEND_URL + '/auth/get-notifications')(TableView)({ tableHead })
  const tabIndex=0;
  return (
    <>
      <AdminLayout getMenuList={authPriNavMenu} roleMode="Super admin mode" sideBar="Notifications">
        <h4 className="login-style1">
          People at Brook {localStorage.getItem('location') && localStorage.getItem('location') !== 'All' ? localStorage.getItem('location') : ''}
        </h4>
        <hr />
        <h1 className="page-heading" tabIndex={tabIndex}>Manage requests</h1>
        <TriNav getMenuList={authManageNotificationSecNav} />
        <App />
      </AdminLayout>
    </>
  )
}
