import React from 'react'

import { Link } from 'react-router-dom'

import BrookLogo from '../../assets/images/brook_logo_new.svg'
// import SpeakerIcon from '../../assets/images/Speaker_Icon_Purple.svg';
import appUrls from '../../services/AppURLs'

interface SignPostingHeaderProps {
  activeState?: string
}

export const SignPostingHeader: React.FC<SignPostingHeaderProps> = ({ activeState }) => {
  return (
    <>
      <div className="signpost-nav-bar">
        <a href="https://www.brook.org.uk/">
          <img src={BrookLogo} alt="Brook" />
        </a>
        {/* <img src={SpeakerIcon} alt="Speaker" /> */}
      </div>
      <section className="signposting-section bg-purple">
        <div className="content-container">
          <div className="signpost-navblk">
            <Link to="/" className={`signpost-navlink ${activeState === 'home' ? 'active' : ''}`}>
              Home
            </Link>
            <Link
              to={appUrls.signPosting.mythBusting}
              className={`signpost-navlink ${activeState === 'myths' ? 'active' : ''}`}
            >
              Myths
            </Link>
            <Link
              to={appUrls.signPosting.help}
              className={`signpost-navlink ${activeState === 'help' ? 'active' : ''}`}
            >
              Help
            </Link>
          </div>
        </div>
      </section>
    </>
  )
}
