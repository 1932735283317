import React, { useCallback, useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

// import backIcon from '../assets/images/Back_Button.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import { Accordion } from '../components/accordian/Accordian'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import appUrls from '../services/AppURLs'
import '../styles/confirmemail.css'

interface WhatNextProps {
  children: any
}

export const WhatNext: React.FC<WhatNextProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)

  const history = useHistory()

  const proceed = useCallback(() => {
    // Props.history.push('/')
    window.location.href = appUrls.outerDomainUrls.brookParent
  }, [])

  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }
  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('whatNext_id')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  const child = (theme: any) => {
    return (
      <>
        <div className={`p3-style what-next-sub-content ${theme.theme}-txt-global p-0`}>
          It's great that you've taken this step to look after your health today.
          <br />
          <br />
          If you feel like you need further support at this time, you can contact your local Brook or other sexual
          health service.
          <br />
          <br />
          You can also check out the Get Help page on our website{' '}
          <a className="hover:text-white" href="https://www.brook.org.uk/get-help/">
            https://www.brook.org.uk/get-help/
          </a>
          , which has details of organisations that support all aspects of health and relationships, including ones that
          can help you if you've experienced sexual assault or violence.
        </div>
      </>
    )
  }

  return (
    <div className="bg-color whatnext-style">
      <div className="brook-container pad-bot">
        <div className="text-to-speech">
          <div className="h-div mt-4">
            <h2 className="page-header-txt">ORDER A TEST KIT</h2>
            <div className="speaker-i">
              <button
                className="no-background-border"
                onClick={(e) => {
                  TextToSpeech.getInstance().playVoice('text-to-speech')
                  setisAccessibilityVoiceOver(true)
                }}
              >
                <img src={Icon} alt="speaker" />
              </button>
            </div>
          </div>
          <div className={`what-next-sub-content ${theme.theme} mb-4 `}>
            {/* <p>
              Remember: Someone from Brook will be in touch in the next few days. Please keep a lookout for their call.{' '}
            </p> */}
            <br />
            <p> What happens next?</p> <br />
            <p> 1. The Doctors Laboratory (TDL) will pop your kit in the post. </p>
            <br />
            <p>
              {' '}
              2. When you receive your kit, complete the samples. Make sure to follow the instructions carefully and
              return your samples with the form and labels provided.
              <br />
              <br />
              <a
                id="whatNext_id"
                href="https://www.brook.org.uk/your-life/how-to-complete-your-sti-test-at-home/"
                className="text-a-tag"
                target="_blank"
              >
                Check out Brook's step-by-step instructions for taking your samples
              </a>
            </p>
            <br />
            <p>3. TDL will process your samples and you will be contacted with the results.</p> <br />
            <p>4. If you test positive for an STI, you will be supported to access treatment.</p>
            <br />
            <p className="l-height">
              <a href="https://www.brook.org.uk/topics/stis/" className="text-a-tag" target="_blank">
                Find out how the different STIs are treated
              </a>{' '}
              <br />
            </p>
            <br />
            <p className="l-height">
              <a href="https://www.brook.org.uk/your-life/living-with-an-sti/" className="text-a-tag" target="_blank">
                Information about managing symptoms when living with an STI
              </a>{' '}
              <br />
            </p>
            <br />
            <p className="l-height">
              Can you take a moment to let us know how you found the STI kit order process? <br />
              <a
                href="https://forms.office.com/Pages/ResponsePage.aspx?id=S2A4YOEixkK3TmVcYQTmMcpoP-K14ctNn-6Oh1jxMMJURVlETjBHRE9GUkhGVUtLSTBQUzVaOUwwOCQlQCN0PWcu"
                className="text-a-tag"
                target="_blank"
              >
                Click here to provide feedback.
              </a>{' '}
              <br />
              <Accordion
                themeName="whatnext-style-accordion"
                panels={[
                  {
                    id: 'brookClinic_id',
                    label: 'More help and support',
                    content: child(theme),
                  },
                ]}
              />
            </p>
          </div>
        </div>
        <div>
          <div className="ft-tab brook-container jus-end">
            {/* <button
              className="float-left no-background-border"
              onClick={e => GoBackPage()}
            >
              <img src={backIcon} alt="back" />
            </button> */}
            <Button
              isDisabled={false}
              buttonClassName="btn btn-default btn-main"
              buttonText="Finish"
              onClick={(e) => proceed()}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
