// safe guarding flag options
const axios = require('axios');

export function getFlags() {
    return axios.get(process.env.REACT_APP_SERVICE_BACKEND_URL + '/admin/flags');
}
   
export enum SAFE_GUARDING_FLAGS {
    "My partner is a lot older than me",
    "I feel down or suffer from feelings of depression",
    "I often drink alcohol or take drugs before having sex",
    "I have been given gifts, money, drugs, alcohol or protection for sex",
    "I have been made to feel scared or uncomfortable by the person/s I have been having sexual contact with",
    "None of the above apply to me, but i would like to talk to someone"
}

export enum AUTHORITY {
    GU59DH = 'Guildford',
    GU59DJ = 'Guildford',
    GU85LA = 'Guildford',
    GU85UH = 'Guildford'
}