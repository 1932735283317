import React, { useState, useEffect } from 'react'

import '../../styles/common.css'
import { useFormik } from 'formik'
import { useLocation } from 'react-router-dom'
import Select from 'react-select'
import * as Yup from 'yup'

import dropdownIcon from '../../assets/images/dropdown.svg'
import eyeIcon from '../../assets/images/Eye_no_strike.svg'
import hidePassowrd from '../../assets/images/Eye_strike.svg'
import apiFunctions from '../../services/api-service'
import { getPostcodeRegion } from '../../services/api/PostcodesAPI'
import { AdminLayout } from './AdminLayout'
import { AuthMenu } from './AdminOptions'

interface SignupPageProps { }

export const SignupPage: React.FC<SignupPageProps> = ({ }) => {
  const errorIntialState = {
    status: false,
    message: '',
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Please enter a valid email address').required('Email is required'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9#?!@$%^&*-]).{8,}$/,
        'Password must have 8 characters with 1 uppercase, 1 lowercase and a number/symbol'
      )
      .required('Password is required'),
    confirmpassword: Yup.string().when('password', {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same'),
    }),
  })

  const { search } = useLocation()

  const [selectedRegion, setSelectedRegion] = useState<any>(null)

  const [invite, setInvite] = useState(false)

  const [pageNo, setPageNo] = useState(1)

  const [archivedAlready, setArchivedAlready] = useState(false)

  const [requestSubmitted, setRequestSubmitted] = useState(false)

  const [error, setError] = useState(errorIntialState)

  const [showpassword, setShowPassword] = useState(false)

  const [showconfirmpassword, setShowConfirmPassword] = useState(false)

  const [roleOptions, SetroleOptions] = useState([])

  const [regionListSelect, setRegionListSelect] = useState([])

  const validName = new RegExp("^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z ]*)*$");


  useEffect(() => {
    const email = new URLSearchParams(search).get('email')
    localStorage.clear();
    if (email) {
      setInvite(true)
      formik.setFieldValue('email', decodeURI(email))
    }

    async function getRegion() {
      try {
        const regionList = await getPostcodeRegion()
        if (regionList) {
          let modSelectRegionList = regionList.data.data.map((region: string) => {
            return { label: region, value: region }
          })
          setRegionListSelect(modSelectRegionList)
        }
      } catch (error) {
        console.log('regionListError', error)
      }
    }
    getRegion()
    apiFunctions
      .get(process.env.REACT_APP_SERVICE_BACKEND_URL + '/admin/get-brook-roles')
      .then((_result) => {
        let optArr: any = []
        if (_result && _result.data && _result.data.data && _result.data.data.BrookRoles.length > 0) {
          _result.data.data.BrookRoles.map((val: any) => {
            optArr.push({ value: Capitalize(val.name), label: Capitalize(val.name) })
          })
        }
        SetroleOptions(optArr)
      })
      .catch((err) => {
        // setError({ status: true, message: err.response.data.message });
      })
  }, [])

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      password: '',
      confirmpassword: '',
      clinic: [],
      roleAtBrook: [],
      pronouncedAs: '',
      description: '',
    },
    validationSchema,
    async onSubmit(values: any) {
      const __role = (__val: any[]) => {
        let __arr: any[] = []
        __val.map((e) => {
          __arr.push(e.value)
        })
        return __arr
      }

      const finalValues = Object.assign({}, values)

      finalValues.clinic = values.clinic
      finalValues.roleAtBrook = __role(values.roleAtBrook)
      finalValues.locations = __role(values.clinic)
      finalValues.pronouncedAs = values.pronouncedAs.value

      delete finalValues.confirmpassword

      try {
        apiFunctions
          .postAdmin(process.env.REACT_APP_SERVICE_BACKEND_URL + '/brook-user/add', finalValues)
          .then((_result) => {
            console.log(_result)
            setPageNo(0)
            setRequestSubmitted(true)
          })
          .catch((err) => {
            setError({ status: true, message: err.response.data.message })
          })
      } catch (error: any) {
        console.log(error.response, 'errorresposne')
      }
    },
  })
  function Capitalize(str: any) {
    str = str.split(' ')
    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
    }
    const CapitalLetter = str.join(' ')
    return CapitalLetter
  }
  const tabIndex = 0;
  return (
    <AdminLayout getMenuList={AuthMenu} roleMode="Super admin mode" hideMenus>
      <div className="admin-login-style">
        <div className="col-12 col-sm-10 col-md-6 mt-4">
          <div className="login-style1">Sign up for a Brook staff user account</div>
        </div>
        <hr />
        {(pageNo === 1 || pageNo === 2) && (
          <div className="col-12 col-sm-10 col-md-6 mt-4">
            <p className="login-style2">Request staff user account</p>
          </div>
        )}
        <div>
          <form className="col-12 col-sm-10 col-md-5 mt-4" onSubmit={formik.handleSubmit} autoComplete="off">
            {pageNo === 1 && (
              <>
                <div className="form-group">
                  <label htmlFor='name' className="input-style">Name (you'd like to be associated with on your account)</label>
                  <input
                    type="text"
                    id="name"
                    className="form-control  login-radius"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />

                  <span className="errorMessage">
                    {formik.touched.name && formik.errors.name ? formik.errors.name : null}
                    {formik.values.name && !validName.test(formik.values.name) ? "Only characters are allowed in the name" : null}
                  </span>
                </div>
                <div className="form-group mt-4">
                  <label className="input-style" htmlFor='email'>Email address</label>
                  <input
                    type="email"
                    id="email"
                    className="form-control login-radius"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    autoComplete="off"
                    readOnly={invite}
                  />

                  <span className="errorMessage">
                    {formik.touched.email && formik.errors.email ? formik.errors.email : null}
                  </span>
                </div>
                <div className="form-group parent-pos">
                  <label className="input-style" htmlFor='password'>Create a password</label>
                  <input
                    type={`${showpassword ? 'text' : 'password'}`}
                    id="password"
                    className="form-control login-radius"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                  />
                  <div
                    className="i-icon i-icon-signup"
                    onClick={(e) => {
                      setShowPassword(!showpassword)
                    }}
                  >
                    <img tabIndex={tabIndex} src={showpassword ? eyeIcon : hidePassowrd} className="icon-style" alt="speaker" />
                  </div>
                </div>
                <ul className='list-pass'>
                  <p className="mt-4">Your password must:</p>
                  <li className="mt-2 mb-1 text-left pass-text-style" tabIndex={tabIndex}>
                    Be at least 8 characters long
                  </li>
                  <li className="mb-1 text-left pass-text-style" tabIndex={tabIndex}>
                    Contain at least 1 uppercase letter, 1 lowercase letter, a number and a symbol (e.g. !?£$%*&)
                  </li>
                  <li className="mb-1 text-left pass-text-style" tabIndex={tabIndex}>
                    Be unique to Staff Hub
                  </li>
                  <li className="mb-1 text-left pass-text-style" tabIndex={tabIndex}>
                    Not be your username or name
                  </li>
                  <li className="mb-1 text-left pass-text-style" tabIndex={tabIndex}>
                    Not be a generic or easy to guess word (like 'password' or 'staff hub')
                  </li>
                  <li className=" text-left pass-text-style" tabIndex={tabIndex}>
                    If you are resetting your password, it cannot be the same as any of your last 5 passwords
                  </li>
                </ul>
                <span className="errorMessage">
                  {formik.touched.password && formik.errors.password ? formik.errors.password : null}
                </span>

                <div className="form-group mt-4 parent-pos">
                  <label className="input-style">Enter password again</label>
                  <input
                    type={`${showconfirmpassword ? 'text' : 'password'}`}
                    id="confirmpassword"
                    aria-label='confirmpassword'
                    className="form-control  login-radius"
                    onChange={formik.handleChange}
                    value={formik.values.confirmpassword}
                  />
                  <div className="i-icon i-icon-signup" onClick={(e) => setShowConfirmPassword(!showconfirmpassword)}>
                    <img tabIndex={tabIndex} src={showconfirmpassword ? eyeIcon : hidePassowrd} className="icon-style" alt="speaker" />
                  </div>
                </div>
                <span className="errorMessage">
                  {formik.touched.confirmpassword && formik.errors.confirmpassword
                    ? formik.errors.confirmpassword
                    : null}
                </span>
                <div className="login-btn-wrap">
                  <button
                    type="submit"
                    className="btn btn-primary float-right login-btn mb-4"
                    onClick={(e) => {
                      Object.entries(formik.errors).length === 0 &&
                        formik.values.email !== '' &&
                        formik.values.name !== '' && validName.test(formik.values.name) &&
                        formik.values.password !== '' &&
                        formik.values.confirmpassword !== ''
                        ? setPageNo(pageNo + 1)
                        : ''
                    }}
                  >
                    Next
                  </button>
                  {/* {
                                    (formik.values.name && (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(formik.values.email) && (formik.values.password.length > 1 && formik.values.password === formik.values.confirmpassword && (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&?*-])\S{7,}.$/).test(formik.values.password) && (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&?*-])\S{7,}.$/).test(formik.values.confirmpassword))) && <button type="button" className="btn btn-primary float-right login-btn mb-4" onClick={e => {
                                        setPageNo(pageNo + 1)
                                    }}>Next</button>
                                } */}
                </div>
              </>
            )}
            {pageNo === 2 && (
              <div>
                <div className="parent-pos">
                  <label className="in-style">Brook service</label>
                  <Select
                    aria-label='Brook service'
                    value={formik.values.clinic}
                    classNamePrefix="select-dropdown-role"
                    onChange={(e) => {
                      let regionConcat = ''
                      e.map((region: any) => (regionConcat += region.value + ', '))
                      setSelectedRegion(regionConcat.slice(0, -2))
                      formik.setFieldValue('clinic', e)
                    }}
                    isMulti
                    options={regionListSelect}
                  />
                  <div className="arrow-icon">
                    <img src={dropdownIcon} className="icon-style pos-arrow" alt="speaker" />
                  </div>
                </div>
                <div className="parent-pos">
                  <label className="in-style mt-5">Role at Brook</label>
                  <Select
                    aria-label='Role at Brook'
                    value={formik.values.roleAtBrook}
                    classNamePrefix="select-dropdown-role"
                    onChange={(e) => formik.setFieldValue('roleAtBrook', e)}
                    isMulti={true}
                    options={roleOptions}
                  />
                  <div className="arrow-icon">
                    <img src={dropdownIcon} className="icon-style pos-arrow" alt="speaker" />
                  </div>
                </div>
                <div className="form-group mt-4 parent-pos">
                  <label className="input-style mt-5">Pronouns (optional)</label>
                  <Select
                    aria-label='Pronouns (optional)'
                    value={formik.values.pronouncedAs}
                    classNamePrefix="select-dropdown"
                    onChange={(e) => formik.setFieldValue('pronouncedAs', e)}
                    options={[
                      { value: 'He/Him', label: 'He/Him' },
                      { value: 'She/Her', label: 'She/Her' },
                      { value: 'They/Them', label: 'They/Them' },
                      { value: 'He/Them', label: 'He/Them' },
                      { value: 'She/Them', label: 'She/Them' },
                    ]}
                  />
                </div>
                <div className="login-btn-wrap">
                  {error && error.message ? <span className="errorMessage">{error.message}</span> : ''}
                  <button
                    type="button"
                    className="btn btn-primary login-btn"
                    onClick={(e) => {
                      setPageNo(pageNo - 1)
                    }}
                  >
                    Back
                  </button>
                  {formik.values.clinic && Boolean(formik.values.roleAtBrook.length) && (
                    <button type="submit" className="btn btn-primary float-right login-btn mb-4">
                      Request account
                    </button>
                  )}
                </div>
              </div>
            )}
          </form>
        </div>
        {requestSubmitted && (
          <div>
            <p className="login-style2 mt-3">{`Thank you ${formik.values.name}, your request has been submitted.`}</p>
            <p className="mt-4">
              Brook {selectedRegion} administrator will be able to see this request and if approved, grant you access to
              the service.
            </p>
          </div>
        )}
        {archivedAlready && (
          <div>
            <p className="login-style2 mt-3">Your email address is associated with an archived account.</p>
            <p className="mt-4">
              This email address has been used to create an admin or user account with Brook before, and the account has
              been archived.
            </p>
            <p> To reinstate your account, please contact your Brook admin.</p>
          </div>
        )}
      </div>
    </AdminLayout>
  )
}
export default SignupPage
