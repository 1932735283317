import React from 'react';

import Select, { components } from 'react-select';

import { ReactComponent as DropdownIcon } from '../../assets/images/dropdown-icon.svg';
import '../../styles/selectDropdown.css';

const indicatorSeparatorStyle = {
  alignSelf: 'stretch',
  backgroundColor: 'transparent',
  marginBottom: 8,
  marginTop: 8,
  width: 1,
};

function IndicatorSeparator(innerProps: any) {
  return <span style={indicatorSeparatorStyle} />;
}

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIcon />
    </components.DropdownIndicator>
  );
};

export interface SelectDropdownOptionArrayProps {
  value: string | Array<string> | null;
  label: string | Array<string> | null;
}

interface SelectDropdownProps {
  label?: string;
  onChange?: any;
  onBlur?: any;
  type?: any;
  name?: any;
  optionsArray: Array<SelectDropdownOptionArrayProps>;
  errors?: string | null | any;
  placeholder?: string;
  value?: any;
  addclass?: string;
  id?: string;
  isSearchable?: boolean;
  hasMultiValue?: boolean;
  hasCustomOption?: boolean;
}

export const SelectDropdown: React.FC<SelectDropdownProps> = ({
  label,
  optionsArray,
  onChange,
  onBlur,
  name,
  errors,
  placeholder,
  value,
  addclass,
  id,
  isSearchable,
  hasMultiValue,
  hasCustomOption = false
}) => {
  const customStyles = {};

  const CustomOption = ({ children, ...props }: any) => {
    return (
      <components.Option {...props}>
        <span className='mr-2'>+</span>
        {children}
      </components.Option>
    );
  };

  const defaultValue = (
    optionsArray: Array<SelectDropdownOptionArrayProps>,
    value: string
  ) => {
    if (hasMultiValue) {
      const result = optionsArray ? optionsArray.filter((item: any) => value && value.includes(item.value)) : [];
      return result;
    }
    return optionsArray
      ? optionsArray.find((optionsArray) => optionsArray.value === value)
      : '';
  };

  return (
    <div
      className={`select-dropdown-section input-group ${addclass ? addclass : ''
        }`}
    >
      {label && <label htmlFor=''>{label}</label>}
      <Select
        inputId={id}
        isSearchable={isSearchable}
        components={
          hasCustomOption ?
            { Option: CustomOption, DropdownIndicator, IndicatorSeparator } :
            { DropdownIndicator, IndicatorSeparator }}
        name={name}
        className='react-select-container'
        classNamePrefix='react-select'
        styles={customStyles}
        onChange={onChange}
        placeholder={placeholder}
        options={optionsArray}
        // menuIsOpen
        value={defaultValue(optionsArray, value) || null}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
          },
        })}
        isMulti={hasMultiValue}
        onBlur={onBlur}
      />
    </div>
  );
};
