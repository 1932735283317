import React, { useEffect, useState } from 'react'
import '../styles/Flags.css';

import useAuthContext from '../../context/provider/AuthContext'
import Loader from '../Loader/loader'

interface FlagsProps {
    value?: string | undefined;
    addClass?: string;
    myobj?: any;
    ques?: any;
    type?: any;
}

export const Flags: React.FC<FlagsProps> = ({ value, addClass, myobj }) => {


    const [flgaggedReason, setFlaggedReason] = useState<Array<string>>([]);
    const [showLoader, setShowLoader] = useState(false)
    const { state: { globalSettings } } = useAuthContext();

    useEffect(() => {
        let reason = [];
        if (myobj && myobj.age && myobj.age < 16 && myobj.form_source !== 'tdl') {
            reason.push('age');
        }
        if (myobj && myobj.flags && myobj.flags.length > 0) {

            globalSettings.flags && globalSettings.flags.map((e: any) => {
                if (myobj.flags.includes(e.id)) {
                    reason.push(e.flags)
                }
            })
        }
        setFlaggedReason(reason);

    }, [value, addClass, myobj, globalSettings])


    return (
        <>
            {showLoader && <Loader />}
            {value === 'Talk to someone' ?
                <div className="admin-flag yellow">{value}</div> :
                <FlaggReason flgaggedReason={flgaggedReason} addClass={addClass} value={value} />
            }
        </>
    );
}

const FlaggReason = (__params: any) => {


    const [showmore, setShowMore] = useState(false);

    return !showmore ? <>{__params.flgaggedReason.length > 1 ?
        <div><div className="admin-flag">{__params.flgaggedReason[0]}</div><br />
            <div className="admin-flag cursor-pointer" onClick={e => setShowMore(!showmore)}>+{__params.flgaggedReason.length - 1}</div></div>
        :
        __params.flgaggedReason.length === 1 ? <div><div className="admin-flag">{__params.flgaggedReason[0]}</div></div>
            :
            <div className={`'admin-flag'} ${(__params.addClass !== undefined) ? __params.addClass : ""}`}>{__params.value}</div>}</> : __params.flgaggedReason.length > 0 ?
        __params.flgaggedReason.map((e: any, __index: number) => {
            return <div key={'flagged_' + __index.toString()}><div className="admin-flag">{e}</div><br />
            </div >
        }) :

        <div className={`'admin-flag-align'} ${__params.addClass}`}>{__params.value}</div>
}