import { createContext, useReducer } from 'react';

import { notificationReducer, initialState, NotificationState, NotificationAction } from '../reducers/NotificationReducer';

type NotificationContextProps = {
    state: NotificationState,
    notificationDispatch: React.Dispatch<NotificationAction>
}

export const NotificationContext = createContext<NotificationContextProps>({
    state: initialState,
    notificationDispatch: () => initialState
})

export function NotificationContextHeader(props: React.PropsWithChildren<{}>) {

    const [state, notificationDispatch] = useReducer(notificationReducer, initialState)

    return <NotificationContext.Provider value={{ state, notificationDispatch }} {...props} />
}