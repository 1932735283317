import React, { useRef, useEffect } from 'react'

import xIcon from '../../assets/images/X_popup.svg'

interface ModalPopupProps {
  headerClass: any
  showErrorMsg: any
  setshowErrorMsg: any
}

const ErrorPopup: React.FC<ModalPopupProps> = ({ showErrorMsg, setshowErrorMsg, headerClass }) => {
  const closeButtonRef = useRef<HTMLButtonElement>(null)
  const previouslyFocusedElementRef = useRef<HTMLElement | null>(null)

  const toggleModal = () => {
    setshowErrorMsg('')
  }

  useEffect(() => {
    if (showErrorMsg) {
      previouslyFocusedElementRef.current = document.activeElement as HTMLElement
      closeButtonRef.current?.focus()
      window.addEventListener('keydown', handleKeyDown)
    } else {
      window.removeEventListener('keydown', handleKeyDown)
      previouslyFocusedElementRef.current?.focus()
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [showErrorMsg])

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      toggleModal()
    } else if (event.key === 'Tab') {
      const focusableElements = closeButtonRef.current
        ?.closest('.popup-info-section')
        ?.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        ) as NodeListOf<HTMLElement>
      if (focusableElements && focusableElements.length > 0) {
        const firstElement = focusableElements[0]
        const lastElement = focusableElements[focusableElements.length - 1]
        if (event.shiftKey && document.activeElement === firstElement) {
          event.preventDefault()
          lastElement.focus()
        } else if (!event.shiftKey && document.activeElement === lastElement) {
          event.preventDefault()
          firstElement.focus()
        }
      }
    }
  }

  return (
    <div>
      {showErrorMsg !== '' && (
        <div className="popup-info-section" tabIndex={-1} role="dialog" aria-modal="true">
          <div className="info-popup internal-error-popup" role="document">
            <button type="button" className=" mt-4 ml-64" ref={closeButtonRef} onClick={toggleModal} aria-label="Close">
              <img src={xIcon} alt="back" />
            </button>

            <div className="p4-style-title">
              <div className={headerClass}>{showErrorMsg}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ErrorPopup
