import React, { useState, ChangeEvent } from 'react'


import { ReactComponent as TextInfoIcon } from '../../assets/images/info-text-input.svg'
import '../styles/input_field.css'

interface TextInputProps {
  style?: string
  text?: string
  placeholder?: string
  disabled?: boolean
  label?: string
  type: string
  name?: string
  addClass?: string
  values?: string | number | Array<string>
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  errors?: string | Array<string> | any
  helpIcon?: boolean
  helpContent?: string
}

export const TextInput: React.FC<TextInputProps> = ({
  label,
  placeholder,
  style,
  disabled,
  type,
  onChange,
  addClass,
  name,
  errors,
  onBlur,
  values,
  helpIcon,
  helpContent,
}) => {
  const [showHelpText, setHelpText] = useState(false)
  const openAccodion = (e: any) => {
    // const elemId = e.target.id
    showHelpText ? setHelpText(false) : setHelpText(true)
  }

  return (
    <>
      <div className="input-group">
        <div className="flex">
          <div className="flex-grow">
            {label && <label htmlFor="">{label}</label>}
          </div>
          {helpIcon && (
            <div className="flex-none relative">
              <TextInfoIcon className="cursor-pointer" onClick={openAccodion} />
              {showHelpText && (
                <div className="popup-over-content">
                  <p>{helpContent}</p>
                </div>
              )}
            </div>
          )}
        </div>

        <input
          type={type}
          className={`text-field w-100 ${addClass ? addClass : ''} ${errors ? 'input-error' : ''
            }`}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          name={name}
          onBlur={onBlur}
          value={values}
          autoComplete="off"
        />
        {errors && <div className="form-error-msg error-message">{errors}</div>}
      </div>
    </>
  )
}
