import React, { useCallback, useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'

import { ReactComponent as VectorIcon } from '../../assets/images/Vector.svg';
import useAuthContext from '../../context/provider/AuthContext';
import apiFunctions from '../../services/api-service'
import '../styles/Toggle.css'
import appUrls from '../../services/AppURLs';
import { history } from '../../utils/history';
import Loader from '../Loader/loader'
import Popup from './popup'

interface ToggleProps {
  status?: string
  header?: string
  adminId?: any
  type?: any
  txt?: any
  category?: any
  obj?: any
  permissions?: any
  result?: any | undefined
  referenceNo?: any
  content?: any
  myobj?: any
  rolePermissions?: any
  columnName?: string
  multipleOrderCount?: any
  getSelectedRowId?: any

}

export const ServiceUserName: React.FC<ToggleProps> = ({
  txt,
  adminId,
  category,
  permissions,
  result,
  referenceNo,
  content,
  myobj,
  obj,
  rolePermissions,
  type,
  columnName,
  multipleOrderCount,
  getSelectedRowId
}) => {
  const [showLoader, setShowLoader] = useState(false)

  const [showPopup, setshowPopup] = useState(false)

  const [ref, setreferenceNo] = useState(referenceNo)

  const [viewclientdata, setviewclientdata] = useState(true)

  const [res, setresult] = useState('')

  const [role] = useState(localStorage.getItem('role'))

  const [values, setvalues] = useState({})

  const [ordercount, setOrderCount] = useState<Number>(0);

  const [ordercountbyphone, setordercountbyphone] = useState<Number>(0);

  const [ordercountbyemail, setordercountbyemail] = useState<Number>(0);

  const {state: { user }} = useAuthContext()

  const usersData:any = user;
  
  let permissionAccess:any = usersData&&usersData.user ? usersData.user:usersData;

  permissionAccess = permissionAccess && permissionAccess.permissions?.orders_and_results.filter((ele:any)=>{return ele.name === 'view_record'});
  // multiple order count button hide and show based on below this codition
  const location = useLocation();
  let str = location.search
  const Multiresp = str.indexOf('multiOrder') !== -1;

  const closeModal = () => {
    setshowPopup(false)
  }
  const CloseModelSuccess = (result: any) => {
    setshowPopup(false)
  }
  useEffect(() => {
    if (permissions) {
      setviewclientdata(
        role === '3'
          ? true
          : role === '2'
            ? permissions.admin_view_client_data
            : role === '1'
              ? true
              : false
      )
    }
  }, [txt, adminId, category, permissions, obj])

  const showUserDetails = useCallback(() => {
    setShowLoader(true)
    if (type === 'brook-roles' || (role === '3' && !(permissionAccess && permissionAccess[0] && permissionAccess[0].value)) || role === '2' && !(permissionAccess && permissionAccess[0] && permissionAccess[0].value)) {
      return
    }
    if (category === 'admins') {
      apiFunctions
        .get(process.env.REACT_APP_SERVICE_BACKEND_URL + '/admin/get-admin-profile?id=' + adminId)
        .then((e) => {
          setShowLoader(false)
          setvalues(e.data.data[0])
          setshowPopup(true)
        })
        .catch((err) => {
          setShowLoader(false)
          console.log(err)
        })
    } else {
      apiFunctions
        .get(process.env.REACT_APP_SERVICE_BACKEND_URL + '/admin/get-answers?id=' + adminId)
        .then((e) => {
          setvalues(e)
          setShowLoader(false)
          setshowPopup(true)
        })
        .catch((err) => {
          console.log(err)
          setShowLoader(false)
        })
    }
  }, [adminId])

  useEffect(() => {
    setordercountbyemail(0)
    setordercountbyphone(0)
    setOrderCount(0)

    multipleOrderCount?.forEach((element: any) => {

      if (element.email === (obj && obj.email)) {

        setordercountbyemail(element && element.count);

        setOrderCount(element && element.count);
      }
      else if (element.phone === (obj && obj.phone_no)) {
        
        setordercountbyphone(element && element.count);
        
        setOrderCount(element && element.count);
      }
    });
  }, [obj, multipleOrderCount]);


  const showMultipleOrders = (email: any) => {

    history.push(`${appUrls.admin.orderKit}?filters=Search results&search=${encodeURIComponent(email)}&multiOrder`)
    
  }


  const tabIndex = 0;

  const renderHeaderNameColumn = () => {
    const multiorderClass = location && location.pathname === '/staffhub/order-kit' ?
      ordercount > 1 && Multiresp !== true ? 'name-column-blk' : '' : '';
    return (
      <div className={`${multiorderClass}`}>
        {location && location.pathname === '/staffhub/order-kit' ?
          (ordercountbyemail ? ordercountbyemail : ordercountbyphone) > 1 && Multiresp !== true ?
            <button className='mof-count-blk' onClick={() => showMultipleOrders((ordercountbyemail === ordercountbyphone) ? (obj && obj.phone_no) : (ordercount === ordercountbyemail) ? (obj && obj.email) :  (obj && obj.phone_no))}>
              <VectorIcon />&nbsp;
              <span className='mof-count'>{`(${ordercount})`}</span>
            </button> : ''
          : ''}

        <span
          tabIndex={tabIndex}
          className="onkeyup new_pointer name-blk"
          onClick={() => showUserDetails()}>
          {txt ? txt : ''}
        </span>
      </div>
    )
  };

  return (
    <>
      {showLoader && <Loader />}
      {
        content === 'result' ? (
          <span>{viewclientdata ? <a>{res ? res : '-'}</a> : res}</span>
        ) : (
          <div>
            {viewclientdata ?
              columnName === 'Name' ?
                renderHeaderNameColumn() :
                <span
                  tabIndex={tabIndex}
                  className="onkeyup new_pointer"
                  onClick={() => showUserDetails()}>
                  {txt ? txt : ''}
                </span> : '-'}
          </div>
        )
      }

      {category === 'admins' ? (
        <Popup showAdminProfile={showPopup} close={closeModal} userdata={values} closeProfile={CloseModelSuccess} />
      ) : (
        <Popup userProfile={showPopup} close={closeModal} userdata={values} />
      )}
      {/* {ordercount && ordercount>1  ? <OrderKit searchName={ordercount} /> : 0} */}
    </>
  )
}
