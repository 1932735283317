import React, { useState, useRef, useEffect } from 'react'

import axios, { AxiosRequestConfig } from 'axios'
import { useLocation } from 'react-router-dom'

import CloseIcon from '../../../../assets/images/Popup_close.svg'
import SearchIcon from '../../../../assets/images/search.svg'
import { SecNav } from '../../../../components/admin/SecNav'
import { TableView } from '../../../../components/admin/TableView'
import { TriNav } from '../../../../components/admin/TriNav'
// import { downloadArchiveForm } from '../../../../services/api/AdminAPI'
import Loader from '../../../../components/Loader/loader'
import useAuthContext from '../../../../context/provider/AuthContext'
import appUrls from "../../../../services/AppURLs"
import { history } from '../../../../utils/history'
import { AdminLayout } from '../../AdminLayout'
import { authFormTabsMenu, authPriNavMenu, syncOrderTabsMenu } from '../../AdminOptions'
import FetchData from './FetchData'

interface OrderKitProps { }



export const OrderKit: React.FC<OrderKitProps> = ({ }) => {

  const [showLoader, SetShowLoader] = useState(false)
  const [searchValue, SetSearchValue] = useState('')
  const [filterValue, SetFilterValue] = useState(new URL(window.location.href).searchParams.get('filters'))
  const [typedValue, SetTypedValue] = useState(new URL(window.location.href).searchParams.get('search'))
  let [searchedResult, SetsearchedResult] = useState<any>([] as Array<String>);

  // labelname(multiple request)when search result hide and show in condition based
  const location = useLocation();
  let str = location.search
  const Multiresp = str.indexOf('multiOrder') !== -1;

  // Permission Handle

  const { state: { user }, } = useAuthContext()

  const permissionData: any = user;

  let permissionAccess = permissionData && permissionData?.user ? permissionData.user : permissionData;

  permissionAccess = permissionAccess && permissionAccess.permissions?.orders_and_results.filter((ele: any) => { return ele.name === 'export_archive' });

  const textInput = useRef<any>(null);
  const [url, setURL] = useState(
    process.env.REACT_APP_SERVICE_BACKEND_URL +
    '/admin/get-users?filterType=' +
    new URL(window.location.href).searchParams.get('filters')
  )
  const [filter, setFilter] = useState(
    new URL(window.location.href).searchParams.get('filters')
      ? new URL(window.location.href).searchParams.get('filters')
      : 'Flagged'
  )
  let objects = {
    label: 'Search results',
    link: `${appUrls.admin.orderKit}?filters=Search results`,
    isPath: 'Search'
  };

  let c_url = new URL(window.location.href).searchParams.get('filters');
  let search = new URL(window.location.href).searchParams.get('search');
  // if(new URL(window.location.href).searchParams.get('search')){

  if (filterValue !== c_url || typedValue !== search) {
    SetFilterValue(c_url);
    SetTypedValue(search);
  }

  let titilevalue = [
    '', 'Request received', 'Name', 'Reference no', 'Age', 'Local authority', 'Phone', 'Flags', 'Approve kit', 'Date approved', 'Sample received', 'Result', 'In Lilie?',
    (new URL(window.location.href).searchParams.get('filters') === 'Added to Lilie' ||
      new URL(window.location.href).searchParams.get('filters') === 'Search results' || new URL(window.location.href).searchParams.get('filters') === 'Archive' || new URL(window.location.href).searchParams.get('filters') === 'Rejected' || new URL(window.location.href).searchParams.get('filters') === 'Positive' || new URL(window.location.href).searchParams.get('filters') === 'Negative' || new URL(window.location.href).searchParams.get('filters') === 'Inconclusive') &&
      (localStorage.getItem('role') === '1' || (user))
      ? 'Archive'
      : '',
    'Lilie Id',
  ]
  if (new URL(window.location.href).searchParams.get('filters') === 'Rejected') {
    titilevalue = [
      '', 'Request received', 'Name', 'Reference no', 'Age', 'Local authority', 'Phone', 'Flags', 'Approve kit', 'In Lilie?',
      (new URL(window.location.href).searchParams.get('filters') === 'Added to Lilie' ||
        new URL(window.location.href).searchParams.get('filters') === 'Archive' || new URL(window.location.href).searchParams.get('filters') === 'Rejected' || new URL(window.location.href).searchParams.get('filters') === 'Positive' || new URL(window.location.href).searchParams.get('filters') === 'Negative' || new URL(window.location.href).searchParams.get('filters') === 'Inconclusive') &&
        (localStorage.getItem('role') === '1' || (user))
        ? 'Archive'
        : '',
      'Lilie Id',
    ]
  }
  if (new URL(window.location.href).searchParams.get('filters') === 'Flagged') {
    titilevalue = [
      '', 'Request received', 'Name', 'Reference no', 'Age', 'Local authority', 'Phone', 'Flags', 'Contact attempts', 'Approve kit', 'Lilie Id'
    ]
  }
  if (new URL(window.location.href).searchParams.get('filters') === 'Approved') {
    titilevalue = [
      '', 'Request received', 'Name', 'Reference no', 'Age', 'Local authority', 'Phone', 'Flags', 'Approve kit', 'Date approved', 'In Lilie?', 'Lilie Id'
    ]
  }
  if (new URL(window.location.href).searchParams.get('filters') === 'Search results') {
    titilevalue = [
      '', 'Request received', 'Name', 'Reference no', 'Age', 'Local authority', 'Phone', 'Flags', 'Contact attempts', 'Approve kit', 'Date approved', 'Sample received', 'Result', 'In Lilie?',
      (new URL(window.location.href).searchParams.get('filters') === 'Added to Lilie' ||
        new URL(window.location.href).searchParams.get('filters') === 'Search results' || new URL(window.location.href).searchParams.get('filters') === 'Archive' || new URL(window.location.href).searchParams.get('filters') === 'Rejected' || new URL(window.location.href).searchParams.get('filters') === 'Positive' || new URL(window.location.href).searchParams.get('filters') === 'Negative' || new URL(window.location.href).searchParams.get('filters') === 'Inconclusive') &&
        (localStorage.getItem('role') === '1' || (user))
        ? 'Archive'
        : '', 'Lilie Id'
    ]
  }

  const tableHead: any = {
    type: 'orderKit',
    titles: titilevalue,
    filter: new URL(window.location.href).searchParams.get('filters'),
  }

  const App = FetchData(url, setURL, encodeURIComponent(searchedResult))(TableView)({ tableHead })
  // Set Search values the page load indicator ------start
  useEffect(() => {
    if (new URL(window.location.href).searchParams.get('filters') === 'Search results' && typedValue === search) {
      if (search) {
        SetsearchedResult(search?.split(','));
        if (!authFormTabsMenu.some((e: any) => e.label === objects.label)) {
          authFormTabsMenu.unshift(objects)
        }
      }
    }
    if (new URL(window.location.href).searchParams.get('filters') !== 'Search results' && searchedResult.length > 0) {
      if (authFormTabsMenu.some((e: any) => e.label === objects.label)) {
        authFormTabsMenu.splice(0, 1);
      }
      SetsearchedResult([]);
      SetSearchValue('');
    }
    if (new URL(window.location.href).searchParams.get('filters') !== 'Search results' && searchedResult.length === 0) {
      if (authFormTabsMenu.some((e: any) => e.label === objects.label)) {
        authFormTabsMenu.splice(0, 1);
      }
      SetsearchedResult([]);
      SetSearchValue('');
    }
  }, []);


  if (new URL(window.location.href).searchParams.get('filters') === 'Search results' && searchedResult.length === 0) {
    if (search) {
      SetsearchedResult(search?.split(','));
      if (!authFormTabsMenu.some((e: any) => e.label === objects.label)) {
        authFormTabsMenu.unshift(objects)
      }
    }
  };
  if (new URL(window.location.href).searchParams.get('filters') !== 'Search results' && searchedResult.length > 0) {
    if (authFormTabsMenu.some((e: any) => e.label === objects.label)) {
      authFormTabsMenu.splice(0, 1);
    }
    SetsearchedResult([]);
    SetSearchValue('');
  };




  // Set Search values the page load indicator ------end

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: '#fff',
      padding: 20,
    }),
  }

  async function downloadArchiveExcel() {
    SetShowLoader(true)
    let siteUrl = process.env.REACT_APP_SERVICE_BACKEND_URL + '/'
    const accessToken = localStorage.getItem('access-token')
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Disposition': 'attachment; filename=sample.xlsx',
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        Authorization: 'Bearer ' + accessToken,
      },
      responseType: 'arraybuffer',
    }
    await axios
      .get(siteUrl + '/admin/download', config)
      .then(async (response) => {
        if (response) {

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${Date.now()}.xlsx`)
          document.body.appendChild(link)
          link.click()
          SetShowLoader(false)
        }
      })
      .catch((err) => SetShowLoader(false))
  }
  const tabIndex = 0;
  // Enter button click
  const handleKeyboardEvent = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      handleClick();
    }
  }
  const handleClick = () => {
    if (textInput.current.value.length > 0) {
      if (searchedResult.includes(textInput.current.value)) {
        textInput.current.value = '';
      } else {
        searchedResult = [...searchedResult, textInput.current.value.trim()];
        SetsearchedResult(searchedResult);
        textInput.current.value = '';
      }

      if (!authFormTabsMenu.some((e: any) => e.label === objects.label)) {
        authFormTabsMenu.unshift(objects)
      }
      history.push(`${appUrls.admin.orderKit}?filters=Search results&search=${encodeURIComponent(searchedResult)}`)
    }
  }

  const removeItem = (value: any) => {
    SetsearchedResult((searchedResult: any) => searchedResult.filter((item: any) => item !== value));
    history.push(`${appUrls.admin.orderKit}?filters=Search results&search=${searchedResult.filter((item: any) => item !== value)}`)

    if (searchedResult.filter((item: any) => item !== value).length === 0) {
      authFormTabsMenu.splice(0, 1);
      history.push(`${appUrls.admin.orderKit}?filters=Flagged`)
    }
  }

  return (
    <>
      {showLoader && <Loader />}
      <AdminLayout getMenuList={authPriNavMenu} roleMode="Super admin mode">
        <SecNav getMenuList={syncOrderTabsMenu} />

        {/* <h4 className="login-style1" tabIndex={tabIndex}>Manage form submissions</h4> */}
        {/* <hr /> */}
        <div className="d-flex flex-wrap justify-content-between">
          <h1 className="page-heading" tabIndex={tabIndex}>Order an STI test kit</h1>
          {(permissionAccess && permissionAccess[0] && permissionAccess[0].value) && new URL(window.location.href).searchParams.get('filters') === 'Archive' && (
            <button type="button" className="btn btn-primary" onClick={downloadArchiveExcel}>
              Export as excel
            </button>
          )}
        </div>
        <div className="search-blk">
          {/* <form className="commentForm"> */}
          <img src={SearchIcon} alt='search' className='search-icon' />
          {searchValue.length !== 0 &&
            <img src={CloseIcon} alt='close' className='close-icon' onClick={() => SetSearchValue('')} />
          }
          <label className="sr-only" htmlFor='emailId' tabIndex={tabIndex} />
          <input
            id='emailId'
            type="text"
            className={`${searchValue.length === 0 ? 'form-control input-w login-radius reduce-mar' : 'form-control input-w login-radius'}`}
            placeholder="Search for name, phone number, reference number, site code, age,..."
            ref={textInput}
            onKeyDown={handleKeyboardEvent}
            maxLength={250}
          // value={searchValue}local_authority,date and email
          // onChange={(e) => SetSearchValue(e.target.value)}
          />
          {/* <button type="button" className={`${textInput.current.value.length ? 'disabled btn btn-primary login-btn' : 'btn btn-primary login-btn'}`} onClick={() => handleClick(searchValue)}>Search</button> */}
          <button type="button" className='btn btn-primary login-btn' onClick={() => handleClick()}>Search</button>
          {/* </form> */}
        </div>
        {searchedResult.length !== 0 &&
          <div>
            <p>{Multiresp && Multiresp !== true ? 'Searched' : 'Multiple requests'}: <span>{searchedResult.map((item: String) => { return <button className="search-kit-btn"> <span>{item} </span><span onClick={() => removeItem(item)}>x</span></button> })}</span></p>
          </div>
        }
        <TriNav setFilter={setFilter} getMenuList={authFormTabsMenu} />
        <App />
        {/* <Popup rejectKit={false} approveKit={true} close={() => { }} userdata={''}></Popup> */}
      </AdminLayout>
    </>
  )
}
