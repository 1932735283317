import React, { useState, useEffect } from 'react'

import moment from 'moment'

import Popup from '../../../components/admin/popup'
import { SecNav } from '../../../components/admin/SecNav'
import { TriNav } from '../../../components/admin/TriNav'
import useAuthContext from '../../../context/provider/AuthContext'
import { useLocationContext } from '../../../context/provider/LocationContext'
import { IUserInfo } from '../../../models/Types'
import apiFunctions from '../../../services/api-service'
import { getPostcodeRegion, getUserRegionLocations } from '../../../services/api/PostcodesAPI'
import { AdminLayout } from '../AdminLayout'
import '../../../styles/common.css'
import '../../../styles/AdminStyle/adminSettings.css'
import { authPriNavMenu, authManageSettingsSecNav, authManageAccountSettingTriNav } from '../AdminOptions'

// import { managePermissions } from './permissionoptions'

interface AccountSettingsProps {
  userDetails?: IUserInfo
}

export const AccountSettings: React.FC<AccountSettingsProps> = ({ userDetails }) => {
  // const { theme, themeDispatch } = useContext(ThemeContext);
  const [showsuccessPopup, setshowsuccessPopup] = useState(false)
  const [showPopup, setshowPopup] = useState(false)
  const [UserData, setUserData] = useState({
    user_id: '',
    name: '',
    email: '',
    role: '',
    approverName: '',
    account_type: '',
    account_created: '',
    account_authorised: '',
    permissions: [],
    roleId: 0,
    role_request: '',
  })
  const {
    state: { user },
  } = useAuthContext()
  const closeModal = () => {
    setshowsuccessPopup(false)
    setshowPopup(false)
  }
  const [role] = useState(localStorage.getItem('role'))
  const [regionListSelect, setRegionListSelect] = useState([])
  const [dropDown, setDropDown] = useState(false)
  const { state: { locationList }, dispatch } = useLocationContext();

  let Userinfo: any = {}
  useEffect(() => {
    apiFunctions
      .get(process.env.REACT_APP_SERVICE_BACKEND_URL + '/auth/get-user-data')
      .then((_result) => {
        // console.log(_result, '-----_result-----');

        let userDetail = _result.data.data
        if (userDetail) {
          setUserData({
            user_id: userDetail.user.id,
            name: userDetail.user.name,
            email: userDetail.user.email,
            approverName: userDetail.approverName,
            role:
              userDetail.user && userDetail.user.roleinfo && userDetail.user.roleinfo.roleName
                ? userDetail.user.roleinfo.roleName
                : '',
            account_type: userDetail.user
              ? userDetail.user.roleAtBrook.replace(/[&\\\#+()$~%.'":*?<>{}]/g, '').replace(/,/g, ', ')
              : null,
            account_created: moment(userDetail.user.createdAt).format('D MMMM YYYY'),
            account_authorised: '',
            permissions: Getpermissions(userDetail.user.permissions, userDetail.user),
            roleId: userDetail.user.role,
            role_request: userDetail.user.roleChangeRequest,
          })
        }
      })
      .catch((err) => {
      })
  }, [])

  // API call for region
  useEffect(() => {
    async function getRegion() {
      try {
        const regionList = await getUserRegionLocations();
        if (regionList) {
          if (regionList.data.data.locations.length === 0 && localStorage.getItem('role') === '1') {
            const allLocationList = await getPostcodeRegion();
            if (allLocationList) {
              const modSkeleton: any = [];
              allLocationList.data.data.map((item: any) => modSkeleton.push({
                name: item,
                status: true
              }));
              dispatch({ type: 'SET_LOCATION', data: modSkeleton });
              setRegionListSelect(modSkeleton)
            }
          } else {
            dispatch({ type: 'SET_LOCATION', data: regionList.data.data.locations });
            setRegionListSelect(regionList.data.data.locations)
          }
        }
      } catch (error) {
        console.log('regionListError', error)
      }
    }
    getRegion()
  }, [])

  function Getpermissions(permission: any, __param: any) {
    let finalPermissionfororder: any = []
    let finalPermissionforteam: any = []
    // if (!Boolean(permission.length)) return []
    if (__param.role === 1 || __param.role === 2) {
      permission && permission.orders_and_results.map((val: any) => {
        if (val.value === true) {
          finalPermissionfororder.push(val.label)
        }
      })
      permission && permission.team_members.map((val: any) => {
        if (val.value === true) {
          finalPermissionforteam.push(val.label)
        }
      })

      const finalPermissions = [...finalPermissionfororder, ...finalPermissionforteam];

      return finalPermissions

    }
    else {
      permission && permission.orders_and_results.map((val: any) => {
        if (val.value === true) {
          finalPermissionfororder.push(val.label)
        }
      })
      return finalPermissionfororder
    }
  }

  const showcallback = (result: any) => {
    setshowPopup(false)
    setshowsuccessPopup(true)
  }
  function Capitalize(str: any) {
    str = str.split(' ')
    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
    }
    const CapitalLetter = str.join(' ')
    return CapitalLetter
  }
  function FirstCapitalize(str: any) {
    str = str.split(',')
    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1).toLowerCase()
    }
    const CapitalLetter = str.join(',')
    return CapitalLetter
  }
  const tabIndex = 0;
  return (
    <AdminLayout getMenuList={authPriNavMenu} roleMode="Super admin mode" sideBar="Settings">
      <div className="admin-login-style">
        {role !== '3' && (
          <>
            <SecNav getMenuList={authManageSettingsSecNav} />
            <h1 className="page-heading">Change settings</h1>
            <TriNav getMenuList={authManageAccountSettingTriNav} />
          </>
        )}
        {role === '3' && (
          <>
            <div className="col-12 col-sm-10 col-md-6 mt-4">
              <div className="login-style1" tabIndex={tabIndex}>Your account</div>
            </div>
            <hr />
            <div className="col-12 col-sm-10 col-md-6">
              <p className="login-style2" tabIndex={tabIndex}>Change settings</p>
            </div>
            <div className="mt-5 ml-5">
              <TriNav getMenuList={authManageAccountSettingTriNav} />
            </div>
          </>
        )}
        <div>
          <div className="col-12 col-sm-10 col-md-12 sub-head">
            <div className="text-account" tabIndex={tabIndex}> Name</div>
            <div className=" input-act" tabIndex={tabIndex}> {UserData && UserData.name ? Capitalize(UserData.name) : ''} </div>
            <div className="text-account" tabIndex={tabIndex}> Email address associated with account </div>
            <div className=" input-act pass-text-style" tabIndex={tabIndex}>{UserData && UserData.email ? UserData.email : ''}</div>
            <div className="text-account" tabIndex={tabIndex}>Role</div>
            <div className="input-act" tabIndex={tabIndex}>{UserData && UserData.role ? FirstCapitalize(UserData.role) : ''}</div>
            <div className="text-account" tabIndex={tabIndex}> Account type </div>
            <div className="input-act" tabIndex={tabIndex}> {UserData && UserData.account_type ? UserData.account_type : ''}</div>
            <div className="text-account" tabIndex={tabIndex}>Account created</div>
            <div className="input-act" tabIndex={tabIndex}>{UserData && UserData.account_created ? UserData.account_created : ''}</div>
            {UserData.approverName ? (
              <>
                <div className="text-account" tabIndex={tabIndex}> Account authorised by </div>
                <div className="input-act" tabIndex={tabIndex}>{Capitalize(UserData.approverName)}</div>
              </>
            ) : (
              ''
            )}

            {UserData && UserData.permissions.length > 0 ? <div className="text-account" tabIndex={tabIndex}> Permissions</div> : ''}
            {UserData &&
              UserData.permissions &&
              UserData.permissions.map((permission, index) => <div className=" input-act" tabIndex={tabIndex}>{permission}</div>)}
            {UserData && UserData.roleId === 3 ? (
              <div className="login-btn-wrap">
                <button
                  type="button"
                  className="btn btn-primary float-right login-btn"
                  disabled={UserData.role_request === 'pending'}
                  onClick={() => {
                    setshowPopup(true)
                  }}
                >
                  Request change
                </button>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <Popup RequestChange={showPopup} callback={showcallback} userdata={UserData} close={closeModal} />
      <Popup successpopup={showsuccessPopup} close={closeModal} />
    </AdminLayout>
  )
}

export default AccountSettings
