import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom';

import { ReactComponent as BackArrow } from '../../../../assets/images/back-arrow.svg';
import { ManageCard } from '../../../../components/admin/ManageCard';
import { PermissionManagementModal } from '../../../../components/admin/PermissionManagementModal';
import { SecNav } from '../../../../components/admin/SecNav';
import { DropdownSubmodule } from '../../../../components/dropdownCascade/DropdownSubmodule';
import Loader from '../../../../components/Loader/loader'
import apiFunctions from '../../../../services/api-service'
import { AdminLayout } from '../../AdminLayout';
import { authManageSettingsSecNav, authPriNavMenu } from '../../AdminOptions';
import { PermissionMgmtMenuList } from './PermissionMgmtMenuList';

interface EditManagerProps {

}

export const EditManager: React.FC<EditManagerProps> = ({ }) => {
  let url:any = process.env.REACT_APP_SERVICE_BACKEND_URL;
  const searchParams = new URLSearchParams(window.location.search);
  const myQueryParam = searchParams.get('user_id');
  const history = useHistory();
  const[loader,setLoader]= useState(true);
  const[userData,setUserData]= useState<any>({name:'',permissions:{}});

  const[dotMenu, setdotMenu]= useState<any>([
    {
      value: 'changeRole',
      label: 'Change account type',
      children: [
        {
          value: 'user',
          label: 'User'
        },
      ]
    },
    {
      value: 'archive',
      label: 'Archive'
    }
  ]);
  const [internalState, setInternalState] = useState<any>({
    actionType: '',
    isModalVisible: false
  });

  const titleModal = `${internalState.actionType === 'archive' ?
    "Are you sure you want to archive selected team members ?" :
    internalState.actionType === 'user' ?
    "Are you sure you want to change roles of selected managers to users ?" :
      ""}`;
 useEffect(()=>{  
  getManagerDatas();
  },[]);
      
 const getManagerDatas = async()=>{ 
   const URL = url + `/admin/get-user-permission?user_id=${myQueryParam}`;
   const response = await apiFunctions.get(URL);   
   setUserData({name:response.data.data.result.name,permission:response.data.data.result.permissions})      
   setLoader(false)
  }
  const getactionType = (selectedValue: string) => {
    setInternalState({
      ...internalState,
      actionType: selectedValue,
      isModalVisible: true
    })
  };
  const handleGoBack = () => {
    history.goBack()
  };

  const handleOnChangeOrderResult = (e: any,index:number) => {
     userData.permission.orders_and_results[index].value = e.target.checked;
    setUserData({...userData ,userData});   
  };

  const handleOnChangeTeamMember = (e: any,index:number) => {
    userData.permission.team_members[index].value = e.target.checked;
    setUserData({...userData ,userData}); 
  };

  const updataPermissions = async ()=>{
    setLoader(true)
    const URL = url + `/admin/update-permission?user_id=${myQueryParam}`;
    const payload = {user_id:[myQueryParam],permissions:userData.permission}
    try{
      const response = await apiFunctions.put(URL,payload);   
      await getManagerDatas();    
      setLoader(false)
    }catch(error){
      setLoader(false)
    }
  }

  const handleCloseModal = () => {
    setInternalState({
      ...internalState,
      isModalVisible: false
    });
  };

// User Archive 
const managersArchive = async() => {
  setLoader(true)
  const URL = url + '/admin/archive-unarchive-administrators'
  try{
    await apiFunctions.put(URL,{type:'archive',user_ids:[myQueryParam]});
    history.goBack();
    setLoader(false)
  }catch(err){
    setLoader(false)
      console.log(err);

  }
};

// User Change Role 
const userRoleChange = async() => {

  setLoader(true)
  const URL = url +'/admin/change-administrators-role'
  try{
    await apiFunctions.put(URL,{type:internalState.actionType,user_ids:[myQueryParam]});
    history.goBack();
    setLoader(false)
  }catch(err){
      console.log(err);
      setLoader(false)
  }
};
  return (
    <div className='add-member-blk'>
      {loader && <Loader />}
      <AdminLayout getMenuList={authPriNavMenu} roleMode="Super admin mode" sideBar="Settings">
        <SecNav getMenuList={authManageSettingsSecNav} />
        <div className='perm-mgmt-block'>
          <PermissionMgmtMenuList />
          <div className='perm-mgmt-content-blk'>
            <div className='ed-name-blk'>
              <button className='mr-5 mt-1' onClick={handleGoBack}>
                <BackArrow />
              </button>
              <div className='name-blk'>
                <h5 className='user-name'>{userData.name}</h5>
                <span className='role-name'>Manager</span>
              </div>
              <div className='drop-down-section'>
                {/* <span>⋮</span> */}
                <DropdownSubmodule
                  menus={dotMenu}
                  actionType={getactionType}
                />
              </div>
            </div>
            <div className='manage-card-section'>
              <div className='edit-perm-blk'>
                <h4 className='edit-perm-label'>Edit permissions</h4>
                <button
                  type="submit"
                  className="btn btn-primary login-btn" onClick={updataPermissions}>
                  Update
                </button>
              </div>
              <ManageCard
                data={userData.permission && userData.permission.orders_and_results}
                handleOnChange={handleOnChangeOrderResult}
                subTitle="If 'Approve/reject request' is switched on, 'Mark as added to Lilie - Flagged Orders' must also be switched on."
                headerLabel='Manage orders and results' />
              <div className='h-10' />
              <ManageCard
                data={userData.permission && userData.permission.team_members}
                handleOnChange={handleOnChangeTeamMember}
                headerLabel='Manage team members' />
            </div>
          </div>
        </div>
        {internalState.isModalVisible ?
          <PermissionManagementModal
            title={titleModal}
            SelectType={internalState.actionType}
            handleCloseModal={handleCloseModal}
            handlePositiveButton={managersArchive}
            userRoleChange={userRoleChange}
          /> : null}
      </AdminLayout>
    </div>
  );
}