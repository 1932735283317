import React, { useEffect, useState } from 'react'

import '../styles/ContactAttempt.css';
import { ReactComponent as ClockIcon } from '../../assets/images/clock.svg'
import { ReactComponent as DislikeIcon } from '../../assets/images/dislike.svg'
import { ReactComponent as LikeIcon } from '../../assets/images/like.svg'
import useAuthContext from '../../context/provider/AuthContext';




interface ContactAttempt {
    value?: string | undefined;
    addClass?: string;
    type?: any;
    onClick?: any;
    typeClick?: any
    myobj?: any
    contactDisabled?: any
    permissions?: any;
    rolePermissions?: any;
}

export const ContactAttempt: React.FC<ContactAttempt> = (props: any) => {
    const [flagData, setflagdata] = useState(true);
    const [contactAdmin, setcontactAdminPresmission] = useState(true);
    const [contactStaff, setcontactStaffPresmission] = useState(true);
    const [role] = useState(localStorage.getItem('role'));
    // const roletype = String(localStorage.getItem('role'));

    const {state: { user },} = useAuthContext()
    const permissionData:any = user;
    let permissionAccess = permissionData && permissionData.user ? permissionData.user:permissionData;
    permissionAccess = permissionAccess && permissionAccess.permissions?.orders_and_results.filter((ele:any)=>{return ele.name === 'client_contact'});
    
    
    useEffect(() => {
        if (props.permissions) {
          
            setflagdata((role === '3') ? props.permissions.u_manage_flags : (role === '2') ? props.permissions.admin_manage_flags : (role === '1') ? true : false)
            setcontactAdminPresmission(permissionAccess && permissionAccess[0] && permissionAccess[0].value)
            setcontactStaffPresmission(permissionAccess && permissionAccess[0] && permissionAccess[0].value)
        }
    }, [props.permissions, flagData, role])

    return (
        <>
            {props.type === 'add' &&
                <div className='contact-add' ><button className={`approve-kit-btn ${!(permissionAccess && permissionAccess[0] && permissionAccess[0].value)
                ? 'disable' : ''}`} onClick={e => props.typeClick(props.myobj)} ><span className='mr-1'>+</span>Add</button></div>
            }
            {props.type === 'clientContacted' &&
                <div ><button className='approve-kit-btn disable remove-opacity' onClick={e => props.typeClick(props.myobj)}><span className='d-flex'><span>
                    <LikeIcon /></span><span className='adjust-pos'>Client contacted</span></span></button></div>
            }
            {props.type === 'unableToContact' &&
                <div className='no-contact'><button className='approve-kit-btn disable remove-opacity' onClick={e => props.typeClick(props.myobj)}><span className='d-flex'><span className='unable_contact_icon'>
                    <DislikeIcon /></span><span className='adjust-pos'>Unable to contact</span></span></button></div>
            }
            {props.type === 'awaitingResponse' &&
                <div className='no-contact' ><button className={`await-kit-btn ${(props.contactDisabled === false || (role === '2' && !contactAdmin
                ) || (role === '3' && !contactStaff
                )) ? 'disable remove-opacity' : ''}`}  onClick={e => props.typeClick(props.myobj)}><span className='d-flex'><span className='response_icon'>
                    <ClockIcon /></span><span className='adjust-pos'>Awaiting response</span></span></button></div>
            }
        </>
    );
}