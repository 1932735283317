import React, { useCallback, useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import '../styles/addressdes.css'

interface AddressdesProps {
  children: any
}

export const Addressdes: React.FC<AddressdesProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)

  const history = useHistory()

  useEffect(() => {
    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: '60' },
    })
  }, [])
  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('address_id')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  const proceed = useCallback(() => {
    TextToSpeech.getInstance().playVoice(null, 'stop')
    theme.quesObj['Sex Consent'] = theme.quesObj['Sex Consent'] ? JSON.stringify(theme.quesObj['Sex Consent']) : ''
    Props.history.push('/address-form')
  }, [])
  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }
  return (
    <div className={`bg-color addressdes-style ${theme.theme}-skin-color`}>
      <div className="">
        <div className="text-to-speech brook-container pad-bot">
          <div className="h-div mt-4">
            <h2 className={`page-header-txt ${theme.theme}-txt-global`}>ORDER A TEST KIT</h2>
            <div className="speaker-i">
              <button
                className="no-background-border"
                onClick={(e) => {
                  TextToSpeech.getInstance().playVoice('text-to-speech')
                  setisAccessibilityVoiceOver(true)
                }}
              >
                <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
              </button>
            </div>
          </div>
          <div className={`b1-style ${theme.theme}-txt-global`}>Address</div>
          <div className={`p2-style ${theme.theme}-txt-global`}>
            Please provide the address you would like us to send your test kit to.
            <br />
          </div>
          <div className={`p3-style p2-style ${theme.theme}-txt-global`}>
            {' '}
            This can be your home address, or another address that you have access to.
          </div>
          <div className={`ft-tab brook-container ${theme.theme}-skin-color`}>
            <button className="float-left no-background-border" id="address_id" onClick={(e) => GoBackPage()}>
              <img src={backIcon} alt="back" />
            </button>
            <Button
              isDisabled={false}
              buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
              buttonText="Next"
              onClick={proceed}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
