import React, { useCallback, useContext, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import Icon from '../assets/images/Speaker_Icon.svg'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import '../styles/vaginalsex.css'
import { getTFourPlusDisease } from '../utils/getTFourPlusDisease'

interface VaginalsexProps {
  children: any
}

export const Vaginalsex: React.FC<VaginalsexProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)
  const history = useHistory()

  useEffect(() => {
    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: '41' },
    })
  }, [])

  const proceed = useCallback(() => {
    const isTFourPlusDisease = getTFourPlusDisease(theme)
    TextToSpeech.getInstance().playVoice(null, 'stop')
    if (isTFourPlusDisease === true) {
      // history.push('/risk-hepatitis')
      history.push('/eligibilitypass')
    } else {
      history.push('/preContact')
    }
  }, [])
  const tabIndex = 0
  return (
    <div className="bg-color vaginalsex-style">
      <div className="brook-container pad-bot">
        <div className="text-to-speech">
          <div className="h-div mt-4">
            <h2 className="page-header-txt">ORDER A TEST KIT</h2>
            <div className="speaker-i">
              <button
                className="no-background-border"
                onClick={(e) => TextToSpeech.getInstance().playVoice('text-to-speech')}
              >
                <img src={Icon} alt="speaker" />
              </button>
            </div>
          </div>
          <div>
            <div className="b1-style">Emergency contraception and pregnancy</div>
            <div className="body-content">
              If you have had vaginal sex and think there is a chance you might be pregnant, for example if a condom
              broke/failed or you missed a pill, then you might also want to consider emergency contraception or a
              pregnancy test. <br />
              <div className="mt-3">
                <strong>
                  <a
                    href="https://hello.brook.org.uk/emergency-contraception"
                    className="te-col text-decoration-underline hover:text-white"
                    target="_blank"
                  >
                    Find out more about emergency contraception
                  </a>
                </strong>
                <br />
              </div>
              {/* <a href="https://www.brook.org.uk/your-life/emergency-contraception/" target="_blank" className='li-color'>https://www.brook.org.uk/your-life/emergency-contraception/</a>.<br></br></div> */}
              <div className="mt-3 mb-3">
                <strong>
                  {' '}
                  <a
                    href="https://www.brook.org.uk/your-life/am-i-pregnant/"
                    className="te-col text-decoration-underline hover:text-white"
                    target="_blank"
                  >
                    {' '}
                    Find out more about pregnancy testing and choices{' '}
                  </a>
                </strong>
                <br />
              </div>
              {/* <a href="https://www.brook.org.uk/your-life/am-i-pregnant/" className="li-color" target="_blank">https://www.brook.org.uk/your-life/am-i-pregnant/</a>.</div></div> */}
              <div className="ft-tab brook-container p-color jus-end">
                <Button
                  isDisabled={false}
                  buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                  buttonText="Next"
                  onClick={(e) => proceed()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
