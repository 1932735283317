import React from 'react'

interface ManageCardProps {
  data: any;
  handleOnChange: (e: any,inx:number) => void;
  headerLabel?: string;
  subTitle?: string;
}

export const ManageCard: React.FC<ManageCardProps> = ({
  data = [],
  handleOnChange,
  headerLabel = '',
  subTitle = ''
}) => {
  return (
    <>
      <div className='mb-10'>
        {headerLabel ? <h2 className='manage-grid-label'>{headerLabel}</h2> : null}
        {subTitle ? <p>{subTitle}</p> : null}
      </div>

      {data && data.length ?
        <div className='manage-grid-blk'>
          {data.map((item: any, idx: number) => {
            return (
              <div key={`manage-item-${idx}`} className='manage-grid-item'>
                <div className="custom-checkbox">
                  <label className="check-label" htmlFor={`manage-card-select-${idx}-${item.name}`}>
                    <input
                      id={`manage-card-select-${idx}-${item.name}`}
                      type="checkbox"
                      className="check-input"
                      checked={item.value}
                      onClick={(e)=>{handleOnChange(e,idx)}}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <p className='manage-name'>{item.label}</p>
              </div>
            )
          })}
        </div> :
        <div className='no-data-card'>
          <p className='manage-name'>No data found</p>
        </div>}
    </>
  );
}