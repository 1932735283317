import { useContext, useEffect } from 'react'

import moment from 'moment'
// import TagManager from 'react-gtm-module'
import { HelmetProvider } from 'react-helmet-async'
import { TinaProvider, TinaCMS } from 'tinacms'

import { AuthContextProvider } from './context/provider/AuthContext'
import { LocationProvider } from './context/provider/LocationContext'
import { NotificationContextHeader } from './context/provider/NotificationContext'
import { ThemeContextProvider } from './context/provider/ThemeContext'
import TinaAuthContext, { TinaAuthContextProvider } from './context/provider/TinaAuthContext'
import { TrackerContextProvider } from './context/provider/TrackerContext'
import AppRoute from './routes'
import helperService from './services/helper-service'
import DummyMediaStore from './utils/mediaStore'

import 'react-datepicker/dist/react-datepicker.css'
import 'moment/locale/en-ca'

// locale for application
moment.locale('en-ca')

// if (process.env.REACT_APP_GA_TAGMANAGER) {
//   const tagManagerArgs = {
//     gtmId: process.env.REACT_APP_GA_TAGMANAGER,
//     dataLayerName: 'PageDataLayer',
//   }
//   TagManager.initialize(tagManagerArgs)
// }

function App() {
  let isAuthenticated = helperService.isAuthenticatedAdmin()
  const { toggleTinaAdminAuthenticated, changeTinaAdminUserData } = useContext(TinaAuthContext)

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        console.log('Enter key was pressed. Run your function.');
        event.preventDefault();
        // callMyFunction();
        const el = $(event.target);

        if ($(el).hasClass('focusto')) {
          if ($(el).attr('focus-to')) {
            // remove attr
            $('[focus-back-element="true"]').each(function (i, e) {
              $(e).removeAttr('focus-back-element');
            });
          }
          if (!$(el).hasClass('question-menu-block')) {
            $('.focusback_btn').removeClass('flex');
            $('.focusback_btn').addClass('hidden');
          }
          if (!$(el).hasClass('user-role-section')) {
            $('.focusback_btn').removeClass('flex');
            $('.focusback_btn').addClass('hidden');
          }

          if (
            $('[focus-back-element="true"]').length === 0 &&
            $(el).attr('focus-to')
          ) {
            console.log('----------no element------------');

            if ($(el).hasClass('question-menu-info')) {
              $('.focusback_btn').removeClass('hidden');
              $('.focusback_btn').addClass('flex');
            }
            if ($(el).hasClass('user-role-section')) {
              $('.focusback_btn').removeClass('hidden');
              $('.focusback_btn').addClass('flex');
            }

            if ($(el).hasClass('setting_popup_focus')) {
              setTimeout(function () {
                console.log(
                  '------rrrrrrrrrrrr--------',
                  $('.setting_back_focus')
                );
                $('.setting_back_focus').removeClass('hidden');
              }, 1000);
              $('.setting_back_focus').addClass('flex');
            }

            // add identity to element
            $(el).attr('focus-back-element', 'true');
          } else {
            console.log(
              '----------element--exist----------',
              $('[focus-back-element="true"]').length
            );
            // get element
            const element: any = $('[focus-back-element="true"]')[0];
            if (element) {
              console.log('focus-back-element', element);
              element.focus();

              if ($(el).hasClass('focusback_btn')) {
                $('.focusback_btn').removeClass('flex');
                $('.focusback_btn').addClass('hidden');
              }
              if ($(el).hasClass('setting_back_focus')) {
                console.log('-setting_back_focus- 2', el);

                $('.setting_back_focus').removeClass('flex');
                $('.setting_back_focus').addClass('hidden');
              }

              if ($(el).attr('focus-to') && !$(el).attr('focus-back-element')) {
                // remove attr
                $('[focus-back-element="true"]').each(function (i, e) {
                  $(e).removeAttr('focus-back-element');
                });
                $('.focusback_btn').each(function (i, e) {
                  $(e).removeClass('flex');
                  $(e).addClass('hidden');
                });
              }

              // remove attr
              $('[focus-back-element="true"]').each(function (i, e) {
                $(e).removeAttr('focus-back-element');
              });
            }
          }

          el.trigger('click');

          if (
            el.attr('focus-to') &&
            document.getElementById(el.attr('focus-to') || 'null')
          ) {
            const focusToEl = document.getElementById(
              el.attr('focus-to') || 'null'
            );
            if (focusToEl) {
              focusToEl.focus();
            }
          }
        }

        const elm = $(event.target);

        if ($(elm).hasClass('onkeyup')) {
          el.trigger('click');
        }
      }
    };
    document.addEventListener('keyup', listener);
    return () => {
      document.removeEventListener('keyup', listener);
    };
  }, []);

  const cms = new TinaCMS({
    enabled: true,
    toolbar: true,
    media: new DummyMediaStore(),
  })

  function Logout() {
    cms.disable()
    localStorage.clear()
    sessionStorage.clear()
    toggleTinaAdminAuthenticated()
    changeTinaAdminUserData()
    // history.push('/cms-login')
    // window.location.href = '/cms-login'
  }

  function TinaDashboard() {
    return (
      <>
        <button onClick={() => alert('Good day to ya')}>Dashboard</button>
        <button onClick={Logout}>Logout</button>
      </>
    )
  }

  const TinaWidget = {
    __type: 'toolbar:widget',
    name: 'howdy',
    weight: 5,
    component: TinaDashboard,
  }

  cms.plugins.add(TinaWidget)
  if (!isAuthenticated) {
    cms.disable()
  }

  return (
    <HelmetProvider>
      <TinaAuthContextProvider>
        <AuthContextProvider>
          <ThemeContextProvider>
            <TrackerContextProvider>
              <NotificationContextHeader>
                <LocationProvider>
                  <TinaProvider cms={cms}>
                    <AppRoute />
                  </TinaProvider>
                </LocationProvider>
              </NotificationContextHeader>
            </TrackerContextProvider>
          </ThemeContextProvider>
        </AuthContextProvider>
      </TinaAuthContextProvider>
    </HelmetProvider>
  )
}

export default App
