import React, { useCallback, useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../../assets/images/Back_Button.svg'
import Icon from '../../assets/images/Speaker_Icon.svg'
import { Button } from '../../components/button/Button'
import TextToSpeech from '../../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../../context/provider/ThemeContext'
// import appUrls from '../../services/AppURLs'
import '../../styles/confirmemail.css'
// import { history } from '../../utils/history'

interface OrderConfirmationProps {
  children: any
}

export const OrderConfirmation: React.FC<OrderConfirmationProps> = (Props: any) => {
  const [yes, setYes] = useState(false)
  const [no, setNo] = useState(false)
  const { theme, themeDispatch } = useContext(ThemeContext)
  const history = useHistory()

  const proceed = useCallback(() => {
    TextToSpeech.getInstance().playVoice(null, 'stop')
    themeDispatch({ type: 'SET_EMAIL_CONFIRM', payload: { isEmailConfirm: yes } })
    if (theme.age && theme.age <= 15) {
      Props.history.push('/callyou')
    } else if (theme.age && theme.age >= 16 && theme.age <= 17) {
      Props.history.push('/userunder18')
    } else if (theme.age) {
      Props.history.push('/addressdes')
    }
  }, [yes])

  // useEffect(() => {
  //   return () => {
  //     if (history.action === 'POP') {
  //       themeDispatch({ type: 'SET_THEME_INTIAL_VALUE' })
  //       history.push(appUrls.sti.home)
  //     }
  //   }
  // }, [history])

  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }
  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('yes')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  return (
    <div className={`bg-color mobilenumber-style ${theme.theme}-skin-color`}>
      <div className="text-to-speech brook-container pad-bot">
        <div className="h-div mt-4">
          <h2 className={`page-header-txt ${theme.theme}-txt-global`}>ORDER CONFIRMATION </h2>
          <div className="speaker-i">
            <button
              className="no-background-border"
              onClick={(e) => {
                TextToSpeech.getInstance().playVoice('text-to-speech')
                setisAccessibilityVoiceOver(true)
              }}
            >
              <img src={Icon} alt="speaker" />
            </button>
          </div>
        </div>
        {/* <h2 className={`page-header-txt ${theme.theme}-txt-global`}>ORDER CONFIRMATION </h2> */}
        <div className={`b1-style b1-style ${theme.theme}-txt-global`}>
          Would you like us to send you an email confirming we have received your test kit order?
          <div className={`p3-style p2-style my-4  sub-text ${theme.theme}-txt-global`}>
            <b>Please note:</b> We are not able to send an order confirmation by text message,{' '}
            <b>so if you select ‘no’ you will not receive any messages confirming your order.</b>
          </div>
          <div className="d-flex checkbox-blk mb-3">
            <input
              aria-label="Yes, I want a confirmation email  "
              type="checkbox"
              id="yes"
              className="form-check-input check-style mt-2"
              checked={yes}
              onChange={(e) => {
                if (e.target.checked) {
                  setNo(false)
                }
                setYes(e.target.checked)
              }}
            />

            <div className={`pl-4 d-size ${theme.theme}-txt-global`}>Yes, I want a confirmation email</div>
          </div>
          <div className="d-flex checkbox-blk">
            <input
              aria-label="No, I don’t want a confirmation email"
              type="checkbox"
              id="no"
              className="form-check-input check-style mt-2"
              checked={no}
              onChange={(e) => {
                if (e.target.checked) {
                  setYes(false)
                }
                setNo(e.target.checked)
              }}
            />
            <div className={`pl-4 d-size ${theme.theme}-txt-global`}>No, I don’t want a confirmation email</div>
          </div>
          <div>
            <div className={`ft-tab brook-container ${theme.theme}-skin-color`}>
              <button className="float-left no-background-border" onClick={(e) => GoBackPage()}>
                <img src={backIcon} alt="back" />
              </button>
              <Button
                isDisabled={!(no || yes)}
                buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                buttonText="Next"
                onClick={proceed}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
