import React, { useCallback, useContext, useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import '../styles/postage.css'
import { Accordion } from '../components/accordian/Accordian'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import { stiKitLoaderPercentage } from '../utils/stiKitLoaderPercentage'

interface PostageProps {
  Props: any
}
const child = (theme: any) => {
  return (
    <>
      <div className={`p3-style p2-style ${theme.theme}-txt-global`}>
        <h2 className="postage-symptom-header">
          Common STI symptoms include: <br />
          <br />
        </h2>
        Unusual discharge from the vagina, penis or anus.
        <br />
        <br />
        Stinging, tingling, burning or itching in the genital area.
        <br />
        <br />
        Soreness or swelling in the genital area.
        <br />
        <br />
        Pain or a burning feeling when you wee, or the need to wee often.
        <br />
        <br />
        Pain or unusual bleeding from the vagina.
        <br />
        <br />
      </div>
    </>
  )
}

export const Postage: React.FC<PostageProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)

  const [state, setState] = useState<string>('')

  const history = useHistory()

  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('postage_voiceover')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  useEffect(() => {
    if (theme.quesObj && theme.quesObj['symptoms']) {
      setState(theme.quesObj['symptoms'])
    }

    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: stiKitLoaderPercentage.POSTAGE_YES },
    })
  }, [])

  const proceed = useCallback(
    (e: any) => {
      themeDispatch({
        type: 'SET_QUES',
        payload: {
          quesObj: {
            ...theme.quesObj,
            symptoms: state,
          },
        },
      })
      setisAccessibilityVoiceOver(false)
      TextToSpeech.getInstance().playVoice(null, 'stop')
      if (state === 'Yes') {
        if (theme && theme.age && theme.age > 17) {
          Props.history.push('/symptombarrier?symptom_barrier_18=true')
        }
        if (theme && theme.age && theme.age < 18) {
          Props.history.push('/symptombarrier?symptom_barrier_u18=true')
        }
        // Props.history.push('/symptombarrier')
      } else {
        Props.history.push('/successpostcode')
      }
    },
    [state]
  )
  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }
  const tabIndex = 0
  return (
    <div className={`bg-color postage-style ${theme.theme}-global`}>
      <div className="brook-container pad-bot">
        <div className="text-to-speech">
          <div className="pad-te pad-des">
            <div className="h-div mt-4">
              <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
              <div className="speaker-i">
                <button
                  className="no-background-border"
                  onClick={(e) => {
                    TextToSpeech.getInstance().playVoice(
                      null,
                      'ORDER A TEST KIT Do you have any symptoms? WHAT ARE SYMPTOMS OF AN STI? Unusual discharge from the vagina, penis or anus. Stinging, tingling, burning or itching in the genital area. Soreness or swelling in the genital area. Pain or burning feeling when you wee, or the need to wee often. Unusual bleeding from the vagina, or pain or bleeding.'
                    )
                    setisAccessibilityVoiceOver(true)
                  }}
                >
                  <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
                </button>
              </div>
            </div>
            {/* <div className={`b1-style ${theme.theme}-txt-global`}></div>
            <div className="main-btn-class"></div> */}
            <fieldset className="btn-style">
              <legend className={`b1-style  ${theme.theme}-txt-global border-none text-white`} id="postAge-btn">
                Do you have any symptoms?
              </legend>
              <ul role="group" aria-labelledby="postAge-btn" className="btn-list">
                <li>
                  <Button
                    id="postage_voiceover"
                    ariaDescribedby="postAge-btn"
                    buttonClassName={`btn btn-default btn-class ${theme.theme}-btn-global`}
                    buttonText="Yes"
                    isDisabled={false}
                    selected={state}
                    onClick={(e) => setState('Yes')}
                  />
                </li>
                <li>
                  <Button
                    buttonClassName={`btn btn-default btn-class ${theme.theme}-btn-global`}
                    buttonText="No"
                    isDisabled={false}
                    selected={state}
                    onClick={(e) => setState('No')}
                  />
                </li>
                <li>
                  <Button
                    buttonClassName={`btn btn-default btn-big ${theme.theme}-btn-global`}
                    buttonText="Don't know"
                    isDisabled={false}
                    selected={state}
                    onClick={(e) => setState("Don't-know")}
                  />
                </li>
              </ul>
            </fieldset>
          </div>
          <Accordion
            themeName={theme.theme}
            panels={[{ label: 'WHAT ARE THE SYMPTOMS OF AN STI?', content: child(theme) }]}
          />
          <div>
            <div className={`ft-tab brook-container ${theme.theme}-skin-color`}>
              <button className="float-left no-background-border" onClick={(e) => GoBackPage()}>
                <img src={backIcon} alt="back" />
              </button>
              <Button
                isDisabled={!Boolean(state.length)}
                buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                buttonText="Next"
                onClick={proceed}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
