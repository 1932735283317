import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import { ReactComponent as DropArrowIcon } from "../assets/images/dropdown_arrow.svg"
import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import '../styles/addressform.css'

interface AddressFormProps {
  Props: any
}

export const AddressForm: React.FC<AddressFormProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)
  const tabIndex = 0;

  const [address, setAddress] = useState({
    ad1: '',
    ad2: '',
    ad3: '',
    town: '',
  })
  const [errorad1, seterrorad1] = useState(false)
  const [errorad2, seterrorad2] = useState(false)
  const [errorad3, seterrorad3] = useState(false)
  const [errortown, seterrortown] = useState(false)
  const history = useHistory()
  const validAddress = new RegExp('^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$')
  const scrollDownRef = useRef<any>(null);
  const [isHideScrollElement, setScrollElement] = useState<boolean>(false);
  const [y, setY] = useState(window.scrollY);

  useEffect(() => {
    setAddress({
      ad1: theme.address_line_1,
      ad2: theme.address_line_2,
      ad3: theme.address_line_3,
      town: theme.town,
    })
    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: '65' },
    })
  }, [])

  /*
    Triger scroll direction while scrolling the page
    if scroll up to page "IF Block" executed.
  */
  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget
      if (y > window.scrollY) {
        setScrollElement(false)
      } else if (y < window.scrollY) {
        setScrollElement(true)
      }
      setY(window.scrollY)
    },
    [y]
  )

  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('address_form_id')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  const proceed = useCallback(() => {
    TextToSpeech.getInstance().playVoice(null, 'stop')
    setisAccessibilityVoiceOver(false)
    themeDispatch({
      type: 'SET_ADDRESS',
      payload: {
        address_line_1: address.ad1,
        address_line_2: address.ad2,
        address_line_3: address.ad3,
        town: address.town,
      },
    })

    Props.history.push('/confirm-address')
  }, [address])

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);
    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }

  const handleOnScroll = () => {
    if (scrollDownRef && scrollDownRef.current) {
      scrollDownRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={`bg-color addressform-style ${theme.theme}-skin-color`}>
      <div className="brook-container pad-bot form-width">
        <div className="text-to-speech">
          <div className="h-div mt-4">
            <h2 className={`page-header-txt ${theme.theme}-txt-global`}>ORDER A TEST KIT</h2>
            <div className="speaker-i">
              <button
                className="no-background-border"
                onClick={(e) => {
                  TextToSpeech.getInstance().playVoice('text-to-speech')
                  setisAccessibilityVoiceOver(true)
                }}
              >
                <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
              </button>
            </div>
          </div>
          <div className={`b1-style mt-5  pad-ad ${theme.theme} ${theme.theme}-txt-global`}>
            <span>
              Address line 1{' '}
            </span>
            <div>
              <input
                id="address_form_id"
                type="email"
                className="form-control input-style "
                aria-describedby="emailHelp"
                value={address.ad1}
                placeholder="Enter your address line 1"
                onChange={(e) => {
                  if (e.target.value !== '' && !validAddress.test(e.target.value)) {
                    seterrorad1(true)
                  } else {
                    seterrorad1(false)
                  }
                  setAddress({
                    ad1: e.target.value,
                    ad2: address.ad2,
                    ad3: address.ad3,
                    town: address.town,
                  })
                }}
              />
              <span className="errorMessage" style={{ fontSize: '20px' }}>
                {errorad1
                  ? 'Please only enter letters and numbers without punctuation marks (commas, full stops etc.) in your address.'
                  : null}
              </span>
              <div className={`b1-style mt-5  pad-ad ${theme.theme} ${theme.theme}-txt-global`}>Address line 2</div>
              <input
                type="email"
                className="form-control input-style "
                aria-describedby="emailHelp"
                placeholder="Enter your address line 2"
                value={address.ad2}
                onChange={(e) => {
                  if (e.target.value !== '' && !validAddress.test(e.target.value)) {
                    seterrorad2(true)
                  } else {
                    seterrorad2(false)
                  }
                  setAddress({
                    ad1: address.ad1,
                    ad2: e.target.value,
                    ad3: address.ad3,
                    town: address.town,
                  })
                }}
              />
              <div className={`opt-style ${theme.theme} ${theme.theme}-txt-global`}>Optional</div>
              <span className="errorMessage" style={{ fontSize: '20px' }}>
                {errorad2
                  ? 'Please only enter letters and numbers without punctuation marks (commas, full stops etc.) in your address.'
                  : null}
              </span>
              <div className={`b1-style mt-5  pad-ad ${theme.theme} ${theme.theme}-txt-global`}>Address line 3</div>
              <input
                type="email"
                className="form-control input-style "
                aria-describedby="emailHelp"
                placeholder="Enter your address line 3"
                value={address.ad3}
                onChange={(e) => {
                  if (e.target.value !== '' && !validAddress.test(e.target.value)) {
                    seterrorad3(true)
                  } else {
                    seterrorad3(false)
                  }
                  setAddress({
                    ad1: address.ad1,
                    ad2: address.ad2,
                    ad3: e.target.value,
                    town: address.town,
                  })
                }}
              />
              <div className={`opt-style ${theme.theme} ${theme.theme}-txt-global`}>Optional</div>
              <span className="errorMessage" style={{ fontSize: '20px' }}>
                {errorad3
                  ? 'Please only enter letters and numbers without punctuation marks (commas, full stops etc.) in your address.'
                  : null}
              </span>
              <div className={`b1-style mt-5  pad-ad ${theme.theme} ${theme.theme}-txt-global`}>Town</div>
              <input
                type="email"
                className="form-control input-style "
                aria-describedby="emailHelp"
                placeholder="Enter your town/city"
                value={address.town}
                onChange={(e) => {
                  if (e.target.value !== '' && !validAddress.test(e.target.value)) {
                    seterrortown(true)
                  } else {
                    seterrortown(false)
                  }
                  setAddress({
                    ad1: address.ad1,
                    ad2: address.ad2,
                    ad3: address.ad3,
                    town: e.target.value,
                  })
                }}
              />
              <span className="errorMessage" style={{ fontSize: '20px' }}>
                {errortown
                  ? 'Please only enter letters and numbers without punctuation marks (commas, full stops etc.) in your town.'
                  : null}
              </span>
              <div className={`b1-style mt-5  pad-ad ${theme.theme} mb-2 ${theme.theme}-txt-global`}>Postcode</div>
              <input
                type="email"
                className="form-control input-style "
                aria-describedby="emailHelp"
                placeholder="Enter your postcode"
                value={theme.postcode}
                disabled
              />
              <div className={`ft-tab brook-container ${theme.theme}-skin-color footer-positon`} ref={scrollDownRef}>
                <button
                  className="float-left no-background-border"
                  onClick={e => GoBackPage()}>

                  <img src={backIcon} alt="back" />
                </button>
                <Button
                  isDisabled={
                    address.ad1.length >= 1 &&
                      address.town.length >= 2 &&
                      validAddress.test(address.town) &&
                      (validAddress.test(address.ad1) || address.ad1 === '') &&
                      (validAddress.test(address.ad2) || address.ad2 === '') &&
                      (validAddress.test(address.ad3) || address.ad3 === '')
                      ? false
                      : true
                  }
                  buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                  buttonText="Next"
                  onClick={proceed}
                />
                {/* && Boolean(address.town.length) */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isHideScrollElement ?
        <div className='scroll-element'>
          <span className='scroll-arrow animate-bounce' onClick={handleOnScroll}>
            <DropArrowIcon />
          </span>
        </div> : null}
    </div>
  )
}