import React, { Component } from 'react'

import _ from 'lodash'
import moment from 'moment'

import Loader from '../../../../components/Loader/loader'
import apiFunctions from '../../../../services/api-service'

const consentOpts = [
    // 'None of the above apply to me',
    // ' None of the above apply to me, but i would like to talk to someone',
    'I have been made to feel scared or uncomfortable by the person/s I have been having sexual contact with',
    'I have been given gifts, money, drugs, alcohol or protection for sex',
    'I often drink alcohol or take drugs before having sex',
    'I feel down or suffer from feelings of depression',
    'My partner is a lot older than me',
    'None of the above apply to me, but i would like to talk to someone'
];
const FetchData = (url: string, __setURL?: any, __search?: any) => (C: any) => (moreProps: any) =>
    class extends Component {

        private _isMounted = false;

        state = {
            loading: false,
            data: null,
            error: null,
            setLength: null,
            search: { str: '', order: '' },
            totalCount: 0,
            offset:0,
            allRecords: null,
            archiveUSer: null,
            totalArchive:null,
            multiorderCount:null,
        }

        constructor(moreProps: any) {
            super(moreProps)
            this.filterDataSearch = this.filterDataSearch.bind(this);
        }

        FindResons(resons: any) {
            let myArray = resons.split(',');
            let finalArr: any = []
            let Reasonshow: any = []
            let ReasonsArr = [
                { 'age': 'User’s age' },
                { 'protection': 'User has been given gifts, money, drugs, alcohol or protection for sex' },
                { 'depression': 'User feels down or suffers from feelings of depression' },
                { 'older_partner': 'User’s partner is much older than them' },
                { 'drugs': 'User often drinks or takes drugs before having sex' },
                { 'scared': 'User has been made to feel scared or uncomfortable by the person/s they have been having sexual contact with' }
            ]
            ReasonsArr.map((val1: any) => {
                for (let i = 0; i < myArray.length; i++) {
                    finalArr.push(val1[myArray[i]])
                }
            })
            finalArr.map((val2: any) => {
                if (val2 !== undefined) {
                    Reasonshow.push(val2)
                }
            })
            return Reasonshow.join();
        }

        returnAdminStatus(__param: any) {
            if (__param === 'signup') return 'User Account';
            if (__param === 'account_archive') return 'Archive Account';
            if (__param === 'change_role') return 'User Account upgrade to Manager';
        }

        returnLocationConcact(__param: any) {
            if (__param.locations.length > 0) {
                let regionConcat = ''
                __param.locations.map((region: any) => (regionConcat += region.name + ', '));
                return regionConcat.slice(0, -2);
            } else {
                return __param.clinic;
            }
        }

        returnActiveLocation(__param: any) {
            if (__param.locations.length > 0) {
                let activeLocations: any = [];
                __param.locations.map((region: any) => region.status && activeLocations.push(region.name));
                return activeLocations;
            }
            return [];
        }

        Capitalize(str: any) {
            str = str.split(" ");
            for (let i = 0; i < str.length; i++) {
                str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
            }
            const CapitalLetter = str.join(" ");
            return CapitalLetter;
        }

        filterDataSearch(searchStr: any) {
            localStorage.removeItem('archive_list');
            let urlSearch = process.env.REACT_APP_SERVICE_BACKEND_URL + '/admin/get-users?filterType=' + (new URL(window.location.href)).searchParams.get('filters') + '&search=' + String(searchStr.str).trim().replace(' ', '') + '&order=' + searchStr.order;
            __setURL(urlSearch);
        }

        componentWillUnmount() {
            // cancel all subscriptions and events...
            this._isMounted = false;
        }

        findHours(receivedDate: any) {
            let DateReceived = moment(receivedDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
            let currentDate = moment().format('YYYY-MM-DD')
            let finaldate = moment(currentDate)
            let finalhours = moment.duration(finaldate.diff(DateReceived));
            let hours = finalhours.asHours();
            return (hours >= 48) ? true : false;
        }

        findApproveStatus(data: any, questions: any, receivedDate: any) {
            let status = false;
            let Approved = false;
            if (data.history.length > 0) {
                data.history.map((val1: any) => {
                    if (val1.action === 'approve') {
                        status = false; Approved = true;
                    }
                    if (val1.action === 'reject') {
                        status = false; Approved = true;
                    }
                })
            }
            if (data.age < 16 && !Approved) {
                status = true;
            }
            if (data.age === 16 || data.age === 17) {
                status = data.flags.length === 0 ? status : true;
            }
            let findHours = this.findHours(receivedDate);
            status = (findHours && status) ? true : false;
            return status;
        }

        findApproveDiff(data: any, myobj: any, status: any) {
            status = (status) ? status : false;
            let ans = _.find(data.question_response, { question_id: myobj.id });
            if (ans && ans.answer) {
                if (ans.answer.includes('none')) {
                    status = true;
                }
            }
            return status;
        }

        findApproved(data: any, myobj: any, statusa: any, type: any) {
            let status = false;
            let reject = false;
            if ((data.age === 16 || data.age === 17) && data.flags.length === 0) {
                status = true
            }
            if (data.history.length > 0) {
                data.history.map((val1: any) => {
                    if (val1.action === 'approve') {
                        status = true;
                    }
                    if (val1.action === 'reject') {
                        reject = true;
                    }
                })
            }
            if (type === 'reject') {
                return reject;
            }
            return status;
        }


        async componentDidMount(){
            await this.fetchData();
        }

        getCheckedUserList(ids:any,title:string){
            let obj:any = {
                title: title && title,
                list: ids && ids.map((res1:any)=>{
                    return{
                        id:res1,
                        checked:false
                    }
                })
            }

            let userList:any = localStorage.getItem('archive_list');
            let checkedlist:any = userList && JSON.parse(userList);
            if(!checkedlist){
                localStorage.setItem('archive_list',JSON.stringify(obj))
            }
            if(checkedlist?.title !== title){
                localStorage.setItem('archive_list',JSON.stringify(obj))
            }
        }


        async fetchData() {
            this._isMounted = true;
            if (this._isMounted) {
                try {
                    this.setState({ loading: true })
                    let response: any;
                    if (url.includes('filterType')) {
                        if (__search && __search.length) {
                            url = url + "&findBy=" + __search;
                        }
                        const href = new URL(url);
                        let selectedType = new URL(window.location.href).searchParams.get('filters');
                        if (selectedType) {
                            href.searchParams.set('filterType', selectedType);
                            href.searchParams.set('limit', '5' );
                            href.searchParams.set('offset', this.state.offset.toString() );
                            response = await apiFunctions.get(href.toString());
                            await this.getCheckedUserList(response.data.data.userIds, moreProps.tableHead.filter)
                        } else {
                            response = await apiFunctions.get(url);
                            await this.getCheckedUserList(response.data.data.userIds, moreProps.tableHead.filter)
                        }
                    } else {
                        response = await apiFunctions.get(url);
                        await this.getCheckedUserList(response.data.data.userIds, moreProps.tableHead.filter)
                    }
                    const myData: any[] = [];
                    const multi_order:any = [];
                    multi_order.push(...response.data.data.multipleOrderscount)
                    this.setState({totalCount:response.data.data.totalCount});
                
                    const _findques = _.find(response.data.data.findQuestions, { 'question_text': 'Sex Consent' })
                    if (moreProps.tableHead.type === 'orderKit') {
                        
                        this.getCheckedUserList(response.data.data.userIds, moreProps.tableHead.filter);
                        response.data.data.findExisting.map((val: any, indx: any) => {
                            const findhours = this.findHours(moment(val.createdAt).format('DD-MM-YYYY'))
                            const findApproveStatus = this.findApproveStatus(val, response.data.data.findQuestions, moment(val.createdAt).format('DD-MM-YYYY'));
                            // results common setup start
                            
                            const resultDetails = {
                                status: '-',
                                resultDate: '-',
                                updatedResult: val.result_updated
                            }
                            // if (val.referenceNo === 'MOL341197') {

                            // }
                            if (val.result.length > 0) {
                                let positive:any=[];
                                let negative:any=[];
                                let inconclusive:any=[];
                                let activeresults:any=[];
                                let sample_collection = ['SPCR','CPCR'];

                                val.result.map((res1:any)=>{
                                    if(res1.result_status.toUpperCase() ==='NEGATIVE' && res1.result_stage === 'active' && !sample_collection.includes(res1.result_type_short)){
                                        negative.push(res1);
                                    }
                                    if(res1.result_status.toUpperCase() ==='POSITIVE'&& res1.result_stage === 'active' && !sample_collection.includes(res1.result_type_short)){
                                        positive.push(res1);
                                    }
                                    if(res1.result_status.toUpperCase() !=='NEGATIVE' && res1.result_status.toUpperCase() !=='POSITIVE' && res1.result_stage === 'active' && !sample_collection.includes(res1.result_type_short)){
                                        inconclusive.push(res1);
                                    }
                                    if(res1.result_stage === 'active' && !sample_collection.includes(res1.result_type_short)){
                                        activeresults.push(res1);
                                    }
                                });
                                
                                resultDetails.status = activeresults.length === negative.length ? 'Negative' : positive.length > 0 ? 'Positive' :'Inconclusive';
                            
                                let date = (activeresults.length === negative.length ? negative[0]?.createdAt : positive.length > 0 ? positive[0]?.createdAt :inconclusive[0]?.createdAt);

                                resultDetails.resultDate = date ?  moment(date).format('DD-MM-YYYY'):'-'

                            }

                            let DateFulfilled = '-';
                            let autoApprove = false
                            if (val.age > 17 || ((val.age === 17 || val.age === 16) && val.flags.length === 0)) {
                                DateFulfilled = val.form_approved_date || val.updatedAt ? moment(val.form_approved_date ? val.form_approved_date : val.updatedAt).format('DD-MM-YYYY'):'-';
                                autoApprove = true
                            }
                            if (val.history.length > 0 && !autoApprove) {
                                const resultapprove = val.history.filter((res: any) => res.action === 'approve');
                                if (resultapprove.length > 0) {
                                    DateFulfilled = resultapprove[0].updatedAt ? moment(resultapprove[0].updatedAt).format('DD-MM-YYYY'):'-'
                                }
                            }
                            // local authority changes backup line
                            // value: (val && val.postcodes && val.postcodes.local_authority) ? val.postcodes.local_authority : val.local_authority

                            if (moreProps.tableHead.filter === 'Archive') {
                                if (val.archive_status !== null && val.archive_status) {
                                    let obj: any = {};
                                    obj['Requested recieved'] = { content: '', value: moment(val.createdAt).format('DD-MM-YYYY'), obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions, approveStatus: findApproveStatus };
                                    obj['Name'] = { content: 'name', value: (this.Capitalize(val.first_name) + ' ' + this.Capitalize(val.last_name)), obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, permissions: response.data.data.adminPermissions, row: true, rolePermissions: response.data.data.rolePermissions };
                                    obj['Reference no'] = { content: '', value: val.referenceNo, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Age'] = { content: '', value: val.age, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Local authority'] = { content: '', value: val.local_authority, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Phone'] = { content: '', value: val.phone_no, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Flags'] = { content: 'Flags', value: '-', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    // obj['Contact'] = { content: 'Contact', value: '-', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                    obj['Approve kit'] = { content: 'Kit Status', value: '-', status: 'approve/reject', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Date fulfilled'] = { content: '', value: DateFulfilled, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Sample recieved'] = { content: '', value: resultDetails.resultDate, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Result'] = { content: 'result', value: resultDetails.status, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['In Lilie'] = { content: 'In Lilie', value: '-', status: 'added', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, referenceNo: val.referenceNo, rolePermissions: response.data.data.rolePermissions };
                                    obj['Archive'] = { content: 'Archive', value: '-', obj: val, type: moreProps.tableHead.type, referenceNo: val.referenceNo, rolePermissions: response.data.data.rolePermissions, label: 'unarchive' };
                                    myData.push(obj);
                                }

                            }
                            else if (moreProps.tableHead.filter === 'Added to Lilie') {
                                if (val.isLilie && !val.archive_status) {
                                    let obj: any = {};
                                    obj['Requested recieved'] = { content: '', value: moment(val.createdAt).format('DD-MM-YYYY'), obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions, approveStatus: findApproveStatus };
                                    obj['Name'] = { content: 'name', value: (this.Capitalize(val.first_name) + ' ' + this.Capitalize(val.last_name)), obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, permissions: response.data.data.adminPermissions, row: true, rolePermissions: response.data.data.rolePermissions };
                                    obj['Reference no'] = { content: '', value: val.referenceNo, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Age'] = { content: '', value: val.age, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Local authority'] = { content: '', value: val.local_authority, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Phone'] = { content: '', value: val.phone_no, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Flags'] = { content: 'Flags', value: '-', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    // obj['Contact'] = { content: 'Contact', value: '-', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                    obj['Approve kit'] = { content: 'Kit Status', value: '-', status: 'approve/reject', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Date fulfilled'] = { content: '', value: DateFulfilled, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Sample recieved'] = { content: '', value: resultDetails.resultDate, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Result'] = { content: 'result', value: resultDetails.status, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['In Lilie'] = { content: 'In Lilie', value: '-', status: 'added', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, referenceNo: val.referenceNo, rolePermissions: response.data.data.rolePermissions };
                                    obj['Archive'] = { content: 'Archive', value: '-', obj: val, type: moreProps.tableHead.type, referenceNo: val.referenceNo, rolePermissions: response.data.data.rolePermissions, label: 'archive' };
                                    myData.push(obj);
                                }
                            } else if (moreProps.tableHead.filter === 'Flagged') {
                                let AppStatus = this.findApproved(val, '', false, 'approve')
                                let RejStatus = this.findApproved(val, '', false, 'reject')

                                if ((val.age < 16 || (val.flags.length > 0 && val.age === 16) || (val.flags.length > 0 && val.age === 17)) && val.archive_status !== 'true' && !AppStatus && !RejStatus) {
                                    let obj: any = {};
                                    obj['Requested recieved'] = { content: '', value: moment(val.createdAt).format('DD-MM-YYYY'), obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions, approveStatus: findApproveStatus };
                                    obj['Name'] = { content: 'name', value: (this.Capitalize(val.first_name) + ' ' + this.Capitalize(val.last_name)), obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, permissions: response.data.data.adminPermissions, row: true, rolePermissions: response.data.data.rolePermissions };
                                    obj['Reference no'] = { content: '', value: val.referenceNo, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Age'] = { content: '', value: val.age, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Local authority'] = { content: '', value: val.local_authority, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Phone'] = { content: '', value: val.phone_no, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Flags'] = { content: 'Flags', value: '-', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Contact'] = { content: 'Contact', value: '-', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Approve kit'] = { content: 'Kit Status', value: '-', status: 'approve/reject', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    // obj['Date fulfilled'] = { content: '', value: DateFulfilled, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    // obj['Sample recieved'] = { content: '', value: resultDetails.resultDate, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    // obj['Result'] = { content: 'result', value: resultDetails.status, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, referenceNo: val.referenceNo, rolePermissions: response.data.data.rolePermissions };
                                    // obj['In Lilie'] = { content: 'In Lilie', value: '-', status: 'added', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, rolePermissions: response.data.data.rolePermissions };
                                    // if (!val.archive_status && !val.isLilie && val.form_source !== 'tdl') {
                                        myData.push(obj);
                                    // }

                                }
                            } else if (moreProps.tableHead.filter === 'Approved') {
                                let myobjq = _.find(response.data.data.findQuestions, { question_text: "Sex Consent" });
                                let approved = this.findApproved(val, myobjq, false, 'approve')
                                // (val.history.length > 0 && val.history[0].action === 'approve') ||
                                if (val.age > 17 && !val.archive_status || approved) {
                                    let obj: any = {};
                                    obj['Requested recieved'] = { content: '', value: moment(val.createdAt).format('DD-MM-YYYY'), obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions, approveStatus: findApproveStatus };
                                    obj['Name'] = { content: 'name', value: (this.Capitalize(val.first_name) + ' ' + this.Capitalize(val.last_name)), obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, permissions: response.data.data.adminPermissions, row: true, rolePermissions: response.data.data.rolePermissions };
                                    obj['Reference no'] = { content: '', value: val.referenceNo, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Age'] = { content: '', value: val.age, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Local authority'] = { content: '', value: val.local_authority, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Phone'] = { content: '', value: val.phone_no, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Flags'] = { content: 'Flags', value: '-', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    // obj['Contact'] = { content: 'Contact', value: '-', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                    obj['Approve kit'] = { content: 'Kit Status', value: '-', status: 'approve/reject', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Date fulfilled'] = { content: '', value: moment(val.form_approved_date ? val.form_approved_date : val.updatedAt).format('DD-MM-YYYY'), obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    // obj['Sample recieved'] = { content: '', value: resultDetails.resultDate, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    // obj['Result'] = { content: 'result', value: resultDetails.status, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, referenceNo: val.referenceNo, rolePermissions: response.data.data.rolePermissions };
                                    obj['In Lilie'] = { content: 'In Lilie', value: '-', status: 'added', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, rolePermissions: response.data.data.rolePermissions };
                                    if (!val.archive_status && resultDetails.status.toLowerCase() === '-' && val.form_source !== 'tdl') {
                                        myData.push(obj);
                                    }
                                }
                                // }
                            } else if (moreProps.tableHead.filter === 'Rejected') {
                                let findRejected = this.findApproved(val, '', '', 'reject')
                                if (findRejected && !val.archive_status && val.form_source !== 'tdl') {
                                    let obj: any = {};
                                    obj['Requested recieved'] = { content: '', value: moment(val.createdAt).format('DD-MM-YYYY'), obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions, approveStatus: findApproveStatus };
                                    obj['Name'] = { content: 'name', value: (this.Capitalize(val.first_name) + ' ' + this.Capitalize(val.last_name)), obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, permissions: response.data.data.adminPermissions, row: true, rolePermissions: response.data.data.rolePermissions };
                                    obj['Reference no'] = { content: '', value: val.referenceNo, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Age'] = { content: '', value: val.age, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Local authority'] = { content: '', value: val.local_authority, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Phone'] = { content: '', value: val.phone_no, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Flags'] = { content: 'Flags', value: '-', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    // obj['Contact'] = { content: 'Contact', value: '-', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                    obj['Approve kit'] = { content: 'Kit Status', value: '-', status: 'approve/reject', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    // obj['Date fulfilled'] = { content: '', value:'', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    // obj['Sample recieved'] = { content: '', value: resultDetails.resultDate, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    // obj['Result'] = { content: 'result', value: resultDetails.status, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, referenceNo: val.referenceNo, rolePermissions: response.data.data.rolePermissions };
                                    obj['In Lilie'] = { content: 'In Lilie', value: '-', status: 'added', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                    obj['Archive'] = { content: 'Archive', value: '-', obj: val, type: moreProps.tableHead.type, referenceNo: val.referenceNo, rolePermissions: response.data.data.rolePermissions, label: 'archive' };
                                    myData.push(obj);
                                }
                            } else if (moreProps.tableHead.filter === 'Positive') {
                                // if(!val.archive_status){
                                let obj: any = {};
                                obj['Requested recieved'] = { content: '', value: moment(val.createdAt).format('DD-MM-YYYY'), obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                obj['Name'] = { content: 'name', value: (val.first_name + ' ' + val.last_name), obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, permissions: response.data.data.adminPermissions };
                                obj['Reference no'] = { content: '', value: val.referenceNo, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                obj['Age'] = { content: '', value: val.age, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                obj['Local authority'] = { content: '', value: val.local_authority, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                obj['Phone'] = { content: '', value: val.phone_no, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                obj['Flags'] = { content: 'Flags', value: '-', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                // obj['Contact'] = { content: 'Contact', value: '-', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                obj['Approve kit'] = { content: 'Kit Status', value: '-', status: val.form_source !== 'tdl' ? 'approve/reject' : '-', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                obj['Date fulfilled'] = { content: '', value: DateFulfilled, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                obj['Sample recieved'] = { content: '', value: resultDetails.resultDate, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                obj['Result'] = { content: '', value: resultDetails.status, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                obj['In Lilie'] = { content: 'In Lilie', value: '-', status: 'added', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                obj['Archive'] = { content: 'Archive', value: '-', obj: val, type: moreProps.tableHead.type, referenceNo: val.referenceNo, rolePermissions: response.data.data.rolePermissions, label: 'archive' };
                                if (resultDetails.status.toLowerCase() === 'positive' && !val.archive_status) {
                                    myData.push(obj);
                                }
                                //  }
                            }
                            else if (moreProps.tableHead.filter === 'Inconclusive') {
                                if (!val.archive_status) {
                                    let obj: any = {};
                                    obj['Requested recieved'] = { content: '', value: moment(val.createdAt).format('DD-MM-YYYY'), obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, approveStatus: false, updateStatus: resultDetails.updatedResult };
                                    obj['Name'] = { content: 'name', value: (val.first_name + ' ' + val.last_name), obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, permissions: response.data.data.adminPermissions };
                                    obj['Reference no'] = { content: '', value: val.referenceNo, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Age'] = { content: '', value: val.age, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                    obj['Local authority'] = { content: '', value: val.local_authority, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                    obj['Phone'] = { content: '', value: val.phone_no, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                    obj['Flags'] = { content: 'Flags', value: '-', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                    // obj['Contact'] = { content: 'Contact', value: '-', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                    obj['Approve kit'] = { content: 'Kit Status', value: '-', status: 'approve/reject', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                    obj['Date fulfilled'] = { content: '', value: DateFulfilled, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                    obj['Sample recieved'] = { content: '', value: resultDetails.resultDate, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                    obj['Result'] = { content: 'result', value: resultDetails.status, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                    obj['In Lilie'] = { content: 'In Lilie', value: '-', status: 'added', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                    obj['Archive'] = { content: 'Archive', value: '-', obj: val, type: moreProps.tableHead.type, referenceNo: val.referenceNo, rolePermissions: response.data.data.rolePermissions, label: 'archive' };
                                    if (resultDetails.status !== '-' && resultDetails.status !== '' && resultDetails.status.toLowerCase() !== 'positive' && resultDetails.status.toLowerCase() !== 'negative') {
                                        myData.push(obj);
                                    }
                                }
                            }
                            else if (moreProps.tableHead.filter === 'Negative') {
                                if (!val.archive_status) {
                                    let obj: any = {};
                                    obj['Requested recieved'] = { content: '', value: moment(val.createdAt).format('DD-MM-YYYY'), obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, updateStatus: resultDetails.updatedResult };
                                    obj['Name'] = { content: 'name', value: (val.first_name + ' ' + val.last_name), obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, permissions: response.data.data.adminPermissions };
                                    obj['Reference no'] = { content: '', value: val.referenceNo, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                    obj['Age'] = { content: '', value: val.age, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                    obj['Local authority'] = { content: '', value: val.local_authority, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                    obj['Phone'] = { content: '', value: val.phone_no, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                    obj['Flags'] = { content: 'Flags', value: '-', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                    // obj['Contact'] = { content: 'Contact', value: '-', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                    obj['Approve kit'] = { content: 'Kit Status', value: '-', status: 'approve/reject', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                    obj['Date fulfilled'] = { content: '', value: DateFulfilled, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                    obj['Sample recieved'] = { content: '', value: resultDetails.resultDate, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                    obj['Result'] = { content: '', value: resultDetails.status, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                    obj['In Lilie'] = { content: 'In Lilie', value: '-', status: 'added', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type };
                                    obj['Archive'] = { content: 'Archive', value: '-', obj: val, type: moreProps.tableHead.type, referenceNo: val.referenceNo, rolePermissions: response.data.data.rolePermissions, label: 'archive' };
                                    if (resultDetails.status.toLowerCase() === 'negative') {
                                        myData.push(obj);
                                    }
                                }
                            }
                            else if (new URL(window.location.href).searchParams.get('filters') === 'Search results') {
                                let obj: any = {};
                                obj['Requested recieved'] = { content: '', value: moment(val.createdAt).format('DD-MM-YYYY'), obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions, approveStatus: findApproveStatus };
                                obj['Name'] = { content: 'name', value: (this.Capitalize(val.first_name) + ' ' + this.Capitalize(val.last_name)), obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, permissions: response.data.data.adminPermissions, row: true, rolePermissions: response.data.data.rolePermissions };
                                obj['Reference no'] = { content: '', value: val.referenceNo, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                obj['Age'] = { content: '', value: val.age, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                obj['Local authority'] = { content: '', value: val.local_authority, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                obj['Phone'] = { content: '', value: val.phone_no, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                obj['Flags'] = { content: 'Flags', value: '-', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                obj['Contact'] = { content: 'Contact', value:  resultDetails.status, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type ,permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions};
                                obj['Approve kit'] = { content: 'Kit Status', value: '-', status: 'approve/reject', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                obj['Date fulfilled'] = { content: '', value: DateFulfilled, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                obj['Sample recieved'] = { content: '', value: resultDetails.resultDate, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                obj['Result'] = { content: 'result', value: resultDetails.status, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, referenceNo: val.referenceNo, rolePermissions: response.data.data.rolePermissions };
                                // obj['In Lilie'] = { content: 'In Lilie', value: '-', status: 'added', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, rolePermissions: response.data.data.rolePermissions };
                                obj['In Lilie'] = { content: 'In Lilie', value: '-', status: 'added', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, referenceNo: val.referenceNo, rolePermissions: response.data.data.rolePermissions };
                                obj['Archive'] = { content: 'Archive', value: '-', obj: val, type: moreProps.tableHead.type, referenceNo: val.referenceNo, rolePermissions: response.data.data.rolePermissions, label: val.archive_status === true ? 'unarchive' : 'archive' };

                                myData.push(obj);

                            } else {
                                let obj: any = {};
                                obj['Requested recieved'] = { content: '', value: moment(val.createdAt).format('DD-MM-YYYY'), obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions, approveStatus: findApproveStatus };
                                obj['Name'] = { content: 'name', value: (this.Capitalize(val.first_name) + ' ' + this.Capitalize(val.last_name)), obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, permissions: response.data.data.adminPermissions, row: true, rolePermissions: response.data.data.rolePermissions };
                                obj['Reference no'] = { content: '', value: val.referenceNo, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                obj['Age'] = { content: '', value: val.age, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                obj['Local authority'] = { content: '', value: val.local_authority, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                obj['Phone'] = { content: '', value: val.phone_no, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                obj['Flags'] = { content: 'Flags', value: '-', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                obj['Approve kit'] = { content: 'Kit Status', value: '-', status: 'approve/reject', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                obj['Date fulfilled'] = { content: '', value: DateFulfilled, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                obj['Sample recieved'] = { content: '', value: resultDetails.resultDate, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                                obj['Result'] = { content: 'result', value: resultDetails.status, obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, row: true, permissions: response.data.data.adminPermissions, referenceNo: val.referenceNo, rolePermissions: response.data.data.rolePermissions };
                                obj['In Lilie'] = { content: 'In Lilie', value: '-', status: 'added', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, rolePermissions: response.data.data.rolePermissions };

                                myData.push(obj);
                            }
                        })
                       

                    } else if (moreProps.tableHead.type === 'brook-roles') {
                        let roleobj: any = {};
                        response.data.data.admin.map((e: any) => {
                            let __i = e.roleAtBrook.replace(/[&\/\\#+()$~%.'":*?<>{}]/g, '');
                            __i.split(',').map((val: any) => {
                                if (!roleobj[val]) roleobj[val] = 0;
                                roleobj[val] += 1;
                            })
                        })
                        response.data.data.BrookRoles.map((val: any, indx: any) => {
                            let obj: any = {};
                            obj['Name'] = { content: '', value: this.Capitalize(val.name), obj: val, list: response.data.data.admin };
                            obj['Date Request'] = { content: '', value: roleobj[val.name], status: '', obj: val, list: response.data.data.admin };
                            obj['Remove'] = { content: 'delete_role', value: '-', status: 'remove', obj: val.id, questions: '', type: moreProps.tableHead.type, label: 'Remove' };
                            myData.push(obj);
                        })
                    }
                    else if (moreProps.tableHead.type === 'admins') {
                        let loc = localStorage.getItem('location');
                        response.data.data.findExisting.map((val: any, indx: any) => {
                            let obj: any = {};
                            obj['Name'] = { content: 'name', value: this.Capitalize(val.name), category: 'admins', obj: val, headCategory: 'admin', Permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                            obj['View client data'] = { content: 'toggle', value: '', status: val['permissioninfo.admin_view_client_data'] ? 'added' : 'not added', obj: val, type: 'admin_view_client_data', category: 'admins', Permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                            obj['Manage flags'] = { content: 'toggle', value: '', status: val['permissioninfo.admin_manage_flags'] ? 'added' : 'not added', obj: val, type: 'admin_manage_flags', category: 'admins', Permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                            obj['Contact clients'] = { content: 'toggle', value: '', status: val['permissioninfo.admin_contact_clients'] ? 'added' : 'not added', obj: val, type: 'admin_contact_clients', category: 'admins', Permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };
                            // Added Archive as new requirement as discussion
                            obj['Manage forms'] = { content: 'toggle', value: '', status: val['permissioninfo.admin_user_form_archive'] ? 'added' : 'not added', obj: val, type: 'admin_user_form_archive', category: 'admins', Permissions: response.data.data.adminPermissions, rolePermissions: response.data.data.rolePermissions };

                            if (loc === 'All') {
                                myData.push(obj);
                            } else if (this.returnActiveLocation(val).includes(loc)) {
                                myData.push(obj);
                            }
                        })
                    } else if (moreProps.tableHead.type === 'users') {
                        let loc = localStorage.getItem('location');
                        response.data.data.findExisting.map((val: any, indx: any) => {
                            let obj: any = {};
                            obj['Name'] = { content: 'name', value: this.Capitalize(val.name), category: 'admins', obj: val };
                            obj['View client data'] = { content: 'In Lilie', value: '', status: val['permissioninfo.u_view_client_data'] ? 'added' : 'not added', obj: val, type: 'u_view_client_data', category: 'admins' };
                            obj['Manage flags'] = { content: '', value: '-', status: val['permissioninfo.u_manage_flags'] ? 'added' : 'not added', obj: val, type: 'u_manage_flags', category: 'admins' };
                            obj['Contact clients'] = { content: 'In Lilie', value: '', status: val['permissioninfo.u_contact_clients'] ? 'added' : 'not added', obj: val, type: 'u_contact_clients', category: 'admins' };
                            if (loc === 'All') {
                                myData.push(obj);
                            } else if (this.returnActiveLocation(val).includes(loc)) {
                                myData.push(obj);
                            }
                        })
                    } else if (moreProps.tableHead.type === 'superadmins') {
                        let loc = localStorage.getItem('location');
                        response.data.data.findExisting.map((val: any, indx: any) => {
                            let obj: any = {};
                            obj['Name'] = { content: 'name', value: this.Capitalize(val.name), category: 'admins', obj: val };
                            obj['Edit Admin Permissions'] = { content: 'toggle', value: '', status: val['permissioninfo.s_edit_admin_permissions'] ? 'added' : 'not added', obj: val, type: 's_edit_admin_permissions', category: 'admins' };
                            obj['Create New Admins'] = { content: 'toggle', value: '', status: val['permissioninfo.s_create_new_admins'] ? 'added' : 'not added', obj: val, type: 's_create_new_admins', category: 'admins' };
                            obj['Remove admins'] = { content: 'toggle', value: '', status: val['permissioninfo.s_remove_admins'] ? 'added' : 'not added', obj: val, type: 's_remove_admins', category: 'admins' };
                            if (loc === 'All') {
                                myData.push(obj);
                            } else if (this.returnActiveLocation(val).includes(loc)) {
                                myData.push(obj);
                            }
                        })
                    } else if (moreProps.tableHead.type === 'admin-history') {
                        response.data.data.map((val: any, indx: any) => {
                            let obj: any = {};
                            obj['Action'] = { content: '', value: (val.action === 'approve') ? 'Approve test kit' : (val.action === 'reject') ? 'Reject a test kit' : (val.action === 'lilie') ? 'Ticked add to Lilie' : '-' };
                            obj['Client name'] = { content: '', value: this.Capitalize(val.userprofile.first_name) + ' ' + this.Capitalize(val.userprofile.last_name) };
                            obj['Age'] = { content: '', value: val.userprofile.age };
                            obj['Flags'] = { content: 'Flags', value: '-', obj: val.userprofile, questions: val.userprofile, type: moreProps.tableHead.type };
                            obj['Reason'] = { content: '', value: (val.action === 'reject') ? this.FindResons(val.reason) : (val.reason === null) ? '-' : val.reason };
                            obj['Date'] = { content: '', value: moment(val.createdAt, 'YYYY-MM-DD').format('DD/MM/YYYY') };
                            obj['Time'] = { content: '', value: moment(val.createdAt).format('HH:MM:ss') };
                            obj['In Lilie'] = { content: 'In Lilie', value: '-', status: 'added', obj: val, questions: response.data.data.findQuestions, type: moreProps.tableHead.type, referenceNo: val.referenceNo, rolePermissions: response.data.data.rolePermissions };
                            myData.push(obj)
                        })
                    } else {
                        response.data.data.map((val: any, indx: any) => {
                            let obj: any = {};
                            switch (moreProps.tableHead.type) {
                                case 'defaultPermissions':
                                    obj['Role'] = { content: '', value: this.Capitalize(val.roleName) };
                                    obj['View client data'] = { content: 'toggle', value: val.viewClientData, status: val.viewClientData ? 'added' : 'not-added', obj: val, type: 'viewClientData', category: 'defaultPermissions' };
                                    obj['Manage flags'] = { content: 'toggle', value: val.manageFlags, status: val.manageFlags ? 'added' : 'not-added', obj: val, type: 'manageFlags', category: 'defaultPermissions' };
                                    obj['Contact clients'] = { content: 'toggle', value: val.contactClients, status: val.contactClients ? 'added' : 'not-added', obj: val, type: 'contactClients', category: 'defaultPermissions' };
                                    // obj['Override approval'] = { content: 'toggle', value: val.overrideApproval, status: val.overrideApproval ? 'added' : 'not-added', obj: val, type: 'overrideApproval', category: 'defaultPermissions' };
                                    break;
                                case 'manage-admins':
                                    obj['Name'] = { content: '', value: this.Capitalize(val.user_info.name), obj: val };
                                    obj['Email'] = { content: '', value: val.user_info.email, obj: val, email_type: true };
                                    obj['Request'] = { content: '', value: this.returnAdminStatus(val.action) + '  -  ' + this.returnLocationConcact(val.user_info), status: '', obj: val };
                                    obj['Notes'] = { content: '', value: (this.returnAdminStatus(val.action) === 'User Account upgrade to Manager') ? val.reason : '-', status: '', obj: val };
                                    obj['Date Request'] = { content: '', value: moment(val.createdAt, 'YYYY-MM-DD').format('DD-MM-YYYY'), status: '', obj: val };
                                    obj['Approval'] = { content: 'Kit Status', value: '', status: 'approve/reject', obj: val };
                                    break;
                            }
                            myData.push(obj);
                        })
                    }

                    if (this._isMounted) this.setState({ data: myData,multiorderCount:multi_order, loading: false,totalArchive:response.data.data.totalArchive,  setLength: response.data.data.totalCount ,archiveUSer : response.data.data.userIds})
                } catch (error) {
                    console.error(error);
                    if (this._isMounted) this.setState({ error, loading: false })
                } finally {
                    if (this._isMounted) this.setState({ loading: false })
                }
            }
        }

        render() {
            return <>{
                this.state.loading && <Loader />
            }
            {/* <C getTableHead={moreProps.tableHead.titles} getTableContent={this.state.data} type={moreProps.tableHead.type} filterData={this.filterDataSearch} orderUrl={url} setLength={this.state.totalCount} totalCount={this.state.totalCount} offset={this.state.offset} setOffset={(offset:number)=> this.setState({offset},()=>{this.fetchData()}) } /> */}
            <C getTableHead={moreProps.tableHead.titles} getTableContent={this.state.data} multiorderCountList = {this.state.multiorderCount} ArchiveUserList={this.state.archiveUSer} type={moreProps.tableHead.type} filterData={this.filterDataSearch} orderUrl={url} setLength={this.state.totalCount} totalArchive={this.state.totalArchive} totalCount={this.state.totalCount} offset={this.state.offset} setOffset={(offset:number)=> this.setState({offset},()=>{this.fetchData()}) } />
            </>
        }
    }

export default FetchData
