// companion for appCommonReducer

import React, { createContext, useContext, useReducer } from 'react'

import {
  trackerReducer,
  initialState,
  TrackerAction,
  TrackerState,
} from '../reducers/TrackerReducer'

type TrackerContextProps = {
  state: TrackerState
  trackerDispatch: React.Dispatch<TrackerAction>
}

const TrackerContext = createContext<TrackerContextProps>({
  state: initialState,
  trackerDispatch: () => initialState,
})

export function TrackerContextProvider(props: React.PropsWithChildren<{}>) {
  const [state, trackerDispatch] = useReducer(trackerReducer, initialState)

  return (
    <TrackerContext.Provider value={{ state, trackerDispatch }} {...props} />
  )
}

export default function useTrackerContext() {
  return useContext(TrackerContext)
}
