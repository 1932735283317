import React, { useCallback, useContext, useState } from 'react'

import moment from 'moment'
import LoadingOverlay from 'react-loading-overlay'
import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import '../styles/symptombarrier.css'
import xIcon from '../assets/images/X_popup.svg'
import { Accordion } from '../components/accordian/Accordian'
import { Button } from '../components/button/Button'
import ErrorPopup from '../components/ModalPopup/ErrorPopup'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import apiFunctions from '../services/api-service'

interface ConsentBarrierProps {
  children: any
}

export const ConsentBarrier: React.FC<ConsentBarrierProps> = (Props: any) => {
  const history = useHistory()

  const [showInfo, setShowInfo] = useState(false)
  const [isEmail, setIsEmail] = useState(false)

  const [showErrorMsg, setshowErrorMsg] = useState('')
  const [errormsg, seterrormsg] = useState('')
  const { theme, themeDispatch } = useContext(ThemeContext)
  const [overlay, setOverlay] = useState<boolean>(false)

  const proceed = useCallback(async () => {
    TextToSpeech.getInstance().playVoice(null, 'stop')
    if (theme.quesObj.isemailContact) {
      setIsEmail(theme.quesObj.isemailContact)
    }
    setOverlay(true)
    const Final_consent = theme.quesObj['Sex Consent']
      ? theme.quesObj['Sex Consent'].filter((item: any) => item !== 'none')
      : []
    let userData = {
      first_name: theme.first_name,
      last_name: theme.last_name,
      date_of_birth: theme.date_of_birth === '' ? null : moment(theme.date_of_birth).format('YYYY-MM-DD'),
      email: theme.email,
      age: theme.age,
      phone_no: theme.phone_no,
      alternate_phone_no: theme.phone_no,
      address_line_1: theme.address_line_1,
      address_line_2: theme.address_line_2,
      address_line_3: theme.address_line_3,
      town: theme.town,
      postcode: theme.postcode,
      email_notification: isEmail,
      isEmailConfirm: theme.isEmailConfirm,
      isemailContact: theme.quesObj.isemailContact,
      isPhoneContact: theme.quesObj.isPhoneContact,
      questions: Final_consent,
      sti_type: theme.sti_type ? theme.sti_type : 'chlamydia',
    }

    theme.quesObj['Sex Consent'] = theme.quesObj['Sex Consent'] ? JSON.stringify(theme.quesObj['Sex Consent']) : ''
    let myObj: any = {}
    await apiFunctions
      .get(process.env.REACT_APP_SERVICE_BACKEND_URL + '/question/list')
      .then(async (result) => {
        result.data.data.map((e: { question_text: string | number; id: string }) => {
          if (e.question_text && e.id) {
            myObj[String(e.question_text)] = e.id
          }
        })
        await apiFunctions
          .post(process.env.REACT_APP_SERVICE_BACKEND_URL + '/user/add', userData)
          .then(async (_result) => {
            let myarr: any = []
            for (let i in theme.quesObj) {
              if (myObj[i] && theme.quesObj[i] && theme.quesObj[i] !== '') {
                let _obj = {
                  question_id: myObj[i],
                  user_id: _result.data.data.id,
                  answer: theme.quesObj[i],
                }
                myarr.push(_obj)
              }
            }

            let __data = {
              response: myarr,
            }

            if (myarr.length < 1) {
              Props.history.push('/contact-brook')
              // window.location.reload();
            } else {
              await apiFunctions
                .post(process.env.REACT_APP_SERVICE_BACKEND_URL + '/response/user-answer', __data)
                .then((_result) => {
                  Props.history.push('/contact-brook')
                })
                .catch((err) => {
                  setOverlay(false)
                  setshowErrorMsg(err.response.data.message)
                })
            }
          })
          .catch((err) => {
            setshowErrorMsg(err.response.data.message)
            setOverlay(false)
          })
      })
      .catch((err) => {
        setOverlay(false)
        setshowErrorMsg(err.response.data.message)
      })
  }, [isEmail])

  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }
  function CloseModel() {
    setshowErrorMsg('')
  }
  const tabIndex = 0
  return (
    <LoadingOverlay active={overlay} spinner text="">
      {showInfo && (
        <div
          className="my_info_popup info-popup fo-title"
          onClick={(e) => {
            setShowInfo(false)
          }}
        >
          <img src={xIcon} className="x-icon" alt="back" />
          <div className="p4-style-title">
            <div className="p5-style fo-style">{errormsg}</div>
          </div>
        </div>
      )}

      <div className="bg-color under16-style">
        {/* {showErrorMsg !== '' && (
          <div
            className="info-popup internal-error-popup"
            onClick={(e) => {
              CloseModel()
            }}
          >
            <img src={xIcon} className="x-icon" alt="back" />
            <div className="p4-style-title">
              <div className={`p5-style fo-style ${theme.theme}-txt-global`}>{showErrorMsg}</div>
            </div>
          </div>
        )} */}
        <ErrorPopup
          showErrorMsg={showErrorMsg}
          setshowErrorMsg={setshowErrorMsg}
          headerClass={`p5-style fo-style ${theme.theme}-txt-global`}
        />
        <div className="text-to-speech brook-container pad-bot">
          <div className="pad-te">
            <div className="h-div mt-4">
              <h2 className="page-header-txt" tabIndex={tabIndex}>
                ORDER A TEST KIT
              </h2>
              <div className="speaker-i">
                <button
                  className="no-background-border"
                  onClick={(e) => TextToSpeech.getInstance().playVoice('text-to-speech')}
                >
                  <img src={Icon} alt="speaker" />
                </button>
              </div>
            </div>
            <div className={`b1-style  ${theme.theme} mb-4`}>
              <p className="pt-0" tabIndex={tabIndex}>
                {' '}
                Because of what you've told us, before we can send you a test kit we need to have a quick chat with you to see if we can offer any additional help and support at this time.{' '}
              </p>
              <p tabIndex={tabIndex}>
                When you hit the Submit button below, the contact details you have provided will be sent to your local Brook service and someone will be in touch as soon as possible. They will let you know if we can approve your home test kit request.
              </p>
              <p tabIndex={tabIndex}>
                They will try contacting you by phone first. They may then try sending you a text or email if they are unable to reach you by phone.
              </p>
              <div className="consent-accordian">
                <Accordion
                  themeName="postcodeBarrier"
                  labelBgClass="personel_info"
                  panels={[
                    {
                      label: `I'D RATHER CONTACT BROOK`,
                      content: child(),
                    },
                  ]}
                />
              </div>
            </div>
            <div>
              <div className="ft-tab brook-container p-color">
                <button className="float-left no-background-border" onClick={(e) => GoBackPage()}>
                  <img src={backIcon} alt="back" />
                </button>

                <Button
                  id="u_18_consent_barrier"
                  isDisabled={false}
                  buttonClassName="btn btn-default btn-main"
                  buttonText="Submit"
                  onClick={(e) => proceed()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  )
}

const child = () => {
  const tabIndex = 0
  return (
    <>
      <div className="p3-style p2-style" tabIndex={tabIndex}>
        <p tabIndex={tabIndex}> Alternatively, you can contact your local Brook service. </p>
        <p className="pt-0" tabIndex={tabIndex}>
          Brook Manchester: call 0161 237 3001 or visit <br />
          <a href="https://www.brook.org.uk/service/brook-manchester" className="text-a-tag " target="_blank">
            www.brook.org.uk/service/brook-manchester
          </a>
          <span className="ml-2">for more information.</span>
        </p>
        <p className="pt-0" tabIndex={tabIndex}>
          Brook Dudley: call 07810 701134 or visit <br />
          <a href="https://www.brook.org.uk/regions/brook-dudley" className="text-a-tag" target="_blank">
            www.brook.org.uk/regions/brook-dudley{' '}
          </a>
          <span className="ml-2">for more information.</span>
        </p>
        <p tabIndex={tabIndex}>
          <i>
            Not sure which one is your local service? Search at
            <a href="https://www.brook.org.uk/find-a-service" className="text-a-tag ml-1" target="_blank">
              www.brook.org.uk/find-a-service
            </a>{' '}
          </i>
        </p>
        <p tabIndex={tabIndex}>
          You can find full details of your rights regarding the information you share with us and when accessing sexual
          health services on the Brook website:
          <a href="https://www.brook.org.uk/privacy-and-confidentiality" className="text-a-tag ml-1" target="_blank">
            www.brook.org.uk/privacy-and-confidentiality
          </a>
        </p>{' '}
      </div>
    </>
  )
}
