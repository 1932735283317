import React, { useState, useEffect } from 'react'

import { SecNav } from '../../../components/admin/SecNav'
import { TriNav } from '../../../components/admin/TriNav'
import Loader from '../../../components/Loader/loader';
import useAuthContext from '../../../context/provider/AuthContext';
import apiFunctions from '../../../services/api-service'
import { AdminLayout } from '../AdminLayout'
import '../../../styles/common.css'
import '../../../styles/AdminStyle/adminSettings.css'
import { authPriNavMenu, authManageSettingsSecNav, authManageAccountSettingTriNav } from '../AdminOptions'

interface ArchiveSettingsProps { }

export const ArchiveSettings: React.FC<ArchiveSettingsProps> = ({ }) => {
  const [formshow, setFormshow] = useState(false)
  const [successResponse, setsuccessResponse] = useState({ status: false, message: '' })
  const [UserData, setUserData] = useState({ archive_request: '' })
  const [showLoader, setShowLoader] = useState(false)

  const {
    state: { user, globalSettings },
    authDispatch,
  } = useAuthContext()
  const dataval: any = user;

  let permessionAccessArchive = dataval?.user ? dataval.user:dataval;

  permessionAccessArchive = permessionAccessArchive && permessionAccessArchive.permissions?.team_members?.filter((ele:any)=>{return ele.name === 'archive_team_member'})


  useEffect(() => {
    setShowLoader(true)
    apiFunctions
      .get(process.env.REACT_APP_SERVICE_BACKEND_URL + '/auth/get-user-data')
      .then((_result) => {
        let userDetail = _result.data.data

        if (userDetail) {
          setShowLoader(false)
          setUserData({ archive_request: userDetail.user.archiveRequest })
          if (userDetail.user.archiveRequest === 'rejected' || userDetail.user.archiveRequest === 'start' || userDetail.user.archiveRequest === null) {
            setFormshow(true)
          }

        }
      })
      .catch((err) => {
        setShowLoader(false)
      })
  }, [])

  function ArchiveAccount() {
    let payload = { archiveRequest: 'pending' }
    apiFunctions
      .put(process.env.REACT_APP_SERVICE_BACKEND_URL + '/auth/archive-request', payload)
      .then((_result) => {
        setsuccessResponse({ status: true, message: 'Archive request has been sent successfully.' })
        setFormshow(false)
      })
      .catch((err) => { })
  }

  const [role] = useState(localStorage.getItem('role'))
  const tabIndex=0;
  return (
    <AdminLayout getMenuList={authPriNavMenu} roleMode="Super admin mode" sideBar="Settings">
      <div className="admin-login-style">
        {role !== '3' && (
          <>
            <SecNav getMenuList={authManageSettingsSecNav} />
            <h1 className="page-heading" tabIndex={tabIndex}>Change settings</h1>
            <TriNav getMenuList={authManageAccountSettingTriNav} />
          </>
        )}
        {role === '3' && (
          <>
            <div className="col-12 col-sm-10 col-md-6 mt-4">
              <div className="login-style1"tabIndex={tabIndex}>Your account</div>
            </div>
            <hr />
            <div className="col-12 col-sm-10 col-md-6">
              <p className="login-style2"tabIndex={tabIndex}>Change settings</p>
            </div>
            <div className="mt-5 ml-5">
              <TriNav getMenuList={authManageAccountSettingTriNav} />
            </div>
          </>
        )}
        {successResponse.status ? (
          <div className="col-12 col-sm-10 col-md-6">
            <div className="archive-text mt-5">{successResponse.message}</div>
          </div>
        ) : (
          ''
        )}

        <div className="text-account" tabIndex={tabIndex}>
          {formshow ? (
            <div className="col-12 col-sm-10 col-md-6">
              <div className="archive-text mt-3" tabIndex={tabIndex}>
                Requesting archival sends a notification to your Super Admin to archive your account.
              </div>
              <div className="archive-text mt-5" tabIndex={tabIndex}>
                Your account will no longer be accessible to you. You should only do this if you are leaving Brook.
              </div>
              <div className="archive-text mt-5" tabIndex={tabIndex}> This is entirely reversible.</div>
              <div className="login-btn-wrap footer-btn">
                <button
                  type="button"
                  disabled={!(permessionAccessArchive && permessionAccessArchive[0] && permessionAccessArchive[0].value)}
                  className="btn btn-primary float-right login-btn"
                  onClick={(e) => ArchiveAccount()}
                >
                  Archive account
                </button>
              </div>
            </div>
          ) : (
            !successResponse.status && !formshow && 'Archive request already sent !'
          )}
        </div>
      </div>
      {showLoader && <Loader />}
    </AdminLayout>
  )
}

export default ArchiveSettings
