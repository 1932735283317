import React from "react";

import { ReactComponent as VerticalThreeDots } from '../../assets/images/vertical-three-dots.svg';


interface TextInputProps {
  style?: string;
  text?: string;
  id?: string;
  placeholder?: string;
  disabled?: boolean;
  label?: string;
  type: string;
  name?: string;
  addClass?: string;
  input_Class?: string;
  values?: string | number | Array<string>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onIconClick?: (e: any) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  errors?: string | Array<string> | any;
  helpIcon?: boolean;
  helpContent?: string;
  fileName?: string;
  onClick?: any;
  icon?: "search" | "password" | "delete" | "file" | "copy" | null;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const TextInput: React.FC<TextInputProps> = ({
  placeholder,
  id,
  disabled,
  type = 'text',
  onChange,
  onClick,
  addClass = '',
  input_Class,
  name,
  errors,
  onBlur,
  values,
  onKeyDown
}) => {
  const tabIndex = 0;
  return (
    <div className='dropdown-cascade'>
      <div className={`input-group ${addClass}`}>
        <div className="relative">
          <label
            htmlFor='vr-dots'
            className='customInput'>
            <VerticalThreeDots />
            <input
              tabIndex={tabIndex}
              id='vr-dots'
              type={type}
              className={`text-field w-100  ${input_Class || ""} ${errors ? "input-error" : ""
                }`}
              placeholder={placeholder}
              onChange={onChange}
              onKeyDown={onKeyDown}
              onClick={onClick}
              disabled={disabled}
              name={name}
              onBlur={onBlur}
              value={values}
              autoComplete="off"
              aria-labelledby={id}
            />
          </label>
        </div>
        {errors && <div className="form-error-msg">{errors}</div>}
      </div>
    </div>
  );
};