import React from 'react'

import BrookLogoWhite from '../../assets/images/Brook_Logo_White.svg'

interface SignPostingFooterProps {}

export const SignPostingFooter: React.FC<SignPostingFooterProps> = ({}) => {
  const tabIndex = 0
  const noTab = -1
  return (
    <>
      <section className="signposting-section color-white bg-purple">
        <div className="content-container footer">
          <div className="footer-blocks">
            <div className="footer-blk">
              <a target="_blank" href="https://www.brook.org.uk" className="inline-block">
                <img src={BrookLogoWhite} alt="Brook" className="footer-logo" />
              </a>
              <div className="spacer-30" />
              <h2 className="sub-header footer">HEALTHY LIVES FOR YOUNG PEOPLE</h2>
              <div className="spacer-20" />
              <p className="copyright">© {new Date().getFullYear()} Brook Young People</p>
            </div>
            <div className="footer-blk">
              <h2 className="sub-header heading">TOPICS</h2>
              <div className="spacer-20" />
              <div className="footer-links">
                <ul>
                  <li>
                    <a target="_blank" href="https://www.brook.org.uk/topics/contraception/">
                      Contraception
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.brook.org.uk/topics/pregnancy/">
                      Pregnancy
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.brook.org.uk/topics/stis/">
                      STIs
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.brook.org.uk/topics/relationships/">
                      Relationships
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.brook.org.uk/topics/staying-safe-online/">
                      Staying Safe Online
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.brook.org.uk/topics/gender/">
                      Gender
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.brook.org.uk/topics/health-and-wellbeing/">
                      Health & wellbeing
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.brook.org.uk/topics/abuse-and-violence/">
                      Abuse & violence
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.brook.org.uk/topics/vaginas-and-vulvas/">
                      Vaginas & Vulvas
                    </a>
                  </li>
                </ul>
                <div className="spacer-40" />
                <ul>
                  <li className="footer-link-type2">
                    <a target="_blank" href="https://www.brook.org.uk/help-advice/">
                      More topics
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-blk">
              <h2 className="sub-header heading">OTHER LINKS</h2>
              <div className="spacer-20" />
              <div className="footer-links">
                <ul>
                  <li>
                    <a target="_blank" href="https://www.brook.org.uk/about-brook/">
                      About Brook
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.brook.org.uk/get-involved/donate/">
                      Donate
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.brook.org.uk/shw/">
                      Sexual Health Week
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://learn.brook.org.uk/">
                      Brook Learn
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.brook.org.uk/contact-us/">
                      Contact us
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.brook.org.uk/press-and-media/">
                      Press and media
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://legacy.brook.org.uk/jobs">
                      Jobs
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.brook.org.uk/brook-blog/">
                      Brook blog
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://legacy.brook.org.uk/our-services/privacy-and-confidentiality">
                      Privacy & confidentiality
                    </a>
                  </li>
                </ul>
                <div className="spacer-40" />
                <ul>
                  <li className="footer-link-type2">
                    <a target="_blank" href="https://www.brook.org.uk/about-brook/">
                      About Brook
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-blk">
              <h2 className="sub-header heading">QUICK LINKS</h2>
              <div className="spacer-20" />
              <div className="footer-links">
                <ul>
                  <li>
                    <a target="_blank" href="https://www.brook.org.uk/help-advice/">
                      Help & Advice
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.brook.org.uk/find-a-service/">
                      Find a Service
                    </a>
                  </li>
                  {/* <li>
                                        <a target='_blank' href="#">Search</a>
                                    </li> */}
                  <li>
                    <a target="_blank" href="https://www.brook.org.uk/contact-us/" className="m-0">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-blk">
              <h2 className="sub-header heading">FOLLOW US</h2>
              <div className="spacer-20" />
              <div className="footer-social-links">
                <a target="_blank" href="https://www.facebook.com/BrookCharityYP/">
                  <span className="sr-only"> facebook</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="11.651" height="21.755" viewBox="0 0 11.651 21.755">
                    <g id="Group_4249" data-name="Group 4249">
                      <path
                        id="Path_15105"
                        data-name="Path 15105"
                        d="M10.887,12.236l.6-3.937H7.714V5.744A1.968,1.968,0,0,1,9.933,3.617H11.65V.266A20.969,20.969,0,0,0,8.6,0C5.491,0,3.458,1.885,3.458,5.3v3H0v3.937H3.458v9.517l2.128,0,2.128,0V12.236Z"
                        transform="translate(0 0)"
                        fill="#652f91"
                      />
                    </g>
                  </svg>
                </a>
                <a target="_blank" href="https://www.instagram.com/brook_sexpositive/">
                  <span className="sr-only"> instagram</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="23.039" height="23.039" viewBox="0 0 23.039 23.039">
                    <g id="Group_4251" data-name="Group 4251">
                      <path
                        id="Path_15106"
                        data-name="Path 15106"
                        d="M21.382,5.065a1.351,1.351,0,1,0,1.351,1.351,1.351,1.351,0,0,0-1.351-1.351"
                        transform="translate(-3.763 -0.952)"
                        fill="#652f91"
                      />
                      <path
                        id="Path_15107"
                        data-name="Path 15107"
                        d="M12.988,7.2a5.674,5.674,0,1,0,5.674,5.674A5.68,5.68,0,0,0,12.988,7.2m0,9.308a3.634,3.634,0,1,1,3.634-3.634,3.638,3.638,0,0,1-3.634,3.634"
                        transform="translate(-1.374 -1.352)"
                        fill="#652f91"
                      />
                      <path
                        id="Path_15108"
                        data-name="Path 15108"
                        d="M16.119,23.039H6.92A6.928,6.928,0,0,1,0,16.119V6.92A6.928,6.928,0,0,1,6.92,0h9.2a6.928,6.928,0,0,1,6.92,6.92v9.2a6.928,6.928,0,0,1-6.92,6.92M6.92,2.168A4.758,4.758,0,0,0,2.168,6.92v9.2A4.758,4.758,0,0,0,6.92,20.871h9.2a4.758,4.758,0,0,0,4.753-4.753V6.92a4.758,4.758,0,0,0-4.753-4.753Z"
                        transform="translate(0 0)"
                        fill="#652f91"
                      />
                    </g>
                  </svg>
                </a>
                <a target="_blank" href="https://twitter.com/BrookCharity">
                  <span className="sr-only"> twitter</span>
                  <svg
                    id="Group_4254"
                    data-name="Group 4254"
                    xmlns="http://www.w3.org/2000/svg"
                    width="23.049"
                    height="18.971"
                    viewBox="0 0 23.049 18.971"
                  >
                    <g id="Group_4253" data-name="Group 4253" transform="translate(0 0)">
                      <path
                        id="Path_15109"
                        data-name="Path 15109"
                        d="M22.954,2.274a9.515,9.515,0,0,1-2.331.665c.376-.063.926-.742,1.147-1.016A4.287,4.287,0,0,0,22.542.511c.02-.04.035-.09-.005-.121a.134.134,0,0,0-.123.01A11.893,11.893,0,0,1,19.65,1.458a.184.184,0,0,1-.19-.051,2.232,2.232,0,0,0-.242-.246A4.895,4.895,0,0,0,17.879.34,4.659,4.659,0,0,0,15.826.01a4.9,4.9,0,0,0-1.948.55,5.008,5.008,0,0,0-1.571,1.283,4.833,4.833,0,0,0-.939,1.88,5.094,5.094,0,0,0-.05,1.985c.015.111.005.126-.1.111A14.757,14.757,0,0,1,1.727.984c-.111-.126-.171-.126-.262.01a4.817,4.817,0,0,0,.821,5.69c.186.176.377.353.583.514a4.825,4.825,0,0,1-1.833-.514c-.111-.07-.166-.03-.176.1a2.917,2.917,0,0,0,.031.544,4.869,4.869,0,0,0,3,3.882,2.836,2.836,0,0,0,.609.186,5.437,5.437,0,0,1-1.8.056c-.131-.025-.181.04-.131.166a5.055,5.055,0,0,0,3.776,3.162c.171.03.343.03.514.07-.01.016-.021.016-.031.031-.424.646-1.894,1.128-2.578,1.367a9.247,9.247,0,0,1-3.917.5c-.211-.031-.255-.029-.312,0s-.008.086.06.141c.266.176.539.333.816.484a12.913,12.913,0,0,0,2.618,1.047,13.947,13.947,0,0,0,13.538-3.165C19.831,12.494,20.8,8.693,20.8,4.887c0-.149.176-.231.28-.31a9.155,9.155,0,0,0,1.843-1.916.582.582,0,0,0,.121-.367v-.02c0-.061,0-.043-.094,0"
                        transform="translate(0 0)"
                        fill="#652f91"
                      />
                    </g>
                  </svg>
                </a>
                <a target="_blank" href="https://www.youtube.com/user/BrookCharity">
                  <span className="sr-only"> youtube</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="17" viewBox="0 0 23 17">
                    <g id="Group_4259" data-name="Group 4259" transform="translate(-251 -2080)">
                      <rect
                        id="Rectangle_3566"
                        data-name="Rectangle 3566"
                        width="23"
                        height="17"
                        rx="4"
                        transform="translate(251 2080)"
                        fill="#652f91"
                      />
                      <path
                        id="Polygon_39"
                        data-name="Polygon 39"
                        d="M3.5,0,7,6.206H0Z"
                        transform="translate(266.206 2084.5) rotate(90)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        {!window.location.href.includes('switch-desktop') && (
          <section className="exitBtn">
            <a className="text-decoration-home" href="https://www.youtube.com/" rel="noreferrer" tabIndex={noTab}>
              <div className="exit-footer">Exit</div>
            </a>
          </section>
        )}
      </section>
    </>
  )
}
