import React from 'react'

const WhitePrev = () => {
  return (
    <div className="custom-next-arrow">
      <svg xmlns="http://www.w3.org/2000/svg" width="15.778" height="27.778" viewBox="0 0 15.778 27.778">
        <g id="Group_4251" data-name="Group 4251" transform="translate(-17.844 -1061.888)">
          <line
            id="Line_1247"
            data-name="Line 1247"
            x1="11.535"
            y1="11.535"
            transform="translate(19.965 1076.01)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-width="3"
          />
          <line
            id="Line_1248"
            data-name="Line 1248"
            x1="11.535"
            y2="11.535"
            transform="translate(19.965 1064.01)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-width="3"
          />
        </g>
      </svg>
    </div>
  )
}

export default WhitePrev
