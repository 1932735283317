import React, { useState } from 'react';
 
import { useFormik } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha'
import * as Yup from 'yup';

import Loader from '../../../components/Loader/loader'
import "../../../styles/common.css";
import { IUserInfo } from '../../../models/Types'
import apiFunctions from '../../../services/api-service'
import appUrls from '../../../services/AppURLs';
import { AdminLayout } from '../AdminLayout';
// import Loader from '../../components/Loader/loader';

interface ForgotPasswordProps {
    userDetails?: IUserInfo;
}
interface RecaptchaRef{
    current: any
  }

const validationSchema = Yup.object({
    email: Yup.string()
        .email('Please enter a valid email address')
        .required('Email is required'),
});
export const ForgotPassword: React.FC<ForgotPasswordProps> = ({ }) => {

    const recaptchaRef :RecaptchaRef = React.useRef(null)
    const [intialForm, setintialForm] = useState(true)
    const [error, setError] = useState("")
    const [showLoader, setshowloader] = useState(false)

    const [SuccessResponse, setSuccessResponse] = useState(false)
    const formik = useFormik({
        initialValues: { email: '' },
        validationSchema,
        
        async onSubmit(values: any) {
            const captchaToken = await recaptchaRef.current.execute()
            setError("");
            setshowloader(true)
            try {
                apiFunctions.put(process.env.REACT_APP_SERVICE_BACKEND_URL + '/auth/forgot-password-request', {
                    email: values.email,
                    recaptchaToken:captchaToken
                }).then(_result => {
                    setintialForm(false)
                    setSuccessResponse(true)
                    setshowloader(false)
                }).catch(err => {
                    console.log(err.response,'ststst');
                    console.log(err.response.status === 403,'condition');
                    console.log(err.response.data);
                    
                    if(err.response.status === 403){
                        setError(err.response.data.message ? err.response.data.message :err.response.data)
                        const timer = setTimeout(() => {
                            setError('')
                        }, 7000);
                    }
                    else{
                        setintialForm(false)
                        setSuccessResponse(true)
                    }
                    setshowloader(false)

                    
                })
            } catch (error: any) {
                setshowloader(false)
                console.log(error)
                // setError("This is Invalid account.")
            }
        },
    });
    const tabIndex=0;                           
    return (
        <AdminLayout roleMode="Super admin mode" hideMenus>
            {showLoader && <Loader />}
            <div className="admin-login-style">
                <div className='col-12 col-sm-10 col-md-6 mt-4'>
                    <div className='login-style1' tabIndex={tabIndex}>Log in using your Brook email address</div>
                </div>
                <hr />
                <div className='col-12 col-sm-10 col-md-6 mt-4'>
                    <p className='login-style2'tabIndex={tabIndex}>Forgot password</p>
                </div>
                {SuccessResponse ? (
                    <div>
                        <p className='login-style2 mt-3 ml-3'>If an account exists for this email address, a reset link will be sent to {formik.values.email}</p>
                        <p className='mt-4 ml-4'>Please click on the link in the email to reset your password.</p>
                    </div>) : ''}

                {error &&  (
                    <span className="errorMessage">{error}</span>
                )} 
                {intialForm ? (
                    <div>
                        <form className='col-12 col-sm-10 col-md-5 mt-5' onSubmit={formik.handleSubmit}>
                            <div className="form-group parent-pos">
                                <label className='in-style'htmlFor='id="email" ' tabIndex={tabIndex} >Email</label>
                                <input type="email" id="email" className="form-control  login-radius"  aria-label="email"
                                    onChange={formik.handleChange} value={formik.values.email}
                                />
                                <span className="errorMessage">
                                    {formik.touched.email && formik.errors.email
                                        ? formik.errors.email
                                        : null}
                                </span>
                            </div>
                            <div className="mt-4 text-right pass-text-style" />
                            <div className="login-btn-wrap d-flex justify-content-end">
                            <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_SECRET_KEY || ''}size="invisible"/>
                                <button type="button" className="btn btn-primary float-left mr-3" onClick={(e) => {
                                    window.location.href = `${appUrls.admin.auth.login}?refresh=true`
                                }}>Back</button>
                                <button type="submit" className="btn btn-primary login-btn">Submit</button>

                            </div>
                        </form>
                    </div>) : ''}

            </div>
            {/* <Loader hide={false}></Loader> */}
        </AdminLayout>

    )
}

export default ForgotPassword