import React, { useState, useEffect } from 'react'

import '../../../styles/common.css'

import { useFormik } from 'formik'
import moment from 'moment'
import * as Yup from 'yup'

import eyeIcon from '../../../assets/images/Eye_no_strike.svg'
import hidePassowrd from '../../../assets/images/Eye_strike.svg'
import Popup from '../../../components/admin/popup'
import { SecNav } from '../../../components/admin/SecNav'
import { TriNav } from '../../../components/admin/TriNav'
import apiFunctions from '../../../services/api-service'
import { logout } from '../../../services/api/AuthAPI'
import appUrls from '../../../services/AppURLs';
import { AdminLayout } from '../AdminLayout'
import { authPriNavMenu, authManageSettingsSecNav, authManageAccountSettingTriNav } from '../AdminOptions'

interface PasswordSettingsProps { }

const validationSchema = Yup.object({
  old_password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9#?!@$%^&*-]).{8,}$/,
      'Password must have 8 characters with 1 uppercase, 1 lowercase and a number/symbol'
    )
    .required('Old password is required'),
  create_password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9#?!@$%^&*-]).{8,}$/,
      'Password must have 8 characters with 1 uppercase, 1 lowercase and a number/symbol'
    )
    .required('Create a password is required'),
  new_password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9#?!@$%^&*-]).{8,}$/,
      'Password must have 8 characters with 1 uppercase, 1 lowercase and a number/symbol'
    )
    .required('New password is required'),
})
const errorIntialState = {
  status: false,
  message: '',
}
export const PasswordSettings: React.FC<PasswordSettingsProps> = ({ }) => {
  const [initialshow, setInitialshow] = useState(true)
  const [error, setError] = useState(errorIntialState)
  const [errormsg, setErrorMsg] = useState("")
  const [formshow, setFormshow] = useState(false)
  const [successResponse, setsuccessResponse] = useState(false)
  const [passwordResposne, setpasswordResposne] = useState(false)

  const [passwordShown, setPasswordShown] = useState(false)
  const [passwordShownagain, setPasswordShownagain] = useState(false)
  const [passwordold, setpasswordold] = useState(false)
  const [comparePassword, setcomparePassword] = useState(false)
  const [showPopup, setshowPopup] = useState(false)
  const [role] = useState(localStorage.getItem('role'))
  const showcallback = (result: any) => {
    setshowPopup(false)
    setsuccessResponse(true)
    setFormshow(false)
    setInitialshow(false)
  }

  const togglePassword = (type: any) => {
    if (type === 'old') {
      setpasswordold(!passwordold)
      return
    }
    if (type === 'again') {
      setPasswordShownagain(!passwordShownagain)
      return
    }
    setPasswordShown(!passwordShown)
  }
  const [UserData, setUserData] = useState<any>({
    user_id: '',
    name: '',
    email: '',
    password_last_update: null,
  })

  const closeModal = () => {
    setshowPopup(false)
  }
  const intialValue = {
    old_password: '',
    create_password: '',
    new_password: '',
  }
  useEffect(() => {
    apiFunctions
      .get(process.env.REACT_APP_SERVICE_BACKEND_URL + '/auth/get-user-data')
      .then((_result) => {
        let password_last_update =
          _result.data.data.user && _result.data.data.user.password_last_update
            ? moment(_result.data.data.user.password_last_update).format('D MMMM YYYY')
            : null
        setUserData({
          user_id: _result.data.data.user.id,
          name: _result.data.data.user.name,
          email: _result.data.data.user.email,
          password_last_update,
        })
        if (!password_last_update) {
          setFormshow(true)
          setInitialshow(false)
        }
      })
      .catch((err) => {
        console.log('err.response = ', err)
      })
  }, [])

  const formik = useFormik({
    initialValues: intialValue,
    validationSchema,
    async onSubmit(values: any) {
      try {
        let payload = {
          user_id: UserData && UserData.user_id ? UserData.user_id : '',
          // create_password: values.create_password,
          password: values.new_password,
          old_password: values.old_password,
        }
        setcomparePassword(false)
        if (values.create_password !== values.new_password) {
          setcomparePassword(true)
          return
        }
        apiFunctions
          .put(process.env.REACT_APP_SERVICE_BACKEND_URL + '/auth/update-password', payload)
          .then((_result) => {
            setErrorMsg('')
            setError({ status: false, message: '' })
            setUserData({
              user_id: _result.data.data.id,
              name: _result.data.data.name,
              email: _result.data.data.email,
              password_last_update:
                _result.data.data.updatestatus && _result.data.data.updatestatus.password_last_update
                  ? moment(_result.data.data.updatestatus.password_last_update).format('D MMMM YYYY')
                  : '',
            })
            setFormshow(false)
            setInitialshow(true)
            setpasswordResposne(true)
            logout();
            window.location.href = `${appUrls.admin.auth.login}?refresh=true`;

          })
          .catch((err) => {
            if (err.response.data.code === 403) {
              setError({ status: false, message: '' })
              setErrorMsg(err.response.data.message);
              const timer = setTimeout(() => {
                setErrorMsg('')
              }, 6000);
            } else {
              setErrorMsg('');
              setError({ status: true, message: err && err.response && err.response.data && err.response.data.message })
              const timer = setTimeout(() => {
                setError({ status: false, message: '' })
              }, 10000);
            }

          })
      } catch (error: any) {
        console.log(error.response, 'errorresposne')
      }
    },
  })
  const tabIndex = 0;
  return (
    <AdminLayout getMenuList={authPriNavMenu} roleMode="" sideBar="Settings">
      <div className="admin-login-style">
        {role !== '3' && (
          <>
            <SecNav getMenuList={authManageSettingsSecNav} />
            <h1 className="page-heading">Change settings</h1>
            <TriNav getMenuList={authManageAccountSettingTriNav} />
          </>
        )}
        {role === '3' && (
          <>
            <div className="col-12 col-sm-10 col-md-6 mt-4">
              <div className="login-style1" tabIndex={tabIndex}>Your account</div>
            </div>
            <hr />
            <div className="col-12 col-sm-10 col-md-6">
              <p className="login-style2" tabIndex={tabIndex}>Change settings</p>
            </div>
            <div className="mt-5 ml-5">
              <TriNav getMenuList={authManageAccountSettingTriNav} />
            </div>
          </>
        )}
        {initialshow ? (
          <div className="col-12 col-sm-10 col-md-6 mt-4">
            {passwordResposne ? <div className="text-account" tabIndex={tabIndex}>Success! Your password was changed.</div> : ''}
            <div className="text-account" tabIndex={tabIndex}>Password last changed</div>
            <div className="mt-4 input-act" tabIndex={tabIndex}>
              {UserData && UserData.password_last_update ? UserData.password_last_update : ''}
            </div>
            <div className="login-btn-wrap">
              <button
                type="button"
                className="btn btn-primary float-right login-btn"
                onClick={() => {
                  setFormshow(true)
                  setInitialshow(false)
                }}
              >
                Change password
              </button>
            </div>
          </div>
        ) : (
          ''
        )}
        {formshow ? (
          <div className="text-account">
            <form className="col-12 col-sm-10 col-md-6" onSubmit={formik.handleSubmit}>
              <div className="form-group parent-pos">
                <label className="in-style" tabIndex={tabIndex}>Enter old password</label>
                <input
                  aria-label='old_password'
                  type={passwordold ? 'text' : 'password'}
                  className="form-control  login-radius"
                  id="old_password"
                  name="old_password"
                  onChange={formik.handleChange}
                />
                <div className="i-icon">
                  <img
                    tabIndex={tabIndex}
                    src={passwordold ? eyeIcon : hidePassowrd}
                    className="icon-style"
                    alt="eyeIcon"
                    onClick={(e) => {
                      togglePassword('old')
                    }}
                  />
                </div>

                <span className="errorMessage">
                  {formik.touched.old_password && formik.errors.old_password
                    ? formik.errors.old_password
                    : error && error.message
                      ? error.message
                      : null}
                </span>
                <div
                  tabIndex={tabIndex}
                  className="mt-4 text-right login-forget onkeyup"
                  onClick={() => {
                    setshowPopup(true)
                  }}
                >
                  Forgotten password?
                </div>
              </div>
              <div className="form-group mt-4 parent-pos">
                <label className="in-style" tabIndex={tabIndex}>Create new password</label>
                <input
                  aria-label='create_password'
                  type={passwordShown ? 'text' : 'password'}
                  className="form-control  login-radius"
                  id="create_password"
                  name="create_password"
                  onChange={formik.handleChange}
                />
                {/* <span className="errorMessage">
                  {formik.touched.create_password && formik.errors.create_password
                    ? formik.errors.create_password
                    : errormsg && errormsg
                      ? errormsg
                      : null}
                </span> */}

                <div className="i-icon">
                  <img
                    tabIndex={tabIndex}
                    src={passwordShown ? eyeIcon : hidePassowrd}
                    className="icon-style"
                    alt="eyeIcon"
                    onClick={(e) => {
                      togglePassword('new')
                    }}
                  />
                </div>
                <span className="errorMessage">
                  {formik.touched.create_password && formik.errors.create_password
                    ? formik.errors.create_password
                    : comparePassword
                      ? 'Create and repeat passwords must be same'
                      : errormsg && errormsg
                        ? errormsg
                        : ''}
                </span>
              </div>
              <ul className='list-pass'>
                <p className="mt-4">Your password must:</p>
                <li className=" mt-2 mb-1 text-left pass-text-style" tabIndex={tabIndex}>
                  Be at least 8 characters long
                </li>
                <li className="mb-1 text-left pass-text-style" tabIndex={tabIndex}>
                  Contain at least 1 uppercase letter, 1 lowercase letter, a number and a symbol (e.g. !?£$%*&)
                </li>
                <li className="mb-1 text-left pass-text-style" tabIndex={tabIndex}>
                  Be unique to Staff Hub
                </li>
                <li className="mb-1 text-left pass-text-style" tabIndex={tabIndex}>
                  Not be your username or name
                </li>
                <li className="mb-1 text-left pass-text-style" tabIndex={tabIndex}>
                  Not be a generic or easy to guess word (like 'password' or 'staff hub')
                </li>
                <li className=" text-left pass-text-style" tabIndex={tabIndex}>
                  If you are resetting your password, it cannot be the same as any of your last 5 passwords
                </li>
              </ul>
              <div className="form-group parent-pos mt-4">
                <label className="in-style" tabIndex={tabIndex}>Repeat new password</label>
                <input
                  type={passwordShownagain ? 'text' : 'password'}
                  className="form-control  login-radius"
                  aria-label='new_password'
                  id="new_password"
                  name="new_password"
                  onChange={formik.handleChange}
                />
                <div className="i-icon">
                  <img
                    tabIndex={tabIndex}
                    src={passwordShownagain ? eyeIcon : hidePassowrd}
                    className="icon-style"
                    alt="eyeIcon"
                    onClick={(e) => {
                      togglePassword('again')
                    }}
                  />
                </div>
                <span className="errorMessage">
                  {formik.touched.new_password && formik.errors.new_password ? formik.errors.new_password : null}
                </span>
              </div>

              <div className="d-flex justify-content-end login-btn-wrap">
                <div className="">
                  <button type="submit" className="btn btn-primary float-right login-btn">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        ) : (
          ''
        )}

        {successResponse ? (
          <div>
            <p className="login-style2 mt-3 ml-3">A reset link was emailed to {UserData.email}.</p>
            <p className="mt-4">Please go to your email account and click on the link to reset your password.</p>
          </div>
        ) : (
          ''
        )}
      </div>
      <Popup ForgotPassword={showPopup} callback={showcallback} userdata={UserData} close={closeModal} />
    </AdminLayout>
  )
}

export default PasswordSettings
