import React, { useCallback, useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import '../styles/ethnicity.css'
import { Accordion } from '../components/accordian/Accordian'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'

interface ethnicityProps {
  Props: any
}

export const Ethnicity: React.FC<ethnicityProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)

  const history = useHistory()

  const [ethnicity, setEthnicity] = useState('')

  const [ethnicity_sub, setEthnicitySub] = useState('')

  useEffect(() => {
    if (theme.quesObj['Ethnicity']) {
      let obj = JSON.parse(theme.quesObj['Ethnicity'])

      setEthnicity(obj.ethnicity)

      setEthnicitySub(obj.ethnicity_sub)
    }

    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: '77' },
    })
  }, [])
  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('ethnicity_id')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  const proceed = useCallback(() => {
    TextToSpeech.getInstance().playVoice(null, 'stop')
    setisAccessibilityVoiceOver(false)
    let ethnicityVal = {
      'ethnicity': ethnicity,
      'ethnicity_sub': ethnicity_sub,
    }

    themeDispatch({
      type: 'SET_QUES',
      payload: { quesObj: { ...theme.quesObj, Ethnicity: JSON.stringify(ethnicityVal) } },
    })

    Props.history.push('/selectgender')
  }, [ethnicity, ethnicity_sub])

  const chooseOption = useCallback(
    (__type: string) => {
      return (e: any) => {
        setEthnicity(e.target.checked ? __type : '')
        setEthnicitySub('')
      }
    },
    [ethnicity, ethnicity_sub]
  )

  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }
  return (
    <div className={`bg-color ethnicity-style ${theme.theme}-skin-color`}>
      <div className="text-to-speech brook-container pad-bot">
        <div className="pad-te pad-bo">
          <div className="h-div mt-4">
            <h2 className={`page-header-txt ${theme.theme}-txt-global`}>ORDER A TEST KIT</h2>
            <div className="speaker-i">
              <button
                className="no-background-border"
                onClick={(e) => {
                  TextToSpeech.getInstance().playVoice('text-to-speech')
                  setisAccessibilityVoiceOver(true)
                }}
              >
                <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
              </button>
            </div>
          </div>

          <div className={`b1-style ${theme.theme} ${theme.theme}-txt-global`}>What best describes your ethnicity?</div>
        </div>
        <div className="">
          <Accordion
            themeName={theme.theme}
            labelBgClass="personel_info"
            panels={[
              {
                id: 'ethnicity_id',
                label: 'WHY ARE YOU ASKING ME THIS?',
                content: child(theme.theme),
              },
            ]}
          />
        </div>
        <div className="pad-te">
          <div className="single-row">
            <div>
              <input
                type="checkbox"
                id="vehicle1"
                className="form-check-input check-style"
                aria-label="Asian or Asian British"
                checked={ethnicity === 'Asian or Asian British'}
                onChange={chooseOption('Asian or Asian British')}
              />
            </div>
            <div className={`style-subdrop ml-4 mt-2 ${theme.theme}-txt-global`}>Asian or Asian British</div>
          </div>
          {ethnicity === 'Asian or Asian British' && (
            <div className="Asian-or-Asian-British">
              <div className="single-row ml-5 mt-3">
                <div>
                  <input
                    type="checkbox"
                    id="vehicle1"
                    aria-label="indian"
                    checked={ethnicity_sub === 'Indian'}
                    onChange={(e) => setEthnicitySub('Indian')}
                    className="form-check-input check-style inp-mar"
                  />
                </div>
                <div className={`style-subdrop ml-4 mt-2 ${theme.theme}-txt-global`}>Indian</div>
              </div>
              <div className="single-row ml-5 mt-3">
                <div>
                  <input
                    type="checkbox"
                    id="vehicle1"
                    aria-label="Pakistani"
                    checked={ethnicity_sub === 'Pakistani'}
                    onChange={(e) => setEthnicitySub('Pakistani')}
                    className="form-check-input check-style inp-mar"
                  />
                </div>
                <div className={`style-subdrop ml-4 mt-2 ${theme.theme}-txt-global`}>Pakistani</div>
              </div>
              <div className="single-row ml-5 mt-3">
                <div>
                  <input
                    type="checkbox"
                    id="vehicle1"
                    aria-label="Bangladeshi"
                    checked={ethnicity_sub === 'Bangladeshi'}
                    onChange={(e) => setEthnicitySub('Bangladeshi')}
                    className="form-check-input check-style inp-mar"
                  />
                </div>
                <div className={`style-subdrop ml-4 mt-2 ${theme.theme}-txt-global`}>Bangladeshi</div>
              </div>
              <div className="single-row ml-5 mt-3">
                <div>
                  <input
                    type="checkbox"
                    id="vehicle1"
                    aria-label="Chinese"
                    checked={ethnicity_sub === 'Chinese'}
                    onChange={(e) => setEthnicitySub('Chinese')}
                    className="form-check-input check-style inp-mar"
                  />
                </div>
                <div className={`style-subdrop ml-4 mt-2 ${theme.theme}-txt-global`}>Chinese</div>
              </div>
              <div className="single-row ml-5 mt-3">
                <div>
                  <input
                    type="checkbox"
                    id="vehicle1"
                    aria-label="Any other Asian background"
                    checked={ethnicity_sub === 'Any other Asian background'}
                    onChange={(e) => setEthnicitySub('Any other Asian background')}
                    className="form-check-input check-style inp-mar"
                  />
                </div>
                <div className={`style-subdrop ml-4 mt-2 ${theme.theme}-txt-global`}>Any other Asian background</div>
              </div>
            </div>
          )}
          <div className="single-row mt-4">
            <div>
              <input
                type="checkbox"
                id="vehicle1"
                className="form-check-input check-style"
                aria-label="Black, African, Caribbean or Black British"
                checked={ethnicity === 'Black, African, Caribbean or Black British'}
                onChange={chooseOption('Black, African, Caribbean or Black British')}
              />
            </div>
            <div className={`style-subdrop ml-4 mt-2 ${theme.theme}-txt-global`}>
              Black, African, Caribbean or Black British
            </div>
          </div>
          {ethnicity === 'Black, African, Caribbean or Black British' && (
            <div className="Black-African-Caribbean-or-Black-British">
              <div className="single-row ml-5 mt-3">
                <div>
                  <input
                    type="checkbox"
                    id="vehicle1"
                    aria-label="African"
                    checked={ethnicity_sub === 'African'}
                    onChange={(e) => setEthnicitySub('African')}
                    className={`form-check-input check-style inp-mar ${theme.theme}-txt-global`}
                  />
                </div>
                <div className={`style-subdrop ml-4 mt-2 ${theme.theme}-txt-global`}>African</div>
              </div>
              <div className="single-row ml-5 mt-3">
                <div>
                  <input
                    type="checkbox"
                    id="vehicle1"
                    aria-label="Caribbean"
                    checked={ethnicity_sub === 'Caribbean'}
                    onChange={(e) => setEthnicitySub('Caribbean')}
                    className={`form-check-input check-style inp-mar ${theme.theme}-txt-global`}
                  />
                </div>
                <div className={`style-subdrop ml-4 mt-2 ${theme.theme}-txt-global`}>Caribbean</div>
              </div>
              <div className="single-row ml-5 mt-3">
                <div>
                  <input
                    type="checkbox"
                    id="vehicle1"
                    aria-label="Any other black, African or caribbean background"
                    checked={ethnicity_sub === 'Any other black, African or caribbean background'}
                    onChange={(e) => setEthnicitySub('Any other black, African or caribbean background')}
                    className="form-check-input check-style inp-mar"
                  />
                </div>
                <div className={`style-subdrop ml-4 mt-2 ${theme.theme}-txt-global`}>
                  Any other black, African or caribbean background.
                </div>
              </div>
            </div>
          )}
          <div className="single-row mt-4">
            <div>
              <input
                className="form-check-input check-style "
                type="checkbox"
                id="vehicle1"
                aria-label="Mixed or Multiple ethnic groups"
                checked={ethnicity === 'Mixed or Multiple ethnic groups'}
                onChange={chooseOption('Mixed or Multiple ethnic groups')}
              />
            </div>
            <div className={`style-subdrop ml-4 mt-2 ${theme.theme}-txt-global`}>Mixed or Multiple ethnic groups</div>
          </div>
          {ethnicity === 'Mixed or Multiple ethnic groups' && (
            <div className="Black-African-Caribbean-or-Black-British">
              <div className="single-row ml-5 mt-3">
                <div>
                  <input
                    type="checkbox"
                    id="vehicle1"
                    aria-label="White and Black Caribbean"
                    checked={ethnicity_sub === 'White and Black Caribbean'}
                    onChange={(e) => setEthnicitySub('White and Black Caribbean')}
                    className="form-check-input check-style inp-mar"
                  />
                </div>
                <div className={`style-subdrop ml-4 mt-2 ${theme.theme}-txt-global`}>White and Black Caribbean</div>
              </div>{' '}
              <div className="single-row ml-5 mt-3">
                <div>
                  <input
                    type="checkbox"
                    id="vehicle1"
                    aria-label="White and Black African"
                    checked={ethnicity_sub === 'White and Black African'}
                    onChange={(e) => setEthnicitySub('White and Black African')}
                    className="form-check-input check-style inp-mar"
                  />
                </div>
                <div className={`style-subdrop ml-4 mt-2 ${theme.theme}-txt-global`}>White and Black African</div>
              </div>{' '}
              <div className="single-row ml-5 mt-3">
                <div>
                  <input
                    type="checkbox"
                    id="vehicle1"
                    aria-label="White and Asian"
                    checked={ethnicity_sub === 'White and Asian'}
                    onChange={(e) => setEthnicitySub('White and Asian')}
                    className="form-check-input check-style inp-mar"
                  />
                </div>
                <div className={`style-subdrop ml-4 mt-2 ${theme.theme}-txt-global`}>White and Asian</div>
              </div>{' '}
              <div className="single-row ml-5 mt-3">
                <div>
                  <input
                    type="checkbox"
                    id="vehicle1"
                    aria-label="Any other Mixed or Multiple background"
                    checked={ethnicity_sub === 'Any other Mixed or Multiple background'}
                    onChange={(e) => setEthnicitySub('Any other Mixed or Multiple background')}
                    className="form-check-input check-style inp-mar"
                  />
                </div>
                <div className={`style-subdrop ml-4 mt-2 ${theme.theme}-txt-global`}>
                  Any other Mixed or Multiple background
                </div>
              </div>{' '}
            </div>
          )}
          <div className="single-row mt-4">
            <div>
              <input
                className="form-check-input check-style"
                type="checkbox"
                id="vehicle1"
                aria-label="Other ethnic group"
                checked={ethnicity === 'Other ethnic group'}
                onChange={chooseOption('Other ethnic group')}
              />
            </div>
            <div className={`style-subdrop ml-4 mt-2 ${theme.theme}-txt-global`}>Other ethnic group</div>
          </div>
          {ethnicity === 'Other ethnic group' && (
            <div className="MIxed-or-Multiple-background">
              <div className="single-row ml-5 mt-3">
                <div>
                  <input
                    type="checkbox"
                    id="vehicle1"
                    aria-label="Arab"
                    checked={ethnicity_sub === 'Arab'}
                    onChange={(e) => setEthnicitySub('Arab')}
                    className="form-check-input check-style inp-mar"
                  />
                </div>
                <div className={`style-subdrop ml-4 mt-2 ${theme.theme}-txt-global`}>Arab</div>
              </div>{' '}
              <div className="single-row ml-5 mt-3">
                <div>
                  <input
                    type="checkbox"
                    id="vehicle1"
                    aria-label="Any other ethnic group"
                    checked={ethnicity_sub === 'Any other ethnic group'}
                    onChange={(e) => setEthnicitySub('Any other ethnic group')}
                    className="form-check-input check-style inp-mar"
                  />
                </div>
                <div className={`style-subdrop ml-4 mt-2 ${theme.theme}-txt-global`}>Any other ethnic group</div>
              </div>{' '}
            </div>
          )}
          <div className="single-row mt-4 mb-3">
            <div>
              <input
                className="form-check-input check-style"
                type="checkbox"
                id="vehicle1"
                aria-label="White"
                checked={ethnicity === 'White'}
                onChange={chooseOption('White')}
              />
            </div>
            <div className={`style-subdrop ml-4 mt-2 ${theme.theme}-txt-global`}>White</div>
          </div>
          {ethnicity === 'White' && (
            <div className="White mb-3">
              <div className="single-row ml-5 mt-3">
                <div>
                  <input
                    type="checkbox"
                    id="vehicle1"
                    aria-label="English, Welsh, Scottish,Northern Irish or British"
                    checked={ethnicity_sub === 'English, Welsh, Scottish,Northern Irish or British'}
                    onChange={(e) => setEthnicitySub('English, Welsh, Scottish,Northern Irish or British')}
                    className="form-check-input check-style inp-mar"
                  />
                </div>
                <div className={`style-subdrop ml-4 mt-2 ${theme.theme}-txt-global`}>
                  English, Welsh, Scottish,Northern Irish or British
                </div>
              </div>{' '}
              <div className="single-row ml-5 mt-3">
                <div>
                  <input
                    type="checkbox"
                    id="vehicle1"
                    aria-label="Irish"
                    checked={ethnicity_sub === 'Irish'}
                    onChange={(e) => setEthnicitySub('Irish')}
                    className="form-check-input check-style inp-mar"
                  />
                </div>
                <div className={`style-subdrop ml-4 mt-2 ${theme.theme}-txt-global`}>Irish</div>
              </div>{' '}
              <div className={`single-row ml-5 mt-3 ${theme.theme}-txt-global`}>
                <div>
                  <input
                    type="checkbox"
                    id="vehicle1"
                    aria-label="Gypsy or Irish Traveller"
                    checked={ethnicity_sub === 'Gypsy or Irish Traveller'}
                    onChange={(e) => setEthnicitySub('Gypsy or Irish Traveller')}
                    className="form-check-input check-style inp-mar "
                  />
                </div>
                <div className={`style-subdrop ml-4 mt-2 ${theme.theme}-txt-global`}>Gypsy or Irish Traveller</div>
              </div>{' '}
              <div className="single-row ml-5 mt-3">
                <div>
                  <input
                    type="checkbox"
                    id="vehicle1"
                    aria-label="Any other White background"
                    checked={ethnicity_sub === 'Any other White background'}
                    onChange={(e) => setEthnicitySub('Any other White background')}
                    className="form-check-input check-style inp-mar"
                  />
                </div>
                <div className={`style-subdrop ml-4 mt-2 ${theme.theme}-txt-global`}>Any other White background</div>
              </div>{' '}
            </div>
          )}
          <div>
            <div className={`ft-tab brook-container ${theme.theme}-skin-color`}>
              <button className="float-left no-background-border" onClick={(e) => GoBackPage()}>
                <img src={backIcon} alt="back" />
              </button>
              <Button
                isDisabled={ethnicity === '' || ethnicity_sub === ''}
                buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                buttonText="Next"
                onClick={proceed}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const child = (__param: any) => {
  const tabIndex = 0
  return (
    <>
      <div className={`p3-style p2-style ${__param}-txt-global`}>
        The information you provide in response to this question helps us understand how our services are being used so
        we can make them more accessible for everyone and identify where we need to make improvements. We may also use
        this information to create a client record if you go on to receive further support from Brook (for example, if
        we provide you with treatment), and for reporting purposes. All the information you gives us is confidential.
        <br />
        <br />
        Brook follows the national mandatory standard for the collection and analysis of ethnicity, using the 16+1
        ethnic data categories defined in the 2001 census.
      </div>
    </>
  )
}
