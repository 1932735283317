import React from "react";

class Rating extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: props.rating || null,
      tempRating: null
    };
  }

  starOver = ratingFromHover => {
    const { rating } = this.state;
    this.setState(
      {
        tempRating: rating
      },
      () => {
        this.setState({
          rating: ratingFromHover,
          tempRating: this.state.tempRating
        });
      }
    );
  };

  starOut = () => {
    this.setState({
      rating: this.state.tempRating
    });
  };

  rate = ratingFromClick => {
    this.setState(
      {
        rating: ratingFromClick,
        tempRating: ratingFromClick
      },
      () => {
        this.props.ratingCallBack(this.state.rating);
      }
    );
  };

  render() {
    const { rating } = this.state;
    const starsCount = 5;
    const tabIndex=0;
    if (rating > starsCount) {
      const error = "Invalid rating value !!!";
      console.info(error);
      return <p>{error}</p>;
    }
    let stars = [];
    for (let i = 1; i <= starsCount; i++) {
      let selectedClass = "";
      if (rating >= i && rating !== null) {
        selectedClass += " is-selected";
      }
      stars.push(
        <label tabIndex={tabIndex}
          key={i}
          className={`onkeyup star-rating__star ${selectedClass}`}
          onMouseOver={this.starOver.bind(this, i)}
          onMouseOut={this.starOut}
          onClick={this.rate.bind(this, i)}
        >
          ★
        </label>
      );
    }
    return <div className="star-rating">{stars}</div>;
  }
}

export default Rating;