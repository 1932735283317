import React from 'react'

import plusIcon from '../../assets/images/Plus_Button.svg'

class Panel extends React.Component<any, any> {
  private __timeout: any

  myRef: React.RefObject<any>

  constructor(props: any) {
    super(props)
    this.myRef = React.createRef()
    this.__timeout = null
    this.state = {
      height: 0,
    }
  }

  componentDidMount() {
    this.__timeout = window.setTimeout(() => {
      const height = this.myRef.current
      if (height && height.scrollHeight) {
        this.setState({
          height: height.scrollHeight,
        })
      }
    }, 333)
  }

  componentWillUnmount() {
    // cancel all subscriptions and events...
    clearTimeout(this.__timeout)
  }

  render() {
    const { label, content, activeTab, index, activateTab, themeName, id } = this.props
    // const { height } = this.state;
    // const isActive = activeTab === index;
    // const innerStyle = {
    //     height: `${isActive ? height : 0}px`
    // }

    return (
      <>
        <div aria-expanded={activeTab['block' + index]} className="accordian-class mb-5">
          <div onClick={activateTab}>
            <button
              id={id ? id : 'accordian-header'}
              type="button"
              className={`text-class page-header-txt ${themeName}-panel new-css w-full`}
              aria-hidden="false"
              data-toggle="collapse"
              data-target={`#collapseExample${index}`}
            >
              {label ? label.toUpperCase() : ''}
              {/* {!isActive && (
                                <span tabIndex={tabIndex} className="onkeyup" aria-hidden="false" data-toggle="collapse" data-target={`#collapseExample${index}`}>
                                    <img src={plusIcon} alt='Add' className='float-right ml-2 plus-i' />
                                </span>
                            )}
                            {isActive && (
                                <div className="fa-minus float-right ml-2 onkeyup" tabIndex={tabIndex} aria-hidden="true" data-toggle="collapse" data-target={`#collapseExample${index}`}>_</div>
                            )} */}
              {activeTab['block' + index] ? (
                <div
                  className="fa-minus float-right ml-2"
                  aria-hidden="true"
                  data-toggle="collapse"
                  data-target={`#collapseExample${index}`}
                >
                  _
                </div>
              ) : (
                <span aria-hidden="false" data-toggle="collapse" data-target={`#collapseExample${index}`}>
                  <img src={plusIcon} alt="Add" className="float-right ml-2 plus-i" />
                </span>
              )}
            </button>
          </div>
          <div
            className="collapse"
            id={`collapseExample${index}`}
            ref={this.myRef}
            aria-hidden={!activeTab['block' + index]}
          >
            <div className="panel__content">
              <div className="pad-te">{content}</div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export class Accordion extends React.Component<any, any> {
  constructor(props: {} | Readonly<{}>) {
    super(props)

    this.state = {
      activeTab: {
        block: false,
      },
    }

    this.activateTab = this.activateTab.bind(this)
  }

  activateTab(index: any) {
    // this.setState((prev: { activeTab: any; }) => ({
    //     activeTab: prev.activeTab === index ? -1 : index
    // }));
    const object = this.state.activeTab
    object['block' + index] = !this.state.activeTab['block' + index]
    this.setState(object)
  }

  render() {
    const { panels } = this.props
    const { activeTab } = this.state

    return (
      <div className="accordion accordion-fulwidth" role="tablist">
        {panels.map(
          (
            panel: JSX.IntrinsicAttributes &
              JSX.IntrinsicClassAttributes<Panel> &
              Readonly<any> &
              Readonly<{ children?: React.ReactNode }>,
            index: React.Key | null | undefined
          ) => (
            <Panel
              themeName={this.props.themeName || ''}
              key={index}
              activeTab={activeTab}
              index={index}
              {...panel}
              activateTab={this.activateTab.bind(null, index)}
            />
          )
        )}
      </div>
    )
  }
}
