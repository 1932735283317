import React, { ChangeEvent, Fragment } from 'react'

import '../styles/stiRadioButton.css'

interface STIRadioButtonProps {
  label: string
  value: string
  checked?: boolean
  name?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  subTitle?: string
  addClass?: string
  theme?: string
  id?: string
}

export const STIRadioButton: React.FC<STIRadioButtonProps> = ({
  label,
  value,
  checked,
  name,
  onChange,
  onBlur,
  subTitle,
  addClass = '',
  theme = '',
  id,
}) => {
  return (
    <>
      <label className={`container label-font ${addClass} ${subTitle ? 'mb-0' : 'mb-4'}`}>
        {label}
        <input id={id} type="radio" checked={checked} name={name} onChange={onChange} onBlur={onBlur} />
        <span className="checkmark" />
      </label>
      {subTitle ? (
        <div className="edit-kit-note-blk">
          {/* <span className={`sub-title-blk ${addClass}`}>{subTitle}</span> */}
          <div className={`b2-style ${theme}-txt-global mb-0`} dangerouslySetInnerHTML={{ __html: subTitle }} />
        </div>
      ) : null}
    </>
  )
}
