import React from 'react'

interface StisListCardProps {
  data: any;
}

export const StisListCard: React.FC<StisListCardProps> = ({ data }) => {
  return (
    <div className='stis-menu-item' >
      <p className='stis-label'>STIS</p>
      <h3 className='stis-title'>{ data.name ? (data.name === 'chlamydia' ? 'Chlamydia and Gonorrhoea':(data.name.charAt(0).toUpperCase() + data.name.slice(1))):""}</h3>
      <div className='test-kits-blk mb-5'>
        <h3 className='test-kit-heading'>Test kits</h3>
        <div className='test-kits-card'>
          <p className='stis-label mb-0'>Requests</p>
          <p className='stis-label mb-0'>Flagged requests</p>
          <p className='stis-kit-card-value'>{data.request}</p>
          <p className='stis-kit-card-value'>{data.flagged}</p>
        </div>
      </div>

      <div className='test-kits-blk'>
        <h3 className='test-kit-heading'>Test results</h3>
        <div className='test-kits-card'>
          <p className='stis-label mb-0'>Received</p>
          <p className='stis-label mb-0'>Positive</p>
          <p className='stis-kit-card-value'>{data.result}</p>
          <p className='stis-kit-card-value'>{data.positive}</p>
        </div>
      </div>
    </div>
  );
}