import React, { useState } from 'react'

import { Helmet } from 'react-helmet-async'
// import Calendar from 'react-multi-date-picker-calendar'
import { Link } from 'react-router-dom'

import '../../styles/SignpostingToolStyle/home.css'
import Signbox from '../../assets/images/sign-box.svg'
import appUrls from '../../services/AppURLs'
import '../../styles/common.css'
import { False } from './False'
import { SignPostingFooter } from './SignPostingFooter'
import { SignPostingHeader } from './SignPostingHeader'

export function SignpostingHome({}) {
  const [TrueFn, ShowTruefn] = useState(false)
  const [FalseFn, ShowFalsefn] = useState(false)
  function truefn() {
    ShowFalsefn(false)
    ShowTruefn(true)
  }
  function falseFn() {
    ShowFalsefn(true)
    ShowTruefn(false)
  }
  // class SignpostingHome extends Component {
  // render() {
  const tabIndex = 0
  const noTabIndex = -1
  return (
    <div className="signposting-page">
      <SignPostingHeader activeState="home" />
      <Helmet>
        <title>SPT | Home - Brook</title>
      </Helmet>
      {/* <Calendar
        multiSelect={true}
        // dates={[new Date("2023-07-18T05:45:48.005Z")]}
        onChange={(dates: Date[]) => {
          console.log(dates)
        }}
      /> */}
      <section className="signposting-section bg-white color-purple">
        <div className="content-container equal-space-padding">
          <div className="container-540">
            <h1 className="page-heading"> WHAT CAN BROOK HELP YOU WITH TODAY?</h1>
          </div>
        </div>
      </section>
      <div className="zig-zag-pattern color-purple bg-white" />
      <section className="signposting-section bg-purple">
        <div className="content-container content-container-home equal-space-padding">
          <Link className="text-decoration-home" tabIndex={noTabIndex} to={appUrls.signPosting.havingSex}>
            <button type="button" className="content-button btn-lg btn-block">
              Having sex for the first time
            </button>
          </Link>
          <Link className="text-decoration-home" tabIndex={noTabIndex} to={appUrls.signPosting.sti}>
            <button type="button" className="content-button btn-lg btn-block">
              Getting an STI test
            </button>
          </Link>
          <Link className="text-decoration-home" tabIndex={noTabIndex} to={appUrls.signPosting.emergencyContraception}>
            <button type="button" className="content-button btn-lg btn-block">
              Emergency contraception
            </button>
          </Link>
          <Link className="text-decoration-home" tabIndex={noTabIndex} to={appUrls.signPosting.contraception}>
            <button type="button" className="content-button btn-lg btn-block">
              What contraception is right for me?
            </button>
          </Link>
          <Link className="text-decoration-home" tabIndex={noTabIndex} to={appUrls.signPosting.unprotectedSex}>
            <button type="button" className="content-button btn-lg btn-block">
              I've had unprotected sex
            </button>
          </Link>
          <Link className="text-decoration-home" tabIndex={noTabIndex} to={appUrls.signPosting.help}>
            <button type="button" className="content-button btn-lg btn-block">
              I'm concerned about myself or someone else
            </button>
          </Link>
        </div>
      </section>
      <section className="signposting-section bg-purple">
        <div className="content-container equal-space-padding py-0">
          <div className="container-450">
            <div className="dotted-border" />
          </div>
          <div className="pattern-placement">
            <img src={Signbox} alt="no-image" className="inline-block top-right" />
          </div>
        </div>
      </section>
      <section className="signposting-section bg-purple">
        <div className="content-container equal-space-padding pb-0">
          <h1 className="section-head" aria-label=" MYTH BUSTING">
            MYTHBUSTING
          </h1>
          <div className="spacer-20" />
          <div className="pos-relative">
            <div className="s-body move-top">BODIES</div>
            <div className="chat-bubble-arrow" />
            <div className="myth-chat-bubble">“There is no scientific way to tell if someone has had sex.”</div>
          </div>
          <div className="container-450">
            {!TrueFn && !FalseFn ? (
              <div>
                <div className="spacer-20" />
                <p className="section-desc section-desc-2-color">What do you think?</p>
                <div className="spacer-10" />
                <div className="dotted-border" />
              </div>
            ) : (
              ''
            )}
            <div className="true-false-blk">
              <button className="s-btn-clr m-0" type="submit" onClick={truefn}>
                True
              </button>
              <p>or</p>
              <button className="s-btn-clr" type="submit" onClick={falseFn}>
                False
              </button>
            </div>

            {TrueFn && (
              <div>
                <div className="spacer-30" />
                <False type="home" />
              </div>
            )}
            {FalseFn && (
              <div>
                <div className="spacer-30" />
                <False type="home" />
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="signposting-section bg-white">
        <div className="content-container equal-space-padding">
          <h1 className="section-head dark">NEED URGENT HELP?</h1>
          <div className="spacer-10" />
          <div className="section-desc sec-desc-2-color">
            If you are worried about yourself or someone else, there are lots of places that can offer help and support.
          </div>
          <div className="spacer-20" />
          <Link className="s-btn-clr mr-3" to={appUrls.signPosting.help}>
            Find help
          </Link>
        </div>
      </section>
      <section className="signposting-section bg-purple">
        <div className="content-container pb-0 equal-space-padding">
          <h1 className="section-head">PEOPLE ALSO ASK…</h1>
          <div className="mar-s">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.brook.org.uk/your-life/having-sex-for-the-first-time/"
              className="ask-link"
            >
              I want to know more about having sex for the first time
            </a>
          </div>
          <div className="mar-s">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.brook.org.uk/your-life/what-is-virginity/"
              className="ask-link"
            >
              What is 'virginity'?
            </a>
          </div>
          <div className="mar-s">
            <a target="_blank" rel="noreferrer" href="https://www.brook.org.uk/topics/sex/" className="ask-link">
              I want to know more about different types of sex
            </a>
          </div>
          <div className="spacer-30" />
          <div className="dotted-border" />
        </div>
      </section>
      <SignPostingFooter />
    </div>
  )
  // }
}
export default SignpostingHome
