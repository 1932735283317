/**
 * Utterance  of text to speech
 */
export default class TextToSpeech {
    utterance: SpeechSynthesisUtterance;

    static __instance: TextToSpeech | undefined;

    soundCount: number;

    texts: Array<string>;

    private __playSound: boolean = true;

    constructor() {
      
        this.soundCount = 0;
        this.texts = [];
        this.__playSound = true;
        this.utterance = new window.SpeechSynthesisUtterance();
        this.utterance.rate = 0.8;
        this.utterance.lang = 'en-gb';
        this.utterance.pitch = 0.9;
        this.utterance.volume = 1;
        this.utterance.onpause = this.callbacks.bind(this)
        this.utterance.onboundary = this.callbacks.bind(this)
        this.utterance.onend = this.callbacks.bind(this)
        this.utterance.onerror = this.callbacks.bind(this)
        this.utterance.onstart = this.callbacks.bind(this)
        this.utterance.onmark = this.callbacks.bind(this)
        this.utterance.onresume = this.callbacks.bind(this)
       
    }

    private callbacks(e: any) {
        if (e.type === 'end') {
            if (this.soundCount < this.texts.length - 1) {
                this.soundCount += 1;
                this.playSound(this.texts[this.soundCount])
            }
        }
    }

    public playSound(_text: string): void {
        if (!this.__playSound) return;
        this.utterance.text = String(_text);
        speechSynthesis.cancel();
        speechSynthesis.speak(this.utterance);
    }

    public stopSpeech() {
        this.soundCount = 0
        this.texts.length = 0
        this.utterance.onend = this.callbacks.bind(this)
    }

    public static getInstance() {
        if (!this.__instance) {
            this.__instance = new TextToSpeech()
        }
        return this.__instance;
    }

    public playVoice(_txt: string | null, _content: string = ''): void {
        if (_content === 'stop') {
            this.__playSound = false;
            speechSynthesis.cancel();
            return;
        }
        this.__playSound = true;
        this.soundCount = 0;
        this.texts = this.splitText(_txt ? (document.getElementsByClassName(_txt)[0].textContent || '') : _content)
        this.playSound(this.texts[this.soundCount])
    }

    private splitText(__text: string): Array<string> {
        let strs = [];
        for (let __n = 0; __n < Math.ceil(__text.length / 180); __n++) {
            strs[__n] = __text.slice(__n * 180, (__n * 180) + 180)
        }
        return strs;
    }
}