import React, { useCallback, useContext, useEffect, useState } from 'react'

// import backIcon from '../assets/images/Back_Button.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import appUrls from '../services/AppURLs'
import '../styles/confirmemail.css'
import { history } from '../utils/history'

interface ContactBrookProps {
  children: any
}

export const ContactBrook: React.FC<ContactBrookProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext<any>(ThemeContext)

  useEffect(() => {
    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: '100' },
    })
  }, [])

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        themeDispatch({ type: 'SET_THEME_INTIAL_VALUE' })
        history.push(appUrls.sti.home)
      }
    }
  }, [history])

  const proceed = useCallback(() => {
    window.location.href = appUrls.outerDomainUrls.brookParent
  }, [])
  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('contactBrook_id')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  return (
    <div className="bg-color whatnext-style contactbrook-h">
      <div className="text-to-speech brook-container pad-bot">
        <div className="h-div mt-4">
          <h2 className="page-header-txt">ORDER A TEST KIT</h2>
          <div className="speaker-i">
            <button
              className="no-background-border"
              onClick={(e) => {
                TextToSpeech.getInstance().playVoice('text-to-speech')
                setisAccessibilityVoiceOver(true)
              }}
            >
              <img src={Icon} alt="speaker" />
            </button>
          </div>
        </div>
        <div className={`b1-style l-space pt-4 f-size-contact ${theme.theme} mb-4`}>
          <br />
          Thank you. Someone from Brook will be in touch as soon as possible. <br />
          Please keep a lookout for our calls or messages.
          {theme && theme.age >= 16 && (
            <p className="pt-0">
              {' '}
              If you asked for email confirmation of your order, please keep an eye on your inbox. If you have not
              received your confirmation email within 24 hours, please check your junk or spam folder before getting in
              touch.{' '}
            </p>
          )}
          <br />
          <p className="l-height mt-4">
            You can find full details of your rights regarding the information you share with us and when accessing
            sexual health services on the Brook website:
            <br />
            <a
              id="contactBrook_id"
              href="https://www.brook.org.uk/privacy-and-confidentiality"
              className="text-a-tag"
              target="_blank"
            >
              www.brook.org.uk/privacy-and-confidentiality{' '}
            </a>
          </p>
          {/* {!new URL(window.location.href).searchParams.get('feedback') && theme.age && theme.age > 15 && (
            <>
              <p className="l-height mt-4">
                Can you take a moment to let us know how you found the STI kit order process?<br />
                <a href='https://forms.office.com/Pages/ResponsePage.aspx?id=S2A4YOEixkK3TmVcYQTmMcpoP-K14ctNn-6Oh1jxMMJURVlETjBHRE9GUkhGVUtLSTBQUzVaOUwwOCQlQCN0PWcu' className='text-a-tag' target='_blank'>Click here to provide feedback.</a> <br />
              </p>
              <br />
            </>
          )} */}
        </div>
        <div>
          <div className="ft-tab brook-container jus-end">
            {/* <button
              className="float-left no-background-border"
              onClick={e => GoBackPage()}
            >
              <img src={backIcon} alt="back" />
            </button> */}
            <Button
              isDisabled={false}
              buttonClassName="btn btn-default btn-main"
              buttonText="Finish"
              onClick={(e) => proceed()}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
