import React from 'react'

/**
 * Props for Button component
 */
interface Props {
  buttonText: string
  buttonClassName: string
  onChange: (e: any) => void
  selected: string
  id: string
}

/**
 * Button component
 * @returns button component with customizable props
 */
export class Select extends React.Component<Props, {}> {
  public static defaultProps: Partial<Props> = {
    buttonClassName: 'btn btn-default btn-d',
  }

  public render(): JSX.Element {
    return (
      <select
        id={this.props.id}
        className="form-select select-style"
        aria-label="select age"
        onChange={this.props.onChange}
        value={this.props.selected}
      >
        <option value="">Select age</option>
        <option value="12">12 or under</option>
        <option value="13">13</option>
        <option value="14">14</option>
        <option value="15">15</option>
        <option value="16">16</option>
        <option value="17">17</option>
        <option value="18">18</option>
        <option value="19">19</option>
        <option value="20">20</option>
        <option value="21">21</option>
        <option value="22">22</option>
        <option value="23">23</option>
        <option value="24">24</option>
        <option value="25">25 or over</option>
      </select>
    )
  }
}
