import React, { useCallback, useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import '../styles/typeofsex.css'
import { Accordion } from '../components/accordian/Accordian'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import { getTFourPlusDisease } from '../utils/getTFourPlusDisease'
import { stiKitLoaderPercentage } from '../utils/stiKitLoaderPercentage'

interface TypeofsexProps {
  Prop: any
}

export const Genitals: React.FC<TypeofsexProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)

  const [typeOfSex, setTypeOfSex] = useState<string>('')
  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('genitals_id')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])
  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  useEffect(() => {
    if (theme.quesObj && theme.quesObj['What genitals do you have?']) {
      setTypeOfSex(theme.quesObj['What genitals do you have?'])
    }

    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: stiKitLoaderPercentage.GENITALS },
    })
  }, [])

  const history = useHistory()

  const isTFourPlusGenitalOptions = (myobj: any) => {
    // console.log('isTFourPlusGenitalOptions myObj :>>', typeOfSex, myobj);
    if (
      (myobj['Vaginal'] && myobj['Vaginal']['type'] === 'given' && typeOfSex === 'Penis') ||
      (myobj['Anal'] && myobj['Anal']['type']['given'] && typeOfSex === 'Penis') ||
      (myobj['Anal'] && myobj['Anal']['type']['received'] && (typeOfSex === 'Penis' || typeOfSex === 'Vagina')) ||
      (myobj['Oral'] && myobj['Oral']['type']['given'] && (typeOfSex === 'Penis' || typeOfSex === 'Vagina')) ||
      (myobj['Oral'] && myobj['Oral']['type']['received'] && (typeOfSex === 'Penis' || typeOfSex === 'Vagina')) ||
      (myobj['Hand'] &&
        myobj['Hand']['type'] &&
        myobj['Hand']['type']['received'] &&
        (typeOfSex === 'Penis' || typeOfSex === 'Vagina'))
    ) {
      return true
    } else {
      return false
    }
  }

  const proceed = useCallback(() => {
    const isTFourPlusDisease = getTFourPlusDisease(theme)

    TextToSpeech.getInstance().playVoice(null, 'stop')
    let myobj = JSON.parse(theme.quesObj['What type of sex have you had since your last test?'])

    themeDispatch({
      type: 'SET_QUES',
      payload: {
        quesObj: { ...theme.quesObj, 'What genitals do you have?': typeOfSex },
      },
    })
    if (isTFourPlusDisease === true) {
      if (isTFourPlusGenitalOptions(myobj)) {
        // history.push('/risk-hepatitis')
        history.push('/eligibilitypass')
      } else if (myobj['Vaginal'] && myobj['Vaginal']['type'] === 'received' && typeOfSex === 'Vagina') {
        history.push('/vaginalsex')
      } else {
        history.push('/brookClinic')
      }
    } else {
      if (
        (myobj['Vaginal'] && myobj['Vaginal']['type'] === 'given' && typeOfSex === 'Penis') ||
        (myobj['Vaginal'] && myobj['Vaginal']['type'] === 'received' && typeOfSex === 'Vagina') ||
        (myobj['Anal'] && myobj['Anal']['type']['given'] && typeOfSex === 'Penis') ||
        (myobj['Oral'] && myobj['Oral']['type']['received'] && (typeOfSex === 'Penis' || typeOfSex === 'Vagina')) ||
        (myobj['Hand'] &&
          myobj['Hand']['type'] &&
          myobj['Hand']['type']['received'] &&
          (typeOfSex === 'Penis' || typeOfSex === 'Vagina'))
      ) {
        history.push('/eligibilitypass')
      } else {
        history.push('/brookClinic')
      }
    }
  }, [typeOfSex])

  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }

  return (
    <div className={`bg-color genital-style  ${theme.theme}-global`}>
      <div className="brook-container pad-bot">
        <div className="text-to-speech">
          <div className="pad-te">
            <div className="h-div mt-4">
              <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
              <div className="speaker-i">
                <button
                  className="no-background-border"
                  onClick={(e) => {
                    TextToSpeech.getInstance().playVoice('text-to-speech')
                    setisAccessibilityVoiceOver(true)
                  }}
                >
                  <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
                </button>
              </div>
            </div>
            <div className={`b1-style ${theme.theme}-txt-global`}>What genitals do you have?</div>
            {/* <div className={`b2-style ${theme.theme}-txt-global`}tabIndex={tabIndex}>
             <i>Tick all that apply</i>
            </div> */}
          </div>
          <Accordion
            themeName={theme.theme}
            panels={[{ id: 'genitals_id', label: 'WHY ARE YOU ASKING ME THIS?', content: child(theme) }]}
          />
          <div className="pad-tp font-check mb-4">
            <div className="form-check mt-5">
              <input
                className="form-check-input"
                type="checkbox"
                value="Vagina"
                aria-label="Vagina"
                id="flexCheckDefault"
                checked={typeOfSex === 'Vagina'}
                onChange={(e) => {
                  e.target.checked ? setTypeOfSex(e.target.value) : setTypeOfSex('')
                }}
              />
              <div className="d-flex">
                <div className="mar-st">
                  <label className={`form-check-label label-font ${theme.theme}-txt-global`}>Vagina</label>
                </div>
              </div>
            </div>
            <div className="form-check mt-5">
              <input
                className="form-check-input"
                type="checkbox"
                value="Penis"
                aria-label="Penis"
                checked={typeOfSex === 'Penis'}
                onChange={(e) => {
                  e.target.checked ? setTypeOfSex(e.target.value) : setTypeOfSex('')
                }}
              />
              <div className="d-flex">
                <div className="mar-st">
                  <label className={`form-check-label label-font ${theme.theme}-txt-global`}>Penis</label>
                </div>
              </div>
            </div>
            <div className="form-check mt-5">
              <input
                className="form-check-input"
                type="checkbox"
                value="I don't know"
                aria-label="I don't know"
                checked={typeOfSex === "I don't know"}
                onChange={(e) => {
                  e.target.checked ? setTypeOfSex(e.target.value) : setTypeOfSex('')
                }}
              />
              <div className="d-flex">
                <div className="mar-st">
                  <label className={`form-check-label label-font ${theme.theme}-txt-global`}>I don't know</label>
                </div>
              </div>
            </div>
            <div className={`ft-tab brook-container ${theme.theme}-skin-color`}>
              <button className="float-left no-background-border" onClick={(e) => GoBackPage()}>
                <img src={backIcon} alt="back" />
              </button>
              <Button
                isDisabled={typeOfSex === ''}
                buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                buttonText="Next"
                onClick={(e) => proceed()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const child = (props: any) => {
  const tabIndex = 0
  return (
    <>
      <div className={`p3-style p2-style ${props.theme}-txt-global`} tabIndex={tabIndex}>
        Your response to the question and the previous question will help us know which type of kit to send you (this
        will either be a swab or a urine sample), or if you may need a different type of test that needs to be done in a
        clinic.
      </div>
    </>
  )
}
