let backendDomain, tinaServer

if (process.env.NODE_ENV === 'production') {
  backendDomain = process.env.REACT_APP_SERVICE_PHR_BACKEND
  tinaServer = window.location.origin
} else {
  backendDomain = process.env.REACT_APP_SERVICE_PHR_BACKEND
  tinaServer = process.env.REACT_APP_SERVICE_TINA_BACKEND
}

tinaServer = tinaServer
  ? tinaServer.endsWith('/')
    ? tinaServer
    : tinaServer + '/'
  : ''

backendDomain = backendDomain
  ? backendDomain.endsWith('/')
    ? backendDomain
    : backendDomain + '/'
  : ''

const configJson = {
  backendDomain,
  tinaServer,
  sso:{
    appId: process.env.REACT_APP_AZURE_CLIENT_ID||'',
    redirectUrl:process.env.REACT_APP_REDIRECT_URL,
    scopes:[
        'user.read'
    ],
    auhthority:`https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`

},
MAIA_APP_URL:process.env.REACT_APP_MAIA_APP_URL || 'https://staging.maiaphr.com/',

}

 const siteConfig = {
  nhs: {
    client_id: process.env.REACT_APP_NHS_LOGIN_CLIENT_ID,
    authorization_url: process.env.REACT_APP_NHS_LOGIN_AUTHORIZATION_URL,
    audience: process.env.REACT_APP_NHS_LOGIN_AUDIENCE,
    token_host: process.env.REACT_APP_NHS_LOGIN_TOKEN_HOST,
    key: process.env.REACT_APP_NHS_LOGIN_KEY_PATH,
    client_redirect_url: process.env.REACT_APP_NHS_LOGIN_CLIENT_REDIRECT_URL,
  },
  sso:{
    appId: process.env.REACT_APP_AZURE_CLIENT_ID||'',
    redirectUrl:process.env.REACT_APP_REDIRECT_URL,
    scopes:[
        'user.read'
    ],
    auhthority:`https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`

}
}

export default configJson;
