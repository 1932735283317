import React, { useCallback, useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import '../styles/postcode.css'

interface PostcodeProps {
  Props: any
}

export const FinalScreen: React.FC<PostcodeProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)

  const history = useHistory()

  const [isEmail, setIsEmail] = useState(false);

  const [isPhone, setIsPhone] = useState(false);

  useEffect(() => {

    if (theme.quesObj.isemailContact) {
      setIsEmail(theme.quesObj.isemailContact)
    }

    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: '99' },
    })
  }, [])

  const proceed = useCallback(() => {
    TextToSpeech.getInstance().playVoice(null, 'stop')
    themeDispatch({
      type: 'SET_QUES',
      payload: {
        quesObj: {
          ...theme.quesObj,
          isemailContact: isEmail,
          isPhoneContact: !isEmail,
        },
      },
    })
    Props.history.push('/thankyou')
  }, [isEmail])

  return (
    <div className={`bg-color postcode-style ${theme.theme}-global`}>
      <div className='brook-container pad-bot'>
        <div className="text-to-speech">
        <div className="h-div mt-4">
          <h2 className={`page-header-txt ${theme.theme}-txt-global`}>
            ORDER A TEST KIT{' '}
          </h2>
          <div className="speaker-i">
            <button
              className="no-background-border"
              onClick={(e) =>
                TextToSpeech.getInstance().playVoice('text-to-speech')
              }
            >
              <img
                src={theme.theme === 'Calm' ? SoundIcon : Icon}
                alt="speaker"
               />
            </button>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div>
          <div className={`b1-style ${theme.theme}-txt-global`}>
            We'll also put someone in touch with you as you've mentioned that
            you'd like to talk to someone.
          </div>
          <br />
          <br />
          <div className="single-row">
            {/* <div>
              <input
                type="checkbox"
                id="vehicle1"
                checked={isEmail}
                onChange={e => {
                  setIsEmail(e.target.checked)
                }}
                className="form-check-input check-style"
              />
            </div>
            <div className={`ml-4 mt-2 p2-style mb-4 ${theme.theme}-txt-global`}>
              I would prefer to be emailed rather than called on my mobile.
            </div> */}
          </div>
        </div>
        </div>
        <div>
          <div className={`ft-tab brook-container jus-end  ${theme.theme}-skin-color`}>
            <Button
              isDisabled={false}
              buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
              buttonText="Finish"
              onClick={(e) => proceed()}
            />
          </div>
        </div>
      </div>
    </div>
  )
}