import React from 'react'

const WhiteNext = () => {
  return (
    <div className="custom-next-arrow">
      <svg xmlns="http://www.w3.org/2000/svg" width="15.778" height="27.313" viewBox="0 0 15.778 27.313">
        <g id="Group_4250" data-name="Group 4250" transform="translate(-345.844 -1062.121)">
          <line
            id="Line_1245"
            data-name="Line 1245"
            x2="11.535"
            y2="11.535"
            transform="translate(347.965 1064.242)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-width="3"
          />
          <line
            id="Line_1246"
            data-name="Line 1246"
            y1="11.535"
            x2="11.535"
            transform="translate(347.965 1075.777)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-width="3"
          />
        </g>
      </svg>
    </div>
  )
}

export default WhiteNext
