import React, { useState, useEffect } from 'react'

import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'

import eyeIcon from '../../assets/images/Eye_no_strike.svg'
import hidePassowrd from '../../assets/images/Eye_strike.svg'
import apiFunctions from '../../services/api-service'
import appUrls from '../../services/AppURLs'
import { AdminLayout } from './AdminLayout'
import '../../styles/common.css'
// import { history } from '../../utils/history';
// import Loader from '../../components/Loader/loader';
interface ResetpasswordPageProps { }
const errorIntialState = {
  status: false,
  message: '',
}
const validationSchema = Yup.object({
  create_password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9#?!@$%^&*-]).{8,}$/,
      'Password must have 8 characters with 1 uppercase, 1 lowercase and a number/symbol'
    )
    .required('New password is required'),
  new_password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9#?!@$%^&*-]).{8,}$/,
      'Password must have 8 characters with 1 uppercase, 1 lowercase and a number/symbol'
    )
    .required('Password is required'),
})
export const ResetpasswordPage: React.FC<ResetpasswordPageProps> = ({ }) => {
  const { token } = useParams<{ token: string }>()
  const [passwordShown, setPasswordShown] = useState(false)
  const [passwordShownagain, setPasswordShownagain] = useState(false)
  const [comparePassword, setcomparePassword] = useState(false)
  const [passwordResposne, setpasswordResposne] = useState(false)
  const [FormShown, setFormShown] = useState(true)
  // const [error, setError] = useState("")
  const [showpoup, setshowpoup] = useState(false)
  const [error, setError] = useState(errorIntialState)
  const togglePassword = (type: any) => {
    if (type === 'again') {
      setPasswordShownagain(!passwordShownagain)
      return
    }
    setPasswordShown(!passwordShown)
  }

  useEffect(() => {
    UpdatePassword({ password: '', token, type: 'initial' })
    localStorage.clear()
  }, [])

  const intialValue = {
    // create_password: '',
    new_password: '',
  }

  const formik = useFormik({
    initialValues: intialValue,
    validationSchema,
    async onSubmit(values: any) {
      try {
        setcomparePassword(false)
        if (values.create_password !== values.new_password) {
          setcomparePassword(true)
          return
        }
        UpdatePassword({ password: values.new_password, token, type: 'update' })
      } catch (error: any) { }
    },
  })

  function UpdatePassword(payload: any) {
    apiFunctions
      .put(process.env.REACT_APP_SERVICE_BACKEND_URL + '/auth/forgot-password-change', payload)
      .then((_result) => {
        console.log(_result);

        if (payload.type !== 'initial') {
          setpasswordResposne(true)
          setFormShown(false)
          setError({ status: false, message: '' })
          localStorage.clear()
          sessionStorage.clear()
        }
      })
      .catch((err) => {
        setError({ status: true, message: err && err.response && err.response.data && err.response.data.message })
        const timer = setTimeout(() => {
          setError({ status: false, message: '' })
        }, 6000);
      })
  }
  function closeModel() {
    window.location.href = `${appUrls.admin.auth.login}?refresh=true`
  }
  const tabIndex = 0;
  return (
    <AdminLayout roleMode="Super admin mode">
      <div className="admin-login-style">
        <div className="col-12 col-sm-10 col-md-6 mt-4">
          <div className="login-style1" tabIndex={tabIndex}>Log in using your Brook email address</div>
        </div>
        <hr />
        {error && error.message ? <span className="errorMessage">{error.message}</span> : ''}
        <div className="col-12 col-sm-10 col-md-6 mt-4">
          <p className="login-style2" tabIndex={tabIndex}>Reset your password</p>
        </div>

        {showpoup ? (
          <div className="modal d-block bg-color-popup" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content model-bor">
                <div className="modal-body body-pad">
                  <div>
                    <div className="popup-text" tabIndex={tabIndex}>Your token is expired</div>
                  </div>
                  <div className="modal-footer bor-none">
                    <div className="popup-btn close-pop" onClick={closeModel} tabIndex={tabIndex}>
                      Close
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}

        {passwordResposne ? (
          <div className="text-account" tabIndex={tabIndex}>
            Success! Your password was changed.
            <a href={appUrls.admin.auth.login}>
              <button type="button" className="btn btn-primary float-right login-btn">
                Go to login
              </button>
            </a>
          </div>
        ) : (
          ''
        )}
        <div>
          {FormShown ? (
            <form className="col-12 col-sm-10 col-md-5 mt-5" onSubmit={formik.handleSubmit}>
              <div className="form-group parent-pos">
                <label className="in-style" tabIndex={tabIndex}>New password</label>

                <input
                  type={passwordShown ? 'text' : 'password'}
                  className="form-control  login-radius"
                  id="create_password"
                  name="create_password"
                  onChange={formik.handleChange}
                />

                <div className="i-icon">
                  <img
                    tabIndex={tabIndex}
                    src={passwordShown ? eyeIcon : hidePassowrd}
                    className="icon-style"
                    alt="eyeIcon"
                    onClick={(e) => {
                      togglePassword('new')
                    }}
                  />
                </div>
                <span className="errorMessage">
                  {formik.touched.create_password && formik.errors.create_password
                    ? formik.errors.create_password
                    : comparePassword
                      ? 'Passwords must be same'
                      : null}
                </span>
              </div>
              <div className="form-group mt-4 parent-pos">
                <label className="in-style" tabIndex={tabIndex}>Type new password again</label>
                <input
                  type={passwordShownagain ? 'text' : 'password'}
                  className="form-control  login-radius"
                  aria-describedby="emailHelp"
                  id="new_password"
                  name="new_password"
                  onChange={formik.handleChange}
                />
                <div className="i-icon">
                  <img
                    tabIndex={tabIndex}
                    src={passwordShownagain ? eyeIcon : hidePassowrd}
                    className="icon-style"
                    alt="eyeIcon"
                    onClick={(e) => {
                      togglePassword('again')
                    }}
                  />
                </div>
                <span className="errorMessage">
                  {formik.touched.new_password && formik.errors.new_password ? formik.errors.new_password : null}
                </span>
              </div>
              <ul className='list-pass'>
                <p className="mt-4">Your password must:</p>
                <li className="mt-2 mb-1 text-left pass-text-style" tabIndex={tabIndex}>
                  Be at least 8 characters long
                </li>
                <li className="mb-1 text-left pass-text-style" tabIndex={tabIndex}>
                  Contain at least 1 uppercase letter, 1 lowercase letter, a number and a symbol (e.g. !?£$%*&)
                </li>
                <li className="mb-1 text-left pass-text-style" tabIndex={tabIndex}>
                  Be unique to Staff Hub
                </li>
                <li className="mb-1 text-left pass-text-style" tabIndex={tabIndex}>
                  Not be your username or name
                </li>
                <li className="mb-1 text-left pass-text-style" tabIndex={tabIndex}>
                  Not be a generic or easy to guess word (like 'password' or 'staff hub')
                </li>
                <li className=" text-left pass-text-style" tabIndex={tabIndex}>
                  If you are resetting your password, it cannot be the same as any of your last 5 passwords
                </li>
              </ul>

              <div className="login-btn-wrap">
                <button type="submit" className="btn btn-primary float-right login-btn">
                  Reset password
                </button>
              </div>
            </form>
          ) : (
            ''
          )}
        </div>
      </div>
      {/* <Loader hide={false}></Loader> */}
    </AdminLayout>
  )
}

export default ResetpasswordPage
