import React, { useState } from 'react';

import moment from 'moment'

import { ReactComponent as NextArrow } from '../../../assets/images/arrow_back.svg';
import { ReactComponent as BackArrow } from '../../../assets/images/arrow_forward.svg';
import POWER_BI_IMG from '../../../assets/images/power_bi.png';
import { SelectDropdown } from '../SelectDropdown';

interface FilterInDashboardProps {
  handleSelectedDaysAndStis: any;
  FilterData?: any;
  stiList?: any;
  changeDates?: any;
  toDate?: any
}



const daysFilterOption: any = [
  {
    label: '7 days',
    value: '6'
  },
  {
    label: '30 days',
    value: '29'
  },
  {
    label: '60 days',
    value: '59'
  },
];

export const FilterInDashboard: React.FC<FilterInDashboardProps> = ({
  handleSelectedDaysAndStis, FilterData, stiList, changeDates, toDate
}) => {
  const STI_List: any = [
    {
      label: 'All STI',
      value: 'all'
    },
  ];
  const [internalState, setInternalState] = useState<any>({
    stiDropDownList: '',
    weekDaysList: ''
  })
  stiList.filter((res:any)=> res.name !=null).map((item: any) => {    
    if(item  && item.name != null){
      STI_List.push({
        label: item.name ==='chlamydia' ? 'Chlamydia and Gonorrhoea': item.name && item.name.charAt(0).toUpperCase() + item.name.slice(1),
        value: item.name
      })
    }
  });
  const uniqueCourseArray = STI_List.filter((course: any, index: number) => {
    return index === STI_List.findIndex((obj: any) => JSON.stringify(obj) === JSON.stringify(course));
  });

  return (
    <div className='filter-section'>
      <div className='date-range-section'>
        <div className='date-subRange-blk'>
          <p className='date-range'>{FilterData.fromDate} - {FilterData.toDate}</p>
          <div className='arrow-icon-blk'>
            <button onClick={() => changeDates('prev')}>
              <NextArrow />
            </button>
            <button
              className='forward-arrow-icon'
              onClick={() => changeDates('next')}
              disabled={!(moment(toDate).format("YYYY-MM-DD") < moment(new Date()).format("YYYY-MM-DD"))}>
              <BackArrow />
            </button>
          </div>
        </div>
        <span className='date-range-label'>{parseInt(FilterData.selectDay) + 1} days</span>
      </div>
      <div className="select-group-block">
        <SelectDropdown
          addclass="secondary-select"
          type="text"
          name="unit"
          placeholder="Select"
          optionsArray={daysFilterOption}
          onChange={(e: any) => {
            handleSelectedDaysAndStis(e, 'selecte_day');
          }}
          value={FilterData.selectDay}
        />
      </div>
      <div className="select-group-block">
        <SelectDropdown
          addclass="secondary-select"
          type="text"
          name="unit"
          placeholder="Select"
          optionsArray={uniqueCourseArray}
          onChange={(e: any) => {
            handleSelectedDaysAndStis(e, 'selecte_sti');
          }}
          value={FilterData.selectSTI}
        />
      </div>
      <div>
        <button onClick={() => window.open(process.env.REACT_APP_POWER_BI_URL)}>
          <img src={POWER_BI_IMG} className='power-bi' />
        </button>
      </div>
    </div>
  );
}