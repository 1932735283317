import React, { useState } from 'react'

// import { Link } from 'react-router-dom';

import '../../styles/SignpostingToolStyle/home.css'
import '../../styles/SignpostingToolStyle/havingsex.css'

import { Helmet } from 'react-helmet-async'

import PersonIcon from '../../assets/images/charlie.png'
import Exclamation from '../../assets/images/exclamation-icon.svg'
import appUrls from '../../services/AppURLs'
import { False } from './False'
import { SignPostingFooter } from './SignPostingFooter'
import { SignPostingHeader } from './SignPostingHeader'

export function STI({}) {
  const [TrueFn, ShowTruefn] = useState(false)
  const [FalseFn, ShowFalsefn] = useState(false)
  const [SubmitFn, ShowContent] = useState(false)
  const [ContraceptionFn, activeContraception] = useState(false)
  const [ImplantFn, activeImplant] = useState(false)
  const [TypesofcontraceptionFn, activeTypesofcontraception] = useState(false)
  function submitFn() {
    if (ImplantFn || ContraceptionFn || TypesofcontraceptionFn) {
      ShowContent(true)
    }
  }
  function contraceptionFn() {
    ShowContent(false)
    activeContraception((contraceptionFn) => !contraceptionFn)
  }
  function pulloutFn() {
    ShowContent(false)
    activeImplant((ImplantFn) => !ImplantFn)
  }
  function stiFn() {
    ShowContent(false)
    activeTypesofcontraception((TypesofcontraceptionFn) => !TypesofcontraceptionFn)
  }
  function truefn() {
    ShowFalsefn(false)
    ShowTruefn(true)
  }
  function falseFn() {
    ShowFalsefn(true)
    ShowTruefn(false)
  }
  // class STI extends Component {
  // render() {
  const tabIndex = 0
  const noTab = 1
  return (
    <div className="signposting-page">
      <Helmet>
        <title>SPT | STI - Brook</title>
      </Helmet>
      <SignPostingHeader />
      <section className="signposting-section bg-green color-white">
        <div className="content-container equal-space-padding sti-page-blk">
          <div className="container-680">
            <h1 className="page-heading mt-0">WHAT ARE SEXUALLY TRANSMITTED INFECTIONS (STIs)?</h1>
          </div>
        </div>
      </section>
      <section className="signposting-section grey">
        <div className="content-container equal-space-padding px-0">
          <div className="contianer-450 card card-style">
            <div className="card-body">
              <p className="card-text">
                A sexually transmitted infection (STI) is any kind of bacterial or viral infection that can be passed on
                through unprotected sexual contact (sex without using a condom).
              </p>
              <p>
                It doesn’t matter how many times you’ve had sex or how many partners you’ve had; anyone can get an STI.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="zig-zag-pattern sti" />
      <section className="signposting-section bg-purple">
        <div className="content-container equal-space-padding">
          <h1 className="section-head">GET A TEST</h1>
          <div className="sec-sub-desc pt-5">
            <p>
              {' '}
              To see if you can order a free STI home test kit from Brook, go to our online order form. In the kits, we
              provide a test for chlamydia and gonorrhoea.
            </p>
          </div>
          <div className="sec-sub-desc pt-5">
            <p>
              If you are not eligible for a test kit from Brook, don’t worry – we will help you find out if there is
              another provider you can order one from or help you find information about your local sexual health
              service.
            </p>
          </div>
          <div className="spacer-20" />
          <a href={appUrls.sti.home} target="_blank" className="text-decoration-home" tabIndex={noTab}>
            <button className="s-btn-clr" type="submit">
              See if you can order a free test kit
            </button>
          </a>
          <div className="spacer-20" />
        </div>
        <section>
          <div className="signposting-section bg-yellow yellow-section">
            <div className="content-container equal-space-padding">
              <h1 className="section-head">NO SYMPTOMS?</h1>
              <div className="sec-sub-desc pt-5">
                <p>
                  <strong>Lots of STIs, including chlamydia and gonorrhoea, don’t always present symptoms. </strong>
                  This means you or your partner(s) could have one without knowing!
                </p>
              </div>
              <div className="sec-sub-desc pt-5">
                <p>
                  {' '}
                  The only way to know for sure that you don’t have an STI is to take a test. It can take up to two
                  weeks for chlamydia and gonorrhoea to show, so just make sure to wait at least two weeks after
                  unprotected sex before testing.
                </p>
              </div>
              <div className="sec-sub-desc pt-5">
                <p>
                  It’s important to get tested every time you have sex with a new partner. If you are in a long-term
                  relationship it’s recommended you test about once a year.
                </p>
              </div>
              <div className="spacer-20" />
            </div>
          </div>
        </section>
        <div className="spacer-30" />
      </section>

      <div className="zig-zag-pattern color-white bg-purple" />
      <section className="signposting-section white">
        <div className="content-container equal-space-padding">
          <h1 className="section-head dark">HIV</h1>
          <div className="spacer-10" />
          <div className="sec-desc-2-color">
            <p>Another STI that you may have heard of is HIV, which stands for Human Immunodeficiency Virus. </p>
            <div className="spacer-20" />
            <p>
              {' '}
              HIV damages the body’s immune system so it cannot fight off infections. It is most commonly passed on
              through vaginal or anal sex without using a condom.{' '}
            </p>
            <div className="spacer-20" />
            <p>
              HIV is preventable and treatable, but it is not curable. HIV is treated with drugs called antiretrovirals.
              Through the use of these drugs, people living with HIV can reach a point where they are unable to pass on
              the virus and can lead happy, healthy lives.
            </p>
          </div>
          <div className="spacer-20" />
        </div>
      </section>
      <section className="signposting-section bg-pink pink-blk">
        <div className="content-container equal-space-padding container-para">
          <div className="spacer-10" />
          <div className="d-flex">
            <img src={Exclamation} alt="Exclamation" />
            <div className="sec-desc-2-color white ml-3">
              <p>
                If you think you may have been exposed to HIV within the last 72 hours (three days), it is possible to
                take anti-HIV medication called PEP (post-exposure prophylaxis) which may stop you becoming infected.
              </p>
              <div className="spacer-10" />
              <a
                className="text-decoration-home"
                tabIndex={noTab}
                href="https://www.brook.org.uk/your-life/prep-and-pep/#whatispep"
                target="_blank"
                rel="noreferrer"
              >
                <button className="pink-btn-class" type="submit">
                  I have found out that I might be at risk of HIV
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="signposting-section white">
        <div className="content-container equal-space-padding">
          <div className="d-flex img-adjust-pos-rel">
            <h1 className="section-head dark">CHARLIE’S DILEMMA</h1>
            <div className="pos-abl">
              <img className="float-right" src={PersonIcon} alt="PersonIcon" />
            </div>
          </div>
          <div className="spacer-10" />
          <div className="sec-desc-2-color m-desc-top">
            <p className="sub-para">
              “I just got a diagnosis of HIV. I have my appointment to talk with the nurse about it tomorrow and I’m
              really scared about what they are going to say. I don’t know anyone who has it, I don’t even know who gave
              it to me, and now I’m going to need to contact everyone I’ve slept with which I’m dreading doing. I’m
              worried about what this means for my health, and whether I will be able to have sex with people without
              passing it on.”{' '}
            </p>
            <div className="spacer-20" />
            <div className="dotted-border dark" />
          </div>
        </div>
      </section>
      <section className="signposting-section white">
        <div className="content-container equal-space-padding sec-desc-2-color pt-0">
          <h1 className="section-head dark">WHAT SHOULD CHARLIE DO?</h1>
          <div className="sec-desc-2-color">
            <div className="spacer-20" />
            <p>Select all the answers you think are correct</p>
          </div>
          <div className="spacer-20" />
          <a className="text-decoration-home">
            <button
              type="button"
              className={'content-button mb-4 btn-lg btn-block' + (ContraceptionFn ? ' triple-btn' : '')}
              onClick={contraceptionFn}
            >
              Speak to the nurse about his worries{' '}
            </button>
          </a>
          <a className="text-decoration-home">
            <button
              type="button"
              className={'content-button mb-4 btn-lg btn-block' + (ImplantFn ? ' triple-btn' : '')}
              onClick={pulloutFn}
            >
              Cancel the appointment and avoid having sex ever again{' '}
            </button>
          </a>
          <a className="text-decoration-home">
            <button
              type="button"
              className={'content-button mb-4 btn-lg btn-block' + (TypesofcontraceptionFn ? ' triple-btn' : '')}
              onClick={stiFn}
            >
              Do some research about living with HIV{' '}
            </button>
          </a>
          <a className="text-decoration-home" href="#gotoanswer" tabIndex={noTab} onClick={submitFn}>
            <button className="s-btn-clr mb-3" type="submit">
              <b>Submit</b>
            </button>
          </a>
          <div className="spacer-10" />
          {/* {!SubmitFn &&
                        <div className='sec-desc-2-color'>
                            <p>Select as many as you think!</p>
                        </div>
                    } */}
          {SubmitFn && (contraceptionFn || ImplantFn || TypesofcontraceptionFn) && (
            <div className="answer" id="gotoanswer">
              <div className="spacer-20" />
              <p className="view-answer">ANSWER</p>
              <p>
                Although this is a very difficult and scary time for Charlie, it is important that he keeps his
                appointment with the specialist nurse as it is an opportunity to ask any questions he has about HIV and
                living with HIV. He will also be able to seek support with contacting his previous sexual partners.
              </p>
              <div className="spacer-20" />
              <p>
                It is likely that he will be referred to a HIV treatment service to begin treatment, known as
                antiretroviral therapy (ART) or highly active antiretroviral therapy (HAART), straight away. This
                treatment can't cure HIV but it can help Charlie to live a longer, healthier life. It will also help
                Charlie have a safe and fulfilling sex life as, when the viral load becomes undetectable, it will stop
                the transmission of HIV to his partners.
              </p>
              <div className="spacer-20" />
              <p>
                There are lots of organisations that might be able to offer Charlie additional help and someone to talk
                to. These organisations are often run by people living with HIV and their friends and families, meaning
                they will understand what Charlie is going through and provide him with the support he needs.
              </p>
              <div className="spacer-20" />
              <div className="support-section">
                <p>
                  <strong>HIV SUPPORT ORGANISATIONS</strong>
                </p>
                <div className="spacer-20" />
                <p>
                  <strong>ViiV: </strong>Developed with people living with HIV (PLHIV) for PLHIV, the ViiV website aims
                  to provide trusted information based on current research and insight from HIV communities, including
                  individuals who wish to share their stories.{' '}
                  <a
                    href="https://viivhealthcare.com/living-with-hiv/"
                    className="text-decoration-home"
                    target="_blank"
                  >
                    <span>Visit the ViiV website </span>{' '}
                  </a>
                </p>
                <div className="spacer-20" />
                <p>
                  <strong>Terrence Higgins Trust: </strong>The UKs leading HIV charity, Terrence Higgins Trust (THT)
                  provide information, support and resources for people living with HIV and those newly diagnosed.{' '}
                  <a href="https://www.tht.org.uk/" target="_blank">
                    <span> Visit the THT website </span>
                  </a>
                </p>
                <div className="spacer-20" />
                <p>
                  <strong>CHIVA: </strong>
                  CHIVA’s mission is to ensure that children, young people and families living with HIV become
                  healthier, happier and more in control of their own futures.{' '}
                  <a href="https://www.chiva.org.uk/" className="text-decoration-home" target="_blank">
                    <span>Visit the CHIVA website</span>
                  </a>
                </p>
                <div className="spacer-20" />
                <p>
                  <strong>Positively UK: </strong>
                  Positively UK provides peer-led support, advocacy and information to everyone living with HIV to
                  effectively manage any aspect of their diagnosis, care and life with HIV.{' '}
                  <a href="  https://positivelyuk.org/" className="text-decoration-home" target="_blank">
                    <span>Visit the Positively UK website</span>
                  </a>
                </p>
              </div>
            </div>
          )}
          <div className="spacer-20" />
        </div>
      </section>
      <section className={TrueFn || FalseFn ? 'signposting-section bg-purple pb-5' : 'signposting-section bg-purple'}>
        <div className="content-container equal-space-padding pb-0">
          <h1 className="section-head" aria-label="MYTH BUSTING">
            MYTHBUSTING
          </h1>
          <div className="spacer-20" />
          <div className="pos-relative">
            <div className="s-body bubble-box"> STIs</div>
            <div className="chat-bubble-arrow chat-green" />
            <div className="spacer-10" />
            <div className="myth-chat-bubble chat-bubble-border ">“You can only get an STI from penetrative sex”</div>
          </div>
          <div className="container-450">
            {!TrueFn && !FalseFn ? (
              <div>
                <div className="spacer-40" />
                <p className="section-desc section-desc-2-color">What do you think?</p>
                <div className="spacer-10" />
                <div className="dotted-border" />
              </div>
            ) : (
              ''
            )}
            <div className="true-false-blk">
              <button className="s-btn-clr m-0" type="submit" onClick={truefn}>
                True
              </button>
              <p>or</p>
              <button className="s-btn-clr" type="submit" onClick={falseFn}>
                False
              </button>
            </div>
            {TrueFn && (
              <div>
                <False type="sti-1" />
              </div>
            )}
            {FalseFn && (
              <div>
                <False type="sti-1" />
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="signposting-section white">
        <div className="content-container equal-space-padding">
          <h1 className="section-head dark">NEED URGENT HELP?</h1>
          <div className="spacer-10" />
          <div className="section-desc sec-desc-2-color">
            If you are worried about yourself or someone else, there are lots of places that can offer help and support.
          </div>
          <div className="spacer-20" />
          <a className="mr-3 s-btn-clr" href={appUrls.signPosting.help}>
            Find help
          </a>
        </div>
      </section>
      <section className="signposting-section bg-purple">
        <div className="content-container equal-space-padding pt-0">
          <div className="spacer-20" />
          <h1 className="section-head">OTHER PEOPLE ASK…</h1>
          <div className="spacer-20" />
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.brook.org.uk/your-life/having-sex-for-the-first-time/"
              className="ask-link"
            >
              I want to know more about having sex for the first time
            </a>
          </div>
          <div className="spacer-10" />
          <div>
            <a target="_blank" rel="noreferrer" href="https://www.brook.org.uk/topics/stis/" className="ask-link">
              {' '}
              I want to know more about STIs and getting tested{' '}
            </a>
          </div>
          <div className="spacer-10" />
          <div>
            <a target="_blank" rel="noreferrer" href="https://www.brook.org.uk/topics/sex/" className="ask-link">
              I want to prepare myself for having sex with someone new
            </a>
          </div>
          <div className="spacer-10" />
          {/* <div>
                        <Link className='ask-link' to={appUrls.signPosting.help}>I'm concerned about myself or someone else</Link>
                    </div> */}
          <div className="spacer-30" />
          <div className="dotted-border" />
        </div>
      </section>
      <SignPostingFooter />
    </div>
  )
  // }
}
export default STI
