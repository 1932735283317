import React, { useCallback, useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import '../styles/eligibilitypass.css'
import { stiKitLoaderPercentage } from '../utils/stiKitLoaderPercentage'

interface TestKitFromBrookProps {
  children: any
}

const data = [
  {
    title: 'a [vaginal swab/urine sample] test for chlamydia and gonorrhoea',
  },
  {
    title: 'additional throat and rectal swabs.',
  },
]
const riskHepatitisOptionKey = 'one or more of these is true for me - please test my blood sample for hepatitis'
const throatRectumKey = 'I also need swab for my throat and rectum'
const hiv_syphilis_key = 'I want to do the blood sample for both HIV and syphilis'
const syphilis_only_key = 'I want to test for syphilis but not hiv'

export const TestKitFromBrook: React.FC<TestKitFromBrookProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)
  // const [isPositiveTestKitContent, setPositiveTestKitContent] = useState<boolean>(false)
  const history = useHistory()

  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('testKit_id')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  useEffect(() => {
    const { quesObj } = theme
    // console.log('log test kit :>>', quesObj)
    // if (quesObj && quesObj.riskHepatitis && quesObj.riskHepatitis === riskHepatitisOptionKey) {
    //   setPositiveTestKitContent(true)
    // }
    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: stiKitLoaderPercentage.TEST_KIT },
    })
  }, [])

  const proceed = useCallback(() => {
    TextToSpeech.getInstance().playVoice(null, 'stop')
    setisAccessibilityVoiceOver(false)
    // history.push('/edit-kit')
    history.push('/preContact')
  }, [])

  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }

  const renderThroatRectumYesView = () => {
    const { quesObj } = theme
    // const { riskHepatitis } = quesObj
    const genitalsText = quesObj['What genitals do you have?'] === 'Penis' ? 'urine sample' : 'vaginal swab'

        // my throat and rectum - yes && both hiv and syphilis - yes
        if (quesObj[hiv_syphilis_key] === 'yes'  ) {
          return (
            <div>
              <div className="b1-style">Your test kit from Brook</div>
              <h5 className="b2-style">We will send you:</h5>
              <ul className="br-order-list">
                <li className={`b2-style ${theme.theme}-txt-global`}>a {genitalsText} test for chlamydia and gonorrhoea</li>
                <li className={`b2-style ${theme.theme}-txt-global`}>additional throat and rectal swabs</li>
                <li className={`b2-style ${theme.theme}-txt-global`}>a blood test for HIV and syphilis</li>
              </ul>
              <h5 className="p2-style">
                You're nearly finished with this order now, there are just a few final questions so we know where to send
                your kit.
              </h5>
            </div>
          )
        }

         // my throat and rectum - yes && syphilis - yes
    if (quesObj[syphilis_only_key] === 'yes'  ) {
      return (
        <div>
          <div className="b1-style">Your test kit from Brook</div>
          <h5 className="b2-style">We will send you:</h5>
          <ul className="br-order-list">
            <li className={`b2-style ${theme.theme}-txt-global`}>a {genitalsText} test for chlamydia and gonorrhoea</li>
            <li className={`b2-style ${theme.theme}-txt-global`}>additional throat and rectal swabs</li>
            <li className={`b2-style ${theme.theme}-txt-global`}>a blood test for syphilis</li>
          </ul>
          <h5 className="p2-style">
            You're nearly finished with this order now, there are just a few final questions so we know where to send
            your kit.
          </h5>
        </div>
      )
    }

    // my throat and rectum - yes && both hiv and syphilis - yes && risk hepatitis - no option
    // if (quesObj[hiv_syphilis_key] === 'yes' && riskHepatitis && riskHepatitis !== riskHepatitisOptionKey) {
    //   return (
    //     <div>
    //       <div className="b1-style">Your test kit from Brook</div>
    //       <h5 className="b2-style">We will send you:</h5>
    //       <ul className="br-order-list">
    //         <li className={`b2-style ${theme.theme}-txt-global`}>a {genitalsText} test for chlamydia and gonorrhoea</li>
    //         <li className={`b2-style ${theme.theme}-txt-global`}>additional throat and rectal swabs</li>
    //         <li className={`b2-style ${theme.theme}-txt-global`}>a blood test for HIV and syphilis</li>
    //       </ul>
    //       <h5 className="p2-style">
    //         You're nearly finished with this order now, there are just a few final questions so we know where to send
    //         your kit.
    //       </h5>
    //     </div>
    //   )
    // }

    // my throat and rectum - yes && both hiv and syphilis - yes && risk hepatitis - yes option
    // if (quesObj[hiv_syphilis_key] === 'yes' && riskHepatitis && riskHepatitis === riskHepatitisOptionKey) {
    //   return (
    //     <div>
    //       <div className="b1-style">Your test kit from Brook</div>
    //       <h5 className="b2-style">We will send you:</h5>
    //       <ul className="br-order-list">
    //         <li className={`b2-style ${theme.theme}-txt-global`}>a {genitalsText} test for chlamydia and gonorrhoea</li>
    //         <li className={`b2-style ${theme.theme}-txt-global`}>additional throat and rectal swabs</li>
    //         <li className={`b2-style ${theme.theme}-txt-global`}>a blood test for HIV, syphilis, Hepatits B and Hepatitis C</li>
    //       </ul>
    //       <h5 className="p2-style">
    //         You're nearly finished with this order now, there are just a few final questions so we know where to send
    //         your kit.
    //       </h5>
    //     </div>
    //   )
    // }

    // my throat and rectum - yes && syphilis - yes && risk hepatitis - no option
    // if (quesObj[syphilis_only_key] === 'yes' && riskHepatitis && riskHepatitis !== riskHepatitisOptionKey) {
    //   return (
    //     <div>
    //       <div className="b1-style">Your test kit from Brook</div>
    //       <h5 className="b2-style">We will send you:</h5>
    //       <ul className="br-order-list">
    //         <li className={`b2-style ${theme.theme}-txt-global`}>a {genitalsText} test for chlamydia and gonorrhoea</li>
    //         <li className={`b2-style ${theme.theme}-txt-global`}>additional throat and rectal swabs</li>
    //         <li className={`b2-style ${theme.theme}-txt-global`}>a blood test for syphilis</li>
    //       </ul>
    //       <h5 className="p2-style">
    //         You're nearly finished with this order now, there are just a few final questions so we know where to send
    //         your kit.
    //       </h5>
    //     </div>
    //   )
    // }

    // my throat and rectum - yes && syphilis - yes && risk hepatitis - yes option
    // if (quesObj[syphilis_only_key] === 'yes' && riskHepatitis && riskHepatitis === riskHepatitisOptionKey) {
    //   return (
    //     <div>
    //       <div className="b1-style">Your test kit from Brook</div>
    //       <h5 className="b2-style">We will send you:</h5>
    //       <ul className="br-order-list">
    //         <li className={`b2-style ${theme.theme}-txt-global`}>a {genitalsText} test for chlamydia and gonorrhoea</li>
    //         <li className={`b2-style ${theme.theme}-txt-global`}>additional throat and rectal swabs</li>
    //         <li className={`b2-style ${theme.theme}-txt-global`}>a blood test for syphilis, Hepatitis B and Hepatitis C</li>
    //       </ul>
    //       <h5 className="p2-style">
    //         You're nearly finished with this order now, there are just a few final questions so we know where to send
    //         your kit.
    //       </h5>
    //     </div>
    //   )
    // }

    // my throat and rectum - yes && skip both option hiv and syphilis & syphilis only
    return (
      <div>
        <div className="b1-style">Your test kit from Brook</div>
        <h5 className="b2-style">We will send you:</h5>
        <ul className="br-order-list">
          <li className={`b2-style ${theme.theme}-txt-global`}>a {genitalsText} test for chlamydia and gonorrhoea</li>
          <li className={`b2-style ${theme.theme}-txt-global`}>additional throat and rectal swabs</li>
        </ul>
        <h5 className="p2-style">
          You're nearly finished with this order now, there are just a few final questions so we know where to send your
          kit.
        </h5>
      </div>
    )
  }

  const renderThroatRectumNoFlow = () => {
    const { quesObj } = theme
    // const { riskHepatitis } = quesObj
    const genitalsText = quesObj['What genitals do you have?'] === 'Penis' ? 'urine sample' : 'vaginal swab'

     // my throat and rectum - yes && both hiv and syphilis - yes
     if (quesObj[hiv_syphilis_key] === 'yes') {
      return (
        <div>
          <div className="b1-style">Your test kit from Brook</div>
          <h5 className="b4-style">We will send you:</h5>
          <ul className="br-order-list">
            <li className={`b2-style ${theme.theme}-txt-global`}>a {genitalsText} test for chlamydia and gonorrhoea</li>
            <li className={`b2-style ${theme.theme}-txt-global`}>a blood test for HIV and syphilis</li>
          </ul>
          <h5 className="p2-style">
            You're nearly finished with this order now, there are just a few final questions so we know where to send
            your kit.
          </h5>
        </div>
      )
    }


    // my throat and rectum - no && syphilis - yes
    if (quesObj[syphilis_only_key] === 'yes') {
      return (
        <div>
          <div className="b1-style">Your test kit from Brook</div>
          <h5 className="b2-style">We will send you:</h5>
          <ul className="br-order-list">
            <li className={`b2-style ${theme.theme}-txt-global`}>a {genitalsText} test for chlamydia and gonorrhoea</li>
            <li className={`b2-style ${theme.theme}-txt-global`}>a blood test for syphilis</li>
          </ul>
          <h5 className="p2-style">
            You're nearly finished with this order now, there are just a few final questions so we know where to send
            your kit.
          </h5>
        </div>
      )
    }

    // my throat and rectum - yes && both hiv and syphilis - yes && risk hepatitis - yes option
    // if (quesObj[hiv_syphilis_key] === 'yes' && riskHepatitis && riskHepatitis === riskHepatitisOptionKey) {
    //   return (
    //     <div>
    //       <div className="b1-style">Your test kit from Brook</div>
    //       <h5 className="b4-style">We will send you:</h5>
    //       <ul className="br-order-list">
    //         <li className={`b2-style ${theme.theme}-txt-global`}>a {genitalsText} test for chlamydia and gonorrhoea</li>
    //         <li className={`b2-style ${theme.theme}-txt-global`}>a blood test for HIV, syphilis, Hepatits B and Hepatitis C</li>
    //       </ul>
    //       <h5 className="p2-style">
    //         You're nearly finished with this order now, there are just a few final questions so we know where to send
    //         your kit.
    //       </h5>
    //     </div>
    //   )
    // }

    // my throat and rectum - no && both hiv and syphilis - yes && risk hepatitis - no option
    // if (quesObj[hiv_syphilis_key] === 'yes' && riskHepatitis && riskHepatitis !== riskHepatitisOptionKey) {
    //   return (
    //     <div>
    //       <div className="b1-style">Your test kit from Brook</div>
    //       <h5 className="b2-style">We will send you:</h5>
    //       <ul className="br-order-list">
    //         <li className={`b2-style ${theme.theme}-txt-global`}>a {genitalsText} test for chlamydia and gonorrhoea</li>
    //         <li className={`b2-style ${theme.theme}-txt-global`}>a blood test for HIV and syphilis</li>
    //       </ul>
    //       <h5 className="p2-style">
    //         You're nearly finished with this order now, there are just a few final questions so we know where to send
    //         your kit.
    //       </h5>
    //     </div>
    //   )
    // }

    // my throat and rectum - no && syphilis - yes && risk hepatitis - no option
    // if (quesObj[syphilis_only_key] === 'yes' && riskHepatitis && riskHepatitis !== riskHepatitisOptionKey) {
    //   return (
    //     <div>
    //       <div className="b1-style">Your test kit from Brook</div>
    //       <h5 className="b2-style">We will send you:</h5>
    //       <ul className="br-order-list">
    //         <li className={`b2-style ${theme.theme}-txt-global`}>a {genitalsText} test for chlamydia and gonorrhoea</li>
    //         <li className={`b2-style ${theme.theme}-txt-global`}>a blood test for syphilis</li>
    //       </ul>
    //       <h5 className="p2-style">
    //         You're nearly finished with this order now, there are just a few final questions so we know where to send
    //         your kit.
    //       </h5>
    //     </div>
    //   )
    // }

    // my throat and rectum - no && syphilis - yes && risk hepatitis - yes option
    // if (quesObj[syphilis_only_key] === 'yes' && riskHepatitis && riskHepatitis === riskHepatitisOptionKey) {
    //   return (
    //     <div>
    //       <div className="b1-style">Your test kit from Brook</div>
    //       <h5 className="b2-style">We will send you:</h5>
    //       <ul className="br-order-list">
    //         <li className={`b2-style ${theme.theme}-txt-global`}>a {genitalsText} test for chlamydia and gonorrhoea</li>
    //         <li className={`b2-style ${theme.theme}-txt-global`}>a blood test for syphilis, Hepatitis B and Hepatitis C</li>
    //       </ul>
    //       <h5 className="p2-style">
    //         You're nearly finished with this order now, there are just a few final questions so we know where to send
    //         your kit.
    //       </h5>
    //     </div>
    //   )
    // }

    // my throat and rectum - no && skip both option hiv and syphilis & syphilis only
    return (
      <div>
        <div className="b1-style">Your test kit from Brook</div>
        <h5 className="b2-style">We will send you a {genitalsText} test for chlamydia and gonorrhoea</h5>
        <h5 className="p2-style">
          You're nearly finished with this order now, there are just a few final questions so we know where to send your
          kit.
        </h5>
      </div>
    )
  }
  return (
    <div className="bg-color elgibilitypass-style">
      <div className="brook-container pad-bot">
        <div className="text-to-speech">
          <div className="h-div mt-4">
            <h2 className="page-header-txt">ORDER A TEST KIT</h2>
            <div className="speaker-i">
              <button
                className="no-background-border"
                onClick={(e) => {
                  TextToSpeech.getInstance().playVoice('text-to-speech')
                  setisAccessibilityVoiceOver(true)
                }}
              >
                <img src={Icon} alt="speaker" />
              </button>
            </div>
          </div>
          {/* {isPositiveTestKitContent ? renderYesOptionView() : renderNoOptionView()} */}
          {theme.quesObj && theme.quesObj[throatRectumKey] === 'yes'
            ? renderThroatRectumYesView()
            : renderThroatRectumNoFlow()}
        </div>
        <div className="ft-tab brook-container">
          <button id="testKit_id" className="float-left no-background-border" onClick={(e) => GoBackPage()}>
            <img src={backIcon} alt="back" />
          </button>
          <Button
            isDisabled={false}
            buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
            buttonText="Next"
            onClick={(e) => proceed()}
          />
        </div>
      </div>
    </div>
  )
}
