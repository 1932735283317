import React, { useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import '../styles/hepatitis.css'
import { stiKitLoaderPercentage } from '../utils/stiKitLoaderPercentage'

const hypetitisContent = [
  {
    title: 'You inject drugs or have a partner who injects drugs',
  },
  {
    title: 'You change your sexual partners frequently',
  },
  {
    title: 'You are a man or person with a penis who has sex with other men or people with penises',
  },
  {
    title: 'A close family member or sexual partner has Hepatitis B',
  },
  {
    title: 'You travel to countries where Hepatitis B is common',
  },
  {
    title:
      'You have sexual contact with people living in (or who have recently travelled from) countries where Hepatitis B is common',
  },
  {
    title: 'You work in the sex industry',
  },
]

interface HepatitisVaccinationProps {
  Props: any
}

export const HepatitisVaccination: React.FC<HepatitisVaccinationProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)
  const history = useHistory()

  useEffect(() => {
    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: stiKitLoaderPercentage.HEPATITIS_VACCINATION },
    })
  }, [])
  const handleOnNext = () => {
    history.push('/test-kit')
  }

  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }
  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('vaccination_id')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  return (
    <div className={`bg-color hepatitis-style ${theme.theme}-global`}>
      <div className="brook-container pad-bot">
        <div className="text-to-speech">
          <div className="h-div mt-4">
            <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
            <div className="speaker-i">
              <button
                className="no-background-border"
                onClick={(e) => {
                  TextToSpeech.getInstance().playVoice('text-to-speech')
                  setisAccessibilityVoiceOver(true)
                }}
              >
                <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
              </button>
            </div>
          </div>
          <div className="hepatitis-content-blk">
            <h1 className={`b1-style  ${theme.theme}-txt-global`}>Hepatitis B vaccination</h1>
            <h4 className={`b4-style ${theme.theme}-txt-global hepatitis-subtitle`}>
              From what you've told us, it would be a good idea to get tested for hepatitis - we'll recommend that on
              the next page.
            </h4>
            <h5 className={`b4-style ${theme.theme}-txt-global hepatitis-subtitle my-3`}>
              You might also want to consider getting vaccinated against Hepatitis B.
            </h5>
            <h5 className={`b4-style ${theme.theme}-txt-global hepatitis-subtitle my-3`}>
              The NHS recommends getting a Hepatitis B vaccine if:
            </h5>
            <ul>
              {hypetitisContent.map((item: any, idx: number) => {
                return (
                  <li
                    key={`hepatitis-${idx}`}
                    className={`b1-style ${theme.theme}-txt-global label-font hepatitis-title`}
                  >
                    {item.title}
                  </li>
                )
              })}
            </ul>
            <h4 className={`b4-style ${theme.theme}-txt-global hepatitis-subtitle`}>
              If you think you're at risk and need the Hepatitis B vaccine, ask your GP to vaccinate you, or visit any
              sexual health or genitourinary medicine (GUM) clinic.
            </h4>
            <div className={`ft-tab brook-container ${theme.theme}-skin-color`}>
              <button
                id="vaccination_id"
                className="float-left no-background-border pos-re"
                onClick={(e) => GoBackPage()}
              >
                <img src={backIcon} alt="back" />
              </button>
              <Button
                isDisabled={false}
                buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                buttonText="Next"
                onClick={handleOnNext}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
