
export const getTFourPlusDisease = (theme: any) => {
  
  const { local_authority,postcode, age } = theme;

  const tfourPlusLocation = process.env.REACT_APP_T4_POSTCODE_VALUE;
  const locationList = tfourPlusLocation ? tfourPlusLocation.split(",").map(item => item.toLowerCase()) : []
  const shortPostCode = postcode.substring(0,3);
  // if(tfourPlusLocation){
    const filterLoc = locationList.filter((loc)=>{return loc === local_authority.toLowerCase()})
    if (filterLoc?.length>0) {
      return true;
    } 
    else if(local_authority === process.env.REACT_APP_AGE_RESTRICTION_POSTCODE_VALUE){
      return 'isManchester'
    }
    else {
      return false;
    }
}