import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'

import '../styles/TriNav.css'
import apiFunctions from '../../services/api-service'



export interface MenuListProps {
  label: string
  link: string
  isActive?: boolean
  isPath?: string
}

interface TriNavProps {
  getMenuList?: Array<MenuListProps>
  children?: any
  setFilter?: React.SetStateAction<any>
}

export const TriNav: React.FC<TriNavProps> = ({ children, getMenuList, setFilter }) => {
  const [ref, setref] = useState(true);
  const [countInitial, setCountInitial] = useState({
    flaggeCount:0,
    positiveCount:0,
    negativeCount:0
  })
  const filterValue = new URL(window.location.href).searchParams.get('filters');
  useEffect(() => {
    setref(!ref)
  }, [getMenuList])

  useEffect(() => {
    if (window.location.href.includes('order-kit')) {
      setFilter(new URL(window.location.href).searchParams.get('filters'))

    }
 
  },[]);



  useEffect(()=>{
    getDataCount();
    if(filterValue === 'Flagged' || filterValue === 'Positive' || filterValue === 'Negative'){
      updateNotifications();
    }
  },[filterValue])


const updateNotifications = async()=>{
  let URL= process.env.REACT_APP_SERVICE_BACKEND_URL + `/admin/update-notification-markers?marker_type=${filterValue}`

  try{
    const result = await apiFunctions.put(URL,{date: new Date().getTime()});
    await getDataCount();
  }catch(error){
    console.log("----error-----",error);
  }
}

  const getDataCount= async()=>{
    let URL= process.env.REACT_APP_SERVICE_BACKEND_URL + `/admin/get-notification-markers`
    try{
      const result = await apiFunctions.get(URL);
      setCountInitial({
        ...countInitial,
        flaggeCount:result.data.data.flagged,
        positiveCount:result.data.data.positive,
        negativeCount:result.data.data.negative,
      })

    }catch(error){
      console.log("----error-----",error);
    }
  }

  return (
    <>
      <div className="tri-nav-wrap">
        <div className="tri-nav-blk">
          {getMenuList &&
            getMenuList.map((menu, index) => (
              <Link
                onClick={(e) => {
                  if (setFilter) {
                    setFilter(menu.label)
                  }
                }}
                key={index}
                to={menu.link}
                className={`notification ${window.location.href.includes(menu.link.substring(menu.link.lastIndexOf('/') + 1)) ||
                  (menu.isPath && window.location.href.includes(menu.isPath))
                  ? 'active'
                  : ''
                  }`}
              >
                {menu.label}
                {(menu.label === 'Flagged' && countInitial.flaggeCount >0) || (menu.label === 'Positive' && countInitial.positiveCount > 0) || (menu.label === 'Negative' && countInitial.negativeCount >0 ) ?

                 <span className="badge">{ menu.label === 'Flagged' ? countInitial.flaggeCount: menu.label === 'Positive'? countInitial.positiveCount : countInitial.negativeCount}</span> : 
                 null}
              </Link>
            ))}
        </div>
        <hr />
        <div className="right-content">{children}</div>
      </div>
    </>
  )
}
