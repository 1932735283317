import React, { useState, useEffect, useRef, forwardRef } from 'react'

import '../../styles/SignpostingToolStyle/home.css'
import '../../styles/SignpostingToolStyle/havingsex.css'
import '../../styles/SignpostingToolStyle/emergencycontracption.css'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { Helmet } from 'react-helmet-async'
import Calendar from 'react-multi-date-picker-calendar'
// import { Calendar } from 'react-multi-date-picker'
import { Link } from 'react-router-dom'

import backbutton from '../../assets/images/Back_Button.svg'
import downArrow from '../../assets/images/down-arrow.svg'
import dropdown_btn from '../../assets/images/dropdown_black_arrow.svg'
import DropDownIcon from '../../assets/images/DropDownIcon'
import tabletIcon from '../../assets/images/emergency-tablet-icon.png'
import PersonIcon from '../../assets/images/person-icon3.png'
import Rating from '../../components/Rating/rating.js'
import '../../components/styles/rating.css'
import appUrls from '../../services/AppURLs'
import { False } from './False'
import { SignPostingFooter } from './SignPostingFooter'
import { SignPostingHeader } from './SignPostingHeader'

export function EmergencyContraception({}) {
  // const [dateRange, setDateRange] = useState([null, null]);
  // const [startDate, endDate] = dateRange;
  const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop)
  const showBubble = useRef(null)
  const [startTime, setstartTime] = useState(new Date())
  const [showPregnancyFlow, ShowPreganancyFlow] = useState(false)
  const [startPreganancyCalculator, StartPreganancyCalculator] = useState(true)
  const [startPreganancy, setIsOpened] = useState(false)
  const [iUnderstand, Iunderstand] = useState(false)
  const [yesUnderstand, Yesunderstand] = useState(false)
  const [NoContraceptive, Nocontraceptive] = useState(false)
  const [showDatePd, setshowDatePd] = useState(false)
  const [ShowThanks, Showthanks] = useState(false)
  const [ShowMe, Showme] = useState(false)
  const [ShowTime, Showtime] = useState(false)
  const [ShowDay, Showday] = useState(false)
  const [monthpopup, Setmonthpopup] = useState(false)
  const [ShowFeedback, SetShowFeedback] = useState(false)
  const [calenderView, setCalenderView] = useState(false)
  const [feedbackexit, FeedbackExit] = useState(false)
  const [showdes, ShowDes] = useState(false)
  const [showec, ShowEc] = useState(false)
  const [showIUD, SetShowIUD] = useState(false)
  const [showUPA, SetShowUPA] = useState(false)
  const [showLNG, SetShowLNG] = useState(false)
  const [postHigh, SetPostHigh] = useState(false)
  const [preHigh, SetPreHigh] = useState(false)
  const [unableResult, SetUnableResult] = useState(false)
  const [showContact, SetContact] = useState(false)
  const [showOvulation, SetOvulation] = useState(false)
  const [showOptions, SetMoreOptions] = useState(false)
  const [showUnderstandOptions, SetUnderstandOptions] = useState(true)
  const [showSuggestOptions, SetSuggestOptions] = useState(true)
  const [showIudOptions, SetIudOptions] = useState(true)
  const [showPillOptions, SetPillOptions] = useState(true)
  const [nearestUPS, SetnearestUPS] = useState('')

  const [Queanswers, setQueanswers] = useState({
    Q1: '',
    Q2: '',
    Q3: {},
    Q4: '',
    Q5: '',
    Q6: '',
    Q7: moment(new Date()).format('h:mm A'),
    Q8: '',
  })
  // const [showTime, SetShowTime] = useState(new Date());
  const [TrueFn, ShowTruefn] = useState(false)
  const [FalseFn, ShowFalsefn] = useState(false)
  // const MinDate = moment().subtract(21, 'days').format('YYYY/MM/DD')
  const MinDate = moment().subtract(40, 'days').format('YYYY/MM/DD')
  const MaxDate = moment().format('YYYY/MM/DD')
  const [FResult, setFResult] = useState({ result: '', status: '', type: '' })
  const [showQ2yes, setshowQ2yes] = useState(false)
  const [mightdonow, Setmightdonow] = useState(false)
  const [SubmitFn, ShowContent] = useState(false)
  const [ContraceptionFn, activeContraception] = useState(false)
  const [ImplantFn, activeImplant] = useState(false)
  const [TypesofcontraceptionFn, activeTypesofcontraception] = useState(false)
  const [Emergencyhormonal, setEmergencyhormonal] = useState(false)
  const [EmergencyhormonalYes, SetEmergencyhormonalYes] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const [changeDate, setChangeDate] = useState(false)
  const CustomInput = forwardRef(({ value, onClick }: any) => (
    <input className="custom-input onkeyup" aria-label="custom" inputMode="none" value={value} onClick={onClick} />
  ))

  const options = []
  for (let i = 20; i <= 60; i++) {
    options.push({ value: i, label: i })
  }

  const onChange = (dates: any, type: any) => {
    if (type === 'back' || type === 'q5') {
      let answers_back = { ...Queanswers }
      if (type === 'back') {
        answers_back.Q4 = ''
      }
      if (type === 'q5') {
        answers_back.Q5 = moment(new Date()).format('HH:mm')
      }
      setQueanswers(answers_back)
    } else {
      let AllDates: any = []
      for (let d = 0; d < dates.length; d++) {
        AllDates.push(moment(new Date(dates[d])).format('YYYY-MM-DD'))
      }
      let answers = { ...Queanswers }
      answers.Q4 = AllDates
      setQueanswers(answers)
    }
  }
  function ratingCallBack(props: any) {
    console.log(props)
  }
  function Continue() {
    Nocontraceptive(false)
    setshowDatePd(true)
  }
  function Next() {
    setshowDatePd(false)
    Showday(true)
  }
  function showQuestionYes(type: any) {
    setshowQ2yes(false)
    let answers = { ...Queanswers }
    answers.Q3 = type
    setQueanswers(answers)
    if (type === 'no') {
      SetShowFeedback(true)
    } else {
      Nocontraceptive(true)
    }
  }
  function ShowChange(dates: any, type: any) {
    if (type === 'no') {
      setFResult({ result: 'unable', status: '', type: 'E5' })
      Showme(true)
      setshowDatePd(false)
      return
    }
    let answers = { ...Queanswers }
    answers.Q5 = type === 'yes' ? moment(new Date(dates)).format('YYYY-MM-DD') : 'not_sure'
    setQueanswers(answers)
    // Showday(true)
    // setshowDatePd(false)
  }
  function submitFn() {
    if (ImplantFn || ContraceptionFn || TypesofcontraceptionFn) {
      ShowContent(true)
    }
  }
  function contraceptionFn() {
    ShowContent(false)
    activeContraception((contraceptionFn) => !contraceptionFn)
  }
  function pulloutFn() {
    ShowContent(false)
    activeImplant((ImplantFn) => !ImplantFn)
  }
  function stiFn() {
    ShowContent(false)
    activeTypesofcontraception((TypesofcontraceptionFn) => !TypesofcontraceptionFn)
  }
  function toggle() {
    StartPreganancyCalculator(false)
    ShowPreganancyFlow(true)
    setIsOpened(true)
  }
  function understand() {
    setIsOpened(false)
    Iunderstand(true)
  }
  function showDes() {
    ShowDes((showdes) => !showdes)
  }

  function showEc() {
    ShowEc((showec) => !showec)
  }

  // function showcontent() {
  //     SetShowContent(true)
  // }

  function iud() {
    SetShowIUD((showiud) => !showiud)
  }
  function upa() {
    SetShowUPA((showupa) => !showupa)
  }
  function lng() {
    SetShowLNG((showlng) => !showlng)
  }
  function posthigh() {
    SetPostHigh(true)
    SetPillOptions(false)
  }
  function contactDoctor() {
    SetContact((showContact) => !showContact)
  }
  function ovulation() {
    SetOvulation((showOvulation) => !showOvulation)
  }
  function prehigh() {
    SetPreHigh(true)
    SetIudOptions(false)
  }
  function unableresult() {
    SetUnableResult(true)
    SetSuggestOptions(false)
  }
  function feedbackExit() {
    Setmonthpopup(false)
    SetShowFeedback(false)
    SetEmergencyhormonalYes(false)
    Showme(false)
    FeedbackExit(true)
  }
  function gobacktounderstand() {
    setIsOpened(true)
    Iunderstand(false)
    Nocontraceptive(false)
  }

  function showMoreOptions() {
    SetMoreOptions(true)
    SetUnderstandOptions(false)
    // if ((!showOptions && FResult.result === 'high' && FResult.status === 'pre') ||
    //     (!showOptions && FResult.result === 'low' && FResult.status === 'pre') ||
    //     (!showOptions && FResult.result === 'low' && FResult.status === 'post')) {
    //     scrollToRef(showBubble);
    // }
  }

  function hideOptions() {
    SetMoreOptions(false)
    SetUnderstandOptions(true)
  }

  function hideSuggestOptions() {
    SetUnableResult(false)
    SetSuggestOptions(true)
  }

  function hideIudOptions() {
    SetPreHigh(false)
    SetIudOptions(true)
  }

  function hidePillOptions() {
    SetPostHigh(false)
    SetPillOptions(true)
  }
  function understandyes(type: any) {
    Iunderstand(false)
    if (type === 'yes') {
      Yesunderstand(true)
      return
    }
    if (type === 'no') {
      SetShowFeedback(true)
      return
    } else {
      Setmonthpopup(true)
    }
  }
  function nocontraceptive(type: any) {
    Yesunderstand(false)
    if (type === 'no') {
      Nocontraceptive(true)
    }
    if (type === 'yes') {
      setshowQ2yes(true)
      // SetShowFeedback(true)
    }
  }
  function daysdrop() {
    Showday(false)
    Showtime(true)
  }
  // function showbtn() {
  //     SetShowbtn(showBtn => !showBtn)
  // }
  function timedrop(time: any, type: any) {
    let answers = { ...Queanswers }
    let timevalue =
      time === null
        ? moment(moment().format('YYYY-MM-DD') + '00:00', 'YYYY-MM-DD h:mm A').format('h:mm A')
        : moment(time).format('h:mm A')
    answers.Q7 = type === 'yes' ? timevalue : 'not_sure'
    setQueanswers(answers)
  }
  function showme() {
    let answers = FindDays(null)
    setFResult({ result: answers.result, status: answers.status, type: answers.type })
    Showthanks(false)
    Showme(true)
  }

  function FindDays(arg: string | null) {
    let findovulation = moment(Queanswers.Q5, 'YYYY-MM-DD').add(Queanswers.Q6, 'days')
    let formatdate = moment(findovulation).format('YYYY-MM-DD')
    let finalOvulation1 = moment(formatdate, 'YYYY-MM-DD').subtract(14, 'days')
    let ovaluationDate = moment(finalOvulation1, 'YYYY-MM-DD').format('YYYY-MM-DD')
    let Q4days = Queanswers.Q4
    let Result: any = []
    let PrePost: string = ''
    let HoursOfOvalution: any = []
    let HoursOfCurrentEntry: any = [] // to store hours of different b/w entry date and ovalution date.
    let ED: boolean = false
    let FinalResult: any = { result: '', type: '', date: '' }
    // let UnableResult: boolean | null = null;

    let currentdate = moment(new Date()).format('YYYY-MM-DD') // current entry date
    let afterdate = moment(currentdate).isAfter(ovaluationDate)

    PrePost = afterdate ? 'post' : 'pre'
    let arr: any = []
    let maxDate: any = ''

    for (let i = 0; i < Q4days.length; i++) {
      let FinalResult
      // Queanswers.Q7 = (Queanswers.Q7 === 'not_sure') ? '' : Queanswers.Q7;
      ED = moment(currentdate).isSame(ovaluationDate)
      // Nearest UPS date
      let FinalDate = Queanswers.Q7
        ? moment(moment(Q4days[i]).format('YYYY-MM-DD') + ' ' + Queanswers.Q7, 'YYYY-MM-DD h:mm A').format(
          'YYYY-MM-DD h:mm A'
        )
        : moment(Q4days[i]).format('YYYY-MM-DD')
      currentdate = Queanswers.Q7 ? currentdate : moment(new Date()).format('YYYY-MM-DD')
      // get hours between UPSI date and ovaluation
      let DifferenceafterDate = getDifferenceInHours(
        afterdate ? new Date(currentdate) : new Date(ovaluationDate),
        afterdate ? new Date(ovaluationDate) : new Date(currentdate),
        'days'
      )
      let OD = Math.round(DifferenceafterDate)

      // get hours between UPSI date and entry date
      let DifferencecurrentDate: any = getDifferenceInHours(
        afterdate ? new Date(moment().format('YYYY-MM-DD h:mm A')) : new Date(FinalDate),
        afterdate ? new Date(FinalDate) : new Date(moment().format('YYYY-MM-DD h:mm A')),
        ''
      )
      if (DifferencecurrentDate % 24 === 0) {
        DifferencecurrentDate += 1
      }
      let CD: any = parseFloat(DifferencecurrentDate).toFixed(2)
      FinalResult = getResult(PrePost, OD, CD, ED, FinalDate)
      arr.push(FinalResult)
    }

    const highRisk = arr.filter((res: any) => res.result === 'high')
    const lowRisk = arr.filter((res: any) => res.result === 'low')
    if (highRisk.length) {
      if (highRisk.length > 1) {
        let result = findClosestDate(highRisk, ovaluationDate)
        let FinnalRes = result.filter((res: any) => res !== undefined)
        if (FinnalRes.length > 1) {
          FinalResult.result = FinnalRes[1].result
          FinalResult.type = FinnalRes[1].type
          FinalResult.date = FinnalRes[1].date
        } else {
          FinalResult.result = FinnalRes[0].result
          FinalResult.type = FinnalRes[0].type
          FinalResult.date = FinnalRes[0].date
        }
      } else {
        FinalResult.result = highRisk[0].result
        FinalResult.type = highRisk[0].type
        FinalResult.date = highRisk[0].date
      }
    }
    if (lowRisk.length && !highRisk.length) {
      if (lowRisk.length > 1) {
        let result = findClosestDate(lowRisk, ovaluationDate)
        let FinnalRes = result.filter((res: any) => res !== undefined)
        if (FinnalRes.length > 1) {
          FinalResult.result = FinnalRes[1].result
          FinalResult.type = FinnalRes[1].type
          FinalResult.date = FinnalRes[1].date
        } else {
          FinalResult.result = FinnalRes[0].result
          FinalResult.type = FinnalRes[0].type
          FinalResult.date = FinnalRes[0].date
        }
      } else {
        FinalResult.result = lowRisk[0].result
        FinalResult.type = lowRisk[0].type
        FinalResult.date = lowRisk[0].date
      }
    }

    SetnearestUPS(moment(FinalResult.date).format('DD/MM/YYYY'))

    return { result: FinalResult.result, type: FinalResult.type, status: FinalResult.type === 'unable' ? '' : PrePost }
  }

  function findClosestDate(a: any, x: any) {
    let lo, hi
    for (let i = a.length; i--;) {
      if (a[i].date <= x && (lo === undefined || lo < a[i].date)) lo = a[i]
      if (a[i].date >= x && (hi === undefined || hi > a[i].date)) hi = a[i]
    }
    return [lo, hi]
  }
  function getDifferenceInHours(date1: any, date2: any, type: string) {
    const diffInMs = Math.abs(date2 - date1)
    return type === 'days' ? diffInMs / (1000 * 60 * 60 * 24) : diffInMs / (1000 * 60 * 60)
  }

  // GET ROP RESULT FUNCTION:

  function getResult(type: string, od: number, hours: number, ED: boolean, date: any) {
    let result = { result: '', type: '', date: '' }
    let lrl: any,
      lrh: any,
      hrh: any,
      hrl: any = ''

    if (type === 'post' && !ED) {
      if (od >= 2) {
        lrl = (od - 2) * 24
        lrh = od >= 6 ? lrl + 7 * 24 : lrl + 8 * 24
      } else {
        lrl = 0
        lrh = 7 * 24
      }
      return postOvulationResult(hours <= lrl, hours > lrh, hours > lrl && hours <= lrh, hours, od, date)
    }
    if (type === 'pre' && !ED) {
      if (od <= 5) {
        hrh = (6 - od) * 24
        hrl = od * 24
      } else {
        hrl = od * 24
      }
      return preOvulationResult(hrl, hours <= hrh, hours, date)
    }
    if (ED) {
      if (hours <= 120) {
        result.result = 'high'
        result.type = 'D3'
        result.date = date
      } else if (hours > 120 && hours <= 144) {
        result.result = 'high'
        result.type = 'D4'
        result.date = date
      } else {
        result.result = 'low'
        result.type = 'A4'
        result.date = date
      }
      return result
    }
  }

  function postOvulationResult(lrl: any, lrh: any, hr: any, hours: number, od: number, date: any) {
    let result = { result: '', type: '', date: '' }

    if (lrh) {
      if (od === 1 && hours > 672) {
        result.result = 'low'
        result.type = 'A4'
        result.date = date
      } else {
        result.result = 'low'
        result.type = 'B4'
        result.date = date
      }
    }
    if (lrl) {
      if (hours > 120) {
        result.result = 'low'
        result.type = 'B4'
        result.date = date
      } else if (hours > 96 && hours <= 120) {
        result.result = 'low'
        result.type = 'B2'
        result.date = date
      } else if (hours <= 96) {
        result.result = 'low'
        result.type = 'B1'
        result.date = date
      }
    }
    if (hr) {
      if (hours <= 120 && od < 6) {
        result.result = 'high'
        result.type = 'D3'
        result.date = date
      } else if (hours > 120 && od < 6) {
        result.result = 'high'
        result.type = 'D4'
        result.date = date
      } else if (od >= 6) {
        result.result = 'high'
        result.type = 'F'
        result.date = date
      }
    }
    return result
  }

  function preOvulationResult(hrl: any, hr: any, hours: number, date: any) {
    let result = { result: '', type: '', date: '' }

    if (hrl) {
      if (hours <= 72) {
        result.result = 'low'
        result.type = 'A1'
        result.date = date
      } else if (hours > 72 && hours <= 120) {
        result.result = 'low'
        result.type = 'A2'
        result.date = date
      } else {
        result.result = 'low'
        result.type = 'A4'
        result.date = date
      }
    }
    if (hr) {
      if (hours <= 72) {
        result.result = 'high'
        result.type = 'C1'
        result.date = date
      } else {
        result.result = 'high'
        result.type = 'C2'
        result.date = date
      }
    }
    return result
  }

  function checkResult() {
    let result = FindDays(null)
    daysdrop()
  }
  useEffect(() => {
    let finddayss = FindDays('firstload')
  }, [Queanswers])
  function truefn() {
    ShowFalsefn(false)
    ShowTruefn(true)
  }
  function falseFn() {
    ShowFalsefn(true)
    ShowTruefn(false)
  }

  function CustomButton(props: any) {
    const { direction, handleClick, disabled } = props
    return (
      <i
        onClick={handleClick}
        style={{
          padding: '0 10px',
          fontWeight: 'bold',
          color: disabled ? '#fff' : '#fff',
        }}
        className={disabled ? 'cursor-default' : 'cursor-pointer'}
      >
        {direction === 'right' ? '>>' : '<<'}
      </i>
    )
  }
  const tabIndex = 0
  const noTab = -1
  return (
    <div className="signposting-page">
      <Helmet>
        <title>SPT | Emergency Contraception - Brook</title>
      </Helmet>
      <SignPostingHeader />
      <section className="signposting-section bg-pink  color-white">
        <div className="content-container equal-space-padding">
          <div className="container-800 d-flex align-items-center ec-header">
            <h1 className="page-heading">WHAT IS EMERGENCY CONTRACEPTION?</h1>
            <img className="ml-4" src={tabletIcon} alt="tablet icon" />
          </div>
        </div>
      </section>
      <section className="signposting-section mt-5 mb-5">
        <div className="content-container equal-space-padding container-para">
          <div className="contianer-450 card card-style">
            <div className="card-body">
              <p className="card-text">
                If you’ve had unprotected sex, which is sex without contraception or a condom, or your contraception
                failed (like if the condom broke or you missed a pill), you may be able to prevent an unplanned
                pregnancy by using emergency contraception within five days. There are two types of emergency
                contraception: the emergency contraceptive pill (often called the "morning after pill") and the
                intrauterine device (IUD, sometimes called the ‘copper coil’).
              </p>
            </div>
          </div>
        </div>
      </section>
      {startPreganancyCalculator && (
        <div>
          <div className="zig-zag-pattern sti" />
          <section className="signposting-section bg-purple">
            <div className="content-container equal-space-padding">
              <h1 className="section-head pad-10 pl-0">RISK OF PREGNANCY CALCULATOR</h1>
              <div className="sec-sub-desc pt-5">
                <p>
                  You can use the risk of pregnancy calculator to work out if you are at risk of becoming pregnant,
                  based on your expected date of ovulation. You might want to use it if you have had unprotected sex or
                  if your method of contraception has failed.
                </p>
                <div className="spacer-20" />
                <p>
                  It will also let you know which emergency contraception options are suitable based on your situation.
                </p>
              </div>
              <div className="spacer-20" />
            </div>
          </section>
          <section className="signposting-section bg-yellow">
            <div className="content-container equal-space-padding">
              <div className="sec-sub-desc dark">
                <p>
                  <strong>Important: </strong> This calculator will not be able to tell you for sure if you are or will
                  become pregnant. It is to help you understand your risk and make an informed choice about emergency
                  contraception.
                </p>
                <div className="spacer-20" />
                <p>
                  If you are worried about an unplanned pregnancy you should speak with a sexual health professional or
                  doctor.
                </p>
              </div>
            </div>
          </section>
          <section className="signposting-section bg-purple">
            <div className="content-container equal-space-padding">
              <button className="s-btn-clr bor-rad" type="submit" onClick={toggle}>
                Start the risk of pregnancy calculator
              </button>
            </div>
          </section>
          <div className="zig-zag-pattern color-white bg-purple" />
        </div>
      )}
      {showPregnancyFlow && (
        <div>
          <div className="zig-zag-pattern sti" />
          <section className="signposting-section bg-purple">
            <div className="content-container equal-space-padding px-0 py-3">
              <div className="contianer-450 card card-style pad-20 pb-0">
                {!ShowMe && (
                  <h1 id="gotoROP" className="section-head purple pad-10">
                    RISK OF PREGNANCY CALCULATOR
                  </h1>
                )}
                <div className="card-body card-body-f-weight py-0">
                  {startPreganancy && (
                    <div>
                      <p className="card-text">
                        We’re going to ask a few questions about your situation. It is important that you answer these
                        as honestly and accurately as possible.
                      </p>
                      <div className="spacer-20" />
                      <p className="card-text">
                        We won’t store any of the answers you give, but you might want to keep a note of them in case
                        you have to provide the information to a professional at a later point.
                      </p>
                      <div className="spacer-20" />
                      <a className="text-decoration-home" tabIndex={noTab} href="#gotoROP">
                        <button className="s-btn-clr bor-rad" type="submit" onClick={understand}>
                          I understand
                        </button>
                      </a>
                      <div className="spacer-20" />
                    </div>
                  )}

                  {iUnderstand && (
                    <div>
                      <p className="card-text">OK. Let's get started.</p>
                      <div className="spacer-20" />
                      <p className="card-text">Have you had penis-in-vagina sex since your last period?</p>
                      <div className="spacer-20" />
                      <p className="card-text">
                        You should answer 'yes' even if your partner didn't ejaculate inside you (they pulled out before
                        they came), or if they ejaculated near your vagina (on your vulva or upper thighs).
                      </p>
                      <div className="spacer-30" />
                      <a className="text-decoration-home" href="#gotoROP" tabIndex={noTab}>
                        <button
                          className="s-btn-clr bor-rad pad-btn"
                          type="submit"
                          onClick={(e) => {
                            understandyes('yes')
                            let answers = { ...Queanswers }
                            answers.Q1 = 'Yes'
                            setQueanswers(answers)
                          }}
                        >
                          Yes
                        </button>
                      </a>
                      <div className="spacer-20" />
                      <a className="text-decoration-home" href="#gotoROP" tabIndex={noTab}>
                        <button
                          className="s-btn-clr bor-rad pad-btn"
                          type="submit"
                          onClick={(e) => {
                            understandyes('no')
                            let answers = { ...Queanswers }
                            answers.Q1 = 'No'
                            setQueanswers(answers)
                          }}
                        >
                          No
                        </button>
                      </a>
                      <a className="text-decoration-home" href="#gotoROP" tabIndex={noTab}>
                        <div className="spacer-20" />
                        <button
                          className="s-btn-clr bor-rad"
                          type="submit"
                          onClick={(e) => {
                            understandyes('month')
                            let answers = { ...Queanswers }
                            answers.Q1 = 'Month'
                            setQueanswers(answers)
                          }}
                        >
                          My period is late or I missed a period
                        </button>
                      </a>
                      <div className="spacer-20" />
                      <a href="#gotoROP" tabIndex={noTab}>
                        <img
                          tabIndex={tabIndex}
                          src={backbutton}
                          width="40"
                          height="40"
                          className="cursor-pointer onkeyup"
                          onClick={gobacktounderstand}
                          alt="back"
                        />
                      </a>
                      <div className="spacer-20" />
                    </div>
                  )}
                  {showQ2yes && (
                    <div>
                      <p>Do you think your contraceptive method might have failed?</p>
                      <div className="spacer-20" />
                      <p>This could be due to missing a pill, a condom breaking or your method being out of date.</p>
                      <div className="spacer-20" />
                      <a className="text-decoration-home" href="#gotoROP" tabIndex={noTab}>
                        <button
                          className="s-btn-clr bor-rad pad-btn"
                          type="submit"
                          onClick={(e) => {
                            // showQuestionYes('yes')
                            setshowQ2yes(false)
                            setEmergencyhormonal(true)
                          }}
                        >
                          Yes
                        </button>
                      </a>
                      <div className="spacer-20" />
                      <a className="text-decoration-home" href="#gotoROP" tabIndex={noTab}>
                        <button
                          className="s-btn-clr bor-rad pad-btn"
                          type="submit"
                          onClick={(e) => {
                            showQuestionYes('no')
                          }}
                        >
                          No
                        </button>
                      </a>
                      <div className="spacer-20" />
                      <a className="text-decoration-home" href="#gotoROP" tabIndex={noTab}>
                        <button
                          className="s-btn-clr bor-rad sure-btn"
                          type="submit"
                          onClick={(e) => {
                            // showQuestionYes('not_sure')
                            setshowQ2yes(false)
                            setEmergencyhormonal(true)
                          }}
                        >
                          I'm not sure
                        </button>
                      </a>
                      <div className="spacer-20" />
                      <a className="text-decoration-home" href="#gotoROP" tabIndex={noTab}>
                        <img
                          tabIndex={tabIndex}
                          src={backbutton}
                          alt="back button"
                          width="40"
                          height="40"
                          className="cursor-pointer onkeyup"
                          onClick={() => {
                            Yesunderstand(true)
                            setshowQ2yes(false)
                          }}
                        />
                      </a>
                      <div className="spacer-20" />
                    </div>
                  )}
                  {ShowFeedback && (
                    <div>
                      <p>
                        Although not impossible, it is extremely unlikely that you will become pregnant if your
                        contraceptive method has been used correctly.
                      </p>
                      <div className="spacer-20" />
                      <p>
                        {' '}
                        However, if you are worried, you can talk to your local sexual health service or doctor for
                        help.
                      </p>
                      <div className="spacer-30" />
                      <a
                        href="https://www.brook.org.uk/find-a-service/"
                        target="_blank"
                        rel="noreferrer"
                        className="link-style ec-style"
                      >
                        Search for a sexual health service near me
                      </a>
                      <div className="spacer-20" />
                      <a href="#gotooptions" tabIndex={noTab}>
                        <button
                          className="s-btn-clr bor-rad done-btn"
                          type="submit"
                          onClick={() => {
                            feedbackExit()
                          }}
                        >
                          I'm all done here
                        </button>
                      </a>
                      <div className="spacer-20" />
                      <a href="#gotoROP" tabIndex={noTab}>
                        <img
                          tabIndex={tabIndex}
                          src={backbutton}
                          alt="back button"
                          width="40"
                          height="40"
                          className="cursor-pointer onkeyup"
                          onClick={() => {
                            SetShowFeedback(false)
                            if (calenderView) {
                              setCalenderView(false)
                              Nocontraceptive(true)
                              return
                            }
                            // if (Queanswers.Q4.length < 0) {
                            //     Nocontraceptive(true)
                            // }
                            if (Queanswers.Q1 === 'Month' && Queanswers.Q2 === 'Yes' && Queanswers.Q3 === 'no') {
                              setshowQ2yes(true)
                              return
                            }
                            if (Queanswers.Q1 === 'No') {
                              Iunderstand(true)
                            }
                            if (Queanswers.Q1 === 'Month') {
                              Setmonthpopup(true)
                              Nocontraceptive(false)
                            }
                            if (Queanswers.Q3 === 'no') {
                              setshowQ2yes(true)
                            }
                          }}
                        />
                      </a>
                      <div className="spacer-20" />
                    </div>
                  )}
                  {monthpopup && (
                    <div>
                      <p>
                        If you haven't had penis-in-vagina sex since your last period, it is extremely unlikely that you
                        are pregnant or will become pregnant.
                      </p>
                      <div className="spacer-20" />
                      <p>
                        If you had penis-in-vagina sex over a month ago and your period is late or you have missed a
                        period, there is a chance you might be pregnant. The only way to know is by taking a pregnancy
                        test.
                      </p>

                      <div className="spacer-20" />
                      <a className="text-decoration-home" href="#gotoROP" tabIndex={noTab}>
                        <button
                          className="s-btn-clr "
                          type="submit"
                          onClick={() => {
                            Yesunderstand(true)
                            Setmonthpopup(false)
                          }}
                        >
                          I have had penis-in-vagina sex since my last period
                        </button>
                      </a>
                      <div className="spacer-20" />
                      <a className="text-decoration-home" href="#gotoROP" tabIndex={noTab}>
                        <button
                          className="s-btn-clr"
                          type="submit"
                          onClick={() => {
                            Setmonthpopup(false)
                            Setmightdonow(true)
                          }}
                        >
                          I might be pregnant, what should I do now?
                        </button>
                      </a>
                      <div className="spacer-20" />
                      <a href="#gotooptions" tabIndex={noTab}>
                        <button
                          className="s-btn-clr "
                          type="submit"
                          onClick={() => {
                            Setmonthpopup(false)
                            FeedbackExit(true)
                          }}
                        >
                          I'm all done here
                        </button>
                      </a>
                      <div className="spacer-20" />
                      <a href="#gotoROP" tabIndex={noTab}>
                        <img
                          tabIndex={tabIndex}
                          src={backbutton}
                          alt="back"
                          width="40"
                          height="40"
                          className="cursor-pointer onkeyup"
                          onClick={() => {
                            Setmonthpopup(false)
                            Iunderstand(true)
                            Nocontraceptive(false)
                          }}
                        />
                      </a>
                      <div className="spacer-20" />
                    </div>
                  )}
                  {mightdonow && (
                    <div>
                      <p>
                        Contact your local Brook or other sexual health service as soon as possible for help and support
                        (you can also contact your doctor if you prefer).
                      </p>
                      <div className="spacer-20" />
                      <p>You can ask for a pregnancy test and discuss your options with them if you want to.</p>
                      <div className="spacer-20" />
                      <a
                        href="https://www.brook.org.uk/find-a-service/"
                        target="_blank"
                        rel="noreferrer"
                        className="link-style ec-style"
                      >
                        Search for a sexual health service near me
                      </a>
                      <div className="spacer-20" />
                      <a
                        className="link-style ec-style"
                        href="https://www.brook.org.uk/topics/pregnancy/"
                        target="_blank"
                      >
                        More information about pregnancy
                      </a>
                      <div className="spacer-20" />
                      <a className="text-decoration-home" href="#gotoROP" tabIndex={noTab}>
                        <button
                          className="s-btn-clr bor-rad done-btn"
                          type="submit"
                          onClick={() => {
                            Setmonthpopup(false)
                            Setmightdonow(false)
                            FeedbackExit(true)
                          }}
                        >
                          I'm all done here
                        </button>
                      </a>
                      <a className="text-decoration-home" href="#gotoROP" tabIndex={noTab}>
                        <img
                          tabIndex={tabIndex}
                          src={backbutton}
                          alt="back"
                          width="40"
                          height="40"
                          className="cursor-pointer onkeyup"
                          onClick={() => {
                            Setmightdonow(false)
                            Setmonthpopup(true)
                          }}
                        />
                      </a>
                      <div className="spacer-20" />
                    </div>
                  )}

                  {yesUnderstand && (
                    <div>
                      <p>Are you currently using any of these contraceptive methods?</p>
                      <div className="spacer-20" />
                      <p>Condoms</p>
                      <p>The pill</p>
                      <p>The implant</p>
                      <p>The injection</p>
                      <p>A coil (IUD or IUS)</p>
                      <p>The patch or the ring</p>
                      <div className="spacer-30" />
                      <a className="text-decoration-home" href="#gotoROP" tabIndex={noTab}>
                        <button
                          className="s-btn-clr bor-rad pad-btn"
                          type="submit"
                          onClick={(e) => {
                            nocontraceptive('yes')
                            let answers = { ...Queanswers }
                            answers.Q2 = 'Yes'
                            setQueanswers(answers)
                          }}
                        >
                          Yes
                        </button>
                      </a>
                      <div className="spacer-20" />
                      <a className="text-decoration-home" href="#gotoROP" tabIndex={noTab}>
                        <button
                          className="s-btn-clr bor-rad pad-btn"
                          type="submit"
                          onClick={(e) => {
                            // nocontraceptive('no')
                            Yesunderstand(false)
                            setEmergencyhormonal(true)
                            let answers = { ...Queanswers }
                            answers.Q2 = 'No'
                            setQueanswers(answers)
                          }}
                        >
                          No
                        </button>
                      </a>
                      <div className="spacer-20" />
                      <a className="text-decoration-home" href="#gotoROP" tabIndex={noTab}>
                        <img
                          tabIndex={tabIndex}
                          src={backbutton}
                          alt="back"
                          width="40"
                          height="40"
                          className="cursor-pointer onkeyup"
                          onClick={() => {
                            Yesunderstand(false)
                            if (Queanswers.Q1 === 'Month') {
                              Setmonthpopup(true)
                            } else {
                              Iunderstand(true)
                            }
                          }}
                        />
                      </a>
                      <div className="spacer-20" />
                    </div>
                  )}

                  {Emergencyhormonal && (
                    <div>
                      <p>Have you used emergency hormonal contraception since your last period?</p>
                      <div className="spacer-20" />
                      <p>This could be the morning after pill (Levonelle or EllaOne).</p>
                      <div className="spacer-30" />
                      <a className="text-decoration-home" href="#gotoROP" tabIndex={noTab}>
                        <button
                          className="s-btn-clr bor-rad pad-btn"
                          type="submit"
                          onClick={(e) => {
                            setEmergencyhormonal(false)
                            SetEmergencyhormonalYes(true)
                          }}
                        >
                          Yes
                        </button>
                      </a>
                      <div className="spacer-20" />
                      <a className="text-decoration-home" href="#gotoROP" tabIndex={noTab}>
                        <button
                          className="s-btn-clr bor-rad pad-btn"
                          type="submit"
                          onClick={(e) => {
                            setEmergencyhormonal(false)
                            Nocontraceptive(true)
                          }}
                        >
                          No
                        </button>
                      </a>
                      <div className="spacer-20" />
                      <a className="text-decoration-home" href="#gotoROP" tabIndex={noTab}>
                        <button
                          className="s-btn-clr bor-rad sure-btn"
                          type="submit"
                          onClick={(e) => {
                            SetEmergencyhormonalYes(true)
                            setEmergencyhormonal(false)
                          }}
                        >
                          I'm not sure
                        </button>
                      </a>

                      <div className="spacer-20" />
                      <a className="text-decoration-home" href="#gotoROP" tabIndex={noTab}>
                        <img
                          tabIndex={tabIndex}
                          src={backbutton}
                          alt="back"
                          width="40"
                          height="40"
                          className="cursor-pointer onkeyup"
                          onClick={() => {
                            setEmergencyhormonal(false)
                            // Yesunderstand(true)
                            setshowQ2yes(true)
                          }}
                        />
                      </a>
                      <div className="spacer-20" />
                    </div>
                  )}
                  {EmergencyhormonalYes && (
                    <div>
                      <p>
                        If you have already used emergency contraception since you last period, or you're not sure if
                        you have or not, then you need to speak with a medical professional before you use it again.
                      </p>
                      <div className="spacer-20" />
                      <p>
                        It's possible that you might still be protected from the first time you used it. A nurse or
                        doctor will be able to advise and also help you to access ongoing contraception to keep yourself
                        safe in the future.
                      </p>

                      <div className="spacer-20" />
                      <a
                        href="https://www.brook.org.uk/find-a-service/"
                        target="_blank"
                        rel="noreferrer"
                        className="link-style ec-style"
                      >
                        Search for a sexual health service near me
                      </a>
                      <div className="spacer-20" />
                      <a className="text-decoration-home" href="#gotoROP" tabIndex={noTab}>
                        <button
                          className="s-btn-clr bor-rad done-btn"
                          type="submit"
                          onClick={() => {
                            feedbackExit()
                          }}
                        >
                          I'm all done here
                        </button>
                      </a>
                      <a className="text-decoration-home" href="#gotoROP" tabIndex={noTab}>
                        <img
                          tabIndex={tabIndex}
                          src={backbutton}
                          alt="back"
                          width="40"
                          height="40"
                          className="cursor-pointer onkeyup"
                          onClick={() => {
                            setEmergencyhormonal(true)
                            SetEmergencyhormonalYes(false)
                          }}
                        />
                      </a>
                      <div className="spacer-20" />
                    </div>
                  )}
                  {NoContraceptive && (
                    <div>
                      <p>When have you had penis-in-vagina sex since your last period?</p>
                      <div className="spacer-20" />
                      <p>Please select all the days you had sex. Use your best guess if you’re not sure.</p>
                      <div className="spacer-30" />
                      {/* <Calendar
                        multiple
                        minDate={MinDate}
                        maxDate={MaxDate}
                        onChange={(date: any) => {
                          onChange(date, 'change')
                        }}
                        renderButton={<CustomButton />}
                      /> */}
                      <Calendar
                        multiSelect={true}
                        // dates={[new Date("2023-07-18T05:45:48.005Z")]}
                        onChange={(dates: Date[]) => {
                          onChange(dates, 'change')
                        }}
                        PrevIcon="<<"
                        NextIcon=">>"
                        minDate={new Date(MinDate)}
                        maxDate={new Date(MaxDate)}
                      />
                      <div className="spacer-20" />
                      <a
                        href="#gotoROP"
                        className="link-style cursor-pointer"
                        onClick={() => {
                          Nocontraceptive(false)
                          SetShowFeedback(true)
                          setCalenderView(true)
                        }}
                      >
                        I haven’t
                      </a>

                      {Queanswers.Q4.length > 0 ? (
                        <div>
                          <div className="spacer-20" />
                          <a
                            tabIndex={-1}
                            href="#gotoROP"
                            className="text-decoration-home"
                            onClick={(e) => {
                              // e.preventDefault()
                              e.stopPropagation()
                              Continue()
                            }}
                          >
                            <button className="s-btn-clr">Continue</button>
                          </a>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="spacer-20" />
                      <a href="#gotoROP" className="text-decoration-home" tabIndex={noTab}>
                        <img
                          tabIndex={tabIndex}
                          src={backbutton}
                          alt="back"
                          width="40"
                          height="40"
                          className="cursor-pointer onkeyup"
                          onClick={() => {
                            Nocontraceptive(false)
                            setEmergencyhormonal(true)
                            // if (Queanswers.Q2 !== 'No' && (Queanswers.Q3 === 'yes' || Queanswers.Q3 === 'not_sure')) {
                            //     setshowQ2yes(true)
                            // }
                            // if (Queanswers.Q2 === 'No') {
                            //     Yesunderstand(true)
                            // }
                          }}
                        />
                      </a>
                      <div className="spacer-20" />
                    </div>
                  )}
                  {showDatePd && (
                    <div>
                      <p>When was the first day of your last period?</p>
                      <div className="spacer-20" />
                      <p>In other words, the first day you started bleeding (don't worry if you can't remember)</p>
                      <div className="spacer-30" />
                      {/* <Calendar
                        maxDate={new Date()}
                        onChange={(e) => {
                          ShowChange(e, 'yes')
                        }}
                        renderButton={<CustomButton />}
                      /> */}
                      <Calendar
                        maxDate={new Date()}
                        multiSelect={false}
                        // dates={[new Date("2023-07-18T05:45:48.005Z")]}
                        onChange={(e) => {
                          ShowChange(e, 'yes')
                        }}
                        PrevIcon="<<"
                        NextIcon=">>"
                      />
                      {/* <DatePicker
                                                onChange={(e) => {
                                                    ShowChange(e, 'yes')
                                                }
                                                }
                                                maxDate={new Date()}
                                                isClearable={true}
                                                inline
                                            /> */}
                      <div className="spacer-20" />

                      <a
                        href="#"
                        className="link-style"
                        onClick={(e) => {
                          e.preventDefault()
                          ShowChange(e, 'no')
                        }}
                      >
                        I'm not sure
                      </a>
                      <div className="spacer-20" />
                      {Queanswers.Q5.length > 0 ? (
                        <a
                          tabIndex={-1}
                          href="#gotoROP"
                          className="text-decoration-home"
                          onClick={(e) => {
                            // e.preventDefault()
                            e.stopPropagation()
                            Next()
                          }}
                        >
                          <button className="s-btn-clr">Continue</button>
                        </a>
                      ) : (
                        ''
                      )}
                      <div className="spacer-20" />
                      <a href="#gotoROP" tabIndex={noTab}>
                        <img
                          tabIndex={tabIndex}
                          src={backbutton}
                          alt="back"
                          width="40"
                          height="40"
                          className="cursor-pointer onkeyup"
                          onClick={() => {
                            onChange('', 'back')
                            Nocontraceptive(true)
                            setshowDatePd(false)
                          }}
                        />
                      </a>
                      <div className="spacer-20" />
                    </div>
                  )}
                  {ShowDay && (
                    <div>
                      <p>What is your normal cycle length?</p>
                      <div className="spacer-20" />
                      <p>This is the number of days from the start of one period to the start of the next.</p>
                      <div className="spacer-20" />

                      {/* <input type="number"
                                                min="20" max="60"
                                                className="form-control input-style period-days"
                                                placeholder="Days"
                                                inputMode="numeric"
                                                onChange={(event) => {
                                                    if (event.target.value.length === 3) return false; // limits to 2 digit entry
                                                    let answers = { ...Queanswers }
                                                    answers.Q6 = event.target.value
                                                    setQueanswers(answers) // saving input to state
                                                }}
                                                value={Queanswers.Q6}
                                            /> */}
                      <select
                        className="form-select select-style-sign cursor-pointer"
                        aria-label="Default select example"
                        onChange={(event) => {
                          let answers = { ...Queanswers }
                          answers.Q6 = event.target.value
                          setQueanswers(answers) // saving input to state
                        }}
                        value={Queanswers.Q6}
                      >
                        <option value="" disabled>
                          Select Days
                        </option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                        <option value="32">32</option>
                        <option value="33">33</option>
                        <option value="34">34</option>
                        <option value="35">35</option>
                        <option value="36">36</option>
                        <option value="37">37</option>
                        <option value="38">38</option>
                        <option value="39">39</option>
                        <option value="40">40</option>
                      </select>

                      <div className="spacer-20" />
                      <a
                        href="#"
                        className="link-style"
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          let answers = { ...Queanswers }
                          answers.Q6 = 'irregular'
                          setQueanswers(answers)
                          // daysdrop();
                          Showday(false)
                          ShowChange('', 'no')
                          // checkResult()
                        }}
                      >
                        It varies as my periods are irregular
                      </a>
                      <div className="spacer-20" />
                      <a
                        href="#"
                        className="link-style"
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          let answers = { ...Queanswers }
                          answers.Q6 = 'not_sure'
                          setQueanswers(answers)
                          // daysdrop();
                          Showday(false)
                          ShowChange('', 'no')
                          // checkResult()
                        }}
                      >
                        I’m not sure
                      </a>
                      <div className="spacer-20" />
                      {Queanswers.Q6 !== '' && parseInt(Queanswers.Q6) ? (
                        <div>
                          <a
                            href="#gotoROP"
                            className="text-decoration-home"
                            onClick={(e) => {
                              // e.preventDefault();
                              // daysdrop();
                              checkResult()
                            }}
                            tabIndex={noTab}
                          >
                            <button className="s-btn-clr">Continue</button>
                          </a>{' '}
                          <br />
                          <br />{' '}
                        </div>
                      ) : (
                        ''
                      )}
                      <a href="#gotoROP" tabIndex={noTab}>
                        <img
                          tabIndex={tabIndex}
                          src={backbutton}
                          alt="back"
                          width="40"
                          height="40"
                          className="cursor-pointer onkeyup"
                          onClick={() => {
                            onChange('', 'q5')
                            Showday(false)
                            setshowDatePd(true)
                          }}
                        />
                      </a>
                      <div className="spacer-20" />
                    </div>
                  )}
                  {ShowTime && (
                    <div>
                      <p>What time on {nearestUPS} did you have sex?</p>
                      <div className="spacer-20" />
                      <p>Try to be as accurate as possible.</p>
                      <div className="spacer-20" />
                      <p>If you has sex more than once on this day, please put the earliest time.</p>
                      <div className="spacer-20" />
                      <div className="pos-relative">
                        <DatePicker
                          selected={new Date()}
                          customInput={<CustomInput />}
                          onChange={(e) => {
                            timedrop(e, 'yes')
                          }}
                          value={Queanswers.Q7}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                        // onKeyDown={(e) => {
                        //     e.preventDefault();
                        // }}
                        />
                        <div className="pos-abs-arrow">
                          <img src={downArrow} alt="down arrow" />
                        </div>
                      </div>
                      <div className="spacer-20" />

                      <a
                        href="#gotoROP"
                        className="link-style"
                        onClick={(e) => {
                          // e.preventDefault()
                          // e.stopPropagation()
                          // ShowChange('', 'no')
                          // Showtime(false)
                          // timedrop(e, 'no')
                          e.preventDefault()
                          Showtime(false)
                          timedrop(null, 'yes')
                          Showthanks(true)
                        }}
                      >
                        I’m not sure
                      </a>
                      <div className="spacer-20" />
                      <a
                        href="#gotoROP"
                        className="text-decoration-home"
                        onClick={(e) => {
                          // e.preventDefault();
                          Showtime(false)
                          Showthanks(true)
                          // timedrop(null, 'yes');
                        }}
                        tabIndex={noTab}
                      >
                        <button className="s-btn-clr">Continue</button>
                      </a>
                      <div className="spacer-20" />
                      <a href="#gotoROP" className="text-decoration-home" tabIndex={noTab}>
                        <img
                          tabIndex={tabIndex}
                          src={backbutton}
                          alt="back"
                          width="40"
                          height="40"
                          className="cursor-pointer onkeyup"
                          onClick={() => {
                            Showday(true)
                            Showtime(false)
                          }}
                        />
                      </a>
                      <div className="spacer-20" />
                    </div>
                  )}
                  {ShowThanks && (
                    <div>
                      <p>
                        Thanks for answering all of those questions. We've worked out your risk of becoming pregnant
                        based on what you've told us - do you want to see the result?
                      </p>
                      {/* <div className="spacer-20" />
                                            <p className='remember-msg-color'>Remember: using this calculator only estimates your chance of becoming pregnant based on the information you have provided.</p>
                                            <div className="spacer-20" />
                                            <p className='remember-msg-color'>It cannot tell you if are already pregnant, or if you definitely will or won’t become pregnant.</p> */}
                      <div className="spacer-20" />
                      <a className="text-decoration-home" href="#gotomyoptions" tabIndex={noTab}>
                        <button className="s-btn-clr bor-rad" type="submit" onClick={showme}>
                          Yes – show me!
                        </button>
                      </a>
                      <div className="spacer-20" />
                      {/* <img src={backbutton} width="40" height="40" className='cursor-pointer' onClick={() => {
                                                Showtime(true)
                                                Showthanks(false)
                                            }} /> */}
                    </div>
                  )}
                  {ShowMe && (
                    <div id="gotomyoptions">
                      <h1 className="section-head purple">
                        {FResult.result === 'low'
                          ? 'LOW RISK OF PREGNANCY'
                          : FResult.result === 'high'
                            ? 'HIGH RISK OF PREGNANCY'
                            : 'UNABLE TO CALCULATE THE RESULT'}
                        <span className="d-none"> - Type {FResult.type}</span>
                      </h1>
                      <div className="spacer-20" />
                      <p>
                        {FResult.result === 'low'
                          ? 'Based on the information you have provided, the chance of you becoming pregnant is low but it is still possible.'
                          : FResult.result === 'high'
                            ? 'Based on the information you have provided, the chance of you becoming pregnant is high.'
                            : FResult.type === 'F'
                              ? 'Based on the information you have provided, the chance of you becoming pregnant is high.'
                              : 'Based on the information you have provided, there is a chance that you could become pregnant.We are unable to calculate if the chance is high or low at this time.'}
                      </p>
                      {FResult.type === 'F' && (
                        <p className="mt-3">
                          However, as it is now more than 5 days since your estimated date of ovulation and when you had
                          unprotected sex, emergency contraception might not be effective.
                        </p>
                      )}
                      <div className="spacer-20" />

                      <div className="result-link">
                        <button
                          className="link-style cursor-pointer accordionBtn"
                          onClick={showDes}
                          type="button"
                          aria-expanded={showdes}
                        >
                          {FResult.result === 'low' || FResult.result === 'high' || FResult.type === 'F'
                            ? 'How is this worked out?'
                            : `Why can't you work it out?`}

                          <DropDownIcon changeClass={showdes} />
                        </button>
                      </div>
                      <div className="spacer-20" />
                      {showdes && (
                        <div className="f-weight-content">
                          <p>
                            {FResult.result === 'low' || FResult.result === 'high'
                              ? 'We have worked out the chance of you becoming pregnant using the information you have given us about when your last period was, the length of your menstrual cycle and when unprotected sex happened.'
                              : 'As you have had unprotected penis-in-vagina sex, there is a chance that you could become pregnant.'}
                          </p>
                          <div className="spacer-10" />
                          <p>
                            There are times in your menstrual cycle when the chance of becoming pregnant is higher
                            (around the time of ovulation), and times when it is lower.
                          </p>
                          <div className="spacer-10" />
                          <p>
                            {FResult.result === 'low'
                              ? 'Based on what you have told us about when during your cycle unprotected sex happened, the chance of you becoming pregnant is low, but it is still possible.'
                              : FResult.result === 'high'
                                ? 'Based on what you have told us about when during your cycle unprotected sex happened, which was around the time you may have ovulated, the chance of you becoming pregnant is high.'
                                : `We are unable to calculate if the chance is high or low at this time because we don't have all the information needed to estimate when you might have ovulated.`}
                          </p>
                          <div className="spacer-20" />
                        </div>
                      )}

                      <div className="result-link">
                        <button
                          type="button"
                          aria-expanded={showOvulation}
                          className="link-style cursor-pointer accordionBtn"
                          onClick={ovulation}
                        >
                          What is ovulation?
                          <DropDownIcon changeClass={showOvulation} />
                        </button>
                      </div>
                      <div className="spacer-20" />
                      {showOvulation && (
                        <div className="f-weight-content">
                          <p>
                            Ovulation is when a mature egg is released from one of your ovaries and moves down the
                            fallopian tube where it can be fertilised.
                          </p>
                          <div className="spacer-10" />
                          <p>Ovulation usually happens about 14 days before the start of your period.</p>
                          <div className="spacer-20" />
                        </div>
                      )}
                      {FResult.type !== 'F' && (
                        <section>
                          <div className="result-link">
                            <button
                              type="button"
                              aria-expanded={showec}
                              className="link-style cursor-pointer accordionBtn"
                              onClick={showEc}
                            >
                              What are my options for emergency contraception?
                              <DropDownIcon changeClass={showec} />
                            </button>
                          </div>
                          {showec && (
                            <div className="f-weight-content">
                              <div className="spacer-20" />
                              {/* <p>The type of emergency contraception that might be suitable for you depends on how long ago unprotected sex happened and whether or not it is likely that you had ovulated before or after the time you had unprotected sex.</p>
                                                        <div className="spacer-10" />
                                                        <p>If you do decide to use emergency contraception, remember that it is FREE on the NHS and from services like Brook.</p>
                                                        <div className="spacer-10" /> */}
                              <p className="bubble-desc-class">
                                The type of emergency contraception that might be suitable for you depends on three
                                things:
                              </p>
                              <div className="spacer-10" />
                              <p className="bubble-desc-class">1. How long ago unprotected sex happened.</p>
                              <div className="spacer-10" />
                              <p className="bubble-desc-class">
                                2. Whether or not it is likely that you had ovulated before or shortly after the time
                                you had unprotected sex.
                              </p>
                              <div className="spacer-10" />
                              <p className="bubble-desc-class">
                                3. Whether or not you have been using hormonal contraception (for example the pill or
                                mini-pill).
                              </p>
                              <div className="spacer-10" />
                              <p className="bubble-desc-class">
                                If you do decide to use emergency contraception, remember that it is FREE on the NHS and
                                from services like Brook.
                              </p>
                              <div className="spacer-10" />
                              <p className="bubble-desc-class">
                                <b>Important:</b> no method of emergency contraception is 100% effective. This tool can
                                help you understand what your options might be, but we recommend you speak with a
                                professional.
                              </p>
                              <div className="spacer-20" />
                            </div>
                          )}
                        </section>
                      )}

                      <section>
                        {/* <p className='link-style cursor-pointer' onClick={showMoreOptions}>I understand, please show me the options</p> */}
                        {/* {(showOptions && FResult.result === 'high' && FResult.status === 'post') &&
                                                    <div className='f-weight-content'>
                                                        <p>As it has been more than 5 days since unprotected sex, the options for emergency contraception are limited.</p>
                                                        <div className="spacer-10" />
                                                        <p><b>It is important that you speak with a professional as soon as possible, especially if the calculator has said your chance of becoming pregnant is high.</b></p>
                                                        <div className="spacer-10" />
                                                        <p>Emergency hormonal contraception (the 'morning after pill') is unlikely to be effective. However, you might still be able to get an IUD.</p>
                                                        <div className="spacer-20" />
                                                    </div>
                                                }
                                                {(showOptions && FResult.result === 'unable') &&
                                                    <div className='f-weight-content'>
                                                        <p>As it's not possible to estimate your ovulation date, we recommend that you see help from a professional to identify which method of emergency contraception is going to be most effective for you.</p>
                                                        <div className="spacer-10" />
                                                        <p>They will probably ask you questions similar to the ones we've asked here and will then be able to advise on which type of emergency contraception is most suitable in your situation.</p>
                                                        <div className="spacer-10" />
                                                    </div>
                                                } */}
                      </section>

                      {/* If upsi less than of 120 determined using response to Q5 */}
                      {/* {((FResult.result === 'high' && FResult.status === 'pre') || (FResult.result === 'high' && FResult.status === 'post' && FResult.type === 'D3')) &&
                                                <section>
                                                    <p className='link-style cursor-pointer' onClick={posthigh}>Can I use the morning after pill?</p>
                                                    <div className="spacer-20" />
                                                    {postHigh && (
                                                        <div className='f-weight-content'>
                                                            <p>The morning after pill works by delaying ovulation.</p>
                                                            <div className="spacer-10" />
                                                            <p>From what you have told us, there is a reasonable chance that you have already ovulated, meaning the morning after pill might not be effective at preventing pregnancy.</p>
                                                            <div className="spacer-10" />
                                                            <p>However, in some cases, ellaOne (Ulipristal Acetate / UPA) may still be an option. <b>You should talk to a professional urgently to check if it's suitable for you as it must be taken as soon as possible.</b></p>
                                                            <div className="spacer-10" />
                                                            <p>If the chance of you falling pregnant is low, you may choose to not use emergency contraception, <b>but we strongly recommend that you discuss this with a nurse or doctor. </b>
                                                                They will also be able to help you with accessing contraception to keep you safe in the future.</p>
                                                            <div className="spacer-20" />
                                                        </div>
                                                    )}
                                                </section>
                                            } */}
                      {/* end */}
                      <div className="spacer-20" />
                    </div>
                  )}

                  {feedbackexit && (
                    <div>
                      <p className="card-text">
                        Please let us know how useful you found this risk of pregnancy calculator.
                      </p>
                      <div className="spacer-20" />
                      <div>
                        <Rating rating={2} ratingCallBack={ratingCallBack} />
                      </div>
                      <div className="spacer-20" />
                      <button
                        className="s-btn-clr"
                        type="submit"
                        onClick={() => {
                          window.location.reload()
                        }}
                      >
                        Submit
                      </button>
                      <div className="spacer-20" />
                    </div>
                  )}
                </div>
              </div>
              {/* Bubble logic for 'I understand, please show me the options' option, client ask us to change the design so it's commented */}
              {/* {ShowMe && (
                                <div ref={showBubble}>
                                    <div className="spacer-20" />
                                    <p className='bubble-context'>{unableResult ? 'There are three possible options that they might suggest:' : ((showOptions && FResult.result === 'low' && FResult.status === 'pre') || (showOptions && FResult.result === 'low' && FResult.status === 'post')) ? 'If you want to use emergency contraception, your recommended options are:' :
                                        (showOptions && FResult.result === 'high' && FResult.status === 'pre') ? `If you want to use emergency contraception, your recommended option is the IUD as it's likely to be the most effective.` : ''}</p>
                                    <div className="spacer-20" />
                                    {((showOptions && FResult.result === 'high' && FResult.status === 'pre') || (showOptions && FResult.result === 'low' && FResult.status === 'pre')
                                        || (showOptions && FResult.result === 'low' && FResult.status === 'post') || preHigh || unableResult) && (
                                            <div className='pos-relative'>
                                                <div className='chat-bubble-arrow chat-blue' />
                                                <div className="spacer-10" />
                                                <div className='myth-chat-bubble chat-bubble-border light-violet pregnancy-subpara-font' onClick={iud}>THE IUD
                                                    {showIUD && (
                                                        <div>
                                                            <p className='bubble-desc-class'>A small plastic and copper device that is fitted in your uterus (womb) by a specially trained doctor or nurse.</p>
                                                            <div className="spacer-10" />
                                                            <p className='bubble-desc-class'> The IUD is over 99% effective, making it the most effect method of emergency contraception.</p>
                                                            <div className="spacer-10" />
                                                            <p className='bubble-desc-class'> It needs to be fitted within five days (120 hours) of unprotected sex or up to five days after the earliest time you could have released an egg (ovulated).</p>
                                                            <div className="spacer-10" />
                                                            <p className='bubble-desc-class'>It takes about 15-20 minutes to fit the IUD and can be uncomfortable, however, you can request a local anaesthetic to help with this.</p>
                                                            <div className="spacer-10" />
                                                            <p className='bubble-desc-class'>After having the IUD fitted you can continue to use this is as your usual method of contraception.</p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    {((FResult.result === 'low' && FResult.status === 'pre' && showOptions) || (showOptions && FResult.result === 'low' && FResult.status === 'post')
                                        || unableResult) && (
                                            <div className='pos-relative'>
                                                <div className='chat-bubble-arrow chat-blue' />
                                                <div className="spacer-20" />
                                                <div className='myth-chat-bubble chat-bubble-border light-violet pregnancy-subpara-font' onClick={upa}>ELLAONE (ULIPRISTAL ACETATE / UPA)
                                                    {(showUPA) && (
                                                        <div>
                                                            <p className='bubble-desc-class'>A type of emergency hormonal contraception that comes as a pill. </p>
                                                            <div className="spacer-10" />
                                                            <p className='bubble-desc-class'>If taken within five days (120 hours) of having unprotected sex, it is almost 98% effective at preventing pregnancy. </p>
                                                            <div className="spacer-10" />
                                                            <p className='bubble-desc-class'>ellaOne does not protect you from further unprotected sex. </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    {((FResult.result === 'low' && FResult.status === 'pre' && showOptions) || unableResult) && (
                                        <div className='pos-relative'>
                                            <div className='chat-bubble-arrow chat-blue' />
                                            <div className="spacer-20" />
                                            <div className='myth-chat-bubble chat-bubble-border light-violet pregnancy-subpara-font' onClick={lng}>LEVONELLE (LEVONORGESTRAL / LNG):
                                                {(showLNG) && (
                                                    <div>
                                                        <p className='bubble-desc-class'>A type of emergency hormonal contraception that comes as a pill. </p>
                                                        <div className="spacer-10" />
                                                        <p className='bubble-desc-class'>Other brands include Levonelle One Step or Upostelle. </p>
                                                        <div className="spacer-10" />
                                                        <p className='bubble-desc-class'>It is most effective when taken within 72 hours of unprotected sex.</p>
                                                        <div className="spacer-10" />
                                                        <p className='bubble-desc-class'>Levonelle does not protect you from further unprotected sex.</p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )} */}
            </div>
          </section>
          {/* {ShowMe &&
                        <section id='gotomyoptions' className='signposting-section bg-purple'>
                            <div className="content-container equal-space-padding pt-0 pb-4">
                                <h1 className='section-head'>WHAT ARE MY OPTIONS?</h1>
                                <div className="spacer-20" />
                                <p className='sec-desc-2-color white'>The type of emergency contraception that might be suitable for you depends on how long ago unprotected sex happened and whether
                                    or not it is likely that you had ovulated before or after the time you had unprotected sex</p>
                                <div className="spacer-20" />
                                <p className='sec-desc-2-color white'>If you do decide to use emergency contraception, remember that it is FREE on the NHS and from services like Brook.</p>
                                <div className="spacer-20" />
                                <p className='sec-desc-2-color white'>Important: no method of emergency contraception is 100% effective. This tool can help you understand what your options might be, but we recommend you speak with a professional.</p>
                            </div>
                        </section>
                    } */}

          {ShowMe && FResult.result === 'unable' && FResult.type !== 'F' && (
            <section className="signposting-section bg-purple pt-2">
              {showUnderstandOptions && (
                <div className="content-container equal-space-padding pl-0 pr-0 options_btn_dropdown">
                  <a href="#gotounableoptions" tabIndex={-1}>
                    <button
                      className="s-btn-clr options-bor primary-button-blk"
                      type="submit"
                      onClick={showMoreOptions}
                      aria-expanded={!showUnderstandOptions}
                    >
                      <span>I understand. Show me my options for emergency contraception.</span>
                      <span className="ml-3 understand-btn-dropdown">
                        <DropDownIcon changeClass={!showUnderstandOptions} newClass="arrowBtn" />
                      </span>
                    </button>
                  </a>
                </div>
              )}
            </section>
          )}
          {ShowMe && showOptions && FResult.result === 'unable' && (
            <div id="gotounableoptions" className="signposting-section bg-yellow pt-4">
              <div className="content-container equal-space-padding pt-4">
                <div className="f-weight-content">
                  <p className="bubble-desc-class">
                    As it's not possible to estimate your ovulation date, we recommend that you see help from a
                    professional to identify which method of emergency contraception is going to be most effective for
                    you.
                  </p>
                  <div className="spacer-10" />
                  <p className="bubble-desc-class">
                    They will probably ask you questions similar to the ones we've asked here and will then be able to
                    advise on which type of emergency contraception is most suitable in your situation.
                  </p>
                  <div className="spacer-10" />
                </div>
              </div>
            </div>
          )}

          {ShowMe && showOptions && FResult.result === 'unable' && (
            <section className="signposting-section bg-purple ">
              {!showUnderstandOptions && (
                <div className="hide_option mt-2  pb-5">
                  <a href="#gotomyoptions" tabIndex={noTab}>
                    <button
                      className="s-btn-clr options-bor pad-btn onkeyup"
                      type="submit"
                      onClick={hideOptions}
                      aria-expanded={!showUnderstandOptions}
                    >
                      Hide options
                      <span className="ml-3">
                        <DropDownIcon changeClass={!showUnderstandOptions} newClass="arrowBtn" />
                      </span>
                    </button>
                  </a>
                </div>
              )}
            </section>
          )}
          {/* If upsi greater than of 120 determined using response to Q5 and Q8 */}
          {ShowMe && FResult.result === 'unable' && showSuggestOptions && (
            <section className="signposting-section bg-purple">
              <div className="content-container equal-space-padding p-0  options_btn_dropdown">
                <a href="#gotooptions">
                  <button className="s-btn-clr options-bor iud-button-blk" type="submit" onClick={unableresult}>
                    <span>What might they suggest?</span>{' '}
                    <span className="ml-3  understand-btn-dropdown">
                      <img width="20" height="20" src={dropdown_btn} alt="dropdown" />
                    </span>
                  </button>
                </a>
              </div>
            </section>
          )}
          {/* end */}

          {ShowMe && FResult.result !== 'unable' && FResult.type !== 'F' && (
            <section className="signposting-section bg-purple pt-2">
              {showUnderstandOptions && (
                <div className="content-container equal-space-padding pl-0 pr-0 options_btn_dropdown">
                  <a href="#gotooptions" tabIndex={-1}>
                    <button
                      className="s-btn-clr options-bor primary-button-blk"
                      type="submit"
                      onClick={showMoreOptions}
                      aria-expanded={!showUnderstandOptions}
                    >
                      <span>I understand. Show me my options for emergency contraception.</span>
                      <span className="ml-3 understand-btn-dropdown">
                        <DropDownIcon changeClass={!showUnderstandOptions} newClass="arrowBtn" />
                      </span>
                    </button>
                  </a>
                </div>
              )}
            </section>
          )}

          {ShowMe && (
            <div id="gotooptions">
              {((showOptions && FResult.result === 'high' && FResult.status === 'pre' && FResult.type === 'C1') ||
                (showOptions && FResult.result === 'high' && FResult.status === 'pre' && FResult.type === 'C2') ||
                (FResult.result === 'low' && FResult.status === 'pre' && FResult.type === 'A2' && showOptions) ||
                (showOptions && FResult.result === 'low' && FResult.status === 'pre' && FResult.type === 'A1') ||
                (showOptions && FResult.result === 'high' && FResult.status === 'post' && FResult.type === 'D3') ||
                (showOptions && FResult.result === 'high' && FResult.status === 'pre' && FResult.type === 'D3') ||
                (showOptions && FResult.result === 'low' && FResult.status === 'post' && FResult.type === 'B1') ||
                (showOptions && FResult.result === 'low' && FResult.status === 'post' && FResult.type === 'B2') ||
                unableResult) && (
                  <div className="signposting-section bg-yellow">
                    <div className="content-container equal-space-padding pb-0">
                      {FResult.type === 'D3' && (
                        <p className="f-weight-content">
                          {' '}
                          If you want to use emergency contraception, your recommended option is the IUD as it's likely to
                          be the most effective.
                        </p>
                      )}
                      {(FResult.type === 'A1' ||
                        FResult.type === 'B1' ||
                        FResult.type === 'C1' ||
                        FResult.type === 'A2' ||
                        FResult.type === 'C2' ||
                        FResult.type === 'B2') && (
                          <p className="f-weight-content">
                            If you want to use emergency contraception, your recommended options are:
                          </p>
                        )}
                      {FResult.type === 'E5' && (
                        <p className="f-weight-content">There are three possible options that they might suggest:</p>
                      )}
                      <div className="spacer-20" />
                      <h1 className="section-head dark">OPTION: THE IUD</h1>
                      <div className="spacer-20" />
                      <div>
                        <p className="bubble-desc-class">
                          A small plastic and copper device that is fitted in your uterus (womb) by a specially trained
                          doctor or nurse.
                        </p>
                        <div className="spacer-10" />
                        <p className="bubble-desc-class">
                          {' '}
                          The IUD is over 99% effective, making it the most effect method of emergency contraception.
                        </p>
                        <div className="spacer-10" />
                        <p className="bubble-desc-class">
                          {' '}
                          It needs to be fitted within five days (120 hours) of unprotected sex or up to five days after
                          the earliest time you could have released an egg (ovulated).
                        </p>
                        <div className="spacer-10" />
                        <p className="bubble-desc-class">
                          It takes about 15-20 minutes to fit the IUD and can be uncomfortable, however, you can request a
                          local anaesthetic to help with this.
                        </p>
                        <div className="spacer-10" />
                        <p className="bubble-desc-class">
                          After having the IUD fitted you can continue to use this is as your usual method of
                          contraception.
                        </p>
                      </div>
                      <div className="spacer-20" />
                      {FResult.type !== 'D3' && <div className="dotted-border dark" />}
                      <div className="spacer-20" />
                    </div>
                  </div>
                )}
              {((FResult.result === 'low' && FResult.status === 'pre' && FResult.type === 'A1' && showOptions) ||
                (FResult.result === 'low' && FResult.status === 'pre' && FResult.type === 'A2' && showOptions) ||
                (showOptions && FResult.result === 'low' && FResult.status === 'post' && FResult.type === 'B1') ||
                (showOptions && FResult.result === 'low' && FResult.status === 'post' && FResult.type === 'B2') ||
                (showOptions && FResult.result === 'high' && FResult.status === 'pre' && FResult.type === 'C1') ||
                (showOptions && FResult.result === 'high' && FResult.status === 'pre' && FResult.type === 'C2') ||
                unableResult) && (
                  <div className="signposting-section bg-yellow">
                    <div className="content-container equal-space-padding pb-0 pt-0">
                      <h1 className="section-head dark">OPTION: ELLAONE (ULIPRISTAL ACETATE / UPA)</h1>
                      <div className="spacer-20" />
                      <div>
                        <p className="bubble-desc-class">
                          A type of emergency hormonal contraception that comes as a pill.{' '}
                        </p>
                        <div className="spacer-10" />
                        <p className="bubble-desc-class">
                          If taken within five days (120 hours) of having unprotected sex, it is almost 98% effective at
                          preventing pregnancy.{' '}
                        </p>
                        <div className="spacer-10" />
                        <p className="bubble-desc-class">ellaOne does not protect you from further unprotected sex. </p>
                        <div className="spacer-10" />
                        <p className="bubble-desc-class">
                          {' '}
                          If the calculator has said your chance of becoming pregnant is high, we recommend speaking with
                          a professional before taking Ulipristal Acetate.
                        </p>
                      </div>
                      <div className="spacer-20" />
                      {FResult.type !== 'A2' && FResult.type !== 'B2' && FResult.type !== 'C2' && (
                        <div className="dotted-border dark" />
                      )}
                      <div className="spacer-20" />
                    </div>
                  </div>
                )}
              {((FResult.result === 'low' && FResult.status === 'pre' && FResult.type === 'A1' && showOptions) ||
                (showOptions && FResult.result === 'low' && FResult.status === 'post' && FResult.type === 'B1') ||
                (showOptions && FResult.result === 'high' && FResult.status === 'pre' && FResult.type === 'C1') ||
                unableResult) && (
                  <div className="signposting-section bg-yellow">
                    <div className="content-container equal-space-padding pt-0">
                      <h1 className="section-head dark">OPTION: LEVONELLE (LEVONORGESTRAL / LNG)</h1>
                      <div className="spacer-20" />
                      <div>
                        <p className="bubble-desc-class">
                          A type of emergency hormonal contraception that comes as a pill.{' '}
                        </p>
                        <div className="spacer-10" />
                        <p className="bubble-desc-class">Other brands include Levonelle One Step or Upostelle. </p>
                        <div className="spacer-10" />
                        <p className="bubble-desc-class">
                          It is most effective when taken within 72 hours of unprotected sex.
                        </p>
                        <div className="spacer-10" />
                        <p className="bubble-desc-class">Levonelle does not protect you from further unprotected sex.</p>
                        <div className="spacer-10" />
                        <p className="bubble-desc-class">
                          If the calculator has said your chance of becoming pregnant is high, we recommend speaking with
                          a professional before taking Levonorgestrel.
                        </p>
                      </div>
                      <div className="spacer-20" />
                    </div>
                  </div>
                )}
              {((showOptions && FResult.type === 'D4') || (showOptions && FResult.type === 'A4')) && (
                <div className="signposting-section bg-yellow">
                  <div className="content-container equal-space-padding pt-4">
                    <div className="f-weight-content">
                      <p className="bubble-desc-class">
                        As it has been more than 5 days since unprotected sex, the options for emergency contraception
                        are limited.
                      </p>
                      <div className="spacer-10" />
                      <p className="bubble-desc-class">
                        <b>
                          It is important that you speak with a professional as soon as possible, especially if the
                          calculator has said your chance of becoming pregnant is high.
                        </b>
                      </p>
                      <div className="spacer-10" />
                      <p className="bubble-desc-class">
                        Emergency hormonal contraception (the 'morning after pill') might not be effective and the
                        recommended option may be an IUD.
                      </p>
                      <div className="spacer-20" />
                    </div>
                  </div>
                </div>
              )}
              {showOptions && FResult.result === 'low' && FResult.status === 'post' && FResult.type === 'B4' && (
                <div className="signposting-section bg-yellow">
                  <div className="content-container equal-space-padding pt-4">
                    <div className="f-weight-content">
                      <p className="bubble-desc-class">
                        As it has been more than 5 days since unprotected sex, the options for emergency contraception
                        are limited.
                      </p>
                      <div className="spacer-10" />
                      <p className="bubble-desc-class">
                        <b>
                          It is important that you speak with a professional as soon as possible, especially if the
                          calculator has said your chance of becoming pregnant is high.
                        </b>
                      </p>
                      <div className="spacer-10" />
                      <p className="bubble-desc-class">
                        Emergency hormonal contraception (the 'morning after pill') is unlikely to be effective.
                        However, you might still be able to get an IUD.
                      </p>
                      <div className="spacer-20" />
                    </div>
                  </div>
                </div>
              )}

              {showOptions && FResult.result !== 'unable' && (
                <section className="signposting-section bg-purple ">
                  {!showUnderstandOptions && (
                    <div className="hide_option mt-2  pb-5">
                      <a href="#gotomyoptions" tabIndex={noTab}>
                        <button
                          className="s-btn-clr options-bor pad-btn"
                          type="submit"
                          onClick={hideOptions}
                          aria-expanded={!showUnderstandOptions}
                        >
                          Hide options
                          <span className="ml-3">
                            <DropDownIcon changeClass={!showUnderstandOptions} newClass="arrowBtn" />
                          </span>
                        </button>
                      </a>
                    </div>
                  )}
                </section>
              )}
              <section className="signposting-section bg-purple ">
                {!showSuggestOptions && (
                  <div className="hide_option mt-2  pb-5">
                    <a href="#gotomyoptions" tabIndex={noTab}>
                      <button
                        className="s-btn-clr options-bor pad-btn onkeyup"
                        type="submit"
                        onClick={hideSuggestOptions}
                        aria-expanded={!showSuggestOptions}
                      >
                        Hide options
                        <span className="ml-3">
                          <DropDownIcon changeClass={!showSuggestOptions} newClass="arrowBtn" />
                        </span>
                      </button>
                    </a>
                  </div>
                )}
              </section>
            </div>
          )}

          {ShowMe &&
            ((FResult.result === 'high' && FResult.status === 'post' && FResult.type === 'D3') ||
              (FResult.result === 'high' && FResult.status === 'pre' && FResult.type === 'D3')) &&
            showPillOptions && (
              <section className="signposting-section bg-purple pt-4">
                <div className="content-container pb-0 pt-0 options_btn_dropdown">
                  <a href="#gotoPill">
                    <button className="s-btn-clr options-bor" type="submit" onClick={posthigh}>
                      Can I use the morning after pill?{' '}
                      <span className="ml-3  understand-btn-dropdown">
                        <img width="20" height="20" src={dropdown_btn} alt="dropdown" />
                      </span>
                    </button>
                  </a>
                  <div className="spacer-20" />
                </div>
              </section>
            )}

          {ShowMe && postHigh && (
            <div id="gotoPill" className="signposting-section bg-yellow">
              <div className="content-container equal-space-padding">
                <p className="bubble-desc-class">The morning after pill works by delaying ovulation.</p>
                <div className="spacer-10" />
                <p className="bubble-desc-class">
                  From what you have told us, there is a reasonable chance that you have already ovulated, meaning the
                  morning after pill might not be effective at preventing pregnancy.
                </p>
                <div className="spacer-10" />
                <p className="bubble-desc-class">
                  However, in some cases, ellaOne (Ulipristal Acetate / UPA) may still be an option.{' '}
                  <b>
                    You should talk to a professional urgently to check if it's suitable for you as it must be taken as
                    soon as possible.
                  </b>
                </p>
                <div className="spacer-10" />
                <p className="bubble-desc-class">
                  If the chance of you falling pregnant is low, you may choose to not use emergency contraception,{' '}
                  <b>but we strongly recommend that you discuss this with a nurse or doctor. </b>
                  They will also be able to help you with accessing contraception to keep you safe in the future.
                </p>
                <div className="spacer-20" />
              </div>
            </div>
          )}

          {ShowMe && !showPillOptions && (
            <section className="signposting-section bg-purple">
              <div className="hide_option mt-2  pb-5">
                <a href="#gotomyoptions" tabIndex={noTab}>
                  <button
                    className="s-btn-clr options-bor pad-btn onkeyup"
                    type="submit"
                    onClick={hidePillOptions}
                    aria-expanded={!showPillOptions}
                  >
                    Hide options
                    <span className="ml-3">
                      <DropDownIcon changeClass={!showPillOptions} newClass="arrowBtn" />
                    </span>
                  </button>
                </a>
              </div>
            </section>
          )}

          {/* If UPSI is between 1 and 120 hours from now (determined using response to Q5) and ovulation is unknown (i.e. risk incalculable) */}
          {ShowMe &&
            (FResult.type === 'D4' ||
              FResult.type === 'A4' ||
              (FResult.result === 'low' && FResult.status === 'post' && FResult.type === 'B4')) &&
            showIudOptions && (
              <section className="signposting-section bg-purple">
                <div className="content-container  pl-0 pr-0 options_btn_dropdown">
                  <a href="#gotoIUD" tabIndex={-1}>
                    <button
                      className="s-btn-clr options-bor iud-button-blk "
                      type="submit"
                      onClick={prehigh}
                      aria-expanded={!showIudOptions}
                    >
                      <span>What is an IUD?</span>
                      <span className="ml-3">
                        <DropDownIcon changeClass={!showIudOptions} newClass="arrowBtn" />
                      </span>
                    </button>
                  </a>
                  <div className="spacer-20" />
                </div>
              </section>
            )}
          {ShowMe && preHigh && (
            <div id="gotoIUD" className="signposting-section bg-yellow">
              <div className="content-container equal-space-padding">
                {FResult.type === 'D3' && (
                  <div>
                    <p className="f-weight-content">
                      If you want to use emergency contraception, your recommended option is the IUD as it's likely to
                      be the most effective.
                    </p>
                    <div className="spacer-20" />
                  </div>
                )}
                <div className="spacer-20" />
                <h1 className="section-head dark">OPTION: THE IUD</h1>
                <div className="spacer-20" />
                <div>
                  <p className="bubble-desc-class">
                    A small plastic and copper device that is fitted in your uterus (womb) by a specially trained doctor
                    or nurse.
                  </p>
                  <div className="spacer-10" />
                  <p className="bubble-desc-class">
                    {' '}
                    The IUD is over 99% effective, making it the most effect method of emergency contraception.
                  </p>
                  <div className="spacer-10" />
                  <p className="bubble-desc-class">
                    {' '}
                    It needs to be fitted within five days (120 hours) of unprotected sex or up to five days after the
                    earliest time you could have released an egg (ovulated).
                  </p>
                  <div className="spacer-10" />
                  <p className="bubble-desc-class">
                    It takes about 15-20 minutes to fit the IUD and can be uncomfortable, however, you can request a
                    local anaesthetic to help with this.
                  </p>
                  <div className="spacer-10" />
                  <p className="bubble-desc-class">
                    After having the IUD fitted you can continue to use this is as your usual method of contraception.
                  </p>
                </div>
                <div className="spacer-30" />
              </div>
            </div>
          )}
          {/* end */}
          {ShowMe && !showIudOptions && (
            <section className="signposting-section bg-purple">
              <div className="hide_option mt-2  pb-5">
                <a href="#gotomyoptions" tabIndex={noTab}>
                  <button
                    className="s-btn-clr options-bor pad-btn onkeyup"
                    type="submit"
                    onClick={hideIudOptions}
                    aria-expanded={!showIudOptions}
                  >
                    Hide options
                    <span className="ml-3">
                      <DropDownIcon changeClass={!showIudOptions} newClass="arrowBtn" />
                    </span>
                  </button>
                </a>
              </div>
            </section>
          )}

          {ShowMe && (
            <section className="signposting-section bg-purple pt-3">
              <div className="content-container equal-space-padding py-3 px-0">
                <div className="contianer-450 card card-style pad-20">
                  <div className="card-body">
                    <h1 className="section-head purple">WHAT SHOULD I DO NOW?</h1>
                    {/* <p className='link-style cursor-pointer' onClick={contactDoctor}>What should I do now?</p> */}
                    <div className="f-weight-content">
                      {FResult.type !== 'B4' && FResult.type !== 'A4' && FResult.type !== 'D4' && (
                        <b>
                          <div className="spacer-10" />
                          {FResult.type === 'F' && (
                            <div>
                              <p>
                                You should <b>contact your local sexual health service or doctor</b> as soon as possible
                                for help and support.
                              </p>
                              <div className="spacer-10" />
                              <p>
                                They will be able advise you on whether or not you can use emergency contraception or
                                help you with taking a pregnancy test if you want to.
                              </p>
                              <div className="spacer-10" />
                              <p>
                                <b>Important:</b> the only way to know for sure if you are pregnant or not is to take a
                                pregnancy test. Pregnancy tests should be taken 3 weeks after the unprotected sex
                                happened.
                              </p>
                              <div className="spacer-10" />
                              <p>
                                They will also be able to help you with making choices about contraception so you are
                                protected from now on.
                              </p>
                              <div className="spacer-10" />
                              <p>If you can, take a screenshot of this page to show the person that you speak to.</p>
                              <div className="spacer-10" />
                              <p>
                                <b>Remember:</b> using this tool only estimates your chance of falling pregnant based on
                                the information you have provided. It cannot tell you if are already pregnant or if you
                                definitely will or won't fall pregnant.
                              </p>
                            </div>
                          )}
                          {(FResult.type === 'C1' ||
                            FResult.type === 'A1' ||
                            FResult.type === 'B1' ||
                            FResult.type === 'C2' ||
                            FResult.type === 'A2' ||
                            FResult.type === 'E5' ||
                            FResult.type === 'D3') && (
                              <div>
                                <p>
                                  You should <b>contact your local sexual health service or doctor as soon as possible</b>{' '}
                                  and ask to speak to someone about emergency contraception.
                                </p>
                                <div className="spacer-10" />
                              </div>
                            )}
                          {(FResult.type === 'B4' || FResult.type === 'A4' || FResult.type === 'D4') && (
                            <div>
                              <p>
                                Contact your local Brook or other sexual health service as soon as possible for help and
                                support (you can also contact your doctor if you prefer).
                              </p>
                              <div className="spacer-10" />
                              <p>
                                You can ask for information about your options and for a pregnancy test, if you want to.
                              </p>
                              <div className="spacer-10" />
                            </div>
                          )}
                          {FResult.type !== 'F' && (
                            <p>
                              You can also ask for help with making choices about contraception so you are protected
                              from now on and for an STI test.
                            </p>
                          )}
                          <div className="spacer-10" />
                          {(FResult.type === 'C1' ||
                            FResult.type === 'A1' ||
                            FResult.type === 'B1' ||
                            FResult.type === 'A2' ||
                            FResult.type === 'B2' ||
                            FResult.type === 'C2' ||
                            FResult.type === 'D3') && (
                              <div>
                                <p>If you can, take a screenshot of your results to show the person that you speak to.</p>
                                <div className="spacer-10" />
                              </div>
                            )}
                          {(FResult.type === 'C1' ||
                            FResult.type === 'A1' ||
                            FResult.type === 'B1' ||
                            FResult.type === 'A2' ||
                            FResult.type === 'B2' ||
                            FResult.type === 'C2' ||
                            FResult.type === 'E5') && (
                              <div className="f-weight-content">
                                <p>
                                  {FResult.type === 'E5' && (
                                    <span>
                                      If they say that you can use the emergency contraceptive pill, you might also be
                                      able to access the emergency contraceptive pill for free from a pharmacy or even
                                      online - it depends what's available in your area.
                                    </span>
                                  )}
                                  You might also be able to access the emergency contraceptive pill for free from a
                                  pharmacy or even online - it depends what's available in your area.
                                </p>
                                <div className="spacer-10" />
                              </div>
                            )}
                          {(FResult.type === 'C1' ||
                            FResult.type === 'A1' ||
                            FResult.type === 'B1' ||
                            FResult.type === 'A2' ||
                            FResult.type === 'B2' ||
                            FResult.type === 'C2') && (
                              <div>
                                <p>
                                  If you’re 16 or over, another option is to see if you can buy the emergency
                                  contraceptive pill from a pharmacy.The ellaOne pill is usually around £35.
                                </p>
                                <div className="spacer-10" />
                              </div>
                            )}
                          {FResult.type !== 'F' && (
                            <div>
                              <p>
                                <b>Remember: </b> using this calculator only estimates your chance of becoming pregnant
                                based on the information you have provided.
                              </p>
                              <div className="spacer-10" />
                              <p>
                                It cannot tell you if are already pregnant, or if you definitely will or won't become
                                pregnant.
                                {(FResult.type === 'A4' || FResult.type === 'B4' || FResult.type === 'D4') && (
                                  <span>
                                    The only way to know if you are pregnant or not is to take a pregnancy test.
                                    Pregnancy tests should be taken 3 weeks after unprotected sex happened.
                                  </span>
                                )}
                              </p>
                            </div>
                          )}
                        </b>
                      )}
                      {(FResult.type === 'B4' || FResult.type === 'A4' || FResult.type === 'D4') && (
                        <div>
                          <div className="spacer-10" />
                          <p>
                            Contact your local Brook or other sexual health service as soon as possible for help and
                            support (you can also contact your doctor if you prefer).
                          </p>
                          <div className="spacer-10" />
                          <p>
                            You can ask for information about your options and for a pregnancy test, if you want to.
                          </p>
                          <div className="spacer-10" />
                          <p>
                            You can also ask for help with making choices about contraception so you are protected from
                            now on and for an STI test.
                          </p>
                          <div className="spacer-10" />
                          <p>If you can, take a screenshot of your results to show the person that you speak to.</p>
                          <div className="spacer-10" />
                          <p>
                            <b>Remember: </b> using this calculator only estimates your chance of becoming pregnant
                            based on the information you have provided.
                          </p>
                          <div className="spacer-10" />
                          <p>
                            It cannot tell you if are already pregnant, or if you definitely will or won't become
                            pregnant. The only way to know if you are pregnant or not is to take a pregnancy test.
                            Pregnancy tests should be taken 3 weeks after unprotected sex happened.
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="spacer-20" />
                    <a
                      href="https://www.brook.org.uk/find-a-service/"
                      target="_blank"
                      rel="noreferrer"
                      className="link-style ec-style"
                    >
                      Search for a sexual health service near me
                    </a>
                    <div className="spacer-20" />
                    {FResult.type !== 'F' && (
                      <div>
                        <a
                          href="https://www.brook.org.uk/your-life/emergency-contraception/"
                          target="_blank"
                          rel="noreferrer"
                          className="link-style ec-style"
                        >
                          More information about emergency contraception
                        </a>
                        <div className="spacer-20" />
                      </div>
                    )}
                    {FResult.type === 'F' && (
                      <div>
                        <a
                          href="https://www.brook.org.uk/topics/pregnancy/"
                          target="_blank"
                          rel="noreferrer"
                          className="link-style ec-style"
                        >
                          More information about pregnancy
                        </a>
                        <div className="spacer-20" />
                      </div>
                    )}
                    <a href="#gotooptions" tabIndex={noTab}>
                      {' '}
                      <button
                        className="s-btn-clr bor-rad done-btn"
                        type="submit"
                        onClick={() => {
                          feedbackExit()
                        }}
                      >
                        I'm all done here
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </section>
          )}
          <div className="zig-zag-pattern color-white bg-purple" />
        </div>
      )}
      <section className="signposting-section white">
        <div className="content-container equal-space-padding pb-0">
          <div className="d-flex img-adjust-pos-rel">
            <h1 className="section-head dark">TEGAN’S DILEMMA</h1>
            <div className="pos-abl">
              <img className="float-right" src={PersonIcon} alt=" person icon" />
            </div>
          </div>
          <div className="sec-desc-2-color m-desc-top">
            <p>
              "I’ve had sex with this guy Mateo a few times this month. It’s been super fun and I definitely want to
              keep seeing him. The first time we had sex we were a bit too keen and didn’t have any condoms but decided
              to have penis-in-vagina sex anyway. Then, because we had done it once, we just kind of carried on doing
              that all the other times too. I didn’t think much of it; he pulled out every time and I’ve done that loads
              before without an issue, but now my period is late and I’m worried I might be pregnant.{' '}
            </p>
            <div className="spacer-20" />
            <p>
              I haven’t done a pregnancy test yet because I’m a bit scared to. I also don’t really know what to do about
              the contraception situation with Mateo; it feels like it would be weird to suddenly start insisting on
              using contraception when I’ve been fine about not using it before. What if he thinks I don’t trust him, or
              is offended? And what if he doesn’t want to?"
            </p>
          </div>
          {/* {!showBtn && (
                        <><div className="spacer-20" /><button className="s-btn-clr" type="submit" onClick={showbtn}>What next?</button></>
                    )} */}
        </div>
      </section>

      <section className="signposting-section white">
        <div className="content-container equal-space-padding pb-0">
          <div className="dotted-border dark" />
          <div className="spacer-20" />
          <h1 className="section-head dark">WHAT SHOULD TEGAN DO?</h1>
          <div className="sec-desc-2-color">
            <div className="spacer-20" />
            <p>Select all the answers you think are correct.</p>
          </div>
          <div className="spacer-20" />
          <a className="text-decoration-home">
            <button
              type="button"
              className={'content-button mb-4 btn-lg btn-block' + (ContraceptionFn ? ' triple-btn' : '')}
              onClick={contraceptionFn}
            >
              Take the morning after pill{' '}
            </button>
          </a>
          <a className="text-decoration-home">
            <button
              type="button"
              className={'content-button mb-4 btn-lg btn-block' + (ImplantFn ? ' triple-btn' : '')}
              onClick={pulloutFn}
            >
              Trust that ‘pulling out’ worked{' '}
            </button>
          </a>
          <a className="text-decoration-home">
            <button
              type="button"
              className={'content-button mb-4 btn-lg btn-block' + (TypesofcontraceptionFn ? ' triple-btn' : '')}
              onClick={stiFn}
            >
              Speak to a local sexual health service
            </button>
          </a>
          <a className="text-decoration-home" href="#gotoanswer" onClick={submitFn} tabIndex={noTab}>
            <button className="s-btn-clr mb-3" type="submit">
              <b>Submit</b>
            </button>
          </a>
          {/* {!SubmitFn &&
                        <div className='sec-desc-2-color'>
                            <p>Select as many as you think!</p>
                        </div>
                    } */}
          {SubmitFn && (
            <div className="sec-desc-2-color" id="gotoanswer">
              <div className="spacer-10" />
              <p className="view-answer">ANSWER</p>
              <p>
                Because Tegan and Mateo have been having sex without a condom and Tegan isn't using any other sort of
                contraception, there is a risk of unplanned pregnancy.
              </p>
              <div className="spacer-20" />
              <p>
                "Pulling out", which is when the penis is removed from the vagina before ejaculation (cumming), is not a
                reliable method of contraception. It is hard to get it right every time and there is also the
                possibility that sperm hanging around in the penis from any recent ejaculations may find their way into
                the vagina.
              </p>
              <div className="spacer-20" />
              <p>
                By speaking to a sexual health service, Tegan will be able to understand if the morning after pill is
                appropriate in this situation or if an IUD might be a better choice. The sexual health service will then
                be able to help Tegan get the appropriate emergency contraception. If it's too late for emergency
                contraception, Tegan can ask for support with taking a pregnancy test and thinking about pregnancy
                choices.
              </p>
              <div className="spacer-20" />
              <p>
                As they haven't been using condoms, it's also important for both Tegan and Mateo to get tested for STIs.
                They might want to think about going to get testedimport or ordering tests together, which may also help
                them to start up a conversation about using contraception in the future.{' '}
              </p>
              <div className="spacer-20" />
              <p>
                <strong>
                  It is never too late to bring up contraception, and no one should make you feel like you can't set a
                  boundary about safe sex, regardless of how you have been having sex up until that point. Strong
                  relationships are built on honesty and care for each other, and although having conversations about
                  contraception might feel nerve-wracking or awkward, they are a great way to build trust and intimacy
                </strong>
              </p>
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/your-life/talking-about-condoms/"
                target="_blank"
                rel="noreferrer"
                className="link-style"
              >
                {' '}
                Talking to your partner about condoms{' '}
              </a>
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/your-life/emergency-contraception/"
                target="_blank"
                rel="noreferrer"
                className="link-style"
              >
                Emergency contraception
              </a>
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/find-a-service/"
                target="_blank"
                rel="noreferrer"
                className="link-style"
              >
                Find a Service
              </a>
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/your-life/why-you-should-get-tested-for-stis/"
                target="_blank"
                rel="noreferrer"
                className="link-style"
              >
                Why you should get tested for STIs{' '}
              </a>
            </div>
          )}
          <div className="spacer-20" />
          {/* <a href='https://www.brook.org.uk/topics/relationships/' target='_blank' rel='noreferrer' className='link-style'>Learn more about relationships</a> */}
          {/* <div className="spacer-20" /> */}
        </div>
      </section>
      {/* )} */}
      <section className={TrueFn || FalseFn ? 'signposting-section bg-purple pb-5' : 'signposting-section bg-purple'}>
        <div className="content-container equal-space-padding pb-0">
          <h1 className="section-head">MYTHBUSTING</h1>
          <div className="spacer-20" />
          <div className="pos-relative">
            <div className="s-body bubble-box light-violet bg-bubble"> CONTRACEPTION</div>
            <div className="chat-bubble-arrow chat-blue" />
            <div className="spacer-10" />
            <div className="myth-chat-bubble chat-bubble-border light-violet">
              “You can be fitted with the IUD or IUS when pregnant.”
            </div>
          </div>
          <div className="container-450">
            {!TrueFn && !FalseFn ? (
              <div>
                <div className="spacer-40" />
                <p className="section-desc section-desc-2-color">What do you think?</p>
                <div className="spacer-10" />
                <div className="dotted-border" />
              </div>
            ) : (
              ''
            )}
            <div className="true-false-blk">
              <button className=" s-btn-clr m-0" type="submit" onClick={truefn}>
                True
              </button>
              <p>or</p>
              <button className=" s-btn-clr" type="submit" onClick={falseFn}>
                False
              </button>
            </div>
          </div>
          {TrueFn && (
            <div>
              <False type="iud-contraception" />
              <div className="content-container">
                <div className="spacer-20" />
                <div className="mt-3">
                  <Link className="s-btn-clr" to={appUrls.signPosting.mythBusting}>
                    Bust more myths
                  </Link>
                </div>
              </div>
            </div>
          )}
          {FalseFn && (
            <div>
              <False type="iud-contraception" />
              <div className="content-container">
                <div className="spacer-20" />
                <div className="mt-3">
                  <Link className="s-btn-clr" to={appUrls.signPosting.mythBusting}>
                    Bust more myths
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      <section className="signposting-section white">
        <div className="content-container equal-space-padding">
          <h1 className="section-head dark">NEED URGENT HELP?</h1>
          <div className="spacer-10" />
          <div className="section-desc sec-desc-2-color">
            If you are worried about yourself or someone else, there are lots of places that can offer help and support.
          </div>
          <div className="spacer-20" />
          <Link className="s-btn-clr mr-3" to={appUrls.signPosting.help}>
            Find help
          </Link>
        </div>
      </section>
      <section className="signposting-section bg-purple">
        <div className="content-container equal-space-padding pt-0">
          <div className="spacer-20" />
          <h1 className="section-head">OTHER PEOPLE ASK…</h1>
          <div className="spacer-20" />
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.brook.org.uk/your-life/having-sex-for-the-first-time/"
              className="ask-link"
            >
              I want to know more about having sex for the first time
            </a>
          </div>
          <div className="spacer-10" />
          <div>
            <a target="_blank" rel="noreferrer" href="https://www.brook.org.uk/topics/stis/" className="ask-link">
              {' '}
              I want to know more about STIs and getting tested{' '}
            </a>
          </div>
          <div className="spacer-10" />
          <div>
            <a target="_blank" rel="noreferrer" href="https://www.brook.org.uk/topics/sex/" className="ask-link">
              I want to prepare myself for having sex with someone new
            </a>
          </div>
          <div className="spacer-10" />
          {/* <div>
                        <Link className='ask-link' to={appUrls.signPosting.help}>I'm concerned about myself or someone else</Link>
                    </div> */}
          <div className="spacer-30" />
          <div className="dotted-border" />
        </div>
      </section>
      <SignPostingFooter />
    </div>
  )
}
export default EmergencyContraception
