import React, { useState, useEffect } from 'react'

import { useMsal } from "@azure/msal-react";
import { Link, useLocation } from 'react-router-dom'
import Select from 'react-select';

import IconHelp from '../../assets/images/admin-icon-help.svg'
import IconLogout from '../../assets/images/admin-icon-logout.svg'
import IconNotification from '../../assets/images/admin-icon-notification.svg'
import IconSettings from '../../assets/images/admin-icon-settings.svg'
import BLogo from '../../assets/images/B_Brook_Logo.svg'
import IconMessage from '../../assets/images/message_icon.png'
import LocationAccordian from '../../components/accordian/LocationAccordian'
import { Notifications } from '../../components/admin/Notifications'
import Popup from '../../components/admin/popup'
// import { SelectDropdown } from '../../components/admin/SelectDropdown';
import { Button } from '../../components/button/Button';
import DialogPopup from '../../components/DailogPopup/DialogPopup';
import useAuthContext from '../../context/provider/AuthContext'
import { useLocationContext } from '../../context/provider/LocationContext';
import { NotificationContext } from '../../context/provider/NotificationContext'
import apiFunctions from '../../services/api-service'
import { getActiveRegionData } from '../../services/api/AuthAPI';
import appUrls from '../../services/AppURLs'
import { history } from '../../utils/history';

export interface MenuListProps {
  label: string
  link: string
  isActive?: boolean
  isPath?: string
  popup?: boolean
}
interface AdminLayoutProps {
  getMenuList?: Array<MenuListProps>
  children?: any
  hideMenus?: boolean
  roleMode?: string
  sideBar?: string
}
const rolesOptions: any = [
  { value: 'dudley', label: 'Dudley' },
  { value: 'thurrock', label: 'Thurrock' },
];
export const AdminLayout: React.FC<AdminLayoutProps> = ({ children, getMenuList, hideMenus, roleMode, sideBar }) => {
  const location = useLocation();
  const { instance } = useMsal();
  const [showNotification, setShowNotification] = useState(false)
  const [notifyMenuClass, setNotifyMenuClass] = useState(false)
  const [showPopup, setshowPopup] = useState(false)
  const toggleNotification = () => {
    setNotifyMenuClass(!showNotification)
    showNotification ? setShowNotification(false) : setShowNotification(true)
  }
  const accessToken = localStorage.getItem('access-token')
  const {
    state: { user },
    authDispatch,
  } = useAuthContext()
  const closeModal = () => {
    setshowPopup(false)
  }
  const [NotificationCount, SetNotificationCount] = useState('')
  const [MessageCount, SetMessageCount] = useState('')
  const [trustLoc, SettrustLoc] = useState(false)
  const [primaryNavMenuList, setPrimaryNavMenuList] = useState<any>([])

  let params = window.location.href.split('/')
  let str = params[params.length - 1]
  let locationshow = false
  const containerWidthClass = location.pathname.indexOf('/staffhub/dashboard') !== -1 ? 'admin-container' : '';
  const { state: { locationList } } = useLocationContext();
  // tfour location check 
  // const tfourlocations = process.env.REACT_APP_T4_POSTCODE_VALUE || '';
  const portalOnboardlocations = 'Dudley,Thurrock,Manchester' || '';
  const portal_locations = portalOnboardlocations.split(',');

  if (str.includes('order-kit') || str.includes('dashboard') || str.includes('sync-user')) {
    locationshow = true
  }

  const getUserRegionLocations = async ()=> {
    return apiFunctions.get(siteUrl + '/postcode/location')
  };
  useEffect(() => {
    if (accessToken) {
      apiFunctions
        .get(process.env.REACT_APP_SERVICE_BACKEND_URL + '/auth/get-notifications')
        .then((e) => {
          SetNotificationCount(e && e.data && e.data.data && e.data.data.length === 0 ? '' : e.data.data.length)
        })
        .catch((err) => {
          console.log('err.response = ', err)
        })
    }
  }, [])

  // GET Message Active count
  useEffect(() => {
    if (accessToken) {
      apiFunctions
        .get(process.env.REACT_APP_SERVICE_BACKEND_URL + '/message/get')
        .then((e) => {
          const data = e.data && e.data.data && e.data.data.Messages
          const arr = data.filter((res: any) => res.read === false)
          SetMessageCount(arr && arr.length)
        })
        .catch((err) => {
          console.log('err.response = ', err)
        })
    }
  }, [])

  // primary nav menu list
  useEffect(() => {
    const fetchData = async () => {
    const locations_response = await getUserRegionLocations();
    const response = locations_response?.data?.data?.locations;
    const location_filter = response?.filter((item: any) => portal_locations.includes(item.name)) || [];
    const portalEnable_location = location_filter.some((obj:any) => obj.status === true);
    if (portalEnable_location) {
      setPrimaryNavMenuList(getMenuList);
    } else {
      // if dudley is not selected in location then we removed last three elements from menu list
      const removeDudleyMenus = getMenuList?.slice(0, getMenuList.length - 4);;
      setPrimaryNavMenuList(removeDudleyMenus);
    }
  }
   fetchData();
  }, [locationList]);

  // logout by azure account and application as well
  let siteUrl = process.env.REACT_APP_SERVICE_BACKEND_URL || '';
  const handleLogout = () => {
    const dd = instance.logoutPopup({
      postLogoutRedirectUri: `${appUrls.admin.auth.login}`,
      mainWindowRedirectUri: `${appUrls.admin.auth.login}`
    })
    const accessToken = localStorage.getItem('access-token')
    const tokendata = {
      auth_token: accessToken
    }
    apiFunctions.post(siteUrl + '/auth/logout', tokendata).then((res) => {
      sessionStorage.clear();
      localStorage.clear();
    });
  }

  const tabIndex = 0;
  const [showModal, setShowModal] = useState(false)
  const [popupRedirection, setPopupRedirection] = useState({
    link: '',
    region_id : ''
  })
  const [regionValue, setRegionValue] = useState<any>(null);

  useEffect(() => {
    async function getRegion() {
      try {
        const locations_response = await getUserRegionLocations()
        const response = await getActiveRegionData();
        const userLocationList = locations_response.data.data.locations;
        if(response && response.data?.result?.trusts?.length > 0){
          const findLocationList = response.data.result.trusts.filter((item: any) => {
            return userLocationList.find((trustItem: any) => {
              return trustItem.name.toLowerCase() === item.trustName.toLowerCase() && trustItem.status === true;
            })
          });
          setRegionValue(findLocationList.map((res: any) => ({value: res.uuid, label: res.trustName})))
        }
      } catch (error) {
        console.log('error');
        
      }
    }
    getRegion();
  }, [])
  const customStyles = {
    option: (provided:any, state:any) => ({
      ...provided,
      backgroundColor: state.isHovered ? '#5c2c68' : 'white', 
      color: state.isHovered ? 'white' : 'black',
      '&:hover': {
        backgroundColor: '#5c2c68', 
        color:'white'
      }
    }),
  };


  const isActive = (menu:any) => {
    const currentPath = window.location.href;
    const menuLink = menu.link;

    // Check if the currentPath exactly matches the menu link
    if (currentPath === menuLink) {
        return true;
    }

    // Check if the currentPath includes the full menu link or any path in menu.isPath
    if (currentPath.includes(menuLink)) {
        return true;
    }

    // Check if any path in menu.isPath is included in the currentPath
    if (menu.isPath && Array.isArray(menu.isPath)) {
        for (let path of menu.isPath) {
            if (currentPath.includes(path)) {
                return true;
            }
        }
    }
    return false;
};


  return (
    <>
      <NotificationContext.Consumer>
        {({ state, notificationDispatch }) => (
          <div className="admin-blk">
            <div className="admin-sidebar">
              <Link
                to={
                  localStorage.getItem('access-token')
                    ? `${appUrls.admin.orderKit}/?filters=Flagged`
                    : appUrls.admin.auth.login
                }
              >
                <div className="logo-blk">
                  <img src={BLogo} alt="Brook" />
                </div>
              </Link>
              {!hideMenus && (
                <div className="bottom">
                  {localStorage.getItem('role') !== '3' && (
                    <div
                      className={notifyMenuClass || sideBar === 'Notifications' ? 'active' : 'onkeyup'}
                      onClick={(e) => {
                        toggleNotification()
                        notificationDispatch({ type: 'NOTIFICATION_DATA' })
                      }}
                      tabIndex={tabIndex}>
                      {(localStorage.getItem('role') === '1' || localStorage.getItem('role') === '2') &&
                        (<span className="badge badge-primary badge-class">{NotificationCount}</span>
                        )}
                      <img src={IconNotification} className="notification-icon-class" alt="Notifications" />
                    </div>
                  )}
                  <Link
                    to={MessageCount ? appUrls.admin.users.inbox : appUrls.admin.users.message}
                    className={sideBar === 'Message' && !notifyMenuClass ? 'active pos-relative' : 'pos-relative'}
                  >
                    <span className="badge badge-primary badge-message">{MessageCount ? MessageCount : ''}</span>
                    <img src={IconMessage} alt="Message" width="25px" height="25px" />
                  </Link>
                  <Link
                    to={`${localStorage.getItem('role') === '1'
                        ? appUrls.admin.settings.managerPermission
                        : localStorage.getItem('role') === '2'
                          ? appUrls.admin.settings.userPermission
                          : appUrls.admin.settings.accounts
                      }`}
                    className={sideBar === 'Settings' && !notifyMenuClass ? 'active' : ''}
                  >
                    <img src={IconSettings} alt="Settings" />
                  </Link>
                  <Link
                    to={appUrls.admin.users.feedback}
                    className={sideBar === 'Help' && !notifyMenuClass ? 'active' : ''}
                  >
                    <img src={IconHelp} alt="Help" />
                  </Link>
                  <div
                    tabIndex={tabIndex}
                    className="onkeyup"
                    onClick={() => {
                      handleLogout()
                    }}
                  >
                    <img
                      className="notification-icon-class"
                      src={IconLogout}
                      alt="Logout"
                    // onClick={(e) => {
                    //         authDispatch({ type: 'LOGOUT' });
                    //         logout();
                    //     }}
                    />
                  </div>
                </div>
              )}
              {showNotification && <Notifications mydata={state} toggleNotification={toggleNotification} />}
            </div>
            <div className="admin-content-wrap">
              <div
                className={`admin-menu-header ${location.pathname.indexOf('/staffhub/login') !== -1 ? 'log-in-blk' : ''
                  }`}
              >
                <div className={`admin-top-nav ${primaryNavMenuList.length > 2 ? '' : 'menus-section'}`}>
                  <DialogPopup
                    onRequestClose={() => setShowModal(false)}
                    open={showModal}
                    showHeading
                    heading="Select your region"
                    subheading={<p>Please select ONE region to proceed to Appointments, Forms or Messaging. When selected, you will be <br/>  only be able to view data for that region. You can change the selected region by editing the list in the top <br/> right corner.</p>}
                  >
                    <div className="body-content mb-10">
                      <Select
                        onChange={(e: any) => setPopupRedirection({...popupRedirection, region_id: e.value})}
                        options={regionValue}
                        className='react-select-container '
                        classNamePrefix='react-select'
                        styles={customStyles}
                      />
                      <div className="message-image">
                        <div className="my-8 flex justify-center">
                          <Button
                            buttonType="button"
                            buttonClassName="btns primary-btn"
                            buttonText="Confirm"
                            isDisabled={!popupRedirection.region_id}
                            onClick={() => history.push(`${popupRedirection.link}&region_id=${popupRedirection.region_id}`)}
                          />
                        </div>
                      </div>
                    </div>
                  </DialogPopup>
                  {primaryNavMenuList &&
                    primaryNavMenuList.map((menu: MenuListProps, index: number) => (
                      <React.Fragment key={index}>
                        {localStorage.getItem('role') === '3' && menu.label === 'Admin' ? (
                          <></>
                        ) : (
                          <>
                          {menu.popup ?
                          <Link
                            key={index}
                            to="#"
                            onClick={() => {
                              setPopupRedirection({...popupRedirection, link: menu.link})
                              setShowModal(true)
                            }}
                            className={` ${window.location.href.includes(menu.link.substring(menu.link.lastIndexOf('/') + 1)) ||
                                (menu.isPath && window.location.href.includes(menu.isPath))
                                ? 'active'
                                : ''
                              }`}
                          >
                            {menu.label}
                          </Link> : <Link
                            key={index}
                            to={menu.link}
                            className={isActive(menu) ? 'active' : ''}
                          >
                            {menu.label}
                          </Link>}
                          
                          </>
                        )}
                      </React.Fragment>
                    ))}
                </div>
                <div className="flex items-center">
                  {localStorage.getItem('access-token') !== null && locationshow && (
                    // <div className="pos-relative">
                    <LocationAccordian />
                    // </div>
                  )}

                  <div className="admin-role-status">
                    <p tabIndex={tabIndex}>
                      {localStorage.getItem('role') === '1' ? 'SUPER ADMIN MODE' :
                        localStorage.getItem('role') === '2' ? 'MANAGER MODE' :
                          localStorage.getItem('role') === '3' ? 'STAFF USER MODE' : ''}
                    </p>
                  </div>
                </div>
              </div>
              <div className={`admin-content-blk ${containerWidthClass}`}>{children}</div>
            </div>
          </div>
        )}
      </NotificationContext.Consumer>
      <Popup Logout={showPopup} close={closeModal} />
    </>
  )
}
