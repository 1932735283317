import FetchData from "../../pages/AdminPanel/super_admin/forms/FetchData";
import { TableView } from "./TableView";

export interface AdminProps {
    user_id?: any;
}
export const AdminHistory: React.FC<AdminProps>= (props) => {
    const App = FetchData(process.env.REACT_APP_SERVICE_BACKEND_URL + '/admin/get-admin-profile?id='+props.user_id+'&type=history')(TableView)({ tableHead: { type: 'admin-history', titles: ['Action', 'Client name','Age','Flag','Reason for decision','Date','Time','In Lilie?'] } })

    return (
        <App />
    );
}