import React, { useEffect, useState } from 'react'

import '../../styles/SignpostingToolStyle/home.css'
import '../../styles/SignpostingToolStyle/mythbusting.css'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'

import WhiteNext from '../../assets/images/WhiteNext'
import WhitePrev from '../../assets/images/WhitePrev'
import appUrls from '../../services/AppURLs'
import { False } from './False'
import { SignPostingFooter } from './SignPostingFooter'
import { SignPostingHeader } from './SignPostingHeader'

// class MythBusting extends Component {
export function MythBusting({}) {
  // render() {
  const [sti, SetSti] = useState(true)
  const [contraception, SetContraception] = useState(true)
  const [consent, SetConsent] = useState(true)
  const [bodies, SetBodies] = useState(true)
  const [rsti, removeSti] = useState(true)
  const [rcontraception, removeContraception] = useState(true)
  const [rconsent, removeConsent] = useState(true)
  const [rbodies, removeBodies] = useState(true)
  const [showmythcontraception, ShowMythContraception] = useState(false)
  const [showmythconsent, ShowMythConsent] = useState(false)
  const [showmythbodies, ShowMythBodies] = useState(false)
  const [showmythsti, ShowMythSti] = useState(false)
  const [showmyths, ShowMyths] = useState(true)
  const contraceptionSettings = {
    accessibility: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }
  function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props
    return (
      <button className={className} style={{ ...style, display: 'block', width: 20, height: 20 }} onClick={onClick}>
        <WhitePrev />
      </button>
    )
  }

  function SampleNextArrow(props: any) {
    const { className, style, onClick } = props
    return (
      <button className={className} style={{ ...style, display: 'block', width: 20, height: 20 }} onClick={onClick}>
        <WhiteNext />
      </button>
    )
  }
  const [Truecontraception1Fn, ShowTruecontraception1Fn] = useState(false)
  const [FalseconceptionFn, ShowFalseconceptionFn] = useState(false)

  const [Truecontraception2Fn, ShowTruecontraception2Fn] = useState(false)
  const [Falseconception2Fn, ShowFalseconception2Fn] = useState(false)

  const [Truecontraception3Fn, ShowTruecontraception3Fn] = useState(false)
  const [Falseconception3Fn, ShowFalseconception3Fn] = useState(false)

  const [Truecontraception4Fn, ShowTruecontraception4Fn] = useState(false)
  const [Falseconception4Fn, ShowFalseconception4Fn] = useState(false)

  const [Truecontraception5Fn, ShowTruecontraception5Fn] = useState(false)
  const [Falseconception5Fn, ShowFalseconception5Fn] = useState(false)

  const [Truecontraception6Fn, ShowTruecontraception6Fn] = useState(false)
  const [Falseconception6Fn, ShowFalseconception6Fn] = useState(false)

  const [Truecontraception7Fn, ShowTruecontraception7Fn] = useState(false)
  const [Falseconception7Fn, ShowFalseconception7Fn] = useState(false)

  const [consentonet, Showconsentonet] = useState(false)
  const [consentonef, Showconsentonef] = useState(false)

  const [consenttwot, Showconsenttwot] = useState(false)
  const [consenttwof, Showconsenttwof] = useState(false)

  const [consentthreet, Showconsentthreet] = useState(false)
  const [consentthreef, Showconsentthreef] = useState(false)

  const [consentfourt, Showconsentfourt] = useState(false)
  const [consentfourf, Showconsentfourf] = useState(false)

  const [consentfivet, Showconsentfivet] = useState(false)
  const [consentfivef, Showconsentfivef] = useState(false)

  const [consentsixt, Showconsentsixt] = useState(false)
  const [consentsixf, Showconsentsixf] = useState(false)

  const [bodiesonet, Showbodiesonet] = useState(false)
  const [bodiesonef, Showbodiesonef] = useState(false)

  const [bodiestwot, Showbodiestwot] = useState(false)
  const [bodiestwof, Showbodiestwof] = useState(false)

  const [bodiesthreet, Showbodiesthreet] = useState(false)
  const [bodiesthreef, Showbodiesthreef] = useState(false)

  const [bodiesfourt, Showbodiesfourt] = useState(false)
  const [bodiesfourf, Showbodiesfourf] = useState(false)

  const [bodiesfivet, Showbodiesfivet] = useState(false)
  const [bodiesfivef, Showbodiesfivef] = useState(false)

  const [bodiessixt, Showbodiessixt] = useState(false)
  const [bodiessixf, Showbodiessixf] = useState(false)

  const [bodiessevent, Showbodiessevent] = useState(false)
  const [bodiessevenf, Showbodiessevenf] = useState(false)

  const [stionet, Showstionet] = useState(false)
  const [stionef, Showstionef] = useState(false)

  const [stitwot, Showstitwot] = useState(false)
  const [stitwof, Showstitwof] = useState(false)

  const [stithreet, Showstithreet] = useState(false)
  const [stithreef, Showstithreef] = useState(false)

  const [stifourt, Showstifourt] = useState(false)
  const [stifourf, Showstifourf] = useState(false)

  const [stifivet, Showstifivet] = useState(false)
  const [stifivef, Showstifivef] = useState(false)

  const [contraceptionMyth, ShowContraceptionMyth] = useState(true)
  const [consentMyth, ShowConsentMyth] = useState(true)
  const [bodiesMyth, ShowBodiesMyth] = useState(true)
  const [stiMyth, ShowStiMyth] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [0])

  function truefn(type: any) {
    if (type === 'myth-contraception-1') {
      ShowFalseconceptionFn(false)
      ShowTruecontraception1Fn(true)
    }
    if (type === 'myth-contraception-2') {
      ShowFalseconception2Fn(false)
      ShowTruecontraception2Fn(true)
    }
    if (type === 'myth-contraception-3') {
      ShowFalseconception3Fn(false)
      ShowTruecontraception3Fn(true)
    }

    if (type === 'myth-contraception-3') {
      ShowFalseconception3Fn(false)
      ShowTruecontraception3Fn(true)
    }

    if (type === 'myth-contraception-4') {
      ShowFalseconception4Fn(false)
      ShowTruecontraception4Fn(true)
    }

    if (type === 'myth-contraception-5') {
      ShowFalseconception5Fn(false)
      ShowTruecontraception5Fn(true)
    }

    if (type === 'myth-contraception-6') {
      ShowFalseconception6Fn(false)
      ShowTruecontraception6Fn(true)
    }

    if (type === 'myth-contraception-7') {
      ShowFalseconception7Fn(false)
      ShowTruecontraception7Fn(true)
    }

    if (type === 'consent-1') {
      Showconsentonet(true)
      Showconsentonef(false)
    }
    if (type === 'consent-2') {
      Showconsenttwot(true)
      Showconsenttwof(false)
    }
    if (type === 'consent-3') {
      Showconsentthreet(true)
      Showconsentthreef(false)
    }
    if (type === 'consent-4') {
      Showconsentfourt(true)
      Showconsentfourf(false)
    }
    if (type === 'consent-5') {
      Showconsentfivet(true)
      Showconsentfivef(false)
    }
    if (type === 'consent-6') {
      Showconsentsixt(true)
      Showconsentsixf(false)
    }
    if (type === 'bodies-1') {
      Showbodiesonet(true)
      Showbodiesonef(false)
    }
    if (type === 'bodies-2') {
      Showbodiestwot(true)
      Showbodiestwof(false)
    }
    if (type === 'bodies-3') {
      Showbodiesthreet(true)
      Showbodiesthreef(false)
    }
    if (type === 'bodies-4') {
      Showbodiesfourt(true)
      Showbodiesfourf(false)
    }
    if (type === 'bodies-5') {
      Showbodiesfivet(true)
      Showbodiesfivef(false)
    }
    if (type === 'bodies-6') {
      Showbodiessixt(true)
      Showbodiessixf(false)
    }
    if (type === 'bodies-7') {
      Showbodiessevent(true)
      Showbodiessevenf(false)
    }
    if (type === 'sti-1') {
      Showstionet(true)
      Showstionef(false)
    }
    if (type === 'sti-2') {
      Showstitwot(true)
      Showstitwof(false)
    }
    if (type === 'sti-3') {
      Showstithreet(true)
      Showstithreef(false)
    }
    if (type === 'sti-4') {
      Showstifourt(true)
      Showstifourf(false)
    }
    if (type === 'sti-5') {
      Showstifivet(true)
      Showstifivef(false)
    }
  }
  function falseFn(type: any) {
    if (type === 'myth-contraception-1') {
      ShowFalseconceptionFn(true)
      ShowTruecontraception1Fn(false)
    }
    if (type === 'myth-contraception-2') {
      ShowFalseconception2Fn(true)
      ShowTruecontraception2Fn(false)
    }
    if (type === 'myth-contraception-3') {
      ShowFalseconception3Fn(true)
      ShowTruecontraception3Fn(false)
    }

    if (type === 'myth-contraception-4') {
      ShowFalseconception4Fn(true)
      ShowTruecontraception4Fn(false)
    }

    if (type === 'myth-contraception-5') {
      ShowFalseconception5Fn(true)
      ShowTruecontraception5Fn(false)
    }

    if (type === 'myth-contraception-6') {
      ShowFalseconception6Fn(true)
      ShowTruecontraception6Fn(false)
    }

    if (type === 'myth-contraception-7') {
      ShowFalseconception7Fn(true)
      ShowTruecontraception7Fn(false)
    }

    if (type === 'consent-1') {
      Showconsentonet(false)
      Showconsentonef(true)
    }
    if (type === 'consent-2') {
      Showconsenttwot(false)
      Showconsenttwof(true)
    }
    if (type === 'consent-3') {
      Showconsentthreet(false)
      Showconsentthreef(true)
    }
    if (type === 'consent-4') {
      Showconsentfourt(false)
      Showconsentfourf(true)
    }
    if (type === 'consent-5') {
      Showconsentfivet(false)
      Showconsentfivef(true)
    }
    if (type === 'consent-6') {
      Showconsentsixt(false)
      Showconsentsixf(true)
    }
    if (type === 'bodies-1') {
      Showbodiesonet(false)
      Showbodiesonef(true)
    }
    if (type === 'bodies-2') {
      Showbodiestwot(false)
      Showbodiestwof(true)
    }
    if (type === 'bodies-3') {
      Showbodiesthreet(false)
      Showbodiesthreef(true)
    }
    if (type === 'bodies-4') {
      Showbodiesfourt(false)
      Showbodiesfourf(true)
    }
    if (type === 'bodies-5') {
      Showbodiesfivet(false)
      Showbodiesfivef(true)
    }
    if (type === 'bodies-6') {
      Showbodiessixt(false)
      Showbodiessixf(true)
    }
    if (type === 'bodies-7') {
      Showbodiessevent(false)
      Showbodiessevenf(true)
    }
    if (type === 'sti-1') {
      Showstionet(false)
      Showstionef(true)
    }
    if (type === 'sti-2') {
      Showstitwot(false)
      Showstitwof(true)
    }
    if (type === 'sti-3') {
      Showstithreet(false)
      Showstithreef(true)
    }
    if (type === 'sti-4') {
      Showstifourt(false)
      Showstifourf(true)
    }
    if (type === 'sti-5') {
      Showstifivet(false)
      Showstifivef(true)
    }
  }
  function stifn(event: any) {
    if (event.target && event.target.innerText === 'STIs') {
      ShowMythBodies(false)
      ShowMythConsent(false)
      ShowMythContraception(false)
      ShowMythSti(true)
    }
    removeContraception(false)
    removeConsent(false)
    removeBodies(false)
    removeSti(true)
    SetSti(false)
    SetContraception(false)
    SetBodies(false)
    SetConsent(false)
  }

  function consentfn(event: any) {
    if (event.target && event.target.innerText === 'CONSENT') {
      ShowMythBodies(false)
      ShowMythConsent(true)
      ShowMythContraception(false)
      ShowMythSti(false)
    }
    removeSti(false)
    removeContraception(false)
    removeConsent(true)
    removeBodies(false)
    SetSti(false)
    SetContraception(false)
    SetBodies(false)
    SetConsent(false)
  }

  function contraceptionfn(event: any) {
    if (event.target && event.target.innerText === 'CONTRACEPTION') {
      ShowMythBodies(false)
      ShowMythConsent(false)
      ShowMythContraception(true)
      ShowMythSti(false)
    }
    removeSti(false)
    removeContraception(true)
    removeConsent(false)
    removeBodies(false)
    SetSti(false)
    SetContraception(false)
    SetBodies(false)
    SetConsent(false)
  }

  function bodiesfn(event: any) {
    if (event.target && event.target.innerText === 'BODIES') {
      ShowMythBodies(true)
      ShowMythConsent(false)
      ShowMythContraception(false)
      ShowMythSti(false)
    }
    removeSti(false)
    removeContraception(false)
    removeConsent(false)
    removeBodies(true)
    SetSti(false)
    SetContraception(false)
    SetBodies(false)
    SetConsent(false)
  }

  function goToConsent() {
    ShowContraceptionMyth(false)
    ShowStiMyth(false)
    ShowBodiesMyth(false)
    ShowConsentMyth(true)
  }

  function goTobodies() {
    ShowContraceptionMyth(false)
    ShowStiMyth(false)
    ShowConsentMyth(false)
    ShowBodiesMyth(true)
  }

  function goTosti() {
    ShowContraceptionMyth(false)
    ShowBodiesMyth(false)
    ShowConsentMyth(false)
    ShowStiMyth(true)
  }

  function goToContraception() {
    ShowStiMyth(false)
    ShowBodiesMyth(false)
    ShowConsentMyth(false)
    ShowContraceptionMyth(true)
  }
  return (
    <div className="signposting-page">
      <Helmet>
        <title>SPT | Myth Busting - Brook</title>
      </Helmet>
      <SignPostingHeader activeState="myths" />
      <section className="signposting-section bg-purple color-white">
        <div className="content-container equal-space-padding pb-0">
          <h1 className="page-heading" aria-label=" MYTH BUSTING">
            MYTHBUSTING
          </h1>
          <div className="spacer-20" />
          <p className="myth-topic">
            See if you can tell whether these common myths about sex and relationships are true or false!
          </p>
          <div className="spacer-20" />
          <p className="myth-topic">Filter myths by topic</p>
          <div className="spacer-20" />
          <div className="d-flex flex-wrap">
            {rsti && (
              <button type="button" className="s-body myth-contr-box bg-bubble-green" onClick={stifn}>
                STIs
              </button>
            )}
            {rconsent && (
              <button type="button" className="s-body myth-contr-box bg-bubble-pink " onClick={consentfn}>
                CONSENT
              </button>
            )}
            {rcontraception && (
              <button type="button" className="s-body myth-contr-box bg-bubble" onClick={contraceptionfn}>
                CONTRACEPTION
              </button>
            )}
            {rbodies && (
              <button type="button" className="s-body myth-contr-box bg-bubble-red" onClick={bodiesfn}>
                BODIES
              </button>
            )}
          </div>
          {(rsti || rconsent || rcontraception || rbodies) && (
            <div>
              <div className="spacer-30" />
              <div className="dotted-border" />
            </div>
          )}
          {/* )} */}
          <div className="spacer-20" />
          <div className="d-flex flex-wrap">
            {!rsti && (
              <button type="button" className="s-body myth-contr-box bg-bubble-green " onClick={stifn}>
                STIs
              </button>
            )}
            {!rconsent && (
              <button className="s-body myth-contr-box bg-bubble-pink" onClick={consentfn}>
                CONSENT
              </button>
            )}
            {!rcontraception && (
              <button className="s-body myth-contr-box bg-bubble" onClick={contraceptionfn}>
                CONTRACEPTION
              </button>
            )}
            {!rbodies && (
              <div className="s-body myth-contr-box bg-bubble-red" onClick={bodiesfn}>
                BODIES
              </div>
            )}
          </div>
          {(!rsti || !rconsent || !rcontraception || !rbodies) && (
            <div>
              <div className="spacer-20" />
              <div className="dotted-border" />
            </div>
          )}
          <div>
            <div className="spacer-30" />
            {contraception && rcontraception && (
              <div className="with-slider-section">
                <Slider {...contraceptionSettings}>
                  {contraceptionMyth && (
                    <div>
                      <section className="signposting-section bg-purple">
                        <div className="content-container pad-30 pt-0 pb-0 text-center">
                          <div className="pos-relative">
                            <div className="s-body bubble-box light-violet bg-bubble"> CONTRACEPTION</div>
                            <div className="chat-bubble-arrow chat-blue" />
                            <div className="spacer-10" />
                            <div className="myth-chat-bubble chat-bubble-border text-blue-myth light-violet pr-1">
                              “Getting an implant will hurt.”
                            </div>
                          </div>
                          <div className="container-450">
                            <div className="true-false-blk">
                              <button
                                className="s-btn-clr m-0"
                                type="submit"
                                onClick={() => {
                                  truefn('myth-contraception-1')
                                }}
                              >
                                True
                              </button>
                              <p>or</p>
                              <button
                                className="s-btn-clr"
                                type="submit"
                                onClick={() => {
                                  falseFn('myth-contraception-1')
                                }}
                              >
                                False
                              </button>
                            </div>

                            {Truecontraception1Fn && (
                              <div>
                                <False type="myth-contraception-1" />
                              </div>
                            )}
                            {FalseconceptionFn && (
                              <div>
                                <False type="myth-contraception-1" />
                              </div>
                            )}
                            {/* <div className="spacer-20"></div>
                                                <div className='text-center'>
                                                    <a className='next-myth'>Next contraception myth</a>
                                                </div> */}
                          </div>
                          <button type="button" className="align-sub-content" onClick={() => goToConsent()}>
                            Next myth
                          </button>
                          <div className="spacer-20" />
                          <div className="dotted-border" />
                        </div>
                      </section>
                    </div>
                  )}
                  {bodiesMyth && (
                    <div className="with-slider-section">
                      <section className="signposting-section bg-purple">
                        <div className="content-container pad-30 pt-0 pb-0 text-center">
                          <div className="pos-relative">
                            <div className="chat-bubble-arrow" />
                            <div className="s-body bubble-box  bg-bubble-red"> BODIES</div>
                            <div className="spacer-10" />
                            <div className="myth-chat-bubble chat-bubble-border-red text-orange-myth ">
                              “You shouldn't exercise when you are on your period.”
                            </div>
                          </div>
                          <div className="container-450">
                            <div className="true-false-blk">
                              <button
                                className="s-btn-clr m-0"
                                type="submit"
                                onClick={() => {
                                  truefn('bodies-3')
                                }}
                              >
                                True
                              </button>
                              <p>or</p>
                              <button
                                className="s-btn-clr"
                                type="submit"
                                onClick={() => {
                                  falseFn('bodies-3')
                                }}
                              >
                                False
                              </button>
                            </div>

                            {bodiesthreet && (
                              <div>
                                <False type="bodies-3" />
                              </div>
                            )}
                            {bodiesthreef && (
                              <div>
                                <False type="bodies-3" />
                              </div>
                            )}
                          </div>
                          <button type="button" className="align-sub-content" onClick={() => goTosti()}>
                            Next myth
                          </button>
                          <div className="spacer-20" />
                          <div className="dotted-border" />
                        </div>
                      </section>
                    </div>
                  )}
                  {contraceptionMyth && (
                    <div className="with-slider-section">
                      <section className="signposting-section bg-purple">
                        <div className="content-container pad-30 pt-0 pb-0 text-center">
                          <div className="pos-relative">
                            <div className="s-body bubble-box light-violet bg-bubble"> CONTRACEPTION</div>
                            <div className="chat-bubble-arrow chat-blue" />
                            <div className="spacer-10" />
                            <div className="myth-chat-bubble chat-bubble-border light-violet text-blue-myth">
                              "You need parental permission to buy condoms if you are under 16"
                            </div>
                          </div>
                          <div className="container-450">
                            <div className="true-false-blk">
                              <button
                                className="s-btn-clr m-0"
                                type="submit"
                                onClick={() => {
                                  truefn('myth-contraception-4')
                                }}
                              >
                                True
                              </button>
                              <p>or</p>
                              <button
                                className="s-btn-clr"
                                type="submit"
                                onClick={() => {
                                  falseFn('myth-contraception-4')
                                }}
                              >
                                False
                              </button>
                            </div>

                            {Truecontraception4Fn && (
                              <div>
                                <False type="myth-contraception-4" />
                              </div>
                            )}
                            {Falseconception4Fn && (
                              <div>
                                <False type="myth-contraception-4" />
                              </div>
                            )}
                          </div>
                          <button className="align-sub-content" onClick={() => goToConsent()}>
                            Next myth
                          </button>
                          <div className="spacer-20" />
                          <div className="dotted-border" />
                          <div className="spacer-20" />
                        </div>
                      </section>
                    </div>
                  )}
                  {stiMyth && (
                    <div className="with-slider-section">
                      <section className="signposting-section bg-purple">
                        <div className="content-container pad-30 pt-0 pb-0 text-center">
                          <div className="pos-relative">
                            <div className="chat-bubble-arrow chat-green" />
                            <div className="s-body bubble-box  bg-bubble-green"> STIs</div>
                            <div className="spacer-10" />
                            <div className="myth-chat-bubble chat-bubble-border-green pr-1 text-green-myth ">
                              “Getting tested for STIs is difficult and painful.”
                            </div>
                          </div>
                          <div className="container-450">
                            <div className="true-false-blk">
                              <button
                                className="s-btn-clr m-0"
                                type="submit"
                                onClick={() => {
                                  truefn('sti-2')
                                }}
                              >
                                True
                              </button>
                              <p>or</p>
                              <button
                                className="s-btn-clr"
                                type="submit"
                                onClick={() => {
                                  falseFn('sti-2')
                                }}
                              >
                                False
                              </button>
                            </div>

                            {stitwot && (
                              <div>
                                <False type="sti-2" />
                              </div>
                            )}
                            {stitwof && (
                              <div>
                                <False type="sti-2" />
                              </div>
                            )}
                          </div>
                          <button type="button" className="align-sub-content  " onClick={() => goToContraception()}>
                            Next myth
                          </button>
                          <div className="spacer-20" />
                          <div className="dotted-border" />
                        </div>
                      </section>
                    </div>
                  )}
                  {consentMyth && (
                    <div className="with-slider-section">
                      <section className="signposting-section bg-purple">
                        <div className="content-container pad-30 pt-0 pb-0 text-center">
                          <div className="pos-relative">
                            <div className="chat-bubble-arrow chat-pink" />
                            <div className="s-body bubble-box  bg-bubble-pink"> CONSENT</div>
                            <div className="spacer-10" />
                            <div className="myth-chat-bubble chat-bubble-border-pink text-pink-myth pr-1">
                              “If your body is turned on then you are giving consent.”
                            </div>
                          </div>
                          <div className="container-450">
                            <div className="true-false-blk">
                              <button
                                className="s-btn-clr m-0"
                                type="submit"
                                onClick={() => {
                                  truefn('consent-1')
                                }}
                              >
                                True
                              </button>
                              <p>or</p>
                              <button
                                className="s-btn-clr"
                                type="submit"
                                onClick={() => {
                                  falseFn('consent-1')
                                }}
                              >
                                False
                              </button>
                            </div>

                            {consentonet && (
                              <div>
                                <False type="consent-1" />
                              </div>
                            )}
                            {consentonef && (
                              <div>
                                <False type="consent-1" />
                              </div>
                            )}
                          </div>
                          <button type="button" className="align-sub-content  " onClick={() => goTobodies()}>
                            Next myth
                          </button>
                          <div className="spacer-20" />
                          <div className="dotted-border" />
                        </div>
                      </section>
                    </div>
                  )}
                  {contraceptionMyth && (
                    <div className="with-slider-section">
                      <section className="signposting-section bg-purple">
                        <div className="content-container pad-30 pt-0 pb-0 text-center">
                          <div className="pos-relative">
                            <div className="s-body bubble-box light-violet bg-bubble"> CONTRACEPTION</div>
                            <div className="chat-bubble-arrow chat-blue" />
                            <div className="spacer-10" />
                            <div className="myth-chat-bubble chat-bubble-border pr-1 light-violet text-blue-myth">
                              “You need parental permission to get contraception if you are under 16.”
                            </div>
                          </div>
                          <div className="container-450">
                            <div className="true-false-blk">
                              <button
                                className="s-btn-clr m-0"
                                type="submit"
                                onClick={() => {
                                  truefn('myth-contraception-2')
                                }}
                              >
                                True
                              </button>
                              <p>or</p>
                              <button
                                className="s-btn-clr"
                                type="submit"
                                onClick={() => {
                                  falseFn('myth-contraception-2')
                                }}
                              >
                                False
                              </button>
                            </div>

                            {Truecontraception2Fn && (
                              <div>
                                <False type="myth-contraception-2" />
                              </div>
                            )}
                            {Falseconception2Fn && (
                              <div>
                                <False type="myth-contraception-2" />
                              </div>
                            )}
                          </div>
                          <button type="button" className="align-sub-content  " onClick={() => goToConsent()}>
                            Next myth
                          </button>
                          <div className="spacer-20" />
                          <div className="dotted-border" />
                        </div>
                      </section>
                    </div>
                  )}
                  {bodiesMyth && (
                    <div className="with-slider-section">
                      <section className="signposting-section bg-purple">
                        <div className="content-container pad-30 pt-0 pb-0 text-center">
                          <div className="pos-relative">
                            <div className="chat-bubble-arrow" />
                            <div className="s-body bubble-box  bg-bubble-red"> BODIES</div>
                            <div className="spacer-10" />
                            <div className="myth-chat-bubble chat-bubble-border-red text-orange-myth pr-1">
                              “You can't get pregnant when you are on your period.”
                            </div>
                          </div>
                          <div className="container-450">
                            <div className="true-false-blk">
                              <button
                                className="s-btn-clr m-0"
                                type="submit"
                                onClick={() => {
                                  truefn('bodies-1')
                                }}
                              >
                                True
                              </button>
                              <p>or</p>
                              <button
                                className="s-btn-clr"
                                type="submit"
                                onClick={() => {
                                  falseFn('bodies-1')
                                }}
                              >
                                False
                              </button>
                            </div>

                            {bodiesonet && (
                              <div>
                                <False type="bodies-1" />
                              </div>
                            )}
                            {bodiesonef && (
                              <div>
                                <False type="bodies-1" />
                              </div>
                            )}
                          </div>
                          <button type="button" className="align-sub-content " onClick={() => goTosti()}>
                            Next myth
                          </button>
                          <div className="spacer-20" />
                          <div className="dotted-border" />
                        </div>
                      </section>
                    </div>
                  )}
                  {consentMyth && (
                    <div className="with-slider-section">
                      <section className="signposting-section bg-purple">
                        <div className="content-container pad-30 pt-0 pb-0 text-center">
                          <div className="pos-relative">
                            <div className="chat-bubble-arrow chat-pink" />
                            <div className="s-body bubble-box  bg-bubble-pink"> CONSENT</div>
                            <div className="spacer-10" />
                            <div className="myth-chat-bubble chat-bubble-border-pink text-pink-myth pr-1">
                              "You only need consent when it comes to penetrative sex."
                            </div>
                          </div>
                          <div className="container-450">
                            <div className="true-false-blk">
                              <button
                                className="s-btn-clr m-0"
                                type="submit"
                                onClick={() => {
                                  truefn('consent-4')
                                }}
                              >
                                True
                              </button>
                              <p>or</p>
                              <button
                                className="s-btn-clr"
                                type="submit"
                                onClick={() => {
                                  falseFn('consent-4')
                                }}
                              >
                                False
                              </button>
                            </div>
                          </div>

                          {consentfourt && (
                            <div>
                              <False type="consent-4" />
                            </div>
                          )}
                          {consentfourf && (
                            <div>
                              <False type="consent-4" />
                            </div>
                          )}
                          <button type="button" className="align-sub-content  " onClick={() => goTobodies()}>
                            Next myth
                          </button>
                          <div className="spacer-20" />
                          <div className="dotted-border" />
                        </div>
                      </section>
                    </div>
                  )}
                  {bodiesMyth && (
                    <div className="with-slider-section">
                      <section className="signposting-section bg-purple">
                        <div className="content-container pad-30 pt-0 pb-0 text-center">
                          <div className="pos-relative">
                            <div className="chat-bubble-arrow" />
                            <div className="s-body bubble-box  bg-bubble-red"> BODIES</div>
                            <div className="spacer-10" />
                            <div className="myth-chat-bubble chat-bubble-border-red pr-1 text-orange-myth ">
                              “You shouldn't have sex when you are on your period.”
                            </div>
                          </div>
                          <div className="container-450">
                            <div className="true-false-blk">
                              <button
                                className="s-btn-clr m-0"
                                type="submit"
                                onClick={() => {
                                  truefn('bodies-5')
                                }}
                              >
                                True
                              </button>
                              <p>or</p>
                              <button
                                className="s-btn-clr"
                                type="submit"
                                onClick={() => {
                                  falseFn('bodies-5')
                                }}
                              >
                                False
                              </button>
                            </div>

                            {bodiesfivet && (
                              <div>
                                <False type="bodies-5" />
                              </div>
                            )}
                            {bodiesfivef && (
                              <div>
                                <False type="bodies-5" />
                              </div>
                            )}
                          </div>
                          <button type="button" className="align-sub-content  " onClick={() => goTosti()}>
                            Next myth
                          </button>
                          <div className="spacer-20" />
                          <div className="dotted-border" />
                        </div>
                      </section>
                    </div>
                  )}
                  {contraceptionMyth && (
                    <div className="with-slider-section">
                      <section className="signposting-section bg-purple">
                        <div className="content-container pad-30 pt-0 pb-0 text-center">
                          <div className="pos-relative">
                            <div className="s-body bubble-box light-violet bg-bubble"> CONTRACEPTION</div>
                            <div className="chat-bubble-arrow chat-blue" />
                            <div className="spacer-10" />
                            <div className="myth-chat-bubble chat-bubble-border pr-1 light-violet text-blue-myth">
                              "You can be fitted with the IUD or IUS when pregnant."
                            </div>
                          </div>
                          <div className="container-450">
                            <div className="true-false-blk">
                              <button
                                className="s-btn-clr m-0"
                                type="submit"
                                onClick={() => {
                                  truefn('myth-contraception-6')
                                }}
                              >
                                True
                              </button>
                              <p>or</p>
                              <button
                                className="s-btn-clr"
                                type="submit"
                                onClick={() => {
                                  falseFn('myth-contraception-6')
                                }}
                              >
                                False
                              </button>
                            </div>

                            {Truecontraception6Fn && (
                              <div>
                                <False type="myth-contraception-6" />
                              </div>
                            )}
                            {Falseconception6Fn && (
                              <div>
                                <False type="myth-contraception-6" />
                              </div>
                            )}
                          </div>
                          <button type="button" className="align-sub-content  " onClick={() => goToConsent()}>
                            Next myth
                          </button>
                          <div className="spacer-20" />
                          <div className="dotted-border" />
                          <div className="spacer-20" />
                        </div>
                      </section>
                    </div>
                  )}
                  {consentMyth && (
                    <div className="with-slider-section">
                      <section className="signposting-section bg-purple">
                        <div className="content-container pad-30 pt-0 pb-0 text-center">
                          <div className="pos-relative">
                            <div className="chat-bubble-arrow chat-pink" />
                            <div className="s-body bubble-box  bg-bubble-pink"> CONSENT</div>
                            <div className="spacer-10" />
                            <div className="myth-chat-bubble chat-bubble-border-pink text-pink-myth pr-1">
                              "If you are in a relationship, or if you have had sex with someone before, consent is
                              automatic."
                            </div>
                          </div>
                          <div className="container-450">
                            <div className="true-false-blk">
                              <button
                                className="s-btn-clr m-0"
                                type="submit"
                                onClick={() => {
                                  truefn('consent-5')
                                }}
                              >
                                True
                              </button>
                              <p>or</p>
                              <button
                                className="s-btn-clr"
                                type="submit"
                                onClick={() => {
                                  falseFn('consent-5')
                                }}
                              >
                                False
                              </button>
                            </div>
                          </div>

                          {consentfivet && (
                            <div>
                              <False type="consent-5" />
                            </div>
                          )}
                          {consentfivef && (
                            <div>
                              <False type="consent-5" />
                            </div>
                          )}
                          <button type="button" className="align-sub-content  " onClick={() => goTobodies()}>
                            Next myth
                          </button>
                          <div className="spacer-20" />
                          <div className="dotted-border" />
                        </div>
                      </section>
                    </div>
                  )}
                  {contraceptionMyth && (
                    <div className="with-slider-section">
                      <section className="signposting-section bg-purple">
                        <div className="content-container pad-30 pt-0 pb-0 text-center">
                          <div className="pos-relative">
                            <div className="s-body bubble-box light-violet bg-bubble"> CONTRACEPTION</div>
                            <div className="chat-bubble-arrow chat-blue" />
                            <div className="spacer-10" />
                            <div className="myth-chat-bubble chat-bubble-border pr-1 light-violet text-blue-myth">
                              “All types of contraception need to be taken every day.”
                            </div>
                          </div>
                          <div className="container-450">
                            <div className="true-false-blk">
                              <button
                                className="s-btn-clr m-0"
                                type="submit"
                                onClick={() => {
                                  truefn('myth-contraception-3')
                                }}
                              >
                                True
                              </button>
                              <p>or</p>
                              <button
                                className="s-btn-clr"
                                type="submit"
                                onClick={() => {
                                  falseFn('myth-contraception-3')
                                }}
                              >
                                False
                              </button>
                            </div>

                            {Truecontraception3Fn && (
                              <div>
                                <False type="myth-contraception-3" />
                              </div>
                            )}
                            {Falseconception3Fn && (
                              <div>
                                <False type="myth-contraception-3" />
                              </div>
                            )}
                          </div>
                          <button type="button" className="align-sub-content  " onClick={() => goToConsent()}>
                            Next myth
                          </button>
                          <div className="spacer-20" />
                          <div className="dotted-border" />
                        </div>
                      </section>
                    </div>
                  )}
                  {bodiesMyth && (
                    <div className="with-slider-section">
                      <section className="signposting-section bg-purple">
                        <div className="content-container pad-30 pt-0 pb-0 text-center">
                          <div className="pos-relative">
                            <div className="chat-bubble-arrow" />
                            <div className="s-body bubble-box  bg-bubble-red"> BODIES</div>
                            <div className="spacer-10" />
                            <div className="myth-chat-bubble chat-bubble-border-red text-orange-myth pr-1">
                              “The menstrual cycle lasts 28 days.”
                            </div>
                          </div>
                          <div className="container-450">
                            <div className="true-false-blk">
                              <button
                                className="s-btn-clr m-0"
                                type="submit"
                                onClick={() => {
                                  truefn('bodies-7')
                                }}
                              >
                                True
                              </button>
                              <p>or</p>
                              <button
                                className="s-btn-clr"
                                type="submit"
                                onClick={() => {
                                  falseFn('bodies-7')
                                }}
                              >
                                False
                              </button>
                            </div>

                            {bodiessevent && (
                              <div>
                                <False type="bodies-7" />
                              </div>
                            )}
                            {bodiessevenf && (
                              <div>
                                <False type="bodies-7" />
                              </div>
                            )}
                          </div>
                          <button type="button" className="align-sub-content  " onClick={() => goTosti()}>
                            Next myth
                          </button>
                          <div className="spacer-20" />
                          <div className="dotted-border" />
                        </div>
                      </section>
                    </div>
                  )}
                  {contraceptionMyth && (
                    <div className="with-slider-section">
                      <section className="signposting-section bg-purple">
                        <div className="content-container pad-30 pt-0 pb-0 text-center">
                          <div className="pos-relative">
                            <div className="s-body bubble-box light-violet bg-bubble"> CONTRACEPTION</div>
                            <div className="chat-bubble-arrow chat-blue" />
                            <div className="spacer-10" />
                            <div className="myth-chat-bubble chat-bubble-border pr-1 light-violet text-blue-myth">
                              "Condoms last forever."
                            </div>
                          </div>
                          <div className="container-450">
                            <div className="true-false-blk">
                              <button
                                className="s-btn-clr m-0"
                                type="submit"
                                onClick={() => {
                                  truefn('myth-contraception-7')
                                }}
                              >
                                True
                              </button>
                              <p>or</p>
                              <button
                                className="s-btn-clr"
                                type="submit"
                                onClick={() => {
                                  falseFn('myth-contraception-7')
                                }}
                              >
                                False
                              </button>
                            </div>

                            {Truecontraception7Fn && (
                              <div>
                                <False type="myth-contraception-7" />
                              </div>
                            )}
                            {Falseconception7Fn && (
                              <div>
                                <False type="myth-contraception-7" />
                              </div>
                            )}
                          </div>
                          <button type="button" className="align-sub-content" onClick={() => goToConsent()}>
                            Next myth
                          </button>
                          <div className="spacer-20" />
                          <div className="dotted-border" />
                          <div className="spacer-20" />
                        </div>
                      </section>
                    </div>
                  )}
                  {stiMyth && (
                    <div className="with-slider-section">
                      <section className="signposting-section bg-purple">
                        <div className="content-container pad-30 pt-0 pb-0 text-center">
                          <div className="pos-relative">
                            <div className="chat-bubble-arrow chat-green" />
                            <div className="s-body bubble-box  bg-bubble-green"> STIs</div>
                            <div className="spacer-10" />
                            <div className="myth-chat-bubble chat-bubble-border-green text-green-myth pr-1">
                              “You can only get an STI from penetrative sex.”
                            </div>
                          </div>
                          <div className="container-450">
                            <div className="true-false-blk">
                              <button
                                className="s-btn-clr m-0"
                                type="submit"
                                onClick={() => {
                                  truefn('sti-4')
                                }}
                              >
                                True
                              </button>
                              <p>or</p>
                              <button
                                className="s-btn-clr"
                                type="submit"
                                onClick={() => {
                                  falseFn('sti-4')
                                }}
                              >
                                False
                              </button>
                            </div>

                            {stifourt && (
                              <div>
                                <False type="sti-4" />
                              </div>
                            )}
                            {stifourf && (
                              <div>
                                <False type="sti-4" />
                              </div>
                            )}
                          </div>
                          <button type="button" className="align-sub-content  " onClick={() => goToContraception()}>
                            Next myth
                          </button>
                          <div className="spacer-20" />
                          <div className="dotted-border" />
                        </div>
                      </section>
                    </div>
                  )}
                  {consentMyth && (
                    <div className="with-slider-section">
                      <section className="signposting-section bg-purple">
                        <div className="content-container pad-30 pt-0 pb-0 text-center">
                          <div className="pos-relative">
                            <div className="chat-bubble-arrow chat-pink" />
                            <div className="s-body bubble-box  bg-bubble-pink"> CONSENT</div>
                            <div className="spacer-10" />
                            <div className="myth-chat-bubble chat-bubble-border-pink text-pink-myth pr-1">
                              "In the UK, it's illegal to have sex if you are under 16."
                            </div>
                          </div>
                          <div className="container-450">
                            <div className="true-false-blk">
                              <button
                                className="s-btn-clr m-0"
                                type="submit"
                                onClick={() => {
                                  truefn('consent-6')
                                }}
                              >
                                True
                              </button>
                              <p>or</p>
                              <button
                                className="s-btn-clr"
                                type="submit"
                                onClick={() => {
                                  falseFn('consent-6')
                                }}
                              >
                                False
                              </button>
                            </div>
                          </div>

                          {consentsixt && (
                            <div>
                              <False type="consent-6" />
                            </div>
                          )}
                          {consentsixf && (
                            <div>
                              <False type="consent-6" />
                            </div>
                          )}
                          <button type="button" className="align-sub-content" onClick={() => goTobodies()}>
                            Next myth
                          </button>
                          <div className="spacer-20" />
                          <div className="dotted-border" />
                        </div>
                      </section>
                    </div>
                  )}
                  {bodiesMyth && (
                    <div className="with-slider-section">
                      <section className="signposting-section bg-purple">
                        <div className="content-container pad-30 pt-0 pb-0 text-center">
                          <div className="pos-relative">
                            <div className="chat-bubble-arrow" />
                            <div className="s-body bubble-box  bg-bubble-red"> BODIES</div>
                            <div className="spacer-10" />
                            <div className="myth-chat-bubble chat-bubble-border-red text-orange-myth pr-1">
                              “Puberty is the same for everyone.”
                            </div>
                          </div>
                          <div className="container-450">
                            <div className="true-false-blk">
                              <button
                                className="s-btn-clr m-0"
                                type="submit"
                                onClick={() => {
                                  truefn('bodies-2')
                                }}
                              >
                                True
                              </button>
                              <p>or</p>
                              <button
                                className="s-btn-clr"
                                type="submit"
                                onClick={() => {
                                  falseFn('bodies-2')
                                }}
                              >
                                False
                              </button>
                            </div>

                            {bodiestwot && (
                              <div>
                                <False type="bodies-2" />
                              </div>
                            )}
                            {bodiestwof && (
                              <div>
                                <False type="bodies-2" />
                              </div>
                            )}
                          </div>
                          <button type="button" className="align-sub-content" onClick={() => goTosti()}>
                            Next myth
                          </button>
                          <div className="spacer-20" />
                          <div className="dotted-border" />
                        </div>
                      </section>
                    </div>
                  )}
                  {stiMyth && (
                    <div>
                      <section className="signposting-section bg-purple">
                        <div className="content-container pad-30 pt-0 pb-0 text-center">
                          <div className="pos-relative">
                            <div className="chat-bubble-arrow chat-green" />
                            <div className="s-body bubble-box  bg-bubble-green"> STIs</div>
                            <div className="spacer-10" />
                            <div className="myth-chat-bubble chat-bubble-border-green text-green-myth ">
                              “I don't have any symptoms so I can't have an STI.”
                            </div>
                          </div>
                          <div className="container-450">
                            <div className="true-false-blk">
                              <button
                                className="s-btn-clr m-0"
                                type="submit"
                                onClick={() => {
                                  truefn('sti-1')
                                }}
                              >
                                True
                              </button>
                              <p>or</p>
                              <button
                                className="s-btn-clr"
                                type="submit"
                                onClick={() => {
                                  falseFn('sti-1')
                                }}
                              >
                                False
                              </button>
                            </div>

                            {stionet && (
                              <div>
                                <False type="sti-1" />
                              </div>
                            )}
                            {stionef && (
                              <div>
                                <False type="sti-1" />
                              </div>
                            )}
                          </div>
                          <button type="button" className="align-sub-content " onClick={() => goToContraception()}>
                            Next myth
                          </button>
                          <div className="spacer-20" />
                          <div className="dotted-border" />
                        </div>
                      </section>
                    </div>
                  )}
                  {bodiesMyth && (
                    <div className="with-slider-section">
                      <section className="signposting-section bg-purple">
                        <div className="content-container pad-30 pt-0 pb-0 text-center">
                          <div className="pos-relative">
                            <div className="chat-bubble-arrow" />
                            <div className="s-body bubble-box  bg-bubble-red"> BODIES</div>
                            <div className="spacer-10" />
                            <div className="myth-chat-bubble chat-bubble-border-red text-orange-myth pr-1">
                              “You are no longer a virgin if you use tampons.”
                            </div>
                          </div>
                          <div className="container-450">
                            <div className="true-false-blk">
                              <button
                                className="s-btn-clr m-0"
                                type="submit"
                                onClick={() => {
                                  truefn('bodies-6')
                                }}
                              >
                                True
                              </button>
                              <p>or</p>
                              <button
                                className="s-btn-clr"
                                type="submit"
                                onClick={() => {
                                  falseFn('bodies-6')
                                }}
                              >
                                False
                              </button>
                            </div>

                            {bodiessixt && (
                              <div>
                                <False type="bodies-6" />
                              </div>
                            )}
                            {bodiessixf && (
                              <div>
                                <False type="bodies-6" />
                              </div>
                            )}
                          </div>
                          <button type="button" className="align-sub-content" onClick={() => goTosti()}>
                            Next myth
                          </button>
                          <div className="spacer-20" />
                          <div className="dotted-border" />
                        </div>
                      </section>
                    </div>
                  )}
                  {consentMyth && (
                    <div className="with-slider-section">
                      <section className="signposting-section bg-purple">
                        <div className="content-container pad-30 pt-0 pb-0 text-center">
                          <div className="pos-relative">
                            <div className="chat-bubble-arrow chat-pink" />
                            <div className="s-body bubble-box  bg-bubble-pink"> CONSENT</div>
                            <div className="spacer-10" />
                            <div className="myth-chat-bubble chat-bubble-border-pink text-pink-myth pr-1">
                              “People who are flirty or who wear revealing clothing are 'asking for it'.”
                            </div>
                          </div>
                          <div className="container-450">
                            <div className="true-false-blk">
                              <button
                                className="s-btn-clr m-0"
                                type="submit"
                                onClick={() => {
                                  truefn('consent-2')
                                }}
                              >
                                True
                              </button>
                              <p>or</p>
                              <button
                                className="s-btn-clr"
                                type="submit"
                                onClick={() => {
                                  falseFn('consent-2')
                                }}
                              >
                                False
                              </button>
                            </div>
                          </div>

                          {consenttwot && (
                            <div>
                              <False type="consent-2" />
                            </div>
                          )}
                          {consenttwof && (
                            <div>
                              <False type="consent-2" />
                            </div>
                          )}
                          <button type="button" className="align-sub-content" onClick={() => goTobodies()}>
                            Next myth
                          </button>
                          <div className="spacer-20" />
                          <div className="dotted-border" />
                        </div>
                      </section>
                    </div>
                  )}
                  {stiMyth && (
                    <div className="with-slider-section">
                      <section className="signposting-section bg-purple">
                        <div className="content-container pad-30 pt-0 pb-0 text-center">
                          <div className="pos-relative">
                            <div className="chat-bubble-arrow chat-green" />
                            <div className="s-body bubble-box  bg-bubble-green"> STIs</div>
                            <div className="spacer-10" />
                            <div className="myth-chat-bubble chat-bubble-border-green text-green-myth pr-1">
                              "If I ignore symptoms of an STI, it will go away on its own."
                            </div>
                          </div>
                          <div className="container-450">
                            <div className="true-false-blk">
                              <button
                                className="s-btn-clr m-0"
                                type="submit"
                                onClick={() => {
                                  truefn('sti-5')
                                }}
                              >
                                True
                              </button>
                              <p>or</p>
                              <button
                                className="s-btn-clr"
                                type="submit"
                                onClick={() => {
                                  falseFn('sti-5')
                                }}
                              >
                                False
                              </button>
                            </div>

                            {stifivet && (
                              <div>
                                <False type="sti-5" />
                              </div>
                            )}
                            {stifivef && (
                              <div>
                                <False type="sti-5" />
                              </div>
                            )}
                          </div>
                          <button type="button" className="align-sub-content " onClick={() => goToContraception()}>
                            Next myth
                          </button>
                          <div className="spacer-20" />
                          <div className="dotted-border" />
                        </div>
                      </section>
                    </div>
                  )}
                  {contraceptionMyth && (
                    <section className="signposting-section bg-purple">
                      <div className="content-container pad-30 pt-0 pb-0 text-center">
                        <div className="pos-relative">
                          <div className="s-body bubble-box light-violet bg-bubble"> CONTRACEPTION</div>
                          <div className="chat-bubble-arrow chat-blue" />
                          <div className="spacer-10" />
                          <div className="myth-chat-bubble chat-bubble-border light-violet text-blue-myth pr-1">
                            "Fertility is affected by LARC methods."
                          </div>
                        </div>
                        <div className="container-450">
                          <div className="true-false-blk">
                            <button
                              className="s-btn-clr m-0"
                              type="submit"
                              onClick={() => {
                                truefn('myth-contraception-5')
                              }}
                            >
                              True
                            </button>
                            <p>or</p>
                            <button
                              className="s-btn-clr"
                              type="submit"
                              onClick={() => {
                                falseFn('myth-contraception-5')
                              }}
                            >
                              False
                            </button>
                          </div>

                          {Truecontraception5Fn && (
                            <div>
                              <False type="myth-contraception-5" />
                            </div>
                          )}
                          {Falseconception5Fn && (
                            <div>
                              <False type="myth-contraception-5" />
                            </div>
                          )}
                        </div>
                        <button type="button" className="align-sub-content  " onClick={() => goToConsent()}>
                          Next myth
                        </button>
                        <div className="spacer-20" />
                        <div className="dotted-border" />
                        <div className="spacer-20" />
                      </div>
                    </section>
                  )}
                  {stiMyth && (
                    <div className="with-slider-section">
                      <section className="signposting-section bg-purple">
                        <div className="content-container pad-30 pt-0 pb-0 text-center">
                          <div className="pos-relative">
                            <div className="chat-bubble-arrow chat-green" />
                            <div className="s-body bubble-box  bg-bubble-green"> STIs</div>
                            <div className="spacer-10" />
                            <div className="myth-chat-bubble chat-bubble-border-green text-green-myth pr-1">
                              “Having an STI means I'm unclean.”
                            </div>
                          </div>
                          <div className="container-450">
                            <div className="true-false-blk">
                              <button
                                className="s-btn-clr m-0"
                                type="submit"
                                onClick={() => {
                                  truefn('sti-3')
                                }}
                              >
                                True
                              </button>
                              <p>or</p>
                              <button
                                className="s-btn-clr"
                                type="submit"
                                onClick={() => {
                                  falseFn('sti-3')
                                }}
                              >
                                False
                              </button>
                            </div>

                            {stithreet && (
                              <div>
                                <False type="sti-3" />
                              </div>
                            )}
                            {stithreef && (
                              <div>
                                <False type="sti-3" />
                              </div>
                            )}
                          </div>
                          <button type="button" className="align-sub-content  " onClick={() => goToContraception()}>
                            Next myth
                          </button>
                          <div className="spacer-20" />
                          <div className="dotted-border" />
                        </div>
                      </section>
                    </div>
                  )}
                  {consentMyth && (
                    <div className="with-slider-section">
                      <section className="signposting-section bg-purple">
                        <div className="content-container pad-30 pt-0 pb-0 text-center">
                          <div className="pos-relative">
                            <div className="chat-bubble-arrow chat-pink" />
                            <div className="s-body bubble-box  bg-bubble-pink"> CONSENT</div>
                            <div className="spacer-10" />
                            <div className="myth-chat-bubble chat-bubble-border-pink text-pink-myth pr-1">
                              "It's fine to have sex with someone if they are drunk or high."
                            </div>
                          </div>
                          <div className="container-450">
                            <div className="true-false-blk">
                              <button
                                className="s-btn-clr m-0"
                                type="submit"
                                onClick={() => {
                                  truefn('consent-3')
                                }}
                              >
                                True
                              </button>
                              <p>or</p>
                              <button
                                className="s-btn-clr"
                                type="submit"
                                onClick={() => {
                                  falseFn('consent-3')
                                }}
                              >
                                False
                              </button>
                            </div>
                          </div>

                          {consentthreet && (
                            <div>
                              <False type="consent-3" />
                            </div>
                          )}
                          {consentthreef && (
                            <div>
                              <False type="consent-3" />
                            </div>
                          )}
                          <button type="button" className="align-sub-content  " onClick={() => goTobodies()}>
                            Next myth
                          </button>
                          <div className="spacer-20" />
                          <div className="dotted-border" />
                        </div>
                      </section>
                    </div>
                  )}
                  {bodiesMyth && (
                    <div className="with-slider-section">
                      <section className="signposting-section bg-purple">
                        <div className="content-container pad-30 pt-0 pb-0 text-center">
                          <div className="pos-relative">
                            <div className="chat-bubble-arrow" />
                            <div className="s-body bubble-box  bg-bubble-red"> BODIES</div>
                            <div className="spacer-10" />
                            <div className="myth-chat-bubble chat-bubble-border-red text-orange-myth pr-1">
                              “Swimming in the sea when you are on your period might attract sharks.”
                            </div>
                          </div>
                          <div className="container-450">
                            <div className="true-false-blk">
                              <button
                                className="s-btn-clr m-0"
                                type="submit"
                                onClick={() => {
                                  truefn('bodies-4')
                                }}
                              >
                                True
                              </button>
                              <p>or</p>
                              <button
                                className="s-btn-clr"
                                type="submit"
                                onClick={() => {
                                  falseFn('bodies-4')
                                }}
                              >
                                False
                              </button>
                            </div>

                            {bodiesfourt && (
                              <div>
                                <False type="bodies-4" />
                              </div>
                            )}
                            {bodiesfourf && (
                              <div>
                                <False type="bodies-4" />
                              </div>
                            )}
                          </div>
                          <button type="button" className="align-sub-content  " onClick={() => goTosti()}>
                            Next myth
                          </button>
                          <div className="spacer-20" />
                          <div className="dotted-border" />
                        </div>
                      </section>
                    </div>
                  )}
                </Slider>
              </div>
            )}
            {showmythcontraception && (
              <section className="without-slider-section">
                <div>
                  <section className="signposting-section bg-purple">
                    <div className="content-container pad-30 pt-0 pb-0 text-center">
                      <div className="pos-relative">
                        <div className="s-body bubble-box light-violet bg-bubble"> CONTRACEPTION</div>
                        <div className="chat-bubble-arrow chat-blue" />
                        <div className="spacer-10" />
                        <div className="myth-chat-bubble pr-1 chat-bubble-border text-blue-myth light-violet">
                          “Getting an implant will hurt.”
                        </div>
                      </div>
                      <div className="container-450">
                        <div className="true-false-blk">
                          <button
                            className="s-btn-clr m-0"
                            type="submit"
                            onClick={() => {
                              truefn('myth-contraception-1')
                            }}
                          >
                            True
                          </button>
                          <p>or</p>
                          <button
                            className="s-btn-clr"
                            type="submit"
                            onClick={() => {
                              falseFn('myth-contraception-1')
                            }}
                          >
                            False
                          </button>
                        </div>

                        {Truecontraception1Fn && (
                          <div>
                            <False type="myth-contraception-1" />
                          </div>
                        )}
                        {FalseconceptionFn && (
                          <div>
                            <False type="myth-contraception-1" />
                          </div>
                        )}
                      </div>
                      <div className="align-sub-content">
                        <a href="#contraception2">Next contraception myth</a>
                      </div>
                      <div className="spacer-20" />
                      <div className="dotted-border" />
                      <div className="spacer-20" />
                    </div>
                  </section>
                </div>
                <div>
                  <section id="contraception2" className="signposting-section bg-purple">
                    <div className="content-container pad-30 pt-0 pb-0 text-center">
                      <div className="pos-relative">
                        <div className="s-body bubble-box light-violet bg-bubble"> CONTRACEPTION</div>
                        <div className="chat-bubble-arrow chat-blue" />
                        <div className="spacer-10" />
                        <div className="myth-chat-bubble pr-1 chat-bubble-border light-violet text-blue-myth">
                          “You need parental permission to get contraception if you are under 16.”
                        </div>
                      </div>
                      <div className="container-450">
                        <div className="true-false-blk">
                          <button
                            className="s-btn-clr m-0"
                            type="submit"
                            onClick={() => {
                              truefn('myth-contraception-2')
                            }}
                          >
                            True
                          </button>
                          <p>or</p>
                          <button
                            className="s-btn-clr"
                            type="submit"
                            onClick={() => {
                              falseFn('myth-contraception-2')
                            }}
                          >
                            False
                          </button>
                        </div>

                        {Truecontraception2Fn && (
                          <div>
                            <False type="myth-contraception-2" />
                          </div>
                        )}
                        {Falseconception2Fn && (
                          <div>
                            <False type="myth-contraception-2" />
                          </div>
                        )}
                      </div>
                      <div className="align-sub-content">
                        <a href="#contraception3">Next contraception myth</a>
                      </div>
                      <div className="spacer-20" />
                      <div className="dotted-border" />
                      <div className="spacer-20" />
                    </div>
                  </section>
                </div>
                <div>
                  <section id="contraception3" className="signposting-section bg-purple">
                    <div className="content-container pad-30 pt-0 pb-0 text-center">
                      <div className="pos-relative">
                        <div className="s-body bubble-box light-violet bg-bubble"> CONTRACEPTION</div>
                        <div className="chat-bubble-arrow chat-blue" />
                        <div className="spacer-10" />
                        <div className="myth-chat-bubble pr-1 chat-bubble-border light-violet text-blue-myth">
                          “All types of contraception need to be taken every day.”
                        </div>
                      </div>
                      <div className="container-450">
                        <div className="true-false-blk">
                          <button
                            className="s-btn-clr m-0"
                            type="submit"
                            onClick={() => {
                              truefn('myth-contraception-3')
                            }}
                          >
                            True
                          </button>
                          <p>or</p>
                          <button
                            className="s-btn-clr"
                            type="submit"
                            onClick={() => {
                              falseFn('myth-contraception-3')
                            }}
                          >
                            False
                          </button>
                        </div>

                        {Truecontraception3Fn && (
                          <div>
                            <False type="myth-contraception-3" />
                          </div>
                        )}
                        {Falseconception3Fn && (
                          <div>
                            <False type="myth-contraception-3" />
                          </div>
                        )}
                      </div>
                      <div className="align-sub-content">
                        <a href="#contraception4">Next contraception myth</a>
                      </div>
                      <div className="spacer-20" />
                      <div className="dotted-border" />
                      <div className="spacer-20" />
                    </div>
                  </section>
                </div>
                <section id="contraception4" className="signposting-section bg-purple">
                  <div className="content-container pad-30 pt-0 pb-0 text-center">
                    <div className="pos-relative">
                      <div className="s-body bubble-box light-violet bg-bubble"> CONTRACEPTION</div>
                      <div className="chat-bubble-arrow chat-blue" />
                      <div className="spacer-10" />
                      <div className="myth-chat-bubble pr-1 chat-bubble-border light-violet text-blue-myth">
                        "You need parental permission to buy condoms if you are under 16."
                      </div>
                    </div>
                    <div className="container-450">
                      <div className="true-false-blk">
                        <button
                          className="s-btn-clr m-0"
                          type="submit"
                          onClick={() => {
                            truefn('myth-contraception-4')
                          }}
                        >
                          True
                        </button>
                        <p>or</p>
                        <button
                          className="s-btn-clr"
                          type="submit"
                          onClick={() => {
                            falseFn('myth-contraception-4')
                          }}
                        >
                          False
                        </button>
                      </div>

                      {Truecontraception4Fn && (
                        <div>
                          <False type="myth-contraception-4" />
                        </div>
                      )}
                      {Falseconception4Fn && (
                        <div>
                          <False type="myth-contraception-4" />
                        </div>
                      )}
                    </div>
                    <div className="align-sub-content">
                      <a href="#contraception5">Next contraception myth</a>
                    </div>
                    <div className="spacer-20" />
                    <div className="dotted-border" />
                    <div className="spacer-20" />
                  </div>
                </section>
                <section id="contraception5" className="signposting-section bg-purple">
                  <div className="content-container pad-30 pt-0 pb-0 text-center">
                    <div className="pos-relative">
                      <div className="s-body bubble-box light-violet bg-bubble"> CONTRACEPTION</div>
                      <div className="chat-bubble-arrow chat-blue" />
                      <div className="spacer-10" />
                      <div className="myth-chat-bubble pr-1 chat-bubble-border light-violet text-blue-myth">
                        "Fertility is affected by LARC methods."
                      </div>
                    </div>
                    <div className="container-450">
                      <div className="true-false-blk">
                        <button
                          className="s-btn-clr m-0"
                          type="submit"
                          onClick={() => {
                            truefn('myth-contraception-5')
                          }}
                        >
                          True
                        </button>
                        <p>or</p>
                        <button
                          className="s-btn-clr"
                          type="submit"
                          onClick={() => {
                            falseFn('myth-contraception-5')
                          }}
                        >
                          False
                        </button>
                      </div>

                      {Truecontraception5Fn && (
                        <div>
                          <False type="myth-contraception-5" />
                        </div>
                      )}
                      {Falseconception5Fn && (
                        <div>
                          <False type="myth-contraception-5" />
                        </div>
                      )}
                    </div>
                    <div className="align-sub-content">
                      <a href="#contraception6">Next contraception myth</a>
                    </div>
                    <div className="spacer-20" />
                    <div className="dotted-border" />
                    <div className="spacer-20" />
                  </div>
                </section>
                <section id="contraception6" className="signposting-section bg-purple">
                  <div className="content-container pad-30 pt-0 pb-0 text-center">
                    <div className="pos-relative">
                      <div className="s-body bubble-box light-violet bg-bubble"> CONTRACEPTION</div>
                      <div className="chat-bubble-arrow chat-blue" />
                      <div className="spacer-10" />
                      <div className="myth-chat-bubble pr-1 chat-bubble-border light-violet text-blue-myth">
                        "You can be fitted with the IUD or IUS when pregnant."
                      </div>
                    </div>
                    <div className="container-450">
                      <div className="true-false-blk">
                        <button
                          className="s-btn-clr m-0"
                          type="submit"
                          onClick={() => {
                            truefn('myth-contraception-6')
                          }}
                        >
                          True
                        </button>
                        <p>or</p>
                        <button
                          className="s-btn-clr"
                          type="submit"
                          onClick={() => {
                            falseFn('myth-contraception-6')
                          }}
                        >
                          False
                        </button>
                      </div>

                      {Truecontraception6Fn && (
                        <div>
                          <False type="myth-contraception-6" />
                        </div>
                      )}
                      {Falseconception6Fn && (
                        <div>
                          <False type="myth-contraception-6" />
                        </div>
                      )}
                    </div>
                    <div className="align-sub-content">
                      <a href="#contraception7">Next contraception myth</a>
                    </div>
                    <div className="spacer-20" />
                    <div className="dotted-border" />
                    <div className="spacer-20" />
                  </div>
                </section>
                <section id="contraception7" className="signposting-section bg-purple">
                  <div className="content-container pad-30 pt-0 pb-0 text-center">
                    <div className="pos-relative">
                      <div className="s-body bubble-box light-violet bg-bubble"> CONTRACEPTION</div>
                      <div className="chat-bubble-arrow chat-blue" />
                      <div className="spacer-10" />
                      <div className="myth-chat-bubble pr-1 chat-bubble-border light-violet text-blue-myth">
                        "Condoms last forever."
                      </div>
                    </div>
                    <div className="container-450">
                      <div className="true-false-blk">
                        <button
                          className="s-btn-clr m-0"
                          type="submit"
                          onClick={() => {
                            truefn('myth-contraception-7')
                          }}
                        >
                          True
                        </button>
                        <p>or</p>
                        <button
                          className="s-btn-clr"
                          type="submit"
                          onClick={() => {
                            falseFn('myth-contraception-7')
                          }}
                        >
                          False
                        </button>
                      </div>

                      {Truecontraception7Fn && (
                        <div>
                          <False type="myth-contraception-7" />
                        </div>
                      )}
                      {Falseconception7Fn && (
                        <div>
                          <False type="myth-contraception-7" />
                        </div>
                      )}
                    </div>
                    <div className="align-sub-content">
                      <a href="#">Next contraception myth</a>
                    </div>
                    <div className="spacer-20" />
                    <div className="dotted-border" />
                    <div className="spacer-20" />
                  </div>
                </section>
              </section>
            )}
            {showmythconsent && (
              <>
                <div>
                  <section className="signposting-section bg-purple">
                    <div className="content-container pad-30 pt-0 pb-0 text-center">
                      <div className="pos-relative">
                        <div className="chat-bubble-arrow chat-pink" />
                        <div className="s-body bubble-box  bg-bubble-pink"> CONSENT</div>
                        <div className="spacer-10" />
                        <div className="myth-chat-bubble pr-1 chat-bubble-border-pink text-pink-myth ">
                          “If your body is turned on then you are giving consent.”
                        </div>
                      </div>
                      <div className="container-450">
                        <div className="true-false-blk">
                          <button
                            className="s-btn-clr m-0"
                            type="submit"
                            onClick={() => {
                              truefn('consent-1')
                            }}
                          >
                            True
                          </button>
                          <p>or</p>
                          <button
                            className="s-btn-clr"
                            type="submit"
                            onClick={() => {
                              falseFn('consent-1')
                            }}
                          >
                            False
                          </button>
                        </div>

                        {consentonet && (
                          <div>
                            <False type="consent-1" />
                          </div>
                        )}
                        {consentonef && (
                          <div>
                            <False type="consent-1" />
                          </div>
                        )}
                      </div>
                      <div className="align-sub-content">
                        <a href="#consent2">Next consent myth</a>
                      </div>
                      <div className="spacer-20" />
                      <div className="dotted-border" />
                      <div className="spacer-20" />
                    </div>
                  </section>
                </div>
                <div>
                  <section id="consent2" className="signposting-section bg-purple">
                    <div className="content-container pad-30 pt-0 pb-0 text-center">
                      <div className="pos-relative">
                        <div className="chat-bubble-arrow chat-pink" />
                        <div className="s-body bubble-box  bg-bubble-pink"> CONSENT</div>
                        <div className="spacer-10" />
                        <div className="myth-chat-bubble pr-1 chat-bubble-border-pink text-pink-myth ">
                          “People who are flirty or who wear revealing clothing are 'asking for it'.”
                        </div>
                      </div>
                      <div className="container-450">
                        <div className="true-false-blk">
                          <button
                            className="s-btn-clr m-0"
                            type="submit"
                            onClick={() => {
                              truefn('consent-2')
                            }}
                          >
                            True
                          </button>
                          <p>or</p>
                          <button
                            className="s-btn-clr"
                            type="submit"
                            onClick={() => {
                              falseFn('consent-2')
                            }}
                          >
                            False
                          </button>
                        </div>
                      </div>

                      {consenttwot && (
                        <div>
                          <False type="consent-2" />
                        </div>
                      )}
                      {consenttwof && (
                        <div>
                          <False type="consent-2" />
                        </div>
                      )}
                      <div className="align-sub-content">
                        <a href="#consent3">Next consent myth</a>
                      </div>
                      <div className="spacer-20" />
                      <div className="dotted-border" />
                      <div className="spacer-20" />
                    </div>
                  </section>
                </div>
                <div>
                  <section id="consent3" className="signposting-section bg-purple">
                    <div className="content-container pad-30 pt-0 pb-0 text-center">
                      <div className="pos-relative">
                        <div className="chat-bubble-arrow chat-pink" />
                        <div className="s-body bubble-box  bg-bubble-pink"> CONSENT</div>
                        <div className="spacer-10" />
                        <div className="myth-chat-bubble chat-bubble-border-pink pr-1 text-pink-myth ">
                          "It's fine to have sex with someone if they are drunk or high."
                        </div>
                      </div>
                      <div className="container-450">
                        <div className="true-false-blk">
                          <button
                            className="s-btn-clr m-0"
                            type="submit"
                            onClick={() => {
                              truefn('consent-3')
                            }}
                          >
                            True
                          </button>
                          <p>or</p>
                          <button
                            className="s-btn-clr"
                            type="submit"
                            onClick={() => {
                              falseFn('consent-3')
                            }}
                          >
                            False
                          </button>
                        </div>
                      </div>

                      {consentthreet && (
                        <div>
                          <False type="consent-3" />
                        </div>
                      )}
                      {consentthreef && (
                        <div>
                          <False type="consent-3" />
                        </div>
                      )}
                      <div className="align-sub-content">
                        <a href="#consent4">Next consent myth</a>
                      </div>
                      <div className="spacer-20" />
                      <div className="dotted-border" />
                      <div className="spacer-20" />
                    </div>
                  </section>
                </div>
                <div>
                  <section id="consent4" className="signposting-section bg-purple">
                    <div className="content-container pad-30 pt-0 pb-0 text-center">
                      <div className="pos-relative">
                        <div className="chat-bubble-arrow chat-pink" />
                        <div className="s-body bubble-box  bg-bubble-pink"> CONSENT</div>
                        <div className="spacer-10" />
                        <div className="myth-chat-bubble chat-bubble-border-pink pr-1 text-pink-myth ">
                          "You only need consent when it comes to penetrative sex."
                        </div>
                      </div>
                      <div className="container-450">
                        <div className="true-false-blk">
                          <button
                            className="s-btn-clr m-0"
                            type="submit"
                            onClick={() => {
                              truefn('consent-4')
                            }}
                          >
                            True
                          </button>
                          <p>or</p>
                          <button
                            className="s-btn-clr"
                            type="submit"
                            onClick={() => {
                              falseFn('consent-4')
                            }}
                          >
                            False
                          </button>
                        </div>
                      </div>

                      {consentfourt && (
                        <div>
                          <False type="consent-4" />
                        </div>
                      )}
                      {consentfourf && (
                        <div>
                          <False type="consent-4" />
                        </div>
                      )}
                      <div className="align-sub-content">
                        <a href="#consent5">Next consent myth</a>
                      </div>
                      <div className="spacer-20" />
                      <div className="dotted-border" />
                      <div className="spacer-20" />
                    </div>
                  </section>
                </div>
                <div>
                  <section id="consent5" className="signposting-section bg-purple">
                    <div className="content-container pad-30 pt-0 pb-0 text-center">
                      <div className="pos-relative">
                        <div className="chat-bubble-arrow chat-pink" />
                        <div className="s-body bubble-box  bg-bubble-pink"> CONSENT</div>
                        <div className="spacer-10" />
                        <div className="myth-chat-bubble chat-bubble-border-pink pr-1 text-pink-myth ">
                          "If you are in a relationship, or if you have had sex with someone before, consent is
                          automatic."
                        </div>
                      </div>
                      <div className="container-450">
                        <div className="true-false-blk">
                          <button
                            className="s-btn-clr m-0"
                            type="submit"
                            onClick={() => {
                              truefn('consent-5')
                            }}
                          >
                            True
                          </button>
                          <p>or</p>
                          <button
                            className="s-btn-clr"
                            type="submit"
                            onClick={() => {
                              falseFn('consent-5')
                            }}
                          >
                            False
                          </button>
                        </div>
                      </div>

                      {consentfivet && (
                        <div>
                          <False type="consent-5" />
                        </div>
                      )}
                      {consentfivef && (
                        <div>
                          <False type="consent-5" />
                        </div>
                      )}
                      <div className="align-sub-content">
                        <a href="#consent6">Next consent myth</a>
                      </div>
                      <div className="spacer-20" />
                      <div className="dotted-border" />
                      <div className="spacer-20" />
                    </div>
                  </section>
                </div>
                <div>
                  <section id="consent6" className="signposting-section bg-purple">
                    <div className="content-container pad-30 pt-0 pb-0 text-center">
                      <div className="pos-relative">
                        <div className="chat-bubble-arrow chat-pink" />
                        <div className="s-body bubble-box  bg-bubble-pink"> CONSENT</div>
                        <div className="spacer-10" />
                        <div className="myth-chat-bubble chat-bubble-border-pink pr-1 text-pink-myth ">
                          "In the UK, it's illegal to have sex if you are under 16."
                        </div>
                      </div>
                      <div className="container-450">
                        <div className="true-false-blk">
                          <button
                            className="s-btn-clr m-0"
                            type="submit"
                            onClick={() => {
                              truefn('consent-6')
                            }}
                          >
                            True
                          </button>
                          <p>or</p>
                          <button
                            className="s-btn-clr"
                            type="submit"
                            onClick={() => {
                              falseFn('consent-6')
                            }}
                          >
                            False
                          </button>
                        </div>
                      </div>

                      {consentsixt && (
                        <div>
                          <False type="consent-6" />
                        </div>
                      )}
                      {consentsixf && (
                        <div>
                          <False type="consent-6" />
                        </div>
                      )}
                      <div className="align-sub-content">
                        <a href="#">Next consent myth</a>
                      </div>
                      <div className="spacer-20" />
                      <div className="dotted-border" />
                      <div className="spacer-20" />
                    </div>
                  </section>
                </div>
              </>
            )}
            {showmythbodies && (
              <>
                <div>
                  <section className="signposting-section bg-purple">
                    <div className="content-container pad-30 pt-0 pb-0 text-center">
                      <div className="pos-relative">
                        <div className="chat-bubble-arrow" />
                        <div className="s-body bubble-box  bg-bubble-red"> BODIES</div>
                        <div className="spacer-10" />
                        <div className="myth-chat-bubble chat-bubble-border-red tpr-1 ext-orange-myth ">
                          “You can't get pregnant when you are on your period.”
                        </div>
                      </div>
                      <div className="container-450">
                        <div className="true-false-blk">
                          <button
                            className="s-btn-clr m-0"
                            type="submit"
                            onClick={() => {
                              truefn('bodies-1')
                            }}
                          >
                            True
                          </button>
                          <p>or</p>
                          <button
                            className="s-btn-clr"
                            type="submit"
                            onClick={() => {
                              falseFn('bodies-1')
                            }}
                          >
                            False
                          </button>
                        </div>

                        {bodiesonet && (
                          <div>
                            <False type="bodies-1" />
                          </div>
                        )}
                        {bodiesonef && (
                          <div>
                            <False type="bodies-1" />
                          </div>
                        )}
                      </div>
                      <div className="align-sub-content">
                        <a href="#body2">Next bodies myth</a>
                      </div>
                      <div className="spacer-20" />
                      <div className="dotted-border" />
                      <div className="spacer-20" />
                    </div>
                  </section>
                </div>
                <div>
                  <section id="body2" className="signposting-section bg-purple">
                    <div className="content-container pad-30 pt-0 pb-0 text-center">
                      <div className="pos-relative">
                        <div className="chat-bubble-arrow" />
                        <div className="s-body bubble-box  bg-bubble-red"> BODIES</div>
                        <div className="spacer-10" />
                        <div className="myth-chat-bubble chat-bubble-border-red pr-1 text-orange-myth ">
                          “Puberty is the same for everyone.”
                        </div>
                      </div>
                      <div className="container-450">
                        <div className="true-false-blk">
                          <button
                            className="s-btn-clr m-0"
                            type="submit"
                            onClick={() => {
                              truefn('bodies-2')
                            }}
                          >
                            True
                          </button>
                          <p>or</p>
                          <button
                            className="s-btn-clr"
                            type="submit"
                            onClick={() => {
                              falseFn('bodies-2')
                            }}
                          >
                            False
                          </button>
                        </div>

                        {bodiestwot && (
                          <div>
                            <False type="bodies-2" />
                          </div>
                        )}
                        {bodiestwof && (
                          <div>
                            <False type="bodies-2" />
                          </div>
                        )}
                      </div>
                      <div className="align-sub-content">
                        <a href="#body3">Next bodies myth</a>
                      </div>
                      <div className="spacer-20" />
                      <div className="dotted-border" />
                      <div className="spacer-20" />
                    </div>
                  </section>
                </div>{' '}
                <div>
                  <section id="body3" className="signposting-section bg-purple">
                    <div className="content-container pad-30 pt-0 pb-0 text-center">
                      <div className="pos-relative">
                        <div className="chat-bubble-arrow" />
                        <div className="s-body bubble-box  bg-bubble-red"> BODIES</div>
                        <div className="spacer-10" />
                        <div className="myth-chat-bubble chat-bubble-border-red pr-1 text-orange-myth ">
                          “You shouldn't exercise when you are on your period.”
                        </div>
                      </div>
                      <div className="container-450">
                        <div className="true-false-blk">
                          <button
                            className="s-btn-clr m-0"
                            type="submit"
                            onClick={() => {
                              truefn('bodies-3')
                            }}
                          >
                            True
                          </button>
                          <p>or</p>
                          <button
                            className="s-btn-clr"
                            type="submit"
                            onClick={() => {
                              falseFn('bodies-3')
                            }}
                          >
                            False
                          </button>
                        </div>

                        {bodiesthreet && (
                          <div>
                            <False type="bodies-3" />
                          </div>
                        )}
                        {bodiesthreef && (
                          <div>
                            <False type="bodies-3" />
                          </div>
                        )}
                      </div>
                      <div className="align-sub-content">
                        <a href="#body4">Next bodies myth</a>
                      </div>
                      <div className="spacer-20" />
                      <div className="dotted-border" />
                      <div className="spacer-20" />
                    </div>
                  </section>
                </div>
                <div>
                  <section id="body4" className="signposting-section bg-purple">
                    <div className="content-container pad-30 pt-0 pb-0 text-center">
                      <div className="pos-relative">
                        <div className="chat-bubble-arrow" />
                        <div className="s-body bubble-box  bg-bubble-red"> BODIES</div>
                        <div className="spacer-10" />
                        <div className="myth-chat-bubble chat-bubble-border-red pr-1 text-orange-myth ">
                          “Swimming in the sea when you are on your period might attract sharks.”
                        </div>
                      </div>
                      <div className="container-450">
                        <div className="true-false-blk">
                          <button
                            className="s-btn-clr m-0"
                            type="submit"
                            onClick={() => {
                              truefn('bodies-4')
                            }}
                          >
                            True
                          </button>
                          <p>or</p>
                          <button
                            className="s-btn-clr"
                            type="submit"
                            onClick={() => {
                              falseFn('bodies-4')
                            }}
                          >
                            False
                          </button>
                        </div>

                        {bodiesfourt && (
                          <div>
                            <False type="bodies-4" />
                          </div>
                        )}
                        {bodiesfourf && (
                          <div>
                            <False type="bodies-4" />
                          </div>
                        )}
                      </div>
                      <div className="align-sub-content">
                        <a href="#body5">Next bodies myth</a>
                      </div>
                      <div className="spacer-20" />
                      <div className="dotted-border" />
                      <div className="spacer-20" />
                    </div>
                  </section>
                </div>
                <div>
                  <section id="body5" className="signposting-section bg-purple">
                    <div className="content-container pad-30 pt-0 pb-0 text-center">
                      <div className="pos-relative">
                        <div className="chat-bubble-arrow" />
                        <div className="s-body bubble-box  bg-bubble-red"> BODIES</div>
                        <div className="spacer-10" />
                        <div className="myth-chat-bubble chat-bubble-border-red pr-1 text-orange-myth ">
                          “You shouldn't have sex when you are on your period.”
                        </div>
                      </div>
                      <div className="container-450">
                        <div className="true-false-blk">
                          <button
                            className="s-btn-clr m-0"
                            type="submit"
                            onClick={() => {
                              truefn('bodies-5')
                            }}
                          >
                            True
                          </button>
                          <p>or</p>
                          <button
                            className="s-btn-clr"
                            type="submit"
                            onClick={() => {
                              falseFn('bodies-5')
                            }}
                          >
                            False
                          </button>
                        </div>

                        {bodiesfivet && (
                          <div>
                            <False type="bodies-5" />
                          </div>
                        )}
                        {bodiesfivef && (
                          <div>
                            <False type="bodies-5" />
                          </div>
                        )}
                      </div>
                      <div className="align-sub-content">
                        <a href="#body6">Next bodies myth</a>
                      </div>
                      <div className="spacer-20" />
                      <div className="dotted-border" />
                      <div className="spacer-20" />
                    </div>
                  </section>
                </div>
                <div>
                  <section id="body6" className="signposting-section bg-purple">
                    <div className="content-container pad-30 pt-0 pb-0 text-center">
                      <div className="pos-relative">
                        <div className="chat-bubble-arrow" />
                        <div className="s-body bubble-box  bg-bubble-red"> BODIES</div>
                        <div className="spacer-10" />
                        <div className="myth-chat-bubble chat-bubble-border-red pr-1 text-orange-myth ">
                          “You are no longer a virgin if you use tampons.”
                        </div>
                      </div>
                      <div className="container-450">
                        <div className="true-false-blk">
                          <button
                            className="s-btn-clr m-0"
                            type="submit"
                            onClick={() => {
                              truefn('bodies-6')
                            }}
                          >
                            True
                          </button>
                          <p>or</p>
                          <button
                            className="s-btn-clr"
                            type="submit"
                            onClick={() => {
                              falseFn('bodies-6')
                            }}
                          >
                            False
                          </button>
                        </div>

                        {bodiessixt && (
                          <div>
                            <False type="bodies-6" />
                          </div>
                        )}
                        {bodiessixf && (
                          <div>
                            <False type="bodies-6" />
                          </div>
                        )}
                      </div>
                      <div className="align-sub-content">
                        <a href="#body7">Next bodies myth</a>
                      </div>
                      <div className="spacer-20" />
                      <div className="dotted-border" />
                      <div className="spacer-20" />
                    </div>
                  </section>
                </div>
                <div>
                  <section id="body7" className="signposting-section bg-purple">
                    <div className="content-container pad-30 pt-0 pb-0 text-center">
                      <div className="pos-relative">
                        <div className="chat-bubble-arrow" />
                        <div className="s-body bubble-box  bg-bubble-red"> BODIES</div>
                        <div className="spacer-10" />
                        <div className="myth-chat-bubble chat-bubble-border-red pr-1 text-orange-myth ">
                          “The menstrual cycle lasts 28 days.”
                        </div>
                      </div>
                      <div className="container-450">
                        <div className="true-false-blk">
                          <button
                            className="s-btn-clr m-0"
                            type="submit"
                            onClick={() => {
                              truefn('bodies-7')
                            }}
                          >
                            True
                          </button>
                          <p>or</p>
                          <button
                            className="s-btn-clr"
                            type="submit"
                            onClick={() => {
                              falseFn('bodies-7')
                            }}
                          >
                            False
                          </button>
                        </div>

                        {bodiessevent && (
                          <div>
                            <False type="bodies-7" />
                          </div>
                        )}
                        {bodiessevenf && (
                          <div>
                            <False type="bodies-7" />
                          </div>
                        )}
                      </div>
                      <div className="align-sub-content">
                        <a href="#">Next bodies myth</a>
                      </div>
                      <div className="spacer-20" />
                      <div className="dotted-border" />
                      <div className="spacer-20" />
                    </div>
                  </section>
                </div>
              </>
            )}
            {showmythsti && (
              <section className="without-slider-section">
                <div>
                  <section className="signposting-section bg-purple">
                    <div className="content-container pad-30 pt-0 pb-0 text-center">
                      <div className="pos-relative">
                        <div className="chat-bubble-arrow chat-green" />
                        <div className="s-body bubble-box  bg-bubble-green"> STIs</div>
                        <div className="spacer-10" />
                        <div className="myth-chat-bubble chat-bubble-border-green pr-1 text-green-myth ">
                          “I don't have any symptoms so I can't have an STI.”
                        </div>
                      </div>
                      <div className="container-450">
                        <div className="true-false-blk">
                          <button
                            className="s-btn-clr m-0"
                            type="submit"
                            onClick={() => {
                              truefn('sti-1')
                            }}
                          >
                            True
                          </button>
                          <p>or</p>
                          <button
                            className="s-btn-clr"
                            type="submit"
                            onClick={() => {
                              falseFn('sti-1')
                            }}
                          >
                            False
                          </button>
                        </div>

                        {stionet && (
                          <div>
                            <False type="sti-1" />
                          </div>
                        )}
                        {stionef && (
                          <div>
                            <False type="sti-1" />
                          </div>
                        )}
                      </div>
                      <div className="align-sub-content">
                        <a href="#sti2">Next STI myth</a>
                      </div>
                      <div className="spacer-20" />
                      <div className="dotted-border" />
                      <div className="spacer-20" />
                    </div>
                  </section>
                </div>
                <div>
                  <section id="sti2" className="signposting-section bg-purple">
                    <div className="content-container pad-30 pt-0 pb-0 text-center">
                      <div className="pos-relative">
                        <div className="chat-bubble-arrow chat-green" />
                        <div className="s-body bubble-box  bg-bubble-green"> STIs</div>
                        <div className="spacer-10" />
                        <div className="myth-chat-bubble chat-bubble-border-green pr-1 text-green-myth ">
                          “Getting tested for STIs is difficult and painful.”
                        </div>
                      </div>
                      <div className="container-450">
                        <div className="true-false-blk">
                          <button
                            className="s-btn-clr m-0"
                            type="submit"
                            onClick={() => {
                              truefn('sti-2')
                            }}
                          >
                            True
                          </button>
                          <p>or</p>
                          <button
                            className="s-btn-clr"
                            type="submit"
                            onClick={() => {
                              falseFn('sti-2')
                            }}
                          >
                            False
                          </button>
                        </div>

                        {stitwot && (
                          <div>
                            <False type="sti-2" />
                          </div>
                        )}
                        {stitwof && (
                          <div>
                            <False type="sti-2" />
                          </div>
                        )}
                      </div>
                      <div className="align-sub-content">
                        <a href="#sti3">Next STI myth</a>
                      </div>
                      <div className="spacer-20" />
                      <div className="dotted-border" />
                      <div className="spacer-20" />
                    </div>
                  </section>
                </div>
                <div>
                  <section id="sti3" className="signposting-section bg-purple">
                    <div className="content-container pad-30 pt-0 pb-0 text-center">
                      <div className="pos-relative">
                        <div className="chat-bubble-arrow chat-green" />
                        <div className="s-body bubble-box  bg-bubble-green"> STIs</div>
                        <div className="spacer-10" />
                        <div className="myth-chat-bubble chat-bubble-border-green pr-1 text-green-myth ">
                          “Having an STI means I'm unclean.”
                        </div>
                      </div>
                      <div className="container-450">
                        <div className="true-false-blk">
                          <button
                            className="s-btn-clr m-0"
                            type="submit"
                            onClick={() => {
                              truefn('sti-3')
                            }}
                          >
                            True
                          </button>
                          <p>or</p>
                          <button
                            className="s-btn-clr"
                            type="submit"
                            onClick={() => {
                              falseFn('sti-3')
                            }}
                          >
                            False
                          </button>
                        </div>

                        {stithreet && (
                          <div>
                            <False type="sti-3" />
                          </div>
                        )}
                        {stithreef && (
                          <div>
                            <False type="sti-3" />
                          </div>
                        )}
                      </div>
                      <div className="align-sub-content">
                        <a href="#sti4">Next STI myth</a>
                      </div>
                      <div className="spacer-20" />
                      <div className="dotted-border" />
                      <div className="spacer-20" />
                    </div>
                  </section>
                </div>
                <div>
                  <section id="sti4" className="signposting-section bg-purple">
                    <div className="content-container pad-30 pt-0 pb-0 text-center">
                      <div className="pos-relative">
                        <div className="chat-bubble-arrow chat-green" />
                        <div className="s-body bubble-box  bg-bubble-green"> STIs</div>
                        <div className="spacer-10" />
                        <div className="myth-chat-bubble chat-bubble-border-green pr-1 text-green-myth ">
                          “You can only get an STI from penetrative sex.”
                        </div>
                      </div>
                      <div className="container-450">
                        <div className="true-false-blk">
                          <button
                            className="s-btn-clr m-0"
                            type="submit"
                            onClick={() => {
                              truefn('sti-4')
                            }}
                          >
                            True
                          </button>
                          <p>or</p>
                          <button
                            className="s-btn-clr"
                            type="submit"
                            onClick={() => {
                              falseFn('sti-4')
                            }}
                          >
                            False
                          </button>
                        </div>

                        {stifourt && (
                          <div>
                            <False type="sti-4" />
                          </div>
                        )}
                        {stifourf && (
                          <div>
                            <False type="sti-4" />
                          </div>
                        )}
                      </div>
                      <div className="align-sub-content">
                        <a href="#sti5">Next STI myth</a>
                      </div>
                      <div className="spacer-20" />
                      <div className="dotted-border" />
                      <div className="spacer-20" />
                    </div>
                  </section>
                </div>
                <div>
                  <section id="sti5" className="signposting-section bg-purple">
                    <div className="content-container pad-30 pt-0 pb-0 text-center">
                      <div className="pos-relative">
                        <div className="chat-bubble-arrow chat-green" />
                        <div className="s-body bubble-box  bg-bubble-green"> STIs</div>
                        <div className="spacer-10" />
                        <div className="myth-chat-bubble chat-bubble-border-green pr-1 text-green-myth ">
                          "If I ignore symptoms of an STI, it will go away on its own."
                        </div>
                      </div>
                      <div className="container-450">
                        <div className="true-false-blk">
                          <button
                            className="s-btn-clr m-0"
                            type="submit"
                            onClick={() => {
                              truefn('sti-5')
                            }}
                          >
                            True
                          </button>
                          <p>or</p>
                          <button
                            className="s-btn-clr"
                            type="submit"
                            onClick={() => {
                              falseFn('sti-5')
                            }}
                          >
                            False
                          </button>
                        </div>

                        {stifivet && (
                          <div>
                            <False type="sti-5" />
                          </div>
                        )}
                        {stifivef && (
                          <div>
                            <False type="sti-5" />
                          </div>
                        )}
                      </div>
                      <div className="align-sub-content">
                        <a href="#">Next STI myth</a>
                      </div>
                      <div className="spacer-20" />
                      <div className="dotted-border" />
                      <div className="spacer-20" />
                    </div>
                  </section>
                </div>
              </section>
            )}
            <section className="signposting-section bg-purple">
              <div className="content-container  pad-30 pt-0 pb-0">
                {(!rsti || !rconsent || !rcontraception || !rbodies) && (
                  <div>
                    <p>Choose another topic</p>
                    <div className="spacer-20" />
                  </div>
                )}
                <div className="d-flex flex-wrap choose-topic-margin">
                  {!rsti && (
                    <button type="button" className="s-body myth-contr-box bg-bubble-green " onClick={stifn}>
                      STIs
                    </button>
                  )}
                  {!rconsent && (
                    <button type="button" className="s-body myth-contr-box bg-bubble-pink  " onClick={consentfn}>
                      CONSENT
                    </button>
                  )}
                  {!rcontraception && (
                    <button type="button" className="s-body myth-contr-box bg-bubble  " onClick={contraceptionfn}>
                      CONTRACEPTION
                    </button>
                  )}
                  {!rbodies && (
                    <button type="button" className="s-body myth-contr-box bg-bubble-red  " onClick={bodiesfn}>
                      BODIES
                    </button>
                  )}
                </div>
              </div>
              <div className="spacer-20" />
            </section>
          </div>
        </div>
      </section>

      <section className="signposting-section white">
        <div className="content-container equal-space-padding">
          <h1 className="section-head dark">NEED URGENT HELP?</h1>
          <div className="spacer-10" />
          <div className="section-desc sec-desc-2-color">
            If you are worried about yourself or someone else, there are lots of places that can offer help and support.
          </div>
          <div className="spacer-20" />
          <Link className="s-btn-clr mr-3" to={appUrls.signPosting.help}>
            Find help
          </Link>
        </div>
      </section>
      <section className="signposting-section bg-purple">
        <div className="content-container equal-space-padding">
          <h1 className="section-head">OTHER PEOPLE ASK…</h1>
          <div className="spacer-20" />
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.brook.org.uk/your-life/having-sex-for-the-first-time/"
              className="ask-link"
            >
              I want to know more about having sex for the first time
            </a>
          </div>
          <div className="spacer-10" />
          <div>
            <a target="_blank" rel="noreferrer" href="https://www.brook.org.uk/topics/stis/" className="ask-link">
              {' '}
              I want to know more about STIs and getting tested{' '}
            </a>
          </div>
          <div className="spacer-10" />
          <div>
            <a target="_blank" rel="noreferrer" href="https://www.brook.org.uk/topics/sex/" className="ask-link">
              I want to prepare myself for having sex with someone new
            </a>
          </div>
          <div className="spacer-10" />
          {/* <div>
                        <Link className='ask-link' to={appUrls.signPosting.help}>I'm concerned about myself or someone else</Link>
                    </div> */}
          <div className="spacer-30" />
          <div className="dotted-border" />
        </div>
      </section>
      <SignPostingFooter />
    </div>
  )
  // }
}
export default MythBusting
