import React from 'react'

import { Link, useLocation, useHistory } from 'react-router-dom';

import { ReactComponent as AddIcon } from '../../../../assets/images/Plus.svg'
import appUrls from '../../../../services/AppURLs';
import { permissionManagementMenus } from '../../AdminOptions';

interface PermissionMgmtMenuListProps {
  addMemebrPresmission?:boolean
}

export const PermissionMgmtMenuList: React.FC<PermissionMgmtMenuListProps> = ({ addMemebrPresmission}) => {
  const location = useLocation();
  const history = useHistory();
  let ManagementMenus= permissionManagementMenus
  const handleAddTeamMember = () => {
    history.push(appUrls.admin.settings.addTeamMember);
  };
if(localStorage.getItem('role') === '2'){
  ManagementMenus = permissionManagementMenus.filter((res:any)=>{return res.value !== 'manager'})
}

  return (
    <>
      <div className='perm-mgmt-menus-list'>
        {!(location.pathname.indexOf('manage-roles/add-member') !== -1) && addMemebrPresmission ?
          <div className='team-member-blk'>
            <button
              type="submit"
              onClick={handleAddTeamMember}
              className="btn btn-primary login-btn">
              <AddIcon />
              Add team member
            </button>
          </div> 
          : null}
        {ManagementMenus &&
          ManagementMenus.map((menu, index) => {
            const activeClass = location.pathname.indexOf(menu.isPath) !== -1 ? 'active' : ''
            return (
              <Link
                key={index}
                to={menu.link}
                className={`${activeClass}`}
              >
                {menu.label}
              </Link>
            )
          })}
      </div>
    </>
  );
}