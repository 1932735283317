import React from 'react';

import '../styles/azurebutton.css';
import { ReactComponent as WindowIcon } from '../../assets/images/window-icon.svg';

interface AzureButtonProps {
  label: string;
  handleClick: (e: any) => void;
  id?: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
}

export const AzureButton: React.FC<AzureButtonProps> = ({
  label,
  handleClick,
  id,
  type = 'button'
}) => {
  return (
    <div className='azure-btn-blk'>
      <button
        className='azure-btn'
        id={id}
        type={type}
        onClick={handleClick}>
        <WindowIcon />
        <span className='azure-btn-label'>{label}</span>
      </button>
    </div>
  );
}