import appUrls from "../../services/AppURLs"

export const AuthMenu: any = [
    {
        label: 'Log in',
        link: appUrls.admin.auth.login,
    },
    // {
    //     label: 'Sign up',
    //     link: appUrls.admin.auth.signup,
    // }
]

export const authPriNavMenu: any = [
    {
        label: 'Dashboard',
        link: `${appUrls.admin.dashboard}`,
        popup: false
    },
    {
        label: 'Orders and Results',
        link: `${appUrls.admin.orderKit}?filters=Flagged`,
        isPath: ['order-kit','sync-user'],
        popup: false
    },
    {
        label: 'Appointments',
        link: `${appUrls.admin.external}?redirect=appointments`,
        popup: true
    },
    {
        label: 'Forms',
        link: `${appUrls.admin.external}?redirect=questionnaires`,
        popup: true
    },
    {
        label: 'Messaging',
        link: `${appUrls.admin.external}?redirect=notifications`,
        popup: true
    },
    // {
    //     label: 'Connect',
    //     link: `${appUrls.admin.external}?redirect=connect`,
    // },
    {
        label: 'Client Activity',
        link: `${appUrls.admin.external}?redirect=caseload`,
        popup: true
    },
    // {
    //     label: 'Admin',
    //     link: appUrls.admin.users.users,
    // }
]

export const authFeedbackTriNavMenu: any = [
    {
        label: 'Report a problem',
        link: appUrls.admin.users.problem,
    },
    {
        label: 'Give feedback',
        link: appUrls.admin.users.feedback,
    },
]

export const authMessageTrivNav: any = [
    {
        label: 'New message',
        link: appUrls.admin.users.message,
    },
    {
        label: 'Inbox',
        link: appUrls.admin.users.inbox,
    },
    {
        label: 'Sent',
        link: appUrls.admin.users.sent,
    },
    {
        label: 'Bin',
        link: appUrls.admin.users.bin,
    },

]


export const authManageSettingsSecNav: any = [
    {
        label: 'Your account',
        link: appUrls.admin.settings.accounts,
        isPath: '/admin/settings/'
    },
    {
        label: 'Manage roles',  
        link:  localStorage.getItem('role') === '2' ?appUrls.admin.settings.userPermission:appUrls.admin.settings.managerPermission,
        isPath: 'manage-roles'
    },
]

export const authManageSettingTriNav: any = [
    {
        label: 'Overview',
        link: appUrls.admin.settings.manageRoles,
    },
    {
        label: 'Set default permissions',
        link: appUrls.admin.settings.managePermission,
    }
]

export const authManageAccountSettingTriNav: any = [
    {
        label: 'Account details',
        link: appUrls.admin.settings.accounts,
    },
    {
        label: 'Edit profile',
        link: appUrls.admin.settings.editProfile,
    },
    {
        label: 'Archive account',
        link: appUrls.admin.settings.archiveAccount,
    }
]

export const authManageNotificationSecNav: any = [
    {
        label: 'New requests',
        link: appUrls.admin.users.manageRequest
    }
]

export const syncOrderTabsMenu: any = [

    {
        label: 'Orders and results',
        link: `${appUrls.admin.orderKit}?filters=Flagged`,
        isPath: 'order-kit'
    },
    {
        label: 'Sync users',
        link: `${appUrls.admin.syncUser}?filters=Flagged`,
        isPath: 'sync-users'
    },

]

export const authFormTabsMenu: any = [

    {
        label: 'Flagged',
        link: `${appUrls.admin.orderKit}?filters=Flagged`,
        isPath: 'Flagged'
    },
    {
        label: 'Approved',
        link: `${appUrls.admin.orderKit}?filters=Approved`,
        isPath: 'Approved'
    },
    {
        label: 'Rejected',
        link: `${appUrls.admin.orderKit}?filters=Rejected`,
        isPath: 'Rejected'
    },
    {
        label: 'Positive',
        link: `${appUrls.admin.orderKit}?filters=Positive`,
        isPath: 'Positive'
    },
    {
        label: 'Negative',
        link: `${appUrls.admin.orderKit}?filters=Negative`,
        isPath: 'Negative'
    },
    {
        label: 'Review needed',
        link: `${appUrls.admin.orderKit}?filters=Inconclusive`,
        isPath: 'Inconclusive'
    },
    {
        label: 'Added to Lilie',
        link: `${appUrls.admin.orderKit}?filters=Added to Lilie`,
        isPath: 'Lilie'
    },
    {
        label: 'Archive',
        link: `${appUrls.admin.orderKit}?filters=Archive`,
        isPath: 'Archive'
    },
]

export const authAdminUsersTriMenu = [
    {
        label: 'Users',
        isActive: false,
    },
    {
        label: 'Admins',
        isActive: false,
    },
    {
        label: 'Super admins',
        isActive: false,
    },
]

export const permissionManagementMenus = [
    {
        label: 'Manager',
        value: 'manager',
        link: appUrls.admin.settings.managerPermission,
        isPath: 'manage-roles/manager'
    },
    {
        label: 'User',
        value: 'user',
        link: appUrls.admin.settings.userPermission,
        isPath: 'manage-roles/user'
    },
    {
        label: 'Archived',
        value: 'archived',
        link: appUrls.admin.settings.archivePermission,
        isPath: 'manage-roles/archive-permissions'
    }
]