import React, { useContext } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import '../styles/addressdes.css'

interface UserDetailNotEligibleProps {
  children: any
}

export const UserDetailNotEligible: React.FC<UserDetailNotEligibleProps> = (
  Props: any
) => {
  const history = useHistory()

  const { theme, themeDispatch } = useContext(ThemeContext)
  function GoBackPage(){
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')    
  }


  return (
    <div className="bg-color usernoteligible-style">
      <div className="text-to-speech brook-container">
        <div className="h-div mt-4">
          <h2 className="page-header-txt">ORDER A TEST KIT</h2>
          <div className="speaker-i">
            <button
              className="no-background-border"
              onClick={(e) =>
                TextToSpeech.getInstance().playVoice('text-to-speech')
              }
            >
              <img src={Icon} alt="speaker" />
            </button>
          </div>
        </div>
        <div className="b1-style mb-3">
          Brook cannot send you a test kit if you do not consent to sharing your
          details with The Doctors Laboratory.
        </div>
        <div>
          <div className="ft-tab brook-container p-color">
            <button
              className="float-left no-background-border"
              onClick={e => GoBackPage()}
            >
              <img src={backIcon} alt="back" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
