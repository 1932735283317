import React, { useState } from 'react'

import '../../styles/SignpostingToolStyle/home.css'
import '../../styles/SignpostingToolStyle/havingsex.css'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

import PatternHalfCircle from '../../assets/images/pattern-half-circle.svg'
import PatternSquarePink from '../../assets/images/pattern-square-pink.svg'
import PatternTetris from '../../assets/images/pattern-tetris.svg'
import PatternTraingle from '../../assets/images/pattern-triangle-light-pink.svg'
import personIcon from '../../assets/images/person-icon1.png'
import rocketIcon from '../../assets/images/rocket-icon.png'
import Signbox from '../../assets/images/sign-box.svg'
import appUrls from '../../services/AppURLs'
import { False } from './False'
import { SignPostingFooter } from './SignPostingFooter'
import { SignPostingHeader } from './SignPostingHeader'

// class HavingSex extends Component {
export function HavingSex({}) {
  const [TrueFn, ShowTruefn] = useState(false)
  const [FalseFn, ShowFalsefn] = useState(false)
  const [SubmitFn, ShowContent] = useState(false)
  const [ContraceptionFn, activeContraception] = useState(false)
  const [PulloutFn, activePullout] = useState(false)
  const [StiFn, activeSti] = useState(false)
  function truefn() {
    ShowFalsefn(false)
    ShowTruefn(true)
  }
  function falseFn() {
    ShowFalsefn(true)
    ShowTruefn(false)
  }
  function submitFn() {
    if (PulloutFn || ContraceptionFn || StiFn) {
      ShowContent(true)
    }
  }
  function contraceptionFn() {
    ShowContent(false)
    activeContraception((contraceptionFn) => !contraceptionFn)
  }
  function pulloutFn() {
    ShowContent(false)
    activePullout((PulloutFn) => !PulloutFn)
  }
  function stiFn() {
    ShowContent(false)
    activeSti((StiFn) => !StiFn)
  }

  // render() {
  const tabIndex = 0
  const noTab = -1
  return (
    <div className="signposting-page having-sex">
      <Helmet>
        <title>SPT | Having Sex - Brook</title>
      </Helmet>
      <SignPostingHeader />
      <section className="signposting-section bg-red color-white">
        <div className="content-container equal-space-padding px-0">
          <div className="container-600 d-flex align-items-center">
            <h1 className="page-heading">HAVING SEX FOR THE FIRST TIME</h1>
            <img className="ml-4" src={rocketIcon} alt="rocket Icon" />
          </div>
        </div>
      </section>
      <section className="signposting-section mt-5 mb-5">
        <div className="content-container equal-space-padding container-para">
          <div className="contianer-450 card card-style">
            <div className="card-body">
              <p className="card-text">
                Sex can be a really fun and exciting part of having an intimate relationship with someone. When you
                decide to have sex for the first time, there’s no right or wrong way to do it, but there are some things
                you should consider to ensure it is safe and enjoyable for everyone involved.{' '}
              </p>
              {/* <p>Pick from the options below to find out more about having sex for the first time.</p> */}
              {/* <p>Pick from the options below to find out more about having sex for the first time.</p> */}
            </div>
          </div>
        </div>
      </section>
      <div className="zig-zag-pattern color-purple" />
      <section className="signposting-section bg-purple">
        <div className="content-container equal-space-padding py-0">
          <div className="pattern-placement">
            <img src={PatternHalfCircle} className="inline-block top-left-havingsex" alt="no-image" />
          </div>
          <div className="pattern-placement">
            <img src={PatternTetris} className="inline-block top-right-havingsex" alt="no-image" />
          </div>
        </div>
      </section>
      <section className="signposting-section bg-purple">
        <div className="content-container equal-space-padding">
          <h1 className="section-head pad-10 pl-0">SEX CAN MEAN ALL TYPES OF SEXUAL ACTIVITY, NOT JUST PENETRATION.</h1>
          <div className="sec-sub-desc pt-5">
            <p>
              When people talk about having sex for the first time, they usually mean having penetrative vaginal or anal
              sex.
            </p>
          </div>
          <div className="sec-sub-desc pt-5">
            <p>Having sex for the first time is often referred to as ‘losing your virginity’.</p>
          </div>
          <div className="sec-sub-desc pt-5">
            <p>
              However, this is usually code for penetrative sex and doesn’t take into account all the different types of
              sex that people can have.
            </p>
          </div>
          <div className="sec-sub-desc pt-5">
            <p>At Brook, we use the word ‘sex’ to include all sexual activity, not just penetration.</p>
          </div>
          <div className="sec-sub-desc pt-5">
            <p>
              There are loads of different ways to be sexually intimate with another person, to feel sensual, or to
              orgasm.{' '}
            </p>
            <div className="spacer-20" />
            <p>This means ‘sex’ and having sex ‘for the first time’ can mean different things for different people.</p>
          </div>
        </div>
      </section>
      <section className="signposting-section bg-purple">
        <div className="content-container equal-space-padding py-0">
          <div className="container-450">
            <div className="dotted-border" />
          </div>
          <div className="pattern-placement">
            <img src={Signbox} alt="no-image" className="inline-block top-right" />
          </div>
        </div>
      </section>
      <section className={TrueFn || FalseFn ? 'signposting-section bg-purple pb-5' : 'signposting-section bg-purple'}>
        <div className="content-container equal-space-padding pb-0">
          <h1 className="section-head">MYTHBUSTING</h1>
          <div className="spacer-20" />
          <div className="pos-relative">
            <div className="s-body  move-top"> BODIES</div>
            <div className="chat-bubble-arrow" />
            <div className="myth-chat-bubble">“There is no scientific way to tell if someone has had sex.”</div>
          </div>
          {!TrueFn && !FalseFn ? (
            <div>
              <div className="spacer-40" />
              <p className="section-desc section-desc-2-color">What do you think?</p>
              <div className="spacer-10" />
              <div className="dotted-border" />
            </div>
          ) : (
            ''
          )}

          <div className="container-450">
            <div className="true-false-blk">
              <button className="s-btn-clr m-0" type="submit" onClick={truefn}>
                True
              </button>
              <p>or</p>
              <button className="s-btn-clr" type="submit" onClick={falseFn}>
                False
              </button>
            </div>
            {TrueFn && (
              <div>
                <False type="home" />
              </div>
            )}
            {FalseFn && (
              <div>
                <False type="home" />
              </div>
            )}
          </div>
        </div>
      </section>
      <div className="zig-zag-pattern color-white bg-purple" />
      <section className="signposting-section white">
        <div className="content-container equal-space-padding">
          <div className="contianer-450 card card-style card-border mb-4">
            <div className="card-body">
              <h1 className="section-head purple">OTHER WAYS TO THINK ABOUT FIRST SEX</h1>
              <p className="card-text pad-10">
                Virginity has a lot of negative associations and is a concept we are trying to move away from.
              </p>
              <p className="card-text pad-10">
                Some people like to use the phrase ‘sexual debut’ instead of ‘losing your virginity’, because it doesn’t
                treat having sex for the first time as a loss of anything, or a change in your status as a person.
              </p>
              <p className="card-text pad-10">
                {' '}
                Other people prefer not to think of having sex for the first time as a moment that is any more
                significant than any other time you have sex in your life.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="signposting-section">
        <div className="content-container equal-space-padding py-0">
          <div className="pattern-placement">
            <img src={PatternSquarePink} className="inline-block top-left-havingsex" alt="no-image" />
          </div>
        </div>
      </section>
      <div className="zig-zag-pattern color-yellow" />
      <section className="signposting-section bg-yellow">
        <div className="content-container equal-space-padding">
          <div className="mw-sexual-activity">
            <h1 className="section-head dark">CONSENSUAL, HAPPY AND COMFORTABLE</h1>
            <div className="pattern-placement">
              <img src={PatternTraingle} className="inline-block top-right-havingsex" alt="no-image" />
            </div>
          </div>
          <div className="sec-desc-2-color">
            <div className="spacer-20" />
            <p>
              However you choose to think about it, the most important thing is that any sexual activity you take part
              in, both the first time and every time after that, is consensual and that you feel happy and comfortable.
            </p>
            <div className="spacer-20" />
            <a
              target="_blank"
              rel="noreferrer"
              href=" https://www.brook.org.uk/your-life/sex-and-consent/"
              tabIndex={noTab}
            >
              <button className="s-btn-clr consent-btn-bg-color" type="submit">
                More on consent
              </button>
            </a>
          </div>
        </div>
      </section>
      <div className="zig-zag-pattern color-white bg-yellow" />
      <section className="signposting-section white">
        <div className="content-container equal-space-padding pos-relative">
          <h1 className="section-head dark">HANNAH’S DILEMMA</h1>
          <div className="spacer-10" />
          <div className="sec-desc-2-color">
            <p>
              “Things have been going really well with Rashid, we’ve been dating for a few months but have been taking
              it slow.
            </p>
          </div>
          <div className="spacer-10" />
          <div className="sec-desc-2-color">
            <p>
              {' '}
              Neither of us has had sex with anyone before, so we’re both a bit nervous. He’s fingered me a few times
              and I’ve given him a few hand-jobs, and we feel like we’re ready to try oral and penis-in-vagina sex.
            </p>
          </div>
          <div className="spacer-10" />
          <div className="sec-desc-2-color">
            <p>
              We know neither of us has an STI because we’ve never done anything with anyone else, and Rashid said he’s
              fine just pulling out, but I’m really scared about an unwanted pregnancy.
            </p>
            <img className="float-right" src={personIcon} alt="person icon" />
            <p>
              I just want to be able to relax and not have to worry about getting pregnant, because it already feels
              like a big deal."
            </p>
          </div>
          <div className="spacer-20" />
        </div>
      </section>
      <section className="signposting-section white">
        <div className="content-container equal-space-padding">
          <div className="spacer-40 space-top" />
          <div className="dotted-border dark" />
          <div className="spacer-20" />
          <h1 className="section-head dark">WHAT SHOULD HANNAH DO?</h1>
          <div className="sec-desc-2-color">
            <div className="spacer-20" />
            <p>Select all the answers you think are correct</p>
          </div>
          <div className="spacer-20" />
          <a className="text-decoration-home">
            <button
              type="button"
              className={'content-button mb-4 btn-lg btn-block' + (ContraceptionFn ? ' triple-btn' : '')}
              onClick={contraceptionFn}
            >
              See what contraception is right for her
            </button>
          </a>
          <a className="text-decoration-home">
            <button
              type="button"
              className={'content-button mb-4 btn-lg btn-block' + (PulloutFn ? ' triple-btn' : '')}
              onClick={pulloutFn}
            >
              Rely on ‘pulling out’
            </button>
          </a>
          <a className="text-decoration-home">
            <button
              type="button"
              className={'content-button mb-4 btn-lg btn-block' + (StiFn ? ' triple-btn' : '')}
              onClick={stiFn}
            >
              Get an STI test anyway
            </button>
          </a>
          <a className="text-decoration-home" href="#gotoanswer" onClick={submitFn} tabIndex={noTab}>
            <button className="s-btn-clr mb-3" type="submit">
              <b>Submit</b>
            </button>
          </a>
          <div className="spacer-20" />
          <div className="dotted-border dark" />
          {/* {!SubmitFn &&
                        <div className='sec-desc-2-color'>
                            <p>Select as many as you think!</p>
                        </div>
                    } */}
          {(ContraceptionFn || PulloutFn || StiFn) && SubmitFn && (
            <div className="answer" id="gotoanswer">
              <p className="view-answer">ANSWER</p>
              <p>
                It can be harder to get excited about sex if, like Hannah, you’re worried about pregnancy, so making
                decisions about contraception before having penetrative sex is a great idea! It is also important to
                keep in mind that you can still get a sexually transmitted infection (STI) even if it is your first time
                having sex. This is because some STIs can be transmitted in other ways and can even be inherited from
                parents.
              </p>
              <div className="spacer-20" />
              <p>
                {' '}
                "Pulling out", which is when the penis is removed from the vagina before ejaculation (cumming), is not a
                reliable method of contraception. It is hard to get it right every time and there is also the
                possibility that sperm hanging around in the penis from any recent ejaculations may find their way into
                the vagina.
              </p>
              <div className="spacer-20" />
              <p>
                {' '}
                There are lots of different types of contraception that Hannah could look into. It might take a bit of
                time to find the one that's right for her, but her local sexual health service will be able to offer
                advice.{' '}
              </p>
              <div className="spacer-20" />
              <p>
                Sexual health services (and sometimes GP surgeries) can also provide free condoms. Condoms are the only
                method of contraception that also protects against STIs. This means they could be a really good option
                for Hannah and Rashid whilst Hannah makes choices about other contraception methods and until they have
                both taken STI tests.
              </p>
            </div>
          )}
        </div>
      </section>
      <section className="signposting-section white">
        <div className="content-container pt-0 equal-space-padding">
          <h1 className="section-head dark">NEED URGENT HELP?</h1>
          <div className="spacer-10" />
          <div className="section-desc sec-desc-2-color">
            If you are worried about yourself or someone else, there are lots of places that can offer help and support.
          </div>
          <div className="spacer-20" />
          <Link className="s-btn-clr mr-3" to={appUrls.signPosting.help}>
            Find help{' '}
          </Link>
        </div>
      </section>
      <section className="signposting-section bg-purple">
        <div className="content-container equal-space-padding">
          <h1 className="section-head">OTHER PEOPLE ASK…</h1>
          <div className="spacer-20" />
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.brook.org.uk/your-life/having-sex-for-the-first-time/"
              className="ask-link"
            >
              I want to know more about having sex for the first time
            </a>
          </div>
          <div className="spacer-10" />
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.brook.org.uk/your-life/what-is-virginity/"
              className="ask-link"
            >
              {' '}
              What is ‘virginity’?
            </a>
          </div>
          <div className="spacer-10" />
          <div>
            <a target="_blank" rel="noreferrer" href="https://www.brook.org.uk/topics/sex/" className="ask-link">
              I want to know more about different types of sex
            </a>
          </div>
          <div className="spacer-30" />
          <div className="dotted-border" />
        </div>
      </section>
      <SignPostingFooter />
    </div>
  )
  // }
}
export default HavingSex
