import React from 'react'

import DataTable from 'react-data-table-component';
import '../styles/customTable.css';

// import Pagination from '../pagination/pagination';

interface CustomDataTableProps {
  data: any;
  columns: any;
  rowsPerPage?: number;
  paginationTotalRows?: any,
  onChangePagination: (e: any) => void;
  inc: number;
  onRowSelected?: any;
  selectedRow?: any;
}

export const CustomDataTable: React.FC<CustomDataTableProps> = ({
  data = [],
  columns = [],
  // rowsPerPage = 10,
  paginationTotalRows,
  onChangePagination,
  inc,
  onRowSelected,
  selectedRow
}) => {

  const handleRowSelected = ({ selectedRows }: any) => {
    onRowSelected(selectedRows);
  };

  // Create custom check box on row select
  const CheckBox: any = React.forwardRef<any, any>(({ onClick, ...rest }, ref) => {
    const id = 'select-all-row'
    return (
      <div className="custom-checkbox">
        <label className="check-label" id={id}>
          <input
            htmlFor={id}
            type="checkbox"
            className="check-input"
            ref={ref}
            onClick={onClick}
            {...rest}
          />
          <span className="checkmark" />
        </label>
      </div>
    );
  });

  return (
    <div className='table-section'>
      <DataTable
        noHeader={false}
        columns={columns}
        data={data}
        selectableRows={selectedRow === false ? false: true}
        selectableRowsComponent={CheckBox}
        onSelectedRowsChange={handleRowSelected}
        paginationServer
        paginationTotalRows={paginationTotalRows}
        // paginationPerPage={rowsPerPage}
      />
      {/* <Pagination
        callback={(pageNumber: number) => onChangePagination(pageNumber)}
        length={data?.length}
        inc={inc}
      /> */}
    </div>
  );
}