import React, { useState, useEffect } from 'react'

import Select from 'react-select'

import dropdownIcon from '../../../../assets/images/dropdown.svg'
import Popup from '../../../../components/admin/popup';
import { SecNav } from '../../../../components/admin/SecNav'
import { StaticTriNav } from '../../../../components/admin/StaticTrivNav'
import { TableView } from '../../../../components/admin/TableView'
import useAuthContext from '../../../../context/provider/AuthContext'
import { IUserInfo } from '../../../../models/Types'
import apiFunctions from '../../../../services/api-service'
import { getPostcodeRegion } from '../../../../services/api/PostcodesAPI'
import appUrls from '../../../../services/AppURLs';
import { AdminLayout } from '../../AdminLayout'
import { authAdminUsersTriMenu, authPriNavMenu } from '../../AdminOptions'
import FetchData from '../forms/FetchData'

interface UsersProps {
  userDetails?: IUserInfo
}

export const Users: React.FC<UsersProps> = ({ userDetails }) => {
  const [mylocation, setLocation] = useState('')

  const [showPopup, setshowPopup] = useState(false)
  const [showModal, setshowModal] = useState(false)
  const [showCreatenewadmin, setShowCreatenewadmin] = useState(true)
  const [regionListSelect, setRegionListSelect] = useState([])
  const [secNavMenu, setSecNavMenu] = useState([
    {
      label: `People at Brook ${mylocation}`,
      link: appUrls.admin.users.users,
      isActive: true,
    },
  ])
  const [StaticTriNavMenu, setStaticTriNavMenu] = useState(authAdminUsersTriMenu)
  const [activeTab, setActiveTab] = useState('Profile')

  const [UserData, setUserData] = useState({
    user_id: '',
    roleId: 0,
  })

  const closeModal = () => {
    setshowModal(false)
    setshowPopup(false)
  }

  const toggleTab = (event: any) => {
    event.stopPropagation()
    event.preventDefault()
    let menu: any = StaticTriNavMenu.find((res) => res.label === event.target.innerText)
    StaticTriNavMenu.map((res) => (res.isActive = false))
    menu['isActive'] = true
    setStaticTriNavMenu([...StaticTriNavMenu])
    setActiveTab(event.target.innerText)
  }
  const {
    state: { user },
  } = useAuthContext()
let ud:any = user;

  useEffect(() => {

    async function getRegion() {
      try {
        const regionList = await getPostcodeRegion();
        if (regionList) {
          let modSelectRegionList = regionList.data.data.map((region: string) => { return { label: region, value: region } });
          modSelectRegionList.unshift({ value: 'all', label: 'All' });
          setRegionListSelect(modSelectRegionList)
        }
      } catch (error) {
        console.log('regionListError', error);
      }

    }
    getRegion();

    apiFunctions
      .get(process.env.REACT_APP_SERVICE_BACKEND_URL + '/auth/get-user-data')
      .then((_result) => {
        setUserData({
          user_id: _result.data.data.user.id,
          roleId: _result.data.data.user.role,
        })
      })
      .catch((err) => {
        console.log('err.response = ', err)
      })
    setActiveTab('Users');
  }, [])

  useEffect(() => {
    const menuChanges = StaticTriNavMenu.map((item: any) => {
      let objCopy = { ...item }
      if (item.label === activeTab) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }
      return item
    })
    setStaticTriNavMenu(menuChanges);
  }, [activeTab])

  const User = FetchData(process.env.REACT_APP_SERVICE_BACKEND_URL + '/admin/get_users')(TableView)({
    tableHead: { type: 'users', titles: ['Name', 'View client data', 'Manage flags', 'Contact clients'] },
  })



  const Admins = FetchData(process.env.REACT_APP_SERVICE_BACKEND_URL + '/admin/get-admins')(TableView)({
    tableHead: { type: 'admins', titles: ['Name', 'View client data', 'Manage flags', 'Contact clients', 'Archive'] },
  })

  const SuperAdmins = FetchData(process.env.REACT_APP_SERVICE_BACKEND_URL + '/admin/get-superadmins')(TableView)({
    tableHead: {
      type: 'superadmins',
      titles: ['Name', 'Edit admin permissions', 'Create new admins', 'Remove admins'],
    },
  });




  const showcallback = (result: any) => {
    setshowModal(false)
    setshowPopup(true)
  }

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: '#fff',
      padding: 20,
      color: '#000',
    }),
  }
  const tabIndex=0;
  return (
    <AdminLayout getMenuList={authPriNavMenu} roleMode="Super admin mode">
      <SecNav getMenuList={secNavMenu} />
      <div className="parent-pos">
        <Select
          onChange={(e: any) => {
            localStorage.setItem('location', e?.label)
            let labelVal = (e.label === 'All') ? '' : e.label
            setSecNavMenu([
              {
                label: `People at Brook ${labelVal}`,
                link: appUrls.admin.settings.manageRoles,
                isActive: true,
              },
            ])
            setLocation(e?.label)
          }}
          aria-label="react-sel"
          value={{ value: (mylocation === "") ? "All" : mylocation, label: (mylocation === "") ? "All" : mylocation }}
          styles={customStyles}
          placeholder="Select Brook location"
          classNamePrefix="order-kit-dropdown"
          options={regionListSelect}
        />
        <div className="arrow-icon arrow-icon-order">
          <img src={dropdownIcon} className="arrow-pos" alt="speaker" />
        </div>
      </div>
      <h1 className="page-heading" tabIndex={tabIndex}>Manage {activeTab ? activeTab.slice(0, -1).toLowerCase() : 'user'} permissions</h1>
      <>
        <StaticTriNav onClick={toggleTab} getMenuList={StaticTriNavMenu} />
        {UserData && UserData.roleId === 1 ? (
          <div className="pos-relative">
            <button type="button" className="btn btn-primary pos-admin" onClick={() => setshowModal(true)}>
              Create new admin
            </button>
          </div>
        ) : (
          ''
        )}
        {activeTab === 'Admins' && UserData && UserData.roleId !== 2 ? <Admins /> : activeTab === 'Super admins' && UserData && UserData.roleId !== 2 ? <SuperAdmins /> : <User />}
      </>
      <Popup showRequest={showModal} callback={showcallback} close={closeModal} />
      <Popup showAdminsuccess={showPopup} close={closeModal} />
    </AdminLayout>
  )
}
