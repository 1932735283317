import React, { useEffect, useState } from 'react'

interface AdminTextAreaProps {
    txtcontent: React.Dispatch<React.SetStateAction<string>>;
    value: string
    type: 'feedback' | 'report'
}

export const AdminTextArea: React.FC<AdminTextAreaProps> = ({ txtcontent, value, type }) => {

    const [content, SetContent] = useState('');

    useEffect(() => {
        txtcontent(content)
    }, [content])
    useEffect(() => {
        SetContent(value)
    }, [value])

    return (
        <div className="form-group mt-4">
            <textarea aria-label='feedack' className="form-control admin-textarea-style" placeholder={`${type === 'feedback' ? 'Type feedback...' : 'Tell us your problem...'}`} value={content} onChange={e => { SetContent(e.target.value) }} />
        </div>
    );
}