import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import { setToken } from '../services/api-service'
import { validateLogin } from '../services/api/AuthAPI'
import appUrls from '../services/AppURLs'

interface ValidateLoginProps {
  children: any
}

export const ValidateLogin: React.FC<ValidateLoginProps> = (Props: any) => {
  const searchParams = new URLSearchParams(window.location.search)
  const token = searchParams.get('token')
  const redirect = searchParams.get('redirect') || btoa(appUrls.admin.dashboard)
  const history = useHistory()
  const [msg, setMSG] = useState('redirecting...')

  useEffect(() => {
    _validateLogin()
  }, [])

  const _validateLogin = async () => {
    try {
      setToken(token, null)
      let result = await validateLogin()
      console.log(result.status)
      if (result.status === 200) {
        const redirectURL = atob(redirect)
        history.push(redirectURL)
      }
    } catch (ex) {
      console.log('ex', ex)
      setMSG('Authentication Failed!')
    }
  }
  return <div>{msg}</div>
}
