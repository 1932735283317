import React, { useCallback, useEffect, useState } from 'react'

import useAuthContext from '../../context/provider/AuthContext';
import apiFunctions from '../../services/api-service';
import Loader from '../Loader/loader';
import '../styles/InLilie.css';

interface InLilieProps {
    status?: string
    header?: string
    adminId?: any
    type?: any
    myobj?: any
}

export const InLilie: React.FC<InLilieProps> = ({ status, adminId, type, myobj }) => {


    const [showLoader, SetShowLoader] = useState(false);

    const [flgaggedReason, setFlaggedReason] = useState<Array<string>>([]);

    const [Checked, Setchecked] = useState(status === 'added' ? true : false)

    const [approverName, setapprovername] = useState('');

    const roletype = String(localStorage.getItem('role'));

    const[lilieaccessinflag,setLilieaccessinflag] = useState(false)

    const {state: { user },} = useAuthContext()
    const permissionData:any = user;
    let permissionAccess = permissionData && permissionData.user ? permissionData.user:permissionData;
    let permissionAccessresult = permissionData && permissionData.user ? permissionData.user:permissionData;
    permissionAccess = permissionAccess && permissionAccess.permissions?.orders_and_results.filter((ele:any)=>{return ele.name === 'add_lilie_flagged'});
    permissionAccessresult = permissionAccessresult && permissionAccessresult.permissions?.orders_and_results.filter((ele:any)=>{return ele.name === 'add_lilie_result'});
   
    const changeStatus = useCallback((__val: any) => {
        localStorage.removeItem('archive_list');
        SetShowLoader(true);
        if (type === 'orderKit') {
            apiFunctions.put(process.env.REACT_APP_SERVICE_BACKEND_URL + '/admin/changeIsLilie', { checked: __val, type, id: adminId, name: (myobj.first_name + '' + myobj.last_name) }).then(() => {
                window.location.reload();
            }).catch(err => {
                window.location.reload();
            })
        } else {
            apiFunctions.put(process.env.REACT_APP_SERVICE_BACKEND_URL + '/admin/permissionChange', { checked: __val, type, id: adminId, name: (myobj.first_name + '' + myobj.last_name) }).then(() => {
                window.location.reload();
            }).catch(err => {
                window.location.reload();
            })
        }
    }, [Checked, status, adminId, type, myobj])

    function Capitalize(str: any) {
        str = str.split(" ");
        for (let i = 0; i < str.length; i++) {
            str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
        }
        const CapitalLetter = str.join(" ");
        return CapitalLetter;
    }
    useEffect(() => {
        let approvername = '', reason: string[] = [];   
        if (type === 'orderKit' && myobj) {
            Setchecked(Boolean(myobj.isLilie))
            if (myobj.isLilie && myobj.history.length && myobj.history[0].administrator) {
                approvername = myobj.history[0].administrator.name;
            }
            if (myobj.age < 16) {
                reason.push('age');
            }
            if (myobj.flags.length > 0) {
                myobj.flags.map((e: any) => {
                    reason.push(e)
                })
            }
        }
        setFlaggedReason(reason);
        setapprovername(approvername);
    }, [status, adminId, type, myobj])

const checkSearchFilter = ()=>{
    let result = false;
    if(myobj && myobj.tdl_status === null
        && new URL(window.location.href).searchParams.get('filters') === 'Search results'
         && myobj.history.filter((his: any) => his.action === 'reject').length ===0){
            result = true;
         }
     return result;     
}
    return (
        <>
            {showLoader && <Loader />}
            {
                // || new URL(window.location.href).searchParams.get('filters') === 'Search results'
                (!Boolean(flgaggedReason.length) && type === 'orderKit' && myobj && myobj.result && myobj.result.length === 0) || new URL(window.location.href).searchParams.get('filters') === 'Flagged' || checkSearchFilter()
                ?
                    <div className="in-lilie">
                        <p>-</p>
                    </div>
                    :
                    status === 'not added' ?
                        <div className={`in-lilie ${new URL(window.location.href).searchParams.get('filters') === 'Archive' || (type === 'orderKit' && myobj && myobj.archive_status === true) ? 'disable' : ''}`}>
                            <label className="container" id={`${!flgaggedReason.length && myobj && myobj.result && myobj.result.length === 0 ? 'checkbox-pr' : ''}`}> <span className='sr-only'>user permission</span>
                                {
                                    type === 'orderKit' ? <input type="checkbox" onChange={e => { changeStatus(e.target.checked) }} checked={Checked} disabled={new URL(window.location.href).searchParams.get('filters') === 'Search Result'  || ((new URL(window.location.href).searchParams.get('filters') === 'Approved') || (new URL(window.location.href).searchParams.get('filters') === 'Rejected')) && (permissionAccess && permissionAccess[0].value ? false : true) || ((new URL(window.location.href).searchParams.get('filters') === 'Positive') ||(new URL(window.location.href).searchParams.get('filters') === 'Negative') ||(new URL(window.location.href).searchParams.get('filters')=== 'Inconclusive')) && (permissionAccessresult && permissionAccessresult[0].value ? false : true) || !Boolean(flgaggedReason.length) && myobj && myobj.result && myobj.result.length === 0 || type === 'admin-history'} /> : <input type="checkbox" onChange={e => { changeStatus(e.target.checked) }} checked={Checked} disabled={new URL(window.location.href).searchParams.get('filters') === 'Search results' || ((new URL(window.location.href).searchParams.get('filters') === 'Approved') || (new URL(window.location.href).searchParams.get('filters') === 'Rejected')) && (permissionAccess && permissionAccess[0].value ? false : true) || ((new URL(window.location.href).searchParams.get('filters') === 'Positive') ||(new URL(window.location.href).searchParams.get('filters') === 'Negative') ||(new URL(window.location.href).searchParams.get('filters')=== 'Inconclusive')) && (permissionAccessresult && permissionAccessresult[0].value ? false : true) || type === 'admin-history'} />
                                }
                                <span className="checkmark" />
                            </label>
                        </div> :
                        status === 'added' ?
                            <div className={`in-lilie ${new URL(window.location.href).searchParams.get('filters') === 'Archive' || (type === 'orderKit' && myobj && myobj.archive_status === true)  ? 'disable' : ''}`}>
                                <label className="container" id={`${!flgaggedReason.length && myobj && myobj.result && myobj.result.length === 0 ? 'checkbox-pr' : ''}`}>
                                    <span className='sr-only'>list</span>
                                    {
                                        type === 'orderKit' ? <input type="checkbox" onChange={e => { changeStatus(e.target.checked) }} checked={Checked} disabled={((new URL(window.location.href).searchParams.get('filters') === 'Approved') || (new URL(window.location.href).searchParams.get('filters') === 'Rejected')) && (permissionAccess && permissionAccess[0].value ? false : true) || ((new URL(window.location.href).searchParams.get('filters') === 'Positive') ||(new URL(window.location.href).searchParams.get('filters') === 'Negative') ||(new URL(window.location.href).searchParams.get('filters')=== 'Inconclusive')) && (permissionAccessresult && permissionAccessresult[0].value ? false : true) || !Boolean(flgaggedReason.length) && myobj && myobj.result && myobj.result.length === 0 || type === 'admin-history'} /> : <input type="checkbox" onChange={e => { changeStatus(e.target.checked) }} checked={Checked} disabled={((new URL(window.location.href).searchParams.get('filters') === 'Approved') || (new URL(window.location.href).searchParams.get('filters') === 'Rejected')) && (permissionAccess && permissionAccess[0].value ? false : true) || ((new URL(window.location.href).searchParams.get('filters') === 'Positive') ||(new URL(window.location.href).searchParams.get('filters') === 'Negative') || (new URL(window.location.href).searchParams.get('filters')=== 'Inconclusive')) && (permissionAccessresult && permissionAccessresult[0].value ? false : true) || type === 'admin-history'} />
                                    }
                                    <span className="checkmark" />
                                </label>
                                <div className={`${approverName === '' ? 'd-none' : ''} ${approverName === 'superadmin' ? 'align-pad' : 'add-pad'}`}>
                                    <p className="table-lisa-text black">Added by</p>
                                    <p className="table-lisa-text">{Capitalize(approverName)}</p>
                                </div>
                            </div> :
                            <div className="in-lilie">
                                <p>--</p>
                            </div>
            }
        </>
    );
}