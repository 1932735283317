import React, { useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import '../styles/hepatitis.css'
import '../styles/typeofsex.css'
import { stiKitLoaderPercentage } from '../utils/stiKitLoaderPercentage'

interface CustomiseKitOneProps { }

const recommendedlist = [
  {
    title: 'You have had unprotected sexual contact (sex without a condom) with multiple partners',
  },
  {
    title: 'You have had unprotected anal sex (without a condom) with multiple partners',
  },
  {
    title:
      'You have had unprotected sexual contact (sex without a condom) with someone who you know has multiple partners',
  },
  {
    title: 'You are a man who has unprotected sex with men',
  },
  {
    title: 'You have had sex when you were too drunk or high to know for sure if it was safe',
  },
  {
    title: 'You have a current or previous partner with HIV, or have had sexual contact with someone who has HIV',
  },
  {
    title: 'You engage in chemsex (using drugs to help or enhance sex)',
  },
  {
    title:
      'You inject drugs and share equipment or you have had sex with somebody who has injected drugs and shared equipment',
  },
  {
    title: 'You have had hepatitis B or hepatitis C in the past',
  },
  {
    title:
      "You have experienced sexual assault (it's if you haven't reported it - we won't make you but we can offer you help and support if you want)",
  },
  {
    title: 'You have been paid for sex or you have paid someone else for sex',
  },
]
const throatRectumKey = 'I also need swab for my throat and rectum'

export const CustomiseKitOne: React.FC<CustomiseKitOneProps> = () => {
  const { theme, themeDispatch } = useContext(ThemeContext)
  const history = useHistory()
  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('checkBoxId')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  useEffect(() => {
    console.log('log theme :>>', theme.quesObj)
    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: stiKitLoaderPercentage.CUSTOMISE_YOUR_KIT },
    })
  }, [])

  const handleOnNext = () => {
    history.push('/customise-kit2')
  }

  const GoBackPage = () => {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }

  return (
    <div className="bg-color Regular-global successpostcode-style">
      <div className="brook-container pad-bot">
        <div className="text-to-speech">
          <div className="h-div mt-4">
            <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
            <div className="speaker-i">
              <button
                className="no-background-border"
                onClick={(e) => {
                  TextToSpeech.getInstance().playVoice('text-to-speech')
                  setisAccessibilityVoiceOver(true)
                }}
              >
                <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
              </button>
            </div>
          </div>
          <div className="hepatitis-content-blk">
            <h1 className={`b1-style ${theme.theme}-txt-global`}>Edit your kit: blood sample for HIV and syphilis</h1>
            <h4 className={`b4-style ${theme.theme}-txt-global hepatitis-subtitle mb-3`}>
              We are able to offer an at-home blood sample for people who need to test for HIV and syphilis.
            </h4>
            <h5 className={`b4-style ${theme.theme}-txt-global hepatitis-subtitle mb-3`}>
              {theme.quesObj && theme.quesObj[throatRectumKey] === 'yes'
                ? 'These tests are recommended if:'
                : 'These tests are not necessary for everyone, but are recommended if:'}
            </h5>
            <ul>
              {recommendedlist.map((item: any, idx: number) => {
                return (
                  <li
                    key={`hepatitis-${idx}`}
                    className={`b2-style ${theme.theme}-txt-global label-font hepatitis-title`}
                  >
                    {item.title}
                  </li>
                )
              })}
            </ul>
            <div className="ft-tab brook-container g-color">
              <button className="float-left no-background-border pos-re" onClick={(e) => GoBackPage()}>
                <img src={backIcon} alt="back" />
              </button>
              <Button
                buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                buttonText="Next"
                onClick={handleOnNext}
                isDisabled={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
