import React, { useCallback, useContext, useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import '../styles/postcodeageverification.css'
import { Button } from '../components/button/Button'
import { Select } from '../components/select/select'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import { getTFourPlusDisease } from '../utils/getTFourPlusDisease'
import { stiKitLoaderPercentage } from '../utils/stiKitLoaderPercentage'

interface PostcodeageverificationProps {
  children: any
}

export const Postcodeageverification: React.FC<PostcodeageverificationProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)

  const history = useHistory()

  const [age, selectAge] = useState<string>('')
  const [date_of_birth, selectDOB] = useState<string>('')
  const isTFourPlusDisease:any = getTFourPlusDisease(theme)

  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('postageverification')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  const proceed = useCallback(() => {
    TextToSpeech.getInstance().playVoice(null, 'stop')
    setisAccessibilityVoiceOver(false)
    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: stiKitLoaderPercentage.POST_CODE_AGE },
    })

    themeDispatch({
      type: 'SET_AGE',
      payload: { age: Number(age) },
    })

    themeDispatch({
      type: 'SET_QUES',
      payload: {
        quesObj: {},
      },
    })


    if (Number(age) > 15) {
      if (Number(age) === 25 && (isTFourPlusDisease === true)) {
        Props.history.push('/postage')
      }
      else if(Number(age) >= 25 && (isTFourPlusDisease !== true)){
        Props.history.push('/agebarrier')
      }
      else if(Number(age) > 19 && (isTFourPlusDisease === 'isManchester')){
        Props.history.push('/agebarrier')
      } 
      else {
        Props.history.push('/postage')
      }
    } 
    else if(Number(age) <= 12){
      Props.history.push('/agebarrier')
    }
    else {
      Props.history.push('/under16sti')
    }
  }, [age])

  useEffect(() => {
    selectAge(String(theme.age))
    selectDOB(String(''))
  }, [])

  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }
  return (
    <div className={`bg-color postcodeage-style ${theme.theme}-global`}>
      <div className="brook-container pad-bot">
        <div className="text-to-speech">
          <div className="h-div">
            <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
            <div className="speaker-i">
              <button
                className="no-background-border"
                onClick={(e) => {
                  TextToSpeech.getInstance().playVoice(
                    null,
                    'ORDER A TEST KIT.  Age. This is how many years old you are today (even if it’s your birthday very soon). We need to know this so we can find the right options for you. We are only able to provide home test kits for people of certain ages, but we will still help you access a test even if we can’t offer you and at-home one.'
                  )
                  setisAccessibilityVoiceOver(true)
                }}
              >
                <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
              </button>
            </div>
          </div>
          <div className={`b1-style ${theme.theme}-txt-global`}>Age</div>
          <Select
            id="postageverification"
            selected={age}
            onChange={(e) => {
              selectAge(e.target.value.toString())
              selectDOB('')
            }}
          />
          <div>
            <div className={`mar-pos p2-style ${theme.theme}-txt-global`}>
              This is how many years old you are today (even if it’s your birthday very soon).
              <br />
            </div>
            <div className={`p3-style p2-style ${theme.theme}-txt-global`}>
              We need to know this so we can find the right options for you.
              <br />
            </div>
            <div className={`p3-style p2-style ${theme.theme}-txt-global mb-4`}>
              We are only able to provide home test kits for people of certain ages, but we will still help you access a
              test even if we can't offer you and at-home one.
            </div>
          </div>
          <div className={`ft-tab brook-container ${theme.theme}-skin-color`}>
            <button className="float-left no-background-border" onClick={(e) => GoBackPage()}>
              <img src={backIcon} alt="back" />
            </button>
            <Button
              isDisabled={age === '0' || age === ''}
              buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
              buttonText="Next"
              onClick={(e) => proceed()}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
