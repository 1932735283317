import React from 'react'
import '../styles/TriNav.css';

export interface MenuListProps {
    label: string
    link?: string
    isActive?: boolean

}

interface StaticTriNavProps {
    getMenuList?: Array<MenuListProps>
    children?: any;
    Archiveactive?: boolean;
    unArchive?: boolean;
    Archivecallback?: (res: any) => void
    onClick?: (res: any) => void
}

export const StaticTriNav: React.FC<StaticTriNavProps> = ({ children, getMenuList, Archiveactive, unArchive, Archivecallback, onClick }) => {
    const ArchiveAccount = (unarchive: any) => {
        if (Archivecallback) Archivecallback(unarchive ? 'unarchive' : 'archive')
    }
    const tabIndex = 0;
    return (
        <>
            <div className="tri-nav-wrap">
                <div className="tri-nav-blk">
                    {Archiveactive ? (<div className='archive-btn'>
                        <button className="approve-kit-btn reject archive-sub" onClick={e => ArchiveAccount(unArchive ? true : false)}>{unArchive ? 'Unarchive' : 'Archive'} account</button>
                    </div>
                    ) : ''}

                    {getMenuList &&
                        getMenuList.map((menu, index) => (
                            localStorage.getItem('role') === '2' && menu.label === 'Users' ?
                                <div
                                    key={index}
                                    // to={menu.link || ''}
                                    className={` ${menu.isActive && 'active'} cursor-pointer onkeyup`}
                                    tabIndex={tabIndex}
                                    onClick={onClick}
                                >
                                    {menu.label}
                                </div> : localStorage.getItem('role') === '1' ? <div
                                    key={index}
                                    // to={menu.link || ''}
                                    className={` ${menu.isActive && 'active'} cursor-pointer onkeyup`}
                                    tabIndex={tabIndex}
                                    onClick={onClick}
                                >
                                    {menu.label}
                                </div> : null

                        ))}
                </div>
                <hr />
            </div>
        </>
    );
}