import React, { useState } from 'react'

import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

import '../../styles/SignpostingToolStyle/home.css'
import '../../styles/SignpostingToolStyle/havingsex.css'
import personIcon2 from '../../assets/images/person-icon2.png'
import unprotectedIcon from '../../assets/images/unprotected-sex-icon.png'
import appUrls from '../../services/AppURLs'
import { False } from './False'
import { SignPostingFooter } from './SignPostingFooter'
import { SignPostingHeader } from './SignPostingHeader'

export function UnprotectedSex({}) {
  const [TrueFn, ShowTruefn] = useState(false)
  const [FalseFn, ShowFalsefn] = useState(false)
  const [Showbtn, ShowThreeButtons] = useState(false)
  const [Hidebtn, HideWhatNextBtn] = useState(false)
  const [SubmitFn, ShowContent] = useState(false)
  const [ContraceptionFn, activeContraception] = useState(false)
  const [RegularStiFn, activeRegularsti] = useState(false)
  const [StiFn, activeSti] = useState(false)
  function truefn() {
    ShowFalsefn(false)
    ShowTruefn(true)
  }
  function falseFn() {
    ShowFalsefn(true)
    ShowTruefn(false)
  }
  function alexado() {
    HideWhatNextBtn(true)
    ShowThreeButtons(true)
  }
  function submitFn() {
    if (RegularStiFn || ContraceptionFn || StiFn) {
      ShowContent(true)
    }
  }

  function regularStiFn() {
    ShowContent(false)
    activeRegularsti((RegularStiFn) => !RegularStiFn)
  }
  function contraceptionFn() {
    ShowContent(false)
    activeContraception((contraceptionFn) => !contraceptionFn)
  }
  function stiFn() {
    ShowContent(false)
    activeSti((StiFn) => !StiFn)
  }
  const tabIndex = 0
  const noTab = -1
  return (
    <div className="signposting-page">
      <Helmet>
        <title>SPT | Unprotected Sex - Brook</title>
      </Helmet>
      <SignPostingHeader />
      <section className="signposting-section bg-orange color-white">
        <div className="content-container equal-space-padding">
          <div className="container-540 d-flex align-items-center">
            <h1 className="page-heading">WHAT IS UNPROTECTED SEX?</h1>
            <div className="ml-4">
              <img className="" src={unprotectedIcon} alt="unprotectedIcon" />
            </div>
          </div>
        </div>
      </section>
      <section className="signposting-section grey">
        <div className="content-container equal-space-padding px-0">
          <div className="contianer-450 card card-style">
            <div className="card-body">
              <p className="card-text">
                Unprotected sex is any sort of sex where protection isn't used. It is often used to refer to having
                penetrative vaginal or anal sex without a condom or when a method of contraception or barrier method has
                failed (for example, a split condom or a missed pill). Having unprotected sex can put you at risk of
                sexually transmitted infections (STIs) or unplanned pregnancy, so it's important to take action soon
                after it happens to keep yourself and your partner safe.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="zig-zag-pattern color-purple" />
      <section className="signposting-section bg-purple">
        <div className="content-container equal-space-padding">
          <h1 className="section-head pad-10 pl-0">IF YOU’VE HAD UNPROTECTED SEX…</h1>
          <div className="sec-sub-desc pt-5">
            <p>Don’t panic. Your local sexual health service or doctor will be able to help you!</p>
          </div>
          <div className="sec-sub-desc pt-5">
            <p>
              <strong>If you have the ability to become pregnant (but don’t want to be): </strong> you have up to 5 days
              after unprotected sex to take an emergency contraceptive pill or get an IUD (intrauterine- device) fitted.
              The sooner you access emergency contraception, the more effective it is, so don't wait longer than
              necessary.{' '}
            </p>
          </div>
          <div className="sec-sub-desc pt-5">
            <p>
              <strong>For everyone: </strong>you should consider taking an STI test. Getting tested for STIs regularly
              is the best way to protect yourself against them and, if you have one, you should treat it as soon as
              possible to avoid health complications and prevent it being passed on to anyone else.
            </p>
          </div>
          <div className="spacer-20" />
          <a
            className="text-decoration-home"
            tabIndex={noTab}
            href={appUrls.signPosting.emergencyContraception}
            rel="noreferrer"
          >
            <button className="s-btn-clr" type="submit">
              Tell me more about emergency contraception
            </button>
          </a>
          <div className="spacer-20" />
          <a
            className="text-decoration-home"
            tabIndex={noTab}
            href="https://www.brook.org.uk/topics/stis/#tab-info-advice"
            target="_blank"
            rel="noreferrer"
          >
            <button className="s-btn-clr" type="submit">
              Find out more about STI testing and treatment
            </button>
          </a>
          <div className="spacer-20" />
          <a
            className="text-decoration-home"
            tabIndex={noTab}
            href="https://www.brook.org.uk/find-a-service/"
            target="_blank"
            rel="noreferrer"
          >
            <button className="s-btn-clr" type="submit">
              Find a sexual health service near me
            </button>
          </a>
        </div>
      </section>
      <div className="zig-zag-pattern color-white bg-purple" />
      <section className="signposting-section white">
        <div className="content-container equal-space-padding pb-0">
          <h1 className="section-head dark">ALEX’S DILEMMA</h1>
          <div className="spacer-10" />
          <div className="sec-desc-2-color">
            <p>
              “I’m mostly having oral and anal sex at the moment, so I feel like it’s unlikely that I’ll get pregnant.
              I’m mostly only sleeping with people once or twice, which means it can be hard to know if they have an STI
              or not, so my main worry is about getting an STI.”
            </p>
          </div>
          <div className="image-align">
            {/* {!Hidebtn && (
                            <button className="s-btn-clr" type="submit" onClick={alexado}>What next?</button>
                        )} */}
            <img src={personIcon2} alt="personIcon2" />
            <div className="spacer-20" />
            <div className="dotted-border dark" />
          </div>
        </div>
      </section>
      <section className="signposting-section white">
        <div className="content-container equal-space-padding pt-0">
          <h1 className="section-head dark">WHAT SHOULD ALEX DO?</h1>
          <div className="sec-desc-2-color">
            <div className="spacer-20" />
            <p>Select all the answers you think are correct.</p>
          </div>
          <div className="spacer-20" />
          <a className="text-decoration-home">
            <button
              type="button"
              className={'content-button mb-4 btn-lg btn-block' + (RegularStiFn ? ' triple-btn' : '')}
              onClick={regularStiFn}
            >
              Get an STI test
            </button>
          </a>
          <a className="text-decoration-home">
            <button
              type="button"
              className={'content-button mb-4 btn-lg btn-block' + (ContraceptionFn ? ' triple-btn' : '')}
              onClick={contraceptionFn}
            >
              Look into methods of contraception
            </button>
          </a>
          <a className="text-decoration-home">
            <button
              type="button"
              className={'content-button mb-4 btn-lg btn-block' + (StiFn ? ' triple-btn' : '')}
              onClick={stiFn}
            >
              Learn about types of STI and treatment
            </button>
          </a>
          <a className="text-decoration-home" tabIndex={noTab} href="#gotoanswer" onClick={submitFn}>
            <button className="s-btn-clr mb-3" type="submit">
              <b>Submit</b>
            </button>
          </a>
          <div className="spacer-10" />
          {/* {!SubmitFn &&
                        <div className='sec-desc-2-color'>
                            <p>Select as many as you think!</p>
                        </div>
                    } */}
          <div className="spacer-20" />
          {SubmitFn && (
            <div className="answer" id="gotoanswer">
              <p className="view-answer">ANSWER</p>
              <p className="font-size-menu">
                Getting an STI test is a good first step for Alex. STIs can be transmitted through oral and anal sex,
                and not all STIs have symptoms, so the only way for Alex to know for sure that they don’t have one is to
                get a test.
              </p>
              <div className="spacer-20" />
              <p>
                Because Alex is mostly having oral and anal sex, they will need to speak to their local sexual health
                service to make sure they get the right type of test. Most home testing kits are designed for people
                having a penis in vagina sex, so if Alex uses one of these tests, they might not get an accurate result.
              </p>
              <div className="spacer-20" />
              <p>
                If Alex finds out they have an STI, they can get treatment from a local sexual health service (or
                sometimes online). Alex will also need to notify all of their partners so they can also get a test and
                if needed, treatment. Many sexual health services will be able to help you anonymously notify your
                partners if you are worried about doing it by yourself.
              </p>
              <div className="spacer-20" />
              <p>
                Alex should think about how else they can keep themselves and their partners safe. Condoms might be a
                good option for Alex – not only can they be used to protect against STIs for oral, anal and vaginal sex,
                they are also protection against pregnancy. Condoms are really easy to get hold of - sexual health
                services (and sometimes GP surgeries) can provide free condoms, and you can buy them (even if you’re
                under 16) from pharmacies, supermarkets, vending machines in public toilets and online.
              </p>
            </div>
          )}
          {/* <div className="spacer-40" />
                        <h1 className="section-head dark">WHY GET CONTRACEPTION?</h1>
                        <div className="spacer-20" />
                        <a href='https://www.brook.org.uk/topics/contraception/' target='_blank' rel='noreferrer' className='link-style'>https://www.brook.org.uk/topics/contraception/</a>
                        <div className="spacer-40" />
                        <h1 className="section-head dark">BE AWARE OF STIs</h1>
                        <div className="spacer-20" />
                        <a href='https://www.brook.org.uk/topics/stis/' target='_blank' rel='noreferrer' className='link-style'>https://www.brook.org.uk/topics/stis/</a> */}
        </div>
      </section>
      <section className={TrueFn || FalseFn ? 'signposting-section bg-purple pb-5' : 'signposting-section bg-purple'}>
        <div className="content-container equal-space-padding pb-0">
          <h1 className="section-head">MYTHBUSTING</h1>
          <div className="spacer-20" />
          <div className="pos-relative">
            <div className="chat-bubble-arrow chat-green" />
            <div className="s-body bubble-box  bg-bubble-green"> STIs</div>
            <div className="spacer-10" />
            <div className="myth-chat-bubble chat-bubble-border">
              “Getting tested for STIs is difficult and painful.”
            </div>
          </div>
          <div className="container-450">
            {!TrueFn && !FalseFn ? (
              <div>
                <div className="spacer-40" />
                <p className="section-desc section-desc-2-color">What do you think?</p>
                <div className="spacer-10" />
                <div className="dotted-border" />
              </div>
            ) : (
              ''
            )}
            <div className="true-false-blk">
              <button className="s-btn-clr m-0" type="submit" onClick={truefn}>
                True
              </button>
              <p>or</p>
              <button className="s-btn-clr" type="submit" onClick={falseFn}>
                False
              </button>
            </div>
            {TrueFn && (
              <div>
                <False type="sti-2" />
                <div className="content-container">
                  <div className="spacer-20" />
                  <div className="mt-3">
                    <Link className="s-btn-clr" to={appUrls.signPosting.mythBusting}>
                      Bust more myths
                    </Link>
                  </div>
                </div>
              </div>
            )}
            {FalseFn && (
              <div>
                <False type="sti-2" />
                <div className="content-container">
                  <div className="spacer-20" />
                  <div className="mt-3">
                    <Link className="s-btn-clr" to={appUrls.signPosting.mythBusting}>
                      Bust more myths
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="signposting-section white">
        <div className="content-container equal-space-padding">
          <h1 className="section-head dark">NEED URGENT HELP?</h1>
          <div className="spacer-10" />
          <div className="section-desc sec-desc-2-color">
            If you are worried about yourself or someone else, there are lots of places that can offer help and support.
          </div>
          <div className="spacer-20" />
          <Link className="s-btn-clr mr-3" to={appUrls.signPosting.help}>
            Find help{' '}
          </Link>
        </div>
      </section>
      <section className="signposting-section bg-purple">
        <div className="content-container equal-space-padding pt-0">
          <div className="spacer-20" />
          <h1 className="section-head">OTHER PEOPLE ASK…</h1>
          <div className="spacer-20" />
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.brook.org.uk/your-life/having-sex-for-the-first-time/"
              className="ask-link"
            >
              I want to know more about having sex for the first time
            </a>
          </div>
          <div className="spacer-10" />
          <div>
            <a target="_blank" rel="noreferrer" href="https://www.brook.org.uk/topics/stis/" className="ask-link">
              {' '}
              I want to know more about STIs and getting tested{' '}
            </a>
          </div>
          <div className="spacer-10" />
          <div>
            <a target="_blank" rel="noreferrer" href="https://www.brook.org.uk/topics/sex/" className="ask-link">
              I want to prepare myself for having sex with someone new
            </a>
          </div>
          <div className="spacer-10" />
          {/* <div>
                        <Link className='ask-link' to={appUrls.signPosting.help}>I'm concerned about myself or someone else</Link>
                    </div> */}
          <div className="spacer-30" />
          <div className="dotted-border" />
        </div>
      </section>
      <SignPostingFooter />
    </div>
  )
  // }
}
export default UnprotectedSex
