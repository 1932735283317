import React from 'react';

import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";

interface GraphComponentProps {
  seriesData: any,
  categoryData: any,
  title: string,
  selectDay: any
}

export const GraphComponent: React.FC<GraphComponentProps> = ({
  seriesData = [],
  categoryData = [],
  title = '',
  selectDay
}) => {
  return (
    <div className='graph-section'>
      {seriesData && Array.isArray(seriesData) && seriesData.length ?
        <HighchartsReact
          highcharts={Highcharts}
          constructorType='chart'
          options={{
            chart: {
              type: 'column',
              backgroundColor: '#F9F9F9',
              marginTop: 100,
              height: 420,
              spacing: [22, 22, 22, 22],
              style: {
                fontFamily: 'MuseoSans'
              }
            },
            title: {
              text: title === 'Received' || title === 'Positive' ? 'Test results' : 'Test kit',
              align: 'left',
              style: {
                color: "#3B4250",
                fontSize: "16px"
              }
            },
            subtitle: {
              text: title,
              align: 'left',
              style: {
                color: "#3B4250",
                fontSize: "24px",
                marginBottom: "20px"
              }
            },
            xAxis: {
              categories: categoryData,
              title: {
                text: null
              }
            },
            yAxis: {
              min: 0,
              title: {
                text: null
              }
            },
            accessibility: {
              enabled: false
            },
            tooltip: {
              // valueSuffix: ' millions'
            },
            plotOptions: {
              bar: {

              },
              series: {
                pointWidth: selectDay === '6' ? 20 : selectDay === '29' ? 10 : 5
              }
            },
            legend: {
              enabled: false
            },
            credits: {
              enabled: false
            },
            series: seriesData
          }}
        /> :
        <div>
          <span>No data</span>
        </div>}
    </div>
  );
}