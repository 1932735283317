export const managerList = [
  {
    name: 'Lorem ipsum 1',
    permission: '07',
    email: 'lorem1@gmail.com'
  },
  {
    name: 'Lorem ipsum 2',
    permission: '05',
    email: 'lorem2@gmail.com'
  },
  {
    name: 'Lorem ipsum 3',
    permission: '06',
    email: 'lorem3@gmail.com'
  },
  {
    name: 'Lorem ipsum 4',
    permission: '04',
    email: 'lorem4@gmail.com'
  },
  {
    name: 'Lorem ipsum 5',
    permission: '08',
    email: 'lorem5@gmail.com'
  },
  {
    name: 'Lorem ipsum 6',
    permission: '09',
    email: 'lorem6@gmail.com'
  },
  {
    name: 'Lorem ipsum 7',
    permission: '10',
    email: 'lorem7@gmail.com'
  },
  {
    name: 'Lorem ipsum 8',
    permission: '08',
    email: 'lorem8@gmail.com'
  },
  {
    name: 'Lorem ipsum 9',
    permission: '04',
    email: 'lorem9@gmail.com'
  },
  {
    name: 'Lorem ipsum 10',
    permission: '06',
    email: 'lorem10@gmail.com'
  },
  {
    name: 'Lorem ipsum 11',
    permission: '07',
    email: 'lorem11@gmail.com'
  },
  {
    name: 'Lorem ipsum 12',
    permission: '08',
    email: 'lorem12@gmail.com'
  },
  {
    name: 'Lorem ipsum 13',
    permission: '07',
    email: 'lorem13@gmail.com'
  },
];

// dropdown
export const verticalDotMenu = [
  {
    value: 'changeRole',
    label: 'Change account type',
    children: [
      {
        value: 'manager',
        label: 'Manager'
      },
      {
        value: 'user',
        label: 'User'
      },
    ]
  },
  {
    value: 'archive',
    label: 'Archive'
  }
];

export const archiveDotMenu = [
  {
    label: 'Unarchive',
    value: 'unarchive'
  }
];

export const selectOptions = [
  {
    label: 'Dudley',
    value: 'dudley'
  },
  {
    label: 'Trafford',
    value: 'trafford'
  },
  {
    label: 'Stockport',
    value: 'stockport'
  },
  {
    label: 'Tameside',
    value: 'tameside'
  },
  {
    label: 'Manchester',
    value: 'manchester'
  },
];

export const roleList = [
  {
    label: 'Manager',
    value: 'admin'
  },
  {
    label: 'User',
    value: 'user'
  },
];

export const managerEditPermissionList = [
  {
    name: 'Approve / reject request',
    value: 'approve-reject-request'
  },
  {
    name: 'Mark as added to Lilie - Flagged orders',
    value: 'lilie-flagged-orders'
  },
  {
    name: 'Mark as added to Lilie - Results',
    value: 'lilie-results'
  },
  {
    name: 'Archive a record',
    value: 'archive-record'
  },
  {
    name: 'Unarchive a record',
    value: 'unarchive-record'
  },
  {
    name: 'Export archived records',
    value: 'export-archived-records'
  },
  {
    name: 'View full details of a record',
    value: 'full-details-record'
  },
  {
    name: 'Add client contact attempts',
    value: 'add-client-contact-attempts'
  },
]

export const managerTeamMember = [
  {
    name: 'Create new team member',
    value: 'create-new-team-member'
  },
  {
    name: 'Archive team member',
    value: 'archive-team-member'
  },
  {
    name: 'Manage team member location',
    value: 'manage-team-member-location'
  },
  {
    name: 'Change team member role',
    value: 'change-team-member-role'
  },
  {
    name: 'Unarchive team member',
    value: 'unarchive-team-member'
  }
];