import React, { useCallback, useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import '../styles/preContact.css'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'

interface SuccesspostcodeProps {
  Props: any
}

export const ContactUserName: React.FC<SuccesspostcodeProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)
  const validName = new RegExp("^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z ]*)*$")
  const [name, setName] = useState<{ fname: string; lname: string }>({
    fname: '',
    lname: '',
  })

  const history = useHistory()
  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('firstName')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  useEffect(() => {
    setName({ fname: theme.first_name, lname: theme.last_name })
    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: '46' },
    })
  }, [])

  const proceed = useCallback(() => {
    TextToSpeech.getInstance().playVoice(null, 'stop')
    setisAccessibilityVoiceOver(false)
    if (!theme.age) {
      window.location.href = '/postcode-age'
      return
    }

    themeDispatch({
      type: 'SET_NAME',
      payload: { first_name: name.fname.trim(), last_name: name.lname.trim() },
    })
    if (theme.age) {
      Props.history.push('/dateOfBirth')
      // } else {
      //   Props.history.push('/mobileNumber')
    }
  }, [name])

  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }
  return (
    <div className={`bg-color preContact-style dis-fl-none ${theme.theme}-skin-color`}>
      <div className="brook-container pad-bot form-width">
        <div className="h-div mt-4">
          <h2 className={`page-header-txt ${theme.theme}-txt-global`}>ORDER A TEST KIT</h2>
          <div className="speaker-i">
            <button
              className="no-background-border"
              onClick={(e) => {
                TextToSpeech.getInstance().playVoice(null, 'ORDER A TEST KIT. First name. Last name.')
                setisAccessibilityVoiceOver(true)
              }}
            >
              <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
            </button>
          </div>
        </div>
        <div className="text-to-speech">
          <div className={`b1-style ${theme.theme}-txt-global`}>First name</div>
          <input
            id="firstName"
            type="text"
            className="form-control input-style"
            placeholder="Enter your first name"
            aria-label="First name"
            value={name.fname}
            onChange={(e) => {
              setName({ fname: String(e.target.value), lname: name.lname })
            }}
          />
          <span
            className="errorMessage"
            style={{ fontSize: '20px', fontFamily: 'museosans' }}
            role="alert"
            aria-live="assertive"
          >
            {name.fname.length > 0 && !validName.test(name.fname) ? 'Only characters are allowed in the name' : null}
          </span>
          <div className={`b1-style ${theme.theme}-txt-global`}>Last name</div>
          <input
            type="text"
            className="form-control input-style"
            placeholder="Enter your last name"
            aria-label="Last name"
            value={name.lname}
            onChange={(e) => {
              setName({ fname: name.fname, lname: String(e.target.value) })
            }}
          />
          <span
            className="errorMessage"
            style={{ fontSize: '20px', fontFamily: 'museosans' }}
            role="alert"
            aria-live="assertive"
          >
            {name.lname.length > 0 && !validName.test(name.lname) ? 'Only characters are allowed in the name' : null}
          </span>
        </div>
        <div>
          <div className={`ft-tab brook-container form-width ${theme.theme}-skin-color`}>
            <button className="float-left no-background-border" onClick={(e) => GoBackPage()}>
              <img src={backIcon} alt="back" />
            </button>
            <Button
              isDisabled={
                name.fname.length < 2 ||
                name.lname.length < 2 ||
                !(Boolean(validName.test(name.fname)) && Boolean(validName.test(name.lname)))
              }
              buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
              buttonText="Next"
              onClick={proceed}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
