import React, { useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import '../styles/hepatitis.css'
import '../styles/typeofsex.css'
import { stiKitLoaderPercentage } from '../utils/stiKitLoaderPercentage'

const customiseKitOption = [
  {
    label: 'Please tick here if you mostly have oral or anal sex.',
    value: 'I also need swabs for my throat and rectum',
    subtitle:
      "<p class='swab-note-blk'>We will then send you additional swabs for the rectum (anus) and throat to test for chlamydia and gonorrhoea.</p><p class='swab-note-blk'>If you don't need these swabs, leave this box empty.</p>",
  },
]

const CUSTOMISE_KIT_KEY = 'I also need swab for my throat and rectum'
export const CustomiseYourKit = () => {
  const { theme, themeDispatch } = useContext(ThemeContext)
  const history = useHistory()
  const [internalState, setInternalState] = useState<any>({
    isThroatAndRectalSwab: false,
    throatAndRectalSwabValue: '',
  })
  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('checkBoxId')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  useEffect(() => {
    // console.log('custom your kit theme :>>', theme);
    // Set the value on question if we have selected the answer already
    if (theme.quesObj && theme.quesObj[CUSTOMISE_KIT_KEY]) {
      setInternalState({
        ...internalState,
        isThroatAndRectalSwab: theme.quesObj[CUSTOMISE_KIT_KEY] ? true : false,
        throatAndRectalSwabValue: theme.quesObj[CUSTOMISE_KIT_KEY],
      })
    }
    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: stiKitLoaderPercentage.CUSTOMISE_YOUR_KIT },
    })
  }, [])

  const handleOnChange = (event: any, option: any) => {
    setInternalState({
      ...internalState,
      isThroatAndRectalSwab: !internalState.isThroatAndRectalSwab,
      throatAndRectalSwabValue: !internalState.isThroatAndRectalSwab ? option.value : '',
    })
  }

  const handleOnNext = () => {
    let themeObjClone: any = theme.quesObj
    if (Object.prototype.hasOwnProperty.call(theme.quesObj, CUSTOMISE_KIT_KEY)) {
      delete themeObjClone[CUSTOMISE_KIT_KEY]
    }
    if (internalState.isThroatAndRectalSwab) {
      themeDispatch({
        type: 'SET_QUES',
        payload: {
          quesObj: {
            ...themeObjClone,
            [CUSTOMISE_KIT_KEY]: 'yes',
          },
        },
      })
    }
    // history.push('/preContact')
    history.push('/customise-kit1')
  }

  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }

  return (
    <div className={`bg-color hepatitis-style ${theme.theme}-global`}>
      <div className="brook-container pad-bot">
        <div className="text-to-speech">
          <div className="h-div mt-4">
            <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
            <div className="speaker-i">
              <button
                className="no-background-border"
                onClick={(e) => {
                  TextToSpeech.getInstance().playVoice('text-to-speech')
                  setisAccessibilityVoiceOver(true)
                }}
              >
                <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
              </button>
            </div>
          </div>
          <div className="hepatitis-content-blk">
            <h1 className={`b1-style  ${theme.theme}-txt-global`}>Edit your kit: additional swabs</h1>
            <div className="sti-kit-radio-blk">
              <div className="pad-tp font-check mb-4">
                {customiseKitOption.map((option: any, idx: number) => {
                  const { throatAndRectalSwabValue, isThroatAndRectalSwab } = internalState
                  return (
                    <div className="form-check mt-5" key={`throat_and_swab_item_${idx}`}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={throatAndRectalSwabValue}
                        aria-label={option.label}
                        id="checkBoxId"
                        checked={isThroatAndRectalSwab}
                        onChange={(event) => handleOnChange(event, option)}
                      />
                      <div className="d-flex">
                        <div className="mar-st">
                          <label className={`form-check-label b4-style ${theme.theme}-txt-global`}>
                            {option.label}
                          </label>
                          <div className="customise-kit-note-blk">
                            <div
                              className={`swab-note mb-3 ${theme.theme}`}
                              dangerouslySetInnerHTML={{ __html: option.subtitle }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className={`ft-tab brook-container ${theme.theme}-skin-color`}>
              <button className="float-left no-background-border pos-re" onClick={(e) => GoBackPage()}>
                <img src={backIcon} alt="back" />
              </button>
              <Button
                isDisabled={false}
                buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                buttonText="Next"
                onClick={handleOnNext}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
