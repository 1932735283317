import React, { useCallback, useContext } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import '../styles/eligibilitypass.css'

interface ElgibilitypassProps {
  children: any
}

export const PostGenital: React.FC<ElgibilitypassProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)

  const history = useHistory()

  const proceed = useCallback(() => {
    TextToSpeech.getInstance().playVoice(null,'stop')
    Props.history.push('/preContact')
  }, [])

  function GoBackPage(){
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')    
  }

  return (
    <div className="bg-color postGenital-style">
      <div className='brook-container pad-bot'>
      <div className="text-to-speech">
        <div className="h-div mt-4">
          <h2 className={`${theme.theme}-txt-global page-header-txt`}>
            ORDER A TEST KIT
          </h2>
          <div className="speaker-i">
            <button
              className="no-background-border"
              onClick={(e) =>
                TextToSpeech.getInstance().playVoice('text-to-speech')
              }
            >
              <img src={Icon} alt="speaker" />
            </button>
          </div>
        </div>
        <div className="b1-style">
          We recommend you contact your local Brook or other sexual health
          service
        </div>
        <div className={`mar-pos p2-style ${theme.theme}-txt-global`}>
          This is how many years old you are today (even if it’s your birthday
          very soon).<br />
        </div>
        <div className={`p3-style p2-style ${theme.theme}-txt-global mb-3`}>
          {' '}
          Don't panic - it's just because certain types of tests can't be done
          at home. You'll need to contact you local service to book yourself in
          as soon as you can.{' '}
        </div>
        <div className="ft-tab brook-container p-color">
          <button
            className="float-left no-background-border"
            onClick={e => GoBackPage()}
          >
            <img src={backIcon} alt="back" />
          </button>
          <Button
            isDisabled={false}
            buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
            buttonText="Next"
            onClick={proceed}
          />
        </div>
      </div>
      </div>
    </div>
  )
}
