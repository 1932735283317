import React from 'react'
import '../styles/RecordStatus.css'

export interface LoaderProps {
    hide?: boolean
}

const Loader: React.FC<LoaderProps> = ({ hide }) => {
    return (
        <>
            <div className="loader-brook">
                <div id="LoadingPage" className="loading-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="148" height="152" viewBox="0 0 148 152" id="brook_loader">
                        <g id="Group_3955" data-name="Group 3955" transform="translate(-920 -454)">
                            <circle id="Ellipse_305" data-name="Ellipse 305" cx="17.5" cy="17.5" r="17.5" transform="translate(978 454)" fill="#652f91" opacity="0.897" />
                            <circle id="Ellipse_306" data-name="Ellipse 306" cx="17.5" cy="17.5" r="17.5" transform="translate(1020 471)" fill="#652f91" />
                            <circle id="Ellipse_307" data-name="Ellipse 307" cx="16" cy="16" r="16" transform="translate(937 473)" fill="#652f91" opacity="0.8" />
                            <circle id="Ellipse_308" data-name="Ellipse 308" cx="15" cy="15" r="15" transform="translate(920 516)" fill="#652f91" opacity="0.72" />
                            <circle id="Ellipse_309" data-name="Ellipse 309" cx="14.5" cy="14.5" r="14.5" transform="translate(938 560)" fill="#652f91" opacity="0.55" />
                            <circle id="Ellipse_310" data-name="Ellipse 310" cx="14" cy="14" r="14" transform="translate(981 578)" fill="#652f91" opacity="0.49" />
                            <circle id="Ellipse_311" data-name="Ellipse 311" cx="13" cy="13" r="13" transform="translate(1025 561)" fill="#652f91" opacity="0.398" />
                            <circle id="Ellipse_312" data-name="Ellipse 312" cx="12" cy="12" r="12" transform="translate(1044 519)" fill="#652f91" opacity="0.28" />
                        </g>
                    </svg>
                </div>
            </div>
        </>
    )
}

export default Loader
