import React from 'react'

import { useHistory } from "react-router-dom";

interface OverviewCardProps {
  overviewState?: any,
  date: any
}

export const OverviewCard: React.FC<OverviewCardProps> = ({ overviewState, date }) => {
  const history = useHistory();
  return (
    <div className='overview-card-blk'>
      <div className='d-flex flex-column justify-content-center'>
        <p className='day-text'>Today</p>
        <span className='date-text'>{date}</span>
      </div>
      <div className='menu-card-container'>
        <div className='menu-card-blk'>
          <div className='sub-menu-card-blk'>
            <div className='menu-card-left'>
              <div>
                <p className='result-menu-label'>Positive</p>
                <p className='result-submenu-label'>Test results</p>
              </div>
              <div>
                <span className='total-count positive-result-count'>{overviewState.positive}</span>
              </div>
            </div>
            <div className='action-blk'>
              <button
                onClick={() => history.push('/staffhub/order-kit?filters=Positive')}
                className='view-all-text'>
                View all
              </button>
            </div>
          </div>
        </div>
        <div className='menu-card-blk'>
          <div className='sub-menu-card-blk'>
            <div className='menu-card-left'>
              <div>
                <p className='result-menu-label'>Flagged</p>
                <p className='result-submenu-label'>Requests</p>
              </div>
              <div>
                <span className='total-count flagged-result-count'>{overviewState.flagged}</span>
              </div>
            </div>
            <div className='action-blk'>
              <button
                onClick={() => history.push('/staffhub/order-kit?filters=Flagged')}
                className='view-all-text'>
                View all
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}