export type TrackerAction =
  | {
      type: 'SET_TRACKERS'
      trackers: any
    }
  | {
      type: 'UNSET_TRACKER'
    }
  | {
      type: 'SET_ALCOHOL_DRINKS'
      alcoholdrinks: any
    }
  | {
      type: 'UNSET_ALCOHOL_DRINKS'
    }

export interface TrackerState {
  trackers: any
  alcoholdrinks: any
}

export const initialState: TrackerState = {
  trackers: {
    overview: true,
    sleep: true,
    mood: true,
    alcohol: true,
    pain: true,
    problem: true,
    smoke: true,
    'blood-pressure': true,
    'bmi-weight': true,
    temperature: true,
  },
  alcoholdrinks: {},
}

export const trackerReducer = (
  state: TrackerState,
  action: TrackerAction
): TrackerState => {
  console.log(action)

  switch (action.type) {
    case 'SET_TRACKERS':
      return {
        ...state,
        trackers: action.trackers,
      }
    case 'UNSET_TRACKER':
      return {
        ...state,
        trackers: initialState,
      }
    case 'SET_ALCOHOL_DRINKS':
      return {
        ...state,
        alcoholdrinks: action.alcoholdrinks,
      }
    case 'UNSET_ALCOHOL_DRINKS':
      return {
        ...state,
        trackers: initialState,
      }
    default:
      return state
  }
}
