import jwtDecode from 'jwt-decode'
import { toast } from 'react-toastify'

interface ToastOptions {
  position?:
    | 'top-right'
    | 'top-center'
    | 'top-left'
    | 'bottom-right'
    | 'bottom-center'
    | 'bottom-left'
  autoClose?: number | false | undefined
  hideProgressBar?: boolean | undefined
  closeOnClick?: boolean | undefined
  pauseOnHover?: boolean | undefined
  draggable?: boolean | undefined
  progress?: string | number | undefined
}

const helperFunctions = {
  showToast: (
    messageToShow: string,
    toastType: 'success' | 'info' | 'error' | 'warning' | 'default' | 'dark',
    toasterOptions?: ToastOptions
  ) => {
    if (toasterOptions && !toasterOptions.position) {
      toasterOptions.position = 'top-right'
    }
    if (toasterOptions && !toasterOptions.autoClose) {
      toasterOptions.autoClose = 2000
    }
    if (toasterOptions && !toasterOptions.pauseOnHover) {
      toasterOptions.pauseOnHover = false
      toasterOptions.hideProgressBar = false
    }
    switch (toastType) {
      case 'success':
        toast.success(messageToShow, toasterOptions)
        break
      case 'error':
        toast.error(messageToShow, toasterOptions)
        break
      case 'info':
        toast.info(messageToShow, toasterOptions)
        break
      case 'warning':
        toast.warn(messageToShow, toasterOptions)
        break
      case 'dark':
        toast.dark(messageToShow, toasterOptions)
        break
      case 'default':
        toast(messageToShow, toasterOptions)
        break
    }
  },
  isAuthenticated: () => {
    const tokenString = localStorage.getItem('access-token')
    return tokenString ? true : false
  },
  isAuthenticatedAdmin: () => {
    const tokenString = sessionStorage.getItem('access-token')
    const role = true
    return tokenString && role ? true : false
  },
  getAdminUserData: () => {
    try {
      const decoded_jwt: any = jwtDecode(
        sessionStorage.getItem('access-token') || ''
      )
      if (decoded_jwt) {
        return { username: decoded_jwt.username, _id: decoded_jwt._id }
      }
      return { username: '', _id: '' }
    } catch (error) {
      return { username: '', _id: '' }
    }
  },
}

export default helperFunctions
