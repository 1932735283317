import React, { useContext } from 'react';

import { useHistory } from "react-router-dom";

import backIcon from '../assets/images/Back_Button.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import '../styles/agebarrier.css'
import { Accordion } from '../components/accordian/Accordian';
import TextToSpeech from '../components/textToSpeech/TextToSpeech';
import { ThemeContext } from '../context/provider/ThemeContext';

interface AgebarrierProps {
  children: any;
}

export const Agebarrier: React.FC<AgebarrierProps> = ({ children }) => {

  const history = useHistory();

  const { theme, themeDispatch } = useContext(ThemeContext)
  function GoBackPage(){
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')    
  }
  return (
    <div className='bg-color agebarrier-style'>
      <div className="brook-container pad-bot">
      <div className="text-to-speech">
        <div className='pad-te'>
          <div className="h-div mt-4">
            <h2 className={`${theme.theme}-txt-global page-header-txt`}>
              ORDER A TEST KIT
            </h2>
            <div className='speaker-i'>
              <button className="no-background-border" onClick={e =>
                TextToSpeech.getInstance().playVoice(null, "ORDER A TEST KIT Brook currently doesn’t provide STI home test kits in your area for your age group. But that doesn’t mean you can’t get a test from another service. See if there is another provider of STI home test kits in your area. WHERE ELSE CAN I GET A TEST?")
              }>
                <img src={Icon} alt='speaker' />
              </button>
            </div>
          </div>
          <div className='b1-style'>Brook currently doesn't provide STI home test kits in your area for your age group.</div>
          <div className='p3-style p2-style mt-3'>
            But that doesn't mean you can't get a test from another service. See if there is another provider of STI home test kits in your area
          </div>
        </div>
        <div className="">
          <Accordion
            themeName="postcodeBarrier"
            labelBgClass="personel_info"
            panels={[
              {
                label: 'WHERE ELSE CAN I GET A TEST?',
                content: child(),
              },
            ]}
          />
        </div>
        <div className="ft-tab brook-container p-color">
            <button
              className="float-left no-background-border"
              onClick={e => GoBackPage()}
            >
              <img src={backIcon} alt="back" />
            </button>
          </div>
      </div>
      </div>
    </div >
  );
};

const child = () => {
  const tabIndex=0;
  return (
    <>
      <div className="p3-style p2-style" tabIndex={tabIndex}>
      Your response to this question and the next question will help us know which type of kit to send you (this will either be a swab or a urine sample), or if you may need a different type of test that needs to be done in a clinic.      </div>
    </>
  )
}
