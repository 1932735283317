import React, { useState } from 'react'

import DropdownCascade from 'react-dropdown-cascade';

import { TextInput } from './TextInput';
import '../styles/DropdownCascade.css';

interface DropdownSubmoduleProps {
  menus: any;
  actionType: any;
}

export const DropdownSubmodule: React.FC<DropdownSubmoduleProps> = ({
  menus = [],
  actionType
}) => {

  const [dropdownValue, setDropdownValue] = useState<any>();
  const index = 0;
  const dropdownRef = React.createRef<HTMLDivElement>();

  const dropdownCustomStyle = {
    dropdown: {
      className: "customstyle",
    },
    dropdownMenu: {
      className: `customdropdownMenu customdropdownMenu${index}`,
    },
  }

  const onClickOutside = (e: MouseEvent, index: number): void => {
    if (dropdownRef?.current) {
      if (!dropdownRef.current.contains(e.target as Node)) {
        document
          .getElementsByClassName(`customdropdownMenu${index}`)[0]
          .classList.remove("show");
        document.getElementsByClassName('dropdown-cascade-blk')[0]
          .classList.remove('bgActive');
      }
    }
  };

  const handleOnSelect = (value: string, selectedItems: any) => {
    // console.log('ddd :>>', value, selectedItems);
    actionType(value)
    setDropdownValue(value);
    document
      .getElementsByClassName(`customdropdownMenu${index}`)[0]
      .classList.remove("show");
    document.getElementsByClassName('dropdown-cascade-blk')[0]
      .classList.remove('bgActive');
  }

  const customInputProps = {
    onChange: () => { },
    onKeyDown: (e: any) => { e.preventDefault() },
    onClick: (e: any) => {
      setTimeout(() => {
        document.getElementsByClassName('dropdown-cascade-blk')[0]
          .classList.add('bgActive');
        document
          .getElementsByClassName(`customdropdownMenu${index}`)[0]
          .classList.add("show");
        document.addEventListener("click", (e: any) =>
          onClickOutside(e, index)
        );
      }, 200);
    },
  };

  return (
    <div className='dropdown-cascade-blk' ref={dropdownRef}>
      <DropdownCascade
        customStyles={dropdownCustomStyle}
        customInputProps={customInputProps}
        customInput={TextInput}
        items={menus}
        onSelect={(value, selectedItems) => handleOnSelect(value, selectedItems)}
        value={dropdownValue}
      />
    </div>
  );
}