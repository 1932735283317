import { Media } from 'tinacms';

import apiService from '../services/api-service';
import helperFunctions from '../services/helper-service';
import tinaUrls from '../services/TinaURLs';

interface AddFile {
  directory: string;
  file: File;
}

export default class DummyMediaStore {
  accept = '*';

  async persist([{ directory, file }]: [AddFile]): Promise<any> {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('img-file', file);
      const fileUploadResp = await apiService.postAdmin(
        tinaUrls.fileUpload,
        bodyFormData
      );
      if (fileUploadResp.data.fileData) {
        return {
          type: 'file',
          id: fileUploadResp.data.fileData.filename,
          directory: '/',
          filename: fileUploadResp.data.fileData.filename,
          previewSrc:
            window.location.origin + '/' + fileUploadResp.data.fileData.path
        };
      }
    } catch (error) {
      console.log('\n fileUploadResp error...', error);
    }
    return null;
  }

  async previewSrc(src: string) {
    return src;
  }

  async list(): Promise<any> {
    let items: Array<Media> = [];
    try {
      const imagesNamesResp = await apiService.getAdmin(tinaUrls.getUploads);
      if (imagesNamesResp.data.result) {
        items = imagesNamesResp.data.result.map((innerData: Media) => {
          return {
            ...innerData,
            previewSrc: innerData.previewSrc
          };
        });
      }
    } catch (error: any) {
      console.log('\n imagesNamesResp error...', error.response);
    }

    return {
      items,
      offset: 0,
      limit: 10,
      totalCount: 0
    };
  }

  async delete(media: Media) {
    try {
      const fileDeleteResp = await apiService.deleteAdmin(
        `${tinaUrls.deleteUpload}${media.filename}`
      );
      helperFunctions.showToast(
        fileDeleteResp.data.result || 'File deleted Successfully',
        'success',
        {}
      );
    } catch (error) {
      console.log('\n file delete error...', error);
    }
  }
}
