import React, { useEffect, useState } from 'react'

// import _ from 'lodash'
import moment from 'moment'
import { useLocation } from 'react-router-dom'

import disableArchiveIcon from '../../assets/images/disable_archive.svg'
import circledown from '../../assets/images/expand_circle_down_black.svg'
import closeIcon from '../../assets/images/Popup_close.svg'
import SearchIcon from '../../assets/images/search_Icon.svg'
import useAuthContext from '../../context/provider/AuthContext'
import apiFunctions from '../../services/api-service'
import Loader from '../Loader/loader'
import Pagination from '../pagination/pagination'
import '../styles/TableView.css'
import { AddLilieId } from './AddLilieId'
import { ApproveKit } from './ApproveKit'
import { AdminCheckbox } from './Checkbox'
import { ContactAttempt } from './ContactAttempt'
import { Flags } from './Flags'
import { InLilie } from './InLilie'
import { ServiceUserName } from './Name'
import Popup from './popup'
import { Toggle } from './Toggle'


export interface TableHeadProps {
  label: string
  width?: string
}

export interface TableContentProps {
  content: string
  role?: string
  value?: string
  status?: string
  color?: string
  label?: string
}

interface TableViewProps {
  ArchiveUserList?:any
  getTableHead?: Array<TableHeadProps>
  getTableContent?: Array<TableContentProps>
  multiorderCountList:Array<TableContentProps>
  setLength?: any,
  type?: any
  filterData?: any
  orderUrl?: any
  totalCount?:any
  totalArchive?:any
  setOffset:(offset:number) => void
}

export const TableView: React.FC<TableViewProps> = ({
  getTableHead,
  getTableContent = [],
  multiorderCountList = [],
  ArchiveUserList,
  type,
  setLength,
  filterData,
  orderUrl,
  totalCount,
  totalArchive,
  setOffset
}) => {
  const [tableHeadLength, setTableHeadLength] = useState(0)
  const [showLoader, setShowLoader] = useState(false)
  const [arr, setarr] = useState([])
  const [inc, setinc] = useState(0)
  const [results, showresults] = useState({})
  const [showPopup, setshowPopup] = useState(false)
  const [showrolePopup, setshowrolePopup] = useState(false)
  const [ShowArchivepopup, setShowArchivepopup] = useState(false)
  const [ShowContactPopup, setShowContactPopup] = useState(false)
  const [values, setvalues] = useState({})
  const [roleID, setroleID] = useState('')
  const [userInfo, setuserInfo] = useState({})
  const [showSort, setShowSort] = useState(false)
  const [contactInfo, setContactInfo] = useState({})
  const [contactDisabled, setContactDisabled] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const [ArrowState, setArrowState] = useState('')
  const [searchString, setsearchString] = useState({});
  const [checkBoxshow, setcheckBoxshow] = useState(false);
  const [isarchiveSearchUser, setisArchiveSearchUser] = useState(false);
  const [archiveHead, setArchivehead] = useState(false);
  const [archiveshow, setArchiveshow] = useState(true)
  const [sortValue, setSortvalue] = useState('')
  const [sortOrder, setSortOrder] = useState('')
  const [selectpagecount, SetSelectpagecount] = useState({ 'totalrec': 0 })
  const [selectArrData, setSelectArrData] = useState<any[]>([]);
  const [selectallcheck, SetSelectallcheck] = useState<any[]>([]);
  const [selectSortValue, setSelectSortValue] = useState<any>();
  const [pagenum, setPageNum] = useState(1);
  const [checkAlltrue, setCheckAlltrue] = useState(false);
  const [showselectcount, SetShowselectcount] = useState(false);
  const [ordercount, setOrderCount] = useState([]);
  const [multiborderhide, setMultiborderhide] = useState({ 'email': '', 'counts': '' });
  const [alluserdata, setAllUserData] = useState([]);
  const [multiOrderCountList, setMultiOrderCountList] = useState<any>([]);

  const [archiveList, setArchiveList] = useState<any>([]); // Set Archive user list 
  const [selectAll, setSelectAll] = useState(false); // Set Archive user list 

  let searchStr = {
    str: new URL(orderUrl).searchParams.get('search') || '',
    order: new URL(orderUrl).searchParams.get('order') || '',
  }

  let searchstr: any;
  let SearchOrder: any;
  const [filterValue, SetFilterValue] = useState(new URL(window.location.href).searchParams.get('filters'))
  let c_url = new URL(window.location.href).searchParams.get('filters');



  let obj:any = {
    title: c_url ,
    list: ArchiveUserList && ArchiveUserList.map((res1:any)=>{
        return{
            id:res1,
            checked:false
        }
    })
}


  // multiple count hide and show border
  const location = useLocation();
  let str = location.search
  const Multiresp = str.indexOf('multiOrder') !== -1;


  /* Name sort popup close in click the other places  */
  window.addEventListener('mouseup', function (event: any) {
    if (event.target && event.target.offsetParent && event.target.offsetParent && event.target.offsetParent.className
      && event.target.offsetParent.children[1] && event.target.offsetParent.children[1].className !== 'parent-radio') {
      if (!localStorage.getItem('filterValue')) {
        setSelectSortValue('');
        setShowSort(false)
      } else {
        setShowSort(false)
      }
    }
  });
  /* Close the name sorting */
  function closeSort() {
    if (!localStorage.getItem('filterValue')) {
      setSelectSortValue('');
      setShowSort(false);
    }
    else {
      setShowSort(false)
    }

  }


  useEffect(() => {
    let arraSort: any = [];
    if ((filterValue === c_url) && localStorage.getItem('filterValue')) {
      const value: any = localStorage.getItem('filterValue');
      setSelectSortValue(parseInt(value))
      sortArray.forEach((val) => {
        if (val.id === parseInt(value)) {
          arraSort.push(val)
        }
      })
      filterData({ str: arraSort.length ? arraSort[0].value : '', order: arraSort.length ? arraSort[0].order : '' })
    } else {
      localStorage.removeItem("filterValue");
    }
  }, [])

  if (sortValue && sortOrder) {
    searchstr = sortValue
    SearchOrder = sortOrder;
  }
  else {
    searchstr = searchStr.str
    SearchOrder = searchStr.order === 'ASC' ? 'UP' : 'DOWN'
  }
  const closeModal = () => {
    setshowPopup(false)
    setShowArchivepopup(false)
    setshowrolePopup(false)
    setShowContactPopup(false)
  }
  const sortArray = [{
    content: 'First Name',
    value: 'firstname',
    order: 'ASC',
    sort: '(A-Z)',
    checked: false,
    id: 1
  }, {
    content: 'Surname',
    value: 'lastname',
    order: 'ASC',
    sort: '(A-Z)',
    checked: false,
    id: 2
  }, {
    content: 'First Name',
    sort: '(Z-A)',
    value: 'firstname',
    checked: false,
    order: 'DESC',
    id: 3
  }, {
    content: 'Surname',
    sort: '(Z-A)',
    value: 'lastname',
    order: 'DESC',
    checked: false,
    id: 4
  }]

  const {
    state: { user },
  } = useAuthContext()

  // Permission Handle
  const dataval: any = user;

  let permissionAccess = dataval && dataval.user ? dataval.user : dataval;

  let permissionAccessunarchive = dataval && dataval.user ? dataval.user : dataval;

  permissionAccess = permissionAccess && permissionAccess.permissions?.orders_and_results.filter((ele: any) => { return ele.name === 'archive' });

  permissionAccessunarchive = permissionAccessunarchive && permissionAccessunarchive.permissions?.orders_and_results.filter((ele: any) => { return ele.name === 'unarchive' });

  /* Selected by sort value */
  function nameSorting(sortingvalue: any) {
    sortArray.map((valueData) => {
      if (sortingvalue.id === valueData.id) {
        sortingvalue.checked = true;
        valueData.checked = true;
        setSelectSortValue(sortingvalue.id);
      }
      else {
        valueData.checked = false;
      }
    })
    sortingvalue.checked = true

  }

  useEffect(() => {
    setContactInfo({})
    if (getTableHead) {
      setTableHeadLength(getTableHead.length)
    }
  }, [getTableHead])

  // const fetchtdldata = useCallback(() => {
  //   fetch(process.env.REACT_APP_SERVICE_BACKEND_URL + '/admin/gettdldata')
  //     .then((res) => {
  //       return res.json()
  //     })
  //     .then((res) => {
  //       showresults(res.data)
  //       // clearTimeout(__timeout);
  //     })
  //     .catch((e) => {
  //       console.log(e)
  //     })
  // }, [])

  const checkString = new URL(window.location.href).searchParams.get('filters'); /* Get search string values */

  // useEffect(() => {

  //   let __timeout: NodeJS.Timer
  //   try {
  //     // __timeout = setInterval(() => {
  //     //     fetchtdldata()
  //     // }, 10000)

  //     if (type === 'orderKit') {
  //       fetchtdldata()
  //     }
  //   } catch (e) { }

  //   return () => clearInterval(__timeout)
  // }, [])
  let checkalltruevalue = [];
  let multiselectAll: any = [];
  let singlechecktrue: any = [];
  let singleCheck: any = [];
  let singleCheckbox: any = [];
  let selectArr: any = [];
  let checkTureValue: any = [];
  let _allarr: any = [];
  let checkboxtrue : any = [];

  // let checkedUserList: any =[];

  // useEffect(()=>{

  // },[])
  useEffect(() => {
    let _arr: any = []
    // let _allarr: any = [];
    if (type === 'orderKit') {
      
      _arr = getTableContent?.concat()
      _allarr = getTableContent?.concat();
      selectArr.push(getTableContent);

      /* temporary added the Lilie Id key in existing object for design purpose */
      if (_arr && _arr.length) {
        _arr = _arr.map((item: any) => {
          item['Lilie Id'] = {
            content: 'Lilie Id', value: "-", obj: item['Requested recieved'].obj
          };
          return item;
        })
      }
      /* end of temporary added the Lilie Id key in existing object for design purpose */

      if (_arr && getTableContent) {
        setAllUserData(_arr)
        for (let index = 0; index < _arr.length; index++) {
          if (_arr[index]['Requested recieved'].obj.checked === true) {
            checkTureValue.push(_arr[index])
          }
        }
        // if(c_url === 'Flagged'){
        //   _arr = _arr.splice(inc, inc + 5 > getTableContent?.length ? inc + (getTableContent?.length % 5) : 5)
        // }
        if (_arr) {
          for (let index = 0; index < _arr.length; index++) {
            if (_arr && _arr[index] && _arr[index]['Requested recieved'] && _arr[index]['Requested recieved']['obj'] && _arr[index]['Requested recieved']['obj']['flagedVal'] === false) {
              singleCheckbox.push(_arr[index])
              singlechecktrue = [];
            }
            if (_arr && _arr[index] && _arr[index]['Requested recieved'] && _arr[index]['Requested recieved']['obj'] && _arr[index]['Requested recieved']['obj']['checked'] === true) {
              singleCheck.push(_arr[index])
              checkalltruevalue = []
            }
          }
        }
        if (_allarr) {
          for (let index = 0; index < _allarr.length; index++) {
            if (_allarr && _allarr[index] && _allarr[index]['Requested recieved'] && _allarr[index]['Requested recieved']['obj'] && _allarr[index]['Requested recieved']['obj']['flagedVal'] === false) {
              singlechecktrue.push(_allarr[index])
              singleCheckbox = []
            }
            if (_allarr && _allarr[index] && _allarr[index]['Requested recieved'] && _allarr[index]['Requested recieved']['obj'] && _allarr[index]['Requested recieved']['obj']['checked'] === true) {
              checkalltruevalue.push(_allarr[index])
              singleCheck = []
            }
          }
        }
        if (_allarr) {
          _allarr.map((menu: any) => {
            menu['Requested recieved'].obj.flagedVal = null;
            let result = [];
            result = menu['Requested recieved'].obj.history.filter((a: any) => a.action === 'reject')

            if (checkString === 'Search results' || checkString === 'Rejected' || checkString === 'Positive' || checkString === 'Negative'
              || checkString === 'Inconclusive' || checkString === 'Added to Lilie') {
              // element.classList.add("mystyle");
              if (checkTureValue.length > 0) {
                setisArchiveSearchUser(true)
                setArchiveshow(false)
                SetShowselectcount(true)
              }
              else {
                setisArchiveSearchUser(false)
                setArchiveshow(true)
                SetShowselectcount(false)
              }
              // if (singleCheck.length === singleCheckbox.length) {
              //   selectallcheck[pagenum] = true
              // }
              // else {
              //   selectallcheck[pagenum] = false
              // }

              if ((checkalltruevalue.length === 0) && (singlechecktrue.length === 0)) {
                selectallcheck[pagenum] = false
              }
              else if (checkalltruevalue.length === singlechecktrue.length) {
                selectallcheck[pagenum] = true
              }
              else {
                selectallcheck[pagenum] = false
              }

              if (selectArrData && selectArrData.length) {
                selectArrData && selectArrData.map((valData: any) => {
                  if (valData && valData['Requested recieved'] && valData['Requested recieved'].obj && valData['Requested recieved'].obj.checked === false) {
                    if (selectallcheck[pagenum] === true) {
                      valData['Requested recieved'].obj.checked = true;
                    }
                  }
                })
              }
              // console.log(menu['Requested recieved'].obj, '---menuobj---');
              // console.log(menu['Result'], '------menu[]------');


              /* Checkbox Enable Condition */
              if ((menu['Result'] && ((menu['Result'].value.toUpperCase() === 'NEGATIVE' && (menu['Requested recieved']['obj'].archive_status === false || menu['Requested recieved']['obj'].archive_status === null)) || (menu['Result'].value.toUpperCase() === 'INCONCLUSIVE' && menu['Requested recieved']['obj'].isLilie === true && (menu['Requested recieved']['obj'].archive_status === false || menu['Requested recieved']['obj'].archive_status === null)) || (menu['Result'].value.toUpperCase() === 'POSITIVE' && menu['Requested recieved']['obj'].isLilie === true && (menu['Requested recieved']['obj'].archive_status === false || menu['Requested recieved']['obj'].archive_status === null))) || (result[0] && result[0].action && result[0].action === 'reject' && (menu['Requested recieved']['obj'].archive_status === false || menu['Requested recieved']['obj'].archive_status === null)))) {
                menu['Requested recieved'].obj.flagedVal = false;
                setcheckBoxshow(true);
              }
              else {  /* Checkbox Disable Condition */
                menu['Requested recieved'].obj.flagedVal = true;
                menu['Requested recieved'].obj.checked = false;
                setcheckBoxshow(true);
              }
            }

          });
          for (let i = 0; i < _allarr.length; i++) {
            if (_allarr && _allarr[i] && _allarr[i]['Requested recieved'] && _allarr[i]['Requested recieved']['obj'] && _allarr[i]['Requested recieved']['obj']['flagedVal'] === true)
              multiselectAll.push(_allarr[i])
          }
          if (multiselectAll.length === _allarr.length) {
            setCheckAlltrue(true)
          }
          else {
            setCheckAlltrue(false)
          }
        }
      }
    } else {
      if (_arr) _arr = getTableContent?.concat()
    }
    setarr(_arr)

    let checkedUserList:any = localStorage.getItem('archive_list');
    let checkedlist = JSON.parse(checkedUserList);
    if(checkedlist?.list){   
      setCheckAlltrue(false)
    }
    else{
      setCheckAlltrue(true)
    }

  }, [getTableContent, inc]);

  // Get connect disabled state
  function getConnectDisabled(data: any) {
    let result = false
    if (new URL(window.location.href).searchParams.get('filters') === 'Flagged' ||
      (new URL(window.location.href).searchParams.get('filters') === 'Search results' &&
        data.history.length === 0 && (data.tdl_status === '' || data.tdl_status === null))) {
      result = true;
    }
    return result;
  }

  function callNameinfo(event: any, adminid: any, category: any) {
    if (category === 'admins') {
      apiFunctions
        .get(process.env.REACT_APP_SERVICE_BACKEND_URL + '/admin/get-admin-profile?id=' + adminid)
        .then((e) => {
          setvalues(e.data.data[0])
          // setshowPopup(true);
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      apiFunctions
        .get(process.env.REACT_APP_SERVICE_BACKEND_URL + '/admin/get-answers?id=' + adminid)
        .then((e) => {
          setvalues(e)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
  function showUserpopup(e: any) {
    if (e.target.cellIndex === 10 || e.target.cellIndex === undefined) {
      return
    }
    setshowPopup(true)
  }

  let arraSort: any = [];
  const SearchFilter = (filterStr: any, filterorder: any) => {
    setShowSort(false);
    sortArray.forEach((val) => {
      if (val.id === selectSortValue) {
        arraSort.push(val)
      }
    })
    localStorage.setItem("filterValue", selectSortValue);
    if (type === 'orderKit') {
      let strLowerCase: any;
      if (filterStr) {
        strLowerCase = String(filterStr).toLowerCase().trim().replace(' ', '')
      } else {
        strLowerCase = String(arraSort[0] && arraSort[0].value).toLowerCase().trim().replace(' ', '')
      }
      setSortvalue(filterStr);
      if (
        strLowerCase === 'requestreceived' ||
        strLowerCase === 'name' ||
        strLowerCase === 'firstname' ||
        strLowerCase === 'lastname' ||
        strLowerCase === 'age' ||
        strLowerCase === 'localauthority' ||
        strLowerCase === 'referenceno' ||
        strLowerCase === 'dateapproved' ||
        strLowerCase === 'inlilie?' ||
        strLowerCase === 'samplereceived'
      ) {

        let classStr = document.getElementsByClassName(strLowerCase)[0]
        const __el: any = '';
        if (classStr) {
          const __el: any = classStr
          let Order = 'DESC'
          let SetArrowDirection = ''
          if (SearchOrder === 'DOWN') {
            SetArrowDirection = 'UP'
            __el.style.borderBottomColor = 'rgba(0,0,0,0)'
            __el.style.borderTopColor = '#666'
            Order = 'ASC'
          } else {
            SetArrowDirection = 'DOWN'
            __el.style.borderTopColor = 'rgba(0,0,0,0)'
            __el.style.borderBottomColor = '#666'
            Order = 'DESC'
          }
          // localStorage.removeItem("filterValue");
          if (filterData) filterData({ str: filterStr, order: Order })  /* Normal Sorting */
          __el.setAttribute('data-arrowdirection', SearchOrder)
        }
        else {  /* Name Sorting */
          if (filterData) filterData({ str: arraSort[0].value, order: arraSort[0].order })
          // setAttribute('data-arrowdirection', SearchOrder)
        }
      }
    }
    // setShowSort(false);
    return
  }


  
  /* Archive by single click checkbox */
  const archiveCheckbox = (item: any, e: any, index: any) => {
    
    let userList:any = localStorage.getItem('archive_list');
    let checkedlist = JSON.parse(userList);
    let listUser = checkedlist && checkedlist.list.map((res:any)=>{
        res.checked = res.id === item.id ? e.target.checked :res.checked;
        return res;
    });    
    setArchiveList(listUser);
    let obj:any = {
      title: checkedlist.title && checkedlist.title,
      list: listUser
   }
    localStorage.setItem('archive_list',JSON.stringify(obj))

   if(checkedlist.list.filter((res:any) => res.checked === true).length ===  checkedlist.list.length){
    setSelectAll(true);
   }else{
    setSelectAll(false);
   }

    if (e.target.checked === true && item.id) {
      item.checked = e.target.checked;
      setisArchiveSearchUser(true);
      setArchiveshow(false);
      if (arr && getTableContent) {
        getTableContent.forEach((val: any) => {
          if (val['Requested recieved']['obj']['checked'] === true) {
            selectCount.push(val);
            setSelectArrData(selectCount);
          }
        })

        SetSelectpagecount({ 'totalrec': selectCount.length });
      }
    }
    else {
      item.checked = e.target.checked;
      if (arr && getTableContent) {
        getTableContent.forEach((val: any) => {
          if (val['Requested recieved']['obj']['checked'] === true) {
            selectCount.push(val);
            setSelectArrData(selectCount);
          }
        })
        SetSelectpagecount({ 'totalrec': selectCount.length });
      }
    }
    selectArr = getTableContent?.concat();
    for (let index = 0; index < selectArr.length; index++) {
      if (selectArr[index]['Requested recieved']['obj']['checked'] === true) {
        checkTureValue.push(selectArr[index])
      }
    }
    if (checkTureValue.length > 0) {
      setisArchiveSearchUser(true)
      setArchiveshow(false)
      SetShowselectcount(true)
    }
    else {
      setisArchiveSearchUser(false)
      setArchiveshow(true)
      SetShowselectcount(false)
    }
    for (let index = 0; index < arr.length; index++) {
      if (arr && arr[index] && arr[index]['Requested recieved'] && arr[index]['Requested recieved']['obj'] && arr[index]['Requested recieved']['obj']['checked'] === true) {
        singleCheck.push(arr[index])
      }
    }
    for (let index = 0; index < arr.length; index++) {
      if (arr && arr[index] && arr[index]['Requested recieved'] && arr[index]['Requested recieved']['obj'] && arr[index]['Requested recieved']['obj']['flagedVal'] === false) {
        singlechecktrue.push(arr[index])
      }
    }
    if (singleCheck.length === singlechecktrue.length) {
      selectallcheck[pagenum] = true
    }
    else {
      selectallcheck[pagenum] = false
    }
    setarr([...arr]);
  }

  let selectCount: any = [];
  /* Archive by multi click checkbox */
  const selectAllcheckbox = (e: any) => {


    let userList:any = localStorage.getItem('archive_list');
    let checkedlist = JSON.parse(userList);
    let listUser = checkedlist && checkedlist?.list?.map((res:any)=>{
        res.checked = e.target.checked;
        return res;
    });
    
    setArchiveList(listUser);
    let obj:any = {
      title: checkedlist.title && checkedlist.title,
      list: listUser
   }
    localStorage.setItem('archive_list',JSON.stringify(obj))

    setSelectAll(e.target.checked);
    if (selectallcheck.length < 1) {
      const aa = Math.ceil(getTableContent.length / 5);
      let checkarr = Array(aa).fill(false);
      checkarr[pagenum] = e.target.checked;
      SetSelectallcheck(checkarr)
    }
    else {
      selectallcheck[pagenum] = e.target.checked;
      SetSelectallcheck(selectallcheck);
    }

    if (e.target.checked === true) {
      setArchivehead(true);
      getTableContent.forEach((menu: any) => {
        menu['Requested recieved']['obj']['checked'] = e.target.checked as never;
        let reject_user: { [key: string]: any } = {};
        let result = menu['Requested recieved'].obj.history.filter((a: any) => a.action === 'reject')

        if ((menu['Result'] && ((menu['Result'].value.toUpperCase() === 'NEGATIVE' && (menu['Requested recieved']['obj'].archive_status === false || menu['Requested recieved']['obj'].archive_status === null)) || (menu['Result'].value.toUpperCase() === 'INCONCLUSIVE' && menu['Requested recieved']['obj'].isLilie === true && (menu['Requested recieved']['obj'].archive_status === false || menu['Requested recieved']['obj'].archive_status === null)) || (menu['Result'].value.toUpperCase() === 'POSITIVE' && menu['Requested recieved']['obj'].isLilie === true && (menu['Requested recieved']['obj'].archive_status === false || menu['Requested recieved']['obj'].archive_status === null))) || (result[0] && result[0].action && result[0].action === 'reject' && (menu['Requested recieved']['obj'].archive_status === false || menu['Requested recieved']['obj'].archive_status === null)))) {
          menu['Requested recieved']['obj']['checked'] = e.target.checked as never;
          setArchiveshow(false);
          setisArchiveSearchUser(true)
        }
        else {
          menu['Requested recieved']['obj']['checked'] = false;
          setisArchiveSearchUser(false)
          setArchiveshow(true);
        }
      })
      if (arr && getTableContent) {
        getTableContent.forEach((val: any) => {
          if (val['Requested recieved']['obj']['checked'] === true) {
            selectCount.push(val);
            setSelectArrData(selectCount);
          }
        })
        SetSelectpagecount({ 'totalrec': selectCount.length });
      }
    }
    if (e.target.checked === false) {
      setArchivehead(false);
      setArchiveshow(true);
      getTableContent.forEach((menu: any) => {
        menu['Requested recieved']['obj']['checked'] = e.target.checked;
      })
      setisArchiveSearchUser(false)
      setArchiveshow(true);
      SetSelectpagecount({ 'totalrec': selectCount.length });
      if (arr && getTableContent) {
        getTableContent.forEach((val: any) => {
          if (val['Requested recieved']['obj']['checked'] === true) {
            selectCount.push(val);
            setSelectArrData(selectCount)
          }
        })
        SetSelectpagecount({ 'totalrec': selectCount.length });
      }
    }

    selectArr = getTableContent?.concat();
    for (let index = 0; index < selectArr.length; index++) {
      if (selectArr && selectArr[index] && selectArr[index]['Requested recieved'] && selectArr[index]['Requested recieved']['obj'] && selectArr[index]['Requested recieved']['obj']['checked'] === true) {
        checkTureValue.push(selectArr[index])
      }
    }
    for (let index = 0; index < selectArr.length; index++) {
      if (selectArr && selectArr[index] && selectArr[index]['Requested recieved'] && selectArr[index]['Requested recieved']['obj'] && selectArr[index]['Requested recieved']['obj']['flagedVal'] === false) {
        singlechecktrue.push(selectArr[index])
      }
      if (selectArr && selectArr[index] && selectArr[index]['Requested recieved'] && selectArr[index]['Requested recieved']['obj'] && selectArr[index]['Requested recieved']['obj']['checked'] === true) {
        checkalltruevalue.push(selectArr[index])
      }
    }
    if (checkTureValue.length > 0) {
      setisArchiveSearchUser(true)
      setArchiveshow(false)
      SetShowselectcount(true)
    }
    else {
      setisArchiveSearchUser(false)
      setArchiveshow(true)
      SetShowselectcount(false)
    }

    if (checkalltruevalue.length === singlechecktrue.length) {
      selectallcheck[pagenum] = true
    }
    else {
      selectallcheck[pagenum] = false
    }
  }

  /* Confirm popup archive by button */
  function ArchiveUser(userinfo: any, type: any) {   
    let userDetails = {
      users: userinfo,
      Atype: type,
    }
    setuserInfo(userDetails)
    setShowArchivepopup(true)
  }
  /* Confirm popup archive by checkbox */
  function ArchiveSearchUser() {
    let archiveUsers: any = [];
    getTableContent.forEach((menu: any) => {
      if (menu['Requested recieved']['obj']['checked'] === true) {
        archiveUsers.push(menu['Requested recieved']['obj']);
        return archiveUsers;
      }
    })
    let userDetails = {
      users: archiveUsers,
      Atype: 'archive',
    }
    setuserInfo(userDetails)
    setShowArchivepopup(true)
  }
  const handleClick = (data: any) => {
    setShowContactPopup(true)
    setContactInfo(data)

  }

  /* Clear Name Sorting */
  function clearsort(filterStr: any, filterorder: any) {
    if ($('input[name=radio]:checked').length > 0) {
      $(".radio-input").prop("checked", false);
      localStorage.removeItem("filterValue");
      if (filterData) filterData({ str: '', order: '' })
      setShowSort(false);
      return
    }
  }
  function checkArchive(values: any, result: any) {
    const resultFiltered = values.result.filter((res: any) => res.result_type_short === 'SUMX');
    if (
      new URL(window.location.href).searchParams.get('filters') === 'Search results' &&
      values.tdl_status === 'approved' && values.result.length === 0
    ) {
      return true;
    }
    if (
      new URL(window.location.href).searchParams.get('filters') === 'Search results' &&
      values.result.length > 0 && values.isLilie === true && resultFiltered[resultFiltered.length - 1] && resultFiltered[resultFiltered.length - 1]['result_status'] && resultFiltered[resultFiltered.length - 1]['result_status'] !== 'NEGATIVE'
    ) {
      return false;
    }
    if (
      new URL(window.location.href).searchParams.get('filters') === 'Search results' &&
      values.result.length > 0
    ) {
      return resultFiltered && resultFiltered[resultFiltered.length - 1] && resultFiltered[resultFiltered.length - 1]['result_status'] === 'NEGATIVE' ? false : true;
    }
    if (
      new URL(window.location.href).searchParams.get('filters') === 'Search results' &&
      values.isLilie === false && values.history.filter((his: any) => his.action === 'reject').length === 0
    ) {
      return true;
    }
    if (
      new URL(window.location.href).searchParams.get('filters') === 'Positive' ||
      new URL(window.location.href).searchParams.get('filters') === 'Negative' ||
      new URL(window.location.href).searchParams.get('filters') === 'Inconclusive'
    ) {
      return false
    }
    if (new URL(window.location.href).searchParams.get('filters') === 'Added to Lilie' && values.age > 17 && values.result.length > 0) {

      return false
    }
    if (result !== undefined && ((result && result.value === '-') || (result && result.value === '')) && new URL(window.location.href).searchParams.get('filters') === 'Search results') {
      return true
    }
    if (values.history.length > 0 && values.age <= 17) {
      let resultStatus = values.history.filter((his: any) => his.action === 'approve').length
      let rejectstatus = values.history.filter((his: any) => his.action === 'reject').length
      return (resultStatus > 0 && new URL(window.location.href).searchParams.get('filters') !== 'Added to Lilie')
        || rejectstatus > 0 ||
        (resultStatus > 0 && values.result.length > 0 && new URL(window.location.href).searchParams.get('filters') === 'Added to Lilie') ? false : true
    } else {
      return true
    }
  }
  let tabIndex = 0;
  const noTab = -1;

  // GET Type of Contact Attempt Response
  const getType = (data: any) => {
    let type = 'add';
    let contactAttempts: any
    let LC_Attempt: any
    let LC_AwaitingAttempt: any

    if (data.contact_attempt.length > 9) {
      LC_Attempt = data.contact_attempt.filter((res: any) => {
        return res.status === "client contacted";
      });
      LC_AwaitingAttempt = data.contact_attempt.filter((res: any) => {
        return res.status === "awaiting response";
      });
      contactAttempts = LC_Attempt && LC_Attempt.length ? LC_Attempt[0] :
        (LC_AwaitingAttempt && LC_AwaitingAttempt.length ? LC_AwaitingAttempt[0] :
          data.contact_attempt[data.contact_attempt.length - 1]
        );

    } else if (data.contact_attempt.length > 0 && data.contact_attempt.length <= 9) {
      LC_Attempt = data.contact_attempt.filter((res: any) => {
        return res.status === "client contacted";
      })
      contactAttempts = LC_Attempt && LC_Attempt && LC_Attempt.length ? LC_Attempt[0] : [];
    }
    const last_connect = contactAttempts ? contactAttempts : type;
    if (last_connect.status === "client contacted") {
      type = 'clientContacted';
    } else if (last_connect.status === "awaiting response") {
      type = 'awaitingResponse';
    }
    else if (last_connect.status === "unable to contact") {
      type = 'unableToContact';
    }
    return type;
  }
  /* Enable name sorting radio button */
  const checkedFun = (id: number) => {
    return id === selectSortValue ? true : false
  }

  // get multiple order count
  useEffect(() => {
    setMultiOrderCountList(multiorderCountList);   
    // apiFunctions.get(process.env.REACT_APP_SERVICE_BACKEND_URL + '/admin/get-multiple-orders-count').then(_result => {
    //   if (_result && _result.data && _result.data.data) {
    //   }
    // }).catch((err) => {
      // console.log('err.response ', checkedlist);
    // })

  }, [multiorderCountList]);
  // get checked values
  function getChecked(userId:string){
    return archiveList.filter((res:any) => userId === res.id)?.[0];
  }
  return (
    <>
      {showLoader && <Loader />}
      <div className="table-overflow">
        {arr && (arr.length === 0 && (type === 'users' || type === 'admins' || type === 'superadmins')) || arr && arr.length ?
          <>
            <div
              className={`table-view  ${checkString === 'Search results' ? 'search-table-width' : ''}
               ${checkString === 'Positive' ? 'positive-table-width' : ''} 
               ${(checkString === 'Negative' || checkString === 'Inconclusive') ? 'negative-width' : ''}
                ${checkString === 'Rejected' ? 'rejected-table-width' : ''}
               ${type === 'admins' ? 'admin-table-width' : ''}
                  ${(new URL(window.location.href).searchParams.get('filters') === 'Added to Lilie' ||
                  new URL(window.location.href).searchParams.get('filters') === 'Archive')
                  ? 'lilie-width'
                  : ''
                }
          ${tableHeadLength < 5 ? 'less-than-5' : ''} ${tableHeadLength === 6 ? 'equal-to-6' : type === 'admin-history' ? 'history-table-height' : ''
                }`}
            >
              <table>
                <thead>
                  <div className={`sort-blk ${(tableHeadLength === 15 || tableHeadLength === 14) ? 'sort-blk-15' : (tableHeadLength === 10 || tableHeadLength === 11) ? 'sort-blk-10' :
                    tableHeadLength === 12 ? 'sort-blk-12' : ''}`}>
                    {showSort &&
                      <>
                        <div className="close-white-pos">
                          <img className="close-white" src={closeIcon} alt="closeWhiteIcon" onClick={() => { closeSort() }} />
                        </div>
                        <div className='parent-radio'>
                          {sortArray.map((item: any) => (
                            <div className="radio_btns" id="container_radio">
                              <div key={item.id}>
                                <label>
                                  <input type="radio" name='radio' checked={checkedFun(item.id)} className='radio-input' id={item.id} onChange={() => nameSorting(item)} />
                                  <span><span className='sort-label'>{item.content}</span></span>
                                  <span />
                                </label>
                              </div>
                              <p className='mt-1'>{item.sort}</p>
                            </div>
                          ))}
                        </div>
                        <div className='sort-footer-blk' />
                        <div className='footer-blk-btn'>
                          <button className='btn mr-3' onClick={() => clearsort('', '')}>Clear sort</button>
                          <button className='btn login-btn' onClick={() => SearchFilter('', '')}>Sort view</button>
                        </div>
                      </>
                    }
                  </div>
                  {(archiveList && archiveList?.filter((res:any) => res.checked === true).length > 0 && (localStorage.getItem('role') === '1' || localStorage.getItem('role') === '2')) &&
                    <div>
                      <img src={SearchIcon} alt='search' className={`search-icon archieve-icon ${archiveHead ? 'top-pos' : ''} ${(new URL(window.location.href).searchParams.get('filters') === 'Added to Lilie') ? 'lilie-pos' : ''} ${(new URL(window.location.href).searchParams.get('filters') === 'Negative') ? 'negative-pos' : ''}`} data-tooltip-content="Archive" onClick={(e) => {
                        ArchiveSearchUser()
                      }
                      } />
                      <div className={`sub-tool d-none ${archiveHead ? '' : 'bottom-pos'} ${checkBoxshow ? 'move-to-top' : ''}`}>Archive</div>
                    </div>
                  }
                  {/* {(checkString === 'Search results' || checkString === 'Rejected' || checkString === 'Positive' || checkString === 'Negative'
              || checkString === 'Inconclusive' || checkString === 'Added to Lilie')  ?
                    <img src={disableArchiveIcon} alt='search' className={`search-icon archieve-icon archieve-pos ${(new URL(window.location.href).searchParams.get('filters') === 'Added to Lilie') ? 'lilie-pos' : ''} ${(new URL(window.location.href).searchParams.get('filters') === 'Negative') ? 'negative-pos' : ''}`} data-tooltip-content="Archive" />
                   :''} */}
                   {!archiveList && 
                    <img src={disableArchiveIcon} alt='search' className={`search-icon archieve-icon archieve-pos ${(new URL(window.location.href).searchParams.get('filters') === 'Added to Lilie') ? 'lilie-pos' : ''} ${(new URL(window.location.href).searchParams.get('filters') === 'Negative') ? 'negative-pos' : ''}`} data-tooltip-content="Archive" />
                  }
                  <tr tabIndex={tabIndex}>
                    {checkBoxshow &&
                      <div>
                        {(archiveHead && selectallcheck[pagenum]) &&
                          <div className='bg-archive' />
                        }
                        <div className='archive-checkbox archive-head'><AdminCheckbox type='checkbox' name='archive1' checked={selectAll} disabled={checkAlltrue || !(permissionAccess && permissionAccess[0] && permissionAccess[0].value)} onChange={selectAllcheckbox} />{ archiveList && archiveList.filter((res:any) => res.checked === true).length >0 && <span className={`selected-archieve ${selectallcheck[pagenum]} ? 'check-top' : archiveshow ? 'no-check-top' : ''}`}>Selected {archiveList && archiveList.filter((res:any) => res.checked === true).length} of {totalArchive}</span>}
                        </div>
                      </div>
                    }
                    {getTableHead &&
                      getTableHead.map((menu, index) => (
                        <th
                          tabIndex={index === 0 ? noTab : tabIndex}
                          key={'tableHead_' + index.toString()}
                          id={String(menu).toLowerCase().trim().replace(' ', '')}
                          className={`${String(menu) === 'Approve kit'
                            ? 'th-medium'
                            : type === 'orderKit' &&
                              (String(menu).toLowerCase().trim().replace(' ', '') === 'requestreceived' ||
                                // String(menu).toLowerCase().trim().replace(' ', '') === 'name' ||
                                String(menu).toLowerCase().trim().replace(' ', '') === 'age' ||
                                String(menu).toLowerCase().trim().replace(' ', '') === 'dateapproved' ||
                                String(menu).toLowerCase().trim().replace(' ', '') === 'referenceno' ||
                                String(menu).toLowerCase().trim().replace(' ', '') === 'inlilie?' ||
                                String(menu).toLowerCase().trim().replace(' ', '') === 'localauthority' ||
                                String(menu).toLowerCase().trim().replace(' ', '') === 'samplereceived')
                              ? 'head-position'
                              : ''
                            } ${String(menu).toLowerCase().trim().replace(' ', '') === 'requestreceived'} `}
                          onClick={(e) => {
                            if (String(menu).toLowerCase().trim().replace(' ', '') !== 'name') {
                              SearchFilter(menu, e);
                              $(".radio-input").prop("checked", false);
                              localStorage.removeItem("filterValue");
                              setSelectSortValue('');
                            } else {
                              setShowSort(true)

                            }
                          }}
                        ><span className='sr-only'>table</span>

                          {menu}
                          {type === 'orderKit' ? (
                            String(menu).toLowerCase().trim().replace(' ', '') === 'requestreceived' ||
                              String(menu).toLowerCase().trim().replace(' ', '') === 'name' ||
                              String(menu).toLowerCase().trim().replace(' ', '') === 'age' ||
                              String(menu).toLowerCase().trim().replace(' ', '') === 'dateapproved' ||
                              String(menu).toLowerCase().trim().replace(' ', '') === 'referenceno' ||
                              String(menu).toLowerCase().trim().replace(' ', '') === 'localauthority' ||
                              String(menu).toLowerCase().trim().replace(' ', '') === 'inlilie?' ||
                              String(menu).toLowerCase().trim().replace(' ', '') === 'samplereceived' ? (
                              <div
                                tabIndex={tabIndex}
                                className={
                                  (searchstr === String(menu).trim().replace(' ', '') && SearchOrder === 'UP'
                                    ? 'child-div-up onkeyup'
                                    : 'child-div onkeyup') +
                                  '  ' +
                                  String(menu).toLowerCase().trim().replace(' ', '')
                                }
                                data-arrowdirection={searchstr === String(menu).trim().replace(' ', '') ? SearchOrder : ''}
                              />
                            ) : (
                              ''
                            )
                          ) : (
                            ''
                          )}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {arr && arr.length === 0 && (type === 'users' || type === 'admins' || type === 'superadmins') ? (
                    <tr>
                      <td>No data found. Please select a different location</td>
                      <td />
                      <td />
                      <td />
                    </tr>
                  ) : (
                    <tr />
                  )}
                  {arr &&
                    arr.map((menu: any, index) => {
                      let menuName: any;
                      if (menu && menu['Requested recieved']) {
                        menuName = menu && menu['Requested recieved'] && menu['Requested recieved'].obj;
                        let countValuebyemail: any = multiOrderCountList?.length > 0 && multiOrderCountList.filter((item: any) => item.email === menuName.email ? item.count : 0);
                        let countValuebyphone = multiOrderCountList?.length > 0 && multiOrderCountList.filter((item: any) => item.phone === menuName.phone_no ? item.count : 0);
                        if (countValuebyemail && countValuebyemail[0] && countValuebyemail[0].count > 1) {
                          menuName['count'] = countValuebyemail && countValuebyemail[0] && countValuebyemail[0].count ? Number(countValuebyemail[0].count) : 0;
                        }
                        if (countValuebyphone && countValuebyphone[0] && countValuebyphone[0].count > 1) {
                          menuName['count'] = countValuebyphone && countValuebyphone[0] && countValuebyphone[0].count ? Number(countValuebyphone[0].count) : 0;
                        }
                      }


                      return (
                        <>
                          {tableHeadLength >= 10 &&
                            <div className='archive-popup'>{((menu && menu['Requested recieved'] && menu['Requested recieved'].obj && menu['Requested recieved'].obj.archive_status === true) && (menu && menu['Requested recieved'] && menu['Requested recieved'].obj && menu['Requested recieved'].obj.archivedate)) ? `Archived-${(menu['Requested recieved'].obj.archivedate) ? moment(menu['Requested recieved'].obj.archivedate).format('DD/MM/YYYY') : ''}` : ''} </div>
                          }
                          {/* At below tr archieved class is for outlineof row */}
                          {/* {ordercount && ordercount.map((menu:any,index)=>{

                          })} */}
                          <tr
                            key={'tableSubHead_' + index.toString()}
                            className={`${menuName && menuName.count && Multiresp !== true ? 'selected-row-blk' : ''} ${menu && menu['Reference no'] && menu['Reference no'].obj && menu['Reference no'].obj.form_source === 'tdl' && 'unsolicited-form'} cursor-default ${menu && menu['Reference no'] && menu['Reference no'].obj && menu['Requested recieved'].obj.checked === true ? 'archived' : ''}`}>
                            {checkBoxshow && 
                              <td className='archive-checkbox archive-row'><AdminCheckbox type='checkbox' name='archive2' disabled={menu['Requested recieved'].obj.flagedVal || !(permissionAccess && permissionAccess[0] && permissionAccess[0].value)} checked={ archiveList && getChecked(menu['Requested recieved'].obj.id)?getChecked(menu['Requested recieved'].obj.id).checked :false} onChange={(e) => archiveCheckbox(menu['Requested recieved'].obj, e, index)} /></td>
                            }
                            {type === 'orderKit' &&
                              ((menu['Requested recieved'].approveStatus && !menu['Age'].obj.isLilie) ||
                                menu['Requested recieved'].obj.result_updated) ? (
                              <>
                                {menu['Requested recieved'].obj.result_updated ? (
                                  <td className="updated-class">
                                    <div className="rotate-mode">
                                      Updated
                                      <img className="rotate-mode" src={circledown} alt='circle down' width="13px" height="13px" />
                                    </div>
                                  </td>
                                ) : (
                                  <td className="fourtyeight-class">
                                    48 <br />
                                    hrs
                                  </td>
                                )}
                              </>
                            ) : type === 'orderKit' ? (
                              <td />
                            ) : (
                              ''
                            )}

                            {Object.keys(menu).map((key: any) => {
                              return (
                                <td
                                  key={'tableContent_' + key + '' + index.toString()}
                                  className={`${menu[key].content === 'result' ? 'result-text-wrap cursor-default' : menu[key].email_type
                                    ? 'wrap-email'
                                    : type === 'orderKit' || type === 'admins' || type === 'users' || type === 'superadmins'
                                      ? 'table-row-pointer'
                                      : 'cursor-default'
                                    }`}
                                  data-label={key}
                                >
                                  {
                                    (menu[key].content === 'Archive' && menu[key].label === 'archive') && (permissionAccess && permissionAccess[0] && permissionAccess[0].value) ? (
                                      <button
                                        className={`archive-kit-btn amend mr-0 ${(checkArchive(
                                          menu[key].obj,
                                          menu['Result'] &&
                                          new URL(window.location.href).searchParams.get('filters') !== 'Rejected'
                                        ) ||
                                          (new URL(window.location.href).searchParams.get('filters') === 'Positive' &&
                                            !menu[key].obj.isLilie) ||
                                          (new URL(window.location.href).searchParams.get('filters') === 'Inconclusive' &&
                                            !menu[key].obj.isLilie)) &&
                                          menu[key].label !== 'unarchive' &&
                                          'disable'
                                          }`}
                                        onClick={(e) => {
                                          ArchiveUser(menu[key].obj, menu[key].label)
                                        }}
                                      >
                                        {menu[key].label}
                                      </button>
                                    ) : (menu[key].content === 'Archive' && menu[key].label === 'unarchive') && (permissionAccessunarchive && permissionAccessunarchive[0] && permissionAccessunarchive[0].value) ? (<button
                                      className={`archive-kit-btn amend mr-0 ${(checkArchive(
                                        menu[key].obj,
                                        menu['Result'] &&
                                        new URL(window.location.href).searchParams.get('filters') !== 'Rejected'
                                      ) ||
                                        (new URL(window.location.href).searchParams.get('filters') === 'Positive' &&
                                          !menu[key].obj.isLilie) ||
                                        (new URL(window.location.href).searchParams.get('filters') === 'Inconclusive' &&
                                          !menu[key].obj.isLilie)) &&
                                        menu[key].label !== 'unarchive' &&
                                        'disable'
                                        }`}
                                      onClick={(e) => {
                                        ArchiveUser(menu[key].obj, menu[key].label)
                                      }}
                                    >
                                      {menu[key].label}
                                    </button>) :

                                      menu[key].content === 'delete_role' ? (
                                        <button
                                          className="approve-kit-btn reject mr-3"
                                          onClick={(e) => {
                                            setshowrolePopup(true)
                                            setroleID(menu[key].obj)
                                          }}
                                        >
                                          {menu[key].label}
                                        </button>
                                        // uncomment this after completed
                                      ) : menu[key].content === 'Flags' ? (
                                        <Flags
                                          value={menu[key].value}
                                          addClass={menu[key].color}
                                          myobj={menu[key].obj}
                                          ques={menu[key].questions}
                                          type={menu[key].type}
                                        />
                                      ) : menu[key].content === 'Contact' ? (
                                        <ContactAttempt
                                          type={getType(menu[key].obj)}
                                          myobj={menu[key].obj}
                                          typeClick={handleClick}
                                          contactDisabled={getConnectDisabled(menu[key].obj)}
                                          permissions={menu[key].permissions}
                                          rolePermissions={menu[key].rolePermissions}
                                        />
                                      ) : menu[key].content === 'Kit Status' ? (
                                        <ApproveKit
                                          status={menu[key].status}
                                          obj={menu[key].obj}
                                          ques={menu[key].questions}
                                          type={menu[key].type}
                                          permissions={menu[key].permissions}
                                          rolePermissions={menu[key].rolePermissions}
                                        />
                                      ) : menu[key].content === 'toggle' ? (


                                        <Toggle
                                          status={menu[key].status}
                                          header={key}
                                          adminId={menu[key].obj ? menu[key].obj['id'] : ''}
                                          type={menu[key].type}
                                          category={menu[key].category}
                                          permissions={menu[key].Permissions}
                                        />
                                      ) : menu[key].content === 'button' ? (
                                        <div className="table-button-blk">
                                          <button className="approve-kit-btn amend mr-0">{menu[key].label}</button>
                                        </div>
                                      ) : menu[key].content === 'In Lilie' ? (
                                        <InLilie
                                          status={menu[key].status}
                                          header={key}
                                          adminId={menu[key].obj ? menu[key].obj['id'] : ''}
                                          type={menu[key].type}
                                          myobj={menu[key].obj}
                                        />
                                      ) : menu.content === 'toggle' ? (
                                        <Toggle />
                                      ) : menu[key].content === 'result' ? (
                                        <div className="table-button-blk justify-content-start">
                                          {menu[key].value ? menu[key].value : '-'}
                                        </div>
                                      ) : menu[key].content === 'Lilie Id'
                                        &&
                                        (new URL(window.location.href).searchParams.get('filters') === 'Positive' ||
                                          new URL(window.location.href).searchParams.get('filters') === 'Negative' ||
                                          new URL(window.location.href).searchParams.get('filters') === 'Inconclusive' ||
                                          new URL(window.location.href).searchParams.get('filters') === 'Search results' && menu[key]?.obj?.result.length) ?
                                        <AddLilieId
                                          obj={menu[key].obj}
                                          pageNumber={pagenum} />
                                        : (
                                          //   <ServiceUserName
                                          //   referenceNo={menu['Result']['referenceNo']}
                                          //   result={results}
                                          //   txt={menu[key].value}
                                          //   myobj={menu[key].obj}
                                          //   rolePermissions={menu[key].rolePermissions}
                                          //   adminId={menu[key].obj ? menu[key].obj['id'] : ''}
                                          //   content={menu[key].content}
                                          //   category={menu[key].category}
                                          //   obj={menu[key].obj}
                                          //   permissions={menu[key].permissions}
                                          //   type={type}
                                          // />
                                          // {email: test@gmail.com, count: 4}

                                          // console.log(menu[key].obj,'ObjectName'),
                                          // }
                                          <ServiceUserName
                                            txt={menu[key].value}
                                            adminId={menu[key].obj ? menu[key].obj['id'] : ''}
                                            category={menu[key].category}
                                            obj={menu[key].obj}
                                            permissions={menu[key].permissions}
                                            rolePermissions={menu[key].rolePermissions}
                                            type={type}
                                            columnName={key}
                                            multipleOrderCount={multiOrderCountList}
                                          />
                                        )
                                    // menu[key].content === 'name' ? <ServiceUserName txt={menu[key].value} adminId={menu[key].obj ? menu[key].obj['id'] : ''} category={menu[key].category} obj={menu[key].obj} permissions={menu[key].permissions} /> :
                                  }
                                </td>
                              )
                            })}
                            {menu['Name'] &&
                              menu['Name']['headCategory'] &&
                              menu['Name']['headCategory'] === 'admin' &&
                              menu['Name']['Permissions'] &&
                              menu['Name']['Permissions']['s_remove_admins'] && (
                                <td>
                                  {' '}
                                  <button
                                    className="approve-kit-btn reject"
                                    disabled={localStorage.getItem('role') !== '1'}
                                    onClick={(e) => {
                                      setShowLoader(true)

                                      apiFunctions
                                        .put(
                                          process.env.REACT_APP_SERVICE_BACKEND_URL +
                                          '/admin/removeAsAdmin?id=' +
                                          menu['Name']['obj']['id']
                                        )
                                        .then((e) => {
                                          window.location.reload()
                                        })
                                        .catch((e) => {
                                          console.log(e.response)
                                        })
                                    }}
                                  >
                                    Remove as admin
                                  </button>
                                </td>
                              )}
                          </tr>
                        </>
                      )
                    })}
                </tbody>
              </table>
              {type === 'orderKit' && (
                //     (<>
                //     <Items currentItems={arr} />
                //     <ReactPaginate
                //     breakLabel="..."
                //     nextLabel=">>"
                //     onPageChange={handlePageClick}
                //     pageRangeDisplayed={5}
                //     pageCount={3}
                //     previousLabel="<<"
                //     renderOnZeroPageCount={null}
                //   /></>) : ''

                <Pagination
                  callback={(__param: number) => {
                    setOffset((__param-1)*5)
                     setinc(__param * 5 - 5)
                  }}
                  // length={ c_url === 'Search results' ? getTableContent?.length : totalCount}
                  length={totalCount}
                  inc={inc}
                />
              )}
            </div>
          </>
          : (new URL(window.location.href).searchParams.get('filters') === 'Search results' ? <div className='no-data'>No data found. Please try a different text.</div> : '')
        }
      </div>
      <Popup Archivepopup={ShowArchivepopup} close={closeModal} userdata={userInfo} archiveList={archiveList} />
      {ShowContactPopup && <Popup Contactpopup={ShowContactPopup} close={closeModal} contactInfo={contactInfo} />}
      <Popup removebrookRole={showrolePopup} close={closeModal} userdata={roleID} />
      <Popup userProfile={showPopup} close={closeModal} userdata={values} />
    </>
  )
}
