import React from 'react';

import { BlocksControls, InlineImage, InlineText } from 'react-tinacms-inline';
import '../../styles/navbar.css';

interface NavBarData {
  index: number; data: {
    text: string;
    test_title: string;
    wordmark: { logoImg: string; name: string; };
    buttons: [{ name: string; id: string; link: string; }]
  }
};

function Navbar({ index, data }: NavBarData) {
  const listItems = (data && data.buttons && data.buttons.map((link) =>
    <li id={link.id} key={link.link}><a href={link.link}>{link.name}</a></li>
  )) || [];
  return (
    <BlocksControls index={index} focusRing={{ offset: 0 }} insetControls>
      <div className="navbar__background">
        <div className="wrapper wrapper--narrow">
          <div style={{ textAlign: 'left' }}>
            <a href='/#'>
              <InlineImage
                name="wordmark.logoImg"
                parse={media => (media.previewSrc || '/public/B_Brook_Logo.png')}
                uploadDir={() => '/public/'}
                alt="logo-image"
                className="logo-img"
              />
              <InlineText name="wordmark.name" />
            </a>
          </div>
          <ul style={{ textAlign: 'right' }}>{listItems}</ul>
        </div>
      </div>
    </BlocksControls>
  );
}

export const navbarBlock = {
  Component: Navbar,
  template: {
    label: 'navbar',
    defaultItem: {
      text:
        'Take root and flourish quis nostrum exercitationem ullam corporis suscipit laboriosam culture Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur descended from astronomers encyclopaedia galactica? Nisi ut aliquid ex ea commodi consequatur something incredible is waiting to be known sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem ',
    },
    fields: [
      {
        label: 'Wordmark',
        name: 'wordmark',
        description: 'Wordmark',
        component: 'group',
        fields: [
          {
            label: 'Logo Image',
            name: 'logoImg',
            component: 'image',
            // Generate the frontmatter value based on the filename
            parse: (media: { previewSrc: string; }) => {
              return `${media.previewSrc}`
            },

            // Decide the file upload directory for the post
            uploadDir: () => '/public/',

            // Generate the src attribute for the preview image.
            previewSrc: (fullSrc: string) => fullSrc.replace('/public', ''),
          },
          {
            label: 'Name',
            name: 'name',
            component: 'text',
          },
        ],
      },
      {
        label: 'Navbar List',
        name: 'buttons',
        component: 'group-list',
        description: 'Navbar List',
        itemProps: (item: { id: any; name: any; }) => {
          return {
            label: item.name,
          }
        },
        defaultItem: () => ({
          name: 'New Author',
          id: Date.now(),
        }),
        fields: [
          {
            label: 'Name',
            name: 'name',
            component: 'text',
          },
          {
            label: 'Link',
            name: 'link',
            component: 'text',
          },
        ],
      },],
  },
};
