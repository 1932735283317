import React, { useState, useEffect } from 'react'

import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from "yup";

import { ReactComponent as BackArrow } from '../assets/images/back-arrow.svg';
import { ReactComponent as CloseIcon } from '../assets/images/Popup_close.svg';
import { SecNav } from '../components/admin/SecNav';
import { SelectDropdown } from '../components/admin/SelectDropdown';
import { TextInput } from '../components/forms/TextInput';
import Loader from '../components/Loader/loader'
import { roleList } from '../data/permission_management/managerList';
import apiFunctions from '../services/api-service'
import { AdminLayout } from './AdminPanel/AdminLayout';
import { authManageSettingsSecNav, authPriNavMenu } from './AdminPanel/AdminOptions';
import { PermissionMgmtMenuList } from './AdminPanel/super_admin/settings/PermissionMgmtMenuList';

interface AddTeamMemberProps {

}

export const AddTeamMember: React.FC<AddTeamMemberProps> = ({ }) => {
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const [regionLocation, setRegionLocation] = useState([]);
  const [role] = useState(localStorage.getItem('role'));
  const [teamMemberState, setTeamMember] = useState<any>({
    // location: '',
    // role: '',
    isSuccess: false,
    isFailed: false,
    message: '',
    roleList: []
  });

  // Manager invite based role
  let roleView:any;
  useEffect(()=>{
    if(role === '2'){
      const roleData = roleList && roleList.filter((ele: any) => ele.value !== 'admin');
      setTeamMember({
        ...teamMemberState,
        roleList: roleData
      });
    }
    else{
      setTeamMember({
        ...teamMemberState,
        roleList
      });
    }
  },[roleList]) 

  useEffect(() => {
    getRegion();
  }, []);
  // Get Region
  const getRegion = async () => {
    const url = process.env.REACT_APP_SERVICE_BACKEND_URL + '/postcode/region'
    const response = await apiFunctions.get(url);
    // Set Region options
    const selectOptions = response.data.data.map((res: string) => {
      return {
        label: res,
        value: res
      }
    });
    setRegionLocation(selectOptions);
    setLoader(false)
  }
  const handleGoBack = () => {
    history.goBack()
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address format")
      .required("Email is required"),
    confirmEmail: Yup.string()
      .required("Confirm email is required")
      .oneOf([Yup.ref('email'), null], 'Your email do not match.'),
    location: Yup.array()
      .min(1, "Select at least one location"),
    role: Yup.string()
      .required("Role is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      confirmEmail: '',
      location: [],
      role: ''
    },
    enableReinitialize: true,
    validationSchema: () => validationSchema,
    onSubmit: async (values: any) => {
      try {
        setLoader(true)
        if (values) {
          const url = process.env.REACT_APP_SERVICE_BACKEND_URL + '/admin/add-team-members'
          try {

            const response = await apiFunctions.post(url, values);
            if(response.data.code  === 409){
              setLoader(false)
              setTeamMember({
                ...teamMemberState,
                isSuccess: false,
                isFailed: true,
                message: response.data.message 
              });
            }
            else{
              setLoader(false)
              setTeamMember({
                ...teamMemberState,
                isSuccess: true,
                message: response.data.message
              })
            }
            
            formik.resetForm();
            window.scrollTo(0, 0);
            setLoader(false)
          } catch (err: any) {
            console.log(err.data,'--err');
            
            setLoader(false)
            setTeamMember({
              ...teamMemberState,
              isSuccess: false,
              isFailed: true,
              message: err.data.message ? err.data.message : 'Something went wrong'
            });
            window.scrollTo(0, 0);

          }

        }
      } catch (err: any) {
        setLoader(false)
        setTeamMember({
          ...teamMemberState,
          isSuccess: false,
          isFailed: true,
          message: err.data.message ? err.data.message : 'Something went wrong'
        });
        console.log('error :>>', err);
      }
    },
  });

  const handleCloseMessage = () => {
    setTeamMember({
      ...teamMemberState,
      isSuccess: false
    });
    handleGoBack();
  };

  return (
    <div className='add-member-blk'>
      <AdminLayout getMenuList={authPriNavMenu} roleMode="Super admin mode" sideBar="Settings">
        {loader && <Loader />}
        <SecNav getMenuList={authManageSettingsSecNav} />
        <div className='perm-mgmt-block'>
          <PermissionMgmtMenuList />
          <div className='perm-mgmt-content-blk'>
            <div className='d-flex'>
              <button className='mr-3' onClick={handleGoBack}>
                <BackArrow />
              </button>
              <h5 className='role-title mr-3 mb-0'>Add Team member</h5>
            </div><br />
            {/* Render success or error message appears */}
            {teamMemberState.isSuccess ?
              <div className='form-message-blk add-team-success'>
                <span className='msg'>{teamMemberState.message}</span>
                <span className='close-icon' onClick={() => setTeamMember({
                  ...teamMemberState,
                  isSuccess: false,
                })}>
                  <CloseIcon />
                </span>
              </div> : null}
            {teamMemberState.isFailed ?
              <div className='form-message-blk add-team-error'>
                <span className='msg'>{teamMemberState.message}</span>
                <span className='close-icon' onClick={() => setTeamMember({
                  ...teamMemberState,
                  isFailed: false,
                })}>
                  <CloseIcon />
                </span>
              </div> : null}
            {/* Add team member form */}
            <form onSubmit={formik.handleSubmit} className='team-member-form'>
              <div className='team-member-field-blk'>
                <label className='team-member-label'>Email</label>
                <div>
                  <TextInput
                    type="text"
                    name="email"
                    placeholder="Please enter the team member’s email"
                    values={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email ?
                    <p className='form-error-msg error-message'>
                      {formik.errors.email}
                    </p> : null}
                </div>
              </div>
              <div className='team-member-field-blk'>
                <label className='team-member-label'>Confirm email</label>
                <div>
                  <TextInput
                    type="text"
                    name="confirmEmail"
                    placeholder="Please re-enter the team member’s email for confirmation"
                    values={formik.values.confirmEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.confirmEmail && formik.errors.confirmEmail ?
                    <p className='form-error-msg error-message'>
                      {formik.errors.confirmEmail}
                    </p> : null}
                </div>
              </div>
              <div className='team-member-field-blk location-block'>
                <label className='team-member-label'>Location</label>
                <div>
                  <SelectDropdown
                    addclass='secondary-select'
                    type="text"
                    name="location"
                    placeholder="Select locations"
                    optionsArray={regionLocation}
                    onChange={(items: any) => {
                      const valueArr = items.map((e: any) => e.value);
                      formik.setFieldValue('location', valueArr);
                      // setTeamMember({
                      //   ...teamMemberState,
                      //   location: valueArr
                      // });
                    }}
                    value={formik.values.location}
                    hasMultiValue={true}
                    hasCustomOption={true}
                  // onBlur={formik.handleBlur}
                  />
                  {formik.errors.location && formik.touched.location ?
                    <p className='form-error-msg error-message'>
                      {formik.errors.location}
                    </p> : null}
                </div>
              </div>
              <div className='team-member-field-blk role-block'>
                <label className='team-member-label'>Role</label>
                <div>
                  <SelectDropdown
                    addclass='secondary-select'
                    type="text"
                    name="role"
                    placeholder="Select role"
                    optionsArray={teamMemberState.roleList}
                    onChange={(e: any) => {
                      formik.setFieldValue('role', e.value);
                      // setTeamMember({
                      //   ...teamMemberState,
                      //   role: e.value
                      // });
                    }}
                    value={formik.values.role}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.role && formik.touched.role ?
                    <p className='form-error-msg error-message'>
                      {formik.errors.role}
                    </p> : null}
                </div>
              </div>
              <div className='team-member-btn'>
                <button
                  type="submit"
                  className={`btn btn-primary login-btn ${(!(formik.isValid && formik.dirty)) ? 'btn-disable' : ''}`}
                  disabled={!(formik.isValid && formik.dirty)}>
                  Send invite link
                </button>
                <button
                  type="button"
                  onClick={handleCloseMessage}
                  className="btn btn-primary login-btn cancel-button">
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </AdminLayout>
    </div>
  );
}