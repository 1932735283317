import React, { useCallback, useContext } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../../assets/images/Back_Button.svg'
import Icon from '../../assets/images/Speaker_Icon.svg'
import { Button } from '../../components/button/Button'
import TextToSpeech from '../../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../../context/provider/ThemeContext'
import '../../styles/confirmemail.css'
import appUrls from '../../services/AppURLs';

interface PostSubmissionProps {
    children: any
}

export const PostSubmission: React.FC<PostSubmissionProps> = (
    Props: any
) => {
    const { theme, themeDispatch } = useContext(ThemeContext)

    const history = useHistory();

    const proceed = useCallback(() => {
        // Props.history.push('/finalScreen')
    }, [])
    function GoBackPage() {
        history.goBack()
        TextToSpeech.getInstance().playVoice(null, 'stop')
    }

    return (
        <div className='bg-color whatnext-style contactbrook-h'>
            <div className="text-to-speech brook-container pad-bot">
                <div className="h-div mt-4">
                    <h2 className={`page-header-txt ${theme.theme}-txt-global`}>ORDER A TEST KIT</h2>
                    <div className="speaker-i">
                        <button
                            className="no-background-border"
                            onClick={(e) => TextToSpeech.getInstance().playVoice('text-to-speech')}
                        >
                            <img src={Icon} alt="speaker" />
                        </button>
                    </div>
                </div>
                <div className={`b1-style  ${theme.theme} mb-4`}>
                    <p className='pt-0'> If you asked for email confirmation of your order, please keep an eye on your inbox. If you have not received your confirmation email within 24 hours, please check your junk or spam folder before getting in touch. </p>
                </div>
                <div>
                    <div className="ft-tab brook-container">
                        <button
                            className="float-left no-background-border"
                            onClick={e => GoBackPage()}
                        >
                            <img src={backIcon} alt="back" />
                        </button>
                        <Button
                            id='undr_16_sti'
                            isDisabled={false}
                            buttonClassName="btn btn-default btn-main"
                            buttonText={theme.age && theme.age >= 16 && theme.age <= 17 ?"Next" : "Finish"}
                            onClick={(e) => {
                                if (theme.age && theme.age <= 15) {
                                    Props.history.push('/contact-brook');
                                  } else if (theme.age && theme.age >= 16 && theme.age <= 17) {
                                    window.location.href = appUrls.outerDomainUrls.brookParent
                                  } else if (theme.age) {
                                    history.push('/whatnext');
                                  }
                               
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}