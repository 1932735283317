import React, { useCallback, useContext, useEffect, useState } from 'react'

import moment from 'moment'
import LoadingOverlay from 'react-loading-overlay'
import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import '../styles/selectgender.css'
// import xIcon from '../assets/images/X_popup.svg'
import { Accordion } from '../components/accordian/Accordian'
import { Button } from '../components/button/Button'
import ErrorPopup from '../components/ModalPopup/ErrorPopup'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import apiFunctions from '../services/api-service'

interface SelectGenderProps {
  Props: any
}

export const SelectGender: React.FC<SelectGenderProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)

  const [gender, setGender] = useState('')

  const [describe, setDescribe] = useState('')

  const history = useHistory()

  const [selfDescribe, showSelfDescribe] = useState(false)

  const [overlay, setOverlay] = useState<boolean>(false)
  const [showInfo, setShowInfo] = useState(false)
  const [showErrorMsg, setshowErrorMsg] = useState('')

  useEffect(() => {
    if (theme.quesObj && theme.quesObj['What best describes your gender']) {
      setGender(theme.quesObj['What best describes your gender'])
    }
    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: '95' },
    })
  }, [])

  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('select_gender_id')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  const proceed = useCallback(async () => {
    TextToSpeech.getInstance().playVoice(null, 'stop')
    setisAccessibilityVoiceOver(false)
    setOverlay(true)
    let FinalAns = { ...theme.quesObj, 'What best describes your gender': gender }
    themeDispatch({
      type: 'SET_QUES',
      payload: {
        quesObj: FinalAns,
      },
    })
    const userAge: any = theme.age
    let userData = {
      first_name: theme.first_name,
      last_name: theme.last_name,
      date_of_birth: moment(theme.date_of_birth).format('YYYY-MM-DD'),
      email: theme.email,
      age: theme.age,
      phone_no: theme.phone_no,
      alternate_phone_no: theme.phone_no,
      address_line_1: theme.address_line_1,
      address_line_2: theme.address_line_2,
      address_line_3: theme.address_line_3,
      town: theme.town,
      postcode: theme.postcode,
      email_notification: false,
      isEmailConfirm: theme.isEmailConfirm,
      isemailContact: theme.quesObj.isemailContact,
      isPhoneContact: theme.quesObj.isPhoneContact,
      sti_type: theme.sti_type ? theme.sti_type : 'chlamydia',
    }

    let myObj: any = {}
    await apiFunctions
      .get(process.env.REACT_APP_SERVICE_BACKEND_URL + '/question/list')
      .then(async (result) => {
        result.data.data.map((e: { question_text: string | number; id: string }) => {
          if (e.question_text && e.id) {
            myObj[String(e.question_text)] = e.id
          }
        })
        await apiFunctions
          .post(process.env.REACT_APP_SERVICE_BACKEND_URL + '/user/add', userData)
          .then(async (_result) => {
            let myarr: any = []
            theme.quesObj = { ...theme.quesObj, 'What best describes your gender': gender }
            for (let i in theme.quesObj) {
              if (myObj[i] && theme.quesObj[i] && theme.quesObj[i] !== '') {
                let _obj = {
                  question_id: myObj[i],
                  user_id: _result.data.data.id,
                  answer: theme.quesObj[i],
                }
                myarr.push(_obj)
              }
            }

            let __data = {
              response: myarr,
            }
            await apiFunctions
              .post(process.env.REACT_APP_SERVICE_BACKEND_URL + '/response/user-answer', __data)
              .then((_result) => {
                if (theme && theme.age && theme.age > 17) {
                  Props.history.push('/thankyou')
                } else {
                  if (theme && theme.age && theme.age < 18 && theme.age > 15) {
                    if (
                      theme.quesObj &&
                      theme.quesObj['Sex Consent'].includes(
                        'None of the above apply to me, but i would like to talk to someone'
                      )
                    ) {
                      Props.history.push('/finalScreen')
                      themeDispatch({ type: 'SET_THEME_INTIAL_VALUE' })
                    } else {
                      Props.history.push('/thankyou')
                    }
                  } else {
                    Props.history.push('/thankyou')
                  }
                }
              })
              .catch((err) => {
                setOverlay(false)
                setshowErrorMsg(err && err.response && err.response.data && err.response.data.message)
              })
          })
          .catch((err) => {
            setOverlay(false)
            setshowErrorMsg(err && err.response && err.response.data && err.response.data.message)
          })
      })
      .catch((err) => {
        setOverlay(false)
        setshowErrorMsg(err && err.response && err.response.data && err.response.data.message)
      })

    // Props.history.push('/user-consent')
  }, [gender])

  function GoBackPage() {
    CloseModel()
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }
  function CloseModel() {
    setshowErrorMsg('')
  }
  const tabIndex = 0
  return (
    <LoadingOverlay active={overlay} spinner text="">
      <div className={`bg-color selectgender-style ${theme.theme}-skin-color`}>
        <div className="text-to-speech brook-container text-sp">
          <div className="pad-te">
            <div className="h-div mt-4">
              <h2 className={`page-header-txt ${theme.theme}-txt-global`}>ORDER A TEST KIT</h2>

              <div className="speaker-i">
                <button
                  className="no-background-border"
                  onClick={(e) => {
                    TextToSpeech.getInstance().playVoice('text-to-speech')
                    setisAccessibilityVoiceOver(true)
                  }}
                >
                  <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
                </button>
              </div>
            </div>

            <div className={`b1-style ${theme.theme} mt-4 mb-4 ${theme.theme}-txt-global`}>
              What best describes your gender?
            </div>
          </div>
          <Accordion
            themeName={theme.theme}
            panels={[
              {
                id: 'select_gender_id',
                label: 'WHY ARE YOU ASKING ME THIS?',
                content: child(theme),
              },
            ]}
          />
          <div className="pad-te">
            <div className="btn-style">
              <Button
                buttonClassName={`btn btn-default btn-cla btn-color btn-bgcolor ${theme.theme}-btn-global`}
                buttonText="Woman or girl"
                selected={gender.split(' ').join('-')}
                isDisabled={false}
                onClick={(e) => {
                  showSelfDescribe(false)
                  setGender('Woman or girl')
                }}
              />
              <Button
                buttonClassName={`btn btn-default btn-cla btn-pos-only btn-le btn-color btn-bgcolor ${theme.theme}-btn-global`}
                buttonText="Man or boy"
                selected={gender.split(' ').join('-')}
                isDisabled={false}
                onClick={(e) => {
                  showSelfDescribe(false)
                  setGender('Man or boy')
                }}
              />
            </div>
            <div className="next-btn-style  btn-ri btn-style">
              <Button
                buttonClassName={`btn btn-default btn-cla btn-color btn-bgcolor ${theme.theme}-btn-global`}
                buttonText="Non-binary"
                selected={gender.split(' ').join('-')}
                isDisabled={false}
                onClick={(e) => {
                  showSelfDescribe(false)
                  setGender('Non-binary')
                }}
              />
              <Button
                buttonClassName={`btn btn-default btn-cla btn-posi btn-color btn-bgcolor ${theme.theme}-btn-global`}
                buttonText="Prefer to self-describe"
                selected={gender.split(' ').join('-')}
                isDisabled={false}
                onClick={(e) => {
                  showSelfDescribe(true)
                  setGender('Prefer to self-describe')
                }}
              />
              <Button
                buttonClassName={`btn btn-default btn-cla btn-pos btn-color btn-prefer btn-bgcolor ${theme.theme}-btn-global`}
                buttonText="Prefer not to say"
                selected={gender.split(' ').join('-')}
                isDisabled={false}
                onClick={(e) => {
                  showSelfDescribe(false)
                  setGender('Prefer not to say')
                }}
              />
            </div>
            {selfDescribe && (
              <>
                <div className={`b1-style f-b1 ${theme.theme} mb-4`}>Prefer to self-describe</div>
                <input
                  type="text"
                  className="form-control input-style"
                  aria-label="emailHelp"
                  placeholder="Enter your gender"
                  value={describe}
                  onChange={(e) => {
                    setDescribe(e.target.value)
                  }}
                />
              </>
            )}
            <div>
              <div>
                <div className={`ft-tab brook-container ${theme.theme}-skin-color`}>
                  <button className="float-left no-background-border" onClick={(e) => GoBackPage()}>
                    <img src={backIcon} alt="back" />
                  </button>
                  <Button
                    id={`${theme && theme.age && theme.age < 18 ? 'below_18_success' : 'above_18_success'}`}
                    isDisabled={Boolean(gender === 'Prefer to self-describe' ? describe.trim() === '' : gender === '')}
                    buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                    buttonText="Submit"
                    onClick={proceed}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {showErrorMsg !== '' && (
          <div className="popup-info-section">
            <div
              className="info-popup internal-error-popup"
              onClick={(e) => {
                CloseModel()
              }}
            >
              <img src={xIcon} className="x-icon" alt="back" />
              <div className="p4-style-title">
                <div className={`p5-style fo-style ${theme.theme}-txt-global`}>{showErrorMsg}</div>
              </div>
            </div>
          </div>
        )} */}
        <ErrorPopup
          showErrorMsg={showErrorMsg}
          setshowErrorMsg={setshowErrorMsg}
          headerClass={`p5-style fo-style ${theme.theme}-txt-global`}
        />
      </div>
    </LoadingOverlay>
  )
}

const child = (theme: any) => {
  const tabIndex = 0
  return (
    <>
      <div className={`p3-style p2-style ${theme.theme}-txt-global`}>
        The information you provide in response to this question helps us understand how our services are being used so
        we can make them more accessible for everyone and identify where we need to make improvements. We may also use
        this information to create a client record if you go on to receive further support from Brook (for example, if
        we provide you with treatment), and for reporting purposes. All the information you gives us is confidential.
        Brook follows the national mandatory standard for the collection and analysis of ethnicity, using the 16+1
        ethnic data categories defined in the 2001 census.{' '}
      </div>
    </>
  )
}
