import React, { useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import '../styles/hepatitis.css'


interface HepatitisProps {
  Props: any
}

export const Hepatitis: React.FC<HepatitisProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)
  const history = useHistory()
  

  const handleOnclick = () => {
    history.push('/test-kit')
  }

  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }


  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker
  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  return (
    <div className={`bg-color hepatitis-style ${theme.theme}-global`}>
      <div className="brook-container pad-bot">
        <div className="text-to-speech">
          <div className="h-div mt-4">
            <h2 className={`${theme.theme}-txt-global page-header-txt`}>TESTING FOR HEPATITIS</h2>
            <div className="speaker-i">
              <button
                className="no-background-border"
                onClick={(e) => {
                  TextToSpeech.getInstance().playVoice('text-to-speech')
                  setisAccessibilityVoiceOver(true)
                }}
              >
                <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
              </button>
            </div>
          </div>
          <div className="hepatitis-content-blk">
            <h1 className={`b1-style ${theme.theme}-txt-global`}>You don't need a test for hepatitis if you don't have any risk factors.</h1>
            <h4 className={`b4-style ${theme.theme}-txt-global hepatitis-subtitle mb-3`}>
              However, there are some situations where getting a hepatitis test and vaccination might be<br/>
               a good idea.
            </h4>
            <h2 className={`b1-style ${theme.theme}-txt-global hepatitis-margin`}>Get tested and vaccinated if:</h2>
            
            <h4 className={`b4-style ${theme.theme}-txt-global hepatitis-subtitle mb-3`}>
              <ol className='ml-8'>
                <li>
                  1. You have multiple sexual partners or switch partners often
                </li>
                <li>
                  2. You are a man who has sex with other men (MSM) without protection (condoms)
                </li>
                <li>
                  3. You have ever had risky sex (unprotected anal sex, sex while intoxicated)
                </li>
                <li>
                  4. You inject drugs or have a partner who does
                </li>
                <li>
                  5. You work in the sex industry or pay for sex
                </li>
                <li>
                  6. You have had sex with someone from an area with high hepatitis rates
                </li>
                <li>
                  7. You have experienced sexual assault (it's ok if you haven't reported it - a service wont'make 
                  &nbsp;you but can offfer you help and support if you want)
                </li>
              </ol>
            </h4>

            <h4 className={`b4-style ${theme.theme}-txt-global hepatitis-subtitle mb-3`}>
              If any of these sound familiar, talk to a sexual health clinic about testing and getting the
              Hepatitis B vaccine (which can prevent future infection).
            </h4>
           
            <div className={`ft-tab brook-container ${theme.theme}-skin-color`}>
              <button className="float-left no-background-border pos-re" onClick={(e) => GoBackPage()}>
                <img src={backIcon} alt="back" />
              </button>
              <Button
                isDisabled={false}
                buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                buttonText="Next"
                onClick={handleOnclick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}