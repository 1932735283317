
const appUrlsJson = {
  ourAppUrls: {
    signPosting: {
      home: '/',
      havingSex: '/having-sex',
      help: '/help',
      unprotectedSex: '/unprotected-sex',
      sti: '/sti',
      contraception: '/contraception',
      emergencyContraception: '/emergency-contraception',
      mythBusting: '/myth-busting',
    },
    sti: {
      home: '/hometest',
      // cmsLogin: '/cms-login',
      preinfo: '/preinfo',
      genitals: '/genitals',
      postage: '/postage',
      postcode: '/postcode',
    },
    switchDesktop: '/switch-desktop',
    admin: {
      authOld: {
        login: '/user/login',
        signup: '/user/signup',
      },
      auth: {
        login: '/staffhub/login',
        signup: '/staffhub/signup',
        resetPassword: '/staffhub/reset-password',
        forgotPassword: '/staffhub/forgot-password',
        azurelogin: '/staffhub/azurelogin'
      },
      settings: {
        accounts: '/staffhub/settings/accounts',
        password: '/staffhub/settings/password',
        editProfile: '/staffhub/settings/edit-profile',
        archiveAccount: '/staffhub/settings/archive-account',
        manageRoles: '/staffhub/settings/manage-roles/overview',
        managePermission: '/staffhub/settings/manage-roles/set-default-permissions',
        managerPermission: '/staffhub/settings/manage-roles/manager-permissions',
        userPermission: '/staffhub/settings/manage-roles/user-permissions',
        archivePermission: '/staffhub/settings/manage-roles/archive-permissions',
        addTeamMember: '/staffhub/settings/manage-roles/add-member',
        editManager: '/staffhub/settings/manage-roles/manager/edit',
        editUser: '/staffhub/settings/manage-roles/user/edit',
      },
      users: {
        users: '/staffhub/admins/users',
        manageRequest: '/staffhub/manage-request',
        feedback: '/staffhub/feedback',
        message: '/staffhub/message',
        inbox: '/staffhub/inbox',
        sent: '/staffhub/sent',
        bin: '/staffhub/bin',
        problem: '/staffhub/report-problem',
        notifications: '/staffhub/notifications'
      },
      orderKit: '/staffhub/order-kit',
      syncUser: '/staffhub/sync-user',
      dashboard: '/staffhub/dashboard',
      external: '/external'
    }
  },
  outerDomainUrls: {
    brookParent: 'https://www.brook.org.uk/',
  },
  MAIA:{
    validate_login_url:'validate-login'
  }
}

function checkInnerJson(jsonData: any) {
  if (jsonData) {
    for (const key in jsonData) {
      if (typeof jsonData[key] === 'string') {
        jsonData[key] = `${jsonData[key]}`;
      } else {
        jsonData[key] = checkInnerJson(jsonData[key]);
      }
    }
  }
  return jsonData as typeof appUrlsJson.ourAppUrls;
}


const appUrls = {
  ...checkInnerJson(appUrlsJson.ourAppUrls),
  outerDomainUrls: appUrlsJson.outerDomainUrls,
  MAIA:appUrlsJson.MAIA
}

export default appUrls
