import React, { useCallback, useContext, useEffect, useState } from 'react'

import moment from 'moment'
import LoadingOverlay from 'react-loading-overlay'
import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
// import xIcon from '../assets/images/X_popup.svg'
import { Button } from '../components/button/Button'
import ErrorPopup from '../components/ModalPopup/ErrorPopup'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import '../styles/userdetailsconsent.css'
import apiFunctions from '../services/api-service'

interface UserDetailsConsentProps {
  children: any
}

export const UserDetailsConsent: React.FC<UserDetailsConsentProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)

  const [notification, setNotification] = useState<boolean>(false)

  const [overlay, setOverlay] = useState<boolean>(false)
  const [showInfo, setShowInfo] = useState(false)
  const [showErrorMsg, setshowErrorMsg] = useState('')

  useEffect(() => {
    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: '90' },
    })
  }, [])

  const history = useHistory()

  const proceed = useCallback(async () => {
    TextToSpeech.getInstance().playVoice(null, 'stop')
    setOverlay(true)

    let userData = {
      first_name: theme.first_name,
      last_name: theme.last_name,
      date_of_birth: moment(theme.date_of_birth).format('YYYY-MM-DD'),
      email: theme.email,
      age: theme.age,
      phone_no: theme.phone_no,
      alternate_phone_no: theme.phone_no,
      address_line_1: theme.address_line_1,
      address_line_2: theme.address_line_2,
      address_line_3: theme.address_line_3,
      town: theme.town,
      postcode: theme.postcode,
      email_notification: notification,
      isEmailConfirm: theme.isEmailConfirm,
      isemailContact: theme.quesObj.isemailContact,
      isPhoneContact: theme.quesObj.isPhoneContact,
      sti_type: theme.sti_type ? theme.sti_type : 'chlamydia',
    }

    let myObj: any = {}
    await apiFunctions
      .get(process.env.REACT_APP_SERVICE_BACKEND_URL + '/question/list')
      .then(async (result) => {
        result.data.data.map((e: { question_text: string | number; id: string }) => {
          if (e.question_text && e.id) {
            myObj[String(e.question_text)] = e.id
          }
        })
        await apiFunctions
          .post(process.env.REACT_APP_SERVICE_BACKEND_URL + '/user/add', userData)
          .then(async (_result) => {
            let myarr: any = []
            for (let i in theme.quesObj) {
              if (myObj[i] && theme.quesObj[i] && theme.quesObj[i] !== '') {
                let _obj = {
                  question_id: myObj[i],
                  user_id: _result.data.data.id,
                  answer: theme.quesObj[i],
                }
                myarr.push(_obj)
              }
            }

            let __data = {
              response: myarr,
            }

            await apiFunctions
              .post(process.env.REACT_APP_SERVICE_BACKEND_URL + '/response/user-answer', __data)
              .then((_result) => {
                Props.history.push('/finalScreen')
              })
              .catch((err) => {
                setOverlay(false)
                setshowErrorMsg(err.response.data.message)
              })
          })
          .catch((err) => {
            setOverlay(false)
            setshowErrorMsg(err.response.data.message)
          })
      })
      .catch((err) => {
        setOverlay(false)
        setshowErrorMsg(err.response.data.message)
      })
    // Props.history.push('/thankyou')
  }, [])

  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }
  function CloseModel() {
    setshowErrorMsg('')
  }
  return (
    <LoadingOverlay active={overlay} spinner text="">
      <div className={`bg-color successpostcode-style ${theme.theme}-skin-color`}>
        <div className="brook-container pad-bot">
          <div className="text-to-speech">
            <div className="h-div mt-4">
              <h2 className={`page-header-txt ${theme.theme}-txt-global`}>ORDER A TEST KIT</h2>
              <div className="speaker-i">
                <button
                  className="no-background-border"
                  onClick={(e) => TextToSpeech.getInstance().playVoice('text-to-speech')}
                >
                  <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
                </button>
              </div>
            </div>

            <div className={`b1-style ${theme.theme}-txt-global`}>
              Would you like an email confirming Brook has received your test request?
            </div>
            {/* <div className="form-check mt-5 mb-3">
              <div className="single-row mt-4">
                <div>
                  <input
                    type="checkbox"
                    id="vehicle1"
                    className="form-check-input check-style"
                    onChange={e => { e.target.checked ? setNotification(true) : setNotification(false) }}
                  />
                </div>
                <div className={`pl-4 mt-2 p2-style ${theme.theme}-txt-global`}>
                  I would like to receive email confirmation that Brook has received
                  my test request.{' '}
                </div>
              </div>
            </div>
            <div className={`mar-pos p2-style mb-3 ${theme.theme}-txt-global`}>
              This is optional - If you'd rather not receive an email confirmation,
              please leave the box empty<br></br>
            </div> */}
            <div className="mar-pos p2-style mb-3">
              By submitting the form, I understand that my information will be shared with Brook and stored in line with
              Brook’s
              <a
                target="_blank"
                className={`text-sty ml-1 cursor-pointer ${theme.theme}-txt-global`}
                href="https://www.brook.org.uk/privacy-and-confidentiality/"
              >
                {' '}
                privacy statement.
              </a>
              <br />
            </div>
            <div className={`ft-tab brook-container ${theme.theme}-skin-color`}>
              <button className="float-left no-background-border" onClick={(e) => GoBackPage()}>
                <img src={backIcon} alt="back" />
              </button>
              <Button
                isDisabled={false}
                buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                buttonText="Submit"
                onClick={proceed}
              />
            </div>
          </div>
        </div>
        {/* {showErrorMsg !== '' && (
          <div
            className="info-popup internal-error-popup"
            onClick={(e) => {
              CloseModel()
            }}
          >
            <img src={xIcon} className="x-icon" alt="back" />
            <div className="p4-style-title">
              <div className={`p5-style fo-style ${theme.theme}-txt-global`}>{showErrorMsg}</div>
            </div>
          </div>
        )} */}
        <ErrorPopup
          showErrorMsg={showErrorMsg}
          setshowErrorMsg={setshowErrorMsg}
          headerClass={`p5-style fo-style ${theme.theme}-txt-global`}
        />
      </div>
    </LoadingOverlay>
  )
}
