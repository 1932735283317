import React, { createContext, useContext, useEffect, useReducer } from 'react';

import { getFlags } from '../../components/admin/enums';
import { isTokenValid } from '../../services/api-service';
import { getUserInfo } from '../../services/api/AuthAPI';
import {
  AuthAction,
  AuthState,
  authReducer,
  initialState,
} from '../reducers/AuthReducer';


type AuthContextProps = {
  state: AuthState;
  authDispatch: React.Dispatch<AuthAction>;
};
const AuthContext = createContext<AuthContextProps>({
  state: initialState,
  authDispatch: () => initialState,
});

export function AuthContextProvider(props: React.PropsWithChildren<{}>) {
  const [state, authDispatch] = useReducer(authReducer, initialState);

  async function checkAuth() {
    const token = await localStorage.getItem('access-token');

    if (!token) return;

    if (isTokenValid(token)) {
      try {
        const userInfo = await getUserInfo();
        if (userInfo) {
          authDispatch({ type: 'LOGIN' });
          const responseModified = userInfo.data.data;
          authDispatch({ type: 'LOAD_USER', user: responseModified });
        } else {
          authDispatch({ type: 'LOGOUT' });
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  }

  async function getAppSettings() {
    try {
      const response = await getFlags();
      if (response) {
        authDispatch({
          type: 'SET_GLOBAL_SETTINGS',
          globalSettings: {
            flags: response.data.data,
          },
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  useEffect(() => {
    checkAuth();
    getAppSettings();
  }, []);

  return <AuthContext.Provider value={{ state, authDispatch }} {...props} />;
}

export default function useAuthContext() {
  return useContext(AuthContext);
}