import React, { useCallback, useEffect, useState } from 'react'

import useAuthContext from '../../context/provider/AuthContext';
import apiFunctions from '../../services/api-service';
import Loader from '../Loader/loader';
import '../styles/ApproveKit.css';
import Popup from './popup';

interface ApproveKitProps {
    status: string | undefined
    obj?: any;
    ques?: any;
    type?: any;
    permissions?: any;
    rolePermissions?: any;
    addMemebrPresmission?:any;
}

export const ApproveKit: React.FC<ApproveKitProps> = ({ status, obj, ques, type, permissions, rolePermissions,addMemebrPresmission }) => {

    const [showLoader, SetShowLoader] = useState(false)
    const [flagData, setflagdata] = useState(true);
    const [role] = useState(localStorage.getItem('role'));
    const consentOpts = [
        'I have been made to feel scared or uncomfortable by the person/s I have been having sexual contact with',
        'I have been given gifts, money, drugs, alcohol or protection for sex',
        'I often drink alcohol or take drugs before having sex',
        'I feel down or suffer from feelings of depression',
        'My partner is a lot older than me',
        'None of the above apply to me, but i would like to talk to someone'
    ]
    const [approvedKit, showapprovedKit] = useState(false);
    const [rejectedKit, showrejectedKit] = useState(false);
    const [approveKit, showapprovekit] = useState(false);
    const [rejectKit, showrejectkit] = useState(false);
    const [rejectstatus, setrejectstatus] = useState(false);
    const roletype = String(localStorage.getItem('role'));

    const {state: { user },} = useAuthContext()
    const permissionData:any = user;
    let permissionAccess:any = permissionData && permissionData.user ? permissionData.user:permissionData;

    permissionAccess = permissionAccess && permissionAccess.permissions?.orders_and_results.filter((ele:any)=>{return ele.name === 'approve/reject'});

    const changeStatus = useCallback((__param: any, __pattern: any) => {
        const __type = (() => {
            if (__param.action === 'signup') return 'signup_approval';
            if (__param.action === 'account_archive') return 'archive';
            if (__param.action === 'change_role') return 'role_change';


        })()
        if (__pattern === 'approve') {
            SetShowLoader(true);
            apiFunctions.put(process.env.REACT_APP_SERVICE_BACKEND_URL + '/admin/approveSignUp', { id: __param.user_info.id, type: __type, email: __param.user_info.email, notification_id: __param.id }).then(() => {
                window.location.reload();
            }).catch(err => {
                console.log(err);
            })
        } else {
            SetShowLoader(true);
            apiFunctions.put(process.env.REACT_APP_SERVICE_BACKEND_URL + '/admin/rejectSignUp', { id: __param.user_info.id, type: __type, email: __param.user_info.email, notification_id: __param.id }).then(() => {
                window.location.reload();
            }).catch(err => {
                console.log(err);
            })
        }
    }, [])

    useEffect(() => {
        let reject = false, __approveKit = false, __rejectKit = false;

        if (obj && obj.flags && obj.flags.length > 0 && obj.form_source !== 'tdl') {
            reject = true;
        }
        if (obj.age < 16 && obj.form_source !== 'tdl') {
            reject = true;
        }

        setrejectstatus(reject);

        if (obj.history && obj.history.length > 0) {
            obj.history.map((val1: any) => {

                if (val1.action === 'approve') {
                    __approveKit = true;
                }
                if (val1.action === 'reject') {
                    __rejectKit = true;
                }

                return val1;

            })
        }

        showapprovedKit(__approveKit);
        showrejectedKit(__rejectKit);

        // if (permissions) {
        //     setflagdata((role === '3') ? permissions.u_manage_flags : (role === '2') ? permissions.admin_manage_flags : (role === '1') ? true : false)
        // }

    }, [status, obj, ques, type, permissions, flagData, role])

    function Capitalize(str: any) {
        str = str.split(" ");
        for (let i = 0; i < str.length; i++) {
            str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
        }
        const CapitalLetter = str.join(" ");
        return CapitalLetter;
    }

    return (
        <>
            {showLoader && <Loader />}
            {rejectKit && <Popup rejectKit={rejectKit} close={() => showrejectkit(false)} ApproveRejectUser={obj} userdata={obj} />}
            {approveKit && <Popup approveKit={approveKit} close={() => showapprovekit(false)} ApproveRejectUser={obj} userdata={obj} />}
            {
                type === 'orderKit' ? <>
                    {
                        rejectstatus ?
                            <div>
                                {approvedKit ? (<>
                                    <p className="fw-normal">Approved</p>
                                    <p className="fw-normal">{`${'by ' + (obj.history.length > 0 ? Capitalize(obj.history[0].administrator.name) : '')}`}</p></>)
                                    :
                                    (!approvedKit && !rejectedKit ?
                                        (<button className="approve-kit-btn" onClick={() => { showapprovekit(true) }} disabled={!(permissionAccess && permissionAccess[0].value) }>Approve</button>) : ''
                                    )
                                }
                                {rejectedKit ? (<>
                                    <p className="fw-normal">Rejected</p>
                                    <p className="fw-normal">{`${'by ' + (obj.history.length > 0 ? Capitalize(obj.history[0].administrator.name) : '')}`}</p>
                                </>
                                ) :
                                    ((!approvedKit && !rejectedKit ?
                                        (<button className="approve-kit-btn reject" onClick={() => { showrejectkit(true) }} disabled={!(permissionAccess && permissionAccess[0].value) }>Reject</button>) : '')
                                    )
                                }

                            </div> :
                            <div>
                                <p className="fw-normal">{obj.form_source === 'tdl' ? '-' : approvedKit ? 'Approved' : rejectedKit ? 'Rejected' : 'Approved (auto)'}</p>
                            </div>
                    }
                </> :
                    <>
                        {status === 'approved' &&
                            <div>
                                <p className="fw-normal">Approved</p>
                                <p className="table-lisa-text">Lisa smart</p>
                            </div>
                        }

                        {status === '-' &&
                            <div>
                                <p className="fw-normal">-</p>
                            </div>
                        }

                        {/* Approved (auto) */}
                        {status === 'approved(auto)' &&
                            <div>
                                <p className="fw-normal">Approved (auto)</p>
                            </div>
                        }

                        {/* Approve and Reject */}
                        {status === 'approve/reject' &&
                            <div>
                                <button className="approve-kit-btn" onClick={() => { changeStatus(obj, 'approve') }} disabled={!(permissionAccess && permissionAccess[0].value)}>Approve</button>
                                <button className="approve-kit-btn reject" onClick={() => { changeStatus(obj, 'reject') }} disabled={!(permissionAccess && permissionAccess[0].value)}>Reject</button>
                            </div>
                        }

                        {/* Rejected */}
                        {status === 'rejected' &&
                            <div>
                                <span className="fw-normal mr-3">Rejected</span>
                                <button className="approve-kit-btn amend">Amend</button>
                            </div>
                        }
                    </>
            }

        </>
    );
}