import React, { useCallback, useEffect, useState } from 'react';

import Loader from '../../components/Loader/loader';
import apiFunctions from '../../services/api-service';
import appUrls from '../../services/AppURLs';
import { AdminLayout } from './AdminLayout';
import '../../components/styles/Notifications.css';

interface NotificationsPageProps {

}

export const NotificationsPage: React.FC<NotificationsPageProps> = ({ }) => {

    const [showLoader, SetShowLoader] = useState(false)

    const [secNavMenu, setSecNavMenu] = useState([
        {
            label: 'Forms',
            link: `${appUrls.admin.orderKit}`,
            isActive: false,
        },
        {
            label: 'Admin',
            link: appUrls.admin.users.users,
            isActive: false,
        }
    ]);

    const [data, SetData] = useState([]);

    useEffect(() => {
        apiFunctions.get(process.env.REACT_APP_SERVICE_BACKEND_URL + '/auth/get-notifications').then(e => {
            SetData(e.data.data);
        }).catch((err) => {
            console.log('err.response = ', err);
        })
    }, []);

    const approveSignup = useCallback((__param, __type = 'signup_approval') => {
        SetShowLoader(true);
        apiFunctions.put(process.env.REACT_APP_SERVICE_BACKEND_URL + '/admin/approveSignUp', { id: __param.id, type: __type, email: __param.email }).then(e => {
            window.location.reload();
        }).catch(err => {
            console.log(err);
        })
    }, [])

    return (
        <AdminLayout getMenuList={secNavMenu} roleMode="Super admin mode" sideBar="Notifications">
            {showLoader && <Loader />}
            <h1 className="page-heading mt-0">Notifications</h1>
            <div className="row align-items-start noti-page">
                <div className="col">
                    {
                        data.map((e: any) => {
                            return <>
                                {
                                    (!e.accountApproved) ?
                                        <div className="noti-card" >
                                            <p className="noti-info">{`${e.name} has requested for account approval.`}</p>
                                            <p className="noti-action" onClick={evt => {
                                                approveSignup(e)
                                            }}>Approve</p>
                                        </div> :
                                        (e.archiveRequest === 'pending' && e.accountApproved === true) ?
                                            <div className="noti-card">
                                                <p className="noti-info">{`${e.name} has requested to archive account.`}</p>
                                                <p className="noti-action" onClick={evt => {
                                                    approveSignup(e)
                                                }}>Approve</p>
                                            </div>
                                            : (e.roleChangeRequest === 'pending' && (e.archiveRequest !== 'pending' || e.archiveRequest !== 'approved')) ?
                                                <div className="noti-card">
                                                    <p className="noti-info">{`${e.name} has requested for role change request.`}</p>
                                                    <p className="noti-action" onClick={evt => {
                                                        approveSignup(e, 'role_change')
                                                    }}>Approve</p>
                                                </div> : ''
                                }
                            </>
                        })
                    }
                </div>
                <div className="col" />
            </div>
        </AdminLayout>
    );
}