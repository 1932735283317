import React,{ChangeEvent} from 'react'
import '../styles/Adminpopup.css';

interface AdminCheckboxProps {
    name?: string;
    type?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
    ischecked?: any;
    value?:string;
    disabled?:boolean;
}

export const AdminCheckbox: React.FC<AdminCheckboxProps> = ({ 
    onChange,
    name,
    type,
    value,
    checked,
    disabled
    
}) => {
    return (
        <>
            <label className="container checkbox-base">
                <input  type={type}
                    name={name}
                    value={value}
                    onChange={onChange}
                    checked={checked}
                    disabled={disabled}
                    />
                <span className="checkmark" />
            </label>
        </>
    );
}