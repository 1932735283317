import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import config from '../config/config';
import { createExternalUser, getExternalUserToken } from '../services/api/AuthAPI'
import appUrls from '../services/AppURLs'

interface ExternalProps {
  children: any
}

export const External: React.FC<ExternalProps> = (Props: any) => {
  const searchParams = new URLSearchParams(window.location.search)
  const redirect = searchParams.get('redirect') || btoa('/')
  const region_id = searchParams.get('region_id')
  const history = useHistory()
  const [msg, setMSG] = useState('redirecting...')

  useEffect(() => {
    if(region_id && redirect) _redirectToExternal()
  }, [region_id, redirect])
  const _redirectToExternal = async () => {
    try {
      if(!region_id){
        setMSG('Region is missiong')
      }
          const response : any = await getExternalUserToken(region_id);
          if(response?.data?.token)
          {
             // 👇️ redirects to an external URL
             if(process.env.REACT_APP_ENABLE_DOWNTIME === 'true'){
               window.location.href=`${config.MAIA_APP_URL}/${appUrls.MAIA.validate_login_url}?token=${response.data.token}&redirect=${redirect}&access-user-key=${process.env.REACT_APP_ENABLE_DOWNTIME_KEY}`;
             } else {
               window.location.href=`${config.MAIA_APP_URL}/${appUrls.MAIA.validate_login_url}?token=${response.data.token}&redirect=${redirect}`;
             }
          
          }else{
            const response = await createExternalUser(region_id);
            if(response?.result?.email)
            {
              const response : any = await getExternalUserToken(region_id);
              if(response?.token){
                if(process.env.REACT_APP_ENABLE_DOWNTIME === 'true'){
                  window.location.href=`${config.MAIA_APP_URL}/${appUrls.MAIA.validate_login_url}?token=${response.token}&redirect=${redirect}&access-user-key=${process.env.REACT_APP_ENABLE_DOWNTIME_KEY}`;
                } else {
                  window.location.href=`${config.MAIA_APP_URL}/${appUrls.MAIA.validate_login_url}?token=${response.token}&redirect=${redirect}`;
                }
              }
            }else{
              setMSG('something went wrong!')
            }
          }
        
    } catch (ex) {
      console.log('ex', ex)
      setMSG('Authentication Failed!')
    }
  }
  return <div>{msg}</div>
}
