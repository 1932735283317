import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { PermissionManagementModal } from '../../../../components/admin/PermissionManagementModal';
import Popup from '../../../../components/admin/popup';
import { DropdownSubmodule } from '../../../../components/dropdownCascade/DropdownSubmodule';
import Loader from '../../../../components/Loader/loader'
import { CustomDataTable } from '../../../../components/table/CustomDataTable';
import useAuthContext from '../../../../context/provider/AuthContext'
import apiFunctions from '../../../../services/api-service'
import appUrls from '../../../../services/AppURLs';
import { PermissionMgmtMenuList } from './PermissionMgmtMenuList';

interface ManagerListProps {

}

export const ManagerList: React.FC<ManagerListProps> = ({ }) => {
  let url: any = process.env.REACT_APP_SERVICE_BACKEND_URL;

  const {
    state: { user },
  } = useAuthContext()

  const dataval: any = user;

  let permissionAccess = dataval?.user ? dataval.user : dataval;

  let permissionAccessArchive = dataval?.user ? dataval.user : dataval;

  permissionAccessArchive = permissionAccessArchive && permissionAccessArchive.permissions?.team_members.filter((ele: any) => { return ele.name === 'archive_team_member' });

  permissionAccess = permissionAccess && permissionAccess.permissions?.team_members.filter((ele: any) => { return ele.name === 'change_team_member_role' });


  const history = useHistory()
  const [existUser, serExistUser] = useState<any>({});
  const [loader, setLoader] = useState(true);
  const [managerListData, setManagerListData] = useState([]);
  const [selectedManagersId, setSelectedManagersId] = useState([]);
  const [showPoup, setShowpoup] = useState(false);
  const [staffdata, setStaffdata] = useState();
  const [dotMenu, setdotMenu] = useState<any>([
    {
      value: 'changeRole',
      label: 'Change account type',
      children: [
        {
          value: 'user',
          label: 'User'
        },
      ]
    },
    {
      value: 'archive',
      label: 'Archive'
    }
  ]);
  const [internalState, setInternalState] = useState<any>({
    actionType: '',
    // pageNumber: 1,
    // inc: 0,
    isModalVisible: false
  });
  const titleModal = `${internalState.actionType === 'archive' ?
    "Are you sure you want to archive selected team members ?" :
    internalState.actionType === 'user' ?
      "Are you sure you want to change roles of selected managers to users ?" :
      ""}`;

  useEffect(() => {
    getManagersList();
  }, []);



  const getManagersList = async () => {
    const URL = url + '/admin/get-all-active-managers'
    const response = await apiFunctions.get(URL);
    console.log(response.data.data);
    serExistUser(response.data.data.existUser)
    setManagerListData(response.data.data.result);
    setLoader(false)
  }


  const getactionType = (selectedValue: string) => {
    setInternalState({
      ...internalState,
      actionType: selectedValue,
      isModalVisible: true
    });
  };

  const renderVerticalDotMenu = () => {
    let dotMenuValue: any;
    if (permissionAccess && permissionAccess[0].value === true) {
      dotMenuValue = dotMenu.filter((item: any) => item.value !== 'archive');
    }
    if (permissionAccessArchive && permissionAccessArchive[0].value === true) {
      dotMenuValue = dotMenu.filter((item: any) => item.value !== 'changeRole');
    }
    if (permissionAccessArchive && permissionAccessArchive[0].value && permissionAccess && permissionAccess[0].value) {
      dotMenuValue = dotMenu;
    }
    return (
      <DropdownSubmodule
        menus={dotMenu}
        actionType={getactionType}
      />
    )
  };

  const columns: any = [
    {
      name: selectedManagersId.length > 0 ? renderVerticalDotMenu() : '',
      minWidth: '50px',
      maxWidth: '50px'
    },
    {
      name: 'Name',
      maxWidth: '400px',
      selector: (row: any) => (
        <div className='d-flex'>
          <span className='name-column' onClick={() => showStaffdetails(row)}>{row.name}</span>
        </div>
      ),
    },
    {
      name: 'Permissions',
      maxWidth: '300px',
      cell: (row: any) => (
        <div className='d-flex'>
          <span className='count-label'>{row.permissionsCount}</span> <br />
          <span className='edit-label' onClick={() => handleEdit(row)}>Edit</span>
        </div>
      )
    },
    {
      name: 'Email',
      maxWidth: '500px',
      minWidth: '400px',
      selector: (row: any) => row.email,
    }
  ];

  const handleEdit = (selectedItem: any) => {
    history.push(appUrls.admin.settings.editManager + `?user_id=${selectedItem.id}`);
  };

  const handlePagination = (page: number) => {
    setInternalState({
      ...internalState,
      pageNumber: page,
      inc: page * 5 - 5
    });
  };

  const onRowSelected = (selectedRow: any) => {
    setSelectedManagersId(selectedRow.map((obj: any) => obj.id))
  };

  const handleCloseModal = () => {
    setInternalState({
      ...internalState,
      isModalVisible: false
    });
  };
  // User Archive
  const managersArchive = async () => {
    setLoader(true)
    console.log('call selectedManagersId archive', selectedManagersId, internalState);
    const URL = url + '/admin/archive-unarchive-administrators'
    try {
      const response = await apiFunctions.put(URL, { type: 'archive', user_ids: selectedManagersId });
      setInternalState({
        ...internalState,
        isModalVisible: false
      });
      await getManagersList();
      setLoader(false)
    } catch (err) {
      setLoader(false)
      console.log(err);

    }
  };

  // User Change Role
  const userRoleChange = async () => {

    setLoader(true)
    console.log('call selectedManagersId rolechange', selectedManagersId, internalState);
    const URL = url + '/admin/change-administrators-role'
    try {
      const response = await apiFunctions.put(URL, { type: internalState.actionType, user_ids: selectedManagersId });
      setInternalState({
        ...internalState,
        isModalVisible: false
      });
      await getManagersList();
      setLoader(false)
    } catch (err) {
      console.log(err);
      setLoader(false)
    }
  };
  // AddTeamMemberPermission
  const getAddTeamMemberPermission = (P_Type: string) => {
    const permission = existUser && existUser.permissions && existUser.permissions.team_members ? existUser.permissions.team_members.filter((res: any) => { return res.name === P_Type }) : [];
    return permission.length && permission[0].value;
  }

  // AddTeamMemberPermission
  const getSelectRowPermission = () => {
    const permission = existUser && existUser.permissions && existUser.permissions.team_members ? existUser.permissions.team_members.filter((res: any) => { return (res.name === 'archive_team_member' && res.value === true) || (res.name === 'change_team_member_role' && res.value === true) }) : [];
    return permission.length ? true : false;
  }

  const showStaffdetails = (uservalue: any) => {
    setStaffdata(uservalue)
    setShowpoup(true)
  }
  const closeModal = () => {
    setShowpoup(false)
  }

  return (
    <div className='perm-mgmt-block'>
      {loader && <Loader />}
      <PermissionMgmtMenuList addMemebrPresmission={getAddTeamMemberPermission('create_team_member')} />
      <div className='perm-mgmt-content-blk manager-blk'>
        <h5 className='role-title'>Manager</h5>
        <CustomDataTable
          data={managerListData}
          columns={columns}
          rowsPerPage={5}
          paginationTotalRows={managerListData.length}
          onChangePagination={handlePagination}
          inc={internalState.inc}
          onRowSelected={onRowSelected}
          selectedRow={getSelectRowPermission}
        />
      </div>
      {/* render popup */}
      {internalState.isModalVisible ?
        <PermissionManagementModal
          title={titleModal}
          SelectType={internalState.actionType}
          handleCloseModal={handleCloseModal}
          handlePositiveButton={managersArchive}
          userRoleChange={userRoleChange}
        /> : null}
      <Popup showAdminProfile={showPoup} close={closeModal} userdata={staffdata} />
    </div>

  );
}