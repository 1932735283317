import React from 'react'

/**
 * Props for Button component
 */
interface Props {
  buttonText: string
  buttonClassName: string
  buttonType: 'submit' | 'reset' | 'button' | undefined
  onClick: (e: any) => void
  isDisabled: boolean
  selected: string
  id: string
  ariaDescribedby: string
}

/**
 * Button component
 * @returns button component with customizable props
 */
export class Button extends React.Component<Props, {}> {
  public static defaultProps: Partial<Props> = {
    buttonClassName: 'btn btn-default btn-d',
    buttonType: 'button',
    isDisabled: true,
    id: '',
  }

  public render(): JSX.Element {
    return (
      <button
        aria-describedby={this.props.ariaDescribedby}
        id={this.props.id}
        type={this.props.buttonType}
        className={`${this.props.buttonClassName} ${
          this.props.selected === this.props.buttonText.split(' ').join('-') ? 'selected' : ''
        }`}
        onClick={this.props.onClick}
        disabled={this.props.isDisabled}
      >
        {this.props.buttonText}
      </button>
    )
  }
}
