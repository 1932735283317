import React, { useEffect, useState } from 'react';

import moment from 'moment'

import { FilterInDashboard } from '../../../components/admin/quickdashboard/FilterInDashboard';
import { GraphComponent } from '../../../components/admin/quickdashboard/GraphComponent';
import { OverviewCard } from '../../../components/admin/quickdashboard/OverviewCard';
import { StisListCard } from '../../../components/admin/quickdashboard/StisListCard';
import Loader from '../../../components/Loader/loader';
import { resultList } from '../../../data/quickDashboardData';
import apiFunctions from '../../../services/api-service'
import { AdminLayout } from '../AdminLayout';
import { authPriNavMenu } from '../AdminOptions';

import '../../../styles/AdminStyle/quickDashboard.css';

interface QuickDashboardProps {

}
export const QuickDashboard: React.FC<QuickDashboardProps> = ({ }) => {
  const [showLoader, SetShowLoader] = useState(false);
  const [internalState, setInternalState] = useState<any>({
    title: 'Requests',
    graphSeries: [],
    graphCategory: [],
    selectedMenu: 'request',
    fromDate: moment(new Date()).subtract(6, 'days').format('DD MMMM YYYY'),
    toDate: moment(new Date()).format('DD MMMM YYYY'),
    selectDay: '6',
    selectSTI: 'all'
  });
  const [overviewState, setOverviwState] = useState<any>({
    positive: 0,
    flagged: 0
  })
  const [cardList, SetcardList] = useState([]);
  const [stiList, SetStiList] = useState([]);


  const tabIndex = 0;
  useEffect(() => {
    getDashboardDetails();
  }, [internalState.title, internalState.selectedMenu, internalState.fromDate, internalState.toDate, internalState.selectDay, internalState.selectSTI]);


  // GET dashboard details
  const getDashboardDetails = async () => {
    SetShowLoader(true)
    try {
      apiFunctions
        .get(process.env.REACT_APP_SERVICE_BACKEND_URL + `/admin/get-dashboard-details?fromDate=${moment(internalState.fromDate).format('YYYY-MM-DD')}&toDate=${moment(internalState.toDate).format('YYYY-MM-DD')}&type=${internalState.selectedMenu}&sti_type=${internalState.selectSTI}`,)
        .then((_result) => {
          const resultData = _result.data.data;
          let graphData = [];
          switch (internalState.selectedMenu) {
            case 'request':
              graphData.push({
                name: 'Requests',
                data: resultData.yValues,
                color: '#652F91'
              });
              break;
            case 'flagged':
              graphData.push({
                name: 'Flagged requests',
                data: resultData.yValues,
                color: '#FF6700'
              });
              break;
            case 'result':
              graphData.push({
                name: 'Received',
                data: resultData.yValues,
                color: '#E44498'
              });
              break;
            case 'positive':
              graphData.push({
                name: 'Positive',
                data: resultData.yValues,
                color: '#31BDBF'
              });
              break;
            default:
              break;
          };
          setInternalState({
            ...internalState,
            graphSeries: graphData,
            graphCategory: resultData.xValues
          });
          setOverviwState({
            ...overviewState,
            positive: resultData.positive_total,
            flagged: resultData.flagged_total
          });
          let cardinfo = resultList.map((card: any) => {
            if (card.value === 'request') {
              card.count = resultData.total_request;
            }
            else if (card.value === 'flagged') {
              card.count = resultData.flagged_count;
            }
            else if (card.value === 'result') {
              card.count = resultData.result_received;
            }
            else if (card.value === 'positive') {
              card.count = resultData.positive_count;
            }
            return card;
          });
          SetcardList(cardinfo);
          SetStiList(resultData.STI_Result)
          SetShowLoader(false)
        })
        .catch((err) => {
          SetShowLoader(false)

        })
    } catch (error: any) {
      SetShowLoader(false)

    }
  };
  // Change the Card select
  const handleClick = (type: string) => {
    switch (type) {
      case 'request':
        setInternalState({
          ...internalState,
          title: 'Requests',
          selectedMenu: type
        })
        break;
      case 'flagged':
        setInternalState({
          ...internalState,
          title: 'Flagged requests',
          selectedMenu: type
        })
        break;
      case 'result':
        setInternalState({
          ...internalState,
          title: 'Received',
          selectedMenu: type
        })
        break;
      case 'positive':
        setInternalState({
          ...internalState,
          title: 'Positive',
          selectedMenu: type
        })
        break;

    }
  };

  // Change Days and STIs
  const handleSelectedDaysAndStis = (selectedValue: any, dataType: string) => {
    if (dataType === 'selecte_day') {
      setInternalState({
        ...internalState,
        selectDay: selectedValue.value,
        fromDate: moment(new Date()).subtract(selectedValue.value, 'days').format('DD MMMM YYYY'),
        toDate: moment(new Date()).format('DD MMMM YYYY'),
      });
    }
    if (dataType === 'selecte_sti') {
      setInternalState({
        ...internalState,
        selectSTI: selectedValue.value,
      });
    }
  }

  // Change Dates prev and next
  const changeDates = (type: string) => {
    const fromdate = internalState.fromDate;
    const todate = internalState.toDate;
    if (type === 'prev') {
      let fromdate1 = moment(fromdate).subtract(1, 'day');
      // let todate1 = moment(todate).subtract(1,'day');
      setInternalState({
        ...internalState,
        fromDate: moment(fromdate1).subtract(internalState.selectDay, 'days').format('DD MMMM YYYY'),
        toDate: moment(fromdate1).format('DD MMMM YYYY'),
      });
    }
    if (type === 'next') {
      //  let fromdate2 = moment(fromdate).add(1,'day');
      let todate2 = moment(todate).add(1, 'day');
      if (moment(todate).format("YYYY-MM-DD") < moment(new Date()).format("YYYY-MM-DD")) {
        setInternalState({
          ...internalState,
          fromDate: moment(todate2).format('DD MMMM YYYY'),
          toDate: moment(todate2).add(internalState.selectDay, 'days').format('DD MMMM YYYY'),
        });
      }
    }
  }

  return (
    <div>
      {showLoader && <Loader />}
      <AdminLayout getMenuList={authPriNavMenu} roleMode="Super admin mode">
        <h2 className="day-text" tabIndex={tabIndex}>
          STI test kits and results overview
        </h2>
        <div className='h-30' />
        <OverviewCard overviewState={overviewState} date={moment(new Date()).format('MMMM DD, YYYY')} />
        <div className='h-30' />
        <div className='filter-blk' >
          <FilterInDashboard FilterData={internalState}
            handleSelectedDaysAndStis={handleSelectedDaysAndStis}
            stiList={stiList.filter((reps:any)=> reps.name !== null)}
            changeDates={changeDates}
            toDate={internalState.toDate}
          />
          <p className='graph-header-text'>Click on the tiles below to update the graph</p>
          <div className='result-blk'>
            <div className='result-menu-blk'>
              {cardList.map((item: any, idx: number) => {
                const menuBgColor = item.value === 'request' ? 'requests' :
                  item.value === 'flagged' ? 'flagged-result' :
                    item.value === 'result' ? 'received' :
                      item.value === 'positive' ? 'positive-result' : '';
                const title = item.value === 'result' || item.value === 'positive' ? 'Test results' : 'Test kit';
                return (
                  <div
                    className={`result-menu-items-blk ${menuBgColor} ${item.value === internalState.selectedMenu ? menuBgColor + '-shadow' : ''}`}
                    key={`result-menu-items-${idx}`}
                    onClick={() => handleClick(item.value)}>
                    <p className='result-menu-title'>{title}</p>
                    <h3 className='result-menu-list-label'>{item.name}</h3>
                    <div className='h-30' />
                    <span className='result-count'>{item.count}</span>
                  </div>
                )
              })}
            </div>
            <div className='chart-section'>
              <GraphComponent
                title={internalState.title}
                seriesData={internalState.graphSeries}
                categoryData={internalState.graphCategory}
                selectDay={internalState.selectDay}
              />
            </div>
          </div>
        </div>
        <div className='h-30' />

        <div className='stis-blk'>
          {stiList.filter((res:any)=>res.name !=null).map((item: any, idx: number) => {
            return (
              <React.Fragment key={`stis-menu-item-${idx}`}>
                <StisListCard data={item} />
              </React.Fragment>
            )
          })}
        </div>
      </AdminLayout>
    </div>
  );
};