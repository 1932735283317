
export const resultList: any = [
  {
    title: 'Test kit',
    name: 'Requests',
    count: '42',
    value: 'request'
  },
  {
    title: 'Test kit',
    name: 'Flagged requests',
    count: '32',
    value: 'flagged'
  },
  {
    title: 'Test kit',
    name: 'Received',
    count: '12',
    value: 'result'
  },
  {
    title: 'Test kit',
    name: 'Positive',
    count: '08',
    value: 'positive'
  }
];

export const stisList: any = [
  {
    name: 'Chlamydia',
    requestKit: '14',
    flaggedKit: '06',
    receivedResult: '12',
    positiveResult: '08'
  },
  {
    name: 'Gonorrhoea',
    requestKit: '14',
    flaggedKit: '06',
    receivedResult: '12',
    positiveResult: '08'
  },
  {
    name: 'Genital herpes',
    requestKit: '14',
    flaggedKit: '06',
    receivedResult: '12',
    positiveResult: '08'
  },
  {
    name: 'HIV',
    requestKit: '14',
    flaggedKit: '06',
    receivedResult: '12',
    positiveResult: '08'
  },
  {
    name: 'Syphilis',
    requestKit: '14',
    flaggedKit: '06',
    receivedResult: '12',
    positiveResult: '08'
  },
  {
    name: 'Public lice',
    requestKit: '14',
    flaggedKit: '06',
    receivedResult: '12',
    positiveResult: '08'
  }
];