import React, { useCallback, useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import xIcon from '../assets/images/X_popup.svg'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import '../styles/confirmemail.css'

interface ConfirmEmailProps {
  children: any
}

export const ConfirmEmail: React.FC<ConfirmEmailProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)

  const [consent, SetConsent] = useState(false)

  const [showInfo, setShowInfo] = useState(false)

  const history = useHistory()

  useEffect(() => {
    if (theme.quesObj && theme.quesObj['accept_consent']) {
      SetConsent(theme.quesObj['accept_consent'])
    }

    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: '70' },
    })
  }, [])

  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('vehicle1')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  const proceed = useCallback(() => {
    themeDispatch({
      type: 'SET_QUES',
      payload: {
        quesObj: {
          ...theme.quesObj,
          accept_consent: consent,
        },
      },
    })
    TextToSpeech.getInstance().playVoice(null, 'stop')
    setisAccessibilityVoiceOver(false)
    if (consent) {
      Props.history.push('/monitor-question')
    } else {
      // Props.history.push('/user-noteligible')
    }
  }, [consent])

  function GoBackPage() {
    setShowInfo(false)
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }
  return (
    <div className={`bg-color confirmemail-style ${theme.theme}-skin-color`}>
      <div className="brook-container pad-bot">
        <div className="text-to-speech">
          <div className="h-div">
            <h2 className={`page-header-txt ${theme.theme}-txt-global`}>ORDER A TEST KIT</h2>
            <div className="speaker-i">
              <button
                className="no-background-border"
                onClick={(e) => {
                  TextToSpeech.getInstance().playVoice('text-to-speech')
                  setisAccessibilityVoiceOver(true)
                }}
              >
                <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
              </button>
            </div>
          </div>
          <div className="text-to-speech">
            <div className={`b1-style ${theme.theme}-txt-global`}>
              Brook will share your information with The Doctors Laboratory, our partner for analysing your test.
            </div>
            <div className="single-row mt-4">
              <div>
                <input
                  type="checkbox"
                  id="vehicle1"
                  checked={consent}
                  className="form-check-input check-style"
                  onChange={(e) => {
                    SetConsent(e.target.checked)
                    if (e.target.checked) {
                      setShowInfo(false)
                    }
                  }}
                />
              </div>
              <div className={`pl-4 mt-2 d-size ${theme.theme}-txt-global`}>
                <label htmlFor="vehicle1">
                  I consent to Brook sending my details to The Doctors Laboratory (TDL) for the purposes of processing
                  my test request.
                </label>
              </div>
            </div>
            <div className={`mar-pos p2-style mb-3 ${theme.theme}-txt-global`}>
              If you do not consent to The Doctors Laboratory seeing your information, you will not be able to get your
              test result.
              <br />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className={`ft-tab brook-container ${theme.theme}-skin-color`}>
          <button className="float-left no-background-border" onClick={(e) => GoBackPage()}>
            <img src={backIcon} alt="back" />
          </button>
          <Button
            isDisabled={false}
            buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
            buttonText="Next"
            onClick={(e) => {
              if (!consent) {
                setShowInfo(true)
              } else {
                proceed()
              }
            }}
          />
        </div>
      </div>
      {/* {showInfo && (
        <div
          className="info-popup fo-title"
          onClick={(e) => {
            setShowInfo(false)
          }}
        >
          <img src={xIcon} className="x-icon" alt="back" />
          <div className="p4-style-title">
            <div className={`p5-style fo-style ${theme.theme}-txt-global`}>
              {' '}
              Brook cannot send you a test kit if you do not consent to sharing your details with The Doctors
              Laboratory.
            </div>
          </div>
        </div>
      )} */}

      {showInfo && <div className="modal d-block bg-color-popup kit-block" role="dialog">
        <div className="modal-dialog modal-dialog-centered reject-pop" role="document">
          <div className="modal-content model-bor p-6">
            <div
              className='kit-modal-header'
              onClick={(e) => setShowInfo(false)}>
              <img src={xIcon} className="kit-close-icon" alt="back" />
            </div>
            <div className="modal-body">
              <div className="kit-modal-content p5-style Regular-txt-global">
                Brook cannot send you a test kit if you do not consent to sharing your details with The Doctors
                Laboratory.
              </div>
            </div>
          </div>
        </div>
      </div>}
    </div>
  )
}
