import React, { useState, useEffect } from 'react';

import "../../../styles/common.css";

import { useFormik } from 'formik';
import moment from 'moment';
import Select from 'react-select';
import * as Yup from 'yup';

import Popup from '../../../components/admin/popup';
import { SecNav } from '../../../components/admin/SecNav';
import { TriNav } from '../../../components/admin/TriNav';
import apiFunctions from '../../../services/api-service'
import { AdminLayout } from '../AdminLayout';
import { authPriNavMenu, authManageSettingsSecNav, authManageAccountSettingTriNav } from '../AdminOptions';

const validationSchema = Yup.object({
    name: Yup.string().required('Name is required')
});
interface EditprofileSettingsProps {

}

export const EditprofileSettings: React.FC<EditprofileSettingsProps> = ({ }) => {

    const [showPopup, setshowPopup] = useState(false);
    const [selectDefaultValue, setselectDefaultValue] = useState('');
    const [selectDefault, setSelectDefault] = useState<any>('');
    const [UserData, setUserData] = useState({
        "user_id": "",
        "name": "",
        "email": "",
        "role": "",
        "password_last_update": "",
        "pronouncedAs": ""
    });
    const validName = new RegExp("^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z ]*)*$");

    useEffect(() => {
        apiFunctions.get(process.env.REACT_APP_SERVICE_BACKEND_URL + '/auth/get-user-data').then(_result => {
            setSelectDefault(_result.data.data.user.pronouncedAs)
            setUserData({
                "user_id": _result.data.data.user.id,
                "name": _result.data.data.user.name,
                "email": _result.data.data.user.email,
                "role": _result.data.data.user && _result.data.data.user.roleinfo && _result.data.data.user.roleinfo.roleName ? _result.data.data.user.roleinfo.roleName : '',
                "pronouncedAs": _result.data.data.pronouncedAs,
                "password_last_update": _result.data.data.user && _result.data.data.user.password_last_update ? moment(_result.data.data.user.password_last_update).format('D MMMM YYYY') : ''
            })
            setPersonalFormVal(_result.data.data.user);
        }).catch((err) => {
            console.log('err.response = ', err);
        })

    }, []);
    useEffect(() => {
    }, [selectDefault]);

    const formValues = {
        name: '',
        pronouncedAs: ''
    }
    
    async function setPersonalFormVal(profile: any) {
        formik.setFieldValue('name', profile.name, false)
        formik.setFieldValue('pronouncedAs', profile.pronouncedAs, false)
    }
    const formik = useFormik({
        initialValues: {
            name: '',
            pronouncedAs: ''
        },
        validationSchema,
        enableReinitialize: true,
        
        async onSubmit(values: any) {
            try {
                if(!validName.test(formik.values.name)){
                    return ''
                }
                let payload = {
                    user_id: UserData && UserData.user_id ? UserData.user_id : '',
                    name: values.name,
                    pronouncedAs: values.pronouncedAs ? values.pronouncedAs.value:''
                }
                apiFunctions.put(process.env.REACT_APP_SERVICE_BACKEND_URL + '/auth/update-profile', payload).then(_result => {
                    setshowPopup(true)
                }).catch((err) => {

                })


            } catch (error: any) {
                console.log(error.response, 'errorresposne')
            }
        },
    });
    const closeModal = () => {
        setshowPopup(false)
    }
    const [role] = useState(localStorage.getItem('role'));
    const tabIndex=0;
    return (
        <AdminLayout getMenuList={authPriNavMenu} roleMode="" sideBar="Settings">
            {
                role !== '3' && <><SecNav getMenuList={authManageSettingsSecNav} />
                    <h1 className="page-heading" tabIndex={tabIndex}>Change settings</h1>
                    <TriNav getMenuList={authManageAccountSettingTriNav} /></>
            }
            <div className="admin-login-style">
                {
                    role === '3' && <>
                        <div className='col-12 col-sm-10 col-md-6 mt-4'>
                            <div className='login-style1'tabIndex={tabIndex}>Your account</div>
                        </div>
                        <hr />
                        <div className='col-12 col-sm-10 col-md-6'>
                            <p className='login-style2'tabIndex={tabIndex}>Change settings</p>
                        </div>
                        <div className='mt-5 ml-5'>
                            <TriNav getMenuList={authManageAccountSettingTriNav} />
                        </div>
                    </>
                }
                <div className='text-account'>
                    <form className='col-12 col-sm-10 col-md-6' onSubmit={formik.handleSubmit}>
                        <div>
                            <div className="form-group parent-pos">
                                {/* value={UserData && UserData.name ? UserData.name : formik.values.name} */}
                                <label className='in-style' htmlFor='name' tabIndex={tabIndex}>Name you like to be called</label>
                                <input type="text" className="form-control  login-radius" 
                                    onChange={formik.handleChange} id="name" name="name" value={formik.values.name} onBlur={formik.handleBlur} />
                                <span className="errorMessage">
                                    {formik.touched.name && formik.errors.name
                                        ? formik.errors.name
                                        : null}
                                        {formik.values.name && !validName.test(formik.values.name)  ? "Only characters are allowed in the name" : null}
                                </span>
                            </div>
                            <div className="form-group">
                                <label className='in-style' tabIndex={tabIndex}>Role at Brook</label>
                                <input type="text" aria-label='Role at Brook' className="form-control  login-radius" value={UserData && UserData.role ? UserData.role : ""} disabled />
                            </div>
                            <div className="form-group">
                                <label className='in-style' tabIndex={tabIndex}>Pronouns (optional)</label>
                                <Select
                                    aria-label='pronounced as'
                                    id="pronouncedAs"
                                    name="pronouncedAs"
                                    classNamePrefix="select-dropdown"
                                    onChange={e => {
                                        formik.setFieldValue('pronouncedAs', e)
                                        if (e) {
                                            setSelectDefault(e.value);
                                        }
                                    }}
                                    options={[{ value: 'He/Him', label: 'He/Him' },
                                    { value: 'She/Her', label: 'She/Her' },
                                    { value: 'They/Them', label: 'They/Them' },
                                    { value: 'He/Them', label: 'He/Them' },
                                    { value: 'She/Them', label: 'She/Them' }]}
                                    value={{ value: selectDefault, label: selectDefault }}
                                />
                            </div>
                            <div className="login-btn-wrap">
                                <button type="submit" className="btn btn-primary float-right login-btn">Save</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
            <Popup profileSuccess={showPopup} close={closeModal} />
        </AdminLayout>

    )
}

export default EditprofileSettings