import React, { useCallback, useEffect, useState } from 'react'

import { Link } from 'react-router-dom'

import '../styles/Notifications.css'
import appUrls from '../../services/AppURLs'
import { history } from '../../utils/history'

interface NotificationsProps {
  mydata: any
  toggleNotification: any
}

export const Notifications: React.FC<NotificationsProps> = ({ mydata, toggleNotification }) => {
  const [data, SetData] = useState([])

  useEffect(() => {
    function clickevent(event: any) {
      if (!(event.target as Element).closest('#notifications_container')) {
        toggleNotification()
      }
    }

    window.addEventListener('click', clickevent)
    mydata &&
      mydata.notificationData &&
      mydata.notificationData.then((e: any) => {
        if (e && e.data && e.data.data) {
          SetData(e.data.data)
        }
      })
    return () => window.removeEventListener('click', clickevent)
  }, [mydata])

  const returnLocationConcact = useCallback((__param: any) => {
    if(__param.user_info.locations.length > 0){
        let regionConcat = ''
        __param.user_info.locations.map((region: any) => (regionConcat += region.name + ', '));
        return regionConcat.slice(0, -2);    
    } else {
        return __param.clinic='';
    }
}, [])
  const tabIndex=0;

  return (
    <div id="notifications_container" className="noti-pop-blk">
      <div className="noti-pop-wrap">
        <div className="head">
          <p tabIndex={tabIndex}>Notifications</p>
          { (data.length > 0 && ( localStorage.getItem('role') === '1' || localStorage.getItem('role') === '2')) &&
          
          <Link
            to={appUrls.admin.users.manageRequest}
            onClick={(e) => {
              toggleNotification()
            }}
          >
            View all
          </Link>
            }         
        </div>
        
        <div className="noti-mar">
          <div className={`overf-card ${(data.length <= 0 || localStorage.getItem('role') === '3') && 'no-notification'}`}>
            {(data.length <= 0 || localStorage.getItem('role') === '3') && 'You have no notification'}
         
            {(localStorage.getItem('role') === '1' || localStorage.getItem('role') === '2') && data.map((e: any) => {
              return (
                <>
                  {!e.user_info.accountApproved ? (
                    <div
                      className="noti-card"
                      onClick={(e) => {
                        toggleNotification()
                        history.push(appUrls.admin.users.manageRequest)
                      }}
                    >
                      <p className="noti-info">{`${e.user_info.name} has requested for account approval - ${returnLocationConcact(e)}`}</p>
                    </div>
                  ) : (e.user_info.archiveRequest === 'pending' || e.user_info.archiveRequest === 'start' ) && e.user_info.accountApproved === true ? (
                    <div
                      className="noti-card"
                      onClick={(e) => {
                        toggleNotification()
                        history.push(appUrls.admin.users.manageRequest)
                      }}
                    >
                      <p className="noti-info">{`${e.user_info.name} has requested to archive account - ${returnLocationConcact(e)}`}</p>
                    </div>
                  ) : e.user_info.roleChangeRequest === 'pending' &&
                    (e.user_info.archiveRequest !== 'pending' || e.user_info.archiveRequest !== 'approved') ? (
                    <div
                      className="noti-card"
                      onClick={(e) => {
                        toggleNotification()
                        history.push(appUrls.admin.users.manageRequest)
                      }}
                    >
                      <p className="noti-info">{`${e.user_info.name} has requested for role change request - ${returnLocationConcact(e)}`}</p>
                    </div>
                  ) : (
                    ''
                  )}
                </>
              )
            })}
           
          </div>
        </div>
        
      </div>
    </div>
  )
}
