import React, { useState, useEffect } from 'react'

import '../../styles/common.css'
import '../../styles/AdminStyle/adminSettings.css'
import moment from 'moment'

import { TriNav } from '../../components/admin/TriNav'
import Loader from '../../components/Loader/loader'
import Pagination from '../../components/pagination/pagination'
import apiFunctions from '../../services/api-service'
import { AdminLayout } from './AdminLayout'
import { authMessageTrivNav, authPriNavMenu } from './AdminOptions'
import MessageDetails from './MessageDetails'

interface SentProps { }

export const Sent: React.FC<SentProps> = ({ }) => {
  const tabIndex = 0
  const [showLoader, SetShowLoader] = useState(true)
  const [pagenum, setPageNum] = useState(1)
  const [inc, setinc] = useState(0)

  const [selectedObject, setSelectedObject] = useState<any>()
  const [showDescription, setShowDescription] = useState(false)
  const [messagesList, setMessageList] = useState<any>([])
  const [messages, setMessage] = useState<any>([])

  useEffect(() => {
    getUserMessage()
  }, [inc])

  // Get user messages
  const getUserMessage = async () => {
    try {
      SetShowLoader(true);
      const { data } = await apiFunctions.get(process.env.REACT_APP_SERVICE_BACKEND_URL + `/message/get?type=sent`)
      const result = data.data && data.data.Messages ? data.data.Messages : []
      setMessageList(result)
      sliceFunction(result)
    } catch (err) {
      console.log(err)
    }
  }

  const sliceFunction = (result: any) => {
    let _arr: any = []
    _arr = result?.concat()
    _arr = _arr.splice(inc, inc + 5 > result?.length ? inc + (result?.length % 5) : 5)
    setMessage(_arr);
    SetShowLoader(false)
  }

  //  Call Back function
  const callback = async () => {
    setShowDescription(false)
    setSelectedObject('')
    await getUserMessage()
  }

  const GetTime = (date: any) => {
    const differents = moment().diff(date, 'hours')
    return differents > 24 ? moment(date).format('DD MMM') : moment(date).format('hh:mm A')
  }
  return (
    <AdminLayout getMenuList={authPriNavMenu} sideBar="Message">
      {showLoader && <Loader />}
      <div className="admin-login-style message-blk">
        <div className="login-style2" tabIndex={tabIndex}>
          Messaging
        </div>
        <hr />
        <p className="login-style1" tabIndex={tabIndex}>
          Send and receive messages from Brook colleagues
        </p>
        <div className="">
          <TriNav getMenuList={authMessageTrivNav} />
        </div>
        {!showDescription && (
          <section className="inbox-blk sent-blk">
            <div className="mail-card">
              {messages && messages.map((item: any) => (
                <div
                  className="card"
                  onClick={() => {
                    setShowDescription(true)
                    setSelectedObject(item)
                  }}
                >
                  {/* <p className="status">Sent</p> */}
                  <p>{item.toUser && item.toUser.name}</p>
                  <p>{item.subject} - <span>{item.message}</span></p>
                  <p>{GetTime(item.createdAt)}</p>
                </div>
              ))}
            </div>
            {messages?.length === 0 && <div className="no-data">No records found</div>}

          </section>
        )}
        {showDescription && (
          <MessageDetails selectedObjects={selectedObject} showDescriptions={showDescription} callBack={callback} pageType="sent" />
        )}
        {!showDescription && (
          <div>
            <Pagination
              callback={function (__param: number): void {
                console.log('pagination added')
                setPageNum(__param)
                setinc(__param * 5 - 5)
              }}
              inc={inc}
              length={messagesList?.length}
            />
          </div>
        )}
      </div>
    </AdminLayout>
  )
}

export default Sent
