import React, { useEffect, useState } from 'react'

import '../styles/ApproveKit.css'
import '../styles/Orderkit.css'

// import _ from 'lodash';
import Select from 'react-select'

import dropdownIcon from '../../assets/images/dropdown.svg'
import useAuthContext from '../../context/provider/AuthContext'
import apiFunctions from '../../services/api-service'
import Loader from '../Loader/loader'
import { Flags } from './Flags'
import { PopupHeader } from './PopupHeader'


interface ApproveKitProps {
  close?: any
  ApproveUser?: any,
  flagReasons?: any,
  callbackKit?: (res: any) => void
}

export const ApprovePopup: React.FC<ApproveKitProps> = (props) => {
  const [add1, setadd1] = useState('')
  const [add2, setadd2] = useState('')
  const [town, settown] = useState('')
  const [pc, setpc] = useState('')
  const [ethnicity, setethnicity] = useState('')
  const [ethnicity_sub, setEthnicitySub] = useState('')
  const [gender, setgender] = useState('')
  const [details, setdetails] = useState('')
  const [describe, setDescribe] = useState('')
  const [selfDescribe, showSelfDescribe] = useState(false)
  const [showLoader, SetShowLoader] = useState(false)
  const [error, Seterror] = useState(false)
  const { ApproveUser } = props
  const [confirmation, showconfirmation] = useState(false)
  const [Checked, Setchecked] = useState(props.ApproveUser.client_contacted === true ? true : false)
  const [checkConfirmation, setcheckConfirmation] = useState(false)
  const [errormessage, seterrormessage] = useState('')
  const [postcodeError, setPostcodeError] = useState('')
  const { callbackKit } = props
  const [role] = useState(localStorage.getItem('role'))
  // const [flagsReason, setflagsReason] = useState([])
  // const [allflags, setFlags] = useState<Array<string>>([]);


  useEffect(() => {
    if (props.ApproveUser.question_response && props.ApproveUser.question_response.length > 0) {
      let questions_res = props.ApproveUser.question_response
      questions_res.map((val: any) => {
        if (val.question.question_text === 'Ethnicity') {
          let ans = JSON.parse(val.answer)
          setethnicity(ans.ethnicity)
          setEthnicitySub(ans.ethnicity_sub)
        }
        if (val.question.question_text === 'What best describes your gender') {
          setgender(val.answer)
        }
      })
    }
    setadd1(`${props.ApproveUser.address_line_1 ? props.ApproveUser.address_line_1 : ''} `)
    setadd2(`${props.ApproveUser.address_line_2 ? props.ApproveUser.address_line_2 : ''} `)
    settown(props.ApproveUser.town)
    setpc(props.ApproveUser.postcode)
    setdetails(props.ApproveUser.additional_data)
  }, [])

  const {state: { user }} = useAuthContext()
  const permissionData:any = user;

  let permissionAccess:any = permissionData && permissionData.user ? permissionData.user:permissionData;

  permissionAccess = permissionAccess && permissionAccess.permissions?.orders_and_results.filter((ele:any)=>{return ele.name === 'add_lilie_flagged'});

  function ValidateFormfields() {
    const ethnicityVal = {
      ethnicity,
      ethnicity_sub,
    }
    const payload = {
      client_uuid: props.ApproveUser.id,
      address_line_1: add1,
      address_line_2: add2,
      town,
      postcode: pc,
      contacted: Checked,
      reason: details,
      type: 'approve',
    }
    Seterror(false)
    if (
      payload.address_line_1 === '' ||
      payload.town === '' ||
      payload.postcode === '' ||
      ethnicityVal.ethnicity === '' ||
      ethnicityVal.ethnicity_sub === '' ||
      gender === '' || !Checked
    ) {
      Seterror(true)
      seterrormessage('Please enter the client contact details')
      return
    }
    apiFunctions.get(process.env.REACT_APP_SERVICE_BACKEND_URL + '/postcode/check?postcode=' + pc).then(result => {
      if (result.data) {
        setPostcodeError('');
        showconfirmation(true);
      }
    }).catch((err) => {
      setPostcodeError('Invalid postcode')
    })
  }
  async function ApproveSti() {
    SetShowLoader(true)
    let ethnicityVal = {
      ethnicity,
      ethnicity_sub,
    }
    const payload = {
      client_uuid: props.ApproveUser.id,
      address_line_1: add1,
      address_line_2: add2,
      town,
      postcode: pc,
      contacted: Checked,
      reason: details,
      type: 'approve',
      islilie: permissionAccess && permissionAccess[0] && permissionAccess[0].value ? checkConfirmation : false
    }
    let myObj: any = {}
    await apiFunctions.get(process.env.REACT_APP_SERVICE_BACKEND_URL + '/question/list').then(async (result) => {
      result.data.data.map((e: { question_text: string | number; id: string }) => {
        if (e.question_text && e.id) {
          myObj[String(e.question_text)] = e.id
        }
      })
      let __data = {
        response: [
          {
            question_id: myObj['Ethnicity'],
            user_id: props.ApproveUser.id,
            answer: JSON.stringify(ethnicityVal),
          },
          {
            question_id: myObj['What best describes your gender'],
            user_id: props.ApproveUser.id,
            answer: gender === 'Prefer to self-describe' ? describe : gender,
          },
        ],
      }
      
      await apiFunctions
        .post(process.env.REACT_APP_SERVICE_BACKEND_URL + '/admin/approveKit', payload)
        .then(async (_result) => {
          await apiFunctions
            .post(process.env.REACT_APP_SERVICE_BACKEND_URL +'/response/user-answer', __data)
            .then((_result) => {
              closesuccess('approve')
              SetShowLoader(false)
            })
            .catch((err) => {
              SetShowLoader(false)
              Seterror(true)
              seterrormessage(err.response.data && err.response.data.message ? err.response.data.message : '')
              console.log(err, 'errorreposne')
            })
        })
        .catch((err) => {
          SetShowLoader(false)
          console.log(err, 'errorreposne')
        })
    })
  }

  const closesuccess = (val: any) => {
    if (callbackKit) callbackKit(val)
  }

  const capitalizeFirstLetter = (value: string) => {
    return value.charAt(0).toUpperCase() + value.slice(1)
  }

  console.log(permissionAccess && permissionAccess);
  
  const tabIndex = 0;
  return (
    <>
      <>
        {confirmation && (
          <div className="modal d-block bg-color-popup " role="dialog">
            <div className="modal-dialog modal-dialog-centered reject-pop" role="document">
              <div className="modal-content model-bor p-5">
                <div className="modal-body">
                  <div className="reject-title text-left">
                    {`You have approved ${ApproveUser &&
                      `${capitalizeFirstLetter(ApproveUser.first_name)} ${capitalizeFirstLetter(ApproveUser.last_name)}`
                      } for a home test kit based on:`}
                  </div>
                  <div className="">
                    {props.flagReasons && (
                      <div className="d-flex flex-wrap">
                        {props.flagReasons &&
                          props.flagReasons.split(',').map((flag: any) => (
                            <div className="mr-3 mt-3">
                              <Flags value={flag} addClass="admin-flag" />
                            </div>
                          ))}
                      </div>
                    )}

                    <div className="mt-3 text-left">
                      <a
                        href="javascript:void(0)"
                        className="reject-pop-link"
                        onClick={() => {
                          showconfirmation(false)
                        }}
                      >
                        This is wrong, go back
                      </a>
                    </div>
                    <div className="mt-5 text-left">Tick the following if you have:</div>
                    <div className="mt-4">
                      <label htmlFor="confirmation" className="container checkbox-base">
                        <span className='sr-only'>confirmation</span>
                        <input
                          type="checkbox"
                          name="confirmation"
                          id="confirmation"
                          checked={checkConfirmation}
                          disabled = {!(permissionAccess && permissionAccess[0] && permissionAccess[0].value)}
                          onChange={(e) => {
                            setcheckConfirmation(e.target.checked)
                          }}
                        />
                        <span className="checkmark" />
                      </label>
                      <div className="reject-des">
                        Please confirm you have added the client to Lilie or updated their record
                      </div>
                    </div>
                  </div>
                </div>
                <span className="errorMessage">{error ? errormessage : null}</span>
                <div className="modal-footer bor-none mb-5">
                  <button
                    type="button"
                    className="btn btn-primary popup-btn login-btn"
                    onClick={() => {
                      ApproveSti()
                    }}
                    disabled={!checkConfirmation ? (props.flagReasons.split(',').length === 1 && props.flagReasons.split(',')[0] === 'I want to talk') ? false : true : false}
                  >
                    Finish
                  </button>
                </div>
              </div>
            </div>
            {showLoader && <Loader />}
          </div>
        )}
      </>
      <>
        {!confirmation && (
          <div className="modal d-block bg-color-popup " role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-width modal-xl" role="document">
              <div className="modal-content model-bor max-size">
                <div className="modal-body body-pad">
                  {/* you can the change popup header content dynamically using popup header component */}
                  <PopupHeader
                    Orderkit={true}
                    ApproveLocal={
                      ApproveUser && ApproveUser.postcodes && ApproveUser.postcodes.local_authority
                        ? ApproveUser.postcodes.local_authority
                        : 'Cornwall'
                    }
                  />
                  {(!ApproveUser.first_name || !ApproveUser.phone_no) && <div className="space-20" />}
                  <div className="pos-header">
                    <div className="login-style1 text-align-left" tabIndex={tabIndex}>
                      {ApproveUser &&
                        ApproveUser.first_name &&
                        ApproveUser.first_name.charAt(0).toUpperCase() + ApproveUser.first_name.slice(1)}{' '}
                      {ApproveUser &&
                        ApproveUser.last_name &&
                        ApproveUser.last_name.charAt(0).toUpperCase() + ApproveUser.last_name.slice(1)}
                    </div>
                    <div className="popup-text text-align-left" tabIndex={tabIndex}>
                      {ApproveUser && ApproveUser.phone_no && ApproveUser.phone_no}{' '}
                    </div>
                    <div className="popup-text pos-abs"> <span className='text-danger mr-1'>*</span>Client contacted? </div>
                    <div className="pos-check">
                      <label className="container" htmlFor='client_contacted'>
                        <input
                          type="checkbox"
                          name="client_contacted"
                          id="client_contacted"
                          checked={Checked}
                          onChange={(e) => {
                            Setchecked(e.target.checked)
                          }}
                        />
                        <span className='sr-only'>client contacted</span>
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                  <hr />
                  <div className="popup-text">Flagged by client in form </div>
                  <div>
                    <form className="col-12 w-100 col-sm-10 col-md-5 mt-4 pl-2 ml-0 popup-input">
                      <div className="row">
                        <div className="form-group mb-0 col-6">
                          <div className="form-group mt-2 text-align-left">
                            <label htmlFor='address_1'><span className='text-danger '>*</span>Address line 1 </label>
                            <input
                              type="text"
                              id="address_1"
                              className="form-control form-color"
                              value={add1}
                              onChange={(e) => setadd1(e.target.value)}
                              autoComplete='off'
                            />
                          </div>
                          <div className="form-group mt-4 text-align-left">
                            <label htmlFor='address_2'>Address line 2</label>
                            <input
                              type="text"
                              id="address_2"
                              className="form-control form-color"
                              value={add2}
                              autoComplete='off'
                              onChange={(e) => setadd2(e.target.value)}
                            />
                          </div>
                          <div className="form-group mt-4 text-align-left">
                            <label htmlFor='town'><span className='text-danger mr-1'>*</span>Town</label>
                            <input
                              type="text"
                              id="town"
                              className="form-control form-color"
                              autoComplete='off'
                              value={town}
                              onChange={(e) => settown(e.target.value)}
                            />
                          </div>
                          <div className="form-group mt-4 text-align-left">
                            <label htmlFor='postcode' ><span className='text-danger mr-1'>*</span>Post code</label>
                            <input
                              type="text"
                              id="postcode"
                              className="form-control form-color"
                              autoComplete='off'
                              value={pc}
                              onChange={(e) => setpc(e.target.value)}
                            />
                            {postcodeError ? <span className="errorMessage">{postcodeError}</span> : ''}
                          </div>
                        </div>
                        <div className="form-group col-6">
                          <div className="parent-pos text-align-left">
                            <label><span className='text-danger mr-1'>*</span>Ethnicity</label>
                            <Select
                              aria-label='ethnicity'
                              value={{ value: ethnicity, label: ethnicity }}
                              onChange={(e: any) => {
                                setethnicity(e?.value)
                              }}
                              classNamePrefix="select-form"
                              options={[
                                { value: 'Asian or Asian British', label: 'Asian or Asian British' },
                                {
                                  value: 'Black, African, Caribbean or Black British',
                                  label: 'Black, African, Caribbean or Black British',
                                },
                                { value: 'Mixed or Multiple ethnic groups', label: 'Mixed or Multiple ethnic groups' },
                                { value: 'Other ethnic group', label: 'Other ethnic group' },
                                { value: 'White', label: 'White' },
                              ]}
                            />
                            <div className="arrow-icon-appr">
                              <img src={dropdownIcon} className="pos-arrow-appr" alt="speaker" />
                            </div>
                          </div>
                          {ethnicity === 'Asian or Asian British' && (
                            <div className="Asian-or-Asian-British">
                              <div className="single-row ml-5 mt-3">
                                <div>
                                  <input
                                    aria-label='checkbox'
                                    type="checkbox"
                                    id="vehicle1"
                                    checked={ethnicity_sub === 'Indian'}
                                    onChange={() => setEthnicitySub('Indian')}
                                    className="form-check-input check-style inp-mar text-align-left"
                                  />
                                </div>
                                <div className="style-subdrop ml-4 mt-2">Indian</div>
                              </div>
                              <div className="single-row ml-5 mt-3">
                                <div>
                                  <input
                                    aria-label='checkbox'
                                    type="checkbox"
                                    id="vehicle1"
                                    checked={ethnicity_sub === 'Pakistani'}
                                    onChange={() => setEthnicitySub('Pakistani')}
                                    className="form-check-input check-style inp-mar text-align-left"
                                  />
                                </div>
                                <div className="style-subdrop ml-4 mt-2">Pakistani</div>
                              </div>
                              <div className="single-row ml-5 mt-3">
                                <div>
                                  <input
                                    aria-label='checkbox'
                                    type="checkbox"
                                    id="vehicle1"
                                    checked={ethnicity_sub === 'Bangladeshi'}
                                    onChange={() => setEthnicitySub('Bangladeshi')}
                                    className="form-check-input check-style inp-mar text-align-left"
                                  />
                                </div>
                                <div className="style-subdrop ml-4 mt-2">Bangladeshi</div>
                              </div>
                              <div className="single-row ml-5 mt-3">
                                <div>
                                  <input
                                    aria-label='checkbox'
                                    type="checkbox"
                                    id="vehicle1"
                                    checked={ethnicity_sub === 'Chinese'}
                                    onChange={() => setEthnicitySub('Chinese')}
                                    className="form-check-input check-style inp-mar text-align-left"
                                  />
                                </div>
                                <div className="style-subdrop ml-4 mt-2">Chinese</div>
                              </div>
                              <div className="single-row ml-5 mt-3">
                                <div>
                                  <input
                                    aria-label='checkbox'
                                    type="checkbox"
                                    id="vehicle1"
                                    checked={ethnicity_sub === 'Any other Asian background'}
                                    onChange={() => setEthnicitySub('Any other Asian background')}
                                    className="form-check-input check-style inp-mar text-align-left"
                                  />
                                </div>
                                <div className="style-subdrop ml-4 mt-2">Any other Asian background</div>
                              </div>
                            </div>
                          )}
                          {ethnicity === 'Black, African, Caribbean or Black British' && (
                            <div className="Black-African-Caribbean-or-Black-British">
                              <div className="single-row ml-5 mt-3">
                                <div>
                                  <input
                                    aria-label='checkbox'
                                    type="checkbox"
                                    id="vehicle1"
                                    checked={ethnicity_sub === 'African'}
                                    onChange={() => setEthnicitySub('African')}
                                    className="form-check-input check-style inp-mar text-align-left"
                                  />
                                </div>
                                <div className="style-subdrop ml-4 mt-2">African</div>
                              </div>
                              <div className="single-row ml-5 mt-3">
                                <div>
                                  <input
                                    aria-label='checkbox'
                                    type="checkbox"
                                    id="vehicle1"
                                    checked={ethnicity_sub === 'Caribbean'}
                                    onChange={() => setEthnicitySub('Caribbean')}
                                    className="form-check-input check-style inp-mar text-align-left"
                                  />
                                </div>
                                <div className="style-subdrop ml-4 mt-2">Caribbean</div>
                              </div>
                              <div className="single-row ml-5 mt-3">
                                <div>
                                  <input
                                    aria-label='checkout'
                                    type="checkbox"
                                    id="vehicle1"
                                    checked={ethnicity_sub === 'Any other black, African or caribbean background'}
                                    onChange={() => setEthnicitySub('Any other black, African or caribbean background')}
                                    className="form-check-input check-style inp-mar text-align-left"
                                  />
                                </div>
                                <div className="style-subdrop ml-4 mt-2">
                                  Any other black, African or caribbean background.
                                </div>
                              </div>
                            </div>
                          )}

                          {ethnicity === 'Mixed or Multiple ethnic groups' && (
                            <div className="Black-African-Caribbean-or-Black-British">
                              <div className="single-row ml-5 mt-3">
                                <div>
                                  <input
                                    aria-label='checkbox'
                                    type="checkbox"
                                    id="vehicle1"
                                    checked={ethnicity_sub === 'White and Black Caribbean'}
                                    onChange={() => setEthnicitySub('White and Black Caribbean')}
                                    className="form-check-input check-style inp-mar text-align-left"
                                  />
                                </div>
                                <div className="style-subdrop ml-4 mt-2">White and Black Caribbean</div>
                              </div>{' '}
                              <div className="single-row ml-5 mt-3">
                                <div>
                                  <input
                                    aria-label='checkout'
                                    type="checkbox"
                                    id="vehicle1"
                                    checked={ethnicity_sub === 'White and Black African'}
                                    onChange={() => setEthnicitySub('White and Black African')}
                                    className="form-check-input check-style inp-mar text-align-left"
                                  />
                                </div>
                                <div className="style-subdrop ml-4 mt-2">White and Black African</div>
                              </div>{' '}
                              <div className="single-row ml-5 mt-3">
                                <div>
                                  <input
                                    aria-label='checkout'
                                    type="checkbox"
                                    id="vehicle1"
                                    checked={ethnicity_sub === 'White and Asian'}
                                    onChange={() => setEthnicitySub('White and Asian')}
                                    className="form-check-input check-style inp-mar text-align-left"
                                  />
                                </div>
                                <div className="style-subdrop ml-4 mt-2">White and Asian</div>
                              </div>{' '}
                              <div className="single-row ml-5 mt-3">
                                <div>
                                  <input
                                    aria-label='checkout'
                                    type="checkbox"
                                    id="vehicle1"
                                    checked={ethnicity_sub === 'Any other Mixed or Multiple background'}
                                    onChange={() => setEthnicitySub('Any other Mixed or Multiple background')}
                                    className="form-check-input check-style inp-mar text-align-left"
                                  />
                                </div>
                                <div className="style-subdrop ml-4 mt-2">Any other Mixed or Multiple background</div>
                              </div>{' '}
                            </div>
                          )}
                          {ethnicity === 'Other ethnic group' && (
                            <div className="MIxed-or-Multiple-background">
                              <div className="single-row ml-5 mt-3">
                                <div>
                                  <input
                                    aria-label='checkout'
                                    type="checkbox"
                                    id="vehicle1"
                                    checked={ethnicity_sub === 'Arab'}
                                    onChange={() => setEthnicitySub('Arab')}
                                    className="form-check-input check-style inp-mar text-align-left"
                                  />
                                </div>
                                <div className="style-subdrop ml-4 mt-2">Arab</div>
                              </div>{' '}
                              <div className="single-row ml-5 mt-3">
                                <div>
                                  <input
                                    aria-label='checkbox'
                                    type="checkbox"
                                    id="vehicle1"
                                    checked={ethnicity_sub === 'Any other ethnic group'}
                                    onChange={() => setEthnicitySub('Any other ethnic group')}
                                    className="form-check-input check-style inp-mar text-align-left"
                                  />
                                </div>
                                <div className="style-subdrop ml-4 mt-2">Any other ethnic group</div>
                              </div>{' '}
                            </div>
                          )}
                          {ethnicity === 'White' && (
                            <div className="White mb-3">
                              <div className="single-row ml-5 mt-3">
                                <div>
                                  <input
                                    aria-label='checkbox'
                                    type="checkbox"
                                    id="vehicle1"
                                    checked={ethnicity_sub === 'English, Welsh, Scottish,Northern Irish or British'}
                                    onChange={() =>
                                      setEthnicitySub('English, Welsh, Scottish,Northern Irish or British')
                                    }
                                    className="form-check-input check-style inp-mar text-align-left"
                                  />
                                </div>
                                <div className="style-subdrop ml-4 mt-2">
                                  English, Welsh, Scottish,Northern Irish or British
                                </div>
                              </div>{' '}
                              <div className="single-row ml-5 mt-3">
                                <div>
                                  <input
                                    aria-label='checkbox'
                                    type="checkbox"
                                    id="vehicle1"
                                    checked={ethnicity_sub === 'Irish'}
                                    onChange={() => setEthnicitySub('Irish')}
                                    className="form-check-input check-style inp-mar text-align-left"
                                  />
                                </div>
                                <div className="style-subdrop ml-4 mt-2">Irish</div>
                              </div>{' '}
                              <div className="single-row ml-5 mt-3">
                                <div>
                                  <input
                                    aria-label='checkbox'
                                    type="checkbox"
                                    id="vehicle1"
                                    checked={ethnicity_sub === 'Gypsy or Irish Traveller'}
                                    onChange={() => setEthnicitySub('Gypsy or Irish Traveller')}
                                    className="form-check-input check-style inp-mar text-align-left "
                                  />
                                </div>
                                <div className="style-subdrop ml-4 mt-2">Gypsy or Irish Traveller</div>
                              </div>{' '}
                              <div className="single-row ml-5 mt-3">
                                <div>
                                  <input
                                    aria-label='checkbox'
                                    type="checkbox"
                                    id="vehicle1"
                                    checked={ethnicity_sub === 'Any other White background'}
                                    onChange={() => setEthnicitySub('Any other White background')}
                                    className="form-check-input check-style inp-mar text-align-left"
                                  />
                                </div>
                                <div className="style-subdrop ml-4 mt-2">Any other White background</div>
                              </div>{' '}
                            </div>
                          )}

                          <div className="parent-pos mt-4 text-align-left">
                            <label><span className='text-danger mr-1'>*</span>Gender identity</label>
                            <Select
                              aria-label='Gender identity'
                              value={{ value: gender, label: gender }}
                              onChange={(e: any) => {
                                setgender(e?.value)
                                showSelfDescribe(false)
                                if (e.value === 'Prefer to self-describe') {
                                  showSelfDescribe(true)
                                }
                              }}
                              classNamePrefix="select-form"
                              options={[
                                { value: 'Woman or girl', label: 'Woman or girl' },
                                { value: 'Man or boy', label: 'Man or boy' },
                                { value: 'Non-binary', label: 'Non-binary' },
                                { value: 'Prefer not to say', label: 'Prefer not to say' },
                                { value: 'Prefer to self-describe', label: 'Prefer to self-describe' },
                              ]}
                            />
                            <div className="arrow-icon-appr">
                              <img src={dropdownIcon} className="pos-arrow-appr" alt="speaker" />
                            </div>
                          </div>

                          {selfDescribe && (
                            <>
                              <input
                                aria-label='describe'
                                type="text"
                                className="form-control input-style mt-5"
                                placeholder="Enter your gender"
                                value={describe}
                                onChange={(e) => {
                                  setDescribe(e?.target.value)
                                }}
                              />
                            </>
                          )}
                          {/* <div className="form-group m-top ">
                                                <AdminCheckbox></AdminCheckbox>
                                                <label className='checkbox-label'>
                                                    Ask client to confirm if details can be shared with TDL
                                                </label>
                                            </div> */}
                        </div>
                      </div>
                      {error ? <span className="errorMessage">{errormessage}</span> : ''}
                    </form>
                  </div>
                  <div className="ml-2">
                    <div className="mt-3 row">
                      {props.flagReasons && (
                        <div className="d-flex flex-wrap">
                          {props.flagReasons &&
                            props.flagReasons.split(',').map((flag: any) => (
                              <div className="mr-3 mt-3">
                                <Flags value={flag} addClass="admin-flag" />
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                    <div className="mar-p text-align-left mt-0">
                      <label>Further details (optional)</label>
                      <textarea
                        aria-label='Further details (optional)'
                        onChange={(e) => setdetails(e.target.value)}
                        className="form-control h-textbox w-txt area-color"
                      />
                    </div>
                    <div className="f-size-app text-align-left f-b-app">
                      Add client details before approving request
                    </div>
                    <div className="f-size-app text-align-left">
                      You will need to ask the client for their information to complete the order form as they won’t
                      have entered it during the application if they had a flag.
                    </div>
                  </div>
                  <div className="modal-footer bor-none">
                    <div
                      className="popup-btn close-pop"
                      onClick={(e) => {
                        props.close()
                      }}
                    >
                      Close
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary popup-btn login-btn btn-app-col"
                      onClick={(e) => {
                        ValidateFormfields()
                      }}
                    >
                      Approve test kit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  )
}
