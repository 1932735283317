import React, { useCallback, useEffect, useState } from 'react'

import apiFunctions from '../../services/api-service';
import Loader from '../Loader/loader';
import '../styles/Toggle.css';

interface ToggleProps {
    status?: string
    header?: string
    adminId?: any,
    type?: any,
    category?: any
    permissions?: any
}

export const Toggle: React.FC<ToggleProps> = ({ status, adminId, type, category, permissions }) => {

    const [showLoader, SetShowLoader] = useState(false)

    const [rights, setrights] = useState(true);

    const [Checked, Setchecked] = useState(status === 'added' ? true : false)

    const roletype = localStorage.getItem('role');

    useEffect(() => {
        if (permissions && !permissions.s_edit_admin_permissions) {
            setrights(false)
        }
    }, [status, adminId, type, category, permissions])

    const changeStatus = useCallback((__val) => {
        SetShowLoader(true);
        apiFunctions.put(process.env.REACT_APP_SERVICE_BACKEND_URL + '/admin/permissionChange', { checked: __val, type, id: adminId, category }).then(() => {
            SetShowLoader(false);
        }).catch(() => {
            SetShowLoader(false);
            Setchecked(!__val);
        })
    }, [Checked, status, adminId, type, category, permissions])

    return (
        <>
            {showLoader && <Loader />}
            <label className="switch">
            <span className='sr-only'>slider</span>
                <input type="checkbox" onChange={e => { Setchecked(e.target.checked); changeStatus(e.target.checked) }} checked={Checked} disabled={roletype !== '1' || !rights} />
                <span className="slider round" />
            </label>
        </>
    );
}