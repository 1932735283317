import React, { useCallback, useContext, useEffect, useState } from 'react'

import Icon from '../assets/images/Speaker_Icon.svg'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import appUrls from '../services/AppURLs'
import '../styles/confirmemail.css'
import { history } from '../utils/history'

interface ThankYouProps {
  children: any
}

export const ThankYou: React.FC<ThankYouProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)
  const proceed = useCallback(() => {
    TextToSpeech.getInstance().playVoice(null, 'stop')
    Props.history.push('/whatnext')
  }, [])

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        themeDispatch({ type: 'SET_THEME_INTIAL_VALUE' })
        history.push(appUrls.sti.home)
      }
    }
  }, [history])
  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('thank_page_id')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  return (
    <div className="bg-color whatnext-style contactbrook-h">
      <div className="text-to-speech brook-container pad-bot">
        <div className="h-div mt-4">
          <h2 className="page-header-txt">ORDER A TEST KIT</h2>
          <div className="speaker-i">
            <button
              className="no-background-border"
              onClick={(e) => {
                TextToSpeech.getInstance().playVoice('text-to-speech')
                setisAccessibilityVoiceOver(true)
              }}
            >
              <img src={Icon} alt="speaker" />
            </button>
          </div>
        </div>
        <div className="b1-style pt-4 ">
          <p className="pt-0 mb-4">Thank you.</p>
          <p className="pt-0 mb-4">Your form has been submitted.</p>
          <p className="pt-0 mb-4">
            If you asked for email confirmation of your order, please keep an eye on your inbox. If you have not
            received your confirmation email within 24 hours, please check your junk or spam folder before getting in
            touch.{' '}
          </p>
          <div>
            <div className="ft-tab brook-container jus-end">
              <Button
                id="thank_page_id"
                isDisabled={false}
                buttonClassName="btn btn-default btn-main"
                buttonText="Next"
                onClick={(e) => proceed()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
