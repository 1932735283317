import React from 'react'

const DropDownIcon = ({ changeClass, newClass }: any) => {
  return (
    <div className={`${newClass} ${changeClass ? 'rotate-arrow' : ''}`}>
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14.243" viewBox="0 0 24.243 14.243">
        <g id="Group_3982" data-name="Group 3982" transform="translate(-717.879 -408.379)">
          <line
            id="Line_1188"
            data-name="Line 1188"
            x2="10"
            y2="10"
            transform="translate(740 410.5) rotate(90)"
            fill="none"
            stroke="#652f90"
            strokeLinecap="round"
            strokeWidth="3"
          />
          <line
            id="Line_1217"
            data-name="Line 1217"
            y1="10"
            x2="10"
            transform="translate(730 410.5) rotate(90)"
            fill="none"
            stroke="#652f90"
            strokeLinecap="round"
            strokeWidth="3"
          />
        </g>
      </svg>
    </div>
  )
}

export default DropDownIcon
