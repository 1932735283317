import React, { useCallback, useContext, useEffect, useState } from 'react'

import Icon from '../assets/images/Speaker_Icon.svg'
import '../styles/successpostcode.css'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import { stiKitLoaderPercentage } from '../utils/stiKitLoaderPercentage'

interface SuccesspostcodeProps {
  Props: any
}

export const Successpostcode: React.FC<SuccesspostcodeProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)

  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('successpostcode')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  const proceed = useCallback(() => {
    TextToSpeech.getInstance().playVoice(null, 'stop')
    setisAccessibilityVoiceOver(false)
    // Props.history.push('/typeofsex')
    Props.history.push('/preinfo')
  }, [])

  useEffect(() => {
    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: stiKitLoaderPercentage.SUCCESS_POST_CODE },
    })
  }, [])

  return (
    <div className="bg-color successpostcode-style">
      <div className="brook-container s-pad">
        <div className="text-to-speech">
          <div className="h-div mt-4">
            <h2 className="page-header-txt">ORDER A TEST KIT</h2>
            <div className="speaker-i">
              <button
                className="no-background-border"
                onClick={(e) => {
                  TextToSpeech.getInstance().playVoice('text-to-speech')
                  setisAccessibilityVoiceOver(true)
                }}
              >
                <img src={Icon} alt="speaker" />
              </button>
            </div>
          </div>
          <div className="mt-5">
            <div className="b1-style">
              Good news – Brook is able to provide a home STI test kit in your area for people your age.
            </div>
            <div className="p3-style p2-style mt-5">
              We have a few more questions to check if a home test is the right option for you. If not, we’ll help you
              access your local Brook clinic for a test.
            </div>
          </div>
          <div className="ft-tab jus-end brook-container g-color">
            <Button
              id="successpostcode"
              isDisabled={false}
              buttonClassName={`btn btn-default btn-main ${theme.theme}-start-btn-global`}
              buttonText="Next"
              onClick={(e) => proceed()}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
