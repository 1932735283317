
import '../styles/SearchableSelect.css'

import { ReactComponent as Clockicon } from '../../assets/images/clock.svg'
import { ReactComponent as Dislikeicon } from '../../assets/images/dislike_black.svg'
import { ReactComponent as Likeicon } from '../../assets/images/like_black.svg'
import { ReactComponent as Emailicon } from '../../assets/images/mail.svg'
import { ReactComponent as Phoneicon } from '../../assets/images/phone.svg'
import { ReactComponent as Smsicon } from '../../assets/images/sms.svg'


function MailIcon() {
    return <span ><Emailicon /></span>;
}
function PhoneIcon() {
    return <span><Phoneicon /></span>;
}
function SmsIcon() {
    return <span><Smsicon /></span>;
}
function DislikeIcon() {
    return <span ><Dislikeicon /></span>;
}
function LikeIcon() {
    return <span><Likeicon /></span>;
}
function ClockIcon() {
    return <span><Clockicon /></span>;
}
export const contactAttempts =
    [
        {
            value: "email",
            label: (
                <>
                    <span className='sub-option'><MailIcon /> <span className='pl-2'>Email</span></span>
                </>
            ),
        },
        {
            value: "phone",
            label: (
                <>
                    <span className='sub-option'><PhoneIcon /> <span className='pl-2'>Phone</span></span>
                </>
            ),
        },
        {
            value: "sms",
            label: (
                <>
                    <span className='sub-option'><SmsIcon /> <span className='pl-2'>Sms</span></span>
                </>
            ),
        },
    ]

export const resultOptions =
    [
        {
            value: "client contacted",
            label: (
                <>
                    <span className='sub-option'><LikeIcon /> <span className='pl-2'>Client contacted</span></span>
                </>
            ),
        },
        {
            value: "unable to contact",
            label: (
                <>
                    <span className='sub-option'><DislikeIcon /> <span className='pl-2'>Unable to contact</span></span>
                </>
            ),
        },
        {
            value: "awaiting response",
            label: (
                <>
                    <span className='sub-option'><ClockIcon /> <span className='pl-2'>Awaiting response</span></span>
                </>
            ),
        },
    ]


