import React from 'react'

import appUrls from '../../services/AppURLs'

interface FalseProps {
  type?: any
  slider?: Boolean
}
export const False: React.FC<FalseProps> = (props) => {
  let pathname = new URL(window.location.href).pathname
  let url = pathname === '/myth-busting' ? 'container-slider-hide' : ''
  const tabIndex = 0
  const noTab = -1
  return (
    <div className={url}>
      <div className="content-container pb-5">
        {props.type === 'emergency' ? (
          <div className="card-body pad-20">
            <h1 className="section-head blue">FALSE!</h1>
            <p className="card-text pad-10">
              The IUD can be used as emergency contraception to prevent pregnancy, but not when you are already
              pregnant.
            </p>
            <p className="card-text pad-10">
              An IUD can be fitted up to five days after unprotected sex, or five days after the earliest time you could
              have ovulated, as a form of emergency contraception. This must be done by a trained provider.
            </p>
            <p className="card-text pad-10">
              Beyond this time, the IUD and IUS should not be used if there is any chance of pregnancy as this may cause
              an ectopic pregnancy.
            </p>
            <div className="spacer-20" />
            <button className="s-btn-clr blue" type="submit">
              Learn about IUDs
            </button>
          </div>
        ) : props.type === 'home' ? (
          <div className="contianer-450 card card-style false-border orange ">
            <div className="card-body pad-20">
              <h1 className="section-head orange">TRUE!</h1>
              <p className="card-text">
                There is no medical or scientific way to tell if someone has had sex (or if they haven't).
              </p>
              <div className="spacer-10" />
              <p className="card-text">
                The word ‘virginity’ has been used for thousands of years to describe the state of not having had sex.
                Some people believe it is possible to 'test' for virginity - it isn't! Find out more about why the
                concept of virginity is problematic, and different ways of thinking about having sex for the first time.
              </p>
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/your-life/having-sex-for-the-first-time/"
                tabIndex={noTab}
                className="text-decoration-home"
                target="_blank"
                rel="noreferrer"
              >
                <button className="s-btn-clr orange" type="submit">
                  Find out more
                </button>{' '}
              </a>
            </div>
          </div>
        ) : props.type === 'myth-contraception-1' ? (
          <div className="contianer-450 card card-style false-border blue ">
            <div className="card-body pad-20">
              <h1 className="section-head blue">IT'S COMPLICATED!</h1>
              <p className="card-text  add-color">IT'S DIFFERENT FOR EVERYONE</p>
              <div className="spacer-10" />
              <p className="card-text  add-color">
                The implant is fitted by a healthcare professional that has been trained specifically to perform this
                procedure. Local anaesthetic is used to numb the area to try and stop it being painful, though it can
                still cause some discomfort. The implant is inserted under the skin using a specialist device.
              </p>
              <div className="spacer-10" />
              <p className="card-text  add-color">
                After the insertion you are advised to keep the area clean and dry. A small dressing can be left in
                place for a number of days. The healthcare professional will explain to you that with any procedure
                there is always a very small risk of infection. However, if you follow the advice given this should be
                unlikely. Very often paper stitches are only used when the implant is removed. These are normally left
                in place for up to 5 days – and you can remove them yourself.
              </p>
              <div className="spacer-20" />
              {/* <a href='https://www.brook.org.uk/your-life/periods/' className='text-decoration-home' target='_blank' rel='noreferrer'>
                              <button className="s-btn-clr blue" type="submit">Find a service</button> </a> */}
            </div>
          </div>
        ) : props.type === 'myth-contraception-2' ? (
          <div className="contianer-450 card card-style false-border blue ">
            <div className="card-body pad-20">
              <h1 className="section-head blue">FALSE!</h1>
              <div className="spacer-20" />
              <p className="card-text  add-color">ANYONE CAN GET HELP AND SUPPORT WITH CONTRACEPTION</p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                Healthcare professionals are legally allowed to provide confidential contraceptive advice and treatment
                to young people under the age of 16 without parental consent.{' '}
              </p>
              <div className="spacer-20" />
              <button className="s-btn-clr blue" type="submit">
                Find out more
              </button>
            </div>
          </div>
        ) : props.type === 'myth-contraception-3' ? (
          <div className="contianer-450 card card-style false-border blue ">
            <div className="card-body pad-20">
              <h1 className="section-head blue">FALSE!</h1>
              <div className="spacer-20" />
              <p className="card-text  add-color">SOME METHODS CAN LAST WEEKS, MONTHS OR EVEN YEARS!</p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                There are many methods of contraception available that once given can work for a number of weeks or even
                years! These methods are often referred to as long-acting reversible contraception (LARC).
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                Depending upon the type of method used, this will determine how often it will need to be renewed.
              </p>
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/topics/contraception/"
                className="text-decoration-home"
                target="_blank"
                tabIndex={noTab}
                rel="noreferrer"
              >
                <button className="s-btn-clr blue" type="submit">
                  Find out more
                </button>{' '}
              </a>
            </div>
          </div>
        ) : props.type === 'myth-contraception-4' ? (
          <div className="contianer-450 card card-style false-border blue ">
            <div className="card-body pad-20">
              <h1 className="section-head blue">FALSE!</h1>
              <div className="spacer-20" />
              <p className="card-text  add-color">ANYONE CAN BUY CONDOMS OR ACCESS FREE CONDOMS</p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                Condoms are available at any age and free of charge from contraception clinics, sexual health clinics
                and young people's clinics, though if you are under 16 you may be asked a few questions to make sure you
                know how to use them and are safe.
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                You can also buy condoms from pharmacies, supermarkets, vending machines in public toilets and online.
              </p>
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/your-life/condoms/"
                className="text-decoration-home"
                target="_blank"
                tabIndex={noTab}
                rel="noreferrer"
              >
                <button className="s-btn-clr blue" type="submit">
                  Find out more
                </button>{' '}
              </a>
            </div>
          </div>
        ) : props.type === 'myth-contraception-5' ? (
          <div className="contianer-450 card card-style false-border blue ">
            <div className="card-body pad-20">
              <h1 className="section-head blue">FALSE!</h1>
              <div className="spacer-20" />
              <p className="card-text  add-color">LARC METHODS DON'T AFFECT FERTILITY ONCE STOPPED</p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                {' '}
                The IUS, IUD and implant can be removed at any time by a trained healthcare professional. These methods
                are suitable for most people of reproductive age, and fertility will return quickly once removed. It is
                important to remember if you stop using a method of contraception and you don't want to get pregnant
                then you must start to use a different method straight away.
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                Leaving LARC methods in longer than their expiry will not cause infertility; on the contrary, their
                effectiveness will be reduced, so it is important to get them replaced or start an alternative method of
                contraception when they expire.
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                However, after having the contraceptive injection the return of your fertility may be delayed (for some
                this may be as long as a year) because it cannot be removed once administered.
              </p>
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/your-life/myths-about-long-acting-reversible-contraception-larc/"
                className="text-decoration-home"
                tabIndex={noTab}
                target="_blank"
                rel="noreferrer"
              >
                <button className="s-btn-clr blue" type="submit">
                  Find out more
                </button>{' '}
              </a>
            </div>
          </div>
        ) : props.type === 'myth-contraception-6' ? (
          <div className="contianer-450 card card-style false-border blue ">
            <div className="card-body pad-20">
              <h1 className="section-head blue">FALSE!</h1>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                The IUD can be used as emergency contraception to prevent pregnancy, but not when you are already
                pregnant.
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                An IUD can be fitted up to five days after unprotected sex, or five days after the earliest time you
                could have ovulated, as a form of emergency contraception. This must be done by a trained provider.
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                Beyond this time, the IUD and IUS should not be used if there is any chance of pregnancy as this may
                cause an ectopic pregnancy.
              </p>
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/your-life/iud-intrauterine-device/"
                className="text-decoration-home"
                target="_blank"
                tabIndex={noTab}
                rel="noreferrer"
              >
                <button className="s-btn-clr blue" type="submit">
                  Learn about IUDs
                </button>{' '}
              </a>
            </div>
          </div>
        ) : props.type === 'myth-contraception-7' ? (
          <div className="contianer-450 card card-style false-border blue ">
            <div className="card-body pad-20">
              <h1 className="section-head blue">FALSE!</h1>
              <div className="spacer-20" />
              <p className="card-text  add-color">CONDOMS HAVE EXPIRY DATES</p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                You must always check the expiry date on the packet of a condom before you use it. If it is past its
                expiry date then the latex may have deteriorated, causing it to split when used.
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                You should also replace any condoms that have been in your wallet, pocket or bag longer than a month,
                even if they are within the expiry date, as this might damage them. If the wrappers look damaged throw
                them away and get new ones.
              </p>
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/your-life/condoms-common-mistakes/"
                className="text-decoration-home"
                target="_blank"
                tabIndex={noTab}
                rel="noreferrer"
              >
                <button className="s-btn-clr blue" type="submit">
                  Find out more
                </button>{' '}
              </a>
            </div>
          </div>
        ) : props.type === 'consent-1' ? (
          <div className="contianer-450 card card-style false-border pink ">
            <div className="card-body pad-20">
              <h1 className="section-head pink">FALSE!</h1>
              <div className="spacer-20" />
              <p className="card-text  add-color">WE CAN'T ALWAYS CONTROL OUR BODIES</p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                Sometimes our bodies will be turned on when we don’t want to engage in sexual activity.
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                Even if a penis is erect or a vulva is wet – if you do not want to have sex, you do not have to! Neither
                of these are an invitation to continue having sex or to make you engage in sexual activity of any kind.
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                If you're not sure if someone want to engage in sexual activity then the simplest way to find out is to
                ask.
              </p>
              <div className="spacer-20" />
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/your-life/sex-and-consent/"
                className="text-decoration-home"
                target="_blank"
                tabIndex={noTab}
                rel="noreferrer"
              >
                <button className="s-btn-clr pink" type="submit">
                  Find out more
                </button>{' '}
              </a>
            </div>
          </div>
        ) : props.type === 'consent-2' ? (
          <div className="contianer-450 card card-style false-border pink ">
            <div className="card-body pad-20">
              <h1 className="section-head pink">FALSE!</h1>
              <div className="spacer-20" />
              <p className="card-text  add-color">THERE IS NO EXCUSE FOR SEXUAL VIOLENCE</p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                Whether or not someone is flirting, has dressed a certain way or has laughed at the other person’s jokes
                is irrelevant. Clothes are not consent, and neither is flirting.
              </p>
              <div className="spacer-20" />

              <p className="card-text  add-color">
                When it comes to sex of any kind, you always need consent from all people involved. Always, every time
                and throughout every encounter.
              </p>
              <div className="spacer-20" />
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/your-life/sex-and-consent/"
                className="text-decoration-home"
                target="_blank"
                tabIndex={noTab}
                rel="noreferrer"
              >
                <button className="s-btn-clr pink" type="submit">
                  Find out more
                </button>{' '}
              </a>
            </div>
          </div>
        ) : props.type === 'consent-3' ? (
          <div className="contianer-450 card card-style false-border pink ">
            <div className="card-body pad-20">
              <h1 className="section-head pink">IT'S COMPLICATED!</h1>
              <div className="spacer-20" />
              <p className="card-text  add-color">CONSENT REQUIRES CAPACITY</p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                When the law uses the words ‘freedom’ and ‘capacity’ in relation to consent, it is talking about someone
                being able to agree to sexual activity with a full understanding of what they are agreeing to, and no
                pressure to say ‘yes’.
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                It is possible to have consensual sex when drinking alcohol or using drugs if everyone involved can
                clearly and fully understand and agree to what is going on, meaning they have the capacity to consent.
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                However, if someone is too drunk or high to understand what is happening, then they do not have the
                mental capacity to consent to sexual activity. In other words, they may not be able to understand what
                they are agreeing to, even if they can say ‘yes’, or they may not be able to say no.{' '}
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">IF YOU'RE NOT SURE THEN STOP</p>
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/your-life/sex-and-consent/"
                className="text-decoration-home"
                target="_blank"
                tabIndex={noTab}
                rel="noreferrer"
              >
                <button className="s-btn-clr pink" type="submit">
                  Find out more
                </button>{' '}
              </a>
            </div>
          </div>
        ) : props.type === 'consent-4' ? (
          <div className="contianer-450 card card-style false-border pink ">
            <div className="card-body pad-20">
              <h1 className="section-head pink">FALSE!</h1>
              <div className="spacer-20" />
              <p className="card-text  add-color">YOU NEED CONSENT FOR ALL TYPES OF SEXUAL ACTIVITY, ALWAYS</p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                There are many different ways of having sex with someone, many of which don’t involve penetration. You
                need consent for all types of sex.
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                The law isn’t always helpful here, defining ‘rape’ as penetration by someone with a penis. People of all
                genders need to ask for consent. And consenting to one thing does not mean you have consented to
                everything.{' '}
              </p>
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/your-life/sex-and-consent/"
                className="text-decoration-home"
                target="_blank"
                tabIndex={noTab}
                rel="noreferrer"
              >
                <button className="s-btn-clr pink" type="submit">
                  Find out more
                </button>{' '}
              </a>
            </div>
          </div>
        ) : props.type === 'consent-5' ? (
          <div className="contianer-450 card card-style false-border pink ">
            <div className="card-body pad-20">
              <h1 className="section-head pink">FALSE!</h1>
              <div className="spacer-20" />
              <p className="card-text  add-color">RELATIONSHIP ≠ CONSENT</p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                Just because you had sex with someone before, that doesn’t mean that you’ve automatically given consent
                to have sex with them again. This includes if you are in a relationship (including a marriage). Consent
                must be sought and given every time.{' '}
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                It doesn’t matter how far into a sexual situation you get or what you’ve done with them in the past, you
                always have the right to change your mind and stop at any time. It’s up to the other person to respect
                that.
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                Any sort of sexual activity without consent is illegal, whatever the age of the people involved and
                whatever their relationship.
              </p>
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/your-life/sex-and-consent/"
                className="text-decoration-home"
                target="_blank"
                tabIndex={noTab}
                rel="noreferrer"
              >
                <button className="s-btn-clr pink" type="submit">
                  Find out more
                </button>{' '}
              </a>
            </div>
          </div>
        ) : props.type === 'consent-6' ? (
          <div className="contianer-450 card card-style false-border pink ">
            <div className="card-body pad-20">
              <h1 className="section-head pink">IT'S COMPLICATED!</h1>
              <div className="spacer-20" />
              <p className="card-text  add-color">TECHNICALLY, THIS IS TRUE</p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                In the eyes of the law, you are only old enough to consent to sex or sexual activity at 16. This is
                what’s known as the ‘age of consent’. The law applies to everyone in the UK, regardless of gender or
                sexuality.
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">HOWEVER...</p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                it is important to remember that the law is designed to protect young people from abuse, harm or being
                taken advantage of by adults. It is not meant to criminalise young people and there is no intention to
                prosecute people under the age of 16 when both mutually agree (consent) and when they are of a similar
                age.
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                The age of consent is 16 because a lot of people don’t feel ready to have sex before they are around 16.
                Nobody should ever feel pressured by their partner, or by their peers to have sex before they feel
                ready, and safe.
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                If you are under 16, you are able to access sexual health services (like Brook) without getting in
                trouble.
              </p>
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/your-life/im-15-can-i-have-sex/"
                className="text-decoration-home"
                tabIndex={noTab}
                target="_blank"
                rel="noreferrer"
              >
                <button className="s-btn-clr pink" type="submit">
                  Find out more
                </button>{' '}
              </a>
            </div>
          </div>
        ) : props.type === 'bodies-1' ? (
          <div className="contianer-450 card card-style false-border orange ">
            <div className="card-body pad-20">
              <h1 className="section-head orange">IT'S COMPLICATED!</h1>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                IT IS POSSIBLE THAT YOU COULD GET PREGNANT IF YOU HAVE SEX WHEN YOU ARE ON YOUR PERIOD
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">During a shorter cycle, the period and ovulation may overlap. </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                As sperm can live in the uterus for up to seven days, this could result in a pregnancy.
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                This is why it's important to use contraception every time you have sex if you don't want to risk
                unwanted pregnancy.{' '}
              </p>
              <div className="spacer-20" />
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/your-life/periods/"
                className="text-decoration-home"
                target="_blank"
                tabIndex={noTab}
                rel="noreferrer"
              >
                <button className="s-btn-clr orange" type="submit">
                  Find out more
                </button>{' '}
              </a>
            </div>
          </div>
        ) : props.type === 'bodies-2' ? (
          <div className="contianer-450 card card-style false-border orange ">
            <div className="card-body pad-20">
              <h1 className="section-head orange">FALSE!</h1>
              <div className="spacer-20" />
              <p className="card-text  add-color">THERE IS NO NORMAL WHEN IT COMES TO PUBERTY</p>
              <div className="spacer-20" />
              <p className="card-text  add-color">Every person will experience puberty differently. </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                Some might start experiencing signs of puberty at a young age, some might start relatively late, and
                others will feel like it is stopping and starting with gaps in between.
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                Usually, the body will start changing between the ages of 7 to 16. It generally takes between two and
                four years to fully develop.{' '}
              </p>
              <div className="spacer-20" />
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/your-life/puberty/"
                className="text-decoration-home"
                target="_blank"
                tabIndex={noTab}
                rel="noreferrer"
              >
                <button className="s-btn-clr orange" type="submit">
                  Find out more
                </button>{' '}
              </a>
            </div>
          </div>
        ) : props.type === 'bodies-3' ? (
          <div className="contianer-450 card card-style false-border orange ">
            <div className="card-body pad-20">
              <h1 className="section-head orange">FALSE !</h1>
              <div className="spacer-20" />
              <p className="card-text  add-color">EXERCISE CAN HELP WITH PERIOD PAIN</p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                Gentle movement like walking, swimming or yoga can boost mood and release hormones called endorphins
                that relieve aches and pains, including period cramps.
              </p>
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/your-life/periods/"
                className="text-decoration-home"
                target="_blank"
                rel="noreferrer"
              >
                <button className="s-btn-clr orange" type="submit">
                  Find out more
                </button>{' '}
              </a>
            </div>
          </div>
        ) : props.type === 'bodies-4' ? (
          <div className="contianer-450 card card-style false-border orange ">
            <div className="card-body pad-20">
              <h1 className="section-head orange">FALSE!</h1>
              <div className="spacer-20" />
              <p className="card-text  add-color">THERE IS NO EVIDENCE TO SUPPORT THIS</p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                Sharks are attracted to blood, but the amount produced during a period is so small that they’d have to
                be almost next to the swimmer to sense it.
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">Swimming is completely safe during a period.</p>
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/your-life/periods/"
                className="text-decoration-home"
                target="_blank"
                tabIndex={noTab}
                rel="noreferrer"
              >
                <button className="s-btn-clr orange" type="submit">
                  Find out more
                </button>{' '}
              </a>
            </div>
          </div>
        ) : props.type === 'bodies-5' ? (
          <div className="contianer-450 card card-style false-border orange ">
            <div className="card-body pad-20">
              <h1 className="section-head orange">FALSE!</h1>
              <div className="spacer-20" />
              <p className="card-text  add-color">IT'S ENTIRELY YOUR CHOICE</p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                It’s perfectly safe, from a health point of view, to have sex during a period.{' '}
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                However, being on your period does not mean you can't get pregnant and STIs are still a risk, so is
                important to use contraception and condoms to avoid unwanted pregnancies and infections.
              </p>
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/topics/sex/"
                className="text-decoration-home"
                target="_blank"
                tabIndex={noTab}
                rel="noreferrer"
              >
                <button className="s-btn-clr orange" type="submit">
                  Find out more
                </button>{' '}
              </a>
            </div>
          </div>
        ) : props.type === 'bodies-6' ? (
          <div className="contianer-450 card card-style false-border orange ">
            <div className="card-body pad-20">
              <h1 className="section-head orange">FALSE!</h1>
              <div className="spacer-20" />
              <p className="card-text  add-color">VIRGINITY IS A PROBLEMATIC CONCEPT</p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                Firstly, virginity itself is a problematic concept. Traditionally, it refers to the first time someone
                has penetrative sex, but virginity can mean different things to different people. Some people prefer the
                term 'sexual debut' when talking about having sex for the first time, whatever type of sex that might
                be.
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                THERE IS NO MEDICAL DEFINITION OF VIRGINITY AND IT CANNOT BE EVIDENCED PHYSICALLY
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                Often, the presence of an intact hymen, which is a thin piece of skin that partially covers the entrance
                to the vagina, is taken as an indicator of virginity. In reality, you can't tell from the state of
                someone's hymen if they have had sex or not.
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                Using a tampon might stretch or tear a hymen as can many other things that have nothing to do with sex.
                On the flip side, it's possible to have penetrative sex without tearing the hymen. It's also important
                to remember that not everyone with a vagina will have a hymen to start with!
              </p>
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/your-life/what-is-virginity/"
                className="text-decoration-home"
                target="_blank"
                tabIndex={noTab}
                rel="noreferrer"
              >
                <button className="s-btn-clr orange" type="submit">
                  Find out more
                </button>{' '}
              </a>
            </div>
          </div>
        ) : props.type === 'bodies-7' ? (
          <div className="contianer-450 card card-style false-border orange ">
            <div className="card-body pad-20">
              <h1 className="section-head orange">IT'S COMPLICATED!</h1>
              <div className="spacer-20" />
              <p className="card-text  add-color">IT'S DIFFERENT FOR EVERYONE</p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                The average menstrual cycle is 28 days, but it can be shorter or longer, ranging anywhere from 21-40
                days. The length of a cycle may also change throughout a lifetime, and it can be affected by a number of
                factors including contraception, mental health and physical health.
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                Knowing you're own menstrual cycle not only helps you monitor your risk of pregnancy (if you are having
                sex), but it can also help you keep track of changes in your body that might affect your mental and
                physical health.{' '}
              </p>
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/your-life/periods/"
                className="text-decoration-home"
                target="_blank"
                tabIndex={noTab}
                rel="noreferrer"
              >
                <button className="s-btn-clr orange" type="submit">
                  Find out more
                </button>{' '}
              </a>
            </div>
          </div>
        ) : props.type === 'sti-1' ? (
          <div className="contianer-450 card card-style false-border green ">
            <div className="card-body pad-20">
              <h1 className="section-head green">FALSE !</h1>
              <div className="spacer-20" />
              <p className="card-text  add-color">STIS CAN BE TRANSMITTED THROUGH DIFFERENT TYPES OF SEXUAL CONTACT</p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                STIs can be passed on through penetrative sex, including vaginal and anal sex, oral sex, and sharing sex
                toys without using a condom or cleaning them. The test you need to have will depend on what type of sex
                you have been having.
              </p>
              <div className="spacer-20" />
              <a
                href={appUrls.sti.home}
                tabIndex={noTab}
                className="text-decoration-home"
                target="_blank"
                rel="noreferrer"
              >
                <button className="s-btn-clr green" type="submit">
                  See if you can order a free test kit
                </button>{' '}
              </a>
            </div>
          </div>
        ) : props.type === 'sti-2' ? (
          <div className="contianer-450 card card-style false-border green ">
            <div className="card-body pad-20">
              <h1 className="section-head green">FALSE!</h1>
              <div className="spacer-20" />
              <p className="card-text  add-color">IT'S USUALLY QUICK AND EASY</p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                Getting tested for STIs is a very quick process, involving either a swab of the vagina, mouth or anus, a
                urine sample or blood sample. STI testing can be done at a sexual health clinic or sometimes your GP
                surgery. You may also be able to order a home testing kit, depending on where you live and what type of
                sex you have had – this is even quicker and easier than going to a clinic!{' '}
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                Taking a blood sample can be a bit sore and some people find swabs uncomfortable, but it’s over quickly
                and gives you the peace of mind that you are keeping yourself and others safe.
              </p>
              <div className="spacer-20" />
              <a href={appUrls.sti.home} target="_blank" tabIndex={noTab} className="text-decoration-home">
                <button className="s-btn-clr green" type="submit">
                  See if you can order a free test kit
                </button>
              </a>
            </div>
          </div>
        ) : props.type === 'sti-3' ? (
          <div className="contianer-450 card card-style false-border green ">
            <div className="card-body pad-20">
              <h1 className="section-head green">FALSE!</h1>
              <div className="spacer-20" />
              <p className="card-text  add-color">IT'S NOTHING TO DO WITH HYGIENE</p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                {' '}
                An STI is no different than any other infection, except in how it's transmitted. Most of them have
                minimal symptoms, and have nothing to do with your hygiene. They are nothing to be ashamed of, and lots
                of people have them regardless of how clean they are!
              </p>
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/topics/stis/"
                className="text-decoration-home"
                target="_blank"
                tabIndex={noTab}
                rel="noreferrer"
              >
                <button className="s-btn-clr green" type="submit">
                  Find out more
                </button>{' '}
              </a>
            </div>
          </div>
        ) : props.type === 'sti-4' ? (
          <div className="contianer-450 card card-style false-border green ">
            <div className="card-body pad-20">
              <h1 className="section-head green">FALSE!</h1>
              <div className="spacer-20" />
              <p className="card-text  add-color">STIS CAN BE TRANSMITTED THROUGH DIFFERENT TYPES OF SEXUAL CONTACT</p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                STIs can be passed on through penetrative sex, including vaginal and anal sex, oral sex, and sharing sex
                toys without using a condom or cleaning them. The test you need to have will depend on what type of sex
                you have been having.
              </p>
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/your-life/do-i-have-an-sti/"
                className="text-decoration-home"
                target="_blank"
                rel="noreferrer"
              >
                <button className="s-btn-clr green" type="submit">
                  Find out more
                </button>{' '}
              </a>
            </div>
          </div>
        ) : props.type === 'sti-5' ? (
          <div className="contianer-450 card card-style false-border green ">
            <div className="card-body pad-20">
              <h1 className="section-head green">FALSE!</h1>
              <div className="spacer-20" />
              <p className="card-text  add-color">GETTING TREATMENT IS A MUST</p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                testing kit, depending on where you live and what type of sex you have than going to a clinic!{' '}
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                While lots of people don't get symptoms when they have an STI, the infection can still cause damage if
                left untreated. They can affect your fertility, and some, like HIV and syphilis, can be fatal if left
                untreated.{' '}
              </p>
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/your-life/why-you-should-get-tested-for-stis/"
                tabIndex={noTab}
                className="text-decoration-home"
                target="_blank"
                rel="noreferrer"
              >
                <button className="s-btn-clr green" type="submit">
                  Find out more
                </button>{' '}
              </a>
            </div>
          </div>
        ) : props.type === 'bodies-hs' ? (
          <div className="contianer-450 card card-style false-border orange ">
            <div className="card-body pad-20">
              <h1 className="section-head orange">FALSE!</h1>
              <div className="spacer-20" />
              <p className="card-text  add-color">VIRGINITY IS A PROBLEMATIC CONCEPT</p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                Firstly, virginity itself is a problematic concept. Traditionally, it refers to the first time someone
                has penetrative sex, but virginity can mean different things to different people. Some people prefer the
                term 'sexual debut' when talking about having sex for the first time, whatever type of sex that might
                be.{' '}
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                THERE IS NO MEDICAL DEFINITION OF VIRGINITY AND IT CANNOT BE EVIDENCED PHYSICALLY{' '}
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                Often, the presence of an intact hymen, which is a thin piece of skin that partially covers the entrance
                to the vagina, is taken as an indicator of virginity. In reality, you can't tell from the state of
                someone's hymen if they have had sex or not.{' '}
              </p>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                Using a tampon might stretch or tear a hymen as can many other things that have nothing to do with sex.
                On the flip side, it's possible to have penetrative sex without tearing the hymen. It's also important
                to remember that not everyone with a vagina will have a hymen to start with!{' '}
              </p>
              <div className="spacer-20" />
              <button className="s-btn-clr orange" type="submit">
                Find out more
              </button>
            </div>
          </div>
        ) : props.type === 'upsi' ? (
          <div className="card-body pad-20">
            <h1 className="section-head orange">FALSE!</h1>
            <div className="spacer-20" />
            <p className="card-text  add-color">IT'S USUALLY QUICK AND EASY</p>
            <div className="spacer-20" />
            <p className="card-text  add-color">
              Getting tested for STIs is a very quick process, involving either a swab of the vagina, mouth or anus, a
              urine sample or blood sample. STI testing can be done at a sexual health clinic or sometimes your GP
              surgery. You may also be able to order a home testing kit, depending on where you live and what type of
              sex you have had – this is even quicker and easier than going to a clinic!{' '}
            </p>
            <div className="spacer-20" />
            <p className="card-text  add-color">
              Taking a blood sample can be a bit sore, and some people find swabs uncomfortable, but it's over quickly
              and gives you the peace of mind that you are keeping yourself and others safe.
            </p>
            <div className="spacer-20" />
            <button className="s-btn-clr orange" type="submit">
              Find out more
            </button>
          </div>
        ) : props.type === 'iud-contraception' ? (
          <div className="contianer-450 card card-style false-border blue ">
            <div className="card-body pad-20">
              <h1 className="section-head blue">FALSE!</h1>
              <div className="spacer-20" />
              <p className="card-text  add-color">
                The IUD can be used as emergency contraception to prevent pregnancy, but not when you are already
                pregnant.
              </p>

              <p className="card-text  add-color">
                An IUD can be fitted up to five days after unprotected sex, or five days after the earliest time you
                could have ovulated, as a form of emergency contraception. This must be done by a trained provider.{' '}
              </p>

              <p className="card-text  add-color">
                Beyond this time, the IUD and IUS should not be used if there is any chance of pregnancy as this may
                cause an ectopic pregnancy.
              </p>
              <div className="spacer-20" />
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/your-life/iud-intrauterine-device/"
                tabIndex={noTab}
                className="text-decoration-home"
                target="_blank"
                rel="noreferrer"
              >
                <button className="s-btn-clr blue" type="submit">
                  Learn about IUDs
                </button>{' '}
              </a>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}
