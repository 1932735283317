import React from "react";

import TimePicker from "react-time-picker";

interface TimePickerProps {
    onChange?: any;
    value?: any;
    className?: string | undefined;
    minutePlaceholder?: string;
    secondPlaceholder?: string;
    isDisabled?: any;
    name?: any
}

export const TimePick: React.FC<TimePickerProps> = ({ onChange, value, isDisabled }) => {
    return (
        <TimePicker
            onChange={onChange}
            value={value}
            format="hh:mm a"
            minutePlaceholder="mm"
            secondPlaceholder="ss"
            disabled={isDisabled}
            isOpen={true}
        />
    );
};
