import React, { useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import { Accordion } from '../components/accordian/Accordian'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import '../styles/postcodebarrier.css'

interface PostcodebarrierProps {
  children: any
}

export const Postcodebarrier: React.FC<PostcodebarrierProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)

  const history = useHistory()

  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('barrier_id')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  useEffect(() => {
    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: '30' },
    })
  }, [])

  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }
  const tabIndex = 0
  return (
    <div className="bg-color postcodebarrier-style">
      <div className="brook-container pad-bot">
        <div className="text-to-speech">
          <div className="pad-te">
            <div className="h-div mt-4">
              <h2 className={` page-header-txt`}>ORDER A TEST KIT</h2>
              <div className="speaker-i">
                <button
                  className="no-background-border"
                  onClick={(e) => {
                    TextToSpeech.getInstance().playVoice('text-to-speech')
                    setisAccessibilityVoiceOver(true)
                  }}
                >
                  <img src={Icon} alt="speaker" />
                </button>
              </div>
            </div>
            <div className="b1-style">Brook currently doesn't provide STI home test kits in your area.</div>
            <div className="p3-style p2-style">
              But that doesn't mean you can't get a test from another service. See if there is another provider of STI
              home test kits in your area
            </div>
          </div>
          <div className="">
            <Accordion
              themeName="postcodeBarrier"
              labelBgClass="personel_info"
              panels={[
                {
                  id: 'barrier_id',
                  label: 'WHERE ELSE CAN I GET A TEST?',
                  content: child(),
                },
              ]}
            />
          </div>
          <div className="ft-tab brook-container p-color">
            <button
              name="post_code_barrier"
              id="post_code_barrier"
              className="float-left no-background-border"
              onClick={(e) => GoBackPage()}
            >
              <img src={backIcon} alt="back" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const child = () => {
  const tabIndex = 0
  return (
    <>
      <div className="p3-style p2-style">
        We currently don't provide STI home test kits in your area.
        <br />
        But that doesn't mean you can't get a test from another service.
        <br />
        See if there is another provider of STI home test kits in your area:
        <br />
        <a href="https://www.brook.org.uk/your-life/free-sti-home-testing-kits/" target="_blank">
          https://www.brook.org.uk/your-life/free-sti-home-testing-kits/
        </a>
      </div>
    </>
  )
}
