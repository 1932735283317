import React, { useState } from 'react'

import '../../styles/common.css'
import '../../styles/AdminStyle/adminSettings.css'
import moment from 'moment'

import ArrowIcon from '../../assets/images/back_arrow.png'
import DeleteIcon from '../../assets/images/delete.png'
import Popup from '../../components/admin/popup'
import Loader from '../../components/Loader/loader'
import apiFunctions from '../../services/api-service'

interface MessageDetailsProps {
  selectedObjects?: any
  showDescriptions?: boolean
  callBack?: any
  callDelete?: any
  pageType?: string
}

export const MessageDetails: React.FC<MessageDetailsProps> = ({ selectedObjects, showDescriptions, callBack, pageType, callDelete }) => {
  const tabIndex = 0
  const [showLoader, SetShowLoader] = useState(false)
  const [showDescription, setShowDescription] = useState(showDescriptions)
  const [selectedObject, setSelectedObject] = useState<any>(selectedObjects)
  const [showPopup, setshowPopup] = useState(false)
  const [showdeletePopup, setshowDeletePopup] = useState(false)

  const closeModal = () => {
    setshowPopup(false)
    setshowDeletePopup(false)
  }

  const clickPopup = () => {
    if (pageType === 'bin') {
      callDelete(selectedObject.id)
    } else {
      archiveMessage(selectedObject.id)
    }
  }
  // Archive Message
  const archiveMessage = async (params: any) => {
    SetShowLoader(true)
    const payload = {
      id: [params],
    }
    try {
      await apiFunctions.put(process.env.REACT_APP_SERVICE_BACKEND_URL + `/message/update?type=archive&user_type=${pageType === "inbox" ? 'revice' : 'send'}`, payload)
      callBack()
      SetShowLoader(false)
    } catch (err) {
      SetShowLoader(false)
      console.log('err.response = ', err)
    }
  }
  return (
    <>
      {showLoader && <Loader />}
      {showDescription && (
        <section className="inbox-preview">
          <div className="header">
            <img
              src={ArrowIcon}
              alt="back_arrow"
              onClick={() => {
                callBack()
              }}
            />
            <span className='main-title'>{selectedObject.subject}</span>
          </div>
          <div className="border" />
          <div className="sub-head">
            <h3>{pageType === "inbox" ? (selectedObject.fromUser && selectedObject.fromUser.name) : (selectedObject.toUser && selectedObject.toUser.name)}</h3>
            <div className='email'>
              <p>{pageType === "inbox" ? selectedObject.fromUser && selectedObject.fromUser.email : (selectedObject.toUser && selectedObject.toUser.email)}</p>
              <p>
                {' '}
                {moment(selectedObject.createdAt).format('MMM DD, YYYY  hh:mm A')} (
                {moment(selectedObject.createdAt).fromNow()})
              </p>
            </div>
          </div>
          <div className="border" />
          <div className="body">
            <textarea>{selectedObject.message}</textarea>
          </div>
          <div className='delete'>
            <img src={DeleteIcon} alt="delete" onClick={() => {
              if (pageType === 'bin') {
                setshowDeletePopup(true)
              } else {
                setshowPopup(true)
              }
            }} />
          </div>
        </section>
      )}
      <Popup removeBin={showPopup} deleteBin={showdeletePopup} id={selectedObject.id} clickPopup={clickPopup} close={closeModal} />
    </>
  )
}

export default MessageDetails
