import React, { useCallback, useState } from 'react'

import '../../styles/common.css'
import '../../styles/AdminStyle/adminSettings.css'
import Popup from '../../components/admin/popup';
import { AdminTextArea } from '../../components/admin/TextArea'
import { TriNav } from '../../components/admin/TriNav'
import Loader from '../../components/Loader/loader'
import apiFunctions from '../../services/api-service'
import { AdminLayout } from './AdminLayout'
import { authPriNavMenu, authFeedbackTriNavMenu } from './AdminOptions'
// import Loader from '../../components/Loader/loader';

interface ReportProblemProps { }

export const ReportProblem: React.FC<ReportProblemProps> = ({ }) => {
  const [showLoader, SetShowLoader] = useState(false)
  const [showPopup, setshowPopup] = useState(false)
  const [phoneno, setphoneno] = useState('')
  const [report, SetReport] = useState('')

  const submitFeedback = useCallback(() => {
    SetShowLoader(true);

    apiFunctions
      .put(
        process.env.REACT_APP_SERVICE_BACKEND_URL +
        '/admin/problem?content=' +
        report.replace(/\s+/g, ' ').trim() +
        '&phoneno=' +
        phoneno
      )
      .then((e) => {
        setshowPopup(true)
        setphoneno('')
        SetReport('')
        SetShowLoader(false)
      })
      .catch((err) => {
        SetShowLoader(false)
      })
  }, [report, phoneno]);

  const closeModal = () => {
    setphoneno('')
    SetReport('')
    setshowPopup(false)
    SetShowLoader(false)
  }
  const tabIndex=0;
  return (
    <AdminLayout getMenuList={authPriNavMenu} sideBar="Help">
      {showLoader && <Loader />}
      <div className="admin-login-style">
        <div className="login-style1" tabIndex={tabIndex}>Solve a problem</div>
        <hr />
        <p className="login-style2"tabIndex={tabIndex}>Report a problem</p>
        <div className="">
          <TriNav getMenuList={authFeedbackTriNavMenu} />
        </div>
        <div>
          <div className="col-12 col-sm-10 col-md-6">
            <div className="text-account" tabIndex={tabIndex}> What are you having trouble with today?</div>
            <AdminTextArea type='report' txtcontent={SetReport} value={report} />
            <div className="sec-text-style" tabIndex={tabIndex}>
              {' '}
              The digital team will be notified and we may contact you for more information.
            </div>
            <div className="form-group pad-report">
              <label className="in-style" tabIndex={tabIndex}>Please provide a contact number</label>
              <input aria-label='Contact number' type="text" maxLength={12} className="form-control login-radius" value={phoneno} onChange={(e) => setphoneno(e.target.value)}  />
            </div>

            <div className="my-5">
              <button
                type="button"
                className={`btn btn-primary submit-style login-btn ${(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(phoneno) && phoneno.length > 8 && report.trim().length > 3) ? '' : 'disable'
                  }`}
                onClick={submitFeedback}
              >
                Report problem
              </button>
            </div>
          </div>
        </div>
      </div>
      <Popup report={showPopup} Successtype="report" close={closeModal} />
    </AdminLayout>
  )
}

export default ReportProblem
