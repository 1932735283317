import React, { useState, useEffect } from 'react'

import '../../styles/common.css'
import '../../styles/AdminStyle/adminSettings.css'
import Select, { components } from "react-select";

import { TriNav } from '../../components/admin/TriNav'
import Loader from '../../components/Loader/loader'
import useAuthContext from '../../context/provider/AuthContext'
import apiFunctions from '../../services/api-service'
import { AdminLayout } from './AdminLayout'
import { authPriNavMenu, authMessageTrivNav } from './AdminOptions'

interface MessageProps { }

export const Message: React.FC<MessageProps> = ({ }) => {
  const tabIndex = 0
  const [showLoader, SetShowLoader] = useState(false)
  const [showSuccess, SetShowSuccess] = useState(false)
  const {
    state: { user },
    authDispatch,
  } = useAuthContext()
  const users: any = user
  const [selectedOptions, setSelectedOptions] = useState([])
  const [subObjects, setSubObjects] = useState<any>({
    subject: '',
    message: '',
  })
  const [Optionlists, setOptionlists] = useState<any>([])

  const GetUserList = async () => {
    try {
      const { data } = await apiFunctions.get(process.env.REACT_APP_SERVICE_BACKEND_URL + `/message/get-users`)
      const options = data.data.map((res: any) => {
        return {
          value: res.name,
          label: res.email,
          id: res.id,
        }
      })
      setOptionlists(options)
    } catch (error) {
      console.log('err.response = ', error)
    }

  }
  useEffect(() => {
    GetUserList();
  }, [])

  // Set select optionlists
  const handleSelect = (data: any) => {
    setSelectedOptions(data)
  }
  // Get payload
  const getPayload = () => {
    return selectedOptions.map((res: any) => {
      return {
        from_user: users && users.user && users.user.id,
        to_user: res.id,
        subject: subObjects.subject,
        message: subObjects.message,
        // status: 'active',
        read: false,
      }
    })
  }
  // Sent Messages
  const SendMessage = async () => {
    SetShowLoader(true)
    const payload = getPayload()
    try {
      const data = await apiFunctions.post(process.env.REACT_APP_SERVICE_BACKEND_URL + `/message/send-message`, payload)
      SetShowSuccess(true)
      // setOptionlists([])
      setSelectedOptions([])
      setSubObjects({
        subject: '',
        message: '',
      })
      await GetUserList();
      SetShowLoader(false)
    } catch (err) {
      SetShowLoader(false)
      console.log('err.response = ', err)
    }
  }

  // const searchOptions = [
  //   { value: 'Helen Smith', label: 'helen@gmail.com' },
  //   { value: 'Jeeva', label: 'Jeeva@gmail.com' },
  //   { value: 'Muthu', label: 'muthu@gmail.com' }
  // ];

  const Option = (props: any) => {
    return (
      <div className='option'>
        <components.Option {...props}>
          <p>{props.value}</p>
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const MultiValue = (props: any) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );


  return (
    <AdminLayout getMenuList={authPriNavMenu} sideBar="Message">
      {showLoader && <Loader />}
      <div className="admin-login-style message-blk">
        <div className="login-style2" tabIndex={tabIndex}>
          Messaging
        </div>
        <hr />
        <p className="login-style1" tabIndex={tabIndex}>
          Send and receive messages from Brook colleagues
        </p>
        <div className="">
          <TriNav getMenuList={authMessageTrivNav} />
        </div>
        <div>
          {showSuccess && (
            <div className="success-msg">
              <p>
                Your message has successfully been sent <span onClick={() => SetShowSuccess(false)}>x</span>
              </p>
            </div>
          )}
          <div className="col-12 col-sm-10 col-md-10 mail-draft-blk">
            <div>
              <Select
                closeMenuOnSelect={false}
                components={{ Option, MultiValue }}
                isMulti
                options={Optionlists}
                // loadOptions={Optionlists}
                placeholder='To'
                onChange={handleSelect}
                value={selectedOptions}
              /></div>

            <div className="border" />
            <div>
              <label className="sr-only" htmlFor="subject" tabIndex={tabIndex} />
              <input
                id="subject"
                type="text"
                className="form-control input-w login-radius reduce-mar"
                placeholder="Subject"
                maxLength={100}
                value={subObjects.subject}
                onChange={(event) => {
                  setSubObjects({ ...subObjects, subject: event.target.value.trimStart() })
                }}
              />
            </div>
            <div className="border" />
            <div className="mar-p text-align-left mt-0">
              <textarea
                maxLength={50000}
                className="form-control h-textbox w-txt area-color"
                placeholder="Start typing your message..."
                value={subObjects.message}
                onChange={(event) => {
                  setSubObjects({ ...subObjects, message: event.target.value.trimStart() })
                }}
              />
            </div>
            <button
              type="button"
              className={`btn btn-primary submit-style login-btn ${selectedOptions &&
                selectedOptions.length &&
                subObjects.subject &&
                subObjects.subject.length &&
                subObjects.message &&
                subObjects.message.length
                ? ''
                : 'disabled'
                }`}
              onClick={SendMessage}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </AdminLayout>
  )
}

export default Message
