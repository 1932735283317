import React from 'react'
import '../styles/Adminpopup.css'
// import '../styles/Popupheader.css';

interface PopupHeaderProps {
    Orderkit?: boolean;
    Rejectkit?: boolean;
    ApproveLocal?: any;
}

export const PopupHeader: React.FC<PopupHeaderProps> = (props) => {
    const tabIndex=0;
    return (
        <>

            {props.Orderkit ? (
                <div className='row'>
                    <div className='col-8'>
                        <div className='login-style1 text-align-left' tabIndex={tabIndex}>Order an STI test kit - {props.ApproveLocal}</div>
                    </div>
                </div>
            ) :
                (
                    <div className='row'>
                        <div className='col-8'>
                            <div className='login-style1 text-align-left'tabIndex={tabIndex}>Order an STI test kit - {props.ApproveLocal}</div>
                        </div>
                    </div>)}



            <hr />
        </>
    );
}