import React, { useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import { Button } from '../components/button/Button'
import { STIRadioButton } from '../components/forms/STIRadioButton'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import '../styles/hepatitis.css'
import { stiKitLoaderPercentage } from '../utils/stiKitLoaderPercentage'

const kitOptionList = [
  {
    label: 'I want to do the blood sample for both HIV and syphilis',
    value: 'I want to do the blood sample for both HIV and syphilis',
    subtitle: '<p>Tick this box if you want to do the blood sample for HIV and syphilis at home.</p>',
  },
  {
    label: 'I want to test for syphilis but not HIV',
    value: 'I want to test for syphilis but not hiv',
    subtitle:
      "<p>Tick this box if you are happy to provide a blood sample to test for syphilis but you <strong class='bold-word-text'>do not</strong> want us to use it to test for HIV.</p>",
  },
]

export const EditYourKit = () => {
  const { theme, themeDispatch } = useContext(ThemeContext)
  const history = useHistory()
  const [kitOption, setKitOption] = useState('')

  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker
  const [radioId, setRadioId] = useState()
  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById(`radiobtn ${radioId}`)
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  useEffect(() => {
    // console.log('theme editYourKit :>>', theme);
    // Set the value on question if we have selected the answer already
    if (theme.quesObj) {
      Object.keys(theme.quesObj).map((quesKey) => {
        const idx = kitOptionList.findIndex((element) => element.value === quesKey)
        if (idx !== -1) {
          const selectedItem = kitOptionList[idx]
          setKitOption(selectedItem.value)
        }
      })
    }
    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: stiKitLoaderPercentage.EDIT_YOUR_KIT },
    })
  }, [])

  const handleOnNext = () => {
    if (kitOption) {
      let themeObjClone = theme.quesObj
      Object.keys(themeObjClone).map((quesKey) => {
        const idx = kitOptionList.findIndex((element) => element.value === quesKey)
        if (idx !== -1) {
          const selectedItem = kitOptionList[idx]
          delete themeObjClone[selectedItem.value]
        } else {
          themeObjClone = theme.quesObj
        }
      })
      themeDispatch({
        type: 'SET_QUES',
        payload: {
          quesObj: {
            ...themeObjClone,
            [kitOption]: kitOption ? 'yes' : 'no',
          },
        },
      })
      // history.push('/risk-hepatitis')
      history.push('/hepatitis')

    } else {
      history.push('/test-kit')
    }
    // history.push('/customise-kit')
  }

  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }

  const handleClearSelection = () => {
    if (kitOption) {
      let themeObjClone = theme.quesObj
      Object.keys(themeObjClone).map((quesKey) => {
        const idx = kitOptionList.findIndex((element) => element.value === quesKey)
        if (idx !== -1) {
          const selectedItem = kitOptionList[idx]
          delete themeObjClone[selectedItem.value]
        } else {
          themeObjClone = theme.quesObj
        }
      })
      themeDispatch({
        type: 'SET_QUES',
        payload: {
          quesObj: {
            ...themeObjClone,
          },
        },
      })
    }
    setKitOption('')
  }

  return (
    <div className={`bg-color hepatitis-style ${theme.theme}-global`}>
      <div className="brook-container pad-bot">
        <div className="text-to-speech">
          <div className="h-div mt-4">
            <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
            <div className="speaker-i">
              <button
                className="no-background-border"
                onClick={(e) => {
                  TextToSpeech.getInstance().playVoice('text-to-speech')
                  setisAccessibilityVoiceOver(true)
                }}
              >
                <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
              </button>
            </div>
          </div>
          <div className="hepatitis-content-blk">
            <h1 className={`b1-style  ${theme.theme}-txt-global`}>Edit your kit: blood sample for HIV and syphilis</h1>
            <br />
            <h4 className={`b2-style  ${theme.theme}-txt-global hepatitis-title lh-sm mb-10`}>
              Use the checkboxes below to edit your kit. <br /> If you don't want to make any changes, please leave all
              the boxes empty.
            </h4>
            <div className="sti-kit-radio-blk">
              <div className="pad-tp font-check mb-4">
                {kitOptionList.map((option: any, idx: number) => {
                  return (
                    <STIRadioButton
                      id={`radiobtn ${radioId}`}
                      label={option.label}
                      name={option.label}
                      checked={option.value === kitOption}
                      value={kitOption}
                      key={idx}
                      onChange={() => {
                        setKitOption(option.value)
                        setRadioId(option.id)
                      }}
                      addClass={`b4-style ${theme.theme}-txt-global mb-0`}
                      subTitle={option.subtitle}
                      theme={theme.theme}
                    />
                  )
                })}
                {kitOption ? (
                  <div className="d-flex">
                    <button className="clear-btn-blk" onClick={() => handleClearSelection()}>
                      Clear selection
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
            <div className={`ft-tab brook-container ${theme.theme}-skin-color`}>
              <button className="float-left no-background-border pos-re" onClick={(e) => GoBackPage()}>
                <img src={backIcon} alt="back" />
              </button>
              <Button
                isDisabled={false}
                buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                buttonText="Next"
                onClick={handleOnNext}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
