import React, { useCallback, useContext, useEffect, useState } from 'react'

import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { paragraphBlock, headingBlock, h3Block } from '../components/tinacms'
import { ThemeContext } from '../context/provider/ThemeContext'
import '../styles/welcome.css'
import { stiKitLoaderPercentage } from '../utils/stiKitLoaderPercentage'

interface WelcomeProps {
  Props: any
}

/**
 * welcome page
 * @param { route instance }
 * @returns welcome jsx page
 */
export const Welcome: React.FC<WelcomeProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)

  const [themeName, setThemeName] = useState('Regular')
  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('welcome')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  useEffect(() => {
    if (theme.theme) setThemeName(theme.theme)
    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: stiKitLoaderPercentage.WELCOME },
    })
  }, [])

  const themeChange = useCallback((e: any) => {
    setThemeName(e.target.textContent.split(' ').join('-'))
    themeDispatch({
      type: 'SET_THEME',
      payload: { themeName: e.target.textContent.split(' ').join('-') },
    })
  }, [])

  const proceed = useCallback((e: any) => {
    TextToSpeech.getInstance().playVoice(null, 'stop')
    setisAccessibilityVoiceOver(false)
    // Props.history.push('/preinfo')
    Props.history.push('/postcode')
  }, [])

  const BLOCKS_HEADING = {
    heading: headingBlock,
  }

  const BLOCKS_CONTENT = {
    h3: h3Block,
    paragraph: paragraphBlock,
  }
  return (
    <div className={`bg-color welcome-style ${theme.theme}-global`}>
      <div className="brook-container pad-bot">
        <div className="text-to-speech">
          <div className="h-div mt-4 ">
            <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT </h2>
            <div className="speaker-i">
              <button
                className="no-background-border"
                onClick={(e) => {
                  TextToSpeech.getInstance().playVoice('text-to-speech')
                  setisAccessibilityVoiceOver(true)
                }}
              >
                <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
              </button>
            </div>
          </div>
          <div className={`b1-style ${theme.theme}-txt-global mb-4`}>
            Get a sexual health check-up with a home test kit from Brook.
          </div>
          <div className={`b2-style ${theme.theme}-txt-global`}>
            This is a free service (eligibility criteria apply).
          </div>
          <div className={`b2-style ${theme.theme}-txt-global`}>
            {' '}
            In order to supply and process your test, Brook will need to share your information with The Doctor’s
            Laboratory (TDL). We will ask you to confirm that you are happy with this before submitting your test
            request.
          </div>
          <div className={`b4-style ${theme.theme}-txt-global`}> Select colour mode </div>
          {/* <div className={`b2-style ${theme.theme}-txt-global`}></div> */}
        </div>
        <fieldset className="btn-style">
          <legend className={`b2-style  ${theme.theme}-txt-global border-none text-white`} id="button-group-title">
            Please choose the option that works best for you
          </legend>
          <ul role="group" aria-labelledby="button-group-title" className="btn-list">
            <li>
              <Button
                id="welcome"
                aria-describedby="button-group-title"
                buttonClassName={`btn btn-default btn-d ${theme.theme}-btn-global`}
                buttonText="Regular"
                onClick={themeChange}
                isDisabled={false}
                selected={themeName}
              />
            </li>
            <li>
              <Button
                buttonClassName={`btn btn-default btn-d  ${theme.theme}-btn-global`}
                buttonText="Dark"
                onClick={themeChange}
                isDisabled={false}
                selected={themeName}
              />
            </li>
            <li>
              <Button
                buttonClassName={`btn btn-default btn-d ${theme.theme}-btn-global`}
                buttonText="Calm"
                onClick={themeChange}
                isDisabled={false}
                selected={themeName}
              />
            </li>
            <li>
              <Button
                buttonClassName={`btn btn-default btn-d  ${theme.theme}-btn-global`}
                buttonText="High contrast"
                onClick={themeChange}
                isDisabled={false}
                selected={themeName}
              />
            </li>
          </ul>
        </fieldset>

        <div className={`ft-tab brook-container ${theme.theme}-skin-color jus-end`}>
          <Button
            isDisabled={false}
            buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
            buttonText="Start"
            onClick={proceed}
          />
        </div>
      </div>
      {/* <Chatbot notification={2}/> */}
    </div>
  )
}
