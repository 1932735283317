import axios, { AxiosResponse } from 'axios'
import jwtDecode from 'jwt-decode';

import { history } from '../utils/history';
import appUrls from './AppURLs';


axios.interceptors.response.use(
  (response) =>
    new Promise((resolve, reject) => {
      resolve(response);     
    }),
  (error) => {
    if (!error.response) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
    if (error.response.status === 401) {
      localStorage.clear();
      if (history) {
        history.push(`${appUrls.admin.auth.login}?refresh=true`);
      } else {
        window.location.href = `${appUrls.admin.auth.login}?refresh=true`;
      }
    } 
    else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);


function getAdminHeaders() {
  const sessionAccessToken = sessionStorage.getItem('access-token')
  if (sessionAccessToken) {
    return {
      headers: {
        Authorization: 'Bearer ' + sessionAccessToken,
      },
    }
  }
  return undefined
}

export function setToken(token: string | null, refreshToken: string | null) {
  if (token) {
    localStorage.setItem('access-token', token);   
    // && refreshToken localStorage.setItem('refresh-token', refreshToken);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
}

function getHeaders() {
  const accessToken = localStorage.getItem('access-token')
  if (accessToken) {
    return {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    }
  }
  return undefined
}

const apiFunctions = {
  get: async (url: string): Promise<AxiosResponse['data']> => {
    return await axios.get(url, getHeaders())
  },
  getAdmin: async (url: string): Promise<AxiosResponse['data']> => {
    return await axios.get(url, getAdminHeaders())
  },
  post: async (url: string, data: object): Promise<AxiosResponse['data']> => {
    return await axios.post(url, data, getHeaders())
  },
  put: async (url: string, data?: object): Promise<AxiosResponse['data']> => {
    return axios.put(url, data, getHeaders());
  },
  postAdmin: async (
    url: string,
    data: object
  ): Promise<AxiosResponse['data']> => {
    return await axios.post(url, data, getAdminHeaders())
  },
  putAdmin: async (
    url: string,
    data: object
  ): Promise<AxiosResponse['data']> => {
    return await axios.put(url, data, getAdminHeaders())
  },
  patchAdmin: async (
    url: string,
    data: object
  ): Promise<AxiosResponse['data']> => {
    return await axios.patch(url, data, getAdminHeaders())
  },
  deleteAdmin: async (url: string) => {
    return await axios.delete(url, getAdminHeaders())
  },
}

export function isTokenValid(token: string) {
  try {
    const decoded_jwt: any = jwtDecode(token)
    return decoded_jwt ? decoded_jwt : false // unlimited expiry
  } catch (error) {
    return false
  }
}

// export default axios;
export default apiFunctions
