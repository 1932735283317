import React, { useCallback, useState, useContext } from 'react'

import '../styles/dateofbirth.css'
import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import useAuthContext from '../context/provider/AuthContext'
import { ThemeContext } from '../context/provider/ThemeContext'

interface SuccesspostcodeProps {
  Props: any
}

export const Consent: React.FC<SuccesspostcodeProps> = (Props: any) => {
  const [name, setName] = useState<{ fname: string; lname: string }>({
    fname: '',
    lname: '',
  })
  const {
    state: { globalSettings },
  } = useAuthContext()

  const history = useHistory()

  const { theme, themeDispatch } = useContext(ThemeContext)

  const [consent, setConsnent] = useState<Array<string>>([])
  const [flags, setFlags] = useState<Array<string>>(globalSettings.flags)

  const proceed = useCallback(() => {
    TextToSpeech.getInstance().playVoice(null, 'stop')
    themeDispatch({
      type: 'SET_QUES',
      payload: { quesObj: { ...theme.quesObj, 'Sex Consent': consent } },
    })

    if (consent.length === 1) {
      if (consent.includes('none')) {
        Props.history.push('/addressdes')
        return false
      } else {
        Props.history.push('/consent-barrier')
        return false
      }
    }
    if (consent.length > 1) {
      Props.history.push('/consent-barrier')
      return false
    }

    // if (
    //   consent.length === 1 &&
    //     (!consent.includes('None of the above apply to me') &&
    //       !consent.includes(
    //         'None of the above apply to me, but i would like to talk to someone'
    //       ))
    // ) {
    //   Props.history.push('/consent-barrier');
    //   return false;
    // }

    // if (
    //   consent.length > 2 ||
    //   (consent.length === 2 &&
    //     (!consent.includes('None of the above apply to me') ||
    //       !consent.includes(
    //         'None of the above apply to me, but i would like to talk to someone'
    //       )))
    // ) {
    //   Props.history.push('/consent-barrier');
    //   return false;
    // }

    // if (consent.length <= 2) {
    //   if (
    //     consent.includes('None of the above apply to me') &&
    //     consent.includes(
    //       'None of the above apply to me, but i would like to talk to someone'
    //     )
    //   ) {
    //     Props.history.push('/addressdes')
    //     return false;
    //   }
    // }

    // if (consent.length <= 1) {
    //   if (consent.includes('None of the above apply to me')) {
    //     Props.history.push('/addressdes')
    //     return false;
    //   }
    //   if (consent.includes('None of the above apply to me, but i would like to talk to someone')) {
    //     Props.history.push('/addressdes')
    //     return false;
    //   }
    //   return false;
    // }

    // if (consent.length >= 1) {
    //   if (!consent.includes('None of the above apply to me') && !consent.includes('None of the above apply to me, but i would like to talk to someone')) {
    //     Props.history.push('/consent-barrier');
    //     return false;
    //   }
    // }

    // if (consent.length < 2) {
    //   if (
    //     consent.includes(
    //       'None of the above apply to me, but i would like to talk to someone'
    //     )
    //   ) {
    //     Props.history.push('/finalScreen')
    //   }
    // }
  }, [consent])

  const chooseOption = useCallback(
    (__type: string) => {
      return (e: any) => {
        let __typeOfSex = consent.concat()
        if (e.target.checked) {
          __typeOfSex.push(__type)
        } else {
          __typeOfSex.splice(__typeOfSex.indexOf(__type), 1)
        }
        setConsnent(__typeOfSex)
      }
    },
    [consent]
  )

  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }
  const tabIndex = 0
  return (
    <div className={`bg-color dateofbirth-style ${theme.theme}-skin-color`}>
      <div className="brook-container  consent-blk pad-bot">
        <div className="text-to-speech mb-4">
          <div className="h-div mt-4">
            <h2 className={`page-header-txt ${theme.theme}-txt-global`}>ORDER A TEST KIT</h2>
            <div className="speaker-i">
              <button
                className="no-background-border"
                onClick={(e) => TextToSpeech.getInstance().playVoice('text-to-speech')}
              >
                <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
              </button>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div className="text-to-speech">
            <>
              {globalSettings &&
                globalSettings.flags &&
                globalSettings.flags.map((res: any, index: number) => (
                  <div className="single-row mt-4">
                    <div>
                      <input
                        aria-label={res.name}
                        type="checkbox"
                        id="vehicle1"
                        className="form-check-input check-style"
                        onClick={chooseOption(res.id)}
                      />
                    </div>
                    <div className={`pl-4 mt-2 p2-style f-sty ${theme.theme}-txt-global`}>{res.name}</div>
                  </div>
                ))}
              {globalSettings && globalSettings.flags && globalSettings.flags.length > 0 && (
                <div className="single-row mt-4">
                  <div>
                    <input
                      aria-label="None of the above apply to me"
                      type="checkbox"
                      id="vehicle1"
                      className="form-check-input check-style"
                      onClick={chooseOption('none')}
                    />
                  </div>
                  <div className={`pl-4 mt-2 p2-style f-sty ${theme.theme}-txt-global`}>
                    None of the above apply to me
                  </div>
                </div>
              )}

              {/* <div className="single-row mt-4">
              <div>
                <input
                  type="checkbox"
                  id="vehicle1"
                  className="form-check-input check-style"
                  onClick={chooseOption(
                    'I have been given gifts, money, drugs, alcohol or protection for sex'
                  )}
                />
              </div>
              <div className={`pl-4 mt-2 p2-style f-sty ${theme.theme}-txt-global`}>
                I have been given gifts, money, drugs, alcohol or protection for
                sex
              </div>
            </div>

            <div className="single-row mt-4">
              <div>
                <input
                  type="checkbox"
                  id="vehicle1"
                  className="form-check-input check-style"
                  onClick={chooseOption(
                    'I often drink alcohol or take drugs before having sex'
                  )}
                />
              </div>
              <div className={`pl-4 mt-2 p2-style f-sty ${theme.theme}-txt-global`}>
                I often drink alcohol or take drugs before having sex
              </div>
            </div>
            <div className="single-row mt-4">
              <div>
                <input
                  type="checkbox"
                  id="vehicle1"
                  className="form-check-input check-style"
                  onClick={chooseOption(
                    'I feel down or suffer from feelings of depression'
                  )}
                />
              </div>
              <div className={`pl-4 mt-2 p2-style f-sty ${theme.theme}-txt-global`}>
                I feel down or suffer from feelings of depression
              </div>
            </div>
            <div className="single-row mt-4">
              <div>
                <input
                  type="checkbox"
                  id="vehicle1"
                  className="form-check-input check-style"
                  onClick={chooseOption('My partner is a lot older than me')}
                />
              </div>
              <div className={`pl-4 mt-2 p2-style f-sty ${theme.theme}-txt-global`}>
                My partner is a lot older than me
              </div>
            </div>
            <div className="single-row mt-4">
              <div>
                <input
                  type="checkbox"
                  id="vehicle1"
                  className="form-check-input check-style"
                  onClick={chooseOption('None of the above apply to me')}
                />
              </div>
              <div className={`pl-4 mt-2 p2-style f-sty ${theme.theme}-txt-global`}>
                None of the above apply to me
              </div>
            </div>
            <div className="single-row mt-4">
              <div>
                <input
                  type="checkbox"
                  id="vehicle1"
                  className="form-check-input check-style"
                  onClick={chooseOption(
                    'None of the above apply to me, but i would like to talk to someone'
                  )}
                />
              </div>
              <div className={`pl-4 mt-2 p2-style f-sty mb-3 ${theme.theme}-txt-global`}>
                None of the above apply to me, but i would like to talk to someone
              </div>
            </div> */}
            </>
          </div>
        </div>
        <div>
          <div className={`ft-tab brook-container ${theme.theme}-skin-color`}>
            <button className="float-left no-background-border" onClick={(e) => GoBackPage()}>
              <img src={backIcon} alt="back" />
            </button>
            <Button
              isDisabled={!Boolean(consent.length)}
              buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
              buttonText="Next"
              onClick={proceed}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
