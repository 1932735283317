import React, { useCallback, useContext, useEffect, useState } from 'react'

import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import '../styles/preContact.css'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'

interface SuccesspostcodeProps {
  Props: any
}

export const PreContact: React.FC<SuccesspostcodeProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)

  const proceed = useCallback(() => {
    TextToSpeech.getInstance().playVoice(null, 'stop')
    Props.history.push('/contactUserName')
  }, [])

  useEffect(() => {
    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: '43' },
    })
  }, [])
  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('preContact_id')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  return (
    <div className={`bg-color preContact-style  ${theme.theme}-skin-color`}>
      <div className="brook-container pad-bot">
        <div className="text-to-speech">
          <div className="h-div mt-4">
            <h2 className={`page-header-txt ${theme.theme}-txt-global`}>ORDER A TEST KIT</h2>
            <div className="speaker-i">
              <button
                className="no-background-border"
                onClick={(e) => {
                  TextToSpeech.getInstance().playVoice('text-to-speech')
                  setisAccessibilityVoiceOver(true)
                }}
              >
                <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
              </button>
            </div>
          </div>
          <div className="text-to-speech">
            <div className={`b1-style ${theme.theme}-txt-global`}>
              Nearly there! <br /><br />
            </div>
            <div className={`p2-style mb-4 ${theme.theme}-txt-global pre-contact-content`}>
              Now we need to know where to send the test and how to contact you.
            </div>
            <div className={`p3-style p2-style mb-4 ${theme.theme}-txt-global`}>
              This will also enable us to send your results. This information is only required so that we can send you
              the right type of kit to the right place.
            </div>
          </div>
          <div className={`ft-tab brook-container jus-end ${theme.theme}-skin-color`}>
            <Button
              id="preContact_id"
              isDisabled={false}
              buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
              buttonText="Next"
              onClick={proceed}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
