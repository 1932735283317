import React, { useContext } from 'react';

import { useHistory } from "react-router-dom";

import backIcon from '../assets/images/Back_Button.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import '../styles/symptombarrier.css'
import { Accordion } from '../components/accordian/Accordian';
import { Button } from '../components/button/Button';
import TextToSpeech from '../components/textToSpeech/TextToSpeech';
import { ThemeContext } from '../context/provider/ThemeContext';

interface SymptombarrierProps {
  children: any;
}

export const Symptombarrier: React.FC<SymptombarrierProps> = ({ children }) => {

  const { theme, themeDispatch } = useContext(ThemeContext)

  const history = useHistory();

  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }

  const handleClick = () => {
    const URL = 'https://www.brook.org.uk/find-a-service/'
    window.location.href = URL
    // window.open(URL);
  }

  const tabIndex = 0;
  return (
    <div className='bg-color symptombarrier-style'>
      <div className='brook-container pad-bot'>
        <div className="text-to-speech">
          <div>
            <div className="h-div mt-4">
              <h2 className="page-header-txt" tabIndex={tabIndex}>
                ORDER A TEST KIT
              </h2>
              <div className='speaker-i'>
                <button className="no-background-border" onClick={e =>
                  TextToSpeech.getInstance().playVoice('text-to-speech')
                }>
                  <img src={Icon} alt='speaker' />
                </button>
              </div>
            </div>
            <div className='b1-style' tabIndex={tabIndex}>Brook is unable to provide home test kits if you have symptoms. You should contact your local sexual health service for help.</div>
          </div>
          <div className="">
            <Accordion
              themeName="postcodeBarrier"
              labelBgClass="personel_info"
              panels={[
                {
                  label: 'SEARCH FOR A BROOK CLINIC OR OTHER SEXUAL HEALTH SERVICE NEAR YOU',
                  content: child(),
                },
              ]}
            />
          </div>
          <div>
            {/* <div className='p2-style mb-3'>
            As you have symptoms, you should get in touch with your local sexual health service or GP as soon as possible for help and treatment.
          </div> */}
            <div className="ft-tab brook-container p-color">
              <button
                id={`${theme && theme.age && theme.age < 18 ? 'u_18_symptom_barrier' : '18_symptom_barrier'}`}
                className="float-left no-background-border"
                onClick={e => GoBackPage()}
              >
                <img src={backIcon} alt="back" />
              </button>
              <Button
                id="u_16_completion"
                isDisabled={false}
                buttonClassName="btn btn-default btn-main"
                buttonText="Finish"
                onClick={(e) => handleClick()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const child = () => {
  const tabIndex = 0;
  return (
    <>
      <div className="p3-style p2-style">
        <p tabIndex={tabIndex}> You can use our Find a Service tool to search for a service near you:  <a href="https://www.brook.org.uk/find-a-service" target="_blank">https://www.brook.org.uk/find-a-service</a></p>
      </div>
    </>
  )
}
