import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import ReactDOM from 'react-dom'

import './styles/index.scss'
import App from './App'
import siteConfig from './config/config';
import * as serviceWorker from './serviceWorker'

const msalInstance = new PublicClientApplication({
  auth:{
      clientId: siteConfig.sso.appId,
      redirectUri:siteConfig.sso.redirectUrl,
      authority:siteConfig.sso.auhthority
  },
})
ReactDOM.render(
  <React.StrictMode>
     <MsalProvider instance={msalInstance}>
        <App />
     </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
