import React, { useState, useEffect } from 'react'

import '../../styles/common.css'
import '../../styles/AdminStyle/adminSettings.css'
import moment from 'moment'

import DeleteIcon from '../../assets/images/delete.png'
import RestoreIcon from '../../assets/images/restore.png'
import Popup from '../../components/admin/popup'
import { TriNav } from '../../components/admin/TriNav'
import Loader from '../../components/Loader/loader'
import Pagination from '../../components/pagination/pagination'
import useAuthContext from '../../context/provider/AuthContext'
import apiFunctions from '../../services/api-service'
import { AdminLayout } from './AdminLayout'
import { authMessageTrivNav, authPriNavMenu } from './AdminOptions'
import MessageDetails from './MessageDetails'

interface BinProps { }

export const Bin: React.FC<BinProps> = ({ }) => {
  const tabIndex = 0
  const {
    state: { user },
    authDispatch,
  } = useAuthContext()
  const users: any = user
  const [showLoader, SetShowLoader] = useState(true)
  const [showDescription, setShowDescription] = useState(false)
  const [pagenum, setPageNum] = useState(1)
  const [inc, setinc] = useState(0)
  const [messagesList, setMessageList] = useState<any>([])
  const [messages, setMessage] = useState<any>([])
  const [selectedObject, setSelectedObject] = useState<any>()
  const [selectedId, setSelectedId] = useState<any>()

  // Get user messages
  const getUserMessage = async () => {
    try {
      SetShowLoader(true)
      const { data } = await apiFunctions.get(process.env.REACT_APP_SERVICE_BACKEND_URL + `/message/get?type=bin`)
      const result = data.data && data.data.Messages ? data.data.Messages : []
      setMessageList(result)
      sliceFunction(result)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getUserMessage()
  }, [inc])

  const sliceFunction = (result: any) => {
    let _arr: any = []
    _arr = result?.concat()
    _arr = _arr.splice(inc, inc + 5 > result?.length ? inc + (result?.length % 5) : 5)
    setMessage(_arr);
    SetShowLoader(false)
  }
  // Update Message ReadStatus
  const DeleteTrashMessage = async (params: any, type = '') => {
    SetShowLoader(true)
    const payload = {
      id: [params],
    }
    try {
      const update = await apiFunctions.put(
        process.env.REACT_APP_SERVICE_BACKEND_URL + `/message/delete?type=${type}`,
        payload
      )
      await getUserMessage()
      setshowPopup(false);
      setShowRestore(false)
      SetShowLoader(false);

    } catch (err) {
      SetShowLoader(false)
      console.log('err.response = ', err)
    }
  }

  const GetTime = (date: any) => {
    const differents = moment().diff(date, 'hours')
    return differents > 24 ? moment(date).format('DD MMM') : moment(date).format('hh:mm A')
  }

  //  Call Back function
  const callback = async () => {
    setShowDescription(false)
    setSelectedObject('')
    await getUserMessage()
  }
  const callDelete = (params: any) => {
    DeleteTrashMessage(params, showPopup ? 'delete' : 'active');
    setShowDescription(false)
  }

  const [showPopup, setshowPopup] = useState(false)
  const [showRestore, setShowRestore] = useState(false)

  const closeModal = () => {
    if (showPopup) {
      setshowPopup(false)
    }
    if (showRestore) {
      setShowRestore(false)
    }
  }

  const clickPopup = (id: any) => {
    setshowPopup(true)
    setSelectedId(id);
  }

  const clickRestorePopup = (id: any) => {
    setShowRestore(true);
    setSelectedId(id);

  }
  return (
    <AdminLayout getMenuList={authPriNavMenu} sideBar="Message">
      {showLoader && <Loader />}
      <div className="admin-login-style message-blk">
        <div className="login-style2" tabIndex={tabIndex}>
          Messaging
        </div>
        <hr />
        <p className="login-style1" tabIndex={tabIndex}>
          Send and receive messages from Brook colleagues
        </p>
        <div className="">
          <TriNav getMenuList={authMessageTrivNav} />
        </div>
        <section className="inbox-blk bin-blk">
          {!showDescription && <div className="mail-card">
            {messages &&
              messages.map((item: any) => (
                <div className="card" onClick={() => {
                  setShowDescription(true)
                  setSelectedObject(item)
                }}>
                  <div className='d-flex' >
                    <div onMouseDown={(e) => {
                      clickPopup(item.id);
                      e.stopPropagation();
                    }}>
                      <img src={DeleteIcon} alt="delete" />
                    </div>
                    <div className='restore-blk' onMouseDown={(e) => {
                      clickRestorePopup(item.id);
                      e.stopPropagation();
                    }}>
                      <img src={RestoreIcon} alt="restore" />
                      {/* <div className='sub-tool'>Restore</div> */}
                    </div>
                  </div>
                  <p>
                    {users && users.user && users.user.id === (item.fromUser && item.fromUser.id) ? (item.toUser && item.toUser.name) : (item.fromUser && item.fromUser.name)}
                  </p>
                  <p>{item.subject} - <span>{item.message}</span></p>
                  <p>{GetTime(item.createdAt)}</p>
                </div>
              ))}
            {messagesList?.length === 0 && <div className="no-data">No records found</div>}
          </div>
          }
          {showDescription && (
            <MessageDetails selectedObjects={selectedObject} showDescriptions={showDescription} callBack={callback} callDelete={callDelete} pageType="bin" />
          )}
        </section>
        <div>
          <Pagination
            callback={function (__param: number): void {
              console.log('pagination added')
              setPageNum(__param)
              setinc(__param * 5 - 5)
            }}
            inc={inc}
            length={messagesList?.length}
          />
        </div>
        {/* {messagesList?.length > 0 && (
          <div className="empty-bin">
            <p>
              Messages that have been in the trash for more than 30 days will be deleted automatically or you can{' '}
              <span onClick={() => DeleteTrashMessage('', 'all')}>empty your bin</span> now
            </p>
          </div>
        )} */}
      </div >
      <Popup deleteBin={showPopup} id={selectedId} clickPopup={callDelete} close={closeModal} />
      <Popup restoreBin={showRestore} id={selectedId} clickPopup={callDelete} close={closeModal} />
    </AdminLayout >
  )
}

export default Bin
