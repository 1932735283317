import React, { useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import '../styles/hepatitis.css'
import '../styles/typeofsex.css'
import { stiKitLoaderPercentage } from '../utils/stiKitLoaderPercentage'

interface CustomiseKitTwoProps { }

export const CustomiseKitTwo: React.FC<CustomiseKitTwoProps> = () => {
  const { theme, themeDispatch } = useContext(ThemeContext)
  const history = useHistory()
  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('checkBoxId')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  useEffect(() => {
    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: stiKitLoaderPercentage.CUSTOMISE_YOUR_KIT },
    })
  }, [])

  const handleOnNext = () => {
    let themeObjClone: any = theme.quesObj
    // if (Object.prototype.hasOwnProperty.call(theme.quesObj, CUSTOMISE_KIT_KEY)) {
    //   delete themeObjClone[CUSTOMISE_KIT_KEY]
    // }
    // if (internalState.isThroatAndRectalSwab) {
    //   themeDispatch({
    //     type: 'SET_QUES',
    //     payload: {
    //       quesObj: {
    //         ...themeObjClone,
    //         [CUSTOMISE_KIT_KEY]: 'yes',
    //       },
    //     },
    //   })
    // }
    // history.push('/preContact')
    history.push('/edit-kit')
  }

  const GoBackPage = () => {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }

  return (
    <div className={`bg-color hepatitis-style ${theme.theme}-global`}>
      <div className="brook-container pad-bot">
        <div className="text-to-speech">
          <div className="h-div mt-4">
            <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
            <div className="speaker-i">
              <button
                className="no-background-border"
                onClick={(e) => {
                  TextToSpeech.getInstance().playVoice('text-to-speech')
                  setisAccessibilityVoiceOver(true)
                }}
              >
                <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
              </button>
            </div>
          </div>
          <div className="hepatitis-content-blk">
            <h1 className={`b1-style ${theme.theme}-txt-global`}>Edit your kit: blood sample for HIV and syphilis</h1>
            <h4 className={`b4-style ${theme.theme}-txt-global hepatitis-subtitle mb-3`}>IMPORTANT:</h4>
            <h5 className={`b2-style ${theme.theme}-txt-global hepatitis-subtitle mb-3`}>
              The window periods (time before an infection can be accurately diagnosed) are longer for HIV and syphilis
              than they are for chlamydia and gonorrhoea.
            </h5>
            <h5 className={`b2-style ${theme.theme}-txt-global hepatitis-subtitle mb-3`}>
              It can take 7 weeks to HIV to show accurately in tests, and up to 12 weeks for syphilis. You should only
              order these tests if it has been at least 7 weeks since you think you may have been at risk.
            </h5>
            <h5 className={`b2-style ${theme.theme}-txt-global hepatitis-subtitle mb-3`}>
              If you think you may have been exposed to HIV in the past 72 hours (3 days) you might be able to get a
              treatment called PEP to stop the virus taking hold. More information about PEP:
              <br />
              <a className="text-white underline" href="https://www.brook.org.uk/your-life/prep-and-pep/#what-is-pep">
                https://www.brook.org.uk/your-life/prep-and-pep/#what-is-pep
              </a>
            </h5>
            <div className={`ft-tab brook-container ${theme.theme}-skin-color`}>
              <button className="float-left no-background-border pos-re" onClick={(e) => GoBackPage()}>
                <img src={backIcon} alt="back" />
              </button>
              <Button
                buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                buttonText="Next"
                onClick={handleOnNext}
                isDisabled={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
