import React, { useState, useEffect } from 'react'

import '../styles/Adminpopup.css'
import { useFormik } from 'formik'
import _ from 'lodash'
import moment from 'moment'
import DatePicker from "react-datepicker";
import { useLocation } from 'react-router-dom'
import Select, { components } from "react-select";
import * as Yup from 'yup'

import addIcon from '../../assets/images/add_icon.svg'
import BLogo from '../../assets/images/brook_logo_new.svg'
import calenderIcon from '../../assets/images/calender.svg'
import clockIcon from '../../assets/images/clock.svg'
import dislikebgIcon from '../../assets/images/dislike_bg.svg'
import dropdownIcon from '../../assets/images/dropdown.svg'
import infoIcon from '../../assets/images/info_icon.svg'
import likebgIcon from '../../assets/images/like_bg.svg'
import Pdf_footer_text_img from '../../assets/images/pdf_footer_text.png'
import closeIcon from '../../assets/images/Popup_close.svg'
import closeWhiteIcon from '../../assets/images/Popup_close_white.svg'
import Twitter_Logo from '../../assets/images/social-twitter.svg'
import useAuthContext from '../../context/provider/AuthContext'
import { pdf_html } from '../../pdf_templates/export_pdf';
import apiFunctions from '../../services/api-service'
import { logout } from '../../services/api/AuthAPI'
import { getPostcodeRegion } from '../../services/api/PostcodesAPI'
import { contactAttempts, resultOptions } from '../Icons/imageSelect'
import Loader from '../Loader/loader'
import { AdminHistory } from './AdminHistory'
import { ApprovePopup } from './ApprovePopup'
import { AdminCheckbox } from './Checkbox'
// import {getFlags} from './enums';
import { Flags } from './Flags'
import { PopupHeader } from './PopupHeader'
import { RejectPopup } from './RejectPopup'
import { TimePick } from './TimePicker'



const html2pdf = require("html2pdf.js")

// import { StaticTriNav } from './StaticTrivNav';

export interface PopupProps {
  archiveList?:any,
  showRequest?: boolean
  userdata?: any
  RequestChange?: boolean
  ForgotPassword?: boolean
  Logout?: boolean
  showNewRoleRequest?: boolean
  showAdminsuccess?: boolean
  removeRole?: boolean
  userProfile?: boolean
  successpopup?: boolean
  profileSuccess?: boolean
  approveKit?: boolean
  showAdminProfile?: boolean
  rejectKit?: boolean
  ApproveRejectUser?: any
  report?: any
  Successtype?: any
  removebrookRole?: any
  removeBin?: any
  deleteBin?: any
  restoreBin?: any
  Archivepopup?: any
  Contactpopup?: any
  contactInfo?: any
  clickPopup?: any;
  id?: any;
  closeProfile?: (res: any) => void
  callback?: (res: any) => void
  close: (res: any) => void
}
const validationSchemanInvite = Yup.object({
  invite_email: Yup.string().email('Please enter a valid email address').required('Email is required'),
})
const validationSchema = Yup.object({
  request: Yup.string().required('This field is required'),
})

const errorIntialState = {
  status: false,
  message: '',
}
const Popup: React.FC<PopupProps> = (props) => {
  const [showLoader, SetShowLoader] = useState(false)
  const [newroletext, CreateNewRoleText] = useState('')
  const [startDate, setStartDate] = useState<any>()
  const [StaticTriNavMenu, setStaticTriNavMenu] = useState([
    {
      label: 'Profile',
      isActive: true,
    },
    {
      label: 'History',
      isActive: false,
    },
  ])

  const [showerror, setshowerror] = useState(false)
  const [errorMsg, seterrormsg] = useState('')
  const [activeTab, setActiveTab] = useState('Profile')
  const [error, setError] = useState(errorIntialState)
  const {
    state: { user, globalSettings },
    authDispatch,
  } = useAuthContext()

  // Permission handle
  const dataval: any = user;

  let permessionAccess = dataval?.user ? dataval.user:dataval;

  let permessionAccessArchive = dataval?.user ? dataval.user:dataval;

  let permessionAccessAprRej = dataval?.user ? dataval.user:dataval;

  permessionAccess = permessionAccess && permessionAccess.permissions?.team_members?.filter((ele:any)=>{return ele.name === 'manage_team_member_location'})
 
  permessionAccessArchive = permessionAccessArchive && permessionAccessArchive.permissions?.team_members?.filter((ele:any)=>{return ele.name === 'archive_team_member'})

  permessionAccessAprRej = permessionAccessAprRej && permessionAccessAprRej.permissions?.orders_and_results?.filter((ele:any)=>{return ele.name === 'approve/reject'})


  const location = useLocation();


  const [ContactAproveReject, setContactAproveReject] = useState(false)
  const [ApproveSuccess, setApproveSuccess] = useState(false)
  const [RegionSuccess, setRegionSuccess] = useState(false)
  const [RejectKitPopup, setRejectKitPopup] = useState(false)
  const [ArchiveAdminPopup, setArchiveAdminPopup] = useState(false)
  const [UnArchiveAdminPopup, setUnArchiveAdminPopup] = useState(false)
  const [ArchiveeSuccess, setArchiveeSuccess] = useState(false)
  const [unArchiveeSuccess, setunArchiveeSuccess] = useState(false)
  const [userReason, setuserReason] = useState('')
  const [ApprovedBy, setApprovedBy] = useState('')
  const [ApprovedRejDate, setApprovedRejDate] = useState('')
  const [userEthnicity, setuserEthnicity] = useState('')
  const [userGeneticals, setUserGeneticals] = useState('')
  const [userGender, setUserGender] = useState('')
  const [upGradeSuperadmin, setupGradeSuperadmin] = useState(false)
  const [addRegionPopup, setaddRegionPopup] = useState(false)
  const [addAttemptBlk, setaddAttemptBlk] = useState(false)
  const [regionListSelect, setRegionListSelect] = useState([])
  const [selectedRegion, setSelectedRegion] = useState<any>(null)
  const [FlagReasons, setFlagReasons] = useState('')
  const [selectedOption, setSelectedOption] = useState(null);
  const [attemptWarning, setAttemptWarning] = useState(false)
  const [attemptArray, setAttemptArray] = useState<any>([{
    contact_type: '',
    date: moment().format("YYYY-MM-DD"),
    time: moment().format("HH:mm"),
    status: "",
  }]);
  const [resultnotes, setResultNotes] = useState<any>([]);

  const [permission, setPermission] = useState(false)
  const [Contactpopups, setContactpopups] = useState(false)
  const [approveStatus, setApproveStatus] = useState(false)
  const [rejectStatus, setRejectStatus] = useState(false)
  const [showColorResult, setShowColorResult] = useState<any>(null)

  const roletype = String(localStorage.getItem('role'));

  const { Option } = components;
  // const IconOption = (props: any) => {
  //   console.log("proppppp :", props);
  //   return (
  //     <Option {...props}>
  //       <img
  //         src='https://reactnative.dev/img/tiny_logo.png'
  //         style={{ width: 36 }}
  //       />
  //       {props.data.label}
  //     </Option>
  //   )
  // };
  let {
    archiveList,
    showRequest,
    userdata,
    RequestChange,
    ForgotPassword,
    showNewRoleRequest,
    Logout,
    callback,
    closeProfile,
    userProfile,
    removeRole,
    showAdminsuccess,
    successpopup,
    profileSuccess,
    approveKit,
    showAdminProfile,
    rejectKit,
    ApproveRejectUser,
    report,
    Successtype,
    removebrookRole,
    removeBin,
    deleteBin,
    restoreBin,
    Archivepopup,
    Contactpopup,
    contactInfo,
    id
  } = props

  const userval: any = contactInfo;



  function compareString(a: any, b: any) {

    // converting to uppercase to have case-insensitive comparison
    const s1 = a.createdAt.toUpperCase();
    const s2 = b.createdAt.toUpperCase();

    let comparison = 0;

    if (s1 > s2) {
      comparison = -1;
    } else if (s1 < s2) {
      comparison = 1;
    }
    return comparison;
  }
  const exportPDF = () => {
    const userdetails = userdata?.data?.data?.findExisting;
    SetShowLoader(true)
    if (userdetails && userdetails.result && userdetails.result.length > 0) {
      // SetShowLoader(true) // Enable loader
      const first_result = userdetails.result.find((x: any) => x.result_stage === 'active')
      let d = new Date()
      let datastring = moment(d).format('DD MMMM  YYYY HH:mm')
      d = new Date(Date.parse(first_result.updatedAt))
      let received_datestring = moment(d).format('DD/MM/YYYY HH:mm')

      let site_code_string = userdetails.tdl_site_code
        ? userdetails.tdl_site_code
        : userdetails.postcodes && userdetails.postcodes.site_code

      let dob_string = (userdetails.date_of_birth
        ? moment(userdetails.date_of_birth).format('DD/MM/YYYY')
        : '') + ' (age ' + userdetails.age + ')'

      const templateData = {
        report_date: datastring,
        reference_no: userdetails.referenceNo.includes('MOL') ? userdetails.referenceNo : '',
        name: userdetails.first_name + ' ' + userdetails.last_name,
        genitals: userGeneticals,
        gender: userGender,
        phone: userdetails.phone_no,
        brook_service: userdetails.local_authority,
        site_code: site_code_string,
        lab_reference_no: userdetails.lab_reference_no,
        received_date: received_datestring,
        age: dob_string,
        active_results: userdetails.result.filter((x: any) => x.result_stage === 'active'),
        inactive_results: userdetails.result.filter((x: any) => x.result_stage === 'inactive'),
        logo: BLogo,
        twitter_logo: Twitter_Logo,
        pdf_footer_text: Pdf_footer_text_img,
      }
      let curr_date = new Date()
      let downloaded_date = moment(curr_date).format('DDMMYYYY');
      let file_name_format = userdetails.first_name + userdetails.last_name +'_result_'+ downloaded_date;
      let _html = _.template(pdf_html.toString())
      let html_value = _html(templateData)
      let options = {
        // margin: 1,
        filename: `${file_name_format}.pdf`,
        html2canvas: {
          dpi: 192,
          letterRendering: true,
          allowTaint: true,
          useCORS: true,
          logging: false,
          scrollX: 0,
          scrollY: 0,
          scale: 2,
        },
        jsPDF: {
          orientation: 'portrait',
          unit: 'cm',
          format: 'a4',
          autosize: false,
        },
        pagebreak: { mode: 'avoid-all', before: '#page2el' },
      }
      html2pdf().set(options).from(html_value).toPdf().save()
      SetShowLoader(false)
    }
  }

  const toggleTab = (event: any) => {
    event.stopPropagation()
    event.preventDefault()
    let menu: any = StaticTriNavMenu.find((res) => res.label === event.target.innerText)
    StaticTriNavMenu.map((res) => (res.isActive = false))
    menu['isActive'] = true
    setStaticTriNavMenu([...StaticTriNavMenu])
    setActiveTab(event.target.innerText)
  }

  const intialValue1 = {
    request: '',
    invite_note: '',
  }
  const formik1 = useFormik({
    initialValues: intialValue1,
    validationSchema,
    async onSubmit(values: any, { resetForm }) {
      try {
        SetShowLoader(true)
        let payload = {
          email: values.request,
          notes: values.invite_note,
        }
        if (!/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(payload.email)) {
          setError({ status: true, message: 'Please enter valid email' })
          const timer = setTimeout(() => {
            setError({ status: false, message: '' })
            formik1.values.request = null
          }, 4000);
          return ''

        }
        await apiFunctions
          .post(process.env.REACT_APP_SERVICE_BACKEND_URL + '/auth/invite-new-admin', payload)
          .then((_result) => {
            setError({ status: false, message: '' })
            resetForm()
            closesuccess(true)
            SetShowLoader(false)
          })
          .catch((err) => {
            SetShowLoader(false)
            setError({ status: true, message: err.response.data.message })
            const timer = setTimeout(() => {
              setError({ status: false, message: '' })
              formik1.values.request = null
            }, 4000);
          })
      } catch (error: any) {
        SetShowLoader(false)
        setError({ status: true, message: error.response.data.message })
        const timer = setTimeout(() => {
          setError({ status: false, message: '' })
          formik1.values.request = null
        }, 4000);
      }
    },
  })

  const intialValue = {
    request: '',
    notes: '',
  }

  const formik = useFormik({
    initialValues: intialValue,
    validationSchema,
    async onSubmit(values: any, { resetForm }) {
      try {
        SetShowLoader(true)
        let payload = {
          user_id: userdata && userdata.user_id ? userdata.user_id : '',
          change_role_note: values.notes,
        }
        await apiFunctions
          .put(process.env.REACT_APP_SERVICE_BACKEND_URL + '/auth/update-change-request', payload)
          .then((_result) => {
            setError({ status: false, message: '' })
            closesuccess(true)
            resetForm()
            SetShowLoader(false)
          })
          .catch((err) => {
            SetShowLoader(false)
            setError({ status: true, message: err.response.data.message })
          })
      } catch (error: any) {
        SetShowLoader(false)
        setError({ status: true, message: error.response.data.message })
      }
    },
  })

  async function requestPassword() {
    SetShowLoader(true)
    let payload = {
      email: userdata && userdata.email ? userdata.email : '',
    }
    await apiFunctions
      .put(process.env.REACT_APP_SERVICE_BACKEND_URL + '/auth/user-forgot-password-request', payload)
      .then((_result) => {
        SetShowLoader(false)
        if (callback) callback(_result)
      })
      .catch((err) => {
        SetShowLoader(false)
      })
  }
  function Capitalize(str: any) {
    str = str.split(' ')
    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
    }
    const CapitalLetter = str.join(' ')
    return CapitalLetter
  }
  const createNewRole = async (__param: string) => {
    SetShowLoader(true)
    await apiFunctions
      .put(process.env.REACT_APP_SERVICE_BACKEND_URL + '/admin/newRoleRequest', { name: Capitalize(__param) })
      .then((_result) => {
        window.location.reload()
      })
      .catch((err) => {
        SetShowLoader(false)
        seterrormsg(
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Something went wrong. Please try again.'
        )
        setshowerror(true)
        const timer = setTimeout(() => {
          seterrormsg('')
          formik1.values.request = null
        }, 4000);
      })
  }

  const closesuccess = (propsval: any) => {
    if (callback) callback('success')
  }
  const showcallback = (result: any) => {
    if (result === 'unarchive') {
      setUnArchiveAdminPopup(true)
      return
    }
    if (result === 'archive') {
      setArchiveAdminPopup(true)
      return
    }
    if (result === 'approve') {
      setApproveSuccess(true)
      if (attemptArray.every((obj: any) => obj.contact_type && obj.date && obj.time && obj.status)) {
        saveContactAttempt();
      }
      return
    }
    setRejectKitPopup(true)
    if (attemptArray.every((obj: any) => obj.contact_type && obj.date && obj.time && obj.status)) {
      saveContactAttempt();
    }
  }
  async function ArchiveAdmin(unarchive: any) {
    SetShowLoader(true)
    let payload = {
      id: userdata.id,
      archiveRequest: unarchive ? 'start' : 'approved',
      type: 'adminarchive',
    }
    await apiFunctions
      .put(process.env.REACT_APP_SERVICE_BACKEND_URL + '/auth/archive-request', payload)
      .then((_result) => {
        if (unarchive) {
          setunArchiveeSuccess(true)
          return
        }
        setArchiveeSuccess(true)
        SetShowLoader(false)
      })
      .catch((err) => {
        SetShowLoader(false)
      })
  }

  const [approvedOrReject, setApprovedOrReject] = useState('')
  // handle onChange event of the dropdown
  const handleChange = (e: any) => {
    setSelectedOption(e);
  }
  async function getRegion() {
    try {
      const regionList = await getPostcodeRegion()
      if (regionList) {
        let modSelectRegionList = regionList.data.data.map((region: string) => {
          return { label: region, value: region }
        })
        setRegionListSelect(modSelectRegionList)
      }
    } catch (error) {
      console.log('regionListError', error)
    }
  }

  useEffect(() => {
    if (Contactpopup) {
      setContactpopups(Contactpopup)
    }
    if (approveKit) {
      setApproveStatus(approveKit)
    }
    if (rejectKit) {
      setRejectStatus(rejectKit)
    }
    if ((userProfile && userdata) || ApproveRejectUser !== undefined) {

      let userArr = ApproveRejectUser !== undefined ? ApproveRejectUser : userdata
      let reason: any = []
      if (userArr.flags || userArr.age < 16) {
        if (userArr.age < 16) {
          reason.push('age')
        }
        globalSettings &&
          globalSettings.flags &&
          globalSettings.flags.map((e: any) => {
            if (userArr.flags.includes(e.id)) {
              reason.push(e.flags)
            }
          })
      }
      setFlagReasons(reason.toString())
      setUserprofile()
    }
  }, [userProfile, userdata, ApproveRejectUser])

  useEffect(() => {
    if (addRegionPopup) {
      getRegion()
    }
    if (Contactpopup) {
      getUserAttemptInfo();
    }
  }, [addRegionPopup])

  // GET User Attempt Information
  async function getUserAttemptInfo() {
    SetShowLoader(true)
    try {
      await apiFunctions
        .get(process.env.REACT_APP_SERVICE_BACKEND_URL + `/admin/get-contact-attempt?user_id=${userval.id}`)
        .then((_result) => {
          const result = _result.data.data;
          if (_result.data.data.length > 0) {
            const resslutFinal = result.map((res: any) => {
              res.edit = res.status === 'awaiting response' ? true : false;
              return res;
            });

            setAttemptArray(resslutFinal);
          }
          SetShowLoader(false)
          setError({ status: false, message: '' })
          closesuccess(true)
        })
        .catch((err) => {
          SetShowLoader(false)
          setError({ status: true, message: err.response.data.message })
          const timer = setTimeout(() => {
            setError({ status: false, message: '' })
          }, 4000);
        })
    } catch (error: any) {
      SetShowLoader(false)
      setError({ status: true, message: error.response.data.message })
      const timer = setTimeout(() => {
        setError({ status: false, message: '' })
      }, 4000);
    }
  }
  function setUserprofile() {
    if (
      userdata &&
      userdata.data &&
      userdata.data.data &&
      userdata.data.data.findExisting &&
      userdata.data.data.findExisting.question_response
    ) {
      userdata.data.data.findExisting.question_response.map((val: any) => {
        if (val?.question?.question_text === 'Ethnicity') {

          let location = JSON.parse(val.answer)
          // Remove the space of objects front
          const updatedJsonObject:any = {};
          for (const key in location) {
            const updatedKey = key.trim(); // Remove leading and trailing spaces from the key
            updatedJsonObject[updatedKey] = location[key];
          }

          setuserEthnicity(updatedJsonObject.ethnicity + '(' + updatedJsonObject.ethnicity_sub + ')')
        }
      })
      userdata.data.data.findExisting.question_response.map((val: any) => {
        if (val?.question?.question_text === 'What genitals do you have?') {
          setUserGeneticals(val.answer)
        }
      })
    }
    if (
      userdata &&
      userdata.data &&
      userdata.data.data &&
      userdata.data.data.findExisting &&
      userdata.data.data.findExisting.question_response
    ) {
      userdata.data.data.findExisting.question_response.map((val: any) => {
        if (val?.question?.question_text === 'What best describes your gender') {
          setUserGender(val.answer)
        }
      })
    }

    let action =
      userdata &&
        userdata.data &&
        userdata.data.data &&
        userdata.data.data.findExisting &&
        userdata.data.data.findExisting.age > 17
        ? userdata.data.data.findExisting.form_source === 'tdl'
          ? '-'
          : 'Approved (auto)'
        : ''

    let ApprovedName = ''
    let reason = ''
    let ApprejDate = ''
    if (
      (userdata &&
        userdata.data &&
        userdata.data.data &&
        userdata.data.data.findExisting &&
        userdata.data.data.findExisting.age === 16) ||
      (userdata &&
        userdata.data &&
        userdata.data.data &&
        userdata.data.data.findExisting &&
        userdata.data.data.findExisting.age === 17)
    ) {
      let __i = _.find(userdata.data.data.q, { question_text: 'Sex Consent' })
      if (__i) {
        let __j = _.find(userdata.data.data.qr, { question_id: __i.id })
      }
    }
    setuserReason(reason)
    setApprovedOrReject(action)
    setApprovedBy(ApprovedName)
    setApprovedRejDate(ApprejDate)
    if (
      userdata &&
      userdata.data &&
      userdata.data.data &&
      userdata.data.data.findExisting &&
      userdata.data.data.findExisting.history.length
    ) {
      userdata.data.data.findExisting.history.map((val: any) => {
        if (val.action === 'approve') {
          ApprovedName = val.administrator.name
          action = 'Approved'
          reason = val.reason === null ? '' : val.reason
          ApprejDate = moment(val.createdAt).format('DD/MM/YYYY')
        }
        if (val.action === 'reject') {
          ApprejDate = moment(val.createdAt).format('DD/MM/YYYY')
          ApprovedName = val.administrator.name
          action = 'Rejected'
          reason = FindResons(val.reason)
        }
      })
      setuserReason(reason)
      setApprovedOrReject(action)
      setApprovedBy(ApprovedName)
      setApprovedRejDate(ApprejDate)
    }

    if(userdata?.data?.data?.findExisting?.result.length >= 1){
      const ds = userdata.data.data.findExisting.result.filter((val:any) => {
        return val.result_stage === 'active'
      })
      setResultNotes(ds[0].notes)
    }
  }

  function FindResons(resons: any) {
    let myArray = resons.split(',')
    let finalArr: any = []
    let Reasonshow: any = []
    let ReasonsArr = [
      { age: 'User’s age' },
      { protection: 'User has been given gifts, money, drugs, alcohol or protection for sex' },
      { depression: 'User is Worried about their low mood' },
      { older_partner: 'User’s partner is much older than them' },
      { drugs: 'User is worried their use of Alcohol or drugs' },
      {
        unsafe_person:
          'User Feels their is someone is their life who makes them feel as an unsafe person',
      },
      {
        unsafe_place: 'User doesn’t Feel safe at home/Where the User lives',
      },
      {
        coercion: 'User is being made to do something that could put them in danger',
      },
    ]
    ReasonsArr.map((val1: any) => {
      for (let i = 0; i < myArray.length; i++) {
        finalArr.push(val1[myArray[i]])
      }
    })
    finalArr.map((val2: any) => {
      if (val2 !== undefined) {
        Reasonshow.push(val2)
      }
    })
    return Reasonshow.join()
  }
  async function DeleteBrookRole(roleId: any) {
    SetShowLoader(true)
    await apiFunctions
      .put(process.env.REACT_APP_SERVICE_BACKEND_URL + '/admin/update-brook-roles', { id: roleId })
      .then((_result) => {
        SetShowLoader(false)
        window.location.reload()
      })
      .catch((err) => {
        SetShowLoader(false)
      })
  }

  async function ArchiveUserInfo() {
    let archiveUser = archiveList && archiveList.filter((res:any)=>res.checked);
    if (archiveUser && archiveUser.length > 0) {   /* Archive using checkbox */
      SetShowLoader(true)
      await apiFunctions
        .put(process.env.REACT_APP_SERVICE_BACKEND_URL + '/user/archive', {
          archiveUser
        })
        .then((_result) => {
          // SetShowLoader(false)
          localStorage.removeItem('archive_list');
          window.location.reload()
        })
        .catch((err) => {
          SetShowLoader(false)
        })
    }
    else {   /* Archive using button */
      SetShowLoader(true)
      await apiFunctions
        .put(process.env.REACT_APP_SERVICE_BACKEND_URL + '/user/archive', {
          id: userdata.users.id,
          type: userdata.Atype === 'archive' ? true : false,
        })
        .then((_result) => {
          // SetShowLoader(false)
          window.location.reload()
        })
        .catch((err) => {
          SetShowLoader(false)
        })
    }
  }
  async function updateAccess(type: any) {
    SetShowLoader(true)
    let url = process.env.REACT_APP_SERVICE_BACKEND_URL + '/auth/upgrade-superadmin'
    let payload: any = { id: userdata.id }
    if (type === 'location') {
      if (selectedRegion.split(',').length === 0) {
        return
      }
      url = process.env.REACT_APP_SERVICE_BACKEND_URL + '/postcode/location?user_id=' + userdata.id
      let locationsObj: any = []
      selectedRegion.split(',').map((val: any) => {
        locationsObj.push({ name: val.trim(), status: true })
      })
      payload = { locations: locationsObj }
    }

    // return;

    seterrormsg('')
    await apiFunctions
      .put(url, payload)
      .then((_result) => {
        setSelectedRegion(null)
        if (type === 'location') {
          setRegionSuccess(true)
          SetShowLoader(false)
          return
        }
        window.location.reload()
        // SetShowLoader(false)
      })
      .catch((err) => {
        seterrormsg(
          err && err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Something went wrong. Please try again.'
        )
        SetShowLoader(false)
      })
  }
  function closeSuccessRegion() {
    if (closeProfile) closeProfile('success')
    showAdminProfile = false
    setaddRegionPopup(false)
    setupGradeSuperadmin(false)
    setRegionSuccess(false)
  }

  // Add Attempts
  const addAttempt = () => {
    if (attemptArray && attemptArray.length <= 9) {
      setAttemptArray([...attemptArray, {
        contact_type: '',
        date: moment().format("YYYY-MM-DD"),
        time: moment().format("HH:mm"),
        status: ''
      }]);
    } else {
      setAttemptWarning(true);
    }
  }
  const tabIndex = 0;
  // Handle change events:
  const handleFormChange = (index: number, event: any, name: string) => {
    const data = [...attemptArray];
    if (name === 'date' || name === 'time') {
      data[index][name] = (name === 'date') ? moment(event).format("YYYY-MM-DD") : event;
    } else {
      if (event.value === 'client contacted') {
        setShowColorResult('1');
      }
      if (event.value === 'unable to contact') {
        setShowColorResult('2');
      }
      if (event.value === 'awaiting response') {
        setShowColorResult('3');
      }
      data[index][name] = event.value ? event.value : event.target.value;
    }
    setAttemptArray(data);
  }

  // Add user Id to payload
  const addUsersId = () => {
    attemptArray.map((attempt: any) => {
      attempt.user_id = userval.id;
      attempt.contacted_by = dataval && dataval.user && dataval.user.id
    })


  }
  // Add Contact Attempts
  const saveContactAttempt = async () => {
    SetShowLoader(true)
    addUsersId();
    try {
      apiFunctions
        .post(process.env.REACT_APP_SERVICE_BACKEND_URL + `/admin/contact-attempt?user_id=${userval.id}`, attemptArray)
        .then((_result) => {
          if (ContactAproveReject === false) {
            window.location.reload()
          }
          SetShowLoader(false)
        })
        .catch((err) => {
          SetShowLoader(false)
          setError({ status: true, message: err.response.data.message })
          const timer = setTimeout(() => {
            setError({ status: false, message: '' })
          }, 4000);
        })
    } catch (error: any) {
      SetShowLoader(false)
      setError({ status: true, message: error.response.data.message })
      const timer = setTimeout(() => {
        setError({ status: false, message: '' })
      }, 4000);
    }


  }
  return (
    // popup for request change settings page //

    Logout ? (
      <div className="modal d-block bg-color-popup" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content model-bor">
            <div className="modal-body body-pad">
              <div className="popup-text" tabIndex={tabIndex}>Are you sure want to logout?</div>
              <div className="modal-footer bor-none">
                <button type="button" tabIndex={tabIndex} className="btn btn-primary popup-btn login-btn close-pop onkeyup" onClick={props.close}>
                  No
                </button>
                <button
                  tabIndex={tabIndex}
                  type="button"
                  className="btn btn-primary popup-btn login-btn close-pop onkeyup"
                  onClick={(e) => {
                    authDispatch({ type: 'LOGOUT' })
                    logout()
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : Archivepopup ? (

      <div className="modal d-block bg-color-popup" role="dialog">
        {showLoader && <Loader />}
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content model-bor">
            <div className="modal-body body-pad">
              <div className="popup-text" tabIndex={tabIndex}>
                Are you sure want to {userdata.Atype === 'archive' ? 'Archive' : 'Unarchive'}?
              </div>
              <div className="modal-footer bor-none">
                <button type="button" className="btn btn-primary popup-btn login-btn close-pop" onClick={props.close}>
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-primary popup-btn login-btn close-pop"
                  onClick={(e) => {
                    ArchiveUserInfo()
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : removebrookRole ? (
      <div className="modal d-block bg-color-popup" role="dialog">
        {showLoader && <Loader />}
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content model-bor">
            <div className="modal-body body-pad">
              <div className="popup-text" tabIndex={tabIndex}>Move to Bin?</div>
              <div className="modal-footer bor-none">
                <button
                  type="button"
                  className="btn btn-primary popup-btn login-btn close-pop"
                  onClick={props.close}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-primary popup-btn login-btn close-pop"
                  onClick={(e) => {
                    props.clickPopup()
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : removeBin ? (
      <div className="modal d-block bg-color-popup" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content model-bor">
            <div className="modal-body body-pad">
              <div className="popup-text" tabIndex={tabIndex}>Move to bin? </div>
              <div className="modal-footer bor-none">
                <button
                  type="button"
                  className="btn btn-primary popup-btn login-btn close-pop"
                  onClick={props.close}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-primary popup-btn login-btn close-pop"
                  onClick={(e) => {
                    props.clickPopup()
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : deleteBin ? (
      <div className="modal d-block bg-color-popup" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content model-bor">
            <div className="modal-body body-pad">
              <div className="popup-text" tabIndex={tabIndex}>Are you sure want to delete permanently? </div>
              <div className="modal-footer bor-none">
                <button
                  type="button"
                  className="btn btn-primary popup-btn login-btn close-pop"
                  onClick={props.close}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-primary popup-btn login-btn close-pop"
                  onClick={(e) => {
                    props.clickPopup(props.id)
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : restoreBin ? (
      <div className="modal d-block bg-color-popup" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content model-bor">
            <div className="modal-body body-pad">
              <div className="popup-text" tabIndex={tabIndex}>Are you sure want to restore? </div>
              <div className="modal-footer bor-none">
                <button
                  type="button"
                  className="btn btn-primary popup-btn login-btn close-pop"
                  onClick={props.close}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-primary popup-btn login-btn close-pop"
                  onClick={(e) => {
                    props.clickPopup(props.id)
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) :
      report ? (
        <div className="modal d-block bg-color-popup" role="dialog">
          {showLoader && <Loader />}
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content model-bor">
              <div className="modal-body body-pad">
                <div>
                  <p className="f-size fw-bold" tabIndex={tabIndex}>Thank you</p>
                  <div className="popup-text" tabIndex={tabIndex}>
                    {Successtype === 'feedback'
                      ? 'Your feedback has been recorded and we may contact you for more information.'
                      : 'Your report has been submitted'}
                  </div>
                </div>
                <div className="modal-footer bor-none">
                  <button type="button" className="btn btn-primary popup-btn login-btn close-pop" onClick={props.close}>
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : unArchiveeSuccess ? (
        <div className="modal d-block bg-color-popup" role="dialog">
          {showLoader && <Loader />}
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content model-bor">
              <div className="modal-body body-pad">
                <div>
                  <div className="popup-text" tabIndex={tabIndex}>{userdata && userdata.name} account has been unarchived</div>
                </div>
                <div className="modal-footer bor-none">
                  <div
                    className="popup-btn close-pop"
                    onClick={() => {
                      // setArchiveeSuccess(false);
                      // setArchiveAdminPopup(false);
                      // setUnArchiveAdminPopup(false);
                      window.location.reload()
                    }}
                  >
                    Close
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : UnArchiveAdminPopup ? (
        <div className="modal d-block bg-color-popup" role="dialog">
          {showLoader && <Loader />}
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content model-bor">
              <div className="modal-body body-pad">
                <div className="popup-text" tabIndex={tabIndex}>Are you sure want to unarchive?</div>
                <div className="modal-footer bor-none">
                  <button
                    type="button"
                    className="btn btn-primary popup-btn login-btn close-pop"
                    onClick={(e) => setUnArchiveAdminPopup(false)}
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary popup-btn login-btn close-pop"
                    onClick={(e) => ArchiveAdmin(true)}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : ApproveSuccess || RegionSuccess ? (
        <div className="modal d-block bg-color-popup" role="dialog">
          {showLoader && <Loader />}
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content model-bor">
              <div className="modal-body body-pad">
                <div>
                  <div className="popup-text">
                    {RegionSuccess
                      ? Capitalize(userdata.name) + ' region updated successfully'
                      : 'STI kit approved successfully'}
                  </div>
                </div>
                <div className="modal-footer bor-none">
                  <div
                    className="popup-btn close-pop"
                    onClick={(e) => {
                      if (RegionSuccess) {
                        closeSuccessRegion()
                        window.location.reload()
                        return
                      }
                      window.location.reload()
                    }}
                  >
                    Close
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : ArchiveeSuccess ? (
        <div className="modal d-block bg-color-popup" role="dialog">
          {showLoader && <Loader />}
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content model-bor">
              <div className="modal-body body-pad">
                <div>
                  <div className="popup-text" tabIndex={tabIndex}>{userdata && userdata.name} account has been archived</div>
                </div>
                <div className="modal-footer bor-none">
                  <div
                    className="popup-btn close-pop"
                    onClick={() => {
                      // setArchiveeSuccess(false);
                      // setArchiveAdminPopup(false);
                      // setUnArchiveAdminPopup(false);
                      window.location.reload()
                    }}
                  >
                    Close
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : RejectKitPopup ? (
        <div className="modal d-block bg-color-popup" role="dialog">
          {showLoader && <Loader />}
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content model-bor">
              <div className="modal-body body-pad">
                <div>
                  <div className="popup-text" tabIndex={tabIndex}>STI kit rejected successfully</div>
                </div>
                <div className="modal-footer bor-none">
                  <div className="popup-btn close-pop" onClick={(e) => window.location.reload()}>
                    Close
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : profileSuccess ? (
        <div className="modal d-block bg-color-popup" role="dialog">
          {showLoader && <Loader />}
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content model-bor">
              <div className="modal-body body-pad">
                {/* <div className='login-style1'>Thank you, your request has been submitted.</div> */}
                <div className="popup-text" tabIndex={tabIndex}>Your profile edited successfully.</div>
                <div className="modal-footer bor-none">
                  <button type="button" className="btn btn-primary popup-btn login-btn close-pop" onClick={props.close}>
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : successpopup ? (
        <div className="modal d-block bg-color-popup" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content model-bor">
              <div className="modal-body body-pad">
                <div className="login-style1" tabIndex={tabIndex}>Thank you, your request has been submitted.</div>
                <div className="popup-text" tabIndex={tabIndex}>
                  Your admin will approve or reject your request based on your requirements and role.
                </div>
                <div className="modal-footer bor-none">
                  <button type="button" className="btn btn-primary popup-btn login-btn close-pop" onClick={props.close}>
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : RequestChange ? (
        <form className="col-12 col-sm-10 col-md-5 mt-4" onSubmit={formik.handleSubmit}>
          {showLoader && <Loader />}
          <div className="modal d-block bg-color-popup" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content model-bor">
                <div className="modal-body body-pad">
                  <div className="login-style1" tabIndex={tabIndex}>Request change to your account</div>
                  <div className="popup-text " tabIndex={tabIndex}> Email address associated with account </div>
                  <div className="mt-2 popup-text email-col">{userdata && userdata.email ? userdata.email : ''}</div>
                  <div className="popup-text" tabIndex={tabIndex}>Choosing a setting to change notifies your admin</div>
                  <div className="parent-pos shadow-box">
                    <select
                      className="form-select login-radius mt-4"
                      aria-label="Default select example"
                      name="request"
                      id="request"
                      value={formik.values.request}
                      onChange={formik.handleChange}
                    >
                      <option value="" selected>
                        Select request
                      </option>
                      {/* <option value="1">Super admin</option> */}
                      <option value="2">Manager</option>
                      {/* <option value="3">User</option> */}
                    </select>
                    {/* <div className='arrow-icon'><img src={} className='icon-style' alt="speaker"></img></div> */}
                  </div>
                  <span className="errorMessage">
                    {formik.touched.request && formik.errors.request ? formik.errors.request : null}
                  </span>
                  <div className="form-group mt-4 parent-pos">
                    <div className="popup-text">Add note (optional)</div>
                    <input
                      type="text"
                      className="form-control shadow-box"
                      name="notes"
                      id="notes"
                      onChange={formik.handleChange}
                    />
                  </div>

                  <span className="errorMessage">{error.status ? error.message : ''}</span>
                  <div className="modal-footer bor-none">
                    <div className="popup-btn close-pop pad-top-impo" onClick={props.close}>
                      Close
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary popup-btn login-btn"
                    // onClick={(e) => roleChangeReq()}
                    >
                      Request change
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : ForgotPassword ? (
        <div className="modal d-block bg-color-popup" role="dialog">
          {showLoader && <Loader />}
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content model-bor">
              <div className="modal-body body-pad">
                <div className="login-style1" tabIndex={tabIndex}>Forgotten password?</div>
                <div className="popup-text " tabIndex={tabIndex}> Email address associated with account </div>
                <div className="mt-2 popup-text email-col">{userdata && userdata.email ? userdata.email : ''}</div>
                <div className="modal-footer bor-none">
                  <div className="popup-btn close-pop" onClick={props.close}>
                    Cancel
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary popup-btn login-btn"
                    onClick={(e) => {
                      requestPassword()
                    }}
                  >
                    Request new password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) :
        showNewRoleRequest ? (
          <div className="modal d-block bg-color-popup" role="dialog">
            {showLoader && <Loader />}
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content model-bor">
                <div className="modal-body body-pad">
                  <div className="login-style1" tabIndex={tabIndex}>Create new role</div>
                  <div className="form-group mt-4 parent-pos">
                    <div className="popup-text" tabIndex={tabIndex}>Name new role</div>
                    <input
                      type="text"
                      className="form-control shadow-box"
                      placeholder="Type name"
                      value={newroletext}
                      onChange={(e) => CreateNewRoleText(e.target.value)}
                    />
                  </div>
                  <span className="errorMessage">{errorMsg}</span>
                  <div className="modal-footer bor-none">
                    <div className="popup-btn close-pop pad-top-impo" onClick={(e) => { props.close; window.location.reload() }}>
                      Close
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary popup-btn login-btn"
                      onClick={(e) => {
                        createNewRole(newroletext)
                      }}
                    >
                      Create role
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : showRequest ? (
          <form className="col-12 col-sm-10 col-md-5 mt-4" onSubmit={formik1.handleSubmit} autoComplete="off">
            {showLoader && <Loader />}
            <div className="modal d-block bg-color-popup" role="dialog">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content model-bor">
                  <div className="modal-body body-pad">
                    <div>
                      <div className="login-style1" tabIndex={tabIndex}>Create new admin</div>
                      <div className="form-group mt-4 parent-pos">
                        <div className="popup-text" tabIndex={tabIndex}>Enter user's Brook email address to invite them</div>
                        <input
                          aria-label='request'
                          type="email"
                          className="form-control shadow-box"
                          placeholder="Type email"
                          name="request"
                          id="request"
                          onChange={formik1.handleChange}
                          value={formik1.values.request}
                        />
                      </div>
                      <span className="errorMessage">
                        {formik1.touched.request && formik1.errors.request
                          ? formik1.errors.request
                          : error && error.message
                            ? error.message
                            : null}
                      </span>
                      <div className="ml-2">
                        <span tabIndex={tabIndex}>
                          <b>Note:</b> Every new person you invite will have 'user' status when they sign up for an account,
                          and will be able to request upgrading it to 'admin' status within their settings.
                        </span>
                      </div>
                      <div className="form-group mt-4 parent-pos d-none">
                        <div className="popup-text" tabIndex={tabIndex}>Add note (optional)</div>
                        <input
                          aria-label='invite_note'
                          type="text"
                          className="form-control shadow-box"
                          name="invite_note"
                          id="invite_note"
                          onChange={formik1.handleChange}
                          value={formik1.values.invite_note}
                        />
                      </div>
                    </div>
                    <div className="modal-footer bor-none">
                      <div className="popup-btn close-pop mt-1" onClick={(e) => { props.close; window.location.reload() }}>
                        Close
                      </div>
                      <button type="submit" className="btn btn-primary popup-btn login-btn">
                        Invite new admin
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        ) : showAdminsuccess ? (
          <div className="modal d-block bg-color-popup" role="dialog">
            {showLoader && <Loader />}
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content model-bor">
                <div className="modal-body body-pad">
                  <div>
                    <div className="login-style1" tabIndex={tabIndex}>Create new admin</div>
                    <div className="popup-text" tabIndex={tabIndex}>Invite sent successfully!!!</div>
                  </div>
                  <div className="modal-footer bor-none">
                    <div className="popup-btn close-pop" onClick={props.close}>
                      Close
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : removeRole ? (
          <div className="modal d-block bg-color-popup " role="dialog">
            {showLoader && <Loader />}
            <div className="modal-dialog modal-dialog-centered modal-width modal-xl" role="document">
              <div className="modal-content model-bor">
                <div className="modal-body body-pad">
                  {/* you can the change popup header content dynamically using popup header component */}
                  <PopupHeader />
                  <div className="pos-header">
                    <div className="login-style1" tabIndex={tabIndex}>Bluebell Kinsley</div>
                    <div className="popup-text" tabIndex={tabIndex}>07989 234 549 </div>
                    <div className="popup-text pos-abs" tabIndex={tabIndex}> Client contacted? </div>
                    <div className="pos-check">
                      <AdminCheckbox />
                    </div>
                  </div>
                  <hr />
                  <div className="popup-text" tabIndex={tabIndex}>Flagged by client in form </div>
                  <div className="popup-text" tabIndex={tabIndex}> Reasons for rejecting kit after contacting client</div>
                  <div className="mt-3 row">
                    <div className="col-1">
                      <Flags value="" />
                    </div>
                    <div className="col-1">
                      <Flags value="" />
                    </div>
                  </div>
                  <div className="form-check pos-check">
                    <AdminCheckbox />
                    <label className="form-check-label label-class" tabIndex={tabIndex}>Leaving job</label>
                  </div>
                  <div className="modal-footer bor-none">
                    <div className="popup-btn close-pop" onClick={props.close}>
                      Close
                    </div>
                    <button type="button" className="btn btn-primary popup-btn login-btn">
                      Pass super admin controls
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : userProfile ? (
          /* Reworked the popup below */
          <div className="modal d-block bg-color-popup " role="dialog">
            {showLoader && <Loader />}
            <div className="modal-dialog modal-dialog-centered client-model-width modal-xl" role="document">
              <div className="modal-content model-bor">
                <div className="modal-header profile-header">
                  <div className="float-left">Client profile</div>
                  <div className="close-white-pos" onClick={props.close}>
                    <img className="close-white" src={closeWhiteIcon} alt="close White Icon" />
                  </div>
                </div>
                <div className="modal-body profile-popup-height pad-admin">
                  {userdata && userdata.data && userdata.data.data && (
                    <div className="row mar-top">
                      <div className="col-12 ">
                        <div className="d-flex flex-wrap mb-3">
                          <div className="sub-head-style pb-3 pl-0 mr-auto pad-admin">
                            {Capitalize(userdata.data.data.findExisting.first_name) +
                              ' ' +
                              Capitalize(userdata.data.data.findExisting.last_name)}
                          </div>
                          {userdata.data.data.findExisting.isLilie && (
                            <div className="pad-admin pb-3 pr-2 font-we" tabIndex={tabIndex}>Added to Lilie:</div>
                          )}
                          {userdata.data.data.findExisting.isLilie && (
                            <div className="sub-rply disable pb-3 pad-admin px-0">
                              <label className="container">
                                <input type="checkbox" checked={true} disabled={true} />
                                <span className="checkmark" />
                              </label>
                            </div>
                          )}
                        </div>
                        <div className="bor-ra-bottom  row">
                          <div className="col set-width align-pad">
                            <div className="text-head-color" tabIndex={tabIndex}>Contact details</div>
                          </div>
                          <div className="col demo-width">
                            <div className="text-head-color" tabIndex={tabIndex}>Demographics</div>
                          </div>
                          <div className="col">
                            <div className="text-head-color" tabIndex={tabIndex}>Gender</div>
                          </div>
                          <div className="col">
                            <div className="text-head-color" tabIndex={tabIndex}>Codes</div>
                          </div>
                          <div className="col">
                            <div className="text-head-color" tabIndex={tabIndex}>Contact attempts details</div>
                          </div>
                        </div>
                        <div className="row mb-4 client-popup">
                          <div className="col set-width align-pad text-align-left">
                            <div className="">
                              <div className="pad-top phone-section ">
                                <div className="text-head-color mr-3" tabIndex={tabIndex}>Phone</div>
                                <div className="sub-rply font-we">{userdata.data.data.findExisting.phone_no || ''} </div>
                              </div>
                              <div className="pad-top email-section ">
                                <div className="text-head-color mr-3">Email</div>
                                <div className="sub-rply font-we">{userdata.data.data.findExisting.email || ''} </div>
                              </div>
                              <div className="d-flex">
                                <div className="text-head-color mr-3 pad-top" tabIndex={tabIndex}>Address</div>
                                <div className="pad-top">
                                  <div className="sub-rply font-we">{userdata.data.data.findExisting.address_line_1}</div>
                                  <div className="font-we">{userdata.data.data.findExisting.address_line_2}</div>
                                  <div className="font-we">{userdata.data.data.findExisting.address_line_3}</div>
                                  <div className="font-we">{userdata.data.data.findExisting.town}</div>
                                  <div className="font-we">
                                    {userdata.data.data.findExisting.address_line_1
                                      ? userdata.data.data.findExisting.postcode
                                      : '-'}
                                  </div>
                                </div>
                              </div>
                              <div className="pad-top email-section ">
                                <div className="text-head-color mr-3" tabIndex={tabIndex}>Email Confirmation</div>
                                {/* <div className="sub-rply font-we">{userdata.data.data.findExisting.isEmailConfirm.toString()} </div> */}
                                {userdata.data.data.findExisting.isEmailConfirm ? 'Yes' : 'No'}
                              </div>
                            </div>
                          </div>
                          <div className="col demo-width text-align-left">
                            <div className="d-flex pad-top">
                              <div className="text-head-color mr-3" tabIndex={tabIndex}>DOB</div>
                              <div className="sub-rply font-we pad-dob">
                                {userdata.data.data.findExisting.date_of_birth
                                  ? moment(userdata.data.data.findExisting.date_of_birth).format('DD-MM-YYYY')
                                  : '' || ''}{' '}
                                {'( age ' + userdata.data.data.findExisting.age + ')'}
                              </div>
                            </div>
                            <div className="">
                              {userEthnicity ? (
                                <div className="d-flex">
                                  <div className="pad-top text-head-color mr-3" tabIndex={tabIndex}>Ethnicity</div>
                                  <div className="sub-rply font-we pad-top">{userEthnicity}</div>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                          <div className="col">
                            <div className="d-flex">
                              <div className="pad-top text-head-color" tabIndex={tabIndex}>Identify as</div>
                              <div className="sub-rply font-we pad-top pl-2">{userGender ? userGender : '-'}</div>
                            </div>
                            <div className="d-flex gender-section">
                              <div className="pad-top text-head-color">Genitals</div>
                              <div className="sub-rply font-we pad-top pl-2">{userGeneticals ? userGeneticals : '-'}</div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="d-flex">
                              <div className="pad-top text-head-color" tabIndex={tabIndex}>Site code</div>
                              <div className="sub-rply font-we pad-top pl-2">
                                {userdata.data.data.findExisting.tdl_site_code ? userdata.data.data.findExisting.tdl_site_code : userdata.data.data.findExisting.postcodes?userdata.data.data.findExisting.postcodes.site_code:''}
                              </div>
                            </div>
                            <div className="d-flex code-section">
                              <div className="pad-top text-head-color" tabIndex={tabIndex}>Ref no</div>
                              <div className="sub-rply font-we pad-top pl-2">
                                {userdata.data.data.findExisting.referenceNo}
                              </div>
                            </div>
                            <div className="d-flex code-section">
                              <div className="pad-top text-head-color" tabIndex={tabIndex}>Test code</div>
                              <div className="sub-rply font-we pad-top pl-2">
                                {userdata?.data?.data?.findExisting?.disease_code}
                              </div>
                            </div>
                          </div>
                          <div className="col pos-relative">
                            {userdata.data.data.findExisting.contact_attempt.length && userdata.data.data.findExisting.contact_attempt.length > 0 ? (userdata.data.data.findExisting.contact_attempt.map((data: any) => {
                              return (
                                <>
                                  <div className="d-flex">
                                    <div className="pad-top text-head-color" tabIndex={tabIndex}><img src={data.status === "awaiting response" ? clockIcon : data.status === "unable to contact" ? dislikebgIcon : likebgIcon} width='30px' height='30px' alt='dislike' /></div>
                                    <div>
                                      <div className="sub-rply pad-top pl-2">
                                        {Capitalize(data.contact_type)} - {moment(data.date).format('DD-MM-YYYY')} , {moment((moment(new Date()).format("YYYY-MM-DD")) + " " + data.time).format("hh:mm A")}
                                      </div>
                                      {data.status === "unable to contact" && <div className="sub-rply final-result-1 pl-2">
                                        No response from client
                                      </div>}
                                      {data.status === "client contacted" && <div className="sub-rply pl-2  final-result-2 ml-0">
                                        Client contacted successfully
                                      </div>}
                                      {data.status === "awaiting response" && <div className="sub-rply pl-2  final-result-2 ml-0">
                                        Awaiting For Response
                                      </div>}
                                      <div className="sub-rply pl-2  ml-0">
                                        <span>Contacted by<span className='bold'> {data?.administrator?.name}{", "} {data?.administrator?.roleinfo?.roleName} </span></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='result-border' />
                                </>
                              )
                            })) : (<div className="d-flex"> <div className="pad-top" > - </div></div>)}

                          </div>
                          {/* <div className="d-flex code-section">
                              <div className="pad-top text-head-color" tabIndex={tabIndex}><img src={likebgIcon} width='30px' height='30px' alt='like' /></div>
                              <div>
                                <div className="sub-rply pad-top pl-2 ml-0">
                                  Phone - 10/10/2022, 3:00 PM
                                </div>
                                <div className="sub-rply pl-2  final-result-2 ml-0">
                                  Client contacted successfully
                                </div>
                              </div>
                            </div> */}

                        </div>
                        <div className="row">
                          <div className="col-4 align-pad">
                            <div className="text-head-color text-left" tabIndex={tabIndex}> Test status</div>
                          </div>
                          <div className="col-2 pl-0">
                            <div className="text-head-color text-left" tabIndex={tabIndex}>Date actioned</div>
                          </div>
                          <div className="col-4 pl-0">
                            <div className="text-head-color text-left" tabIndex={tabIndex}> Test result</div>
                          </div>
                          <div className="col-2 pl-0">
                            <div className="text-head-color text-left" tabIndex={tabIndex}>Date received</div>
                          </div>
                        </div>
                        <div className="mt-3 bor-ra-bottom" />
                        <div className="row">
                          <div className="col-6 pl-5">
                            {/* {approvedOrReject && (
                      <div className="align-status text-left mb-4">
                        {approvedOrReject && <div className="p-2  pl-0 font-we">{approvedOrReject}</div>}
                        {ApprovedBy && <div className="ml-4 p-2 mr-auto">{Capitalize(ApprovedBy)}
                        <br/>{userReason}
                        <br/>
                        {approvedOrReject === 'Rejected' && userdata.data.data.findExisting.additional_data !== "" && 
                           <><b>Note: </b>{userdata.data.data.findExisting.additional_data}</>
                        }
                        </div>}
                        <div className="p-2">{ApprovedRejDate}</div>
                      </div>
                    )} */}

                            {userdata.data.data.findExisting.referenceNo &&
                              userdata.data.data.findExisting.azureUploads &&
                              userdata.data.data.findExisting.azureUploads.length > 0 && (
                                <div className="row">
                                  <div className="align-status col-8 text-left mb-4">
                                    <div className="p-2 pl-0 font-we align-pad">
                                      TDL Status ({userdata.data.data.findExisting.azureUploads[0]['status']})
                                    </div>
                                  </div>
                                  <div className="col  pl-0 pad-top">
                                    {moment(userdata.data.data.findExisting.azureUploads[0]['createdAt']).format(
                                      'DD/MM/YYYY'
                                    )}
                                  </div>
                                </div>
                              )}

                            {FlagReasons && (
                              <div className="row">
                                <div className="align-status col-8 text-left mb-4">
                                  <div className="p-2 pl-3 font-we align-pad" tabIndex={tabIndex}>Flagged</div>

                                  <div className="p-2 pl-5">
                                    {userdata.data.data.findExisting.form_source === 'tdl' ? '-' : FlagReasons}
                                  </div>
                                </div>
                                <div className="col  pl-0 pad-top">
                                  {moment(userdata.data.data.findExisting.createdAt).format('DD/MM/YYYY')}
                                </div>
                              </div>
                            )}
                            {userdata.data.data.findExisting.age > 17 ||
                              ((userdata.data.data.findExisting.age === 17 || userdata.data.data.findExisting.age === 16) &&
                                userdata.data.data.findExisting.flags.length === 0) ? (
                              <div className="row">
                                <div className="align-status col-8 text-left mb-4">
                                  <div className="p-2 pl-0 font-we">
                                    {userdata.data.data.findExisting.form_source === 'tdl' ? '-' : 'Approved(auto)'}
                                  </div>
                                  {/* <div className="p-2">{ApprovedBy && ApprovedBy}</div> */}
                                </div>
                                <div className="col pad-top pl-0">
                                  {moment(
                                    userdata.data.data.findExisting.form_approved_date
                                      ? userdata.data.data.findExisting.form_approved_date
                                      : userdata.data.data.findExisting.createdAt
                                  ).format('DD/MM/YYYY')}
                                </div>
                              </div>
                            ) : (
                              <>
                                {approvedOrReject && (
                                  <div className="row">
                                    <div className="align-status col-8 text-left mb-4">
                                      <div className="p-2 pl-0 font-we">{approvedOrReject}</div>

                                      <div className="p-2">{ApprovedBy && ApprovedBy}</div>
                                    </div>
                                    <div className="col pad-top pl-0">
                                      {ApprovedRejDate
                                        ? ApprovedRejDate
                                        : moment(userdata.data.data.findExisting.createdAt).format('DD/MM/YYYY')}
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                          <div className="col-6 pl-5">
                            {userdata.data.data.findExisting.result.length > 0 &&
                              userdata.data.data.findExisting.result
                                .slice(0).sort(compareString)
                                .map((resultData: any) => (
                                  <div className="row">
                                    <div className="align-status col-8 text-left">
                                      <div
                                        className={`p-2 pl-0 col set-col-width ${resultData.result_stage === 'inactive' ? '' : 'font-we'
                                          }`}
                                      >
                                        <span
                                          className={`${resultData.result_stage === 'inactive' && 'strike-off'}`}
                                        >{`${resultData.result_type} (${resultData.result_type_short})`}</span>
                                        <br/><br/>
                                        {resultData.notes && resultData.notes.length > 0 &&<span>Notes:  </span>}
                                        {resultData.notes && resultData.notes.length > 0 &&
                                        resultData.notes.map((notes:string)=>(
                                          <span
                                              className={`${resultData.result_stage === 'inactive'}`}
                                            >{notes}</span>
                                          ))

                                        }

                                        {resultData.result_stage === 'inactive' && (
                                          <>
                                            <br />
                                            <span className="outdated" tabIndex={tabIndex}>Outdated result</span>
                                          </>
                                        )}
                                      </div>
                                      <div className={`p-3 pt-2 ${resultData.result_stage === 'inactive' && 'strike-off'}`}>
                                        {resultData.result_text ? resultData.result_text : resultData.result_status}
                                      </div>
                                    </div>
                                    <div
                                      className={`col pad-top ${resultData.result_stage === 'inactive' && 'strike-off'}`}
                                    >
                                      {moment(resultData.createdAt).format('DD-MM-YYYY')}
                                    </div>
                                  </div>
                                ))}
                          </div>
                        </div>

                        {/* )} */}

                        <div className="text-head-color text-left"> Notes </div>
                        <div className="mt-3 bor-ra-bottom" />
                        <div className="pad-top">
                          <div className="row">
                            <div className="col-sm">{userReason && userReason !== 'false' ? userReason :
                              ''
                            }</div>

                            {approvedOrReject === 'Rejected' && userdata.data.data.findExisting.additional_data !== '' && (
                              <div className="col-sm">
                                <b>Rejected Note: </b>
                                {userdata.data.data.findExisting.additional_data}
                              </div>
                            )}
                          </div>
                          {userdata.data.data.findExisting.result.length > 0 && (<div className='export-btn'>
                            <button className='btn btn-primary' onClick={exportPDF}>Export PDF</button>
                          </div>)}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

        ) : approveStatus ? (
          <ApprovePopup
            close={props.close}
            callbackKit={showcallback}
            ApproveUser={ApproveRejectUser ? ApproveRejectUser : userval}
            flagReasons={FlagReasons}
          />
        ) : rejectStatus ? (
          <RejectPopup
            RejectUser={ApproveRejectUser ? ApproveRejectUser : userval}
            callbackKit={showcallback}
            close={props.close}
            flagReasons={FlagReasons}
          />
        ) : ArchiveAdminPopup ? (
          <div className="modal d-block bg-color-popup " role="dialog">
            {showLoader && <Loader />}
            <div className="modal-dialog modal-dialog-centered  modal-md" role="document">
              <div className="modal-content model-bor p-5">
                <div className="modal-header pad-head pb-0">
                  <div className="close-pos" onClick={(e) => setArchiveAdminPopup(false)}>
                    <img src={closeIcon} alt="close Icon" />
                  </div>
                </div>
                <div className="modal-body pt-0">
                  <p className="archive-pop-title" tabIndex={tabIndex}>Archive this user account?</p>
                  <div className="mt-4 sub-title-text" tabIndex={tabIndex}>
                    Archiving another user's account means it will no longer be accessible to them. You should only do this
                    on behalf of someone who has left.
                  </div>
                  <div className="mt-4" tabIndex={tabIndex}> Admins will still be able to access the user's history.</div>
                  <div className="mt-4" tabIndex={tabIndex}> This is entirely reversible.</div>
                </div>
                <div className="modal-footer bor-none">
                  <button
                    type="button"
                    className="btn btn-primary popup-btn login-btn"
                    onClick={(e) => ArchiveAdmin(false)}
                  >
                    Confirm archive account
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) :
          /* Confirmation popup */

          upGradeSuperadmin ? (
            <div className="modal d-block bg-color-popup" role="dialog">
              {showLoader && <Loader />}
              <div className="modal-dialog modal-dialog-centered modal-width-staff model-width modal-xl" role="document">
                <div className="modal-content model-bor  modal-h ">
                  <div className="modal-header profile-header">
                    <div className="float-left" tabIndex={tabIndex}>Staff profile</div>
                    <div
                      className="close-white-pos"
                      onClick={(e) => {
                        showAdminProfile = false
                        setaddRegionPopup(false)
                        setupGradeSuperadmin(false)
                        if (closeProfile) closeProfile('success')
                      }}
                    >
                      <img className="close-white" src={closeWhiteIcon} alt="closeWhiteIcon" />
                    </div>
                  </div>
                  <div className="modal-body pad-admin">
                    <div className="row mar-top">
                      <div className="col-12 ">
                        <p className="font-we f-size  pt-5 pb-3" tabIndex={tabIndex}>You are about to make {userdata.name} a Super admin.</p>
                        <div className="mt-3 bor-ra-bottom" />
                        <p className="font-we mt-3" tabIndex={tabIndex}>
                          This means they will have all the same permissions, powers and access to the Staff Hub that you do.
                          This process cannot be reversed.
                        </p>
                      </div>
                    </div>
                    <span className="errorMessage">{errorMsg}</span>
                  </div>
                  <div className="modal-footer bor-none mb-4 pr-4">
                    <button
                      type="button"
                      className="go-back mr-5 mt-2"
                      onClick={(e) => {
                        showAdminProfile = true
                        setaddRegionPopup(false)
                        setupGradeSuperadmin(false)
                      }}
                    >
                      Go back
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={(e) => {
                        setaddRegionPopup(true)
                        updateAccess('admin')
                      }}
                    >
                      Make super admin
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : addRegionPopup ? (
            <div className="modal d-block bg-color-popup " role="dialog">
              {showLoader && <Loader />}
              <div className="modal-dialog modal-dialog-centered modal-width-staff model-width modal-xl" role="document">
                <div className="modal-content model-bor  modal-h ">
                  <div className="modal-header profile-header">
                    <div className="float-left" tabIndex={tabIndex}>Staff profile</div>
                    <div
                      className="close-white-pos"
                      onClick={(e) => {
                        showAdminProfile = false
                        setaddRegionPopup(false)
                        setupGradeSuperadmin(false)
                        if (closeProfile) closeProfile('success')
                      }}
                    >
                      <img className="close-white" src={closeWhiteIcon} alt="closeWhiteIcon" />
                    </div>
                  </div>
                  <div className="modal-body pad-admin text-align-left">
                    <div className="row mar-top">
                      <div className="col-12 ">
                        <p className="font-we f-size  pt-5 pb-3">
                          Select extra region(s) to allow {userdata.name} to access.
                        </p>
                        <div className="mt-3 bor-ra-bottom" />
                        <p className="font-we mt-3" tabIndex={tabIndex}>
                          Existing region will not be renewed , only upon addition the new regions will be updated and sustained.
                        </p>
                      </div>
                    </div>
                    <div className="parent-pos select-width mt-5">
                      <Select
                        aria-label='select-dropdown-role'
                        classNamePrefix="select-dropdown-role"
                        className="region-select"
                        onChange={(e) => {
                          let regionConcat = ''
                          e.map((region: any) => (regionConcat += region.value + ', '))
                          setSelectedRegion(regionConcat.slice(0, -2))
                        }}
                        isMulti
                        options={regionListSelect}
                      />
                      <div className="arrow-icon pos-right">
                        <img src={dropdownIcon} className="icon-style pos-arrow" alt="speaker" />
                      </div>
                    </div>

                    <div className="mt-5">
                      <div className="pl-2 text-head-color">Existing region:</div>
                      {userdata.role === 1 && userdata.locations.length === 0
                        ? regionListSelect &&
                        regionListSelect.map((loc: any, index: number) => (
                          <div className="sub-rply font-we pl-2">{loc.value}</div>
                        ))
                        : userdata.locations &&
                        userdata.locations.map((loc: any, index: number) =>
                          loc.status ? <div className="sub-rply font-we pl-2">{loc.name}</div> : ''
                        )}
                    </div>
                    {selectedRegion && (
                      <div className="mt-5">
                        <div className="pl-2 text-head-color">New region:</div>
                        {selectedRegion &&
                          selectedRegion
                            .split(',')
                            .map((loc: any, index: number) => <div className="sub-rply font-we pl-2">{loc}</div>)}
                      </div>
                    )}
                    <span className="errorMessage">{errorMsg}</span>
                  </div>

                  <div className="modal-footer bor-none mb-4 pr-4">
                    <button
                      type="button"
                      className="go-back mr-5 mt-2"
                      onClick={(e) => {
                        showAdminProfile = true
                        setaddRegionPopup(false)
                        setupGradeSuperadmin(false)
                        setSelectedRegion(null)
                      }}
                    >
                      Go back
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={(e) => {
                        setaddRegionPopup(true)
                        updateAccess('location')
                      }}
                      disabled={selectedRegion && selectedRegion.length > 0 ? false : true}
                    >
                      Give access
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : showAdminProfile ? (
            <div>
              {showLoader && <Loader />}
              <div className="modal d-block bg-color-popup " role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-width-staff  model-width modal-xl" role="document">
                  <div className="modal-content model-bor">
                    <div className="modal-header profile-header">
                      <div className="float-left">Staff profile</div>
                      <div className="close-white-pos" onClick={props.close}>
                        <img className="close-white" src={closeWhiteIcon} alt="closeWhiteIcon" />
                      </div>
                    </div>
                    <div className="modal-body pad-admin">
                      <div className="row mar-top">
                        <div className="col-12 ">
                          <div className="d-flex flex-wrap bor-ra-bottom mb-3 pl-4">
                            <div className="sub-head-style pb-3 pl-0 mr-auto pad-admin-pop">
                              {userdata && userdata.name ? Capitalize(userdata.name) : ''}
                            </div>
                            <div className="pad-admin-pop pr-0 pb-3">
                              {!(location && location.pathname === '/staffhub/settings/manage-roles/archive-permissions')? (
                                <button
                                  className="approve-kit-btn reject custo-class"
                                  disabled={!(permessionAccessArchive && permessionAccessArchive[0] && permessionAccessArchive[0].value)}
                                  onClick={(e) => showcallback(userdata.archiveAccount ? 'unarchive' : 'archive')}
                                >
                                  {/* {' '} */}
                                  Archive account
                                </button>
                              ) :
                                ''
                              }
                            </div>
                          </div>
                          <div className="row mb-4 client-popup pl-3">
                            <div className="col-12 text-align-left pl-2">
                              <div className="d-flex flex-wrap justify-content-between">
                                <div className="">
                                  <div className="text-head-color pl-2">Brook location:</div>
                                  <div className="sub-rply font-we pl-1">
                                    {userdata.role === 1 && userdata.locations.length === 0
                                      ? regionListSelect &&
                                      regionListSelect.map((loc: any, index: number) => (
                                        <div className="sub-rply font-we pl-2">{loc.value}</div>
                                      ))
                                      : userdata.locations &&
                                      userdata.locations.map((loc: any, index: number) =>
                                        loc.status ? <div className="sub-rply font-we pl-1">{loc.name}</div> : ''
                                      )}
                                  </div>
                                </div>
                                <div className="pr-0 pb-3">
                                  <button
                                    className="location-btn custo-class"
                                    disabled={!(permessionAccess && permessionAccess[0] && permessionAccess[0].value)}
                                    onClick={(e) => {
                                      showAdminProfile = false
                                      setaddRegionPopup(true)
                                    }}
                                  >
                                    {' '}
                                    Add another location
                                  </button>
                                </div>

                              </div>
                              <div className="mt-4">
                                <div className="pl-2 text-head-color">Role</div>
                                <div className="sub-rply font-we pl-2">
                                  {userdata &&
                                    userdata.roleAtBrook &&
                                    userdata.roleAtBrook.replace(/[&\\\#+()$~%.'":*?<>{}]/g, '').replace(/,/g, ', ')}
                                </div>
                              </div>
                              <div className="mt-4">
                                <div className="d-flex flex-wrap justify-content-between">
                                  <div>
                                    <div className="pl-2 text-head-color">Account type:</div>
                                    <div className="sub-rply font-we pl-2">
                                      {userdata && userdata.role === 3
                                        ? 'User'
                                        : userdata && userdata.role === 2
                                          ? 'Manager'
                                          : 'Super Admin'}{' '}
                                    </div>
                                  </div>
                                  {localStorage.getItem('role') === '1' && userdata && userdata.role === 2 && (
                                    <div className="pr-0 pb-3 pt-5">
                                      <button
                                        className="location-btn custo-class"
                                        onClick={(e) => {
                                          showAdminProfile = false
                                          setupGradeSuperadmin(true)
                                        }}
                                      >
                                        {' '}
                                        Upgrade to Super admin
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row m-0">
                            <div className="col-12 p-0 staff-profile-table">
                              <AdminHistory user_id={userdata && userdata.id} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : Contactpopups ? (
            <>
              {showLoader && <Loader />}
              <div className="modal d-block bg-color-popup" role="dialog">
                <div className="modal-dialog contact-attempt-popup modal-dialog-centered" role="document">
                  <div className="modal-content model-bor">
                    <div className="modal-body body-pad pb-0">
                      <div className="login-style1" tabIndex={tabIndex}>Add contact attempts</div>
                      <div className="close-white-pos" onClick={props.close}>
                        <img className="close-white" src={closeIcon} alt="closeIcon" />
                      </div>
                      <div className='border-style' />
                      <div className='contact-attempts-block'>
                        {attemptArray.length > 0 && attemptArray.map((element: any, index: number) => {
                          return (
                            <div key={index}>
                              <div className="form-group mt-4 parent-pos">
                                <div className="login-style1 sub-head" tabIndex={tabIndex}>Attempt {index + 1}</div>
                                <div className='parent-contact'>
                                  <div className='dropdown-class'>
                                    <Select
                                      options={contactAttempts}
                                      isSearchable={false}
                                      placeholder='Type'
                                      isDisabled={element.id && !element.edit ? true : false}
                                      value={contactAttempts.filter(function (option) {
                                        return option.value === element.contact_type;
                                      })}
                                      name='contact_type'
                                      onChange={event => handleFormChange(index, event, 'contact_type')} /></div>
                                  <div className='date'>
                                    <div className={`date ${element.id && !element.edit ? 'disable' : ''}`}>
                                      <DatePicker placeholderText="DD/MM/YYYY"
                                        maxDate={new Date()}
                                        selected={startDate}
                                        value={moment(element.date, 'YYYY-MM-DD"').format("DD-MM-YYYY")}
                                        onChange={
                                          event => handleFormChange(index, event, 'date')
                                        } className={`cursor-pointer ${element.id && !element.edit ? 'disabled' : ''}`} />
                                      <img className='calender-img' src={calenderIcon} alt='calendar' />
                                    </div>
                                    {/* </div> */}
                                    {/* <input type="date" name='date' value={moment(element.date, 'YYYY-MM-DD"').format("YYYY-MM-DD")} max={moment(new Date(), 'YYYY-MM-DD"').format("YYYY-MM-DD")}
                                      onChange={event => handleFormChange(index, event, 'date')} placeholder="MM/DD/YYYY" className={`cursor-pointer ${element.id ? 'disable' : ''}`} /> */}

                                  </div>
                                  {/* <div className='time  d-flex'>
                                    <input type='text' name='time' onChange={event => handleFormChange(index, event, 'time')} value={element.time} placeholder='09:00 AM'
                                      className={`cursor-pointer ${element.id ? 'disable' : ''}`} /><img src={clockIcon} width='20px' height='20px' alt='clock_icon' /> moment(element.time).format("hh:mm a")
                                  </div> */}
                                  <div className='time d-flex'>
                                    <TimePick name='time' onChange={(event: any) => handleFormChange(index, event, 'time')} value={element.time} isDisabled={element.id && !element.edit ? true : false} /><img src={clockIcon} width='20px' height='20px' alt='clock_icon' />
                                  </div>
                                  <div className={`result showColour-${element.status === 'client contacted' ? 1 : element.status === 'unable to contact' ? 2 : element.status === 'awaiting response' ? 3 : '0'}`}><Select
                                    options={resultOptions}
                                    isSearchable={true}
                                    placeholder='Result'
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    isDisabled={element.id && !element.edit ? true : false}
                                    value={resultOptions.filter(function (option) {
                                      return option.value === element.status;
                                    })}
                                    name='status'
                                    onChange={(event: any) => { handleFormChange(index, event, 'status') }}
                                  /></div>
                                </div>
                                {element.status === 'client contacted' && <div className='sub-btn'>
                                  <button disabled={!(permessionAccessAprRej && permessionAccessAprRej[0] && permessionAccessAprRej[0].value)} onClick={() => {
                                    setContactAproveReject(true);
                                    setApproveStatus(true);
                                    setContactpopups(false)
                                  }} className={`approve-kit-btn ${(attemptArray[index].contact_type !== '' && attemptArray[index].date !== '' && attemptArray[index].time !== '' && attemptArray[index].status !== '') ? '' : 'disable'}`}>Approve</button>
                                  <button disabled={!(permessionAccessAprRej && permessionAccessAprRej[0] && permessionAccessAprRej[0].value)} onClick={() => {
                                    setContactAproveReject(true);
                                    setRejectStatus(true)
                                    setContactpopups(false)
                                  }} className={`approve-kit-btn reject ${(attemptArray[index].contact_type !== '' && attemptArray[index].date !== '' && attemptArray[index].time !== '' && attemptArray[index].status !== '') ? '' : 'disable'}`}>Reject</button>
                                </div>}
                                <div className='border-style' />
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <span className="errorMessage">{errorMsg}</span>
                    <div className="modal-footer">
                      {!permission &&
                        <>
                          {!attemptWarning && (attemptArray.filter((e: any) => e.status === 'client contacted').length === 0) && (
                            <div className='col text-left'>
                              <button
                                type="button"
                                className={`attempt-btn ${attemptArray.every((obj: any) => obj.contact_type && obj.date && obj.time && obj.status) ? '' : 'disable'}`}
                                onClick={e => addAttempt()}
                              >
                                <span className='d-flex'><img src={addIcon} width='32px' height='32px' alt='add_icon' /><span className='pl-3 pt-1'>Add new attempt</span></span>
                              </button>
                            </div>
                          )}
                          {attemptWarning && (
                            <div className='warning-msg-attempt col text-left'>
                              <div><img width='32px' height='32' src={infoIcon} alt='info_icon' /></div>
                              <span>All 10 attempts have been added</span>
                            </div>
                          )}
                        </>

                      }
                      {attemptArray.length <= 10 && <div>
                        <button
                          type="button"
                          className={`btn btn-primary popup-btn ${attemptArray.every((obj: any) => obj.contact_type && obj.date && obj.time && obj.status) ? '' : 'disable'}`}
                          onClick={saveContactAttempt}
                        >
                          Save
                        </button>
                        <div className="btn btn-primary popup-btn cancel-btn" onClick={(e) => { props.close; window.location.reload() }}>
                          Close
                        </div>
                      </div>}
                    </div>
                  </div>
                </div>
              </div >
            </>
          ) : (<></>)

    // Thank you popup of request change //

    // create new role popup 14th page XD //

    // Manage roles 15th page XD //
    // showRequest ? (
    //     <div className="modal d-block bg-color-popup " role="dialog">
    //         <div className="modal-dialog modal-dialog-centered modal-width modal-xl" role="document">
    //             <div className="modal-content model-bor">
    //                 <div className="modal-body body-pad">
    //                     <PopupHeader></PopupHeader>
    //                     <div className="form-group mt-4 parent-pos">
    //                     <div className='login-style1'>Nurse Manager</div>
    //                     <hr></hr>
    //                     </div>
    //                     <div className='popup-text content-height'> Reassign 4 people in this role </div>
    //                     <div className="modal-footer bor-none">
    //                         <div className='popup-btn close-pop' onClick={props.close}>Close</div>
    //                         <button type="button" className="btn btn-primary popup-btn login-btn">Confirm remove role</button>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // ) : (<></>)

    // Admin user and histoy Xd page 44 //

    /* pass super admin status XD page no 42 */

    // showRequest ? (
    //     <div className="modal d-block bg-color-popup body-h" role="dialog">
    //     <div className="modal-dialog modal-dialog-centered modal-width modal-xl" role="document">
    //         <div className="modal-content model-bor ">
    //             <div className="modal-body body-pad">
    //                 {/* you can the change popup header content dynamically using popup header component */}
    //                 <PopupHeader />
    //                 <div className='login-style1 text-left'><span className='dr-style'>Dr Leonard McCoy</span> to <span className='dr-style'> Dr Beverley Crusher</span></div>
    //                 <div className='popup-text text-left'>You will not be able to reverse this change, unless the person you pass your super admin status to passes it back. </div>
    //                 <hr />
    //                 <section>
    //                     <div className='popup-text text-left'>Select reason(s)</div>
    //                     <div className="form-check d-flex mt-3">
    //                         <div className='sub-rply pb-3 px-0'>
    //                             <label className="container">
    //                                 <input
    //                                     type='checkbox'
    //                                 />
    //                                 <span className="checkmark" />
    //                             </label>
    //                         </div>
    //                         <label className="form-check-label label-class mt-2">
    //                             Leaving job
    //                         </label>
    //                     </div>
    //                     <div className="form-check d-flex mt-3">
    //                     <div className='sub-rply pb-3 px-0'>
    //                             <label className="container">
    //                                 <input
    //                                     type='checkbox'
    //                                 />
    //                                 <span className="checkmark" />
    //                             </label>
    //                         </div>
    //                         <label className="form-check-label label-class mt-2">
    //                         No longer part of job role
    //                         </label>
    //                     </div>
    //                     <div className="form-check d-flex mt-3">
    //                     <div className='sub-rply pb-3 px-0'>
    //                             <label className="container">
    //                                 <input
    //                                     type='checkbox'
    //                                 />
    //                                 <span className="checkmark" />
    //                             </label>
    //                         </div>
    //                         <label className="form-check-label label-class mt-2">
    //                            Other (please specify)
    //                         </label>
    //                     </div>
    //                     {/* <div className="form-check pos-check">
    //                         <AdminCheckbox />
    //                         <label className="form-check-label label-class">
    //                             No longer part of job role
    //                         </label>
    //                     </div>
    //                     <div className="form-check pos-check">
    //                         <AdminCheckbox />
    //                         <label className="form-check-label label-class">
    //                             Other (please specify)
    //                         </label>
    //                     </div> */}
    //                 </section>
    //                 <div className="modal-footer footer-style">
    //                     <div className="form-group mr-auto ml-4">
    //                         <input type="email" className="form-control pop-inp" />
    //                     </div>
    //                     <div className='popup-btn close-pop' onClick={props.close}>Close</div>
    //                     <button type="button" className="btn btn-primary popup-btn login-btn">Pass super admin controls</button>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // </div>
    // ) : (<></>)
  )
}

export default Popup
