import React, { useState } from 'react'

import '../styles/Toggle.css';

interface ToggleProps2 {
    status: boolean
    name: string
    onChange: (status: boolean, name: string) => void
}

const ToggleNew: React.FC<ToggleProps2> = ({ status, name, onChange }) => {
    const [Checked, Setchecked] = useState(status ? true : false)
    return (
        <>
            <label className="switch">
            <span className='sr-only'>slider</span>
                <input type="checkbox" onChange={e => {Setchecked(e.target.checked); onChange(e.target.checked, name)}} checked={Checked} />
                <span className="slider round" />
            </label>
        </>
    );
}

export default ToggleNew;