import React, { useEffect } from 'react'

import { isBrowser } from 'react-device-detect'
import { Link } from 'react-router-dom';

import BrookLogo from '../../assets/images/brook_logo_new.svg';
import appUrls from '../../services/AppURLs';
import { history } from '../../utils/history';
import { SignPostingFooter } from './SignPostingFooter';

interface SwitchDesktopProps {}

export const SwitchDesktop: React.FC<SwitchDesktopProps> = () => {

    useEffect(() => {
        localStorage.clear();
        if(isBrowser){
            history.push('/');
        }
    }, [])
    
    return (
        <div className='mw-switch-desktop'>
            <div className="signpost-nav-bar">
            <Link to={appUrls.signPosting.help}>
                    <img src={BrookLogo} alt="Brook" />
                </Link>
            </div>
            <div className="signposting-page">
                <section className='signposting-section mt-5 mb-5'>
                    <div className="content-container equal-space-padding">
                        <div className='contianer-450 card card-style'>
                            <div className="card-body">
                                <p className="card-text">The Brook Staff hub cannot be accessed from a mobile device.</p>
                                <p className="card-text mt-5">You must use a Brook managed laptop or desktop computer.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <SignPostingFooter />
            </div>
        </div>
    );
}