import React, { useState } from 'react'

import Popup from '../../../../components/admin/popup';
import { SecNav } from '../../../../components/admin/SecNav';
import { TableView } from '../../../../components/admin/TableView';
import { TriNav } from '../../../../components/admin/TriNav';
import { AdminLayout } from '../../AdminLayout';
import { authManageSettingsSecNav, authManageSettingTriNav, authPriNavMenu } from '../../AdminOptions';
import FetchData from '../forms/FetchData';

interface OverviewProps {

}

export const Overview: React.FC<OverviewProps> = ({ }) => {
    const [showPopup, setshowPopup] = useState(false);

    const [UserData, setUserData] = useState({
        "user_id": "",
        "name": "",
        "email": "",
        "password_last_update": ""
    });

    const closeModal = () => {
        setshowPopup(false)
    }

    const App = FetchData(process.env.REACT_APP_SERVICE_BACKEND_URL + '/admin/get-brook-roles')(TableView)({ tableHead: { type: 'brook-roles', titles: ['Role', 'No of users in role','Actions'] } })
    const tabIndex=0;
    return (
        <AdminLayout getMenuList={authPriNavMenu} roleMode="Super admin mode" sideBar="Settings">
            <SecNav getMenuList={authManageSettingsSecNav} />
            <h1 className="page-heading"tabIndex={tabIndex}>Change settings</h1>
            <TriNav getMenuList={authManageSettingTriNav}>
                <button type="button" className="btn btn-primary" onClick={e => { setshowPopup(true) }}>Create new role</button>
            </TriNav>
            <App />
            <Popup showNewRoleRequest={showPopup} userdata={UserData} close={closeModal}  />
        </AdminLayout>
    );
}