import React, { useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import { Accordion } from '../components/accordian/Accordian'
import { Button } from '../components/button/Button'
import { STIRadioButton } from '../components/forms/STIRadioButton'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import '../styles/hepatitis.css'
import { stiKitLoaderPercentage } from '../utils/stiKitLoaderPercentage'

const hypetitisContent = [
  {
    title: 'I have had unprotected sexual contact (sex without a condom) with 6 or more people.',
  },
  {
    title: 'I have had unprotected anal sex (without a condom) with multiple partners.',
  },
  {
    title: 'I have had sex when I was too drunk or high to know for sure if it was safe.',
  },
  {
    title: 'I have injected drugs or had sexual contact with someone who injects drugs.',
  },
  {
    title: 'I have been injected with a needle against my will.',
  },
  {
    title:
      'I have had sexual contact with someone who lives in a country outside of Western Europe, North America, or Australia (where hepatitis is more common).',
  },
  {
    title:
      "I have experienced sexual assault (it's ok if you haven't reported it - we won't make you but we can offer you help and support if you want).",
  },
  {
    title: 'I have been paid for sex or I have paid someone else for sex.',
  },
]

const hepatitisOptions = [
  {
    label: 'One or more of these is true for me - please test my blood sample for hepatitis',
    value: 'one or more of these is true for me - please test my blood sample for hepatitis',
  },
  {
    label:
      "One or more of these is true for me but I am fully vaccinated against hepatitis so you don't need to test my blood sample for this",
    value:
      "one or more of these is true for me but i am fully vaccinated against hepatitis so you don't need to test my blood sample for this",
  },
  {
    label: 'None of these are true for me',
    value: 'none of these are true for me',
  },
  {
    label: 'I’m not sure',
    value: 'i am not sure',
  },
]
interface RiskOfHepatitisProps {
  Props: any
}

export const RiskOfHepatitis: React.FC<RiskOfHepatitisProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)
  const history = useHistory()
  const [hypetitisOption, setHypetitisOption] = useState('')

  useEffect(() => {
    // console.log('risk of hepatitis :>>', theme.quesObj)
    if (theme.quesObj && theme.quesObj['riskHepatitis']) {
      setHypetitisOption(theme.quesObj['riskHepatitis'])
    }
    themeDispatch({
      type: 'SET_PERCENTAGE',
      payload: { percentage: stiKitLoaderPercentage.RISK_HEPATITIS },
    })
  }, [])

  const handleOnclick = () => {
    themeDispatch({
      type: 'SET_QUES',
      payload: {
        quesObj: {
          ...theme.quesObj,
          riskHepatitis: hypetitisOption,
        },
      },
    })
    if (hypetitisOption === hepatitisOptions[0].value) {
      history.push('/hepatitis-vaccination')
    } else {
      history.push('/test-kit')
    }
  }

  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }

  const askingMeContent = (theme: any) => {
    return (
      <>
        <div className={`p3-style p2-style ${theme.theme}-txt-global`}>
          This question help us to work out whether we should test your blood sample for hepatitis, as there are certain
          factors that increase a person's risk. Your answer will help us know which type of test you need, but we will
          not know which statement is true.
        </div>
      </>
    )
  }

  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker
  const [radioId, setRadioId] = useState()

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById(`radiobtn ${radioId}`)
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  return (
    <div className={`bg-color hepatitis-style ${theme.theme}-global`}>
      <div className="brook-container pad-bot">
        <div className="text-to-speech">
          <div className="h-div mt-4">
            <h2 className={`${theme.theme}-txt-global page-header-txt`}>ORDER A TEST KIT</h2>
            <div className="speaker-i">
              <button
                className="no-background-border"
                onClick={(e) => {
                  TextToSpeech.getInstance().playVoice('text-to-speech')
                  setisAccessibilityVoiceOver(true)
                }}
              >
                <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
              </button>
            </div>
          </div>
          <div className="hepatitis-content-blk">
            <h1 className={`b1-style ${theme.theme}-txt-global`}>Assessing your risk of hepatitis</h1>
            <h4 className={`b4-style ${theme.theme}-txt-global hepatitis-subtitle mb-3`}>
              Please let us know if any of the statements below are true for you
            </h4>
            <h5 className={`b4-style ${theme.theme}-txt-global hepatitis-subtitle mb-3`}>In the past 6 months...</h5>
            <ul>
              {hypetitisContent.map((item: any, idx: number) => {
                return (
                  <li
                    key={`hepatitis-${idx}`}
                    className={`b1-style ${theme.theme}-txt-global label-font hepatitis-title`}
                  >
                    {item.title}
                  </li>
                )
              })}
            </ul>
            <h5 className={`b4-style ${theme.theme}-txt-global hepatitis-subtitle p-0 mt-5 mb-5`}>
              Please choose one answer
            </h5>
            <div className="sti-kit-radio-blk">
              {hepatitisOptions.map((option: any, idx: number) => {
                return (
                  <STIRadioButton
                    id={`radiobtn ${radioId}`}
                    label={option.label}
                    name={option.label}
                    checked={option.value === hypetitisOption}
                    value={hypetitisOption}
                    key={idx}
                    onChange={() => {
                      setHypetitisOption(option.value)
                      setRadioId(option.id)
                    }}
                    addClass={`${theme.theme}-txt-global`}
                  />
                )
              })}
            </div>
            <div>
              <Accordion
                themeName={theme.theme}
                panels={[
                  {
                    label: 'Why are you asking me this ?',
                    content: askingMeContent(theme),
                  },
                ]}
              />
            </div>
            <div className={`ft-tab brook-container ${theme.theme}-skin-color`}>
              <button className="float-left no-background-border pos-re" onClick={(e) => GoBackPage()}>
                <img src={backIcon} alt="back" />
              </button>
              <Button
                isDisabled={!(hypetitisOption && hypetitisOption.length)}
                buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
                buttonText="Next"
                onClick={handleOnclick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
