export type CommonAction =
  | {
    type: 'SET_THEME',
    payload: { themeName: string }
  } |
   {
    type: 'SET_STI_TYPE',
    payload: { sti_type: string }
  } |
  {
    type: 'PROCEED_NXT_PAGE',
    payload: { pageName: string }
  } |
  {
    type: 'SET_PERCENTAGE',
    payload: { percentage: string }
  } |
  {
    type: 'SET_AGE',
    payload: { age: number }
  } |
  {
    type: 'SET_NAME',
    payload: { first_name: string, last_name: string }
  } |
  {
    type: 'SET_DOB',
    payload: { date_of_birth: string }
  } |
  {
    type: 'SET_ADDRESS',
    payload: { address_line_1: string, address_line_2: string, address_line_3: string, town: string }
  } |
  {
    type: 'SET_PHONE',
    payload: { phone_no: string, email: string }
  } |
  {
    type: 'SET_EMAIL_NOTIFICATION',
    payload: { email_notification: boolean }
  } |
  {
    type: 'SET_EMAIL_CONFIRM',
    payload: { isEmailConfirm: boolean }
  } |
  {
    type: 'SET_QUES',
    payload: { quesObj: {} }
  } |
  {
    type: 'SET_POSTCODE',
    payload: { postcode: string }
  } |
  {
    type: 'SET_LOCAL_AUTHORITY',
    payload: { local_authority: string }
  } |
  {
    type: 'SET_THEME_INTIAL_VALUE',
  }

export interface ThemeState {
  theme?: string
  percentage?: string
  age?: number,
  first_name: string,
  last_name: string,
  date_of_birth: string,
  email: string,
  phone_no: string,
  alternate_phone_no: string,
  address_line_1: string,
  address_line_2: string,
  address_line_3: string,
  town: string,
  postcode: string,
  email_notification: boolean
  isemailContact: boolean,
  isPhoneContact: boolean,
  isEmailConfirm:boolean,
  local_authority: string,
  quesObj: any,
  sti_type?:string
}

export const initialState: ThemeState = {
  theme: 'Regular',
  percentage: '',
  age: 0,
  first_name: "",
  last_name: '',
  date_of_birth: '',
  email: '',
  phone_no: "",
  alternate_phone_no: '',
  address_line_1: '',
  address_line_2: '',
  address_line_3: '',
  town: '',
  postcode: '',
  isemailContact: false,
  isPhoneContact: false,
  isEmailConfirm:false,
  email_notification: false,
  local_authority: '',
  quesObj: {},
  sti_type:''
}

export const themeReducer = (
  state: ThemeState,
  action: CommonAction
): ThemeState => {
  switch (action.type) {
    case 'SET_THEME':
      return {
        ...state,
        theme: action.payload.themeName
      }
      case 'SET_STI_TYPE':
        return {
          ...state,
          sti_type: action.payload.sti_type
        }
    case 'SET_PERCENTAGE':
      return {
        ...state,
        percentage: action.payload.percentage
      }
    case 'SET_AGE':
      return {
        ...state,
        age: action.payload.age
      }
      case 'SET_EMAIL_CONFIRM':
      return {
        ...state,
        isEmailConfirm: action.payload.isEmailConfirm
      }
    case 'SET_NAME':
      return {
        ...state,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
      }
    case 'SET_DOB':
      return {
        ...state,
        date_of_birth: action.payload.date_of_birth
      }
    case 'SET_ADDRESS':
      return {
        ...state,
        address_line_1: action.payload.address_line_1,
        address_line_2: action.payload.address_line_2,
        address_line_3: action.payload.address_line_3,
        town: action.payload.town,
      }
    case 'SET_PHONE':
      return {
        ...state,
        phone_no: action.payload.phone_no,
        email: action.payload.email
      }
    case 'SET_EMAIL_NOTIFICATION':
      return {
        ...state,
        email_notification: action.payload.email_notification
      }
    case 'SET_QUES':
      return {
        ...state,
        quesObj: action.payload.quesObj
      }
    case 'SET_POSTCODE':
      return {
        ...state,
        postcode: action.payload.postcode
      }
    case 'SET_LOCAL_AUTHORITY':
      return {
        ...state,
        local_authority: action.payload.local_authority
      }
    case 'SET_THEME_INTIAL_VALUE':
      return {
        ...initialState
      }
    default:
      return state
  }
}