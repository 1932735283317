import React, { useState, useEffect } from 'react'

import { PermissionManagementModal } from '../../../../components/admin/PermissionManagementModal';
import Popup from '../../../../components/admin/popup';
import { SecNav } from '../../../../components/admin/SecNav';
// import { DropdownSubmodule } from '../../../../components/dropdownCascade/DropdownSubmodule';
import Loader from '../../../../components/Loader/loader'
import { CustomDataTable } from '../../../../components/table/CustomDataTable';
// import { archiveDotMenu } from '../../../../data/permission_management/managerList';
// import { archiveList } from '../../../../data/permission_management/userList';
import useAuthContext from '../../../../context/provider/AuthContext';
import apiFunctions from '../../../../services/api-service'
import { AdminLayout } from '../../AdminLayout';
import { authPriNavMenu, authManageSettingsSecNav } from '../../AdminOptions';
import { PermissionMgmtMenuList } from './PermissionMgmtMenuList';

interface ArchiveListProps {

}

export const ArchiveList: React.FC<ArchiveListProps> = ({ }) => {

  let url:any = process.env.REACT_APP_SERVICE_BACKEND_URL;
  const[loader,setLoader]= useState(true);
  const [existUser, serExistUser]=useState<any>({});
  const[archiveListData,setArchiveListData]= useState([]);
  const[showPoup,setShowpoup] = useState(false);
  const[staffdata,setStaffdata] = useState();

  const [internalState, setInternalState] = useState<any>({
    actionType: '',
    isModalVisible: false,
    user_ids: ''
  })

  const {state: { user },} = useAuthContext()
  const permissionData:any = user;
  let permissionAccess =permissionData &&  permissionData.user ? permissionData.user:permissionData;
  permissionAccess = permissionAccess && permissionAccess.permissions?.team_members.filter((ele:any)=>{return ele.name === 'unarchive_team_member'});
console.log(permissionAccess,'--ARchivepermissionAccess--');

  useEffect(() => {
    getArchiveUsersList()
  }, []);

  // Get Archive User lists
  const getArchiveUsersList = async () => {
    const URL = url + '/admin/get-all-archive-administrators'
    const response = await apiFunctions.get(URL);
    serExistUser(response.data.data.existUser)
    setArchiveListData(response.data.data.result);
    setLoader(false)
  }
  const getactionType = (selectedValue: string) => {
    setInternalState({
      ...internalState,
      actionType: selectedValue,
      isModalVisible: true
    });
  };

  // const renderVerticalDotMenu = () => {
  //   return (
  //     <DropdownSubmodule
  //       menus={archiveDotMenu}
  //       actionType={getactionType}
  //     />
  //   )
  // };

  const columns: any = [
    // {
    //   name: renderVerticalDotMenu(),
    //   maxWidth: '30px'
    // },
    {
      name: 'Name',
      maxWidth: '400px',
      selector: (row: any) => (<div className='d-flex'>
        <span className='name-column' onClick={() => showStaffdetails(row)}>{row.name}</span>
      </div>)
    },
    {
      name: 'Email',
      maxWidth: '500px',
      minWidth: '400px',
      selector: (row: any) => row.email,
    },
    {
      name: 'Action',
      cell: (row: any) => (
        <div className='d-flex'>
          <span className='edit-label' onClick={() => handleEdit(row)}>{permissionAccess && permissionAccess[0] && permissionAccess[0].value ? 'Unarchive' : '-'}</span>
        </div>
      )
    },

  ];

  const handleEdit = (selectedItem: any) => {
    setInternalState({
      ...internalState,
      isModalVisible: true,
      user_ids: selectedItem.id
    });
  };

  const handlePagination = (page: number) => {
    setInternalState({
      ...internalState,
      pageNumber: page,
      inc: page * 5 - 5
    });
  }

  const onRowSelected = (selectedRow: any) => {
    console.log('Print selected row :>>', selectedRow);
  };


  const handleCloseModal = () => {
    setInternalState({
      ...internalState,
      isModalVisible: false
    });
  };

  // User Archive
  const managersArchive = async () => {
    setLoader(true)
    const URL = url + '/admin/archive-unarchive-administrators'
    try {
      await apiFunctions.put(URL, { type: 'unarchive', user_ids: [internalState.user_ids] });
      await getArchiveUsersList();
      setInternalState({
        ...internalState,
        isModalVisible: false
      });
      setLoader(false)
    } catch (err) {
      setLoader(false)
      console.log(err);
    }
  };
  const getPermission = (P_Type: string) => {
    const permission = existUser && existUser.permissions && existUser.permissions.team_members ? existUser.permissions.team_members.filter((res: any) => { return res.name === P_Type }) : [];
    return permission.length && permission[0].value;
  }


const showStaffdetails = (uservalue:any)=>{
  setStaffdata(uservalue)
  setShowpoup(true)
}

const closeModal = () => {
  setShowpoup(false)
}

  return (
    <AdminLayout getMenuList={authPriNavMenu} roleMode="Super admin mode" sideBar="Settings">
      {loader && <Loader />}
      <SecNav getMenuList={authManageSettingsSecNav} />
      <div className='perm-mgmt-block'>
        <PermissionMgmtMenuList addMemebrPresmission={getPermission('create_team_member')} />
        <div className='perm-mgmt-content-blk archive-blk'>
          <h5 className='role-title'>Archive</h5>
          <CustomDataTable
            data={archiveListData}
            columns={columns}
            rowsPerPage={5}
            paginationTotalRows={archiveListData.length}
            onChangePagination={handlePagination}
            inc={internalState.inc}
            onRowSelected={onRowSelected}
            selectedRow={false}
          />
        </div>
        {/* render popup */}
        {internalState.isModalVisible ?
          <PermissionManagementModal
            title='Are you sure you want to unarchive selected team members ? '
            SelectType='unarchive'
            handleCloseModal={handleCloseModal}
            handlePositiveButton={managersArchive}

          /> : null}
          <Popup showAdminProfile={showPoup} close={closeModal} userdata={staffdata} />
      </div>
    </AdminLayout>
  );
}