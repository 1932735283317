import React, { useCallback, useEffect, useRef, useState } from 'react'

import dropdownIcon from '../../assets/images/dropdown.svg'
import { useLocationContext } from '../../context/provider/LocationContext'
import { getPostcodeRegion, getUserRegionLocations, updateUserRegionLocations } from '../../services/api/PostcodesAPI'
// import { Toggle } from '../admin/Toggle'
import ToggleNew from '../admin/Toogle2'

export function LocationAccordian({ }) {
  const [regionListSelect, setRegionListSelect] = useState([])
  const [dropDown, setDropDown] = useState(false)
  const { state: { locationList }, dispatch } = useLocationContext();

  useEffect(() => {
    async function getRegion() {
      try {
        const regionList = await getUserRegionLocations();
        if (regionList) {
          if (regionList.data.data.locations.length === 0 && localStorage.getItem('role') === '1') {
            const allLocationList = await getPostcodeRegion();
            if (allLocationList) {
              const modSkeleton: any = [];
              allLocationList.data.data.map((item: any) => modSkeleton.push({
                name: item,
                status: true
              }));
              dispatch({ type: 'SET_LOCATION', data: modSkeleton });
              setRegionListSelect(modSkeleton)
            }
          } else {
            dispatch({ type: 'SET_LOCATION', data: regionList.data.data.locations });
            setRegionListSelect(regionList.data.data.locations)
          }
        }
      } catch (error) {
        console.log('regionListError', error)
      }
    }
    getRegion()
  }, [])

  const updateLocation = useCallback(async (__val: any) => {
    try {
      let moifyLocations = __val.regionListSelect.map((loc: any) => {
        let tempLoc = { ...loc }
        if (loc.name === __val.name) {
          tempLoc.status = __val.status;
        }
        return tempLoc;
      })
      dispatch({ type: 'SET_LOCATION', data: moifyLocations });
      setRegionListSelect(moifyLocations);
      const updateStatus = await updateUserRegionLocations({ locations: moifyLocations });
      if (updateStatus) {
        window.location.reload();
      }
    } catch (error) {
      console.log('regionListError', error)
    }
  }, [])


  /**
   * Hook that alerts clicks outside of the passed ref
   */
  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDropDown(false);
          // console.log('you clicked outside');
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const tabIndex = 0;
  return (
    <div className="accordian-dropdown mr-4" ref={wrapperRef}>
      <div className="card card-w-h">
        <div className="card-head pos-relative" id="headingTwo">
          <p
            tabIndex={tabIndex}
            className="mb-0 collapsed header onkeyup"
            onClick={(e) => setDropDown(!dropDown)}
          >
            Select location(s)
          </p>
          <div className="arrow-icon-accordian">
            <img src={dropdownIcon} className="pos-arrow-appr lo-pos" alt="speaker" />
          </div>
        </div>
        <div id="collapseTwo" className={`collapse ${dropDown && 'in'}`} aria-labelledby="headingTwo" data-parent="#accordionExample">
          <div className="card-body d-flex flex-wrap sub-menu">
            {regionListSelect.map((location: any) => (
              <div key={Math.random()} className="d-flex align-items-center toggle-style" tabIndex={tabIndex}>
                <ToggleNew
                  status={location.status}
                  name={location.name}
                  onChange={(status, name) => updateLocation({ status, name, regionListSelect })}
                />{' '}
                <span className="ml-3">{location.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LocationAccordian
