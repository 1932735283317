import React, { useState } from 'react'

import { Helmet } from 'react-helmet-async'

import ServicesAll from '../../assets/images/all-any.png'
import Abortion from '../../assets/images/brook-abortion-icon.png'
import CondomsLubricant from '../../assets/images/brook-condoms-lubricant-icon.png'
import Contraception from '../../assets/images/brook-contraception-icon.png'
import Counselling from '../../assets/images/brook-counselling-icon.png'
import DrugAlcoholSupport from '../../assets/images/brook-drug-alcohol-support-icon.png'
import EmergencyContraception from '../../assets/images/brook-emergency-contraception-icon.png'
import Hiv from '../../assets/images/brook-hiv-icon.png'
import OtherTesting from '../../assets/images/brook-other-testing-icon.png'
import PepPrep from '../../assets/images/brook-pep-prep-icon.png'
import PhysicalAbuse from '../../assets/images/brook-physical-abuse-icon.png'
import Pregnancy from '../../assets/images/brook-pregnancy-icon.png'
import SexualHealthAdvice from '../../assets/images/brook-sexual-health-advice-icon.png'
import StiTesting from '../../assets/images/brook-sti-testing-icon.png'
import Vaccinations from '../../assets/images/brook-vaccinations-icon.png'
import { SignPostingFooter } from './SignPostingFooter'
import { SignPostingHeader } from './SignPostingHeader'
import '../../styles/SignpostingToolStyle/help.css'

interface HelpProps {}

export const Help: React.FC<HelpProps> = ({}) => {
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // })
  const [selectedRadio, setSelectedRadio] = useState('')

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRadio(event.target.value)
  }

  const noTab = -1
  return (
    <>
      <div className="signposting-page">
        <Helmet>
          <title>SPT | Help - Brook</title>
        </Helmet>
        <SignPostingHeader activeState="help" />
        <section className="signposting-section bg-red color-white">
          <div className="content-container equal-space-padding">
            <div className="">
              <h1 className="page-heading">URGENT HELP</h1>
              <div className="spacer-20" />
              <p className="section-desc">
                If you are concerned that your life or the life of someone else is in danger, you can call 999 for an
                ambulance, police, fire brigade or any other emergency service 24/7.
              </p>
              <div className="spacer-20" />
              <p className="section-desc">
                The 999 emergency number covers all of the UK and is free to call, but it should only be used in cases
                where there is a risk or threat to someone’s safety (including if someone is at risk of suicide).
              </p>
              <div className="spacer-20" />
              <p className="section-desc">
                The emergency services may be able to track the location of the phone used to make the call.
              </p>
              <div className="spacer-20" />
              <a href="#go_to_help" className="text-decoration-home" tabIndex={noTab}>
                <button className="s-btn-clr" type="submit">
                  I need help but it's not an emergency
                </button>
              </a>
            </div>
          </div>
        </section>
        <div className="zig-zag-pattern color-white bg-red" />
        <section className="signposting-section bg-white color-black">
          <div className="content-container equal-space-padding pb-0">
            <h1 className="page-heading">FIND A SEXUAL HEALTH SERVICE NEAR YOU</h1>
            <div className="spacer-20" />
            <p className="section-desc">What kind of care do you need?</p>
            <p className="section-desc">Choose a service below.</p>
            <div className="spacer-20" />
            <div className="row services-list">
              <div className="col-lg-6 col-xl-6 col-xxl-5">
                <div className="input-group-text">
                  <img src={ServicesAll} alt="Services All" className="services-label-img" />
                  <label>
                    <input
                      type="radio"
                      name="radio"
                      value="All/any"
                      checked={selectedRadio === 'All/any'}
                      onChange={handleRadioChange}
                    />
                    <span className="services-checkmark" />
                    All/any
                  </label>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.brook.org.uk/find-a-service-step-1/?serv=any"
                    className={`services-btn ${selectedRadio === 'All/any' ? 'inline-block' : 'hidden'}`}
                  >
                    Next
                  </a>
                </div>
              </div>

              <div className="col-lg-6 col-xl-6 col-xxl-5">
                <div className="input-group-text">
                  <img src={Abortion} alt="Abortion" className="services-label-img" />
                  <label>
                    <input
                      type="radio"
                      name="radio"
                      value="abortion"
                      checked={selectedRadio === 'abortion'}
                      onChange={handleRadioChange}
                    />
                    <span className="services-checkmark" />
                    Abortion referrals
                  </label>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.brook.org.uk/find-a-service-step-1/?serv=abortion-referrals"
                    className={`services-btn ${selectedRadio === 'abortion' ? 'inline-block' : 'hidden'}`}
                  >
                    Next
                  </a>
                </div>
              </div>

              <div className="col-lg-6 col-xl-6 col-xxl-5">
                <div className="input-group-text">
                  <img src={CondomsLubricant} alt="CondomsLubricant" className="services-label-img" />
                  <label>
                    <input
                      type="radio"
                      name="radio"
                      value="Condoms"
                      checked={selectedRadio === 'Condoms'}
                      onChange={handleRadioChange}
                    />
                    <span className="services-checkmark" />
                    Condoms & lubricant
                  </label>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.brook.org.uk/find-a-service-step-1?serv=condoms-lubricant"
                    className={`services-btn ${selectedRadio === 'Condoms' ? 'inline-block' : 'hidden'}`}
                  >
                    Next
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-xl-6 col-xxl-5">
                <div className="input-group-text">
                  <img src={Contraception} alt="Contraception" className="services-label-img" />
                  <label>
                    <input
                      type="radio"
                      name="radio"
                      value="Contraception"
                      checked={selectedRadio === 'Contraception'}
                      onChange={handleRadioChange}
                    />
                    <span className="services-checkmark" />
                    Contraception
                  </label>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.brook.org.uk/find-a-service-step-1?serv=contraception"
                    className={`services-btn ${selectedRadio === 'Contraception' ? 'inline-block' : 'hidden'}`}
                  >
                    Next
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-xl-6 col-xxl-5">
                <div className="input-group-text">
                  <img src={Counselling} alt="Counselling" className="services-label-img" />
                  <label>
                    <input
                      type="radio"
                      name="radio"
                      value="Counselling"
                      checked={selectedRadio === 'Counselling'}
                      onChange={handleRadioChange}
                    />
                    <span className="services-checkmark" />
                    Counselling
                  </label>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.brook.org.uk/find-a-service-step-1?serv=counselling"
                    className={`services-btn ${selectedRadio === 'Counselling' ? 'inline-block' : 'hidden'}`}
                  >
                    Next
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-xl-6 col-xxl-5">
                <div className="input-group-text">
                  <img src={DrugAlcoholSupport} alt="DrugAlcoholSupport" className="services-label-img" />
                  <label>
                    <input
                      type="radio"
                      name="radio"
                      value="Drug"
                      checked={selectedRadio === 'Drug'}
                      onChange={handleRadioChange}
                    />
                    <span className="services-checkmark" />
                    Drug & alcohol support
                  </label>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.brook.org.uk/find-a-service-step-1?serv=drug-alcohol-support"
                    className={`services-btn ${selectedRadio === 'Drug' ? 'inline-block' : 'hidden'}`}
                  >
                    Next
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-xl-6 col-xxl-5">
                <div className="input-group-text">
                  <img src={EmergencyContraception} alt="EmergencyContraception" className="services-label-img" />
                  <label>
                    <input
                      type="radio"
                      name="radio"
                      value="Emergency"
                      checked={selectedRadio === 'Emergency'}
                      onChange={handleRadioChange}
                    />
                    <span className="services-checkmark" />
                    Emergency contraception
                  </label>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.brook.org.uk/find-a-service-step-1?serv=emergency-contraception"
                    className={`services-btn ${selectedRadio === 'Emergency' ? 'inline-block' : 'hidden'}`}
                  >
                    Next
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-xl-6 col-xxl-5">
                <div className="input-group-text">
                  <img src={Hiv} alt="Hiv" className="services-label-img" />
                  <label>
                    <input
                      type="radio"
                      name="radio"
                      value="HIV"
                      checked={selectedRadio === 'HIV'}
                      onChange={handleRadioChange}
                    />
                    <span className="services-checkmark" />
                    HIV testing & support
                  </label>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.brook.org.uk/find-a-service-step-1?serv=hiv-testing-support"
                    className={`services-btn ${selectedRadio === 'HIV' ? 'inline-block' : 'hidden'}`}
                  >
                    Next
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-xl-6 col-xxl-5">
                <div className="input-group-text">
                  <img src={OtherTesting} alt="OtherTesting" className="services-label-img" />
                  <label>
                    <input
                      type="radio"
                      name="radio"
                      value="Other"
                      checked={selectedRadio === 'Other'}
                      onChange={handleRadioChange}
                    />
                    <span className="services-checkmark" />
                    Other testing
                  </label>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.brook.org.uk/find-a-service-step-1?serv=other-testing"
                    className={`services-btn ${selectedRadio === 'Other' ? 'inline-block' : 'hidden'}`}
                  >
                    Next
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-xl-6 col-xxl-5">
                <div className="input-group-text">
                  <img src={PepPrep} alt="PepPrep" className="services-label-img" />
                  <label>
                    <input
                      type="radio"
                      name="radio"
                      value="PrEP"
                      checked={selectedRadio === 'PrEP'}
                      onChange={handleRadioChange}
                    />
                    <span className="services-checkmark" />
                    PEP and/or PrEP
                  </label>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.brook.org.uk/find-a-service-step-1?serv=pep-and-or-prep"
                    className={`services-btn ${selectedRadio === 'PrEP' ? 'inline-block' : 'hidden'}`}
                  >
                    Next
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-xl-6 col-xxl-5">
                <div className="input-group-text">
                  <img src={Pregnancy} alt="Pregnancy" className="services-label-img" />
                  <label>
                    <input
                      type="radio"
                      name="radio"
                      value="Pregnancy"
                      checked={selectedRadio === 'Pregnancy'}
                      onChange={handleRadioChange}
                    />
                    <span className="services-checkmark" />
                    Pregnancy testing
                  </label>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.brook.org.uk/find-a-service-step-1?serv=pregnancy-testing"
                    className={`services-btn ${selectedRadio === 'Pregnancy' ? 'inline-block' : 'hidden'}`}
                  >
                    Next
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-xl-6 col-xxl-5">
                <div className="input-group-text">
                  <img src={StiTesting} alt="StiTesting" className="services-label-img" />
                  <label>
                    <input
                      type="radio"
                      name="radio"
                      value="STI_test"
                      checked={selectedRadio === 'STI_test'}
                      onChange={handleRadioChange}
                    />
                    <span className="services-checkmark" />
                    STI testing & treatment
                  </label>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.brook.org.uk/find-a-service-step-1?serv=sti-testing-treatment"
                    className={`services-btn ${selectedRadio === 'STI_test' ? 'inline-block' : 'hidden'}`}
                  >
                    Next
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-xl-6 col-xxl-5">
                <div className="input-group-text">
                  <img src={PhysicalAbuse} alt="PhysicalAbuse" className="services-label-img" />
                  <label>
                    <input
                      type="radio"
                      name="radio"
                      value="Sexual"
                      checked={selectedRadio === 'Sexual'}
                      onChange={handleRadioChange}
                    />
                    <span className="services-checkmark" />
                    Sexual assault help and support
                  </label>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.brook.org.uk/find-a-service-step-1?serv=sexual-assault-help-and-support"
                    className={`services-btn ${selectedRadio === 'Sexual' ? 'inline-block' : 'hidden'}`}
                  >
                    Next
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-xl-6 col-xxl-5">
                <div className="input-group-text">
                  <img src={SexualHealthAdvice} alt="SexualHealthAdvice" className="services-label-img" />
                  <label>
                    <input
                      type="radio"
                      name="radio"
                      value="health"
                      checked={selectedRadio === 'health'}
                      onChange={handleRadioChange}
                    />
                    <span className="services-checkmark" />
                    Sexual health advice
                  </label>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.brook.org.uk/find-a-service-step-1?serv=sexual-health-advice"
                    className={`services-btn ${selectedRadio === 'health' ? 'inline-block' : 'hidden'}`}
                  >
                    Next
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-xl-6 col-xxl-5">
                <div className="input-group-text">
                  <img src={Vaccinations} alt="Vaccinations" className="services-label-img" />
                  <label>
                    <input
                      type="radio"
                      name="radio"
                      value="Vaccinations"
                      checked={selectedRadio === 'Vaccinations'}
                      onChange={handleRadioChange}
                    />
                    <span className="services-checkmark" />
                    Vaccinations
                  </label>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.brook.org.uk/find-a-service-step-1?serv=vacc"
                    className={`services-btn ${selectedRadio === 'Vaccinations' ? 'inline-block' : 'hidden'}`}
                  >
                    Next
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="signposting-section bg-white" id="go_to_help">
          <div className="content-container equal-space-padding">
            <div className="container-450">
              <div className="dotted-border purple" />
              <div className="spacer-40" />
              <h1 className="page-heading">IF YOU NEED HELP, BUT IT'S NOT LIFE-THREATENING</h1>
              {/* <p>IF YOU NEED URGENT HELP</p> */}
              <p>
                Brook is unable to provide 24/7 support, but there are other organisations who might be able to help:
              </p>
              <div className="spacer-10" />
              <div className="helpline-blk">
                <h2 className="sub-header small purple">MEDICAL ADVICE</h2>
                <div className="spacer-20" />
                <div className="helpline-heading">
                  <p>NHS 111</p>
                  <div className="spacer-5" />
                  <p className="section-desc light">by dialling 111 (England and Wales, all-age)</p>
                </div>
                <div className="spacer-20" />
                <div className="helpline-heading">
                  <p>NHS 24</p>
                  <div className="spacer-5" />
                  <p className="section-desc light">call 08454 242424 (Scotland, all-age)</p>
                </div>
              </div>
              {/* <div className="spacer-40" />
                            <div className="dotted-border purple" /> */}
              <div className="spacer-40" />
              <div className="helpline-blk">
                <h2 className="sub-header small purple">EMOTIONAL SUPPORT AND MENTAL HEALTH</h2>
                <div className="spacer-20" />
                <div className="helpline-heading">
                  <a
                    rel="noreferrer"
                    className="sub-helpline-links"
                    target="_blank"
                    href="https://www.childline.org.uk/"
                  >
                    Childline
                  </a>
                  <div className="spacer-5" />
                  <p className="section-desc light">
                    <span>call 0800 1111 </span>/
                    <a
                      rel="noreferrer"
                      className="sub-helpline-links"
                      target="_blank"
                      href="https://www.childline.org.uk/get-support/"
                    >
                      email
                    </a>{' '}
                    /{' '}
                    <a
                      rel="noreferrer"
                      target="_blank"
                      className="sub-helpline-links"
                      href="https://www.childline.org.uk/get-support/1-2-1-counsellor-chat/"
                    >
                      {' '}
                      online chat{' '}
                    </a>{' '}
                    (<span>under 18s</span>){' '}
                  </p>
                </div>
                <div className="spacer-20" />
                <div className="helpline-heading">
                  <a rel="noreferrer" target="_blank" className="sub-helpline-links" href="https://www.samaritans.org/">
                    Samaritans
                  </a>
                  <div className="spacer-5" />
                  <p className="section-desc light">
                    {' '}
                    <span>call 116 123</span>/
                    <a
                      rel="noreferrer"
                      className="sub-helpline-links"
                      target="_blank"
                      href="https://www.samaritans.org/how-we-can-help-you/different-ways-you-can-get-touch/what-happens-when-i-email"
                    >
                      email
                    </a>{' '}
                    /{' '}
                    <a
                      rel="noreferrer"
                      className="sub-helpline-links"
                      target="_blank"
                      href="https://www.samaritans.org/branches"
                    >
                      visit
                    </a>{' '}
                    (<span>all-age</span>)
                  </p>
                </div>
                <div className="spacer-20" />
                <div className="helpline-heading">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.thecalmzone.net/"
                    className="sub-helpline-links"
                  >
                    CALM
                  </a>
                  <div className="spacer-5" />
                  <p className="section-desc light">
                    {' '}
                    <span>call 0800 58 58 58</span>/
                    <a
                      rel="noreferrer"
                      target="_blank"
                      className="sub-helpline-links"
                      href="https://www.thecalmzone.net/help/webchat/"
                    >
                      webchat
                    </a>{' '}
                    (<span>all-age</span>)
                  </p>
                </div>
                <div className="spacer-20" />
                <div className="helpline-heading">
                  <a rel="noreferrer" target="_blank" className="sub-helpline-links" href="https://switchboard.lgbt/">
                    Switchboard
                  </a>
                  <div className="spacer-5" />
                  <p className="section-desc light">
                    {' '}
                    <span>call 0300 330 0630</span> /{' '}
                    <a rel="noreferrer" className="sub-helpline-links" target="_blank" href="https://switchboard.lgbt/">
                      online chat{' '}
                    </a>{' '}
                    /{' '}
                    <span>
                      {' '}
                      email <a className="text-decoration-home">chris@switchboard.lgbt</a>{' '}
                    </span>{' '}
                    (<span>LGBTQ+, all-age</span> )
                  </p>
                </div>
                <div className="spacer-20" />
                <div className="helpline-heading">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    className="sub-helpline-links"
                    href="https://www.giveusashout.org/get-help/"
                  >
                    Shout
                  </a>
                  <div className="spacer-5" />
                  <p className="section-desc light">text Shout to 85258 (all-age)</p>
                </div>
                <div className="spacer-20" />
                <div className="helpline-heading">
                  <a rel="noreferrer" target="_blank" className="sub-helpline-links" href="https://www.themix.org.uk/">
                    The Mix
                  </a>
                  <div className="spacer-5" />
                  <p className="section-desc light">
                    {' '}
                    <span>call 0808 808 4994</span>/{' '}
                    <a
                      rel="noreferrer"
                      target="_blank"
                      className="sub-helpline-links"
                      href="https://www.themix.org.uk/get-support/speak-to-our-team/email-us"
                    >
                      email
                    </a>{' '}
                    /{' '}
                    <a
                      className="sub-helpline-links"
                      target="_blank"
                      href="https://www.themix.org.uk/get-support/speak-to-our-team"
                    >
                      online chat{' '}
                    </a>{' '}
                    (<span>under 25s</span> )
                  </p>
                </div>
              </div>
              <div className="spacer-40" />
              <div className="helpline-blk">
                <h2 className="sub-header small purple">DOMESTIC ABUSE AND SEXUAL VIOLENCE</h2>
                <div className="spacer-20" />
                <div className="helpline-heading">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    className="sub-helpline-links"
                    href="https://rapecrisis.org.uk/get-help/want-to-talk/"
                  >
                    Rape Crisis
                  </a>
                  <div className="spacer-5" />
                  <p className="section-desc light">
                    {' '}
                    <span>call 0808 802 9999</span> /{' '}
                    <a
                      rel="noreferrer"
                      className="sub-helpline-links"
                      target="_blank"
                      href="https://rapecrisis.org.uk/get-help/want-to-talk/"
                    >
                      online chat
                    </a>{' '}
                    (<span>16 and over</span>)
                  </p>
                </div>
                <div className="spacer-20" />
                <div className="helpline-heading">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    className="sub-helpline-links"
                    href="https://www.victimsupport.org.uk/"
                  >
                    Victim Support
                  </a>
                  <div className="spacer-5" />
                  <p className="section-desc light">
                    <span>call 0800 123 6600 </span>/{' '}
                    <a
                      target="_blank"
                      className="sub-helpline-links"
                      href="https://www.victimsupport.org.uk/help-and-support/get-help/support-near-you/live-chat"
                    >
                      online chat
                    </a>{' '}
                    (<span>all-age</span>)
                  </p>
                </div>
                <div className="spacer-20" />
                <div className="helpline-heading">
                  <a rel="noreferrer" target="_blank" className="sub-helpline-links" href="https://www.galop.org.uk/">
                    GALOP
                  </a>
                  <div className="spacer-5" />
                  <p className="section-desc light">call 0800 999 5428 (LGBTQ+, all-age)</p>
                </div>
                <div className="spacer-20" />
                <div className="helpline-heading">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    className="sub-helpline-links"
                    href="https://www.thesurvivorstrust.org/"
                  >
                    The Survivors Trust
                  </a>
                  <div className="spacer-5" />
                  <p className="section-desc light">call 08088 010 818 (all-age)</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="spacer-40" />
        <SignPostingFooter />
      </div>
    </>
  )
}
