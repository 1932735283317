
import { createContext, useReducer } from "react"

import { CommonAction, themeReducer, ThemeState, initialState } from "../reducers/ThemeReducer"

type ThemeContextProps = {
    theme: ThemeState
    themeDispatch: React.Dispatch<CommonAction>
}

export const ThemeContext = createContext<ThemeContextProps>({
    theme: initialState,
    themeDispatch: (_params: any) => initialState,
})

export function ThemeContextProvider(props: React.PropsWithChildren<{}>) {
    const [theme, themeDispatch] = useReducer(themeReducer, initialState)

    return <ThemeContext.Provider value={{ theme, themeDispatch }} {...props} />
}