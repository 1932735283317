import apiFunctions from '../api-service';

let siteUrl = process.env.REACT_APP_SERVICE_BACKEND_URL + '/';

export function getPostcodeRegion() {
  return apiFunctions.get(siteUrl + 'postcode/region').then((region) => {
    return region;
  });
}
export function getUserRegionLocations() {
  return apiFunctions.get(siteUrl + 'postcode/location').then((location) => {
    return location;
  });
}
export function updateUserRegionLocations(payload: any) {
  return apiFunctions.put(siteUrl + 'postcode/Userlocation', payload).then((location) => {
    return location;
  });
}
