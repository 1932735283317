import React, { useState } from 'react'

import '../../styles/SignpostingToolStyle/home.css'
import '../../styles/SignpostingToolStyle/havingsex.css'
import '../../styles/SignpostingToolStyle/emergencycontracption.css'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

import contraceptionIcon from '../../assets/images/contraception-icon.png'
import PersonIcon from '../../assets/images/georgie.png'
import appUrls from '../../services/AppURLs'
import { False } from './False'
import { SignPostingFooter } from './SignPostingFooter'
import { SignPostingHeader } from './SignPostingHeader'

// class Contraception extends Component {

export function Contraception({}) {
  const [TrueFn, ShowTruefn] = useState(false)
  const [FalseFn, ShowFalsefn] = useState(false)
  const [SubmitFn, ShowContent] = useState(false)
  const [ContraceptionFn, activeContraception] = useState(false)
  const [ImplantFn, activeImplant] = useState(false)
  const [TypesofcontraceptionFn, activeTypesofcontraception] = useState(false)
  function truefn() {
    ShowFalsefn(false)
    ShowTruefn(true)
  }
  function falseFn() {
    ShowFalsefn(true)
    ShowTruefn(false)
  }
  function submitFn() {
    if (ImplantFn || ContraceptionFn || TypesofcontraceptionFn) {
      ShowContent(true)
    }
  }
  function contraceptionFn() {
    ShowContent(false)
    activeContraception((contraceptionFn) => !contraceptionFn)
  }
  function pulloutFn() {
    ShowContent(false)
    activeImplant((ImplantFn) => !ImplantFn)
  }
  function stiFn() {
    ShowContent(false)
    activeTypesofcontraception((TypesofcontraceptionFn) => !TypesofcontraceptionFn)
  }
  const tabIndex = 0
  const noTab = -1
  // render() {
  return (
    <div className="signposting-page">
      <Helmet>
        <title>SPT | Contraception - Brook</title>
      </Helmet>
      <SignPostingHeader />
      <section className="signposting-section bg-blue  color-white">
        <div className="content-container equal-space-padding">
          <div className="container-540">
            <div className="d-flex align-items-center">
              <h1 className="page-heading">WHAT IS CONTRACEPTION?</h1>
              <div className="ml-4">
                <img className="" src={contraceptionIcon} alt="contraception Icon" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="signposting-section grey">
        <div className="content-container equal-space-padding px-0">
          <div className="contianer-450 card card-style">
            <div className="card-body">
              <p className="card-text">
                Contraception refers to the methods that are used to prevent pregnancy. Some methods of contraception
                (condoms and internal condoms) are also used to prevent sexually transmitted infections (STIs).
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="zig-zag-pattern color-purple" />
      <section className="signposting-section bg-purple">
        <div className="content-container equal-space-padding">
          <h1 className="section-head pad-10 pl-0">WHICH CONTRACEPTION IS RIGHT FOR YOU?</h1>
          <div className="sec-sub-desc pt-5">
            <p>
              {' '}
              What works best for you will depend on your preferences. Remember, you can always talk through your
              options with a doctor or your local sexual health service.
            </p>
          </div>
          <div className="spacer-20" />
          <div className="contr-btn-blk">
            <a target="_blank" href="https://www.brook.org.uk/topics/contraception/" tabIndex={noTab}>
              <button className="s-btn-clr contr-btn-pad" type="submit">
                Types of contraception
              </button>
            </a>
          </div>
        </div>
      </section>
      <div className="zig-zag-pattern color-white bg-purple" />
      <section className="signposting-section white">
        <div className="content-container equal-space-padding pb-0">
          <div className="d-flex img-adjust-pos-rel">
            <h1 className="section-head dark">GEORGIE’S DILEMMA</h1>
            <div className="pos-abl mt-3">
              <img className="float-right" src={PersonIcon} alt="Person Icon" />
            </div>
          </div>
          <div className="spacer-10" />
          <div className="sec-desc-2-color m-desc-top">
            <p className="sec-desc-sub-content">
              "Me and Ezra have been working up to having penis-in-vagina sex, and have reached a point where they can
              put their penis about an inch or two into my vagina, which is great!{' '}
            </p>
            <div className="spacer-20" />
            <p>
              But when we were just doing oral and hand stuff we didn’t need to think about pregnancy, whereas now we
              do. I want to feel safe and protected against pregnancy, but I don’t think I will be able to get a coil
              fitted because it will be too painful to have anything put that far into my vagina. Condoms work but I
              would rather not have to think about it every time."
            </p>
          </div>
          <div className="spacer-20" />
          <div className="dotted-border dark" />
        </div>
      </section>
      <section className="signposting-section white">
        <div className="spacer-20" />
        <div className="content-container equal-space-padding pt-0">
          <h1 className="section-head dark">WHAT SHOULD GEORGIE DO?</h1>
          <div className="sec-desc-2-color">
            <div className="spacer-20" />
            <p>Select all the answers you think are correct.</p>
          </div>
          <div className="spacer-20" />
          <a className="text-decoration-home">
            <button
              type="button"
              className={'content-button mb-4 btn-lg btn-block' + (ContraceptionFn ? ' triple-btn' : '')}
              onClick={contraceptionFn}
            >
              Look into the contraceptive pill{' '}
            </button>
          </a>
          <a className="text-decoration-home">
            <button
              type="button"
              className={'content-button mb-4 btn-lg btn-block' + (ImplantFn ? ' triple-btn' : '')}
              onClick={pulloutFn}
            >
              Look into getting the implant{' '}
            </button>
          </a>
          <a className="text-decoration-home">
            <button
              type="button"
              className={'content-button mb-4 btn-lg btn-block' + (TypesofcontraceptionFn ? ' triple-btn' : '')}
              onClick={stiFn}
            >
              Learn about all types of contraception{' '}
            </button>
          </a>
          <a className="text-decoration-home" href="#gotoanswer" onClick={submitFn} tabIndex={noTab}>
            <button className="s-btn-clr mb-3" type="submit">
              <b>Submit</b>
            </button>
          </a>
          {SubmitFn && (
            <div className="answer" id="gotoanswer">
              <div className="spacer-20" />
              <p className="view-answer">ANSWER</p>
              <p>
                There are several different types of contraception that Georgie might be able to use, even if they don’t
                want to insert anything into their vagina. The contraceptive pill or implant are both options, but
                Georgie could also look into the patch or the injection.
              </p>
              <div className="spacer-20" />
              <p>
                It might take Georgie a bit of time to find the contraception that's right for them, but their local
                sexual health service will be able to offer advice. They can also do their own research - the Brook
                website has loads of information about the different types of contraception available.
              </p>
              <div className="spacer-20" />
              <p>
                Whilst Georgie is exploring contraception options, they should stick to using condoms. Condoms are free
                from Brook and other sexual health services, and from some GP surgeries. You can also buy condoms (even
                if you’re under 16) from pharmacies, supermarkets, vending machines in public toilets and online.
              </p>
              <div className="spacer-20" />
              <p>
                On top of preventing pregnancy, condoms are the only method of contraception that also protects against
                STIs, which Georgie and Ezra both need to think about.
              </p>
              <div className="spacer-20" />
              <p>
                Georgie might also want to consider seeking some support around the pain they experience when inserting
                things into their vagina. It’s possible that they have something called vaginismus, which is when the
                vagina suddenly tightens up when you try to insert something into it. Whatever the cause, pain and
                discomfort in the vagina or vulva is not something Georgie should have to live with and should not be
                ignored.
              </p>
              <div className="spacer-20" />
              <a href="https://www.brook.org.uk/topics/contraception/" target="_blank" className="link-style">
                Explore the different types of contraception
              </a>
              <div className="spacer-20" />
              <a
                href="https://www.brook.org.uk/your-life/having-great-sex-safely/"
                target="_blank"
                className="link-style"
              >
                Check out our guidance on how to have great sex (safely!)
              </a>
              {/* <div className="spacer-20" />
                            <a href='https://www.brook.org.uk/your-life/vaginal-pain' target='_blank' className='link-style'>Find out more about vaginismus and vaginal pain</a> */}
            </div>
          )}
          <div className="spacer-10" />
          {/* {!SubmitFn &&
                        <div className='sec-desc-2-color'>
                            <p>Select as many as you think!</p>
                            <div className="spacer-10" />
                            <a href='https://www.brook.org.uk/your-life/vaginismus-rosies-story/' target='_blank' className='link-style'>What is vaginismus?</a>
                            <div className="spacer-20" />
                        </div>
                    } */}
        </div>
      </section>
      <section className="signposting-section bg-purple">
        <div className="content-container equal-space-padding pb-0">
          <h1 className="section-head">MYTHBUSTING</h1>
          <div className="spacer-20" />
          <div className="pos-relative">
            <div className="s-body bubble-box light-violet bg-bubble"> CONTRACEPTION</div>
            <div className="chat-bubble-arrow chat-blue" />
            <div className="spacer-10" />
            <div className="myth-chat-bubble chat-bubble-border light-violet">“Getting an implant will hurt.”</div>
          </div>
          <div className="container-450">
            {!TrueFn && !FalseFn ? (
              <div>
                <div className="spacer-40" />
                <p className="section-desc section-desc-2-color">What do you think?</p>
                <div className="spacer-10" />
                <div className="dotted-border" />
              </div>
            ) : (
              ''
            )}
            <div className="true-false-blk">
              <button className="s-btn-clr m-0" type="submit" onClick={truefn}>
                True
              </button>
              <p>or</p>
              <button className="s-btn-clr" type="submit" onClick={falseFn}>
                False
              </button>
            </div>
            {TrueFn && (
              <div>
                <False type="myth-contraception-1" />
              </div>
            )}
            {FalseFn && (
              <div>
                <False type="myth-contraception-1" />
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="signposting-section white">
        <div className="content-container equal-space-padding">
          <h1 className="section-head dark">NEED URGENT HELP?</h1>
          <div className="spacer-10" />
          <div className="section-desc sec-desc-2-color">
            If you are worried about yourself or someone else, there are lots of places that can offer help and support.
          </div>
          <div className="spacer-20" />
          <Link className="text-decoration-home mr-3 s-btn-clr" to={appUrls.signPosting.help}>
            Find help
          </Link>
        </div>
      </section>
      <section className="signposting-section bg-purple">
        <div className="spacer-30" />
        <div className="content-container equal-space-padding pt-0">
          <h1 className="section-head">OTHER PEOPLE ASK…</h1>
          <div className="spacer-20" />
          <div>
            <a
              target="_blank"
              href="https://www.brook.org.uk/your-life/having-sex-for-the-first-time/"
              className="ask-link"
            >
              I want to know more about having sex for the first time
            </a>
          </div>
          <div className="spacer-10" />
          <div>
            <a target="_blank" href="https://www.brook.org.uk/topics/stis/" className="ask-link">
              {' '}
              I want to know more about STIs and getting tested{' '}
            </a>
          </div>
          <div className="spacer-10" />
          <div>
            <a target="_blank" href="https://www.brook.org.uk/topics/sex/" className="ask-link">
              I want to prepare myself for having sex with someone new
            </a>
          </div>
          <div className="spacer-30" />
          <div className="dotted-border" />
        </div>
      </section>
      <SignPostingFooter />
    </div>
  )
  // }
}
export default Contraception
