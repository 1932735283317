import React, { useCallback, useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import backIcon from '../assets/images/Back_Button.svg'
import SoundIcon from '../assets/images/sound_icon.svg'
import Icon from '../assets/images/Speaker_Icon.svg'
import { Button } from '../components/button/Button'
import TextToSpeech from '../components/textToSpeech/TextToSpeech'
import { ThemeContext } from '../context/provider/ThemeContext'
import '../styles/confirmaddress.css'

interface ConfirmAddressProps {
  children: any
}

export const ConfirmAddress: React.FC<ConfirmAddressProps> = (Props: any) => {
  const { theme, themeDispatch } = useContext(ThemeContext)

  const proceed = useCallback(() => {
    TextToSpeech.getInstance().playVoice(null, 'stop')
    Props.history.push('/confirm-email')
  }, [])

  const history = useHistory()

  function GoBackPage() {
    history.goBack()
    TextToSpeech.getInstance().playVoice(null, 'stop')
  }
  const [isAccessibilityVoiceOver, setisAccessibilityVoiceOver] = useState(false) // for voiceover on and off for speaker

  // this useEffect is only for Accessibility speaker button stop when give tab "START" //
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && isAccessibilityVoiceOver) {
        e.preventDefault()
        TextToSpeech.getInstance().playVoice(null, 'stop')
        setisAccessibilityVoiceOver(false)
        const nextElement = document.getElementById('confirmAddress_id')
        if (nextElement) {
          nextElement.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isAccessibilityVoiceOver])

  // this useEffect is only for Accessibility speaker button stop when give tab "End" //

  return (
    <div className={`bg-color confirmaddress-style dis-fl-none ${theme.theme}-skin-color`}>
      <div className="brook-container pad-bot form-width">
        <div className="text-to-speech">
          <div className="h-div mt-4">
            <h2 className={`page-header-txt ${theme.theme}-txt-global`}>ORDER A TEST KIT</h2>
            <div className="speaker-i">
              <button
                className="no-background-border"
                onClick={(e) => {
                  TextToSpeech.getInstance().playVoice('text-to-speech')
                  setisAccessibilityVoiceOver(true)
                }}
              >
                <img src={theme.theme === 'Calm' ? SoundIcon : Icon} alt="speaker" />
              </button>
            </div>
          </div>
          <div>
            <div className={`b1-style ${theme.theme}-txt-global`}>Confirm your details</div>
            <div className={`mar-p p2-style ${theme.theme}-txt-global`}>
              If this looks right, tap 'confirm'
              <br />
              If you need to change something, tap the relevant box or use the 'back' arrow.
            </div>
            <div className="mar-p">
              <textarea
                id="confirmAddress_id"
                onClick={(e) => {
                  Props.history.push('/address-form')
                }}
                className="form-control h-textbox onkeyup"
                onChange={(e) => {}}
                value={`\n\t${theme.first_name} ${theme.last_name} \n\t${theme.address_line_1} \n\t${theme.address_line_2} \n\t${theme.address_line_3}  \n\t${theme.town} \n\t${theme.postcode} `}
              />
            </div>
            <div className="mar-p">
              <textarea
                onClick={(e) => {
                  Props.history.push('/mobileNumber')
                }}
                className="form-control h-textbox onkeyup"
                onChange={(e) => {}}
                value={`\n\tMobile number \n\t${theme.phone_no} \n\n\tEmail address \n\t${theme.email}`}
              />
            </div>
          </div>
        </div>
        <div>
          <div className={`ft-tab brook-container ${theme.theme}-skin-color`}>
            <button className="float-left no-background-border" onClick={(e) => GoBackPage()}>
              <img src={backIcon} alt="back" />
            </button>
            <Button
              isDisabled={false}
              buttonClassName={`btn btn-default btn-main float-right ${theme.theme}-start-btn-global`}
              buttonText="Confirm"
              onClick={proceed}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
