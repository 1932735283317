export const pdf_html=`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <style>
    .main-container{
        max-width: 800px;
        margin: auto;
        padding: 30px 50px;
        font-family: Montserrat, sans-serif;
    }
    .main-container1{
        max-width: 800px;
        margin: auto;
        padding: 30px 50px;
        font-family: Montserrat, sans-serif;
    }
    .main{
       
    }
    .header{
        display: flex;
        align-items: center;
    }
    .header-contant{
        padding-right: 10px;
        font-size: 13px;
    }
    .body-top{
        font-size: 15px;
    }
    .body-contant-top{
        padding:0 0 20px 0;
    }
    .body-contant-top span{
        font-weight: bold;
    }
    .body-top hr{
        border: 1px solid;
        opacity: 0.5;
    }
    .body-contant{
        padding: 10px 0;
    }
    .body-bottom p:nth-child(1){
        border-bottom: 1px solid;
        width: fit-content;
        padding-bottom: 5px;
    }
        
    .body-contant-header{
        border-bottom: 1px solid;
        width: fit-content;
        padding-bottom: 5px;
    }
    .body-bottom span{
        font-weight:bold ;
    }
    .contant-align{
        display: grid;
        grid-template-columns: auto auto auto;
    }
    .contant-align div{
        padding-bottom: 5px;
    }
    .contant-align div:nth-child(even){
       // font-weight: bold;
    }
    .body-contant-bottom{
        padding-bottom: 80px;
    }
    .body-bottom hr{
        border: 1px dashed;
        opacity: 0.5;
    }
    .end{
        text-align: center;
    }
    .footer{
        display: grid;
        grid-template-columns: auto auto auto;
        justify-content: end;
    }
    .footer-right img{
        width: 40px;
    }
    .footer-warp{
        display: flex;
        flex-direction: column;
        justify-content: end;
    }
    .footer-left div{
        padding-bottom: 10px;
        font-size: 14px;
    }
    .footer-left div:nth-child(1){
        color: #652f91;
    }
    .footer-left div:nth-child(2){
        display: flex;
     //   align-items: center;
    }
    .footer-left img{
        margin: 10px 3px 0 25px;
    }
    .footer-bottom{
        font-size: 12px;
    }
    .positive-text{
        color:red;
    }
    </style>
</head>
<body>
        <div class="main-container" style="">
           <div class="header">
           <div class="header-contant"><b>IMPORTANT:</b> This report has been generated by Brook using results received from The Doctor’s
                Laboratory (TDL). If you have any queries, please contact the relevant Brook service in the first
                instance.</div>
            <div>
                <img src="<%= logo %>" alt="Brook logo" style="width: 200px;">
            </div>
        </div>
        <div class="main"  >
            <div class="body-top">
            <br>
                <p class="body-contant-top">Report date: <span><%= report_date %></span></p>

                <p class="body-contant-top" >Reference no.: <span><%= reference_no %></span></p>

                <div class="body-contant-top" ><p>Name: <span><%= name %></span></p>
                    <p>DOB: <span><%= age %></span></p>
                    <p>Genitals: <span><%= genitals %></span></p>
                    <p>Identify as: <span><%= gender %></span></p>
                    <p>Phone: <span><%= phone %></span></p>
                </div>

                <div class="body-contant-top" ><p>Brook service: <span><%= brook_service %></span></p>
                    <p>Initiator (site) code: <span><%= site_code %></span></p>
                    <p>Lab reference no.: <span><%= lab_reference_no %></span></p>
                    <p>Received: <span><%= received_date %></span></p>
                </div>
                <hr/>
            </div>
            <div class="body-bottom">
                <div class="body-contant">
                    <p>ENDOCRINOLOGY</p>
                    <p>Text Number: <span><%= phone %></span></p> 
                </div>
                <div class="body-contant-bottom">
                    <div class="body-contant-header">IMMUNOLOGY</div>
					   <div class="body-contant-header">Active</div>
                    <div class="contant-align">
                 
                        <% _.each(active_results, function(result){
                            var d=new Date(Date.parse(result.createdAt));
                            d.setMonth(d.getMonth() + 1);
                            var datastring=d.getDate()+'/'+d.getMonth()+'/'+d.getFullYear() %>
                            <% if(result.result_status==='POSITIVE'){%>
                            <div class="positive-text"><%= datastring %></div> <div class="positive-text"><%= result.result_type %></div><div class="positive-text" style="font-weight:bold"><%= result.result_text %></div>
                            <% }else{%>
                                <div  ><%= datastring %></div> <div><%= result.result_type %></div><div style="font-weight:bold"><%= result.result_text %></div>
                                <%}%>
                        <% }); %>
                    </div>
					  
                </div>
               
            </div>
           
            </div>
            <hr>
           
            <div class="footer">
                <div class="footer-warp">
                    <div class="footer-left">
                        <div>
                            brook.org.uk
                        </div>
                        <div>
                            Brook,PO Box 78732, London, EC2P 2TA <span> <img src="<%= twitter_logo %>" alt="twitter" width="15px"></span><span>@BrookCharity</span> 
                        </div>
                    </div>
                    <div class="footer-bottom">Brook is the trading name of Brook Young People. Registered office: Penhaligon House, Green Street, Truro, TR1 2LH.
                        Registered Charity in England and Wales No. 703015. Limited Company Registered in England and Wales No. 2466940.</div>
                </div>
                <div class="footer-right"><img src="<%= pdf_footer_text %>" alt=" logo"></div>
            </div>
        </div>
<div>
        <% if(inactive_results.length>0){ %>
        <div class="main-container1" style="margin-top:1px" >
           <div class="header">
           <div class="header-contant"><b>IMPORTANT:</b> This report has been generated by Brook using results received from The Doctor’s
                Laboratory (TDL). If you have any queries, please contact the relevant Brook service in the first
                instance.</div>
            <div>
                <img src="<%= logo %>" alt="Brook logo" style="width: 200px;">
            </div>
        </div>
            
        <div style="height: 700px;">
        <br>
					           <div class="body-contant-header">Inactive</div>
                    <div class="contant-align">
         
                    <% _.each(inactive_results, function(result){ 
                        var d=new Date(Date.parse(result.createdAt));
                        d.setMonth(d.getMonth() + 1);
                        var datastring=d.getDate()+'/'+d.getMonth()+'/'+d.getFullYear()
                        %>
                        <% if(result.result_status==='POSITIVE'){%>
                            <div class="positive-text"><%= datastring %></div> <div class="positive-text"><%= result.result_type %></div><div class="positive-text" style="font-weight:bold"><%= result.result_text %></div>
                            <% }else{%>
                                <div  ><%= datastring %></div> <div><%= result.result_type %></div><div style="font-weight:bold"><%= result.result_text %></div>
                                <%}%>
                        <% }); %>
                    
                </div>
         
              
            </div>
            <hr>
          
            
            <div class="footer">
                <div class="footer-warp">
                    <div class="footer-left">
                        <div>
                            brook.org.uk
                        </div>
                        <div>
                            Brook,PO Box 78732, London, EC2P 2TA <span> <img src="<%= twitter_logo %>" alt="twitter" width="15px"></span><span>@BrookCharity</span> 
                        </div>
                    </div>
                    <div class="footer-bottom">Brook is the trading name of Brook Young People. Registered office: Penhaligon House, Green Street, Truro, TR1 2LH.
                        Registered Charity in England and Wales No. 703015. Limited Company Registered in England and Wales No. 2466940.</div>
                </div>
                <div class="footer-right"><img src="<%= pdf_footer_text %>" alt=" logo"></div>
            </div>
        </div>
<% }%>
</div>
</body>
</html>`