import React, { useEffect, useRef, useState } from 'react'

import moment from 'moment'
import { components } from 'react-select'
import { AsyncPaginate } from 'react-select-async-paginate'

import closeIcon from '../../../../assets/images/Popup_close.svg'
import Tick from '../../../../assets/images/Tick.svg'
import { SecNav } from '../../../../components/admin/SecNav'
import Loader from '../../../../components/Loader/loader'
import apiFunctions from '../../../../services/api-service'
import { AdminLayout } from '../../AdminLayout'
import { authPriNavMenu, syncOrderTabsMenu } from '../../AdminOptions'
import '../../../../components/styles/syncUser.css'

interface SyncUserProps {}

interface User {
  email?: string
  date_of_birth?: string
  dob?: string
  phone_no?: string
  mobileNumber?: string
}

export const SyncUser: React.FC<SyncUserProps> = () => {
  const tabIndex = 0
  const [showModal, setShowModal] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [syncStaffHubPortal, setSyncStaffHubPortal] = useState<any>(null)
  const [searchDetails, setSearchDetails] = useState<string>('')
  const [count, setCount] = useState<number>()
  const currentRef = useRef('')

  const [syncWebPortalDetail, setSyncWebPortalDetail] = useState<any>(null)
  const [searchPortalDetails, setSearchPortalDetails] = useState<string>('')
  const [webPortalCount, setWebPortalCount] = useState<number>()
  const [mergeDataSuccessPopup, setMergeDataSuccessPopup] = useState<boolean> (false);
  const [popUpsuccessMessage, setPopUpsuccessMessage] = useState('')
  const comparisonResult = compareUsers(syncStaffHubPortal, syncWebPortalDetail)

  function compareUsers(obj1: User | null, obj2: User | null): { email: boolean; dob: boolean; mobileNumber: boolean } {
    if (!obj1 || !obj2) return { email: false, dob: false, mobileNumber: false }

    const emailMatch = obj1.email?.toLowerCase() === obj2.email?.toLowerCase()
    const dobMatch = moment(obj1.date_of_birth).format('DD/MM/YYYY') === moment(obj2.dob).format('DD/MM/YYYY')
    const mobileNumberMatch = obj1.phone_no === obj2.mobileNumber

    return {
      email: emailMatch,
      dob: dobMatch,
      mobileNumber: mobileNumberMatch,
    }
  }

  const matchedDetails = [
    {
      label: 'Phone',
      value: comparisonResult.mobileNumber,
    },
    {
      label: 'Email',
      value: comparisonResult.email,
    },
    {
      label: 'DOB',
      value: comparisonResult.dob,
    },
  ]

  // Starting with the Staff HUB functionality of the first dropdown select...
  const renderSelectedOption = (item: any) => {
    return (
      <div className="flex">
        <p>{`${item.first_name + ' ' + item.last_name}`}</p>
      </div>
    )
  }

    useEffect(() => {
      fetchRecipientListCount()
      fetchPortalRecipientListCount()
    }, [mergeDataSuccessPopup]);


    const fetchRecipientListCount = async () => {
      setShowLoader(true)   
      try {
        const response = await apiFunctions.get(
          `${process.env.REACT_APP_SERVICE_BACKEND_URL}/third-party/get-staffhub-users`
        )
        setCount(response?.data?.total_length);
        setShowLoader(false);
        
      } catch (error) {
        console.error('Error fetching recipient list:', error)
        setShowLoader(false)
      }
    }

  const fetchRecipientList = async (searchQuery: string, loadedOptions: any, { page }: any) => {   
    const limit = 50
    try {
      currentRef.current = searchQuery
      const response = await apiFunctions.get(
        `${process.env.REACT_APP_SERVICE_BACKEND_URL}/third-party/get-staffhub-users?search=${encodeURIComponent(searchQuery)}&limit=${limit}&offset=${page}`
      ) 
      return {
        options: response?.data?.data || [],
        hasMore: response?.data?.data.length >= 1,
        additional: {
          page: currentRef.current !== searchQuery ? 1 : page + 1,
        },
      }
    } catch (error) {
      console.error('Error fetching recipient list:', error)
    }
  }

  const CustomOption = (props: any) => {
    const { data } = props
    return (
      <components.Option {...props}>
        <div className="p-2">
          <span>
            Name: <span className="font-bold">{`${data?.first_name + ' ' + data.last_name}`}</span>
          </span>
          <br />
          <span>
            Phone: <span className="font-bold">{data?.phone_no}</span>
          </span>
          <br />
          <span>
            Email: <span className="font-bold">{data?.email}</span>
          </span>
          <br />
          <span>
            DOB: <span className="font-bold">{moment(data?.date_of_birth).format('DD/MM/YYYY')}</span>
          </span>
          <br />
          <span>
            Reference ID: <span className="font-bold">{data?.referenceNo}</span>
          </span>
          <br />
        </div>
      </components.Option>
    )
  }

  const handleInputChange = (newValue: string) => {
    setSearchDetails(newValue)
  }

  const handleSelectChange = (selectedOption: any) => {
    setSyncStaffHubPortal(selectedOption)
    setSearchDetails('')
  }
  // Ending with the Staff HUB functionality of the first dropdown select...

  // Starting with the Web Portalfunctionality of the first dropdown select...
  const renderPortalSelectedOption = (item: any) => {
    return (
      <div className="flex">
        <p>{`${item.firstName + ' ' + item.lastName}`}</p>
      </div>
    )
  }

  const fetchPortalRecipientListCount = async () => {
    setShowLoader(true)
    try {
      const response = await apiFunctions.post(
        `${process.env.REACT_APP_SERVICE_BACKEND_URL}/third-party/get-patient-portal-users`,
        {}
      )
      setWebPortalCount(response?.data?.total_length)
      setShowLoader(false)
    } catch (error) {
      console.error('Error fetching recipient list:', error)
      setShowLoader(false)
    }
  }

  const fetchPortalRecipientList = async (searchQuery: string, loadedOptions: any, { page }: any) => {
    const limit = 50
    try {
      currentRef.current = searchQuery
      const response = await apiFunctions.post(
        `${process.env.REACT_APP_SERVICE_BACKEND_URL}/third-party/get-patient-portal-users`,
        {
          search: searchQuery,
          limit,
          offset: page
        }
      )
      return {
        options: response?.data?.data || [],
        hasMore: response?.data?.data.length >= 1,
        additional: {
          page: currentRef.current !== searchQuery ? 1 : page + 1,
        },
      }
    } catch (error) {
      console.error('Error fetching recipient list:', error)
    }
  }

  const CustomPortalOption = (props: any) => {
    const { data } = props
    return (
      <components.Option {...props}>
        <div className="p-2">
          <span>
            Name: <span className="font-bold">{`${data?.firstName + ' ' + data.lastName}`}</span>
          </span>
          <br />
          <span>
            Phone: <span className="font-bold">{data?.mobileNumber}</span>
          </span>
          <br />
          <span>
            Email: <span className="font-bold">{data?.email}</span>
          </span>
          <br />
          <span>
            DOB: <span className="font-bold">{moment(data?.dob).format('DD/MM/YYYY')}</span>
          </span>
          <br />      
        </div>
      </components.Option>
    )
  }

  const handlePortalInputChange = (newValue: string) => {
    setSearchPortalDetails(newValue)
  }

  const handlePortalSelectChange = (selectedOption: any) => {
    setSyncWebPortalDetail(selectedOption)
    setSearchPortalDetails('')
  }
  // Ending with the Web Portal functionality of the second dropdown select...

const dataMergedAccount = async () => {
  setShowLoader(true)
  const payload = {
    merge_details: {
      staff_id: syncStaffHubPortal.id,
      staff_email: syncStaffHubPortal.email,
      staff_dob: syncStaffHubPortal.date_of_birth,
      staff_phone_no: syncStaffHubPortal.phone_no,
      staff_referenceNo: syncStaffHubPortal.referenceNo,

      pp_uuid: syncWebPortalDetail.uuid,
      pp_email: syncWebPortalDetail.email,
      pp_dob: syncWebPortalDetail.dob,
      pp_phone_no: syncWebPortalDetail.mobileNumber,
      pp_reference_id: syncWebPortalDetail.reference_id,
      pp_qbthirdpartyid : syncWebPortalDetail.qbThirdPartyId
    },
  }

  try {
    const response = await apiFunctions.put(
      `${process.env.REACT_APP_SERVICE_BACKEND_URL}/third-party/staff_portal_manual_merge`,
      payload
    )
    setPopUpsuccessMessage(response?.data?.message);
    console.log(response?.data?.message)
    setMergeDataSuccessPopup(true); 
    setShowModal(false)
    setShowLoader(false)
  } catch (error) {
    setShowLoader(false)
    console.error('Error fetching recipient list:', error)
  }
}


  return (
    <>
      {showLoader && <Loader />}
      <AdminLayout getMenuList={authPriNavMenu} roleMode="Super admin mode">
        <SecNav getMenuList={syncOrderTabsMenu} />
        <br />
        <br />
        <h2 className="day-text" tabIndex={tabIndex}>
          SYNC PORTAL ACCOUNTS
        </h2>
        <br />
        <br />
        <div className="grid-blk">
          <div>
            <h3 className="test-kit-heading">Count : {count}</h3>
            <AsyncPaginate
              value={syncStaffHubPortal}
              className="secondary-select"
              classNamePrefix="react-select"
              components={{ Option: CustomOption }}
              loadOptions={fetchRecipientList}
              onChange={handleSelectChange}
              onInputChange={handleInputChange}
              placeholder="Test result"
              formatOptionLabel={renderSelectedOption}
              inputValue={searchDetails}
              isClearable={true}
              additional={{
                page: 1, // Start with page 1
              }}
            />
          </div>

          <div>
            <h3 className="test-kit-heading">Count : {webPortalCount}</h3>
            <AsyncPaginate
              value={syncWebPortalDetail}
              className="secondary-select"
              classNamePrefix="react-select"
              components={{ Option: CustomPortalOption }}
              loadOptions={fetchPortalRecipientList}
              onChange={handlePortalSelectChange}
              onInputChange={handlePortalInputChange}
              placeholder="My Brook account"
              formatOptionLabel={renderPortalSelectedOption}
              inputValue={searchPortalDetails}
              isClearable={true}
              additional={{
                page: 1, // Start with page 1
              }}
            />
          </div>
        </div>

        <div className="h-20" />
        <div className="merge-blk">
          <button
            disabled={!syncStaffHubPortal || !syncWebPortalDetail ? true : false}
            type="button"
            className="btn btn-primary login-btn"
            onClick={() => setShowModal(true)}
          >
            Merge accounts
          </button>
        </div>

        {showModal && (
          <div className="modal d-block bg-color-popup" role="dialog">
            <div className="modal-dialog contact-attempt-popup  modal-dialog-centered" role="document">
              <div className="modal-content model-bor1 ">
                <div className="modal-body body-pad pb-0">
                  <div className="login-style1" tabIndex={tabIndex}>
                    Confirm sync accounts
                  </div>
                  <div className="close-white-pos" onClick={() => setShowModal(false)}>
                    <img className="close-white" src={closeIcon} alt="closeIcon" />
                  </div>
                  <br />
                  <div>
                    <div className="text-head-color" tabIndex={tabIndex}>
                      When you click confirm, both the accounts will be synced into one. Please make sure all the
                      details have been checked properly before this action.
                    </div>
                    <div className="text-account tick-flex" tabIndex={tabIndex}>
                      <div>Match found:</div>
                      {matchedDetails.some((detail) => detail.value) ? (
                        <>
                          {matchedDetails.map((item: any, index: number) => (
                            <>
                              {item.value && (
                                <label key={index} htmlFor="" className="tick">
                                  <span className=" flex ml-2">
                                    <img className="" src={Tick} alt="tick-icon" />
                                  </span>
                                  <span className="ml-3">{item.label}</span>
                                </label>
                              )}
                            </>
                          ))}
                        </>
                      ) : (
                        <div>Data doesn't match</div>
                      )}
                    </div>
                    <br />
                    <div className="confirm-blk">
                      <button type="button" className="btn btn-primary login-btn" onClick={() => dataMergedAccount()}>
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {mergeDataSuccessPopup ? (
          <div className="modal d-block bg-color-popup" role="dialog">
            {showLoader && <Loader />}
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content model-bor">
                <div className="modal-body body-pad">
                  <div>
                    <div className="popup-text">{popUpsuccessMessage}</div>
                  </div>
                  <div className="modal-footer bor-none">
                    <div
                      className="popup-btn close-pop"
                      onClick={() => {
                        window.location.reload()
                        setMergeDataSuccessPopup(false)
                        setSyncStaffHubPortal('')
                        setSyncWebPortalDetail('')
                      }}
                    >
                      Close
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </AdminLayout>
    </>
  )
}

export default SyncUser
