import React, { createContext, useState } from 'react'

import helperService from '../../services/helper-service'

const TinaAuthContext = createContext({
  isTinaAdminAuthenticated: false,
  tinaAdminUserData: { username: '', _id: '' },
  toggleTinaAdminAuthenticated: () => {},
  changeTinaAdminUserData: () => {},
})

export function TinaAuthContextProvider(props: React.PropsWithChildren<{}>) {
  const [isTinaAdminAuthenticated, changeAuthenticationStatus] = useState(
    helperService.isAuthenticatedAdmin()
  )
  const [tinaAdminUserData, modifyTinaUserData] = useState(
    helperService.getAdminUserData()
  )
  const toggleTinaAdminAuthenticated = () =>
    changeAuthenticationStatus(helperService.isAuthenticatedAdmin())
  const changeTinaAdminUserData = () =>
    modifyTinaUserData(helperService.getAdminUserData())

  return (
    <TinaAuthContext.Provider
      value={{
        isTinaAdminAuthenticated,
        tinaAdminUserData,
        toggleTinaAdminAuthenticated,
        changeTinaAdminUserData,
      }}
      {...props}
    />
  )
}

export default TinaAuthContext
