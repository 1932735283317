import React from 'react'

interface PermissionManagementModalProps {
  handleCloseModal: any;
  title: string;
  handlePositiveButton?: any;
  SelectType?:string;
  userRoleChange?:any
}

export const PermissionManagementModal: React.FC<PermissionManagementModalProps> = ({
  title = '',
  handleCloseModal,
  handlePositiveButton,
  SelectType,
  userRoleChange
}) => {
  const tabIndex = 0;

  return (
    <div className="modal d-block bg-color-popup perm-modal" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content model-bor">
          <div className="modal-body p-0">
            <div className='modal-header'>
              <div className="popup-text" tabIndex={tabIndex}>
                {title}
              </div>
            </div>
            <hr />
            <div className="modal-footer bor-none p-0">
              <button
                type="button"
                onClick={()=>{
                  if(SelectType === "archive" || SelectType === "unarchive" ){
                    handlePositiveButton()
                  }
                  else{
                    userRoleChange()
                  }
                }}
                className="btn btn-primary login-btn cancel-btn">
                Yes, {SelectType === "archive" ? "archive": SelectType === "unarchive" ? "un archive":"change account type"}
              </button>
              <button
                type="button"
                onClick={handleCloseModal}
                className="btn btn-primary login-btn cancel-btn">
                No, cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}