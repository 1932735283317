
import { ILocation } from "../../models/Types";

export interface LocationState {
  isLoading: boolean;
  locationList: ILocation[];
}

export const initialState: LocationState = {
  isLoading: false,
  locationList: [],
};


// a reducer give back a state
export const locationReducer = (state: LocationState, action: any) => {
  switch (action.type) {
    case 'SET_LOCATION':
      // console.log('location reducer :>>', action);
      return { 
        ...state, 
        locationList: action.data
      };
    default:
      return state;
  }
};