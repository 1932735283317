import React, { useCallback, useState } from 'react'

import '../styles/ApproveKit.css'
import useAuthContext from '../../context/provider/AuthContext'
import apiFunctions from '../../services/api-service'
import Loader from '../Loader/loader'
import { Flags } from './Flags'
import { PopupHeader } from './PopupHeader'

interface RejectKitProps {
  close?: any
  RejectUser?: any
  flagReasons?: any,
  callbackKit?: (res: any) => void
}

export const RejectPopup: React.FC<RejectKitProps> = (props) => {
  const [showLoader, SetShowLoader] = useState(false)
  const { callbackKit, RejectUser } = props
  const [Checked, Setchecked] = useState(props.RejectUser.client_contacted === true ? true : false)
  const [AgeChecked, Setagechecked] = useState(false)
  const [confirmation, showconfirmation] = useState(false)
  const [ProtectionChecked, SetProtectionChecked] = useState(false)
  const [DepressionChecked, SetDepressionChecked] = useState(false)
  const [OlderPartnerChecked, SetOlderPartnerChecked] = useState(false)
  const [DrugsChecked, SetDrugsChecked] = useState(false)
  const [ScaredChecked, SetScaredChecked] = useState(false)
  const [UnsafeChecked, SetUnsafeChecked] = useState(false)
  const [CoercionChecked, SetCoercionChecked] = useState(false)
  const [details, Setdetails] = useState('')
  const [error, setError] = useState(false)
  const [checkConfirmation, setcheckConfirmation] = useState(false)
  const [errormessage, seterrormessage] = useState('')
  // const [flagsReason, setflagsReason] = useState([])
  // let ReasonsArr = [
  //     { 'age': 'User’s age' },
  //     { 'protection': 'User has been given gifts, money, drugs, alcohol or protection for sex' },
  //     { 'depression': 'User feels down or suffers from feelings of depression' },
  //     { 'older_partner': 'User’s partner is much older than them' },
  //     { 'drugs': 'User often drinks or takes drugs before having sex' },
  //     { 'scared': 'User has been made to feel scared or uncomfortable by the person/s they have been having sexual contact with' }
  // ]

  const {state: { user },} = useAuthContext()
  const permissionData:any = user;
  
  let permissionAccess:any = permissionData && permissionData.user ? permissionData.user:permissionData;

  permissionAccess = permissionAccess && permissionAccess.permissions?.orders_and_results.filter((ele:any)=>{return ele.name === 'add_lilie_flagged'});

  const Rejectkit = useCallback(() => {
  }, [])

  function GetrejectReasons() {
    let RejectReasons: any = []
    setError(false)
    if (AgeChecked) {
      RejectReasons.push('age')
    }
    if (ProtectionChecked) {
      RejectReasons.push('protection')
    }
    if (DepressionChecked) {
      RejectReasons.push('depression')
    }
    if (OlderPartnerChecked) {
      RejectReasons.push('older_partner')
    }
    if (DrugsChecked) {
      RejectReasons.push('drugs')
    }
    if (ScaredChecked) {
      RejectReasons.push('scared')
    }
    if (UnsafeChecked) {
      RejectReasons.push('unsafe_place')
    }
    if (CoercionChecked) {
      RejectReasons.push('coercion')
    }
    return RejectReasons
  }

  function RejectSti() {
    SetShowLoader(true)
    let RejectReasons: any = GetrejectReasons()
    setError(false)
    if (RejectReasons.length === 0 || !Checked) {
      setError(true)
      seterrormessage('Reject option & client contacted is required')
      return
    }
    SetShowLoader(false)
    showconfirmation(true)
  }

  function finalSubmit() {
    SetShowLoader(true)
    let RejectReasons: any = GetrejectReasons()
    const payload = {
      client_uuid: props.RejectUser.id,
      client_contacted: Checked,
      details,
      reason: RejectReasons.toString(),
      type: 'reject',
      islilie: permissionAccess && permissionAccess[0] && permissionAccess[0].value ? checkConfirmation : false,
    }
    apiFunctions
      .post(process.env.REACT_APP_SERVICE_BACKEND_URL + '/admin/approveKit', payload)
      .then((_result) => {
        SetShowLoader(false)
        closesuccess('reject')
      })
      .catch((err) => {
        setError(true)
        console.log()
        seterrormessage(err.response.data && err.response.data.message ? err.response.data.message : '')
        SetShowLoader(false)
      })
  }

  const closesuccess = (val: any) => {
    if (callbackKit) callbackKit(val)
  }
  return (
    <>
      <>
        {confirmation && (
          <div className="modal d-block bg-color-popup " role="dialog">
            <div className="modal-dialog modal-dialog-centered  modal-width-approve reject-pop" role="document">
              <div className="modal-content model-bor p-5">
                <div className="modal-body">
                  <div className="reject-title">{`You have rejected ${RejectUser &&
                    RejectUser.first_name &&
                    RejectUser.last_name &&
                    RejectUser.first_name.charAt(0).toUpperCase() +
                    RejectUser.first_name.slice(1) +
                    ' ' +
                    (RejectUser.last_name.charAt(0).toUpperCase() + RejectUser.last_name.slice(1))
                    } for a home test kit based on:`}</div>

                  <div className="">
                    {props.flagReasons && (
                      <div className="d-flex flex-wrap">
                        {props.flagReasons &&
                          props.flagReasons.split(',').map((flag: any) => (
                            <div className="mr-3 mt-3">
                              <Flags value={flag} addClass="admin-flag" />
                            </div>
                          ))}
                      </div>
                    )}
                  </div>

                  <div className="mt-3 row">
                    <div className="mt-3">
                      <a
                        href="javascript:void(0)"
                        className="reject-pop-link"
                        onClick={() => {
                          showconfirmation(false)
                        }}
                      >
                        This is wrong, go back
                      </a>
                    </div>
                    <div className="mt-5">Tick the following if you have:</div>
                    <div className="mt-4">
                      <label className="container checkbox-base" htmlFor='confirmation'>
                        <span className='sr-only'>confirmation</span>
                        <input
                          type="checkbox"
                          name="confirmation"
                          id="confirmation"
                          checked={checkConfirmation}
                          disabled={!(permissionAccess && permissionAccess[0] && permissionAccess[0].value)}
                          onChange={(e) => {
                            setcheckConfirmation(e.target.checked)
                          }}
                        />
                        <span className="checkmark" />
                      </label>
                      <div className="reject-des">
                        Please confirm you have added the client to Lilie or updated their record
                      </div>
                    </div>
                  </div>
                </div>

                <span className="errorMessage">{error ? errormessage : null}</span>

                <div className="modal-footer bor-none mb-5">
                  <button
                    type="button"
                    className="btn btn-primary popup-btn login-btn"
                    onClick={() => {
                      finalSubmit()
                    }}
                    disabled={!checkConfirmation ? (props.flagReasons.split(',').length === 1 && props.flagReasons.split(',')[0] === 'I want to talk') ? false : true : false}
                  >
                    Finish
                  </button>
                </div>
              </div>
            </div>
            {showLoader && <Loader />}
          </div>
        )}
      </>
      <>
        {!confirmation && (
          <div className="modal d-block bg-color-popup " role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-width modal-xl" role="document">
              <div className="modal-content model-bor  max-size">
                <div className="modal-body body-pad">
                  {/* you can the change popup header content dynamically using popup header component */}
                  <PopupHeader
                    Rejectkit={true}
                    ApproveLocal={
                      RejectUser && RejectUser.postcodes && RejectUser.postcodes.local_authority
                        ? RejectUser.postcodes.local_authority
                        : 'Cornwall'
                    }
                  />
                  {(!RejectUser.first_name || !RejectUser.phone_no) && <div className="space-20" />}
                  <div className="pos-header">
                    <div className="login-style1 text-align-left">
                      {RejectUser &&
                        RejectUser.first_name &&
                        RejectUser.first_name.charAt(0).toUpperCase() + RejectUser.first_name.slice(1)}{' '}
                      {RejectUser &&
                        RejectUser.last_name &&
                        RejectUser.last_name.charAt(0).toUpperCase() + RejectUser.last_name.slice(1)}
                    </div>
                    <div className="popup-text  text-align-left">
                      {RejectUser && RejectUser.phone_no && RejectUser.phone_no}
                    </div>
                    <div className="popup-text pos-abs"> <span className='text-danger mr-1'>*</span>Client contacted? </div>
                    <div className="pos-check">
                      <label className="container" htmlFor='client_contacted'>
                        <span className='sr-only'>client_contacted</span>
                        <input
                          type="checkbox"
                          name="client_contacted"
                          id="client_contacted"
                          checked={Checked}
                          onChange={(e) => {
                            Setchecked(e.target.checked)
                          }}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                  <hr />
                  <div className="popup-text ">Flagged by client in form </div>
                  <div className="mt-3 row">
                    {props.flagReasons && (
                      <div className="d-flex flex-wrap">
                        {props.flagReasons &&
                          props.flagReasons.split(',').map((flag: any) => (
                            <div className="mr-3 mt-3">
                              <Flags value={flag} addClass="admin-flag" />
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                  <div className="mt-4  text-align-left"><span className='text-danger mr-1'>*</span>Reasons for rejecting kit after contacting client</div>
                  <div className="row mt-4">
                    <div className="col-4 col-sm-4">
                      <div className="form-group d-flex text-align-left ">
                        <label className="container m-0 checkbox-wi">
                          <span className='sr-only'>age</span>
                          <input
                            aria-label='age'
                            type="checkbox"
                            name="age"
                            id="age"
                            checked={AgeChecked}
                            onChange={(e) => {
                              Setagechecked(e.target.checked)
                            }}
                          />
                          <span className="checkmark" />
                        </label>
                        <label className="checkbox-label f-check">User’s age</label>
                      </div>
                    </div>
                    <div className="col-4 col-sm-4">
                      <div className="form-group d-flex text-align-left">
                        <label className="container m-0 checkbox-wi">
                          <span className='sr-only'>protection</span>
                          <input
                            aria-label='protection'
                            type="checkbox"
                            name="protection"
                            id="protection"
                            checked={ProtectionChecked}
                            onChange={(e) => {
                              SetProtectionChecked(e.target.checked)
                            }}
                          />
                          <span className="checkmark" />
                        </label>
                        <label className="checkbox-label m-check">
                          User has been given gifts, money, drugs, alcohol or protection for sex
                        </label>
                      </div>
                    </div>
                    <div className="col-4 col-sm-4">
                      <div className="form-group d-flex text-align-left">
                        <label className="container m-0 checkbox-wi">
                          <span className='sr-only'>depression</span>
                          <input
                            aria-label='depression'
                            type="checkbox"
                            name="depression"
                            id="depression"
                            checked={DepressionChecked}
                            onChange={(e) => {
                              SetDepressionChecked(e.target.checked)
                            }}
                          />
                          <span className="checkmark" />
                        </label>
                        <label className="checkbox-label l-check">
                          User is worried about their low mood
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-4 col-sm-4">
                      <div className="form-group d-flex text-align-left ">
                        <label className="container m-0 checkbox-wi">
                          <span className='sr-only'>older_partner</span>
                          <input
                            aria-label='older_partner'
                            type="checkbox"
                            name="older_partner"
                            id="older_partner"
                            checked={OlderPartnerChecked}
                            onChange={(e) => {
                              SetOlderPartnerChecked(e.target.checked)
                            }}
                          />
                          <span className="checkmark" />
                        </label>
                        <label className="checkbox-label f-check">User’s partner is much older</label>
                      </div>
                    </div>
                    <div className="col-4 col-sm-4">
                      <div className="form-group d-flex text-align-left">
                        <label className="container m-0 checkbox-wi">
                          <span className='sr-only'>drugs</span>
                          <input
                            aria-label='drugs'
                            type="checkbox"
                            name="drugs"
                            id="drugs"
                            checked={DrugsChecked}
                            onChange={(e) => {
                              SetDrugsChecked(e.target.checked)
                            }}
                          />
                          <span className="checkmark" />
                        </label>
                        <label className="checkbox-label m-check">
                        User is worried about their use of alcohol or drugs
                        </label>
                      </div>
                    </div>
                    <div className="col-4 col-sm-4">
                      <div className="form-group d-flex text-align-left">
                        <label className="container m-0 checkbox-wi">
                          <span className='sr-only'>unsafe_person</span>
                          <input
                            aria-label='unsafe_person'
                            type="checkbox"
                            name="unsafe_person"
                            id="unsafe_person"
                            checked={ScaredChecked}
                            onChange={(e) => {
                              SetScaredChecked(e.target.checked)
                            }}
                          />
                          <span className="checkmark" />
                        </label>
                        <label className="checkbox-label l-check">
                        User feels there is someone in their life who makes them feel unsafe{' '}
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* add new flag */}
                  <div className="row mt-4">
                    <div className="col-4 col-sm-4">
                      <div className="form-group d-flex text-align-left ">
                        <label className="container m-0 checkbox-wi">
                          <span className='sr-only'>unsafe_place</span>
                          <input
                            aria-label='unsafe_place'
                            type="checkbox"
                            name="unsafe_place"
                            id="unsafe_place"
                            checked={UnsafeChecked}
                            onChange={(e) => {
                              SetUnsafeChecked(e.target.checked)
                            }}
                          />
                          <span className="checkmark" />
                        </label>
                        <label className="checkbox-label f-check">User doesn’t feel safe at home/where they live</label>
                      </div>
                    </div>
                    <div className="col-4 col-sm-4">
                      <div className="form-group d-flex text-align-left">
                        <label className="container m-0 checkbox-wi">
                          <span className='sr-only'>coercion</span>
                          <input
                            aria-label='coercion'
                            type="checkbox"
                            name="coercion"
                            id="coercion"
                            checked={CoercionChecked}
                            onChange={(e) => {
                              SetCoercionChecked(e.target.checked)
                            }}
                          />
                          <span className="checkmark" />
                        </label>
                        <label className="checkbox-label m-check">
                        User is being made to do something that could put them in danger
                        </label>
                      </div>
                    </div>
                   
                  </div>
                  <span className="errorMessage">{error ? errormessage : null}</span>

                  <div className="mar-p  text-align-left">
                    <label>Further details (optional)</label>
                    <textarea
                      aria-label='details'
                      name="details"
                      id="details"
                      onChange={(e) => Setdetails(e.target.value)}
                      className="form-control h-textbox w-txt area-color"
                    />
                  </div>
                </div>

                <div className="modal-footer bor-none body-pad">
                  <div className="popup-btn close-pop" onClick={props.close}>
                    Close
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary popup-btn login-btn btn-rej-col"
                    onClick={(e) => {
                      RejectSti()
                    }}
                  >
                    Reject test kit
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  )
}
