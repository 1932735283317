import React, { createContext, useContext, useReducer } from 'react';

import {
  LocationState,
  initialState,
  locationReducer
} from '../reducers/LocationReducer';

type LocationContextProps = {
  state: LocationState;
  dispatch: React.Dispatch<any>;
};

const LocationContext = createContext<LocationContextProps>({
  state: initialState,
  dispatch: () => null
});

// create admin location context provider
export const LocationProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(locationReducer, initialState);

  return (
    <LocationContext.Provider value={{ state, dispatch }}>
      {children}
    </LocationContext.Provider>
  )
}

export const useLocationContext = () => {
  const context = useContext(LocationContext);
  if (!context) throw new Error('Location must be used');
  return context;
};
